import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React from 'react'

import { MakeAdminDialogProps } from './MakeAdminDialog.types'

const MakeAdminDialog = (props: MakeAdminDialogProps) => {
  const { isOpen, cancelRef, onClose, onConfirm } = props
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Grant admin access to user(s)
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? Admins can delete users, change quotas, and make other users admins.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} aria-label="cancel access changes">
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                onConfirm()
                onClose()
              }}
              ml={3}
              aria-label="grant admin access"
            >
              Grant
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default MakeAdminDialog
