import { MeterUsageDisplay } from '@components/meter/MeterUsageDisplay'
import { cn } from '@utils/tailwindUtils'

interface OrgOverviewProps {
  totalCost: number
  totalBudgetDollars: number
}

const OrgOverview = (props: OrgOverviewProps) => {
  const { totalCost, totalBudgetDollars } = props
  return (
    <div className="flex w-2/5 flex-col gap-y-4 rounded-4 border border-border-1 bg-surface-0 p-6">
      <div className="flex flex-col gap-y-2">
        <h2 className="type-caption-medium text-text-strong">Organization overview</h2>
        <div className="flex w-full flex-row gap-6">
          <div className="w-1/2">
            <p className="type-caption text-text-faint">Total used</p>
            {/* TODO: properly display cost calculations when ready */}
            <p
              className={cn(
                'type-body-200-semibold text-text-strong',
                totalCost === -1 && 'text-text-disabled'
              )}
            >
              ${totalCost === -1 ? ' --' : totalCost}
            </p>
          </div>
          <div className="w-1/2">
            <p className="type-caption text-text-faint">Total budget</p>
            <p className="type-body-200-semibold font-semibold text-text-strong">
              ${totalBudgetDollars}
            </p>
          </div>
        </div>
      </div>
      <MeterUsageDisplay value={totalCost} max={totalBudgetDollars} />
    </div>
  )
}

export default OrgOverview
