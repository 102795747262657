import { Box, HStack, Icon, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { Input } from '@components/input/Input'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import DeploymentAnalyticsGrid from './deploymentAnalyticsGrid/DeploymentAnalyticsGrid'
import { ModelDetailsProps } from './ModelDetails.types'

const ModelDetails = ({ deploymentDetails, projectDetails, data }: ModelDetailsProps) => {
  const detailsBackground = useColorModeValue('gray.200', 'gray.600')
  const borderColor = useColorModeValue('gray.200', 'gray.500')
  const statusColor = useColorModeValue('green.500', 'green.300')
  const linkColor = useColorModeValue('blue.400', 'blue.300')

  const navigate = useNavigate()

  const { trackEvent } = useEventTracking()

  return (
    <VStack fontSize="sm" align="flex-start">
      <HStack spacing={6}>
        <VStack align="flex-start">
          <Text>Model Name</Text>
          <Box
            borderRadius="md"
            border="1px solid"
            borderColor={borderColor}
            px={4}
            py={2}
            minW="40vw"
          >
            <Text fontSize="md">{deploymentDetails.name}</Text>
          </Box>
        </VStack>
        <VStack align="flex-start">
          <Text>Model ID</Text>
          <Box overflow="hidden" borderRadius="md">
            <Input canCopy size="large" variant="monospace" value={deploymentDetails.id} />
          </Box>
        </VStack>
      </HStack>
      <HStack spacing={6} pt={6}>
        <VStack align="flex-start">
          <Text>Creation at</Text>
          <Box borderRadius="md" bg={detailsBackground} px={4} py={2} minW="15vw">
            <Text fontSize="md">{timestampToDate(deploymentDetails.updated_at)}</Text>
          </Box>
        </VStack>
        <VStack align="flex-start">
          <Text>Status</Text>
          <HStack borderRadius="md" bg={detailsBackground} px={4} py={2} minW="15vw">
            <Icon boxSize={4} color={statusColor} as={BsFillCheckCircleFill} />
            <Text pl={1} color={statusColor} fontSize="md">
              Ready
            </Text>
          </HStack>
        </VStack>
        <VStack align="flex-start">
          <Text>Data Modality</Text>
          <Box borderRadius="md" bg={detailsBackground} px={4} py={2} minW="15vw">
            <Text fontSize="md">{projectDetails.modality}</Text>
          </Box>
        </VStack>
        <VStack align="flex-start">
          <Text>Project Name</Text>
          <HStack
            justify="space-between"
            borderRadius="md"
            bg={detailsBackground}
            px={4}
            py={2}
            minW="30vw"
            cursor="pointer"
            onClick={() => {
              navigate(`/cleansets/${deploymentDetails.cleanset_id}`)
              trackEvent(MixpanelEvents.clickProjectLinkFromModelDetails, {
                ...projectDetails,
                ...deploymentDetails,
              })
            }}
          >
            <Text fontSize="md">{projectDetails.projectName}</Text>
            <Icon boxSize={4} color={linkColor} as={FiExternalLink} />
          </HStack>
        </VStack>
      </HStack>
      <Box pt={10}>
        <Text fontWeight="500" fontSize="md" pb={2}>
          Model Evaluation
        </Text>
        <Text fontSize="sm" fontWeight="400">
          Your deployed model was created by ensembling these (and many other) ML models trained for
          you automatically. Check out the performance of each of the models we trained for you
          below. Your deployed model is the one with the highest accuracy, shown in the first row.
        </Text>
      </Box>
      {data && data.length > 0 && (
        <Box pt={6} w="100%">
          <DeploymentAnalyticsGrid data={data} />
        </Box>
      )}
    </VStack>
  )
}

export default ModelDetails
