import { Center, HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { useMultiSelectBatchActionMutation } from '@services/datasheet/mutations'
import { useLabelOptions } from '@services/datasheet/queries'
import { cn } from '@utils/tailwindUtils'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import { Tasktype } from 'src/pages/datasetUpload/DatasetUpload.types'

import CommandPalette from '../commandpalette/CommandPalette'
import { ActionType } from '../commandpalette/CommandPalette.types'
import DataLabelingCommandPalette from '../dataLabelingCommandPalette/DataLabelingCommandPalette'
import {
  getGivenLabelDisplay,
  getSuggestedLabelDisplay,
} from '../editInterface/EditInterface.helpers'
import ResolverHeading from '../editInterface/resolverHeading/ResolverHeading'
import TabularDataDisplay from '../editInterface/tabularDataDisplay/TabularDataDisplay'
import TextDataDisplay from '../editInterface/textDataDisplay/TextDataDisplay'
import { DataLabelingResolverProps, LabelType } from './DataLabelingResolver.types'

const DataLabelingResolver = (props: DataLabelingResolverProps) => {
  const {
    cleansetId,
    gridApi,
    originalDatasetColumns,
    projectDetails,
    selectedRowIndex,
    selectedRowData,
    isTemplate,
    selectedRows,
    onClose,
    resolverFailure,
    isFetchingRows,
    onSetThresholdButtonClick,
    createAutoLabelColumn,
    currentThresholdRow,
    dataLabelingActiveStep,
    setHasStartedLabeling,
    updateRowAction,
    handleMultiSelectAction,
    updateRowCustomTag,
    isActionLoading,
    autoAdvance,
    setAutoAdvance,
    componentWidth,
    setResolverWidth,
  } = props
  const { modality, labels, labelColumn, predictiveColumns, tasktype } = projectDetails
  const imageMode = projectDetails.modality === 'image'

  const { isLoading: isMutationLoading } = useMultiSelectBatchActionMutation(gridApi)

  const labelOptions = useLabelOptions(labels, cleansetId, projectDetails.tasktype)
  const newlyAddedLabels = labelOptions.filter((label) => !labels.includes(label))

  const resolverBodyColor = useColorModeValue('neutral.50', 'neutralDarkMode.50')

  const isMultiSelect = selectedRows.length > 1
  const dataColValue = selectedRowData[predictiveColumns?.[0]]
  const isIssue = selectedRowData._cleanlab_issue
  const correctedLabel = selectedRowData._cleanlab_clean_label
  const suggestedLabel = selectedRowData._cleanlab_suggested_label
  const givenLabel = selectedRowData[labelColumn] as LabelType | null
  const givenLabelType = givenLabel ? typeof givenLabel : null
  const isOutlier = selectedRowData?._cleanlab_outlier
  const isAmbiguous = selectedRowData?._cleanlab_ambiguous ?? false
  const suggestExclude = isOutlier || isAmbiguous
  const suggestedAction = suggestExclude ? ActionType.EXCLUDE : ActionType.AUTO_FIX
  const isUnlabeled = selectedRowData?._cleanlab_unlabeled ?? false
  const suggestedLabelDisplay = getSuggestedLabelDisplay(
    suggestedLabel,
    suggestExclude,
    projectDetails.tasktype === Tasktype.MULTICLASS
  )
  const givenLabelDisplay = getGivenLabelDisplay(givenLabel)

  const topLabels = selectedRowData._cleanlab_top_labels
  const topProbs = selectedRowData._cleanlab_top_probs
  const currentRowAction = selectedRowData._cleanlab_action

  const resolverBackgroundColor = useColorModeValue('white', 'neutralDarkMode.100')

  const [showHalfResolver, setShowHalfResolver] = useState(false)

  const labelToProb = topLabels.reduce(
    (acc: Record<LabelType, number>, currLabel: LabelType, idx: number) => {
      return { ...acc, [currLabel]: Number(topProbs[idx]) }
    },
    {}
  )

  useEffect(() => {
    // If multi-select is active, halve the resolver width
    if (isMultiSelect && !showHalfResolver) {
      setShowHalfResolver(true)
      setResolverWidth((prevComponentWidth) => (prevComponentWidth ?? 0) / 2)
    } else if (showHalfResolver && !isMultiSelect) {
      setShowHalfResolver(false)
      setResolverWidth((prevComponentWidth) => (prevComponentWidth ?? 0) * 2)
    }
  }, [showHalfResolver, isMultiSelect, setResolverWidth])

  // Row object without any cleanlab added columns.
  const omitColumns = [labelColumn]
  const originalCurrRowData = _.omit(_.pick(selectedRowData, originalDatasetColumns), omitColumns)

  const resolverLeftClass = cn(
    'h-full overflow-x-clip border-r border-t border-border-1',
    componentWidth ? 'w-full' : 'w-fit'
  )

  return (
    <VStack
      alignItems="flex-start"
      bg={resolverBackgroundColor}
      h="100%"
      w="100%"
      borderLeftRadius="md"
      gap={0}
      overflowY="hidden"
    >
      <ResolverHeading
        autoAdvance={autoAdvance}
        onClose={onClose}
        isMultiSelect={isMultiSelect}
        setAutoAdvance={setAutoAdvance}
        isTemplate={isTemplate}
      />
      {selectedRowData && (
        <HStack spacing={0} h="100%" w="100%" bg={resolverBodyColor}>
          {/* Left side of the resolver (datapoint) */}
          {!showHalfResolver && (
            <div className={resolverLeftClass}>
              {/* Edit interface for text and image modalities */}
              {modality === 'text' && (
                <TextDataDisplay
                  textColumnName={predictiveColumns?.[0]}
                  text={dataColValue as string}
                />
              )}
              {imageMode && (
                <div className="flex h-3/4 items-center overflow-hidden object-contain">
                  <img
                    className="h-min max-h-full w-full rounded-2 object-contain px-6"
                    alt=""
                    src={
                      selectedRowData[
                        `_cleanlab_media_url_${projectDetails.imageColumns[0]}`
                      ] as string
                    }
                  />
                </div>
              )}

              {/* Edit interface for tabular modality */}
              {modality === 'tabular' && (
                <TabularDataDisplay
                  data={[...Object.entries(originalCurrRowData)]}
                  projectDetails={projectDetails}
                />
              )}
            </div>
          )}
          {/* Right side of the resolver (actions) */}
          {dataLabelingActiveStep === 0 ? (
            <DataLabelingCommandPalette
              selectedRowIndex={selectedRowIndex}
              labels={labelOptions}
              givenLabel={givenLabelDisplay}
              suggestedLabel={suggestedLabelDisplay}
              correctedLabel={correctedLabel?.toString() ?? null}
              suggestedAction={suggestedAction}
              labelToProba={labelToProb}
              updateRowAction={(action: ActionType | string) => {
                updateRowAction(action)
                setHasStartedLabeling()
                setTimeout(() => dataLabelingActiveStep === 0 && createAutoLabelColumn(), 1200)
              }}
              handleMultiSelectAction={(action: string, label?: string | number | boolean) => {
                handleMultiSelectAction(action, label)
                setHasStartedLabeling()
                setTimeout(() => createAutoLabelColumn(), 1200)
              }}
              isLabelIssue={isIssue}
              isUnlabeled={isUnlabeled}
              suggestExclude={suggestExclude}
              taskType={tasktype}
              projectDetails={projectDetails}
              selectedRows={selectedRows}
              isLoading={isMutationLoading}
              isActionLoading={isActionLoading}
              resolverWidth={componentWidth ?? 0}
              suggestedLabelDisplay={suggestedLabelDisplay}
              isFetchingRows={isFetchingRows}
              onSetThresholdButtonClick={onSetThresholdButtonClick}
              currentThresholdRow={currentThresholdRow}
              givenLabelType={givenLabelType}
              currentRowAction={currentRowAction}
              autoAdvance={autoAdvance}
              updateRowCustomTag={(tag: string) => {
                updateRowCustomTag(tag)
                setTimeout(() => createAutoLabelColumn(), 1200)
              }}
              newlyAddedLabels={newlyAddedLabels}
            />
          ) : (
            <CommandPalette
              selectedRowIndex={selectedRowIndex}
              labels={labelOptions}
              givenLabel={givenLabelDisplay}
              suggestedLabel={suggestedLabelDisplay}
              correctedLabel={correctedLabel?.toString() ?? null}
              suggestedAction={suggestedAction}
              labelToProba={labelToProb}
              updateRowAction={updateRowAction}
              handleMultiSelectAction={handleMultiSelectAction}
              updateRowCustomTag={updateRowCustomTag}
              isLabelIssue={isIssue}
              isUnlabeled={isUnlabeled}
              suggestExclude={suggestExclude}
              taskType={tasktype}
              projectDetails={projectDetails}
              selectedRows={selectedRows}
              isLoading={isMutationLoading}
              isActionLoading={isActionLoading}
              resolverWidth={componentWidth ?? 0}
              suggestedLabelDisplay={suggestedLabelDisplay}
              isFetchingRows={isFetchingRows}
              showDataLabelingWorkflow
              givenLabelType={givenLabelType}
              currentRowAction={currentRowAction}
              autoAdvance={autoAdvance}
              newlyAddedLabels={newlyAddedLabels}
            />
          )}
        </HStack>
      )}
      {resolverFailure && (
        <Center flexDirection="column" bg={resolverBackgroundColor} h="85vh" w="100%">
          <FiAlertTriangle size={52} color="red" />
          <Text mt={4} fontSize="lg">
            Could not load the Resolver. Please check your connection and try again.
          </Text>
        </Center>
      )}
    </VStack>
  )
}

export default DataLabelingResolver
