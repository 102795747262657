import { cleanlabColors } from '@assets/styles/CleanlabColors'
import globalStyles from '@assets/styles/global.module.css'
import {
  Box,
  Center,
  HStack,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import { IconExpand } from '@components/icons/IconExpand'
import { useEventTracking } from '@hooks/useEventTracking'
import { BarDatum } from '@nivo/bar'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import {
  useCleansetFeatureImportance,
  useCleansetModelPerformance,
  useCleansetModelPerformanceByClass,
  useModelSummary,
} from '@services/cleanset/queries'
import { useErrorsByColumn } from '@services/datasheet/queries'
import datasheetApiService from '@services/datasheetApi'
import { isUUID4 } from '@utils/functions/isUUID4'
import { AxiosError } from 'axios'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useParamsCleansetId } from '../cleanset/Cleanset.helpers'
import { CleansetContext } from '../cleanset/CleansetContext'
import { CLEANLAB_FRONTEND_COLUMN } from '../cleanset/datasheet/Datasheet.types'
import { FilterActionType } from '../cleanset/filterReducer/FilterState.types'
import { useFilters } from '../cleanset/filterReducer/useFilters'
import { ISSUE_TYPE } from '../cleanset/filterSidebar/FilterSidebar.types'
import { returnInitialTabsState } from '../cleanset/tabsReducer/TabsState'
import { useCleansetTabs } from '../cleanset/tabsReducer/useCleansetTabs'
import AgModelSummaryGrid from './agModelSummaryGrid/AgModelSummaryGrid'
import { ChartCard } from './chartCard/ChartCard'
import {
  correctedLabelIssuesOnClickFn,
  correctionsHeatmapOnClickFn,
  sourcesWithLabeledDataOnClickFn,
  sourcesWithMostIssuesOnClickFn,
  suggestedLabelIssuesOnClickFn,
  suggestionsHeatmapOnClickFn,
  topCorrectionsOnClickFn,
  topSuggestedCorrectionsOnClickFn,
} from './CleansetChartOnClick.helpers'
import {
  CHART_TEXT_DICT,
  generateCorrectedLabelIssuesData,
  generateHeatmapData,
  generateIssuesByColumn,
  generateLabeledRank,
  generateSuggestedLabelIssuesData,
  generateTopCorrectionsData,
  getRankedChartsClass,
} from './CleansetCharts.helpers'
import {
  CleansetChartsProps,
  RankedChart,
  RankedChartKey,
  RankedChartMode,
} from './CleansetCharts.types'
import CorrectionsChart from './correctionsChart/CorrectionsChart'
import CorrectionsHeatmap from './correctionsHeatmap/CorrectionsHeatmap'
import { HeatmapData } from './correctionsHeatmap/CorrectionsHeatmap.types'
import DataIssuesChart from './dataIssuesChart/DataIssuesChart'
import ExportToPdf from './exportToPdf/ExportToPdf'
import FeatureImportanceTable from './featureImportanceTable/FeatureImportanceTable'
import { IssuesByColumnModal } from './issuesByColumnModal/IssuesByColumnModal'
import LabelCountsChart from './labelCountsChart/LabelCountsChart'
import LabeledDataChart from './labeledDataChart/LabeledDataChart'
import ModelPerformanceSection from './modelPerformanceSection/ModelPerformanceSection'
import PrintHeader from './printHeader/PrintHeader'

const CleansetCharts = ({
  projectName,
  scrollToSummary,
  setScrollToSummary,
  setAccuracy,
  isTemplate,
  firstGridDataRendered,
  isInitialFetch,
  columns,
  labelColumn,
}: CleansetChartsProps) => {
  const cleansetId = useParamsCleansetId()

  const [suggestedLabelIssueData, setSuggestedLabelIssueData] = useState<BarDatum[] | null>(null)
  const [topSuggestedCorrectionsData, setTopSuggestedCorrectionsData] = useState<BarDatum[] | null>(
    null
  )
  const [suggestedHeatmapData, setSuggestedHeatmapData] = useState<HeatmapData | null>(null)
  const [suggestedHeatmapDataLoading, setSuggestedHeatmapDataLoading] = useState(false)
  const [correctedLabelIssueData, setCorrectedLabelIssueData] = useState<BarDatum[] | null>(null)
  const [correctedLabelIssueDataLoading, setCorrectedLabelIssueDataLoading] = useState(false)
  const [topCorrectionsData, setTopCorrectionsData] = useState<BarDatum[] | null>(null)
  const [topCorrectionsDataLoading, setTopCorrectionsDataLoading] = useState(false)
  const [correctedHeatmapData, setCorrectedHeatmapData] = useState<HeatmapData | null>(null)
  const [selectedIssueColumn, setSelectedIssueColumn] = useState<string>(labelColumn ?? '')
  const [rankedChartsToShow, setRankedChartsToShow] = useState<RankedChart[]>([
    RankedChart.BestPerformers,
    RankedChart.MostWellLabeled,
    RankedChart.WorstPerformers,
  ])
  const [rankedChartMode, setRankedChartMode] = useState<RankedChartMode>(
    RankedChartMode.Percentage
  )

  const { data: agModelSummaryData, isLoading: agModelSummaryDataLoading } = useModelSummary(
    cleansetId,
    firstGridDataRendered ?? false
  )
  const errorsByColumnData = useErrorsByColumn({
    cleansetId: cleansetId,
    column: selectedIssueColumn ?? '',
    enabled: (firstGridDataRendered ?? false) && !!selectedIssueColumn,
  })

  const mostErrorsKey = useMemo(
    () => `mostErrorsBy${rankedChartMode}` as RankedChartKey,
    [rankedChartMode]
  )

  const leastErrorsKey = useMemo(
    () => `leastErrorsBy${rankedChartMode}` as RankedChartKey,
    [rankedChartMode]
  )

  const mostUnlabeledKey = useMemo(
    () => `mostUnlabeledBy${rankedChartMode}` as RankedChartKey,
    [rankedChartMode]
  )

  const mostErrorsSorted = useMemo(
    () =>
      [...(errorsByColumnData?.[mostErrorsKey] ?? [])].sort((a, b) => {
        if (rankedChartMode === RankedChartMode.Percentage) {
          return a.totalIssuesPercentage - b.totalIssuesPercentage
        } else {
          return a.totalIssuesCount - b.totalIssuesCount
        }
      }),
    [errorsByColumnData, mostErrorsKey, rankedChartMode]
  )

  const leastErrorsSorted = useMemo(
    () =>
      [...(errorsByColumnData?.[leastErrorsKey] ?? [])].sort((a, b) => {
        if (rankedChartMode === RankedChartMode.Percentage) {
          return b.totalIssuesPercentage - a.totalIssuesPercentage
        } else {
          return b.totalIssuesCount - a.totalIssuesCount
        }
      }),
    [errorsByColumnData, leastErrorsKey, rankedChartMode]
  )

  const hasWellLabeled = useMemo(
    () =>
      (errorsByColumnData?.mostWellLabeledByPercentage ?? []).some((col) => col.wellLabeled !== 0),
    [errorsByColumnData]
  )

  const filteredColumns = useMemo(
    () => columns?.filter((col) => col !== 'cleanlab_row_ID'),
    [columns]
  )

  const optionalChartsReady = useMemo(
    () => !!(firstGridDataRendered && errorsByColumnData && selectedIssueColumn),
    [errorsByColumnData, firstGridDataRendered, selectedIssueColumn]
  )
  const modelPerformanceData = useCleansetModelPerformance(cleansetId)
  const modelPerformancePerClassData = useCleansetModelPerformanceByClass(cleansetId)
  const featureImportanceData = useCleansetFeatureImportance(cleansetId)

  const toast = useToast()

  const {
    isOpen: isDataIssuesByTypeOpen,
    onOpen: onDataIssuesByTypeOpen,
    onClose: onDataIssuesByTypeClose,
  } = useDisclosure()
  const {
    isOpen: isPotentialLabelIssuesByClassOpen,
    onOpen: onPotentialLabelIssuesByClassOpen,
    onClose: onPotentialLabelIssuesByClassClose,
  } = useDisclosure()
  const {
    isOpen: isSuggestedLabelCorrectionsOpen,
    onOpen: onSuggestedLabelCorrectionsOpen,
    onClose: onSuggestedLabelCorrectionsClose,
  } = useDisclosure()
  const {
    isOpen: isSuggestedDistributionOpen,
    onOpen: onSuggestedDistributionOpen,
    onClose: onSuggestedDistributionClose,
  } = useDisclosure()
  const {
    isOpen: isLabelCorrectionsByClassOpen,
    onOpen: onLabelCorrectionsByClassOpen,
    onClose: onLabelCorrectionsByClassClose,
  } = useDisclosure()
  const {
    isOpen: isFrequentLabelCorrectionsOpen,
    onOpen: onFrequentLabelCorrectionsOpen,
    onClose: onFrequentLabelCorrectionsClose,
  } = useDisclosure()
  const {
    isOpen: isLabelCorrectionDistributionOpen,
    onOpen: onLabelCorrectionDistributionOpen,
    onClose: onLabelCorrectionDistributionClose,
  } = useDisclosure()
  const {
    isOpen: isIssuesByColumnOpen,
    onOpen: onIssuesByColumnOpen,
    onClose: onIssuesByColumnClose,
  } = useDisclosure()
  const {
    isOpen: isWorstPerformersOpen,
    onOpen: onWorstPerformersOpen,
    onClose: onWorstPerformersClose,
  } = useDisclosure()
  const {
    isOpen: isBestPerformersOpen,
    onOpen: onBestPerformersOpen,
    onClose: onBestPerformersClose,
  } = useDisclosure()

  const {
    isOpen: isMostUnlabeledOpen,
    onOpen: onMostUnlabeledOpen,
    onClose: onMostUnlabeledClose,
  } = useDisclosure()

  const {
    isOpen: isMostWellLabeledOpen,
    onOpen: onMostWellLabeledOpen,
    onClose: onMostWellLabeledClose,
  } = useDisclosure()

  const isLoading =
    suggestedHeatmapDataLoading ||
    correctedLabelIssueDataLoading ||
    topCorrectionsDataLoading ||
    agModelSummaryDataLoading ||
    !firstGridDataRendered

  const agSummaryRef = useRef<HTMLDivElement | null>(null)

  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()

  const trackHover = (event: string) => {
    trackEvent(event, { ...cleansetInfo, isTemplate: isTemplate })
  }

  const filters = useFilters()

  const tabs = useCleansetTabs()

  const applyFiltersAndChangeTab = (value: FilterActionType) => {
    tabs.dispatch(returnInitialTabsState())
    filters.dispatch(value)
  }

  useEffect(() => {
    if (firstGridDataRendered && setAccuracy) {
      const accuracies = agModelSummaryData.length
        ? agModelSummaryData.map((e: any) => e['Held-out Accuracy'])
        : []
      // Use the max of the two held-out accuracies
      const maxAccuracy = accuracies.length
        ? accuracies.sort((a: number, b: number) => b - a)[0]
        : 0
      setAccuracy(maxAccuracy * 100)
    }
  }, [agModelSummaryData, setAccuracy, firstGridDataRendered])

  // Scroll to the Model Summary when deployment modal link is clicked
  useEffect(() => {
    if (scrollToSummary && agSummaryRef.current && setScrollToSummary) {
      setTimeout(() => agSummaryRef.current?.scrollIntoView({ behavior: 'smooth' }), 100)
      setScrollToSummary(false)
    }
  }, [scrollToSummary, setScrollToSummary])

  // Suggested Label Distribution Data useEffects
  useEffect(() => {
    const fetchAndSetSuggestedLabelDistributionData = async () => {
      try {
        if (!isUUID4(cleansetId)) {
          return
        }
        const givenLabelDistribution = await datasheetApiService.getLabelDistribution(
          cleansetId as string,
          true
        )
        setSuggestedLabelIssueData(generateSuggestedLabelIssuesData(givenLabelDistribution.data))
      } catch (err) {
        const statusCode = (err as AxiosError).response?.status
        if (statusCode && statusCode >= 400 && statusCode < 500) {
          return // let main Datasheet component handle it
        } else {
          notifyAxiosError(toast, err as AxiosError, { title: 'Label distribution chart failed' })
        }
      }
    }
    if (cleansetId && isUUID4(cleansetId) && firstGridDataRendered) {
      fetchAndSetSuggestedLabelDistributionData()
    }
  }, [cleansetId, firstGridDataRendered, toast])

  // Suggested Corrections Distribtion Data useEffects
  useEffect(() => {
    const fetchAndSetSuggestedCorrectionAndHeatmapData = async () => {
      setSuggestedHeatmapDataLoading(true)
      try {
        const suggestedCorrectionDistribution =
          await datasheetApiService.getSuggestedCorrectionDistribution(cleansetId as string)
        setTopSuggestedCorrectionsData(
          generateTopCorrectionsData(suggestedCorrectionDistribution).map((item) => ({
            ...item,
            suggestions: item.corrections,
          }))
        )
        setSuggestedHeatmapData(generateHeatmapData(suggestedCorrectionDistribution.heatmap_data))
      } catch (err) {
        const statusCode = (err as AxiosError).response?.status
        if (statusCode && statusCode >= 400 && statusCode < 500) {
          return // let main Datasheet component handle it
        } else {
          notifyAxiosError(toast, err as AxiosError, {
            title: 'Suggested corrections chart failed',
          })
        }
      }
      setSuggestedHeatmapDataLoading(false)
    }
    if (cleansetId && isUUID4(cleansetId) && firstGridDataRendered) {
      fetchAndSetSuggestedCorrectionAndHeatmapData()
    }
  }, [cleansetId, firstGridDataRendered, toast])

  // Label Corrections Distribution Data useEffects
  useEffect(() => {
    const fetchAndSetCorrectedLabelDistributionData = async () => {
      try {
        if (!isUUID4(cleansetId)) {
          return
        }
        setCorrectedLabelIssueDataLoading(true)
        const [correctedLabelDistribution, exclusionDistribution] = await Promise.all([
          datasheetApiService.getLabelCorrectionDistribution(cleansetId as string),
          datasheetApiService.getExclusionDistribution(cleansetId as string),
        ])
        setCorrectedLabelIssueData(
          generateCorrectedLabelIssuesData(
            correctedLabelDistribution.corrections,
            exclusionDistribution.exclusions
          )
        )
      } catch (err) {
        const statusCode = (err as AxiosError).response?.status
        if (statusCode && statusCode >= 400 && statusCode < 500) {
          return // let main Datasheet component handle it
        } else {
          notifyAxiosError(toast, err as AxiosError, {
            title: 'Corrected Label distribution chart failed',
          })
        }
      }
      setCorrectedLabelIssueDataLoading(false)
    }
    if (cleansetId && isUUID4(cleansetId) && firstGridDataRendered) {
      fetchAndSetCorrectedLabelDistributionData()
    }
  }, [cleansetId, firstGridDataRendered, toast])

  // Corrections Distribution Data useEffects
  useEffect(() => {
    const fetchAndSetCorrectionAndHeatmapData = async () => {
      try {
        setTopCorrectionsDataLoading(true)
        const correctionDistribution = await datasheetApiService.getCorrectionDistribution(
          cleansetId as string
        )
        setTopCorrectionsData(generateTopCorrectionsData(correctionDistribution))
        setCorrectedHeatmapData(generateHeatmapData(correctionDistribution.heatmap_data))
      } catch (err) {
        const statusCode = (err as AxiosError).response?.status
        if (statusCode && statusCode >= 400 && statusCode < 500) {
          return // let main Datasheet component handle it
        } else {
          notifyAxiosError(toast, err as AxiosError, {
            title: 'Suggested corrections chart failed',
          })
        }
      }
      setTopCorrectionsDataLoading(false)
    }
    if (cleansetId && isUUID4(cleansetId) && firstGridDataRendered) {
      fetchAndSetCorrectionAndHeatmapData()
    }
  }, [cleansetId, firstGridDataRendered, toast])

  return (
    <Box pb="4rem">
      <PrintHeader projectName={projectName} />

      <HStack marginBottom="1rem" justify="space-between">
        <Text fontWeight="600" color={useColorModeValue('neutral.900', 'neutralDarkMode.900')}>
          {projectName}
        </Text>
        <HStack justifyContent="flex-end" alignItems="center" className={globalStyles.screenOnly}>
          <Tooltip
            hasArrow
            label="Track where your errors come from. Group by unique values for a data source column."
          >
            <Button variant="highContrast" onClick={onIssuesByColumnOpen} iconEnd={<IconExpand />}>
              View issues by source:{' '}
              <span className="inline-block max-w-xs truncate align-bottom">
                {selectedIssueColumn}
              </span>
            </Button>
          </Tooltip>

          <ExportToPdf cleansetId={cleansetId} />
        </HStack>
      </HStack>

      {/* IMPORTANT: Puppeteer looks for the ID below. Do not change it! */}
      <div
        className={getRankedChartsClass(rankedChartsToShow, optionalChartsReady)}
        id="analyticsChartsContainer"
      >
        {optionalChartsReady &&
          errorsByColumnData[mostErrorsKey] &&
          rankedChartsToShow.includes(RankedChart.WorstPerformers) && (
            <ChartCard
              chartId="worst-performers"
              title={CHART_TEXT_DICT.titles.sourcesWithMostIssues}
              bottomLegendTitle={
                rankedChartMode === RankedChartMode.Count
                  ? CHART_TEXT_DICT.titles.numberOfExamples
                  : CHART_TEXT_DICT.titles.percentageOfTotalExamples
              }
              description={CHART_TEXT_DICT.descriptions.sourcesWithMostIssues}
              isOpen={isWorstPerformersOpen}
              onOpen={onWorstPerformersOpen}
              onClose={onWorstPerformersClose}
            >
              <LabelCountsChart
                data={generateIssuesByColumn(mostErrorsSorted)}
                showPercentage={rankedChartMode === RankedChartMode.Percentage}
                indexBy="label"
                keys={['label issue', 'outlier', 'ambiguous', 'near duplicate']}
                bottomTitle={
                  rankedChartMode === RankedChartMode.Count
                    ? CHART_TEXT_DICT.titles.numberOfExamples
                    : CHART_TEXT_DICT.titles.percentageOfTotalExamples
                }
                leftTitle={`${selectedIssueColumn} values`}
                colors={['#69A6D0', '#378FC0', '#087F8B', '#036C5A', '#034637']}
                barClickEvent={MixpanelEvents.clickLabelIssuesByClassBar}
                onClick={(bar) =>
                  sourcesWithMostIssuesOnClickFn(
                    bar,
                    selectedIssueColumn ?? '',
                    applyFiltersAndChangeTab
                  )
                }
                onClose={onPotentialLabelIssuesByClassClose}
              />
            </ChartCard>
          )}
        {optionalChartsReady &&
          errorsByColumnData[leastErrorsKey] &&
          rankedChartsToShow.includes(RankedChart.BestPerformers) && (
            <ChartCard
              chartId="best-performers"
              title={CHART_TEXT_DICT.titles.sourcesWithLeastIssues}
              bottomLegendTitle={
                rankedChartMode === RankedChartMode.Count
                  ? CHART_TEXT_DICT.titles.numberOfExamples
                  : CHART_TEXT_DICT.titles.percentageOfTotalExamples
              }
              description={CHART_TEXT_DICT.descriptions.sourcesWithLeastIssues}
              isOpen={isBestPerformersOpen}
              onOpen={onBestPerformersOpen}
              onClose={onBestPerformersClose}
            >
              <LabelCountsChart
                data={generateIssuesByColumn(leastErrorsSorted)}
                showPercentage={rankedChartMode === RankedChartMode.Percentage}
                indexBy="label"
                keys={['label issue', 'outlier', 'ambiguous', 'near duplicate']}
                bottomTitle={
                  rankedChartMode === RankedChartMode.Count
                    ? CHART_TEXT_DICT.titles.numberOfExamples
                    : CHART_TEXT_DICT.titles.percentageOfTotalExamples
                }
                leftTitle={`${selectedIssueColumn} values`}
                colors={['#69A6D0', '#378FC0', '#087F8B', '#036C5A', '#034637']}
                barClickEvent={MixpanelEvents.clickLabelIssuesByClassBar}
                onClick={(bar) =>
                  sourcesWithMostIssuesOnClickFn(
                    bar,
                    selectedIssueColumn ?? '',
                    applyFiltersAndChangeTab
                  )
                }
                onClose={onPotentialLabelIssuesByClassClose}
              />
            </ChartCard>
          )}
        {optionalChartsReady && rankedChartsToShow.includes(RankedChart.MostUnlabeled) && (
          <ChartCard
            chartId="most-unlabeled"
            title={CHART_TEXT_DICT.titles.sourcesWithMostUnlabeled}
            bottomLegendTitle={
              rankedChartMode === RankedChartMode.Count
                ? CHART_TEXT_DICT.titles.numberOfExamples
                : CHART_TEXT_DICT.titles.percentageOfTotalExamples
            }
            description={CHART_TEXT_DICT.descriptions.sourcesWithMostUnlabeled}
            isOpen={isMostUnlabeledOpen}
            onOpen={onMostUnlabeledOpen}
            onClose={onMostUnlabeledClose}
          >
            <LabeledDataChart
              leftTitle={`${selectedIssueColumn} values`}
              data={generateLabeledRank(errorsByColumnData[mostUnlabeledKey], ISSUE_TYPE.UNLABELED)}
              showPercentage={rankedChartMode === RankedChartMode.Percentage}
              onClick={(bar) =>
                sourcesWithLabeledDataOnClickFn(
                  bar,
                  selectedIssueColumn ?? '',
                  applyFiltersAndChangeTab,
                  CLEANLAB_FRONTEND_COLUMN.UNLABELED
                )
              }
            />
          </ChartCard>
        )}
        {optionalChartsReady &&
          hasWellLabeled &&
          rankedChartsToShow.includes(RankedChart.MostWellLabeled) && (
            <ChartCard
              chartId="most-well-labeled"
              title={CHART_TEXT_DICT.titles.sourcesWithMostWellLabeled}
              bottomLegendTitle={
                rankedChartMode === RankedChartMode.Count
                  ? CHART_TEXT_DICT.titles.numberOfExamples
                  : CHART_TEXT_DICT.titles.percentageOfTotalExamples
              }
              description={CHART_TEXT_DICT.descriptions.sourcesWithMostWellLabeled}
              isOpen={isMostWellLabeledOpen}
              onOpen={onMostWellLabeledOpen}
              onClose={onMostWellLabeledClose}
            >
              <LabeledDataChart
                leftTitle={`${selectedIssueColumn} values`}
                showPercentage={true}
                data={generateLabeledRank(errorsByColumnData.mostWellLabeledByPercentage, '')}
                onClick={(bar) =>
                  sourcesWithLabeledDataOnClickFn(
                    bar,
                    selectedIssueColumn ?? '',
                    applyFiltersAndChangeTab,
                    CLEANLAB_FRONTEND_COLUMN.BEST_EXAMPLES
                  )
                }
              />
            </ChartCard>
          )}
        {firstGridDataRendered && (
          <ChartCard
            chartId="data-issues-by-type"
            onHover={() => trackHover(MixpanelEvents.hoverDataIssuesBarChart)}
            title={CHART_TEXT_DICT.titles.dataIssuesChart}
            bottomLegendTitle={CHART_TEXT_DICT.titles.numberOfExamples}
            description={CHART_TEXT_DICT.descriptions.dataIssuesChart}
            isOpen={isDataIssuesByTypeOpen}
            onOpen={onDataIssuesByTypeOpen}
            onClose={onDataIssuesByTypeClose}
          >
            <DataIssuesChart
              bottomTitle={CHART_TEXT_DICT.titles.numberOfExamples}
              leftTitle={CHART_TEXT_DICT.titles.dataIssues}
              barClickEvent={MixpanelEvents.clickDataIssuesBarChart}
              cleansetId={cleansetId}
              firstGridDataRendered={firstGridDataRendered ?? false}
              onClose={onDataIssuesByTypeClose}
              isInitialFetch={isInitialFetch}
            />
          </ChartCard>
        )}
        {suggestedLabelIssueData && suggestedLabelIssueData.length > 0 && (
          <ChartCard
            chartId="potential-label-issues-by-class"
            onHover={() => trackHover(MixpanelEvents.hoverLabelIssuesByClassChart)}
            title={CHART_TEXT_DICT.titles.labelIssueChart}
            bottomLegendTitle={CHART_TEXT_DICT.titles.numberOfExamples}
            description={CHART_TEXT_DICT.descriptions.labelIssueChart}
            isOpen={isPotentialLabelIssuesByClassOpen}
            onOpen={onPotentialLabelIssuesByClassOpen}
            onClose={onPotentialLabelIssuesByClassClose}
          >
            <LabelCountsChart
              data={suggestedLabelIssueData}
              indexBy="label"
              keys={['issues', 'correct']}
              bottomTitle={CHART_TEXT_DICT.titles.numberOfExamples}
              leftTitle={CHART_TEXT_DICT.titles.givenLabel}
              colors={[cleanlabColors.teal[700], cleanlabColors.cyan[700]]}
              barClickEvent={MixpanelEvents.clickLabelIssuesByClassBar}
              onClick={(bar) => suggestedLabelIssuesOnClickFn(bar, applyFiltersAndChangeTab)}
              onClose={onPotentialLabelIssuesByClassClose}
            />
          </ChartCard>
        )}
        {topSuggestedCorrectionsData && topSuggestedCorrectionsData.length > 0 && (
          <ChartCard
            chartId="most-frequently-suggested-label-corrections"
            onHover={() => trackHover(MixpanelEvents.hoverMostFrequentlySuggestedCorrectionsChart)}
            title={CHART_TEXT_DICT.titles.suggestedCorrectionsChart}
            description={CHART_TEXT_DICT.descriptions.suggestedCorrectionsChart}
            isOpen={isSuggestedLabelCorrectionsOpen}
            onOpen={onSuggestedLabelCorrectionsOpen}
            onClose={onSuggestedLabelCorrectionsClose}
          >
            <CorrectionsChart
              data={topSuggestedCorrectionsData}
              keys={['suggestions']}
              indexBy="correction"
              columnLeftTitle={CHART_TEXT_DICT.titles.givenLabel}
              columnRightTitle={CHART_TEXT_DICT.titles.suggestedLabel}
              chartTitle={CHART_TEXT_DICT.titles.numberOfSuggestedCorrections}
              barClickEvent={MixpanelEvents.clickMostFrequentlySuggestedCorrectionsBar}
              onClick={(bar) => topSuggestedCorrectionsOnClickFn(bar, applyFiltersAndChangeTab)}
              onClose={onSuggestedLabelCorrectionsClose}
            />
          </ChartCard>
        )}
        {suggestedHeatmapData && (
          <ChartCard
            chartId="distribution-of-suggested-label-corrections"
            onHover={() => trackHover(MixpanelEvents.hoverSuggestedCorrectionsHeatmap)}
            title={CHART_TEXT_DICT.titles.suggestedCorrectionsHeatmapChart}
            bottomLegendTitle={CHART_TEXT_DICT.titles.suggestedLabel}
            description={CHART_TEXT_DICT.descriptions.suggestedCorrectionsHeatmapChart}
            isOpen={isSuggestedDistributionOpen}
            onOpen={onSuggestedDistributionOpen}
            onClose={onSuggestedDistributionClose}
          >
            <CorrectionsHeatmap
              data={suggestedHeatmapData}
              leftTitle={CHART_TEXT_DICT.titles.givenLabel}
              tooltip="suggestions"
              squareClickEvent={MixpanelEvents.clickSuggestedCorrectionsHeatmapSquare}
              onClick={(square) => suggestionsHeatmapOnClickFn(square, applyFiltersAndChangeTab)}
              onClose={onSuggestedDistributionClose}
            />
          </ChartCard>
        )}
        {correctedLabelIssueData && correctedLabelIssueData.length > 0 && (
          <ChartCard
            chartId="label-corrections-by-class"
            onHover={() => trackHover(MixpanelEvents.hoverLabelCorrectionsByClassChart)}
            title={CHART_TEXT_DICT.titles.labelCorrectionsChart}
            bottomLegendTitle={CHART_TEXT_DICT.titles.numberOfExamples}
            description={CHART_TEXT_DICT.descriptions.labelCorrectionsChart}
            isOpen={isLabelCorrectionsByClassOpen}
            onOpen={onLabelCorrectionsByClassOpen}
            onClose={onLabelCorrectionsByClassClose}
          >
            <LabelCountsChart
              data={correctedLabelIssueData}
              indexBy="label"
              keys={['corrections', 'exclusions']}
              bottomTitle={CHART_TEXT_DICT.titles.numberOfExamples}
              leftTitle={CHART_TEXT_DICT.titles.givenLabel}
              colors={[cleanlabColors.teal[700], cleanlabColors.cyan[700]]}
              barClickEvent={MixpanelEvents.clickLabelCorrectionsByClassBar}
              onClick={(bar) => correctedLabelIssuesOnClickFn(bar, applyFiltersAndChangeTab)}
              onClose={onLabelCorrectionsByClassClose}
            />
          </ChartCard>
        )}
        {topCorrectionsData && topCorrectionsData.length > 0 && (
          <ChartCard
            chartId="most-frequent-label-corrections"
            onHover={() => trackHover(MixpanelEvents.hoverMostFrequentCorrectionsChart)}
            title={CHART_TEXT_DICT.titles.correctionsChart}
            bottomLegendTitle={CHART_TEXT_DICT.titles.numberOfExamples}
            description={CHART_TEXT_DICT.descriptions.correctionsChart}
            isOpen={isFrequentLabelCorrectionsOpen}
            onOpen={onFrequentLabelCorrectionsOpen}
            onClose={onFrequentLabelCorrectionsClose}
          >
            <CorrectionsChart
              data={topCorrectionsData}
              keys={['corrections']}
              indexBy="correction"
              columnLeftTitle={CHART_TEXT_DICT.titles.givenLabel}
              columnRightTitle={CHART_TEXT_DICT.titles.correctedLabel}
              chartTitle={CHART_TEXT_DICT.titles.numberOfExamples}
              barClickEvent={MixpanelEvents.clickMostFrequentCorrectionsBar}
              onClick={(bar) => topCorrectionsOnClickFn(bar, applyFiltersAndChangeTab)}
              onClose={onFrequentLabelCorrectionsClose}
            />
          </ChartCard>
        )}
        {correctedHeatmapData && (
          <ChartCard
            chartId="distribution-of-label-corrections"
            onHover={() => trackHover(MixpanelEvents.hoverCorrectionsHeatmap)}
            title={CHART_TEXT_DICT.titles.correctionsHeatmapChart}
            bottomLegendTitle={CHART_TEXT_DICT.titles.correctedLabel}
            description={CHART_TEXT_DICT.descriptions.correctionsHeatmapChart}
            isOpen={isLabelCorrectionDistributionOpen}
            onOpen={onLabelCorrectionDistributionOpen}
            onClose={onLabelCorrectionDistributionClose}
          >
            <CorrectionsHeatmap
              data={correctedHeatmapData}
              leftTitle={CHART_TEXT_DICT.titles.givenLabel}
              tooltip="corrections"
              squareClickEvent={MixpanelEvents.clickCorrectionsHeatmapSquare}
              onClick={(square) => correctionsHeatmapOnClickFn(square, applyFiltersAndChangeTab)}
              onClose={onLabelCorrectionDistributionClose}
            />
          </ChartCard>
        )}
      </div>
      {modelPerformanceData?.length > 0 && <ModelPerformanceSection data={modelPerformanceData} />}
      {!!modelPerformancePerClassData?.allClasses?.length && (
        <ModelPerformanceSection isPerClass data={modelPerformancePerClassData} />
      )}
      {featureImportanceData?.length > 0 && <FeatureImportanceTable data={featureImportanceData} />}
      {agModelSummaryData?.length > 0 && (
        <Box ref={agSummaryRef}>
          <AgModelSummaryGrid data={agModelSummaryData} />
        </Box>
      )}
      {isLoading && (
        <Center pt="3rem">
          <Spinner size="xl" />
        </Center>
      )}
      {filteredColumns && (
        <IssuesByColumnModal
          isOpen={isIssuesByColumnOpen}
          onClose={onIssuesByColumnClose}
          columns={filteredColumns}
          selectedIssueColumn={selectedIssueColumn}
          setSelectedIssueColumn={setSelectedIssueColumn}
          setRankedChartsToShow={setRankedChartsToShow}
          rankedChartsToShow={rankedChartsToShow}
          setRankedChartMode={setRankedChartMode}
          rankedChartMode={rankedChartMode}
          cleansetId={cleansetId}
        />
      )}
    </Box>
  )
}

export default CleansetCharts
