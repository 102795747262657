import { Button, Flex, FormControl, FormLabel, Input, Stack, useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useState } from 'react'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/v0/trustworthy_llm`,
  withCredentials: true,
})

const TlmUsage = () => {
  const toast = useToast()
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')

  const handleDownloadUsage = async () => {
    try {
      const accessToken = await auth.getTokenSilently()
      const response = await axiosClient.post(
        '/export_usage',
        {
          start_time: new Date(startTime).getTime() / 1000,
          end_time: new Date(endTime).getTime() / 1000,
        },
        {
          responseType: 'arraybuffer',
          ...createAuthHeaders(accessToken),
        }
      )

      // Create a link element to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `tlm_usage_${startTime}_${endTime}.csv`) // Specify the file name based on start and end times
      document.body.appendChild(link)
      link.click()
      link.remove()

      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Download started',
          status: 'success',
        }),
      })
    } catch (error) {
      console.error('Error downloading usage data:', error)
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Download failed',
          status: 'warning',
        }),
      })
    }
  }

  return (
    <Stack spacing={4}>
      <FormControl isRequired>
        <FormLabel>Start Time</FormLabel>
        <Input
          type="datetime-local"
          value={startTime}
          onChange={(e) => {
            setStartTime(e.target.value)
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>End Time</FormLabel>
        <Input
          type="datetime-local"
          value={endTime}
          onChange={(e) => {
            setEndTime(e.target.value)
          }}
        />
      </FormControl>
      <Flex justify="flex-end">
        <Button colorScheme="blue" onClick={handleDownloadUsage}>
          Download Usage Data
        </Button>
      </Flex>
    </Stack>
  )
}

export default TlmUsage
