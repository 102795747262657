import { FilterOperator } from '../../filterReducer/FilterState.types'
import { EditFilterMenuOptions } from './EditFilterMenu.types'

export const CLEANLAB_FRONTEND_CUSTOM_TAGS = ['needs_review']

export const CLEANLAB_FRONTEND_ISSUE_COLUMNS = [
  '_cleanlab_issue',
  '_cleanlab_outlier',
  '_cleanlab_unlabeled',
  '_cleanlab_ambiguous',
  '_cleanlab_is_near_duplicate',
]

export const CLEANLAB_FRONTEND_CUSTOM_METADATA_COLUMNS = [
  '_cleanlab_PII_types',
  '_cleanlab_PII_items',
  '_cleanlab_predicted_language',
]

export const issueToFrontendColumn: Record<string, string> = {
  issue: '_cleanlab_issue',
  unlabeled: '_cleanlab_unlabeled',
  outlier: '_cleanlab_outlier',
  ambiguous: '_cleanlab_ambiguous',
  is_near_duplicate: '_cleanlab_is_near_duplicate',
}

export const getFilterValue = (filter: string, isNumber: boolean) => {
  switch (filter) {
    case EditFilterMenuOptions.EQUALS: {
      return FilterOperator.Equals
    }
    case EditFilterMenuOptions.NOT_EQUAL: {
      return FilterOperator.NotEquals
    }
    case EditFilterMenuOptions.LESS_THAN: {
      return FilterOperator.LessThan
    }
    case EditFilterMenuOptions.LESS_THAN_OR_EQUAL: {
      return FilterOperator.LessThanOrEqual
    }
    case EditFilterMenuOptions.GREATER_THAN: {
      return FilterOperator.GreaterThan
    }
    case EditFilterMenuOptions.GREATER_THAN_OR_EQUAL: {
      return FilterOperator.GreaterThanOrEqual
    }
    case EditFilterMenuOptions.CONTAINS: {
      return FilterOperator.Contains
    }
    case EditFilterMenuOptions.NOT_CONTAINS: {
      return FilterOperator.NotContains
    }
    case EditFilterMenuOptions.STARTS_WITH: {
      return FilterOperator.StartsWith
    }
    case EditFilterMenuOptions.ENDS_WITH: {
      return FilterOperator.EndsWith
    }
    default: {
      return isNumber ? FilterOperator.Equals : FilterOperator.Contains
    }
  }
}

export const getFilterLabel = (filter: string, isNumber: boolean) => {
  switch (filter) {
    case FilterOperator.Equals: {
      return EditFilterMenuOptions.EQUALS
    }
    case FilterOperator.NotEquals: {
      return EditFilterMenuOptions.NOT_EQUAL
    }
    case FilterOperator.LessThan: {
      return EditFilterMenuOptions.LESS_THAN
    }
    case FilterOperator.LessThanOrEqual: {
      return EditFilterMenuOptions.LESS_THAN_OR_EQUAL
    }
    case FilterOperator.GreaterThan: {
      return EditFilterMenuOptions.GREATER_THAN
    }
    case FilterOperator.GreaterThanOrEqual: {
      return EditFilterMenuOptions.GREATER_THAN_OR_EQUAL
    }
    case FilterOperator.Contains: {
      return EditFilterMenuOptions.CONTAINS
    }
    case FilterOperator.NotContains: {
      return EditFilterMenuOptions.NOT_CONTAINS
    }
    case FilterOperator.StartsWith: {
      return EditFilterMenuOptions.STARTS_WITH
    }
    case FilterOperator.EndsWith: {
      return EditFilterMenuOptions.ENDS_WITH
    }
    case FilterOperator.All: {
      return EditFilterMenuOptions.EQUALS
    }
    default: {
      return isNumber ? EditFilterMenuOptions.EQUALS : EditFilterMenuOptions.CONTAINS
    }
  }
}

export const numberOptions = [
  'Equals',
  'Not equal',
  'Less than',
  'Less than or equal',
  'Greater than',
  'Greater than or equal',
]

export const textOptions = [
  'Contains',
  'Not contains',
  'Equals',
  'Not equal',
  'Starts with',
  'Ends with',
]

export const issueColumnText: Record<string, string> = {
  issue: 'label_issue',
  unlabeled: 'unlabeled',
  outlier: 'outlier',
  ambiguous: 'ambiguous',
  is_near_duplicate: 'near_duplicate',
}
