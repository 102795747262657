import { ButtonSubtle } from '@components/buttonSubtle/ButtonSubtle'
import { IconFrame } from '@components/iconFrameButton/IconFrame'
import { IconEdit, IconGlobe } from '@components/icons'
import { getMDYDate } from '@utils/functions/timestampToDate'

interface OrgInfoProps {
  onOpen: VoidFunction
  organizationName: string
  licenseStartDate: number
  licenseEndDate: number
}

const OrgInfo = (props: OrgInfoProps) => {
  const { onOpen, organizationName, licenseStartDate, licenseEndDate } = props

  return (
    <div className="relative flex w-3/5 flex-col gap-y-5 rounded-4 border border-border-1 bg-surface-0 p-6 pr-5">
      <div className="absolute right-5 top-6">
        <ButtonSubtle iconEnd={<IconEdit />} onClick={onOpen}>
          Edit budget
        </ButtonSubtle>
      </div>
      <IconFrame size="medium" variant="level1" icon={<IconGlobe />} />
      <div className="flex flex-col gap-y-3">
        <h2 className="type-display-50 text-text-strong">{organizationName}</h2>
        <p className="type-body-100 text-text-faint">
          <span className="text-text-primary">License:</span> {getMDYDate(licenseStartDate)} -{' '}
          {getMDYDate(licenseEndDate)}
        </p>
      </div>
    </div>
  )
}

export default OrgInfo
