import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import useGridClassname from '@hooks/useGridClassname'
import { AgilityApp } from '@services/agilityApps/constants'
import {
  useCreateAgilityApp,
  useDeleteAgilityApps,
  useExportDebugInfo,
  useExportScrapedUrls,
  useUpdateAgilityApp,
} from '@services/agilityApps/mutations'
import { useAgilityApps } from '@services/agilityApps/queries'
import { downloadFileFromAxiosResponse } from '@utils/functions/downloadFile'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { AxiosResponse } from 'axios'
import { useRef, useState } from 'react'

import ConfirmDeleteDialog from '../confirmDeleteDialog/ConfirmDeleteDialog'
import {
  agilityGridOptions,
  getAgilityAppFromSelectedNode,
  getCreateAppModalInitialData,
} from './AgilityApps.helpers'
import CreateAppModal from './createAgilityAppModal/CreateAppModal'
import { UpdateAppModal } from './updateAgilityAppModal/UpdateAppModal'

const AgilityApps = () => {
  const numRowsPerPage = 20
  const toast = useToast()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [updateEnabled, setUpdateEnabled] = useState<boolean>(false)
  const [deleteEnabled, setDeleteEnabled] = useState<boolean>(false)
  const [generateUrlEnabled, setGenerateUrlEnabled] = useState<boolean>(false)
  const [exportScrapedUrlsEnabled, setExportScrapedUrlsEnabled] = useState<boolean>(false)
  const [exportDebugInfoEnabled, setExportDebugInfoEnabled] = useState<boolean>(false)
  const [createAppFormData, setCreateAppFormData] = useState<Partial<AgilityApp>>(
    getCreateAppModalInitialData()
  )

  const [generatedUrl, setGeneratedUrl] = useState<string>('')
  const cancelDeleteRef = useRef(null)
  const {
    isOpen: isCreateAppOpen,
    onOpen: onCreateAppOpen,
    onClose: onCreateAppClose,
  } = useDisclosure()
  const {
    isOpen: isUpdateAppOpen,
    onOpen: onUpdateAppOpen,
    onClose: onUpdateAppClose,
  } = useDisclosure()

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const {
    isOpen: isGenerateUrlOpen,
    onOpen: onGenerateUrlOpen,
    onClose: onGenerateUrlClose,
  } = useDisclosure()

  const { mutate: createAgilityApp } = useCreateAgilityApp()
  const { mutate: updateAgilityApp } = useUpdateAgilityApp()
  const { mutate: deleteAgilityApps } = useDeleteAgilityApps()

  const agilityApps = useAgilityApps()

  const onExportSuccess = (res: AxiosResponse) => {
    downloadFileFromAxiosResponse(res)
  }

  const { mutate: exportScrapedUrls, isLoading: isExportScrapedUrlsLoading } =
    useExportScrapedUrls(onExportSuccess)

  const { mutate: exportDebugInfo, isLoading: isExportDebugInfoLoading } =
    useExportDebugInfo(onExportSuccess)

  const handleCreateAppSubmit = (agilityApp: AgilityApp) => {
    createAgilityApp(agilityApp)
    onCreateAppClose()
  }

  const handleUpdateAppSubmit = (updatedApp: AgilityApp) => {
    updateAgilityApp(updatedApp)
    onUpdateAppClose()
    clearSelection()
  }

  const handleDeleteAppSubmit = (appIds: string[]) => {
    if (appIds.length === 0) {
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'No apps selected for deletion',
          status: 'info',
        }),
      })
    } else {
      deleteAgilityApps(appIds, {
        onSuccess: () => {
          gridApi?.refreshCells()
          gridApi?.deselectAll()
          onDeleteClose()
        },
      })
    }
  }

  const handleRowSelected = () => {
    const singleRowSelected = gridApi?.getSelectedNodes().length === 1
    const noRowsSelected = gridApi?.getSelectedNodes().length === 0

    setUpdateEnabled(
      singleRowSelected && gridApi?.getSelectedNodes()[0].data.status !== 'IN_PROGRESS'
    )
    setDeleteEnabled(!noRowsSelected)
    setGenerateUrlEnabled(
      singleRowSelected && gridApi?.getSelectedNodes()[0].data.status === 'READY'
    )
    setExportScrapedUrlsEnabled(
      singleRowSelected && gridApi?.getSelectedNodes()[0].data.scraped_urls !== null
    )
    setExportDebugInfoEnabled(
      singleRowSelected && gridApi?.getSelectedNodes()[0].data.debug_info !== null
    )
  }

  const handleGenerateUrl = () => {
    const selectedNodes = gridApi?.getSelectedNodes()
    if (selectedNodes?.length === 1 && selectedNodes[0].data?.urlSuffix) {
      const url = `https://rag.app/${selectedNodes[0].data.urlSuffix}`
      setGeneratedUrl(url)
      onGenerateUrlOpen()
    }
  }

  const clearSelection = () => {
    if (gridApi) {
      gridApi.deselectAll()
    }
  }

  // Add this function to handle grid ready event
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
  }

  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(generatedUrl)
      .then(() => {
        toast({
          title: 'URL copied to clipboard',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
        toast({
          title: 'Failed to copy URL',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      })
  }

  return (
    <Stack>
      <Flex justify="flex-end">
        <HStack>
          <Button colorScheme="whatsapp" size="sm" onClick={onCreateAppOpen}>
            Create
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={onUpdateAppOpen}
            isDisabled={!updateEnabled}
          >
            Update
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              if (gridApi?.getSelectedNodes() && gridApi.getSelectedNodes().length > 0) {
                onDeleteOpen()
              } else {
                toast({
                  ...defaultToastAlertProps,
                  render: renderChakraToastAlert({
                    heading: 'No apps selected',
                    status: 'info',
                  }),
                })
              }
            }}
            isDisabled={!deleteEnabled}
          >
            Remove app
          </Button>
          <Button
            colorScheme="teal"
            size="sm"
            onClick={handleGenerateUrl}
            isDisabled={!generateUrlEnabled}
          >
            Generate Public-Facing URL
          </Button>
          <Button
            size="sm"
            onClick={() => {
              exportScrapedUrls(gridApi?.getSelectedNodes()[0].data.id)
            }}
            isDisabled={!exportScrapedUrlsEnabled}
            isLoading={isExportScrapedUrlsLoading}
          >
            Export scraped URLs
          </Button>
          <Button
            size="sm"
            onClick={() => {
              exportDebugInfo(gridApi?.getSelectedNodes()[0].data.id)
            }}
            isDisabled={!exportDebugInfoEnabled}
            isLoading={isExportDebugInfoLoading}
          >
            Export debug info
          </Button>
        </HStack>
      </Flex>
      <Box className={useGridClassname()} h="70vh" w="100%">
        <CleanlabGrid
          gridOptions={agilityGridOptions}
          onGridReady={onGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={agilityApps}
          onRowSelected={handleRowSelected}
        />
      </Box>
      <CreateAppModal
        formData={createAppFormData}
        isOpen={isCreateAppOpen}
        onClose={onCreateAppClose}
        onSubmit={handleCreateAppSubmit}
        onFormDataChange={setCreateAppFormData}
      />
      <UpdateAppModal
        isOpen={isUpdateAppOpen}
        onClose={() => {
          onUpdateAppClose()
          clearSelection()
        }}
        onSave={handleUpdateAppSubmit}
        app={getAgilityAppFromSelectedNode(gridApi?.getSelectedNodes()[0])}
      />
      <ConfirmDeleteDialog
        onClose={onDeleteClose}
        isOpen={isDeleteOpen}
        cancelRef={cancelDeleteRef}
        onConfirm={() =>
          handleDeleteAppSubmit(gridApi?.getSelectedNodes().map((app) => app.data.id) ?? [])
        }
        item="app"
      />
      <Modal
        isOpen={isGenerateUrlOpen}
        onClose={() => {
          onGenerateUrlClose()
          clearSelection()
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generated URL</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex>
              <Input value={generatedUrl} isReadOnly mr={2} />
              <Button onClick={handleCopyUrl} colorScheme="blue">
                Copy
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default AgilityApps
