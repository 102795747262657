type RecordWithStringKey = Record<string | number, any>

const snakeCaseKeys = (input: RecordWithStringKey[] | RecordWithStringKey): any => {
  // if array OR object, recurse. Exclude null because in JS, null is also an object ¯\_(ツ)_/¯
  // function is also type object, but we won't receive those from an API so not checking for that at the moment
  if (typeof input === 'object' && input !== null) {
    if (Array.isArray(input)) {
      return input.map((item) => snakeCaseKeys(item))
    } else {
      const output: RecordWithStringKey = {}

      // eslint-disable-next-line no-restricted-syntax
      for (const key in input) {
        const snakeKey = key.replace(/[A-Z]/g, (capitalLetter) => `_${capitalLetter.toLowerCase()}`)

        output[snakeKey] = snakeCaseKeys(input[key])
      }

      return output
    }
  } else {
    return input
  }
}

export default snakeCaseKeys
