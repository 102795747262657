import { FormField } from '@components/formField/FormField'
import { DatasetDetailsProps } from '@services/datasetApi'
import { useId, useMemo } from 'react'

import { CleanlabSelect } from './CleanlabSelect'
import { PageDescription, PageSubHeading } from './CreateProject.helpers'
import { SelectLabelForm } from './CreateProjectSelectLabel'
import { Modality } from './projectFormFields/ProjectFormFields.types'
import { InlineLink } from './Sidebar'

export const SelectTextColumn = ({
  form,
  datasetDetails,
  modality,
}: {
  form: SelectLabelForm
  datasetDetails: DatasetDetailsProps
  modality: Modality
}) => {
  const labelId = useId()
  const descriptionId = useId()
  const sortedPossibleTextColumns = useMemo(
    () => [...datasetDetails.possible_text_columns].sort((a, b) => a.localeCompare(b)),
    [datasetDetails.possible_text_columns]
  )
  if (modality !== Modality.text) {
    return null
  }

  return (
    <form.Field
      name="textColumn"
      validators={{
        onMount: () => {
          if (modality !== Modality.text) {
            return undefined
          }
          if (sortedPossibleTextColumns.length === 0) {
            return 'Dataset contains no possible text columns.'
          }
        },
        onChange: ({ value }) => {
          if (modality !== Modality.text) {
            return undefined
          }
          if (!value && modality === Modality.text) {
            return 'Please select a text column.'
          }

          return undefined
        },
      }}
    >
      {({ state, handleChange, handleBlur }) => {
        const error = state.meta.errors?.[0] || undefined
        return (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <PageSubHeading>Text column</PageSubHeading>
              <PageDescription>
                A text Project expects one column of unstructured data to learn from. Please refer
                to our{' '}
                <InlineLink to="https://help.cleanlab.ai/tutorials/format_text_data/">
                  FAQ
                </InlineLink>{' '}
                for joining more than one column of text.
              </PageDescription>
            </div>
            <FormField error={error}>
              <CleanlabSelect
                aria-labelledby={labelId}
                aria-describedby={descriptionId}
                placeholder="Select text column"
                value={state.value}
                onChange={(e) => handleChange(e.currentTarget.value)}
                onBlur={handleBlur}
              >
                {sortedPossibleTextColumns.map((v: string) => (
                  <option key={'text' + v} value={v}>
                    {v}
                  </option>
                ))}
              </CleanlabSelect>
            </FormField>
          </div>
        )
      }}
    </form.Field>
  )
}
