import { Box, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react'
import React from 'react'

import { SimpleTableProps } from './SimpleTable.types'

const SimpleTable = (props: SimpleTableProps) => {
  const { size, columns, rows } = props

  return (
    <Box border="2px solid" borderColor="gray.300" overflowX="auto">
      <Table size="sm" fontSize={size || 'md'} variant="striped">
        <Thead>
          <Tr>
            {columns.map((c, cIdx) => (
              <Td key={cIdx}>{c as string}</Td>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((r, rowIdx) => (
            <Tr key={rowIdx}>
              {Object.values(r).map((v, vIdx) => (
                <Td key={vIdx}>{v as string}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default SimpleTable
