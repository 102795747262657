import { IconX } from '@components/icons'
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react'

import { ButtonInput } from './ButtonInput'

export const ClearButton = forwardRef(
  (
    { className, ...props }: ComponentPropsWithoutRef<'button'>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return <ButtonInput ref={ref} icon={<IconX />} {...props} />
  }
)
