import { AgilityApp } from '@services/agilityApps/constants'
import snakeCaseKeys from '@utils/functions/snakeCaseKeys'
import { timestampToDate } from '@utils/functions/timestampToDate'
import {
  GetContextMenuItemsParams,
  IRowNode,
  ITooltipParams,
  ProcessCellForExportParams,
} from 'ag-grid-community'
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'

export const timeFormatter = (param: ValueFormatterParams<AgilityApp>) => {
  return timestampToDate(param.value)
}

export const jsonFormatter = (param: ValueFormatterParams<AgilityApp>) => {
  return param.value ? JSON.stringify(param.value) : param.value
}

const appTypeFormatter = (params: ValueFormatterParams<AgilityApp>) => {
  return params.value ? 'External Demo App' : 'Internal Test App'
}

const processCellForClipboard = (params: ProcessCellForExportParams<AgilityApp>) => {
  const colDef = params.column.getColDef()
  if (colDef.valueFormatter && typeof colDef.valueFormatter === 'function') {
    return colDef.valueFormatter({
      ...params,
      data: params.node?.data,
      node: params.node || null,
      colDef: colDef,
    } as ValueFormatterParams<AgilityApp, any>)
  } else {
    return params.value
  }
}

export const columnDefs: ColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    headerTooltip: 'ID',
    pinned: 'left',
    checkboxSelection: true,
    minWidth: 200,
  },
  {
    field: 'name',
    headerName: 'Name',
    headerTooltip: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
    headerTooltip: 'Description',
  },
  { field: 'createdAt', headerName: 'Created At', valueFormatter: timeFormatter, sort: 'desc' },
  {
    field: 'urlSuffix',
    headerName: 'URL Suffix',
    headerTooltip: 'URL Suffix',
  },
  {
    field: 'status',
    headerName: 'Status',
    headerTooltip: 'Status',
  },
  {
    field: 'ingestionProgress',
    headerName: 'Ingestion Progress',
    headerTooltip: 'Ingestion Progress',
    valueFormatter: (params: ValueFormatterParams<AgilityApp>) => {
      return Number(params.value).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 0,
        minimumIntegerDigits: 1,
      })
    },
  },
  {
    field: 'crawlerConfig',
    headerName: 'Crawler Config',
    valueFormatter: jsonFormatter,
    tooltipValueGetter: (params: ITooltipParams) => {
      return JSON.stringify(params.value)
    },
    headerTooltip: 'Crawler Config',
  },
  {
    field: 'chatTitle',
    headerName: 'Chat Title',
    headerTooltip: 'Chat Title',
  },
  {
    field: 'chatSubtitle',
    headerName: 'Chat Subtitle',
    headerTooltip: 'Chat Subtitle',
  },
  {
    field: 'suggestedQuestions',
    headerName: 'Suggested Questions',
    headerTooltip: 'Suggested Questions',
    cellRenderer: (params: ICellRendererParams<AgilityApp>) => {
      if (Array.isArray(params.value)) {
        return params.value.join(', ') || '-'
      }
      return '-'
    },
    tooltipValueGetter: (params: ITooltipParams) => {
      if (Array.isArray(params.value)) {
        return params.value.join('\n')
      }
      return '-'
    },
  },
  {
    field: 'scrapedUrls',
    headerName: 'Scraped URLs',
    headerTooltip: 'Scraped URLs',
    valueFormatter: jsonFormatter,
    tooltipValueGetter: (params: ITooltipParams) => {
      if (Array.isArray(params.value)) {
        const urls = params.value.slice(0, 10).join(', ')
        if (params.value.length > 10) {
          return `${urls}, ...`
        }
        return urls
      }
      return '-'
    },
  },
  {
    field: 'numScrapedUrls',
    headerName: 'Number of Scraped URLs',
    headerTooltip: 'Number of Scraped URLs',
  },
  {
    field: 'debugInfo',
    headerName: 'Debug Info',
    headerTooltip: 'Debug Info',
    valueFormatter: jsonFormatter,
    tooltipValueGetter: (params: ITooltipParams) => {
      return JSON.stringify(params.value)
    },
  },
  {
    field: 'isSalesDemo',
    headerName: 'App Type',
    headerTooltip: 'App Type',
    valueFormatter: appTypeFormatter,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [true, false],
      valueFormatter: appTypeFormatter,
    },
  },
]

export const agilityGridOptions: GridOptions = {
  columnDefs,
  rowSelection: 'multiple',
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true,
    flex: 1,
    width: 100,
    minWidth: 100,
    tooltipValueGetter: (params: ITooltipParams) => {
      return params.value
    },
  },
  animateRows: true,
  getContextMenuItems: (params: GetContextMenuItemsParams) => {
    if (params.value === undefined) {
      return []
    } else {
      return ['copy']
    }
  },
  processCellForClipboard,
  tooltipShowDelay: 0,
}

export const getAgilityAppFromSelectedNode = (node?: IRowNode): AgilityApp => {
  return node?.data
    ? { ...snakeCaseKeys(node.data), crawler_config: JSON.stringify(node.data.crawlerConfig) }
    : ({} as AgilityApp)
}

export const INITIAL_CRAWLER_CONFIG = '{"urls": [<enter list of urls to crawl>]}'

export const getCreateAppModalInitialData = (): Partial<AgilityApp> => {
  return {
    name: '',
    description: '',
    url_suffix: '',
    crawler_config: INITIAL_CRAWLER_CONFIG,
    iframe_url: '',
    chat_title: '',
    chat_subtitle: '',
    suggested_questions: [''],
    is_sales_demo: false,
  }
}
