import Layout from '@common/layout/Layout'
import NotFoundComponent from '@providers/errors/NotFoundComponent'
import * as React from 'react'

const NotFound = () => {
  return (
    <Layout>
      <NotFoundComponent />
    </Layout>
  )
}

export default NotFound
