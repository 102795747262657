'use client'

import { useFormField } from '@components/formField/FormFieldContext'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cn, tv } from '@utils/tailwindUtils'
import { ComponentPropsWithoutRef, ComponentPropsWithRef, forwardRef, memo } from 'react'

import { SvgBox } from '../icons/wrapSvgIcon'
export type { CheckedState } from '@radix-ui/react-checkbox'

type CheckboxProps = Omit<
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  'defaultChecked' | 'checked'
> & {
  variant?: 'default' | 'highContrast'
  checked: CheckboxPrimitive.CheckedState | null | undefined
}

export const CheckboxIcon = ({
  checked,
  ...props
}: { checked: CheckboxPrimitive.CheckedState } & Omit<
  Omit<ComponentPropsWithoutRef<typeof SvgBox>, 'viewBoxSize' | 'size'>,
  'children'
>) => {
  return (
    <SvgBox viewBoxSize={14} size={14} {...props}>
      <path
        d={
          checked === true
            ? /* Checkmark */ 'm2.35 7 2.9 2.9 6.41 -6.4'
            : /* Dash */ 'm3 7 2.25 0 5.75 0'
        }
        strokeLinecap="round"
        strokeLinejoin="round"
        // Use strokeDasharray to draw on/off stroke when checked state changes
        strokeDasharray={checked === true || checked === 'indeterminate' ? '14 100' : '0 100'}
        style={{
          transitionProperty: 'stroke-dasharray, d, transform',
          transitionDuration: '0.15s, 0.1s, 0.25s',
          transitionTimingFunction: `cubic-bezier(.6,0,.72,1), ${checked !== 'indeterminate' ? 'cubic-bezier(.41,1.97,.55,1)' : 'ease-out'}, cubic-bezier(.41,1.97,.55,1)`,
          transform: !checked ? 'scale(.5)' : 'scale(1)',
          transformOrigin: '50% 50%',
        }}
      />
    </SvgBox>
  )
}

const variants = tv({
  base: 'group flex h-6 w-6 shrink-0 grow-0 items-center justify-center rounded-1 shadow-elev-0 outline outline-1 -outline-offset-1 outline-border-1 focus-visible:ring focus-visible:ring-focus',
  variants: {
    variant: {
      default: '',
      highContrast: '',
    },
    disabled: {
      true: 'cursor-not-allowed bg-surface-disabled text-text-disabled outline-transparent',
      false:
        'text-text-high-contrast data-[state=unchecked]:bg-surface-1 data-[state=unchecked]:outline-border-1 hover:data-[state=unchecked]:bg-surface-1-hover',
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      disabled: false,
      class:
        'bg-blue-600 outline-transparent hover:bg-blue-700 focus-visible:outline-blue-50 data-[state=unchecked]:focus-visible:outline-blue-700',
    },
    {
      variant: 'highContrast',
      disabled: false,
      class:
        'bg-surface-high-contrast outline-transparent hover:bg-neutral-800 focus-visible:outline-neutral-0 data-[state=unchecked]:focus-visible:outline-neutral-900',
    },
  ],
})

const CheckboxBase = (
  {
    className,
    id: idProp,
    required: requiredProp,
    disabled: disabledProp,
    checked: checkedProp,
    variant = 'default',
    ...props
  }: CheckboxProps,
  ref: ComponentPropsWithRef<typeof CheckboxPrimitive.Root>['ref']
) => {
  const formFieldContext = useFormField()
  const id = idProp || formFieldContext?.htmlFor || undefined
  const required =
    requiredProp !== null && requiredProp !== undefined
      ? !!requiredProp
      : !!formFieldContext?.required
  const disabled =
    disabledProp !== null && disabledProp !== undefined
      ? !!disabledProp
      : !!formFieldContext?.disabled
  const checked = checkedProp === 'indeterminate' ? checkedProp : !!checkedProp

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      id={id}
      required={required}
      disabled={disabled}
      className={cn(variants({ variant, disabled: disabled }), className)}
      checked={checked}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        forceMount
        className={cn('flex h-0 items-center justify-center text-current')}
      >
        <CheckboxIcon checked={checked} />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
}

export const Checkbox = memo(forwardRef(CheckboxBase))
Checkbox.displayName = 'Checkbox'
