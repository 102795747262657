import { CreateToastFnReturn, Text, ToastId, ToastProps, UseToastOptions } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import RegisterNotification, {
  useAllowRegisterNotification,
} from '@common/misc/registerNotification/RegisterNotification'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { CustomErrorResponseData } from '@providers/errors/ErrorToast.types'
import * as Sentry from '@sentry/browser'
import { IS_PROD_ENV, IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { AxiosError } from 'axios'

const defaultTitle = 'Something went wrong.'

enum RecognizedError {
  COLUMN_LIMIT_EXCEEDED = 'COLUMN_LIMIT_EXCEEDED',
  CLASS_LIMIT_EXCEEDED = 'CLASS_LIMIT_EXCEEDED',
  EMPTY_DATASET_UPLOADED = 'EMPTY_DATASET_UPLOADED',
  COLUMN_MISMATCH_DATASET_UPLOAD = 'COLUMN_MISMATCH_DATASET_UPLOADED',
  PRED_PROBS_NO_ID_COLUMN = 'PRED_PROBS_NO_ID_COLUMN',
  PRED_PROBS_IDS_DONT_MATCH = 'PRED_PROBS_IDS_DONT_MATCH',
  PRED_PROBS_LABELS_DONT_MATCH = 'PRED_PROBS_LABELS_DONT_MATCH',
  PRED_PROBS_RERUN_ATTEMPT = 'PRED_PROBS_RERUN_ATTEMPT',
  CANNOT_UPLOAD_DATASET_FROM_URL = 'CANNOT_UPLOAD_DATASET_FROM_URL',
  URL_DATASET_TOO_LARGE = 'URL_DATASET_TOO_LARGE',
  URL_DATASET_UPLOAD_TIMEOUT = 'URL_DATASET_UPLOAD_TIMEOUT',
  URL_DATASET_UNSUPPORTED_CONTENT_TYPE = 'URL_DATASET_UNSUPPORTED_CONTENT_TYPE',
  INCORRECT_ZIP_DATASET_FORMAT = 'INCORRECT_ZIP_DATASET_FORMAT',
  MEDIA_UPLOAD_FAILED = 'MEDIA_UPLOAD_FAILED',
  PROJECT_LABELS_PREFIX_NOT_UNIQUE = 'PROJECT_LABELS_PREFIX_NOT_UNIQUE',
}

const generateDescriptionWithID = (eventId: string) => {
  return `We have logged the error. Please refresh the page or try again later. (ID: ${eventId})`
}

export const notifyError = (
  toast: CreateToastFnReturn,
  props: ToastProps,
  showInProduction = true
) => {
  let toastProps = {
    ...defaultToastAlertProps,
    ...props,
  }
  if (IS_PROD_ENV && !showInProduction) {
    const eventId = Sentry.captureException(`${props.title} - ${props.description}`)
    toastProps = {
      ...toastProps,
      title: defaultTitle,
      description: generateDescriptionWithID(eventId),
      duration: 9000,
    }
  }
  if (!toast.isActive(defaultToastAlertProps.id as ToastId)) {
    toast(toastProps)
  }
}

const ClickToBeNotified = ({ eventId }: { eventId?: string }) => {
  const allowRegisterNotification = useAllowRegisterNotification()

  if (!allowRegisterNotification) return null
  return (
    <Text marginTop="0.5rem">
      Click <RegisterNotification {...(eventId ? { eventId } : {})}>here</RegisterNotification> to
      be notified by email once the issue is fixed.
    </Text>
  )
}

export const notifyAxiosError = (
  toast: CreateToastFnReturn,
  error: AxiosError,
  overrides?: ToastProps
) => {
  const { title, description } = getToastTitleAndDescription(error, overrides)
  const toastProps = {
    ...defaultToastAlertProps,
    ...overrides,
    render: renderChakraToastAlert({
      heading: title,
      description: description,
      status: 'warning',
    }),
  } as UseToastOptions

  if (!toast.isActive(defaultToastAlertProps.id as ToastId)) {
    toast(toastProps)
  }
}

const getToastTitleAndDescription = (error: AxiosError, overrides?: ToastProps) => {
  if (error.response) {
    const errorResponseData = error.response.data as CustomErrorResponseData
    if (error.response.status === 422) {
      // render known issue in user-readable format
      switch (errorResponseData.error.code) {
        case RecognizedError.COLUMN_LIMIT_EXCEEDED:
          return {
            title: 'Max column limit',
            description: 'You can only upload up to 1000 columns',
          }
        case RecognizedError.CLASS_LIMIT_EXCEEDED:
          return {
            title: 'Max class limit',
            description: errorResponseData.error.description,
          }
        case RecognizedError.EMPTY_DATASET_UPLOADED:
          return {
            title: 'Invalid Dataset upload -- empty Dataset',
            description: 'No data found in uploaded file.',
          }
        case RecognizedError.COLUMN_MISMATCH_DATASET_UPLOAD:
          return {
            title: 'Invalid Dataset upload -- column mismatch',
            description: 'Some column headers were missing.',
          }
        case RecognizedError.PRED_PROBS_NO_ID_COLUMN:
          return {
            title: 'Invalid predicted probabilities',
            description: 'No id column found in uploaded predicted probabilities.',
          }
        case RecognizedError.PRED_PROBS_IDS_DONT_MATCH:
          return {
            title: 'Invalid predicted probabilities',
            description: 'Predicted probability IDs do not match those of uploaded Dataset.',
          }
        case RecognizedError.PRED_PROBS_LABELS_DONT_MATCH:
          return {
            title: 'Invalid predicted probabilities',
            description: 'Predicted probability labels do not match those of uploaded Dataset.',
          }
        case RecognizedError.PRED_PROBS_RERUN_ATTEMPT:
          return {
            title: 'Invalid rerun attempt',
            description:
              'Rerunning Cleanlab is not supported for Projects with user uploaded predicted probabilities. Please contact us to discuss your use case - support@cleanlab.ai',
          }
        case RecognizedError.CANNOT_UPLOAD_DATASET_FROM_URL:
          return {
            title: 'Unable to upload Dataset',
            description:
              'Cleanlab Studio is unable upload a Dataset from the provided URL. Please check that the URL entered is correct.',
          }
        case RecognizedError.URL_DATASET_TOO_LARGE:
          return {
            title: 'Dataset too large',
            description:
              'Cleanlab Studio is unable upload a Dataset from the provided URL because the Dataset is too large or the `Content-Length` header is not provided. Please contact us if you believe this is in error - support@cleanlab.ai.',
          }
        case RecognizedError.URL_DATASET_UPLOAD_TIMEOUT:
          return {
            title: 'Dataset upload timed out',
            description:
              'Cleanlab Studio is unable upload a Dataset from the provided URL because the request timed out. Please contact us if you believe this is in error - support@cleanlab.ai.',
          }
        case RecognizedError.URL_DATASET_UNSUPPORTED_CONTENT_TYPE:
          return {
            title: 'Dataset upload format is unsupported',
            description:
              'Cleanlab Studio is unable upload a Dataset from the provided URL because the Dataset format is unsupported. Please contact us if you believe this is in error - support@cleanlab.ai.',
          }
        case RecognizedError.INCORRECT_ZIP_DATASET_FORMAT:
          return {
            title: 'Invalid Dataset upload -- incorrect ZIP file format',
            description: errorResponseData.error.description,
          }
        case RecognizedError.MEDIA_UPLOAD_FAILED:
          return {
            title: 'Media upload failed',
            description: 'Failed to upload media for Dataset. Please try again',
          }
        case RecognizedError.PROJECT_LABELS_PREFIX_NOT_UNIQUE:
          return {
            title: 'Project validation failed',
            description:
              'Project creation failed due to a failed validation check. Each unique class in your Dataset must have a unique 63-byte prefix.  Please contact us if you believe this is in error - support@cleanlab.ai.',
          }
        default:
          return {
            title: errorResponseData.error.code,
            description: errorResponseData.error.description,
          }
      }
    }
  }

  if (IS_PROD_ENV) {
    const eventId = Sentry.captureException(error)
    const productionDescription = overrides?.description
      ? overrides?.description + `(Error ID: ${eventId})`
      : generateDescriptionWithID(eventId)
    return {
      title: overrides?.title || defaultTitle,
      description: (
        <>
          <Text>{productionDescription}</Text>
          <ClickToBeNotified eventId={eventId} />
        </>
      ),
    }
  } else if (IS_VPC_ENVIRONMENT) {
    return {
      title: overrides?.title || defaultTitle,
      description: (
        <>
          <Text>Please try again later.</Text>
          <Text marginTop="0.5rem">
            If this issue persists, please contact your organization administrator.
          </Text>
        </>
      ),
    }
  } else {
    return {
      title: overrides?.title || defaultTitle,
      description: (
        <>
          <Text>Please try again later.</Text>
          <ClickToBeNotified />
        </>
      ),
    }
  }
}

export const SOFT_QUOTA_STATUS = 'user_soft_quota_exceeded'

const defaultQuotaWarning =
  'Please delete unused resources or contact us to discuss your use case - support@cleanlab.ai'

export const showQuotaWarning = (toast: CreateToastFnReturn, description?: string) => {
  toast({
    ...defaultToastAlertProps,
    render: renderChakraToastAlert({
      heading: 'Approaching Free Tier Quota',
      description: description || defaultQuotaWarning,
      status: 'caution',
    }),
  })
}
