import { IconPlus } from '@components/icons'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { TemplateProjectPreview } from '@services/templateProjects/constants'
import { cn } from '@utils/tailwindUtils'
import { upperFirst } from 'lodash'
import { ComponentProps, useCallback, useState } from 'react'

import { DEMO_CARD_MODALITIES, DemoProjectModality } from './ProjectDemo.types'
import { ProjectDemoCard } from './ProjectDemoCard'

const PAGE_SIZE = 9

const RadioTab = ({ children, ...props }: ComponentProps<typeof RadioGroupPrimitive.Item>) => (
  <RadioGroupPrimitive.Item
    className="type-button-lg cursor-pointer rounded-full bg-surface-0 px-5 py-3 text-text-faint hover:bg-surface-0-hover active:bg-surface-0-active data-[state=checked]:bg-surface-0-active  data-[state=checked]:text-text-strong dark:bg-surface-1 dark:hover:bg-surface-1-hover dark:active:bg-surface-1-active dark:data-[state=checked]:bg-surface-1-active"
    {...props}
  >
    {children}
  </RadioGroupPrimitive.Item>
)

const RadioTabGroup = ({ children, ...props }: ComponentProps<typeof RadioGroupPrimitive.Root>) => (
  <RadioGroupPrimitive.Root className="flex gap-4" {...props}>
    {children}
  </RadioGroupPrimitive.Root>
)

export const ProjectDemoSelector = ({
  variant,
  templates,
}: {
  variant: 'dashboard' | 'createProject'
  templates: TemplateProjectPreview[]
}) => {
  const [modalityFilter, setModalityFilter] = useState<DemoProjectModality | ''>('')
  const onTypeFilterChange = useCallback(
    (value: DemoProjectModality | '') => {
      setModalityFilter(value)
      if (value !== modalityFilter) {
        setNumPages(1)
      }
    },
    [modalityFilter]
  )
  const [numPages, setNumPages] = useState(1)
  const filteredTemplates = templates.filter(
    (template) =>
      modalityFilter === '' || template.modality.toLowerCase() === modalityFilter.toLowerCase()
  )
  const truncatedTemplates = filteredTemplates.slice(
    0,
    variant === 'dashboard' ? numPages * PAGE_SIZE : undefined
  )

  return (
    <div className="flex flex-col gap-6">
      <RadioTabGroup
        value={modalityFilter}
        onValueChange={onTypeFilterChange}
        aria-label="Demo Project type"
      >
        {['', ...DEMO_CARD_MODALITIES].map((modality) => (
          <RadioTab key={modality} value={modality}>
            {!modality ? 'All' : upperFirst(modality)}
          </RadioTab>
        ))}
      </RadioTabGroup>
      <div
        className={cn(
          'grid grid-cols-1 gap-8',
          variant === 'dashboard' && 'md:grid-cols-2 lg:grid-cols-3',
          variant === 'createProject' && 'lg:grid-cols-2 xl:grid-cols-3'
        )}
      >
        {truncatedTemplates.map((template) => {
          return <ProjectDemoCard key={template.id} template={template} />
        })}
      </div>
      <div className="flex justify-end">
        {truncatedTemplates.length < filteredTemplates.length && (
          <LinkBlock
            variant="secondary"
            size="medium"
            iconEnd={<IconPlus />}
            onClick={() => setNumPages((prev) => prev + 1)}
          >
            Show more
          </LinkBlock>
        )}
      </div>
    </div>
  )
}
