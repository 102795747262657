/**
 * This file tracks all events that we send to mixpanel, grouped by page.
 * Event strings generally should be written as <Action> <Target>
 * Example: Click Guide - Click is the action the user takes and Guide is the target of the action
 */
export const MixpanelEvents = {
  // color mode events
  switchToDarkMode: 'Switch to dark mode',
  switchToLightMode: 'Switch to light mode',

  // activate account events
  activateUserAccount: 'Activate User Account',

  // top bar events
  clickGuide: 'Click Guide',
  clickCleanlabGithub: 'Click Cleanlab Github',
  clickSupport: 'Click Support',
  clickProductDocsLink: 'Click Product Docs Link',
  clickGetStarted: 'Click Get Started',

  // project nav events
  clickCleansetTab: 'Click Cleanset Tab',
  clickAnalyticsTab: 'Click Analytics Tab',

  // resolver events
  resolveLabel: 'Resolve Label',
  rerunCleanlab: 'Rerun Cleanlab',
  marksNeedsReview: 'Marks Needs Review',

  // datasheet events
  activateEditModeByRowClick: 'Activate Edit Mode by Row Click',
  showPreviouslyResolved: 'Show Previously Resolved',
  hidePreviouslyResolved: 'Hide Previously Resolved',
  clickHelpResolverPanel: 'Click Help Resolver Panel',
  pressHelpKeyResolverPanel: 'Press Help Key Resolver Panel',
  clickShowColumn: 'Click Show Column',
  clickHideColumn: 'Click Hide Column',

  // analytics tab events
  hoverLabelIssuesByClassChart: 'Hover Label Issues By Class Chart',
  clickLabelIssuesByClassBar: 'Click Label Issues By Class Bar',
  hoverMostFrequentlySuggestedCorrectionsChart: 'Hover Most Frequently Suggested Corrections Chart',
  clickMostFrequentlySuggestedCorrectionsBar: 'Click Most Frequently Suggested Corrections Bar',
  hoverSuggestedCorrectionsHeatmap: 'Hover Suggested Corrections Heatmap',
  clickSuggestedCorrectionsHeatmapSquare: 'Click Suggested Corrections Heatmap Square',
  hoverLabelCorrectionsByClassChart: 'Hover Label Corrections By Class Chart',
  clickLabelCorrectionsByClassBar: 'Click Label Corrections By Class Bar',
  hoverMostFrequentCorrectionsChart: 'Hover Most Frequent Corrections Chart',
  clickMostFrequentCorrectionsBar: 'Click Most Frequent Corrections Bar',
  hoverCorrectionsHeatmap: 'Hover Corrections Heatmap',
  clickCorrectionsHeatmapSquare: 'Click Corrections Heatmap Square',
  hoverDataIssuesBarChart: 'Hover Data Issues Bar Chart',
  clickDataIssuesBarChart: 'Click Data Issues Bar Chart',
  clickModelPerformanceBarChart: 'Click Model Performance Bar Chart',
  clickModelPerformancePerClassBarChart: 'Click Model Performance Per Class Bar Chart',

  // export events
  startCleansetDownload: 'Start Cleanset Download',
  paymentSuccessful: 'Payment Successful',
  clickPricingAndPaymentButton: 'Click Pricing And Payment Button',
  clickModalExportButton: 'Click Modal Export Button',
  clickExportCleansetButton: 'Click Export Cleanset Button',
  clickAddCardButton: 'Click Add Card Button',
  clickEditCardDetailsButton: 'Click Edit Card Details Button',
  clickPayAndExportButton: 'Click Pay and Export Button',
  clickViewReceiptButton: 'Click View Receipt Button',
  clickTryDownloadingAgainButton: 'Click Try Downloading Again Button',
  clickContactUsButton: 'Click Contact Us Button',

  // dashboard events
  clickUploadDatasetButtonFromDashboard: 'Click Upload Dataset Button from Dashboard',
  clickReadyForReview: 'Click Ready For Review',

  // create project events
  createProjectComplete: 'Create Project Complete',
  clickCreateProject: 'Click Create Project',

  // beta feature events
  clickSlackCommunityLink: 'Click Slack Community Link',
  clickEmailTeamLink: 'Click Email Team Link',
  clickBetaFeatureButton: 'Click Beta Feature Button',

  // dataset upload events
  clickUploadButton: 'Click Upload Button',
  clickBrowse: 'Click Browse',
  addFilepondFile: 'Add Filepond File',
  clickEditSchema: 'Click Edit Schema',
  clickConfirmSchema: 'Click Confirm Schema',
  clickGoToDatasetDetailedView: 'Click Go To Dataset Detailed View',
  clickHowToFormatYourDataset: 'Click How To Format Your Dataset',
  clickCancel: 'Click Cancel',
  clickApiKeyCopy: 'Click API Key Copy',
  clickPythonApiLink: 'Click Python API Link',
  clickCliDocsLinks: 'Click CLI Docs',
  clickUrlUploadButton: 'Click URL Upload Button',
  clickGoToDashboard: 'Click Go To Dashboard',
  keyPressHowToFormatYourDataset: 'Key Press How To Format Your Dataset',
  clickSaveSchemaChanges: 'Click Save Schema Changes',

  // dataset events
  deleteDataset: 'Delete Dataset',

  // upload wizard events
  clickWizardMachineLearningTask: 'Click Wizard Machine Learning Task',
  clickWizardModality: 'Click Wizard Modality',
  clickWizardDatasetType: 'Click Wizard Dataset Type',
  clickWizardFileType: 'Click Wizard File Type',
  clickWizardBack: 'Click Wizard Back',
  clickWizardCancel: 'Click Wizard Cancel',
  toggleDoNotShowNextTime: 'Toggle Do Not Show Next Time',

  // model deployment events
  clickDeployModelButton: 'Click Deploy Model Button',
  clickModalDeployModelButton: 'Click Modal Deploy Model Button',
  clickSeeDetailedStatsLink: 'Click See Detailed Stats Link',
  clickViewCleansetModelDeployment: 'Click View Cleanset Model Deployment',
  clickViewModel: 'Click View Model',
  clickPredictNewLabels: 'Click Predict New Labels',
  clickDeleteModelButton: 'Click Delete Model Button',
  clickModalDeleteModelButton: 'Click Modal Delete Model Button',
  clickGridDeleteModelButton: 'Click Grid Delete Model Button',
  clickModalGridDeleteModelButton: 'Click Modal Grid Delete Model Button',
  clickModalPredictNewLabels: 'Click Modal Predict New Labels',
  filepondTestFileReady: 'Filepond Test File Ready',
  addFilepondTestFile: 'Add Filepond Test File',
  clickExportInferenceButton: 'Click Export Inference Button',
  clickDeleteInferenceButton: 'Click Delete Inference Button',
  clickModalDeleteInferenceButton: 'Click Modal Delete Inference Button',
  clickInferenceResultsTab: 'Click Inference Results Tab',
  clickModelDetailsTab: 'Click Model Details Tab',
  clickProjectLinkFromModelDetails: 'Click Project Link From Model Details',
  clickPythonInferenceLink: 'Click Python Inference Link',
  clickInferenceApiTutorialLink: 'Click Inference Api Tutorial Link',

  // batch actions events
  clickBatchActionButton: 'Click Batch Action Button',
  closeBatchActionModal: 'Close Batch Action Modal',
  applyBatchAction: 'Apply Batch Action',

  // filter events
  clickExistingFilterButton: 'Click Existing Filter Button',
  clickNewFilterButton: 'Click New Filter Button',
  clickQuickFilterButton: 'Click Quick Filter Button',
  clickClearAllFiltersButton: 'Click Clear All Filters Button',
  clickClearSingleFilterButton: 'Click Clear Single Filter Button',
  clickApplyNewFilter: 'Click Apply New Filter',
  applyExistingFilter: 'Apply Existing Filter',

  // subscription events
  openEnterpriseSalesModal: 'Open Enterprise Sales Modal',
  openUpgradePlanModal: 'Open Upgrade Plan Modal',
  clickUpgradePlanButton: 'Click Upgrade Plan Button',
  clickCancelSubscriptionButton: 'Click Cancel Subscription Button',
  clickConfirmCancellationButton: 'Click Confirm Cancellation Button',
  clickUpgradeSubscriptionButton: 'Click Upgrade Subscription Button',
}
