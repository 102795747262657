import { Heading, Image, Text, VStack } from '@chakra-ui/react'

interface ErrorMessageProps {
  error?: Error
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const REACT_APP_ENV_SETTINGS = import.meta.env.REACT_APP_ENV_SETTINGS
  const { error } = props
  // const navigate = useNavigate()
  const errorMessage = error ? error.message?.toString() || error.toString() : ''
  const isBrowserTranslateError = errorMessage.includes("Failed to execute 'removeChild' on 'Node'")
  const isProd = REACT_APP_ENV_SETTINGS === 'production'

  return (
    <VStack width="100%" height="100%" align="center" justify="center">
      <Image src="/images/error.svg" h="450px" />
      <VStack align="center" w="45%" spacing="2">
        <Heading fontSize="2xl">Something went wrong.</Heading>
        <Text fontSize="lg">Please refresh the page or try again later.</Text>

        <Text fontSize="lg">
          {isProd &&
            isBrowserTranslateError &&
            'We’re sorry, but we have noticed that the browser’s Translate Page feature can sometimes cause the application to crash. Please try another translation tool or disable the translation feature completely and see if that remedies the issue. Thank you!'}
          {isProd &&
            !isBrowserTranslateError &&
            'We have logged the error and we are working on it.'}
          {!isProd && !isBrowserTranslateError && `Error: ${errorMessage}`}
        </Text>
      </VStack>
    </VStack>
  )
}

export default ErrorMessage
