import React from 'react'

import { SvgIconProps } from '../IconProps.types'

const ExpandIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35 2.31C8.15 2.11 7.84 2.11 7.64 2.31L4.31 5.64C4.11 5.84 4.11 6.15 4.31 6.35C4.50 6.54 4.82 6.54 5.02 6.35L7.99 3.37L10.97 6.35C11.17 6.54 11.49 6.54 11.68 6.35C11.88 6.15 11.88 5.84 11.68 5.64L8.35 2.31ZM5.02 9.64C4.82 9.45 4.50 9.45 4.31 9.64C4.11 9.84 4.11 10.15 4.31 10.35L7.64 13.68C7.84 13.88 8.15 13.88 8.35 13.68L11.68 10.35C11.88 10.15 11.88 9.84 11.68 9.64C11.49 9.45 11.17 9.45 10.97 9.64L7.99 12.62L5.02 9.64Z"
        fill={color}
      />
    </svg>
  )
}

export default ExpandIcon
