import { Button as ChakraButton } from '@chakra-ui/react'
import React from 'react'

import { SecondaryButtonProps } from './SecondaryButton.types'

const SecondaryButton = (props: SecondaryButtonProps) => {
  const { children, isDisabled, isLoading, height, fontSize, ariaLabel, onClick } = props
  return (
    <ChakraButton
      height={height}
      fontSize={fontSize}
      variant="secondary"
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
    </ChakraButton>
  )
}

export default SecondaryButton
