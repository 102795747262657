import { Box, Button, Flex, HStack, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import useGridClassname from '@hooks/useGridClassname'
import { FeatureFlags } from '@services/featureFlags/constants'
import { useDisableFeatureFlagMutation } from '@services/featureFlags/mutations'
import { useFeatureFlags } from '@services/featureFlags/queries'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { useCallback, useRef, useState } from 'react'

import ConfirmDeleteDialog from '../../confirmDeleteDialog/ConfirmDeleteDialog'
import EnableFeatureFlagModal from '../enableFeatureFlagModal/EnableFeatureFlagModal'
import { columnDefs } from './FeatureFlagGrid.helpers'

const FeatureFlagGrid = () => {
  const numRowsPerPage = 20
  const toast = useToast()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const { isOpen: isEnableOpen, onOpen: onEnableOpen, onClose: onEnableClose } = useDisclosure()
  const { isOpen: isDisableOpen, onOpen: onDisableOpen, onClose: onDisableClose } = useDisclosure()
  const cancelDisableRef = useRef(null)
  const featureFlags = useFeatureFlags()
  const [selectedFeatureFlags, setSelectedFeatureFlags] = useState<FeatureFlags[]>([])
  const { mutate: disableFeatureFlag } = useDisableFeatureFlagMutation()

  const updateSelectedFeatureFlags = useCallback(() => {
    const featureFlags = gridApi?.getSelectedRows() ?? []
    if (featureFlags.length === 0) {
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Please select at least one user',
          status: 'warning',
        }),
      })
    } else {
      setSelectedFeatureFlags(featureFlags)
    }
    return featureFlags
  }, [gridApi, toast])

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
  }

  const handleClickDisable = useCallback(() => {
    const ids = updateSelectedFeatureFlags()
    if (ids.length) {
      onDisableOpen()
    }
  }, [onDisableOpen, updateSelectedFeatureFlags])

  const handleConfirmDisable = useCallback(() => {
    for (const featureFlag of selectedFeatureFlags) {
      const userId = featureFlag.userId
      const featureFlagId = featureFlag.featureFlagId
      disableFeatureFlag({ userId, featureFlagId })
    }
  }, [disableFeatureFlag, selectedFeatureFlags])

  const gridOptions: GridOptions = {
    columnDefs,
    rowSelection: 'multiple',
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    },
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  return (
    <Stack>
      <Flex justify="flex-end">
        <HStack>
          <Button colorScheme="whatsapp" size="sm" onClick={onEnableOpen}>
            Enable feature for user
          </Button>
          <Button colorScheme="red" size="sm" onClick={handleClickDisable}>
            Disable feature for user(s)
          </Button>
        </HStack>
        <ConfirmDeleteDialog
          onClose={onDisableClose}
          isOpen={isDisableOpen}
          cancelRef={cancelDisableRef}
          onConfirm={handleConfirmDisable}
          item="feature flag"
        />
      </Flex>
      <Box className={useGridClassname()} h="70vh" w="100%">
        <CleanlabGrid
          gridOptions={gridOptions}
          onGridReady={handleGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={featureFlags}
        />
      </Box>
      <EnableFeatureFlagModal isOpen={isEnableOpen} onClose={onEnableClose} />
    </Stack>
  )
}

export default FeatureFlagGrid
