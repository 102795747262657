import { Button, Grid, Heading, Text, VStack } from '@chakra-ui/react'
import queryApi from '@services/queryApi'
import React, { useState } from 'react'

import JSONQueryResults from './JSONQueryResults'
import { ControlledFormInputElement } from './QueryInterface.helpers'

const QueryInterfaceFindTask = () => {
  const [projectId, setProjectId] = useState('')
  const [cleansetId, setCleansetId] = useState('')
  const [experimentalJobId, setExperimentalJobId] = useState('')
  const [taskId, setTaskId] = useState('')
  const [queryResults, setQueryResults] = useState(null)

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Query Tasks</Heading>
      <Text>
        <strong>Instructions:</strong> Tasks can be queried using ProjectID, CleansetID,
        ExperimentalJobID, or TaskID.
      </Text>
      <Text>If a ProjectID is given, the ExperimentalJobID is ignored.</Text>
      <Text>If a CleansetID is given, the ExperimentalJobID and ProjectID are ignored.</Text>
      <Text>
        If a TaskID is given, the CleansetID, ExperimentalJobID, and ProjectID are ignored.
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <ControlledFormInputElement
          id="project_id"
          type="text"
          label="ProjectID"
          value={projectId}
          setValue={setProjectId}
        />
        <ControlledFormInputElement
          id="cleanset_id"
          type="text"
          label="CleansetID"
          value={cleansetId}
          setValue={setCleansetId}
        />
        <ControlledFormInputElement
          id="experimental_job_id"
          type="text"
          label="ExperimentalJobID"
          value={experimentalJobId}
          setValue={setExperimentalJobId}
        />
        <ControlledFormInputElement
          id="task_id"
          type="text"
          label="TaskID"
          value={taskId}
          setValue={setTaskId}
        />
      </Grid>

      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApi.query({
            resource: 'task',
            project_id: projectId,
            cleanset_id: cleansetId,
            experimental_job_id: experimentalJobId,
            task_id: taskId,
          })
          setQueryResults(response.data)
        }}
      >
        Find Task
      </Button>

      <JSONQueryResults data={queryResults} resource="tasks" />
    </VStack>
  )
}

export default QueryInterfaceFindTask
