import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEnableFeatureFlagMutation } from '@services/featureFlags/mutations'
import { useAvailableFeatureFlagIds } from '@services/featureFlags/queries'
import React, { useCallback, useState } from 'react'

import { EnableFeatureFlagModalProps } from './EnableFeatureFlagModal.types'

const EnableFeatureFlagModal = (props: EnableFeatureFlagModalProps) => {
  const { isOpen, onClose } = props
  const inputBg = useColorModeValue('gray.200', 'gray.600')

  const [userId, setUserId] = useState<string>('')
  const [featureFlagId, setFeatureFlagId] = useState<string>('')

  const { mutate: enableFeatureFlag } = useEnableFeatureFlagMutation()
  const availableFeatureFlagIds = useAvailableFeatureFlagIds()

  const handleConfirmEnable = useCallback(() => {
    enableFeatureFlag({ userId, featureFlagId })
  }, [userId, featureFlagId, enableFeatureFlag])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enable Feature Flag</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing="1.5rem">
            <FormControl>
              <FormLabel>User ID</FormLabel>
              <Input
                placeholder={userId}
                value={userId}
                onChange={(evt) => {
                  setUserId(evt.target.value)
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Feature Flag ID</FormLabel>
              <Select
                placeholder="Select feature flag"
                bg={inputBg}
                onChange={(evt) => setFeatureFlagId(evt.target.value)}
              >
                {availableFeatureFlagIds.map((featureFlagId) => (
                  <option key={featureFlagId} value={featureFlagId}>
                    {featureFlagId}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter mt={3}>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={() => {
              handleConfirmEnable()
              onClose()
            }}
          >
            Enable
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EnableFeatureFlagModal
