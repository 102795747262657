import { Text, Tooltip } from '@chakra-ui/react'
import { MaxWidthTextProps } from '@common/misc/maxWidthText/MaxWidthText.types'
import * as React from 'react'

const MaxWidthText = (props: MaxWidthTextProps) => {
  const { maxWidth, children, showLabel = true, ...textProps } = props

  const body = (
    <Text
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      maxWidth={maxWidth}
      {...textProps}
    >
      {children}
    </Text>
  )
  if (showLabel) {
    return (
      <Tooltip hasArrow label={children}>
        {body}
      </Tooltip>
    )
  } else {
    return body
  }
}

export default MaxWidthText
