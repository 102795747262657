import { Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

const ValidationError = () => {
  const red_700 = useColorModeValue('red.700', 'redDarkMode.700')
  const red_50 = useColorModeValue('red.50', 'redDarkMode.50')
  return (
    <Text w="100%" p="8px" fontSize="xs" color={red_700} bg={red_50}>
      Please complete this required field.
    </Text>
  )
}

export default ValidationError
