import { TYPOGRAPHY_PREFIX, typographyKeys } from '@assets/styles/typography'
import { type ClassValue, clsx } from 'clsx'
// eslint-disable-next-line no-restricted-imports
import { extendTailwindMerge, mergeConfigs } from 'tailwind-merge'
// eslint-disable-next-line no-restricted-imports
import { createTV } from 'tailwind-variants'

const twMergeConfig = {
  extend: {
    classGroups: {
      [TYPOGRAPHY_PREFIX]: [
        {
          [TYPOGRAPHY_PREFIX]: typographyKeys,
        },
      ],
    },
  },
}

/**
 * Customized tailwind merge function to handle typography component classes
 */
const twMerge = extendTailwindMerge((config) => mergeConfigs<string>(config, twMergeConfig))

/**
 * Customed tailwind-variants `tv` function including customized twMerge configuration
 */
export const tv = createTV({
  twMerge: true,
  twMergeConfig: twMergeConfig,
})

/**
 * Use clsx to merge classnames, then use twMerge to remove
 * overridden tailwind classes
 * @param inputs
 * @returns
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Use this identity function to wrap anything you want the prettier tailwind
 * plugin to auto sort
 */
export const tw: <T>(arg: T) => T = (arg) => {
  return arg
}
