import { Checkbox } from '@components/checkbox/Checkbox'
import { Kbd } from '@components/kbd/Kbd'
import { LabelInline } from '@components/label/LabelInline'
import { useEffect, useMemo, useState } from 'react'

import { RowData } from '../../editInterface/EditInterface.types'
import { CustomTagType } from '../CommandPalette.types'

interface PaletteHeaderProps {
  updateRowCustomTag: (tag: CustomTagType | string) => void
  handleMultiSelectAction: (action: CustomTagType | string) => void
  isMultiSelect: boolean
  selectedRowData: RowData
  showDataLabelingWorkflow: boolean
}

const PaletteHeader = (props: PaletteHeaderProps) => {
  const {
    showDataLabelingWorkflow,
    selectedRowData,
    updateRowCustomTag,
    handleMultiSelectAction,
    isMultiSelect,
  } = props

  const hasCustomTagsColumn = selectedRowData?._cleanlab_custom_tags !== undefined
  const customTagsValue = useMemo(
    () => (hasCustomTagsColumn ? selectedRowData?._cleanlab_custom_tags ?? [] : []),
    [hasCustomTagsColumn, selectedRowData?._cleanlab_custom_tags]
  )

  const [needsReviewChecked, setNeedsReviewChecked] = useState(
    customTagsValue.includes(CustomTagType.NEEDS_REVIEW)
  )

  useEffect(() => {
    setNeedsReviewChecked(customTagsValue.includes(CustomTagType.NEEDS_REVIEW))
  }, [customTagsValue, selectedRowData])

  return (
    <div className="flex w-full flex-row items-center justify-between px-5 pt-5">
      <p className="type-caption-medium h-[1rem] text-text-primary">Actions</p>
      {!showDataLabelingWorkflow && (
        <LabelInline
          content={
            <div className="flex flex-row gap-4 text-nowrap">
              <p>Needs review</p>
              <Kbd>N</Kbd>
            </div>
          }
        >
          <Checkbox
            checked={needsReviewChecked}
            onCheckedChange={() => {
              if (isMultiSelect) {
                handleMultiSelectAction(CustomTagType.MARK_NEEDS_REVIEW)
              } else {
                updateRowCustomTag(CustomTagType.NEEDS_REVIEW)
              }
              setNeedsReviewChecked(!needsReviewChecked)
            }}
            disabled={!hasCustomTagsColumn}
          />
        </LabelInline>
      )}
    </div>
  )
}

export default PaletteHeader
