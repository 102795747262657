export const getEditButtonDisabledText = (
  isDeprecatedDatasetSchema: boolean,
  mutable: boolean,
  isDatasetUsedInProject: boolean
): string | null => {
  if (isDeprecatedDatasetSchema) {
    return 'Schema edit is disabled for deprecated Dataset schemas. Please upload a new Dataset.'
  } else if (!mutable) {
    return 'Schema edit is disabled for immutable columns.'
  } else if (isDatasetUsedInProject) {
    return 'Schema edit is disabled after a Project has been created with a Dataset. Please upload a new Dataset.'
  }
  return null
}
