export interface AgilityApp {
  id: string
  name: string
  description: string
  created_at?: string
  url_suffix: string
  status?: string
  crawler_config: string
  iframe_url: string
  chat_title: string
  chat_subtitle: string
  suggested_questions: string[]
  scraped_urls?: string[] | null
  num_scraped_urls?: number | null
  is_sales_demo: boolean
  system_instructions?: string | null
  additional_system_instructions?: string | null
}

export const queryKeys = {
  apps: {
    all: () => ['apps'] as const,
  },
}
