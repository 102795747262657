import { Text, Tooltip } from '@chakra-ui/react'
import TextOverflowTooltip from '@common/textOverflowTooltip/TextOverflowTooltip'
import React from 'react'

import { SuggestedCellTagProps } from './SuggestedCellTag.types'

const SuggestedCellTag = (props: SuggestedCellTagProps) => {
  const { label, suggestExclude } = props

  if (suggestExclude || label === null) {
    return (
      <Tooltip hasArrow w="100%" label="Cleanlab suggests exluding this data point" openDelay={500}>
        <Text w="100%">-</Text>
      </Tooltip>
    )
  } else if (label !== null) {
    return <TextOverflowTooltip suppressEllipsis text={`${label}`} openDelay={500} />
  } else {
    return <Text>-</Text>
  }
}

export default SuggestedCellTag
