import { TagProps } from '@chakra-ui/react'
import { BadgeHue } from '@components/badge/Badge'

import { ActionType } from '../../../commandpalette/CommandPalette.types'

export interface ActionCellTagProps extends TagProps {
  category: ActionType
  action: ActionType
}

export const actionToHue: Record<string, BadgeHue> = {
  'auto-fix': 'green',
  exclude: 'pink',
  keep: 'purple',
  label: 'blue',
  're-label': 'blue',
  unresolved: 'neutral',
}

export const categoryDisplay: Record<string, string> = {
  'auto-fix': 'Auto-fixed',
  exclude: 'Excluded',
  keep: 'Kept',
  label: 'Corrected',
  unresolved: 'Unresolved',
  resolved: 'Resolved',
  're-label': 'Corrected',
  'mark-unresolved': 'Unresolved',
}
