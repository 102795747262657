import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import { ColumnApi, GridApi } from 'ag-grid-community'
import axios, { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { PreviousState } from '../../pages/cleanset/datasheet/lastActionWidget/LastActionWidget.types'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasheets`,
  withCredentials: true,
})

export const useMultiSelectBatchActionMutation = (gridApi: GridApi<any>) => {
  const toast = useToast()
  return useMutation({
    mutationFn: async ({
      cleansetId,
      batchAction,
      rowIds,
      label,
    }: {
      cleansetId: string
      batchAction: string
      rowIds: string[]
      label: string | number | boolean
    }) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        row_ids: rowIds,
        new_label: label,
      }
      return axiosClient.patch(
        `${cleansetId}/multi-select/${batchAction}`,
        body,
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      gridApi.refreshServerSide({})
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useBatchActionMutation = (onSuccess: VoidFunction) => {
  const toast = useToast()
  return useMutation({
    mutationFn: async ({
      cleansetId,
      batchAction,
      k,
      relabel,
      filterModel,
      sort,
    }: {
      cleansetId: string
      batchAction: string
      k: number
      relabel: string | number | boolean
      filterModel: {
        [key: string]: Record<string, unknown>
      }
      sort: ((string | null | undefined)[] | undefined)[]
    }) => {
      const accessToken = await auth.getTokenSilently()
      const body = { k, filter: filterModel || null, new_label: relabel, sort: sort }

      return axiosClient.patch(
        `${cleansetId}/batch/${batchAction}`,
        body,
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      onSuccess()
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useSetDatasheetRow = ({
  gridApi,
  onSuccess,
}: {
  gridApi: GridApi
  onSuccess?: VoidFunction
}) => {
  const toast = useToast()
  return useMutation({
    mutationFn: async ({
      cleansetId,
      rowId,
      previousState,
    }: {
      cleansetId: string | null
      rowId: string
      previousState: PreviousState
    }) => {
      if (!cleansetId) return
      const accessToken = await auth.getTokenSilently()
      await axiosClient.post(
        `${cleansetId}/${encodeURIComponent(rowId)}`,
        {
          clean_label: previousState.correctedLabel,
          action: previousState.action,
          custom_tags: previousState.tags,
        },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      gridApi.refreshServerSide({})
      onSuccess?.()
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useSaveGridLayout = ({
  cleansetId,
  columnApi,
  gridApi,
}: {
  cleansetId: string
  columnApi: ColumnApi | null
  gridApi: GridApi | null
}) => {
  return useMutation({
    mutationFn: async () => {
      const columnState = columnApi?.getColumnState()
      const filterModel = gridApi?.getFilterModel()
      localStorage.setItem(
        cleansetId,
        JSON.stringify({ columnState: columnState, filterModel: filterModel })
      )

      return 'success'
    },
  })
}
