import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { FeatureFlags, GetFeatureFlagsRes, queryKeys } from '@services/featureFlags/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useQuery } from 'react-query'

const apiVersion = 'v0'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/feature_flags`,
  withCredentials: true,
})

export const useFeatureFlags = (): FeatureFlags[] => {
  const { data } = useQuery({
    queryKey: queryKeys.featureFlags.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get<{ rows: GetFeatureFlagsRes[] }>(
        '/',
        createAuthHeaders(accessToken)
      )
      return res.data?.rows?.map((r) => ({
        id: r.id,
        userId: r.user_id,
        featureFlagId: r.feature_flag_id,
        email: r.email,
        name: r.name,
        timestamp: r.timestamp,
      }))
    },
    meta: { error: 'Failed to fetch feature flags data.' },
  })
  return data ?? []
}

export const useAvailableFeatureFlagIds = (): string[] => {
  const { data } = useQuery({
    queryKey: queryKeys.featureFlags.allAvailable(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get<{ rows: string[] }>(
        '/available',
        createAuthHeaders(accessToken)
      )
      return res.data.rows
    },
    meta: { error: 'Failed to fetch available feature flag IDs data.' },
  })
  return data ?? []
}
