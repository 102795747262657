import { useEffect, useState } from 'react'
import {
  checkIsBadgeOverflowing,
  checkIsPaletteOverflowing,
} from 'src/pages/cleanset/commandpalette/paletteSuggestion/PaletteSuggestion.helpers'

export const usePaletteView = (paletteWidth: number) => {
  const [showTallView, setShowTallView] = useState<boolean>(false)
  const [showXLView, setShowXLView] = useState<boolean>(false)
  const [paletteBreakPoint, setPaletteBreakPoint] = useState<number | null>(null)

  useEffect(() => {
    const isLabelOverflowing = checkIsPaletteOverflowing()
    const isBadgeOverflowing = checkIsBadgeOverflowing()

    // If there is overflow, show the tall view and set breakpoint
    if (isLabelOverflowing && !showTallView) {
      setShowTallView(true)
      setPaletteBreakPoint(paletteWidth)
    }
    // If resolver is resized past the breakpoint, show small view
    else if (paletteBreakPoint && paletteWidth > paletteBreakPoint && showTallView) {
      setShowTallView(false)
      setShowXLView(false)
      // If showing tall view and the badges overflow, show XL view
    } else if (showTallView && isBadgeOverflowing && !showXLView) {
      setShowXLView(true)
      // Reset to tall view if badges no longer overflow
    } else if (showXLView && !isBadgeOverflowing) {
      setShowXLView(false)
    }
  }, [paletteBreakPoint, paletteWidth, showTallView, showXLView])

  return { showTallView, showXLView }
}
