import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import queryApi from '@services/queryApi'
import React, { useState } from 'react'

import JSONQueryResults from './JSONQueryResults'
import { ControlledFormInputElement } from './QueryInterface.helpers'

const QueryInterfaceFindSchema = () => {
  const [datasetId, setDatasetId] = useState('')
  const [queryResults, setQueryResults] = useState(null)

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Query Schemas</Heading>
      <Text>
        <strong>Instructions:</strong> Dataset schemas can be queried using DatasetID.
      </Text>
      <ControlledFormInputElement
        id="dataset_id"
        type="text"
        label="DatasetID"
        value={datasetId}
        setValue={setDatasetId}
      />
      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApi.query({
            resource: 'schema',
            dataset_id: datasetId,
          })
          setQueryResults(response.data)
        }}
      >
        Find Schema
      </Button>

      <JSONQueryResults data={queryResults} resource="Schema" />
    </VStack>
  )
}

export default QueryInterfaceFindSchema
