import AgGrid from '@common/grid/AgGrid'
import { IconArrowDown, IconArrowUp, IconMenu } from '@components/icons'
import { Tooltip } from '@components/tooltip/Tooltip'
import useGridClassname from '@hooks/useGridClassname'
import { cn } from '@utils/tailwindUtils'
import { type ColDef, IHeaderParams } from 'ag-grid-community'
import { ComponentProps, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

const DashboardColumnHeader = ({
  displayName,
  enableMenu,
  tooltipLabel,
  eGridHeader: headerElt,
  progressSort,
  column,
  showColumnMenu,
  enableSorting,
}: IHeaderParams & { tooltipLabel: ReactNode }) => {
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const [sort, setSort] = useState(column.getSort())
  const showThisColumnMenu = useCallback(() => {
    if (menuButtonRef.current) {
      showColumnMenu(menuButtonRef.current)
    }
  }, [showColumnMenu])
  const [hasFocus, setHasFocus] = useState(false)
  useEffect(() => {
    const focusOut = () => setHasFocus(false)
    const focusIn = () => setHasFocus(true)
    headerElt.addEventListener('focusin', focusIn)
    headerElt.addEventListener('focusout', focusOut)
    return () => {
      headerElt.removeEventListener('focusin', focusIn)
      headerElt.removeEventListener('focusout', focusOut)
    }
  }, [headerElt])
  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (
        e.key === 'Enter' &&
        (e.ctrlKey || e.metaKey || menuButtonRef.current === document.activeElement)
      ) {
        e.preventDefault()
        showThisColumnMenu()
        return
      }
      if ((e.key === 'Enter' || e.key === ' ') && enableSorting) {
        e.preventDefault()
        progressSort()
      }
    }

    headerElt.addEventListener('keydown', keyListener)
    return () => headerElt.removeEventListener('keydown', keyListener)
  }, [headerElt, enableSorting, progressSort, showThisColumnMenu])

  useEffect(() => {
    const sortListener = () => {
      setSort(column.getSort())
    }
    column.addEventListener('sortChanged', sortListener)
    return () => column.removeEventListener('sortChanged', sortListener)
  }, [column])

  let sortIcon = null
  if (enableSorting) {
    if (sort === 'asc') {
      sortIcon = <IconArrowUp className="text-text-faint" size={12} />
    } else if (sort === 'desc') {
      sortIcon = <IconArrowDown className="text-text-faint" size={12} />
    }
  }

  return (
    <Tooltip disabled={!tooltipLabel} content={tooltipLabel}>
      <div
        onClick={(e) => {
          if (
            !enableSorting ||
            menuButtonRef.current === e.target ||
            menuButtonRef.current?.contains(e.target as Node)
          ) {
            return
          }
          progressSort()
        }}
        className="group flex h-full w-full items-center justify-between gap-3 px-[var(--ag-cell-horizontal-padding)] py-4"
      >
        <div>{displayName}</div>
        <div className="flex items-center gap-2">
          {sortIcon && <div className="s-6">{sortIcon}</div>}
          {enableMenu && (
            <button
              tabIndex={-1}
              className="s-6 group/button rounded-1 p-1 opacity-0 transition-all focus-visible:outline-0 focus-visible:ring focus-visible:ring-focus group-hover:opacity-100 group-focus:opacity-100 data-[focus-visible=true]:opacity-100"
              data-focus-visible={hasFocus}
              ref={menuButtonRef}
              onClick={showThisColumnMenu}
            >
              <IconMenu size={12} className="text-text-faint group-hover/button:text-text-strong" />
            </button>
          )}
        </div>
      </div>
    </Tooltip>
  )
}

export const DashboardGrid = <TData = any,>({
  className,
  ...props
}: ComponentProps<typeof AgGrid<TData>>) => {
  const gridClassName = useGridClassname()

  return (
    <div
      className={cn(
        gridClassName,
        'h-full w-full [--ag-cell-horizontal-padding:theme(space.6)]',
        className
      )}
      data-grid-theme="dashboard"
    >
      <AgGrid<TData>
        suppressCellFocus={false}
        suppressScrollOnNewData={true}
        stopEditingWhenCellsLoseFocus={true}
        {...props}
      />
    </div>
  )
}

export const DASHBOARD_DEFAULT_COLUMN_DEF = {
  headerComponent: DashboardColumnHeader,
  filter: true,
  sortable: true,
  resizable: false,
  suppressMenu: false,
  suppressMovable: true,
  cellClass: 'type-caption text-text-primary flex items-center',
  headerClass:
    'p-0 type-caption-medium text-text-strong bg-surface-1 hover:bg-surface-1-hover [&_.ag-cell-label-container]:p-0',
} as const satisfies ColDef
