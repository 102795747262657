import { Center, Flex, HStack, Icon, Image, Spinner, VStack } from '@chakra-ui/react'
import { Badge } from '@components/badge/Badge'
import { useTopImageExamples } from '@services/datasheet/queries'
import { useMemo, useState } from 'react'
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'

import { useParamsCleansetId } from '../../Cleanset.helpers'
import { LabelType } from '../EditInterface.types'

export const ExampleImage = ({ src }: { src: string }) => {
  const [isSingleImageLoading, setIsSingleImageLoading] = useState(true)

  return (
    <Flex w="50%" h="100%" justify="center" align="center">
      {isSingleImageLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      <Image
        display={isSingleImageLoading ? 'none' : 'flex'}
        rounded="md"
        w="100%"
        h="100%"
        objectFit="contain"
        src={src}
        onLoad={() => setIsSingleImageLoading(false)}
      />
    </Flex>
  )
}

export const GivenLabelExampleImages = ({ givenLabel }: { givenLabel: LabelType }) => {
  const cleansetId = useParamsCleansetId()
  const { data, isLoading } = useTopImageExamples({
    cleansetId,
    label: givenLabel.toString(),
  })

  const givenLabelExamples = useMemo(() => {
    if (data !== undefined) {
      return data.urls
    } else {
      return []
    }
  }, [data])

  return (
    <VStack justify="center" align="space-between" w="45%" h="80%" spacing={1}>
      <VStack spacing={1} w="full" h="45px">
        <Badge
          className="max-w-full overflow-hidden text-ellipsis text-nowrap"
          round="small"
          size="medium"
          variant="actionSubtle"
          hue="purple"
        >
          {givenLabel}
        </Badge>
        <p className="type-caption-medium">more examples</p>
      </VStack>
      <VStack w="full" h="calc(100% - 45px)">
        {isLoading && (
          <Center h="100%">
            <Spinner />
          </Center>
        )}
        {!isLoading && (
          <>
            <HStack w="full" h="50%" justify="center">
              {givenLabelExamples.slice(2, 4).map((src) => (
                <ExampleImage src={src} key={src} />
              ))}
            </HStack>
            <HStack w="full" h="50%" justify="center">
              {givenLabelExamples.slice(0, 2).map((src) => (
                <ExampleImage src={src} key={src} />
              ))}
            </HStack>
          </>
        )}
      </VStack>
    </VStack>
  )
}

export const NonGivenLabelExampleImages = ({
  suggestedLabel,
  shownLabelIndex,
  stepShownLabelIndex,
  nonGivenLabels,
}: {
  suggestedLabel: LabelType | null
  shownLabelIndex: number
  stepShownLabelIndex: (increment: 1 | -1) => void
  nonGivenLabels: string[]
}) => {
  const nonGivenLabel = nonGivenLabels[shownLabelIndex]
  const suggestedLabelShown = nonGivenLabel === suggestedLabel?.toString()

  const cleansetId = useParamsCleansetId()
  const { data, isLoading } = useTopImageExamples({
    cleansetId,
    label: nonGivenLabels[shownLabelIndex].toString(),
  })

  const nonGivenLabelExamples = useMemo(() => {
    if (data !== undefined) {
      return data.urls
    } else {
      return []
    }
  }, [data])

  return (
    <VStack justify="center" align="space-between" w="45%" h="80%" spacing={1}>
      <HStack justify="space-between" w="100%" h="45px">
        <Icon as={AiFillCaretLeft} cursor="pointer" onClick={() => stepShownLabelIndex(-1)} />
        <VStack spacing={1} w="80%">
          <Badge
            className="w-max-full overflow-hidden text-ellipsis text-nowrap"
            round="small"
            size="medium"
            variant="actionSubtle"
            hue={suggestedLabelShown ? 'green' : 'blue'}
          >
            {nonGivenLabel}
          </Badge>
          <p className="type-caption-medium">other examples</p>
        </VStack>
        <Icon as={AiFillCaretRight} cursor="pointer" onClick={() => stepShownLabelIndex(1)} />
      </HStack>
      <VStack h="calc(100% - 45px)" align="center">
        {isLoading && (
          <Center h="100%">
            <Spinner />
          </Center>
        )}
        {!isLoading && (
          <>
            <HStack w="full" justify="center" h="50%">
              {nonGivenLabelExamples.slice(2, 4).map((src) => (
                <ExampleImage src={src} key={src} />
              ))}
            </HStack>
            <HStack w="full" justify="center" h="50%">
              {nonGivenLabelExamples.slice(0, 2).map((src) => (
                <ExampleImage src={src} key={src} />
              ))}
            </HStack>
          </>
        )}
      </VStack>
    </VStack>
  )
}
