import { Button } from '@components/button/Button'
import { IconArrowDown, IconArrowUp } from '@components/icons'
import { memo, useState } from 'react'

export interface ErrorMessageProps {
  errorMessage: string
  defaultHidden?: boolean
}

export const ErrorMessage = memo(({ errorMessage, defaultHidden = false }: ErrorMessageProps) => {
  const [hidden, setHidden] = useState(defaultHidden)

  return (
    <div className="flex flex-col gap-5">
      {!hidden && (
        <div className="type-code-100 flex max-h-[160px] flex-col gap-4 overflow-auto rounded-1 border border-border-1 bg-surface-0 p-4 text-text-primary">
          {errorMessage.split('\n').map((line) => (
            <p key={line}>{line}</p>
          ))}
        </div>
      )}
      {defaultHidden && (
        <div>
          <Button
            iconEnd={hidden ? <IconArrowDown /> : <IconArrowUp />}
            size="small"
            variant="secondary"
            onClick={() => setHidden((h) => !h)}
          >
            {hidden ? 'Show' : 'Hide'} error
          </Button>
        </div>
      )}
    </div>
  )
})
