export const CLEANSET_PRODUCT_TOUR_ID = 541113
export const DASHBOARD_TOUR_ID = 541106

export const getIntercomTourId = (path: string): number => {
  if (path.includes('Cleansets')) {
    return CLEANSET_PRODUCT_TOUR_ID
  } else {
    return DASHBOARD_TOUR_ID
  }
}

export const getShowTourButton = (path: string, isDataLabeling: boolean): boolean => {
  if (isDataLabeling) {
    return false
  } else if (path.includes('Cleansets') || path.includes('Home')) {
    return true
  } else {
    return false
  }
}
