import { CreateToastFnReturn } from '@chakra-ui/react'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { ProjectRowRes } from '@services/project/constants'
import logger from '@utils/logger'
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { SortModelItem } from 'ag-grid-community/dist/lib/sortController'
import { AxiosError } from 'axios'
import { queryClient } from 'src/queryClient'

import { projectsQueryOptions } from './project/queries'

const getProjects = async (
  startRow: number,
  endRow: number,
  sortModel?: SortModelItem[],
  filterModel?: { [key: string]: Record<string, unknown> }
) => {
  try {
    return await queryClient.fetchQuery({
      ...projectsQueryOptions(startRow, endRow, sortModel, filterModel),
    })
  } catch {
    return { row_count: 0, rows: [] as ProjectRowRes[] }
  }
}

export interface ServerType {
  getData: (request: IServerSideGetRowsRequest) => Promise<{
    rows: any[]
    row_count: number
  }>
}

const createServer = () => {
  return {
    getData: async (request: IServerSideGetRowsRequest) => {
      const startRow = request.startRow
      const endRow = request.endRow
      const sortModel = request.sortModel
      const filterModel = request.filterModel
      const res = (await getProjects(startRow || 0, endRow || 0, sortModel, filterModel)) ?? []
      return res
    },
  } as const satisfies ServerType
}

export const createServerSideDatasource = (
  server: ServerType,
  toast: CreateToastFnReturn,
  setInitialized: VoidFunction,
  setIsFetchingRows?: (isFetchingRows: boolean) => void,
  isEnrichment?: boolean
): IServerSideDatasource => {
  logger.info('creating server side datasource from', server)

  return {
    getRows: function (params: IServerSideGetRowsParams) {
      logger.info(
        '[Datasource] - rows requested by grid: startRow = ' +
          params.request.startRow +
          ', endRow = ' +
          params.request.endRow
      )
      const fetchData = async () => {
        setIsFetchingRows?.(true)
        try {
          const data = await server.getData(params.request)
          const projectData = data.rows ?? []
          const projectCount = data.row_count ?? 0

          if (isEnrichment) {
            for (const project of projectData) {
              project.isEnrichment = true
            }
          }

          params.success({
            rowData: projectData,
            rowCount: projectCount,
          })

          setInitialized()
        } catch (err) {
          console.error('createServerSideDataSource getData() failed', err)
          notifyAxiosError(toast, err as AxiosError)
          params.fail()
        }
        setIsFetchingRows?.(false)
      }
      fetchData()
    },
  }
}

const apiMethods = {
  createServer,
  createServerSideDatasource,
}

export default apiMethods
