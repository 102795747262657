import { Box, Center, Spinner, VStack } from '@chakra-ui/react'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { useCurrentPeriodUsage } from '@services/billing/queries'
import React from 'react'

import PaymentHistory from './paymentHistory/PaymentHistory'
import PaymentDetails from './paymentSetup/cardDetails/PaymentDetails'
import PaymentSetup from './paymentSetup/PaymentSetup'
import PlanDetails from './planDetails/PlanDetails'

const Payment = () => {
  const {
    isEnterprisePlan,
    freeTrialPeriod,
    isLoading: isSubscriptionDataLoading,
  } = useSubscription()

  const { isLoading: isCurrentPeriodUsageLoading } = useCurrentPeriodUsage()

  if (isSubscriptionDataLoading || isCurrentPeriodUsageLoading) {
    return (
      <Center h="75vh" w="100%">
        <Spinner size="xl" />
      </Center>
    )
  }

  return (
    <VStack align="flex-start">
      <PlanDetails />
      {!isEnterprisePlan && !freeTrialPeriod && (
        <>
          <Box pt={6}>
            <PaymentSetup />
          </Box>
          <Box pt={6} w="100%">
            <PaymentDetails />
          </Box>
          <PaymentHistory />
        </>
      )}
    </VStack>
  )
}

export default Payment
