import { InputApiKey } from '@common/apiKey/InputApiKey'
import { FormField } from '@components/formField/FormField'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useApiKey } from '@services/commandLine/queries'
import {
  CreateProjectContainer,
  CreateProjectContent,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  WithSidebarContainer,
} from 'src/pages/projectForm/CreateProject.helpers'
import { InlineLink } from 'src/pages/projectForm/Sidebar'

import { UploadType } from '../DatasetUpload.types'
import { DatasetUploadSidebarStandard } from '../DatasetUploadSidebar'
import { UploadBackLink } from '../UploadBackLink'

export const ApiKeyText = () => (
  <>
    Your personal API key is shown below (Do not share this!). You may also generate a new API key
    in your <InlineLink to="/account?tab=General">Account page</InlineLink>.
  </>
)

const PythonApiUpload = () => {
  const { trackEvent } = useEventTracking()
  const { apiKey } = useApiKey()

  return (
    <CreateProjectContainer>
      <UploadBackLink />
      <WithSidebarContainer>
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Import via Python API</PageHeading>
            <PageDescription>
              We offer a{' '}
              <InlineLink
                to="https://help.cleanlab.ai/guide/quickstart/api"
                onClick={() => trackEvent(MixpanelEvents.clickPythonApiLink)}
              >
                Python API
              </InlineLink>{' '}
              for uploading Datasets. <ApiKeyText />
            </PageDescription>
          </HeadingWrapper>
          <div className="flex flex-col gap-8">
            <FormField label="API key">
              <InputApiKey
                value={apiKey}
                onClickCopy={() =>
                  trackEvent(MixpanelEvents.clickApiKeyCopy, { uploadType: UploadType.commandLine })
                }
              />
            </FormField>
          </div>
        </CreateProjectContent>
        <DatasetUploadSidebarStandard />
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}

export { PythonApiUpload }
