import { Code } from '@chakra-ui/react'
import React from 'react'

export const getIssueTagTooltipBody = (issue: string, tooltipBg: string, tooltipColor: string) => {
  switch (issue) {
    case 'label_issue': {
      return (
        <>
          <strong>Label Issue</strong>
          <br />
          This data point is potentially mislabeled. The{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Suggested
          </Code>{' '}
          column shows the most likely alternative label.
        </>
      )
    }
    case 'outlier': {
      return (
        <>
          <strong>Outlier</strong>
          <br />
          This data point is significantly different from other data and may be an outlier. Such
          data might be appropriate to exclude from the Dataset.
        </>
      )
    }
    case 'unlabeled': {
      return (
        <>
          <strong>Unlabeled</strong>
          <br />
          This data point is unlabeled. The{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Suggested
          </Code>{' '}
          column shows the most likely label for this data point.
        </>
      )
    }
    case 'ambiguous': {
      return (
        <>
          <strong>Ambiguous</strong>
          <br />
          This data point may be ambiguous and is not well-described by any of the classes in this
          Dataset. Such data might be appropriate to exclude from the Dataset.
        </>
      )
    }
    case 'near_duplicate': {
      return (
        <>
          <strong>Near Duplicate</strong>
          <br />
          The Dataset contains at least one other data point that is identical or near-identical to
          this data point. These data points have been given the same{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Near Duplicate Cluster ID
          </Code>{' '}
          value. Duplicates might be appropriate to exclude from the Dataset.
        </>
      )
    }
    default: {
      return ''
    }
  }
}
