import {
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import React, { useState } from 'react'
import { FiInfo } from 'react-icons/fi'

import DeploymentUpload from '../deploymentUpload/DeploymentUpload'
import { PredictNewLabelsModalProps } from './PredictNewLabelsModal.types'

const PredictNewLabelsModal = (props: PredictNewLabelsModalProps) => {
  const { isOpen, onClose, handlePredictNewLabels, files, setFiles, isLoading } = props

  const [fileReady, setFileReady] = useState(false)

  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent bg={modalBg} w="100%">
        <ModalHeader>Predict labels for new test data</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="md" m={2} w="100%">
          <HStack>
            <Text>
              Upload new test data to predict target labels using Cleanlab Studio’s trained model.
              Currently, only .csv files are supported.
            </Text>
            <Tooltip
              label="Predict new labels for test data. The new test data is required to have the same columns as the training Dataset, with only the inclusion of the label column being optional."
              fontSize="sm"
            >
              <Box>
                <Icon as={FiInfo}></Icon>
              </Box>
            </Tooltip>
          </HStack>
          <DeploymentUpload
            files={files}
            setFiles={setFiles}
            setFileReady={() => {
              setFileReady(true)
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} variant="ghost" onClick={onClose} aria-label="cancel deletion">
            Cancel
          </Button>
          <PrimaryButton
            height="40px"
            onClick={async () => {
              handlePredictNewLabels()
              setFiles([])
              setFileReady(false)
            }}
            isDisabled={!fileReady || files.length === 0}
            isLoading={isLoading}
            aria-label="predict-new-labels"
          >
            Predict New Labels
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PredictNewLabelsModal
