import { ProjectDetailsProps } from '../datasheet/Datasheet.types'
import { LabelType } from '../editInterface/EditInterface.types'

export interface CommandPaletteProps {
  labels: string[]
  givenLabel: string
  suggestedLabel: string
  labelToProba: Record<string, number>
  updateRowAction: (action: ActionType | string, errorCallback?: VoidFunction) => void
  handleMultiSelectAction: (action: ActionType | string, label?: string | number | boolean) => void
  updateRowCustomTag: (tag: CustomTagType | string) => void
  isLabelIssue: boolean
  isUnlabeled: boolean
  selectedRowIndex: number
  suggestExclude: boolean
  suggestedAction: ActionType
  taskType: string
  projectDetails: ProjectDetailsProps
  correctedLabel: string | null
  selectedRows: any[]
  isLoading: boolean
  isActionLoading: boolean
  resolverWidth: number
  suggestedLabelDisplay: string
  isFetchingRows: boolean
  showDataLabelingWorkflow?: boolean
  givenLabelType: LabelType | null
  currentRowAction: string | null
  autoAdvance: boolean
  newlyAddedLabels: string[]
}

export enum ActionType {
  AUTO_FIX = 'auto-fix',
  KEEP = 'keep',
  RELABEL = 're-label',
  EXCLUDE = 'exclude',
  UNRESOLVED = 'unresolved',
  ADD_TAG_LABEL = 'add-tag-label',
  REMOVE_TAG_LABEL = 'remove-tag-label',
  REMOVE_NEEDS_REVIEW = 'remove needs_review',
  LABEL = 'relabel',
  ADD_LABEL = 'add-label',
  MARK_UNRESOLVED = 'mark-unresolved',
}

export enum CustomTagType {
  NEEDS_REVIEW = 'needs_review',
  UNLABELED = 'unlabeled',
  MARK_NEEDS_REVIEW = 'mark-needs-review',
}

export interface CommandProps {
  name: string
  category: string
  shortcut?: string | null
  proba?: number
  command: VoidFunction
}
