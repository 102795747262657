import { FormControl, FormLabel, useColorModeValue, useFormControlContext } from '@chakra-ui/react'
import { CopySnippetProps } from '@common/misc/copySnippet/CopySnippetV1.types'
import { Input } from '@components/input/Input'
import { ComponentProps, forwardRef } from 'react'

const InputWrap = forwardRef((props: ComponentProps<typeof Input>, ref: any) => {
  const ctx = useFormControlContext()
  return <Input ref={ref} id={ctx.id} {...props} />
})

const CopySnippet = ({
  label,
  trackCopyEvent,
  snippet,
  isExternalCopy,
  canHide,
  extraRightElements,
  ...props
}: CopySnippetProps) => {
  const textColor = useColorModeValue('neutral.900', 'neutralDarkMode.900')

  return (
    <FormControl>
      <FormLabel fontSize="md" color={textColor}>
        {label}
      </FormLabel>
      <InputWrap
        size="large"
        variant="monospace"
        value={snippet}
        canHide={canHide}
        defaultHidden={true}
        contentEnd={extraRightElements}
        canCopy={!isExternalCopy}
        onClickCopy={() => trackCopyEvent?.()}
        {...props}
      />
    </FormControl>
  )
}

export default CopySnippet
