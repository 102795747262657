import { Box, Flex, Stack } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import useGridClassname from '@hooks/useGridClassname'
import { useUsers } from '@services/user/queries'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import { useMemo, useState } from 'react'

import AdminButtonPanel from '../../adminButtonPanel/AdminButtonPanel'
import NotificationsPanel from '../notificationsPanel/NotificationsPanel'

const UserDataGrid = () => {
  const numRowsPerPage = 20
  const users = useUsers()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const timeFormatter = (params: ValueFormatterParams) => {
    if (params.value) {
      return timestampToDate(params.value)
    } else {
      return ''
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        pinned: 'left',
        checkboxSelection: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
      },
      { field: 'email', filter: 'agTextColumnFilter' },
      {
        field: 'name',
        headerName: 'Name',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'account_activated',
        headerName: 'Account activated',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'account_activated_at',
        headerName: 'Account activated at',
        cellRenderer: 'timeFormatter',
        filter: 'agDateColumnFilter',
      },
      {
        field: 'email_confirmed',
        headerName: 'Email confirmed',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'api_key',
        headerName: 'API key',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'api_key_timestamp',
        headerName: 'API key timestamp',
        cellRenderer: 'timeFormatter',
        filter: 'agDateColumnFilter',
      },
      {
        field: 'access_level',
        headerName: 'Access level',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'phone_number',
        headerName: 'Phone number',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'signup_origin',
        headerName: 'Signup origin',
        filter: 'agSetColumnFilter',
      },
      {
        field: 'account_status',
        headerName: 'Account status',
        filter: 'agSetColumnFilter',
      },
    ],
    []
  )

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
  }

  const gridOptions: GridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'multiple',
    components: {
      timeFormatter,
    },
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    },
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  return (
    <Stack>
      <Flex justify="flex-end">{gridApi && <AdminButtonPanel gridApi={gridApi} />}</Flex>
      <NotificationsPanel />
      <Box className={useGridClassname()} h="70vh" w="100%">
        <CleanlabGrid
          gridOptions={gridOptions}
          onGridReady={handleGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={users}
          suppressCopyRowsToClipboard
        />
      </Box>
    </Stack>
  )
}

export default UserDataGrid
