import {
  DeleteRowModalProps,
  DeletionType,
} from '@common/modals/deleteRowModal/DeleteRowModal.types'
import { ModalAlertAction, ModalAlertCancel } from '@components/modal/ModalAlert'
import { ModalAlertBasic } from '@components/modal/ModalAlertBasic'
import { memo } from 'react'

const DeleteTypeDescription = memo(
  ({ deletionType, numCleansets }: { deletionType: DeletionType; numCleansets?: number }) => {
    const cleansetStart = (
      <>You are about to permanently delete this Cleanset and all of its data.</>
    )
    switch (deletionType) {
      case 'dataset':
        return (
          <p>
            You are about to permanently delete this Dataset and all of its data. Projects based on
            this Dataset will also be deleted.
          </p>
        )
      case 'deployment':
        return (
          <p>
            You are about to delete this Model and all of its data. Predictions generated from this
            Model will also be deleted.
          </p>
        )
      case 'inference':
        return <p>You are about to permanently delete this Inference and all of its data.</p>
      case 'project':
        return (
          <p>
            You are about to permanently delete this Project and all of its data. The Dataset this
            Project was created from will be unaffected.
          </p>
        )
      case 'cleanset':
        if (numCleansets === 1) {
          return (
            <p>
              {cleansetStart} As the parent Project contains only this Cleanset, the parent Project
              will also be deleted.
            </p>
          )
        }
        return (
          <p>
            {cleansetStart} The Dataset and Project associated with this Cleanset will be
            unaffected.
          </p>
        )
      case 'report':
        return (
          <p>
            You are about to permanently delete this report and all of its data. The Project and
            test Dataset this report was generated from will be unaffected.
          </p>
        )
      default:
        return null
    }
  }
)

const DeleteRowModal = ({
  isOpen,
  onClose,
  rowData,
  handleDeleteButtonClicked,
  deletionType,
  numCleansets,
  size = 'medium',
}: DeleteRowModalProps) => {
  return (
    rowData && (
      <ModalAlertBasic
        size={size}
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) onClose?.()
        }}
        title={`Delete ${rowData.name}?`}
        description={
          <>
            <DeleteTypeDescription {...{ deletionType, numCleansets }} />
            <p>Do you wish to proceed?</p>
          </>
        }
        footerContent={
          <>
            <ModalAlertAction variant="critical" onClick={() => handleDeleteButtonClicked(rowData)}>
              Delete
            </ModalAlertAction>
            <ModalAlertCancel>Cancel</ModalAlertCancel>
          </>
        }
      />
    )
  )
}

export default DeleteRowModal
