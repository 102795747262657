import { Slide } from '@chakra-ui/react'
import { IconMoreVerticalDouble } from '@components/icons/IconMoreVerticalDouble'
import { useSubscription } from '@providers/billing/SubscriptionProvider'

interface ResolverSlideWrapperProps {
  children: React.ReactNode
  editMode: boolean
  resolverRef: React.RefObject<HTMLDivElement>
  componentWidth: number
  maxWidth: string
  setResolverWidth: React.Dispatch<React.SetStateAction<number>>
  resizeComponent: () => void
}

const ResolverSlideWrapper = (props: ResolverSlideWrapperProps) => {
  const {
    children,
    editMode,
    resolverRef,
    componentWidth,
    maxWidth,
    resizeComponent,
    setResolverWidth,
  } = props

  const { showNotificationBanner } = useSubscription()

  return (
    <Slide
      className="cleanset-tour-resolver-panel shadow-elev-4"
      direction="right"
      in={editMode}
      ref={resolverRef}
      transition={{ enter: { duration: 0.3 } }}
      // on opening animation start, set resolver width to 0 to trigger auto-size
      onAnimationStart={() => {
        if (editMode) {
          setResolverWidth(0)
        }
      }}
      style={{
        width: componentWidth || 'fit-content',
        height: '100%',
        minWidth: '30%',
        maxWidth: maxWidth,
        marginTop: `${showNotificationBanner ? '212px' : '160px'}`,
        borderRadius: '16px',
        zIndex: 9,
      }}
    >
      {editMode && (
        <button
          className="absolute left-[-24px] top-[37%] z-10 flex h-[33px] w-[24px] cursor-ew-resize items-center justify-center rounded-bl-2 rounded-tl-2 border-border-1 bg-surface-1 shadow-elev-4"
          onMouseDown={(evt) => {
            evt.preventDefault()
            resizeComponent()
          }}
        >
          <IconMoreVerticalDouble />
        </button>
      )}
      {children}
    </Slide>
  )
}

export default ResolverSlideWrapper
