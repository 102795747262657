import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react'
import IconButton from '@components/iconButton/IconButton'
import React from 'react'
import { FiMaximize2 } from 'react-icons/fi'

import { ChartCardContent } from '../chartCard/ChartCardContent'
import { ChartModalProps } from './ChartModal.types'

export const ChartModal = (props: ChartModalProps) => {
  const { children, title, bottomLegendTitle, description, onOpen, onClose, isOpen } = props
  const iconColor = useColorModeValue('neutral.700', 'neutralDarkMode.700')

  const backgroundColor = useColorModeValue('neutral.50', 'neutralDarkMode.50')

  return (
    <>
      <IconButton
        ariaLabel="expand-chart"
        minWidth="32px"
        tooltipLabel="Expand chart"
        icon={<FiMaximize2 size={16} />}
        iconColor={iconColor}
        size="32px"
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent minWidth="70%" background={backgroundColor} width="80%">
          <ModalBody flexGrow={1} minH="35vh" padding="2rem">
            <ChartCardContent
              description={description}
              title={title}
              children={children}
              isInModal={true}
              bottomLegendTitle={bottomLegendTitle}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  )
}
