import datasheetApiService from '@services/datasheetApi'
import { GridApi } from 'ag-grid-community'

import { RowData } from '../../dataLabelingResolver/DataLabelingResolver.types'
import { NUM_ROWS_PER_PAGE } from '../Datasheet.types'
import { LastActionState } from '../lastActionWidget/LastActionWidget.types'

interface RowActionProps {
  gridApi: GridApi
  projectDetails: any
  currPageNum: number
  lastRowNum: number
  setCurrPageNum: (currPageNum: number) => void
  fetchAndUpdateCurrRowData: (selectedRowIndex: number, retry?: number) => void
  setLastActionState: (lastActionState: LastActionState | null) => void
  cleansetId: string
  autoAdvance: boolean
}

export const updateRowAction = async (action: string, props: RowActionProps) => {
  const {
    gridApi,
    projectDetails,
    lastRowNum,
    cleansetId,
    currPageNum,
    setCurrPageNum,
    setLastActionState,
    fetchAndUpdateCurrRowData,
    autoAdvance,
  } = props
  const selectedNode = gridApi.getSelectedNodes()[0]
  const selectedRowData = selectedNode.data as RowData
  const selectedRowIndex = selectedNode.rowIndex ?? 0
  // Find next target node before applying action. If action is successful,
  // we select the next node after.
  const nextRowNode = gridApi.getDisplayedRowAtIndex(selectedRowIndex + 1)
  const selectedRowId = selectedRowData[projectDetails.idColumn] as string
  await datasheetApiService.updateRowAction(cleansetId, selectedRowId, action)
  if (selectedRowIndex < lastRowNum && autoAdvance) {
    // Need to deselect previous row and select next row with multi-select enabled
    const pageNum = Math.floor((selectedRowIndex + 1) / NUM_ROWS_PER_PAGE)
    selectedNode.setSelected(false)
    // Check if this action will result in jump to next page. If so, we need to ensure
    // that the next node to select has rendered.
    if (pageNum !== currPageNum) {
      gridApi?.deselectAll()
      gridApi?.paginationGoToPage(pageNum)
      setCurrPageNum(pageNum)
      fetchAndUpdateCurrRowData(selectedRowIndex + 1)
    } else {
      nextRowNode?.setSelected(true)
    }
    setLastActionState({
      rowIdx: (selectedNode.rowIndex ?? 0) + 1,
      rowId: selectedRowId,
      type: action,
      previousState: {
        action: selectedRowData._cleanlab_action,
        tags: selectedRowData._cleanlab_custom_tags,
        correctedLabel: selectedRowData._cleanlab_clean_label,
      },
      undone: false,
    })
  }
}

export const updateRowCustomTag = async (tag: string, props: RowActionProps) => {
  const {
    gridApi,
    projectDetails,
    lastRowNum,
    cleansetId,
    currPageNum,
    setCurrPageNum,
    setLastActionState,
    fetchAndUpdateCurrRowData,
    autoAdvance,
  } = props

  const selectedNode = gridApi.getSelectedNodes()[0]
  const selectedRowIndex = selectedNode.rowIndex ?? 0
  const selectedRowData = selectedNode.data as RowData
  const selectedRowId = selectedRowData?.[projectDetails.idColumn] as string
  const isTagRemoval = (selectedRowData?._cleanlab_custom_tags ?? []).includes(tag)
  const nextRowNode = gridApi.getDisplayedRowAtIndex(selectedRowIndex + 1)
  await datasheetApiService.updateRowCustomTag(cleansetId, selectedRowId, tag)
  if (selectedRowIndex < lastRowNum && autoAdvance) {
    // Need to deselect previous row and select next row with multi-select enabled
    selectedNode.setSelected(false)
    const pageNum = Math.floor((selectedRowIndex + 1) / NUM_ROWS_PER_PAGE)
    selectedNode.setSelected(false)
    // Check if this action will result in jump to next page. If so, we need to ensure
    // that the next node to select has rendered.
    if (pageNum !== currPageNum) {
      gridApi?.deselectAll()
      gridApi?.paginationGoToPage(pageNum)
      setCurrPageNum(pageNum)
      fetchAndUpdateCurrRowData(selectedRowIndex + 1)
    } else {
      nextRowNode?.setSelected(true)
    }
  }
  setLastActionState({
    rowIdx: (selectedNode.rowIndex ?? 0) + 1,
    rowId: selectedRowId,
    type: isTagRemoval ? 'remove ' + tag : tag,
    previousState: {
      action: selectedRowData._cleanlab_action,
      tags: selectedRowData._cleanlab_custom_tags,
      correctedLabel: selectedRowData._cleanlab_clean_label,
    },
    undone: false,
  })
}
