import Amex from '@assets/images/credit_cards/amex.png'
import DinersClub from '@assets/images/credit_cards/diners_club.png'
import Discover from '@assets/images/credit_cards/discover.png'
import JCB from '@assets/images/credit_cards/jcb.png'
import MasterCard from '@assets/images/credit_cards/mastercard.png'
import UnionPay from '@assets/images/credit_cards/unionpay.png'
import Visa from '@assets/images/credit_cards/visa.png'

const getCreditCardImage = (cardBrand: string) => {
  switch (cardBrand) {
    case 'amex': {
      return Amex
    }
    case 'diners': {
      return DinersClub
    }
    case 'discover': {
      return Discover
    }
    case 'mastercard': {
      return MasterCard
    }
    case 'visa': {
      return Visa
    }
    case 'jcb': {
      return JCB
    }
    case 'unionpay': {
      return UnionPay
    }
    default: {
      return ''
    }
  }
}

export { getCreditCardImage }
