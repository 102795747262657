import {
  createContext,
  Dispatch,
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

const TruncatorContext = createContext<{
  hiddenCount: number
  setHiddenCount: Dispatch<SetStateAction<number>>
} | null>(null)

export const TruncatorContextProvider = ({ children }: { children: ReactNode }) => {
  const [hiddenCount, setHiddenCount] = useState(0)
  const value = useMemo(() => ({ hiddenCount, setHiddenCount }), [hiddenCount])
  return <TruncatorContext.Provider value={value}>{children}</TruncatorContext.Provider>
}

export const useTruncatorContext = () => {
  const ctx = useContext(TruncatorContext)
  if (!ctx) {
    throw new Error('useTruncatorContext must be used within a TruncatorContextProvider')
  }
  return ctx
}

export const TruncatorMoreCount = memo(
  forwardRef((_, ref: ForwardedRef<HTMLSpanElement>) => {
    const { hiddenCount } = useTruncatorContext()

    return (
      <span ref={ref} data-more-indicator-count>
        {hiddenCount}
      </span>
    )
  })
)
