import { Button, Menu, MenuButton, useDisclosure } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { ColDef } from 'ag-grid-community'
import { useContext, useEffect, useState } from 'react'
import { FiPlusCircle } from 'react-icons/fi'

import { ColumnStatus } from '../../Cleanset.types'
import { CleansetContext } from '../../CleansetContext'
import { EnabledCleanlabColumnConfigs } from '../../datasheet/columnConfigs/ColumnConfigs'
import { getBackendColName, getColumnStatus } from '../../datasheet/Datasheet.helpers'
import { CLEANLAB_FRONTEND_COLUMN } from '../../datasheet/Datasheet.types'
import { PresetHeaders } from '../../filterReducer/FilterState.types'
import AddNewFilterMenu from '../editFilterMenu/AddNewFilterMenu'
import { AddNewFilterButtonProps } from './AddNewFilterButton.types'

const AddNewFilterButton = (props: AddNewFilterButtonProps) => {
  const { gridOptions, cleansetId, isMultilabel, resetResolverAndRowSelection } = props

  const { isOpen, onClose, onOpen } = useDisclosure()

  const { trackEvent } = useEventTracking()

  const { columnStatuses } = useContext(CleansetContext)

  const getPresetProperties = () => {
    return (gridOptions.columnDefs as ColDef[]).filter((e) => {
      // Add New Filter button should filter out preset headers, Image column, and multilabel tag columns
      const baseColumnName = getBackendColName(e.field ?? '')
      const columnStatus = getColumnStatus(baseColumnName, columnStatuses ?? {})
      return (
        !Object.values(PresetHeaders).includes(e.headerName as PresetHeaders) &&
        !(
          e.field?.startsWith('_cleanlab') &&
          !Object.keys(EnabledCleanlabColumnConfigs).includes(e.field)
        ) &&
        e.headerName !== 'Image' &&
        !e.field?.startsWith('_cleanlab_multilabel_tag') &&
        !(isMultilabel && e.field === CLEANLAB_FRONTEND_COLUMN.CORRECTED) &&
        columnStatus === ColumnStatus.READY
      )
    })
  }

  const [presetProperties, setPresetProperties] = useState(getPresetProperties())
  const [selectedProperty, setSelectedProperty] = useState('')
  const [filterInput, setFilterInput] = useState<string | number | string[]>('')

  useEffect(() => {
    if (!isOpen) {
      setFilterInput('')
      setSelectedProperty('')
    }
  }, [isOpen])

  return (
    <Menu
      key="newFilter"
      isOpen={isOpen}
      onOpen={() => {
        onOpen()
        trackEvent(MixpanelEvents.clickNewFilterButton, {
          cleansetId: cleansetId,
        })
      }}
      closeOnBlur
      onClose={onClose}
      closeOnSelect={false}
    >
      <MenuButton
        as={Button}
        fontSize="sm"
        height="2rem"
        rightIcon={<FiPlusCircle color="neutral.200" />}
      >
        New Filter
      </MenuButton>
      <AddNewFilterMenu
        gridOptions={gridOptions}
        setSelectedProperty={setSelectedProperty}
        setFilterInput={setFilterInput}
        selectedProperty={selectedProperty as string}
        filterInput={filterInput}
        resetResolverAndRowSelection={resetResolverAndRowSelection}
        setPresetProperties={setPresetProperties}
        presetProperties={presetProperties}
        isOpen={isOpen}
        cleansetId={cleansetId}
        onClose={onClose}
      />
    </Menu>
  )
}

export default AddNewFilterButton
