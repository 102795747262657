import { HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'

import MultiLabelTag from '../multiLabelTagCellRenderer/multiLabelTag/MultiLabelTag'
import { COLUMN_TYPE } from '../multiLabelTagCellRenderer/MultiLabelTagParams'
import { MultiLabelLegendProps } from './MultiLabelLegend.types'

const MultiLabelLegend = ({ column }: MultiLabelLegendProps) => {
  return (
    <VStack alignItems="flex-start" pb={2}>
      {column === 'Suggested' ? (
        <>
          <HStack>
            <MultiLabelTag label="" column={COLUMN_TYPE.SUGGESTED} />
            <Text>Caution: not in Given label</Text>
          </HStack>
          <HStack>
            <MultiLabelTag label="" column={COLUMN_TYPE.SUGGESTED} isInGiven />
            <Text>present in Given label</Text>
          </HStack>
        </>
      ) : column === 'Given' ? (
        <>
          <HStack>
            <MultiLabelTag label="" column={COLUMN_TYPE.GIVEN} />
            <Text>Caution: not in Suggested label</Text>
          </HStack>
          <HStack>
            <MultiLabelTag label="" column={COLUMN_TYPE.GIVEN} isInSuggested />
            <Text>present in Suggested label</Text>
          </HStack>
        </>
      ) : (
        <></>
      )}
    </VStack>
  )
}

export default MultiLabelLegend
