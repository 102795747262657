import { useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import UserQuotaAlert from '@common/alerts/userQuotaAlert/UserQuotaAlert'
import { UserQuotaAlertInfo } from '@common/alerts/userQuotaAlert/UserQuotaAlert.types'
import { Button } from '@components/button/Button'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { showQuotaWarning, SOFT_QUOTA_STATUS } from '@providers/errors/ErrorToast'
import { useLayoutEffect } from '@react-aria/utils'
import { useOrgCostUsageRatio } from '@services/organizationQuotas/queries'
import { useCreateProjectMutation } from '@services/project/mutations'
import { useEnabledModelTypes } from '@services/project/queries'
import { queryKeys } from '@services/userQuota/constants'
import { FormApi, useForm } from '@tanstack/react-form'
import { FileStatus } from 'filepond'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { CREATE_PROJECT_ROUTES } from 'src/createProjectRoutes'

import testIds from '../../playwright/test_ids.json'
import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { useDatasetDetailsOutletContext } from './DatasetDetailsOutlet'
import { MLPreferences } from './MLPreferences'
import { CreateProjectReq } from './ProjectForm.types'
import { DEFAULT_MODEL_TYPE } from './projectFormFields/ProjectFormFields.types'
import {
  PROJECT_WIZARD_STEPS,
  ProjectWizardStep,
  useProjectWizardStore,
} from './projectWizard/ProjectWizard.store'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionWelcomeML,
} from './Sidebar'

export type MlMode = 'auto' | 'custom'
// Reducing the props on this to keep typescript from getting stuck in infinite loop
export type FilePondFileSubset = { filename?: string; status?: FileStatus } | undefined

export type FormValues = {
  mlMode: MlMode
  modelType: string
  predProbsFile: FilePondFileSubset
}

export type SelectMLPrefsForm = FormApi<FormValues, undefined>

export const THIS_STEP = 'selectMLPrefs' as const satisfies ProjectWizardStep

const Form = () => {
  const { datasetId } = useDatasetDetailsOutletContext()
  const [quotaAlert, setQuotaAlert] = useState<UserQuotaAlertInfo | null>(null)
  const enabledModelTypes = useEnabledModelTypes(datasetId)
  const toast = useToast()
  const emailToast = useToast()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { numProjectsAvailable, featuresDisabled } = useSubscription()
  const { costUsageRatio } = useOrgCostUsageRatio()

  const projectName = useProjectWizardStore((s) => s.projectName)
  const taskType = useProjectWizardStore((s) => s.tasktype)
  const modality = useProjectWizardStore((s) => s.modality)
  const textColumn = useProjectWizardStore((s) => s.textColumn)
  const labelColumn = useProjectWizardStore((s) => s.labelColumn)
  const featureColumns = useProjectWizardStore((s) => s.featureColumns)
  const resetWizardState = useProjectWizardStore((s) => s.resetState)

  const form = useForm<FormValues>({
    onSubmit: async ({ value }) => {
      const predProbsFilename = value?.predProbsFile?.filename
      let modelType = value?.modelType?.toLowerCase()
      const mlMode = value?.mlMode
      if (mlMode === 'custom') {
        modelType = 'predicted_probabilities'
      }
      if (!projectName || !datasetId || !modality || !taskType || !modelType) {
        return
      }
      let modelTypeProps: Pick<CreateProjectReq, 'modelType' | 'predProbsFilename'> = {
        modelType,
        predProbsFilename: null,
      }
      if (mlMode === 'custom') {
        if (!predProbsFilename) {
          return
        }
        modelTypeProps = {
          modelType,
          predProbsFilename,
        }
      }
      createProject({
        projectName,
        datasetId,
        modality: modality ?? '',
        tasktype: taskType,
        labelColumn: labelColumn ?? '',
        featureColumns: featureColumns ?? [],
        textColumn: textColumn ?? '',
        ...modelTypeProps,
      })
    },
    defaultValues: {
      mlMode: 'auto',
      modelType: DEFAULT_MODEL_TYPE,
      predProbsFile: undefined,
    },
  })
  const canSubmit = form.useStore((state) => state.canSubmit)

  const { mutate: createProject, isLoading: createProjectIsLoading } = useCreateProjectMutation({
    onSuccess: (res) => {
      if (res.data.status === SOFT_QUOTA_STATUS) {
        showQuotaWarning(toast, res.data.description)
      }
      navigate('/')
      setTimeout(() => {
        // Reset the wizard state after a delay to prevent the wizard from resetting before navigation is complete
        resetWizardState()
      }, 100)
      queryClient.invalidateQueries({ queryKey: queryKeys.quotas.self() })
      emailToast({
        ...defaultToastAlertProps,
        id: 'training-email',
        render: renderChakraToastAlert({
          status: 'success',
          heading: 'Your Project has been created',
          description:
            'Our AI is gearing up to analyze your data. You will get an email when training is complete.',
        }),
      })
    },
    onError: (error) => {
      if (error.response?.status === 429) {
        setQuotaAlert({
          // @ts-ignore
          code: error.response.data.code,
        })
      }
    },
  })

  const shouldRedirect = !modality || !taskType || !projectName
  useLayoutEffect(() => {
    if (shouldRedirect) {
      navigate(`/clean/${datasetId}/${CREATE_PROJECT_ROUTES.TYPE}`)
    }
  }, [datasetId, navigate, shouldRedirect])

  if (shouldRedirect) {
    return null
  }

  return (
    <>
      <UserQuotaAlert info={quotaAlert} setInfo={setQuotaAlert} />
      <form
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <div className="flex flex-col gap-8">
          <MLPreferences
            form={form}
            enabledModelTypes={enabledModelTypes}
            modality={modality}
            taskType={taskType}
          />
        </div>
        <ProjectWizardActions
          step={THIS_STEP}
          stepCount={PROJECT_WIZARD_STEPS.length}
          className="pt-8"
        >
          <Button
            variant="highContrast"
            type="submit"
            onClick={() => null}
            disabled={
              createProjectIsLoading ||
              !canSubmit ||
              featuresDisabled ||
              numProjectsAvailable < 1 ||
              costUsageRatio >= 1
            }
            data-testid={testIds.createProjectPageCleanButton}
          >
            Create Project
          </Button>
        </ProjectWizardActions>
      </form>
    </>
  )
}

export const CreateProjectSelectMLPrefs = () => {
  const { datasetId } = useDatasetDetailsOutletContext()
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          <Link to={`/clean/${datasetId}/label`}>Verify your data</Link>
        </PreviousLink>
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <Form />
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcomeML />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/">Cleanlab Studio docs</ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/concepts/models/#deploy-a-model">
              Deploy an Auto-ML model
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/concepts/models/#using-a-model-to-make-predictions">
              Using a model to make predictions
            </ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}
