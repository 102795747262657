import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'

import { constructColumnDefs } from './columnDefs/ColumnDefs'
import { ProjectDetailsProps } from './Datasheet.types'

export const constructDatasheetGridOptions = (
  projectDetails: ProjectDetailsProps,
  allColumns: string[],
  originalDatasetColumns: string[],
  gridContext: { datasheetColumnStatuses: { [columnName: string]: string } }
): GridOptions => {
  const columnDefs = constructColumnDefs(projectDetails, allColumns, originalDatasetColumns)
  const showingImages = projectDetails.modality === 'image'

  return {
    context: gridContext,
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    columnDefs: columnDefs,
    rowHeight: showingImages ? 160 : undefined,
    rowModelType: 'serverSide',
    suppressServerSideInfiniteScroll: false,
    cacheBlockSize: 100, // fetch this number of rows at a time
    maxBlocksInCache: 1, // only keep 1 block of rows in cache
    animateRows: true,
    getContextMenuItems: (params) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
    suppressFieldDotNotation: true,
  }
}
