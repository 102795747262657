import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'

const userEventApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/user_events`,
  withCredentials: true,
})

const trackUserEvent = async (
  eventName: string,
  eventInfo?: object,
  sensitiveEventInfo?: object
) => {
  const accessToken = await auth.getTokenSilently()
  const eventInfoPayload = { ...eventInfo, ...sensitiveEventInfo }
  const body = {
    event: eventName,
    event_info: eventInfoPayload,
  }
  const response = await userEventApi.post('/t', body, createAuthHeaders(accessToken))
  return response.data
}

const exportUserEvents = async () => {
  const accessToken = await auth.getTokenSilently()
  return await userEventApi.get('/export', createAuthHeaders(accessToken))
}

const apiMethods = {
  trackUserEvent,
  exportUserEvents,
}

export default apiMethods
