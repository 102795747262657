import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import Link from '@components/link/Link'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ProjectErrorModalProps } from './ProjectErrorModal.types'

const ProjectErrorModal = (props: ProjectErrorModalProps) => {
  const { isOpen, onClose, projectDetails } = props
  const {
    error_message: errorMessage,
    error_type: errorType,
    has_multiple_cleansets: hasMultipleCleansets,
    id: projectId,
  } = projectDetails
  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')
  const titleIconColor = useColorModeValue('red.600', 'redDarkMode.600')
  const navigate = useNavigate()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>
          <HStack>
            <WarningTwoIcon color={titleIconColor} /> <span>{errorType ?? 'Error'}</span>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <p>
            {errorMessage} For support, contact{' '}
            <Link
              isExternal
              variant="standard"
              textDecoration="underline"
              href="mailto:support@cleanlab.ai"
            >
              support@cleanlab.ai
            </Link>
          </p>
        </ModalBody>

        <ModalFooter>
          {hasMultipleCleansets && (
            <PrimaryButton
              height="36px"
              onClick={() => navigate(`/projects/${projectId}`)}
              aria-label="See Cleanset versions"
            >
              See versions
            </PrimaryButton>
          )}
          <Button
            mx={3}
            variant="outline"
            onClick={onClose}
            aria-label="Cancel error details modal"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ProjectErrorModal
