import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import { useCallback } from 'react'

import { InlineLink } from '../../projectForm/Sidebar'
import { useDashboardSettings } from '../settings/useDashboardSettings'

export const RemoveDashboardProjectsModal = ({ isOpen, onClose }: UseModalProps) => {
  const { setHideProjectDemos } = useDashboardSettings(({ setHideProjectDemos }) => ({
    setHideProjectDemos,
  }))
  const onConfirm = useCallback(() => {
    setHideProjectDemos(true)
    onClose?.()
  }, [onClose, setHideProjectDemos])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remove demo Projects</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="type-body-200 flex flex-col gap-4 text-text-faint">
          <p>
            This action will remove demo Projects from your Dashboard. However you will be able to
            access demo Datasets at any time when you{' '}
            <InlineLink to="/clean">create a new Project</InlineLink>.
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="flex flex-row-reverse justify-start gap-5">
            <Button variant="highContrast" size="small" onClick={onConfirm}>
              Confirm
            </Button>
            <Button variant="secondary" size="small" onClick={() => onClose?.()}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
