import { Center, Image, useColorModeValue } from '@chakra-ui/react'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'
import React, { useState } from 'react'

const ImageCellRenderer = (imageUrlField: string, params: ICellRendererParams) => {
  const rowData = params.node.data
  const imageUrl = rowData[imageUrlField]
  const border = '1px solid'
  const borderColor = useColorModeValue('neutral.300', 'neutralDarkMode.300')
  const borderRadius = '4px'
  const [errorLoadingImage, setErrorLoadingImage] = useState<boolean>(false)
  const fallbackDimension = '141px'

  return errorLoadingImage ? (
    <Center
      border={border}
      borderRadius={borderRadius}
      borderColor={borderColor}
      h={fallbackDimension}
      w={fallbackDimension}
    >
      <Image src={imageUrl} />
    </Center>
  ) : (
    <Image
      border={border}
      borderRadius={borderRadius}
      borderColor={borderColor}
      p="4px"
      h="calc(100% - 16px)"
      src={imageUrl}
      loading="lazy"
      objectFit="contain"
      onError={() => {
        setErrorLoadingImage(true)
      }}
    />
  )
}

export default ImageCellRenderer
