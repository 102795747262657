import { WarningTwoIcon } from '@chakra-ui/icons'
import { Button, CircularProgress, Flex, HStack, Tag, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { FiExternalLink } from 'react-icons/fi'

import { PREDICTION_STATUS } from '../ExportModelGrid.types'
import PredictionErrorModal from '../predictionErrorModal/PredictionErrorModal'
import { ExportModelStatusCellComponentProps } from './exportModelStatusCellComponent.types'

const ExportModelStatusCellComponent = (props: ExportModelStatusCellComponentProps) => {
  const { status, statusDescription, datasetName, error } = props

  const { isOpen, onOpen, onClose } = useDisclosure()

  if (status === PREDICTION_STATUS.COMPLETE) {
    return (
      <Flex align="center" height="100%">
        <Tag colorScheme="teal" size="sm" aria-label="review Project Cleanset">
          Ready for export
        </Tag>
      </Flex>
    )
  } else if (status === PREDICTION_STATUS.FAILED) {
    return (
      <>
        <PredictionErrorModal
          isOpen={isOpen}
          onClose={onClose}
          datasetName={datasetName}
          error={error}
        />
        <HStack justify="space-between" height="100%">
          <Button
            leftIcon={<WarningTwoIcon />}
            size="sm"
            aria-label="Review Project error"
            variant="error"
            onClick={onOpen}
          >
            Error
          </Button>
          <Button size="sm" leftIcon={<FiExternalLink />} colorScheme="gray" onClick={onOpen}>
            See Details
          </Button>
        </HStack>
      </>
    )
  } else if (status === PREDICTION_STATUS.QUOTA_FAILURE) {
    return (
      <>
        <PredictionErrorModal
          isOpen={isOpen}
          onClose={onClose}
          datasetName={datasetName}
          error={error}
        />
        <HStack justify="space-between" height="100%">
          <Button
            leftIcon={<WarningTwoIcon />}
            colorScheme="red"
            size="sm"
            aria-label="review quota error"
            variant="error"
            disabled
          >
            Quota Error
          </Button>
          <Button size="sm" leftIcon={<FiExternalLink />} colorScheme="gray" onClick={onOpen}>
            See Details
          </Button>
        </HStack>
      </>
    )
  } else {
    return (
      <HStack align="center" height="100%">
        <CircularProgress
          aria-label="Project Status"
          isIndeterminate
          color="blue.300"
          thickness="14px"
          size="20px"
        />
        <Text>{statusDescription}</Text>
      </HStack>
    )
  }
}

export default ExportModelStatusCellComponent
