'use client'

import { useFormField } from '@components/formField/FormFieldContext'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cn } from '@utils/tailwindUtils'
import { ComponentPropsWithoutRef, ComponentPropsWithRef, forwardRef, memo, ReactNode } from 'react'

type LabelRootProps = ComponentPropsWithoutRef<typeof LabelPrimitive.Root>

type LabelProps = LabelRootProps & {
  required?: boolean
  description?: ReactNode
  disabled?: boolean
}

export const LabelRoot = forwardRef(
  (
    { children, className, ...props }: LabelRootProps,
    ref: ComponentPropsWithRef<typeof LabelPrimitive.Root>['ref']
  ) => {
    const { htmlFor, disabled } = useFormField() || {}

    return (
      <LabelPrimitive.Root
        ref={ref}
        {...(htmlFor ? { htmlFor } : {})}
        className={cn(
          'type-body-100-medium text-text-strong',
          disabled && 'cursor-not-allowed text-text-disabled',
          className
        )}
        {...props}
      >
        {children}
      </LabelPrimitive.Root>
    )
  }
)

const LabelBase = (
  { className, children, description, required: requiredProp, ...props }: LabelProps,
  ref: ComponentPropsWithRef<typeof LabelRoot>['ref']
) => {
  const { required: requiredCtx } = useFormField() || {}

  const required = typeof requiredProp === 'boolean' ? requiredProp : !!requiredCtx
  return (
    <LabelRoot ref={ref} className={className} {...props}>
      <span>
        {children}
        {required && <span>*</span>}
      </span>
      {description && <div className="type-body-100 pt-1 text-text-faint">{description}</div>}
    </LabelRoot>
  )
}

export const Label = memo(forwardRef(LabelBase))
Label.displayName = 'Label'
