import { Input } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

import { PhoneFormFieldProps } from './PhoneFormField.types'

const TextFieldPhoneInput = (props: PhoneFormFieldProps, ref: React.Ref<HTMLInputElement>) => {
  return (
    <Input ref={ref} {...props} variant="phone" maxLength={32} placeholder="+1 (123) 345-6789" />
  )
}

export default forwardRef(TextFieldPhoneInput)
