import Loading from '@common/layout/loading/Loading'
import NonExistentResource from '@providers/errors/NonExistentResource'
import { DatasetDetailsProps } from '@services/datasetApi'
import { useDatasetDetails } from '@services/datasets/queries'
import { Suspense } from 'react'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

export type DatasetDetailsOutletContext = {
  datasetId: string
  datasetDetails: DatasetDetailsProps
}

const DatasetDetails = () => {
  const { datasetId } = useParams<{ datasetId: string }>()
  const datasetDetails = useDatasetDetails({ datasetId })

  if (!datasetId || !datasetDetails) {
    return <NonExistentResource errorMessage="No such Dataset exists." />
  }
  return <Outlet context={{ datasetId, datasetDetails } satisfies DatasetDetailsOutletContext} />
}

export const DatasetDetailsOutlet = () => {
  return (
    <Suspense fallback={<Loading text="Loading Dataset..." />}>
      <DatasetDetails />
    </Suspense>
  )
}

export const useDatasetDetailsOutletContext = () => {
  const ctx = useOutletContext<DatasetDetailsOutletContext>()
  if (!ctx) {
    throw new Error('useDatasetOutletContext must be used within a DatasetOutlet')
  }
  return ctx
}
