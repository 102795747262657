import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  useOutsideClick,
} from '@chakra-ui/react'
import React, { CSSProperties, useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'

import testIds from '../../../../playwright/test_ids.json'
import { BatchAction } from '../BatchActionsModal.types'
import { BatchActionInputProps } from './BatchActionInput.types'

const BatchActionInput = ({
  numRowsNonFiltered,
  batchAction,
  relabelOptions,
  setRelabel,
  relabel,
  isInvalidNumber,
  setTopKInput,
  topKInput,
}: BatchActionInputProps) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false)
  const [relabelSearch, setRelabelSearch] = useState('')

  const ref = useRef<HTMLDivElement | null>(null)

  const searchRelabelOptions = relabelOptions.filter((e) => e.toString().includes(relabelSearch))

  const hoverAllExamplesColor = useColorModeValue('gray.300', 'gray.800')

  // Not using MenuItem in dropdown, so need to make our own outside click closing functionality
  useOutsideClick({
    ref: ref,
    handler: () => {
      return setIsLabelOpen(false)
    },
  })

  return (
    <FormControl isInvalid={isInvalidNumber}>
      <Flex align="center">
        <Text fontWeight={500} whiteSpace="nowrap" mr={1}>
          {batchAction === BatchAction.needsReview
            ? 'Mark as needs review for '
            : `${batchAction} `}
          top
        </Text>
        <Input
          autoFocus
          name="auto-fix"
          maxW="15%"
          value={topKInput}
          max={numRowsNonFiltered}
          isDisabled={!numRowsNonFiltered}
          data-testid={testIds.batchActionNumberOfRowsInput}
          onKeyDown={(e) => {
            // prevent command palette options from capturing event
            e.stopPropagation()
          }}
          onChange={(e) => {
            setTopKInput(e.target.value)
          }}
        />
        <Tooltip
          hasArrow
          label="The displayed datapoints are calculated from your applied sort and filters"
        >
          <Text ml={1} fontSize="md" fontWeight={500} whiteSpace="nowrap">
            / {numRowsNonFiltered} displayed datapoints
          </Text>
        </Tooltip>
        {batchAction === BatchAction.label && (
          <>
            <Text mx={1} fontSize="md" fontWeight="500">
              to
            </Text>
            <Menu isOpen={isLabelOpen}>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                onClick={() => setIsLabelOpen(!isLabelOpen)}
              >
                <Text isTruncated> {relabel !== null ? relabel : 'Select Label'}</Text>
              </MenuButton>
              <Box ref={ref}>
                <MenuList>
                  <Box px={2}>
                    <Input
                      p={2}
                      m={0}
                      key="labelInput"
                      value={relabelSearch}
                      placeholder="Search for a class"
                      onKeyDown={(e) => {
                        // prevent command palette options from capturing event
                        e.stopPropagation()
                      }}
                      onChange={(event) => setRelabelSearch(event.target.value)}
                    />
                  </Box>
                  <Box overflowX="auto">
                    <FixedSizeList
                      height={searchRelabelOptions.length > 10 ? 250 : 150}
                      itemCount={searchRelabelOptions.length}
                      itemSize={30}
                      width={300}
                    >
                      {({ index, style }: { index: number; style: CSSProperties }) => (
                        <Box
                          px={3}
                          pt={1}
                          key={searchRelabelOptions[index].toString()}
                          _hover={{ bgColor: hoverAllExamplesColor }}
                          onClick={() => {
                            setRelabel(searchRelabelOptions[index])
                            setIsLabelOpen(!isLabelOpen)
                          }}
                          style={style}
                        >
                          <Text whiteSpace="nowrap">{searchRelabelOptions[index]}</Text>
                        </Box>
                      )}
                    </FixedSizeList>
                  </Box>
                </MenuList>
              </Box>
            </Menu>
          </>
        )}
        {isInvalidNumber && (
          <FormErrorMessage m={0} pl={4} fontSize="xs">
            You must select between 1 and {numRowsNonFiltered} datapoints
          </FormErrorMessage>
        )}
      </Flex>
    </FormControl>
  )
}

export default BatchActionInput
