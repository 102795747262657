import React from 'react'

import { SvgIconProps } from '../IconProps.types'

const ExportIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 16}
      height={height ?? 16}
      viewBox={`0 0 ${width ?? 16} ${height ?? 16}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.646.98a.5.5 0 0 1 .707 0l2.667 2.667a.5.5 0 0 1-.707.707L8.5 2.54V10a.5.5 0 0 1-1 0V2.54L5.687 4.354a.5.5 0 1 1-.707-.707L7.646.98ZM2.666 7.5a.5.5 0 0 1 .5.5v5.334a.833.833 0 0 0 .834.833h8a.834.834 0 0 0 .833-.833V8a.5.5 0 1 1 1 0v5.334A1.834 1.834 0 0 1 12 15.167H4a1.833 1.833 0 0 1-1.833-1.833V8a.5.5 0 0 1 .5-.5Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}

export default ExportIcon
