import { useAuth0 } from '@auth0/auth0-react'
import { AUTH_MODE_OAUTH, IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { usePostHog } from 'posthog-js/react'

import { useOAuth } from './useOAuth'

// an abstraction layer over Auth0 auth, which we use in SaaS, and OAuth 2
// auth, which we use in VPC
//
// slightly less precise types than Auth0ContextInterface
export interface AuthContextInterface {
  error?: Error
  getAccessTokenSilently: (options?: any) => Promise<string>
  isAuthenticated: boolean
  isLoading: boolean
  loginWithRedirect: (options?: any) => Promise<void>
  logout: (options?: any) => void
  user?: {
    email?: string
    name?: string
    picture?: string
    sub?: string
  }
}

// ensure that nobody uses properties unsupported by our abstraction layer
const useAuthFiltered = (): AuthContextInterface => {
  const posthog = usePostHog()
  const {
    error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout: logoutAuth,
    user,
    // eslint-disable-next-line no-restricted-syntax
  } = useAuth0()

  const logout = (options?: any) => {
    if (!IS_VPC_ENVIRONMENT && posthog) {
      posthog.reset()
    }
    logoutAuth(options)
  }
  return {
    error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  }
}

export const useAuth: () => AuthContextInterface = AUTH_MODE_OAUTH ? useOAuth : useAuthFiltered
