import { timestampToDate } from '@utils/functions/timestampToDate'
import {
  ColDef,
  GetContextMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community'

const timeFormatter = (param: ICellRendererParams) => timestampToDate(param.value)

export const columnDefs: ColDef[] = [
  {
    field: 'user_id',
    headerName: 'User ID',
    minWidth: 200,
    filter: 'agTextColumnFilter',
    pinned: 'left',
    checkboxSelection: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'email',
    headerName: 'Email',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'tier',
    headerName: 'Tier',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'plan_name',
    headerName: 'Plan Name',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'description',
    headerName: 'Description',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'price_per_month',
    headerName: 'Price Per Month ($)',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'price_per_year',
    headerName: 'Price Per Year ($)',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'price_per_row_cleanset_export',
    headerName: 'Price Per Row Cleanset Export (cent)',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'price_per_row_inference_export',
    headerName: 'Price Per Row Inference Export (cents)',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'paying_customer',
    headerName: 'Paying Customer',
    filter: 'agSetColumnFilter',
  },
  { field: 'is_enterprise_plan', headerName: 'Is Enterprise Plan', filter: 'agSetColumnFilter' },
  { field: 'dataset_rows_limit', headerName: 'Dataset Rows Limit', filter: 'agNumberColumnFilter' },
  {
    field: 'cleanset_export_rows_monthly_limit',
    headerName: 'Cleanset Export Rows Monthly Limit',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'inference_export_rows_monthly_limit',
    headerName: 'Inference Export Rows Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_projects_monthly_limit',
    headerName: 'Free Projects Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_models_monthly_limit',
    headerName: 'Free Models Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_cleanset_export_rows_monthly_limit',
    headerName: 'Free Cleanset Export Rows Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_inference_export_rows_monthly_limit',
    headerName: 'Free Inference Export Rows Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_length_days',
    headerName: 'Free Trial Length Days',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_dataset_rows_limit',
    headerName: 'Free Trial Dataset Rows Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_projects_limit',
    headerName: 'Free Trial Projects Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_models_limit',
    headerName: 'Free Trial Models Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_inference_export_rows_limit',
    headerName: 'Free Trial Inference Export Rows Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_period',
    headerName: 'Free Trial Period',
    filter: 'agSetColumnFilter',
  },
  {
    field: 'free_trial_period_end',
    headerName: 'Free Trial Period End',
    cellRenderer: 'timeFormatter',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'free_trial_period_remaining',
    headerName: 'Free Trial Period Remaining (Days)',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'id',
    headerName: 'ID',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'subscription_plan_id',
    headerName: 'Subscription Plan ID',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'plan_id',
    headerName: 'Plan ID',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'payment_status',
    headerName: 'Payment Status',
    filter: 'agSetColumnFilter',
  },
  {
    field: 'current_period_start',
    headerName: 'Start Current Period',
    cellRenderer: 'timeFormatter',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'current_period_end',
    headerName: 'End Current Period',
    cellRenderer: 'timeFormatter',
    filter: 'agDateColumnFilter',
  },
  { field: 'created_at', headerName: 'Created At', filter: 'agDateColumnFilter' },
  {
    field: 'expiry_date',
    headerName: 'Expiry Date',
    cellRenderer: 'timeFormatter',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    cellRenderer: 'timeFormatter',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'last_updated',
    headerName: 'Last Updated',
    cellRenderer: 'timeFormatter',
    filter: 'agDateColumnFilter',
  },
  { field: 'cancelled_at', headerName: 'Cancelled At', filter: 'agNumberColumnFilter' },
]

export const subscriptionGridOptions: GridOptions = {
  columnDefs,
  rowSelection: 'single',
  components: { timeFormatter },
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true,
    flex: 1,
    width: 200,
    minWidth: 200,
  },
  animateRows: true,
  onGridReady: (event: GridReadyEvent) => {
    setTimeout(() => {
      event.columnApi.autoSizeAllColumns(false)
    }, 400)
  },
  getContextMenuItems: (params: GetContextMenuItemsParams) => {
    if (params.value === undefined) {
      return []
    } else {
      return ['copy']
    }
  },
}
