import { Box, Button, Flex, HStack, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useAuth } from '@hooks/useAuth'
import useGridClassname from '@hooks/useGridClassname'
import { PlanData } from '@services/billing/constants'
import { useAddPlan, useDeletePlan, useEditPlan } from '@services/billing/mutations'
import { useGetBillingPlans } from '@services/billing/queries'
import { GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community'
import { useRef, useState } from 'react'

import ConfirmDeleteDialog from '../../confirmDeleteDialog/ConfirmDeleteDialog'
import AddPlanModal from './addPlanModal/AddPlanModal'
import { planGridOptions } from './PlanGrid.helpers'
import UpdatePlanModal from './updatePlanModal/UpdatePlanModal'

const PlanGrid = () => {
  const numRowsPerPage = 20

  const { isOpen: isUpdateOpen, onOpen: onUpdateOpen, onClose: onUpdateClose } = useDisclosure()
  const { isOpen: isAddPlanOpen, onOpen: onAddPlanOpen, onClose: onAddPlanClose } = useDisclosure()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [selectedRowData, setSelectedRowData] = useState<PlanData | null>(null)
  const cancelDeleteRef = useRef(null)

  const { isAuthenticated } = useAuth()

  const { mutate: editPlan } = useEditPlan()
  const { mutate: addPlan } = useAddPlan()
  const { mutate: deletePlan } = useDeletePlan()

  const toast = useToast()

  const plans = useGetBillingPlans(isAuthenticated)

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
  }

  const handleEditPlanSubmit = (planId: string, planData: PlanData) => {
    editPlan({ planId, planData })
  }

  const handleAddPlanSubmit = (planData: PlanData) => {
    addPlan(planData)
  }

  const handleDeletePlanSubmit = (planId: string) => {
    deletePlan(planId)
  }

  return (
    <Stack>
      <Flex justify="flex-end">
        <HStack>
          <Button colorScheme="whatsapp" size="sm" onClick={onAddPlanOpen}>
            Add Plan
          </Button>
          <Button
            colorScheme="orange"
            size="sm"
            onClick={() => {
              if (gridApi && gridApi?.getSelectedRows().length > 0) {
                onUpdateOpen()
              } else {
                toast({
                  ...defaultToastAlertProps,
                  render: renderChakraToastAlert({
                    heading: 'No plans selected',
                    status: 'info',
                  }),
                })
              }
            }}
          >
            Edit Plan
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              if (gridApi && gridApi?.getSelectedRows().length > 0) {
                onDeleteOpen()
              } else {
                toast({
                  ...defaultToastAlertProps,
                  render: renderChakraToastAlert({
                    heading: 'No plans selected',
                    status: 'info',
                  }),
                })
              }
            }}
          >
            Delete plan
          </Button>
        </HStack>
      </Flex>
      <Box className={useGridClassname()} h="70vh" w="100%">
        <CleanlabGrid
          gridOptions={planGridOptions}
          onGridReady={handleGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={plans}
          onRowSelected={(event: RowSelectedEvent) => {
            setSelectedRowData(event.data)
          }}
        />
      </Box>
      {selectedRowData && gridApi && (
        <UpdatePlanModal
          isOpen={isUpdateOpen}
          onClose={onUpdateClose}
          onSubmit={handleEditPlanSubmit}
          rowData={selectedRowData}
        />
      )}
      {selectedRowData && gridApi && (
        <ConfirmDeleteDialog
          onClose={onDeleteClose}
          isOpen={isDeleteOpen}
          cancelRef={cancelDeleteRef}
          onConfirm={() => handleDeletePlanSubmit(selectedRowData.id)}
          item="plan"
        />
      )}

      <AddPlanModal
        isOpen={isAddPlanOpen}
        onClose={onAddPlanClose}
        onSubmit={handleAddPlanSubmit}
      />
    </Stack>
  )
}

export default PlanGrid
