import { Box, Flex, HStack, Text, useColorMode, useColorModeValue, VStack } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { BarDatum, ComputedDatum, ResponsiveBar } from '@nivo/bar'
import * as React from 'react'
import { useContext } from 'react'
import { CleansetContext } from 'src/pages/cleanset/CleansetContext'

import {
  BAR_BORDER_RADIUS,
  BASE_FONT_SIZE,
  CHART_BASE_MARGINS,
  CHART_DEFAULT_BAR_COLOR,
  CHART_TEXT_DICT,
  CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
  getChartTheme,
  getLabelTextColor,
} from '../CleansetCharts.helpers'
import { LabelItem, LabelStack } from './CorrectionsChart.helpers'
import { CorrectionsChartProps } from './CorrectionsChart.types'

const CorrectionsChart = (props: CorrectionsChartProps) => {
  const { colorMode } = useColorMode()
  const {
    data,
    keys,
    indexBy,
    columnLeftTitle,
    columnRightTitle,
    barClickEvent,
    onClick,
    onClose,
  } = props

  const barPaddingMultiplier = 0.25

  const fontSize = 14
  const headingFontWeight = 600

  const colorBW = useColorModeValue('neutral.700', 'neutralDarkMode.700')
  const titleColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')

  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()

  const trackClickEvent = (bar: ComputedDatum<BarDatum>) => {
    const { indexValue, value } = bar
    const barData = {
      barCorrection: indexValue,
      exampleCount: value,
    }

    if (barClickEvent) {
      trackEvent(barClickEvent, { ...cleansetInfo, ...barData })
    }
    if (onClick) {
      onClick(bar)
      onClose()
    }
  }

  // Nivo chart displays bars bottom to top so must flip data to generate label columns
  const reversedData = data.slice().reverse()

  const givenLabelItems = [
    <Box flex={barPaddingMultiplier} key="Header 1" />,
    ...reversedData.map((item, idx) => (
      <LabelItem
        key={idx}
        value={item.given}
        barPaddingMultiplier={barPaddingMultiplier}
        fontSize={fontSize}
        color={colorBW}
      />
    )),
  ]

  const suggestedLabelItems = [
    <Box flex={barPaddingMultiplier} key="Header 2" />,
    ...reversedData.map((item, idx) => (
      <LabelItem
        key={idx}
        value={item.suggested}
        barPaddingMultiplier={barPaddingMultiplier}
        fontSize={fontSize}
        color={colorBW}
      />
    )),
  ]

  return (
    <HStack display="flex" height="inherit" width="100%">
      <LabelStack
        header={columnLeftTitle}
        items={givenLabelItems}
        headingFontWeight={headingFontWeight}
        fontSize={fontSize}
        titleColor={titleColor}
      />
      <LabelStack
        header={columnRightTitle}
        items={suggestedLabelItems}
        headingFontWeight={headingFontWeight}
        fontSize={fontSize}
        titleColor={titleColor}
      />
      <VStack
        flex={1}
        alignItems="start"
        height="inherit"
        // Gives chart a minimum starting width to allow responsive bars
        // to initially render, then grow to fill container
        width="50%"
      >
        <Box height={18} flex={0} pl="16px">
          <Text fontSize={fontSize} color={titleColor} fontWeight={headingFontWeight} align="left">
            {CHART_TEXT_DICT.titles.numberOfSuggestedCorrections}
          </Text>
        </Box>
        <Flex alignSelf="stretch" height="100%" width="100%">
          <ResponsiveBar
            data={data}
            keys={keys}
            indexBy={indexBy}
            onClick={trackClickEvent}
            margin={{
              ...CHART_BASE_MARGINS,
              top: 0,
              bottom: CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
            }}
            padding={barPaddingMultiplier}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={CHART_DEFAULT_BAR_COLOR}
            colorBy="indexValue"
            borderRadius={BAR_BORDER_RADIUS}
            axisRight={null}
            axisLeft={null}
            enableGridY={false}
            enableGridX={true}
            groupMode="grouped"
            labelSkipWidth={BASE_FONT_SIZE}
            labelSkipHeight={BASE_FONT_SIZE}
            labelTextColor={getLabelTextColor(colorMode === 'light')}
            theme={getChartTheme(colorMode === 'light')}
          />
        </Flex>
      </VStack>
    </HStack>
  )
}

export default CorrectionsChart
