import { CLBlue } from '@assets/styles/theme'
import { Flex, Spinner, StackProps, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Grid } from 'react-loader-spinner'

import { LoadingProps } from './Loading.types'

// Not really a Page, just a component to be inserted into Layout to create a generic loading screen page
const Loading = ({ text, type, ...rest }: LoadingProps & StackProps) => {
  return (
    <VStack
      spacing="2rem"
      align="center"
      justify="center"
      h="100%"
      w="100%"
      position="absolute"
      {...rest}
    >
      {type === 'chakra-spinner' && <Spinner size="md" />}
      {type !== 'chakra-spinner' && <Grid color={CLBlue} height={80} width={80} />}
      <Text color="gray.500" fontSize="md">
        {text ?? 'Loading...'}
      </Text>
    </VStack>
  )
}

/**
 * Basic Loading component that fills the given space and has a spinner in the center
 */
export const SimpleLoading = () => {
  return (
    <Flex w="100%" h="100%" align="center" justify="center">
      <Spinner />
    </Flex>
  )
}

export default Loading
