import { Column } from 'ag-grid-community'

import { ColumnStatus } from '../Cleanset.types'

export const getColumnDisplayName = (gridColumn: Column): string => {
  if (gridColumn && gridColumn?.getUserProvidedColDef()) {
    return (
      (gridColumn?.getUserProvidedColDef()?.headerName ||
        gridColumn?.getUserProvidedColDef()?.field) ??
      ''
    )
  }
  return ''
}

export const getColumnName = (gridColumn: Column, getField = true): string => {
  if (gridColumn && gridColumn?.getUserProvidedColDef()) {
    if (getField) {
      return gridColumn?.getUserProvidedColDef()?.field ?? ''
    } else {
      return gridColumn?.getUserProvidedColDef()?.headerName ?? ''
    }
  }
  return ''
}

export const getColumnSelectorTooltip = (columnStatus: string): string | null => {
  if (columnStatus === ColumnStatus.PENDING) {
    return 'Column is not yet ready. Please check again later.'
  }
  if (columnStatus === ColumnStatus.ERROR) {
    return 'Error generating column.'
  }
  return null
}

export const searchColumns = (searchTerm: string, gridColumns: Column[]) => {
  const searchValue = searchTerm.toLowerCase()
  const filteredColumns =
    gridColumns?.filter((column) =>
      getColumnDisplayName(column).toLowerCase().includes(searchValue)
    ) ?? []
  return filteredColumns
}
