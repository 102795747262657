import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Radio,
  RadioGroup,
  Select,
  VStack,
} from '@chakra-ui/react'
import queryApi from '@services/queryApi'
import { getCurrentDateISO, getNDaysAgoISO } from '@utils/functions/timestampToDate'
import { useState } from 'react'

import { ControlledFormInputElement } from '../../queryInterface/QueryInterface.helpers'
import CostReportGrid from './CostReportGrid'

const CostReport = () => {
  const [startDate, setStartDate] = useState(getNDaysAgoISO(7))
  const [endDate, setEndDate] = useState(getCurrentDateISO())
  const [groupBy, setGroupBy] = useState<string>()
  const [queryResults, setQueryResults] = useState(null)
  const [taskTypeFilter, setTaskTypeFilter] = useState('all')

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <ControlledFormInputElement
          id="start_date"
          type="date"
          label="Start date"
          value={startDate}
          setValue={setStartDate}
        />
        <ControlledFormInputElement
          id="end_date"
          type="date"
          label="End date"
          value={endDate}
          setValue={setEndDate}
        />
        <FormControl>
          <FormLabel htmlFor="group_by">Group by</FormLabel>
          <Select
            placeholder="Select option"
            id="group_by"
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
          >
            <option value="task">Task</option>
            <option value="user">User</option>
            <option value="none">None</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="task_type">Tasks to include</FormLabel>
          <RadioGroup id="task_type" onChange={setTaskTypeFilter} value={taskTypeFilter}>
            <HStack align="center">
              <Radio value="cleansets">Cleansets</Radio>
              <Radio value="deployments">Deployments</Radio>
              <Radio value="experimental_jobs">Experimental Jobs</Radio>
              <Radio value="all">All</Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApi.query({
            resource: 'cost_report',
            start_date: startDate,
            end_date: endDate,
            group_by: groupBy,
            task_type: taskTypeFilter,
          })
          setQueryResults(response.data)
        }}
      >
        Get Cost Report
      </Button>

      <CostReportGrid data={queryResults} />
    </VStack>
  )
}

export default CostReport
