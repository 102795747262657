import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Button, HStack, Spinner, Text, useColorModeValue } from '@chakra-ui/react'
import { Badge } from '@components/badge/Badge'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useRowCount } from '@services/datasheet/queries'
import { useEffect, useMemo } from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { ColumnStatus } from 'src/pages/cleanset/Cleanset.types'
import { useFilters } from 'src/pages/cleanset/filterReducer/useFilters'

import { getFrontendColumnByFilterType, getQuickFilterFunction } from './PresetFilterRow.helpers'
import { PresetFilterRowProps, PresetFilterRowTitle } from './PresetFilterRow.types'
import PresetFilterRowTooltip from './presetFilterRowTooltip/PresetFilterRowTooltip'

const PresetFilterRow = (props: PresetFilterRowProps) => {
  const {
    cleansetId,
    gridApi,
    filterType,
    rowCountType,
    currentPresetFilter,
    setActivePresetFilter,
    firstGridDataRendered,
    columnApi,
    presetConfigCallback,
    resetResolverAndRowSelection,
  } = props
  const { numRows, columnStatus } = useRowCount(
    cleansetId,
    rowCountType,
    gridApi,
    firstGridDataRendered
  )
  const { trackEvent } = useEventTracking()
  const { dispatch, state } = useFilters()
  const applyQuickFilter = getQuickFilterFunction(filterType)

  const currentAppliedFilterData = useMemo(() => {
    return state.filterData.filter(
      (filter) =>
        (typeof filter.filterInput === 'string' && filter.filterInput !== '') ||
        (Array.isArray(filter.filterInput) && filter.filterInput.length)
    )
  }, [state.filterData])

  const handlePresetFilterClick = () => {
    if (applyQuickFilter) {
      resetResolverAndRowSelection()
      columnApi.resetColumnState()
      applyQuickFilter(dispatch, filterType)
      presetConfigCallback(filterType)
      gridApi?.onSortChanged()
      trackEvent(MixpanelEvents.clickQuickFilterButton, {
        filterType: filterType,
        filterRowCount: numRows,
      })
      setActivePresetFilter(filterType)
    }
  }

  useEffect(() => {
    // Preset filter is active when it is the only filter applied and input is true (or action = unresolved)
    const cleanlabFrontendColumn = getFrontendColumnByFilterType(filterType)
    if (currentAppliedFilterData.length === 0) {
      setActivePresetFilter('total')
    } else if (currentAppliedFilterData.length !== 1 || !cleanlabFrontendColumn) {
      setActivePresetFilter('')
    } else if (cleanlabFrontendColumn === currentAppliedFilterData[0].property) {
      if (
        currentAppliedFilterData[0].filterInput === 'true' ||
        currentAppliedFilterData[0].filterInput === 'unresolved'
      ) {
        setActivePresetFilter(filterType)
      } else {
        setActivePresetFilter('')
      }
    }
  }, [setActivePresetFilter, currentAppliedFilterData, filterType])

  const rowCountInfo = useMemo(() => {
    if (columnStatus === ColumnStatus.PENDING) {
      return <Spinner />
    } else if (columnStatus === ColumnStatus.ERROR) {
      return <FiAlertCircle style={{ color: cleanlabColors.red[700] }} size={16} />
    } else if (numRows === -1) {
      return <Badge size="small">-</Badge>
    } else {
      return <Badge size="small">{numRows}</Badge>
    }
  }, [columnStatus, numRows])

  return (
    <PresetFilterRowTooltip filterType={filterType} columnStatus={columnStatus}>
      <Button
        variant="ghost"
        role="group"
        onClick={handlePresetFilterClick}
        borderRadius={0}
        width="100%"
        isActive={filterType === currentPresetFilter}
        _hover={{
          bg: useColorModeValue('neutral.100', 'neutralDarkMode.100'),
        }}
        _active={{
          bg: useColorModeValue('neutral.200', 'neutralDarkMode.200'),
          borderLeftWidth: '3px',
          borderColor: useColorModeValue('blue.500', 'blueDarkMode.500'),
          paddingLeft: '13px',
        }}
        isDisabled={columnStatus !== ColumnStatus.READY}
      >
        <HStack justifyContent="space-between" width="100%">
          <Text
            fontSize="12px"
            fontWeight="84px"
            color={useColorModeValue('neutral.700', 'neutralDarkMode.700')}
            textTransform="capitalize"
          >
            {PresetFilterRowTitle[filterType] ?? filterType}
          </Text>
          {rowCountInfo}
        </HStack>
      </Button>
    </PresetFilterRowTooltip>
  )
}

export default PresetFilterRow
