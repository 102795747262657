import { Badge } from '@components/badge/Badge'
import { cn } from '@utils/tailwindUtils'

import { ActionType, CustomTagType } from '../../commandpalette/CommandPalette.types'

export const getActionTag = (category: ActionType | string, undone: boolean) => {
  const badgeClass = cn('flex flex-nowrap', undone ? ' line-through' : 'none')
  switch (category) {
    case ActionType.AUTO_FIX:
      return (
        <Badge
          className={badgeClass}
          round="small"
          size="medium"
          variant="actionSubtle"
          hue="green"
        >
          Auto-fix
        </Badge>
      )
    case ActionType.EXCLUDE:
      return (
        <Badge className={badgeClass} round="small" size="medium" variant="actionSubtle" hue="pink">
          Exclude
        </Badge>
      )
    case ActionType.KEEP:
      return (
        <Badge
          className={badgeClass}
          round="small"
          size="medium"
          variant="actionSubtle"
          hue="purple"
        >
          Keep
        </Badge>
      )
    case CustomTagType.NEEDS_REVIEW:
      return (
        <Badge className={badgeClass} size="medium">
          needs review
        </Badge>
      )
    case ActionType.REMOVE_TAG_LABEL:
      return (
        <Badge size="medium" className={badgeClass}>
          remove label
        </Badge>
      )
    case ActionType.REMOVE_NEEDS_REVIEW:
      return <Badge size="medium">remove needs review</Badge>
    default:
      return (
        <Badge className={badgeClass} round="small" size="medium" variant="actionSubtle" hue="blue">
          Correct
        </Badge>
      )
  }
}
