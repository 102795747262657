import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import AddUsersModal from '@common/modals/addUsersModal/AddUsersModal'
import EditBudgetModal from '@common/modals/editBudgetModal/EditBudgetModal'
import OrgInfo from '@common/orgInfo/OrgInfo'
import OrgOverview from '@common/orgOverview/OrgOverview'
import VpcAdminGrid from '@common/vpcAdminGrid/VpcAdminGrid'
import { Button } from '@components/button/Button'
import { IconInfo } from '@components/icons'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import PageAlert from '@components/pageAlert/PageAlert'
import { useOrgCostUsageRatio, useOrgUsage } from '@services/organizationQuotas/queries'
import { useOrg, useOrgUsageSummary } from '@services/organizations/queries'
import { AccessLevel, AccountStatus, VpcUserRowRes } from '@services/user/constants'
import { useMyself, useUsersByOrganization } from '@services/user/queries'
import camelCaseKeys from '@utils/functions/camelCaseKeys'
import { GridApi } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'

import NotFound from '../404/NotFound'
import { getVpcAlertMessage } from './VpcAdminPanel.helpers'

const VpcAdminPanel = () => {
  const { accessLevel, organizationId } = useMyself()

  const usersByOrganization = useUsersByOrganization(organizationId ?? '') ?? []
  const usersData = camelCaseKeys(usersByOrganization) as VpcUserRowRes[]

  const { isOpen: isAddUserOpen, onClose: onAddUserClose, onOpen: onAddUserOpen } = useDisclosure()
  const {
    isOpen: isEditBudgetModalOpen,
    onClose: onEditBudgetModalClose,
    onOpen: onEditBudgetModalOpen,
  } = useDisclosure()

  const [activeGridApi, setActiveGridApi] = useState<GridApi | null>()
  const [deactivatedGridApi, setDeactivatedGridApi] = useState<GridApi | null>()
  const [addedGridApi, setAddedGridApi] = useState<GridApi | null>()
  const [tabIndex, setTabIndex] = useState(0)
  const [showBudgetAlert, setShowBudgetAlert] = useState(false)
  const [showCostCalcAlert, setShowCostCalcAlert] = useState(false)

  const textStrong = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const blue600 = useColorModeValue('blue.600', 'blueDarkMode.600')

  const activeUsers = usersData.filter((row) => row.accountStatus === AccountStatus.ACTIVE)
  const deactivatedUsers = usersData.filter(
    (row) => row.accountStatus === AccountStatus.DEACTIVATED
  )
  const addedUsers = usersData.filter((row) => row.accountStatus === AccountStatus.INVITED)

  const { totalCost, totalBudgetDollars } = useOrgUsage()
  const { costUsageRatio } = useOrgCostUsageRatio()
  const { name: organizationName } = useOrg(organizationId ?? '')
  const { licenseStartDate, licenseEndDate, isCostCalculationEnabled } =
    useOrgUsageSummary(organizationId ?? '') ?? {}

  const vpcAlertMessage = getVpcAlertMessage(true, costUsageRatio ?? 0)

  const tabStyles = {
    borderTopRadius: '6px',
    _selected: { color: textStrong, borderColor: textStrong },
    _focusVisible: { borderColor: blue600, outlineColor: blue600, outlineOffset: '0px' },
  }

  const tabClassName = 'bg-transparent active:bg-surface-0-active'

  const onExportCsv = () => {
    switch (tabIndex) {
      case 0:
        activeGridApi?.exportDataAsCsv({ fileName: 'active-users.csv' })
        break
      case 1:
        deactivatedGridApi?.exportDataAsCsv({ fileName: 'deactivated-users.csv' })
        break
      case 2:
        addedGridApi?.exportDataAsCsv({ fileName: 'pending-users.csv' })
        break
    }
  }

  useEffect(() => {
    setShowBudgetAlert((costUsageRatio ?? 0) >= 0.8)
    setShowCostCalcAlert(!isCostCalculationEnabled)
  }, [costUsageRatio, isCostCalculationEnabled])

  if (accessLevel !== AccessLevel.ORGANIZATION_ADMIN) {
    return <NotFound />
  }

  return (
    <Layout nav={<p className="type-button-md text-text-strong">Admin dashboard</p>}>
      <div className="flex w-full flex-col gap-9 px-14">
        {showBudgetAlert && (
          <PageAlert
            message={vpcAlertMessage.message}
            spanText={vpcAlertMessage.spanText}
            onClose={() => setShowBudgetAlert(false)}
            iconStart={<IconInfo size={20} className="text-red-600" />}
            buttonEnd={
              <LinkBlock
                size="small"
                variant="secondary"
                iconEnd={<FiChevronRight size="16px" />}
                onClick={onEditBudgetModalOpen}
              >
                Edit budget
              </LinkBlock>
            }
          />
        )}
        {showCostCalcAlert && (
          <PageAlert
            message="Cost calculations not enabled yet."
            spanText="To enable them, please activate the cost allocation tag in the AWS Console."
            onClose={() => setShowCostCalcAlert(false)}
            iconStart={<IconInfo size={20} className="text-blue-600" />}
          />
        )}
        <div className="flex w-full flex-row items-stretch gap-6">
          <OrgInfo
            organizationName={organizationName}
            onOpen={onEditBudgetModalOpen}
            licenseStartDate={licenseStartDate}
            licenseEndDate={licenseEndDate}
          />
          <OrgOverview totalCost={totalCost} totalBudgetDollars={totalBudgetDollars} />
        </div>
        <Tabs pt={4} onChange={(index) => setTabIndex(index)}>
          <div className="flex flex-row justify-between">
            <TabList borderColor="transparent">
              <Tab className={tabClassName} {...tabStyles}>
                Active users
              </Tab>
              <Tab className={tabClassName} {...tabStyles}>
                Deactivated users
              </Tab>
              <Tab className={tabClassName} {...tabStyles}>
                Pending users
              </Tab>
            </TabList>
            <div className="flex flex-row gap-5">
              <Button variant="secondary" onClick={onExportCsv}>
                Export .csv
              </Button>
              <Button variant="highContrast" onClick={onAddUserOpen}>
                Add users
              </Button>
            </div>
          </div>

          <TabPanels>
            <TabPanel px={0}>
              <VpcAdminGrid
                setGridApi={setActiveGridApi}
                vpcAdminGridType={AccountStatus.ACTIVE}
                rowData={activeUsers}
              />
            </TabPanel>
            <TabPanel px={0}>
              <VpcAdminGrid
                setGridApi={setDeactivatedGridApi}
                vpcAdminGridType={AccountStatus.DEACTIVATED}
                rowData={deactivatedUsers}
              />
            </TabPanel>
            <TabPanel px={0}>
              <VpcAdminGrid
                setGridApi={setAddedGridApi}
                vpcAdminGridType={AccountStatus.INVITED}
                rowData={addedUsers}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      <AddUsersModal isOpen={isAddUserOpen} onClose={onAddUserClose} />
      <EditBudgetModal isOpen={isEditBudgetModalOpen} onClose={onEditBudgetModalClose} />
    </Layout>
  )
}
export default VpcAdminPanel
