'use client'

import { Badge } from '@components/badge/Badge'
import {
  FormFieldContextValue,
  FormFieldProvider,
  useFormField,
} from '@components/formField/FormFieldContext'
import { IconFrame } from '@components/iconFrameButton/IconFrame'
import { LabelRoot } from '@components/label/Label'
import { cn, tv } from '@utils/tailwindUtils'
import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ForwardedRef,
  forwardRef,
  memo,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react'

import { RadioGroupItemProvider } from './RadioGroup'

const rootVariants = tv({
  slots: {
    base: 'flex cursor-pointer flex-col overflow-hidden rounded-2 bg-surface-0 outline outline-1 -outline-offset-1 outline-border-1 transition-all focus-within:outline-blue-700 focus-within:ring focus-within:ring-focus dark:bg-surface-1',
    content: '',
    radioArea:
      'flex items-center justify-center border-t border-border-1 bg-surface-1 dark:bg-surface-2',
  },
  variants: {
    orientation: {
      vertical: {
        base: 'flex flex-col',
        content: 'flex-grow',
        radioArea: 'p-4 pt-[calc(theme(space.4)-1px)]',
      },
    },
    disabled: {
      true: {
        base: 'cursor-not-allowed bg-surface-disabled text-text-disabled outline-transparent',
        radioArea: 'bg-surface-2',
      },
      false: {
        base: '',
      },
    },
  },
  compoundVariants: [],
})

const LabelRadioCardRoot = forwardRef(
  (
    {
      orientation = 'vertical',
      className,
      children,
      contents,
      ...props
    }: ComponentPropsWithoutRef<typeof LabelRoot> & {
      orientation?: 'vertical'
      contents: ReactNode
    },
    ref: ForwardedRef<HTMLLabelElement>
  ) => {
    const { htmlFor, disabled } = useFormField() || {}
    const v = rootVariants({ orientation, disabled: !!disabled })
    const { base: baseClass, content: contentClass, radioArea: radioAreaClass } = v
    const contextVal = useMemo(() => ({ focusIsVisible: false }), [])
    return (
      <LabelRoot className={cn(baseClass(), className)} ref={ref} htmlFor={htmlFor} {...props}>
        <div className={cn(contentClass())}>{contents}</div>
        <div className={cn(radioAreaClass())}>
          <RadioGroupItemProvider value={contextVal}>{children}</RadioGroupItemProvider>
        </div>
      </LabelRoot>
    )
  }
)

const LabelRadioCardBase = forwardRef(
  (
    {
      heading,
      description,
      icon,
      badgeContent,
      ...props
    }: Omit<ComponentPropsWithoutRef<typeof LabelRadioCardRoot>, 'contents'> & {
      heading?: ReactNode
      description?: ReactNode
      icon?: ReactElement
      badgeContent?: ReactNode
    },
    ref: ForwardedRef<HTMLLabelElement>
  ) => {
    const formField = useFormField()
    const disabled = formField?.disabled
    const content = (
      <div className={cn('flex flex-col p-6', badgeContent && 'gap-y-3', icon && 'gap-y-4')}>
        {icon && <IconFrame variant="outline" size="small" icon={icon} disabled={disabled} />}
        {badgeContent && !icon && (
          <Badge
            variant="subtle"
            round="full"
            className={cn(
              'w-max',
              disabled && 'bg-surface-disabled text-text-disabled outline-border-2'
            )}
          >
            {badgeContent}
          </Badge>
        )}
        <div className="flex flex-col gap-1">
          <div
            className={cn(
              'type-body-200-medium text-text-strong',
              disabled && 'text-text-disabled'
            )}
          >
            {heading}
          </div>
          <div className={cn('type-body-100 text-text-faint', disabled && 'text-text-disabled')}>
            {description}
          </div>
        </div>
      </div>
    )
    return <LabelRadioCardRoot ref={ref} contents={content} {...props} />
  }
)

const LabelRadioCardBaseWithCtx = (
  {
    htmlFor,
    required,
    error,
    disabled,
    ...props
  }: ComponentPropsWithoutRef<typeof LabelRadioCardBase> & FormFieldContextValue,
  ref: ComponentPropsWithRef<typeof LabelRadioCardBase>['ref']
) => {
  return (
    <FormFieldProvider htmlFor={htmlFor} required={required} error={error} disabled={disabled}>
      <LabelRadioCardBase ref={ref} {...props} />
    </FormFieldProvider>
  )
}

export const LabelRadioCard = memo(forwardRef(LabelRadioCardBaseWithCtx))
LabelRadioCard.displayName = 'LabelRadioCard'
