import { Heading, Image, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

interface NotFoundComponentProps {
  errorMessage?: string
}

const NotFoundComponent = (props: NotFoundComponentProps) => {
  return (
    <VStack w="100%" h="100%" align="center" justify="center">
      <Image src="/images/404.svg" h="400px" />
      <br />
      <VStack align="center" spacing={2}>
        <Heading size="md">Experiment #404</Heading>
        <Text fontWeight={400}>
          Hang on, you’re not supposed to be in this part of the lab. Turn back now.
        </Text>
        {props.errorMessage && (
          <Heading size="sm" fontWeight={400}>
            {props.errorMessage}
          </Heading>
        )}
      </VStack>
    </VStack>
  )
}

export default NotFoundComponent
