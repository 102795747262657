import { Button } from '@components/button/Button'
import { IconFile, IconGraduationCap, IconSlackLogo, IconYouTubeLogo } from '@components/icons'

export const HelpfulResourcesSection = () => (
  <div className="flex h-full w-full flex-col gap-7">
    <h2 className="type-display-50 text-text-strong">Helpful resources </h2>
    <div className="flex items-center gap-6">
      <Button variant="secondaryFaint" size="medium" iconStart={<IconFile />} asChild>
        <a href="https://help.cleanlab.ai/" target="_blank" rel="noreferrer">
          Documentation
        </a>
      </Button>
      <Button variant="secondaryFaint" size="medium" iconStart={<IconGraduationCap />} asChild>
        <a href="https://help.cleanlab.ai/tutorials/" target="_blank" rel="noreferrer">
          Tutorials
        </a>
      </Button>
      <Button variant="secondaryFaint" size="medium" iconStart={<IconSlackLogo />} asChild>
        <a href="https://cleanlab.ai/slack/" target="_blank" rel="noreferrer">
          Community Slack
        </a>
      </Button>
      <Button variant="secondaryFaint" size="medium" iconStart={<IconYouTubeLogo />} asChild>
        <a href="https://www.youtube.com/@CleanlabAI" target="_blank" rel="noreferrer">
          Videos
        </a>
      </Button>
    </div>
  </div>
)
