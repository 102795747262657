import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useAddUserNotificationMutation } from '@services/userNotification/mutations'
import React, { useCallback, useState } from 'react'

import { NotificationsModalProps } from './NotificationsModal.types'

const SEND_TO_ALL_USERS = 'send to all users'

const NotificationsModal = (props: NotificationsModalProps) => {
  const { isOpen, onClose } = props

  const [notificationString, setNotificationString] = useState<string>('')
  const [confirmationString, setConfirmationString] = useState<string>('')

  const submitDisabled = !notificationString || confirmationString !== SEND_TO_ALL_USERS

  const invalid = !!notificationString && confirmationString !== SEND_TO_ALL_USERS
  const { mutate: addNotification } = useAddUserNotificationMutation()

  const submitNotification = useCallback(() => {
    addNotification(notificationString)
    setNotificationString('')
    setConfirmationString('')
    onClose()
  }, [addNotification, notificationString, onClose])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Set New User Notification</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="flex-start">
            <Text fontSize="md">Enter your notification message:</Text>
            <Input
              placeholder="User Notification Message goes here"
              value={notificationString}
              onChange={(e) => setNotificationString(e.target.value)}
            />
            <Text fontSize="md">
              <b>Note:</b> Submitting this will push your notification message to all Cleanlab
              Studio Users
            </Text>
            <Text fontSize="md">
              Type "<b>{SEND_TO_ALL_USERS}</b>" to confirm you want to send this notification
            </Text>
            <Input
              placeholder="confirm your notification"
              value={confirmationString}
              onChange={(e) => setConfirmationString(e.target.value)}
              isInvalid={invalid}
            />
            {invalid && (
              <Text fontSize="sm" color="red">
                Write "{SEND_TO_ALL_USERS}" to submit
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justify="space-between" width="100%">
            <Button colorScheme="teal" isDisabled={submitDisabled} onClick={submitNotification}>
              Submit
            </Button>
            <Button colorScheme="gray" onClick={onClose}>
              Close
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NotificationsModal
