import { Box, Input, InputGroup, InputLeftElement, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { BsArrowUpRight } from 'react-icons/bs'

import { DatasheetPaginationInputProps } from './DatasheetPaginationInput.types'

const DatasheetPaginationInput = (props: DatasheetPaginationInputProps) => {
  const {
    pageJumpNum,
    setPageJumpNum,
    setCurrPageNum,
    numRowsPerPage,
    gridApi,
    fetchAndUpdateCurrRowData,
  } = props
  return (
    <Box m={0}>
      <Tooltip label="Jump to page">
        <InputGroup width="120px" size="sm">
          <InputLeftElement pointerEvents="none" children={<BsArrowUpRight color="gray.400" />} />
          <Input
            type="number"
            placeholder="Jump"
            _placeholder={{ color: 'gray.400' }}
            variant="outline"
            borderWidth="1px"
            borderColor="gray.400"
            onChange={(evt) => {
              setPageJumpNum(Number(evt.target.value) - 1)
            }}
            onKeyDown={(e) => {
              e.stopPropagation()
              if (e.key === 'Enter') {
                setCurrPageNum(pageJumpNum)
                if (gridApi) {
                  gridApi.paginationGoToPage(pageJumpNum)
                  // Page and first row might not exist yet, so fetch and wait
                  // until it does exist to select
                  fetchAndUpdateCurrRowData(pageJumpNum * numRowsPerPage, 10)
                }
              }
            }}
          />
        </InputGroup>
      </Tooltip>
    </Box>
  )
}

export default DatasheetPaginationInput
