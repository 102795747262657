import { Card as ChakraCard, CardBody, CardHeader, Heading, HStack } from '@chakra-ui/react'
import React from 'react'

import { CardProps } from './Card.types'

const Card = (props: CardProps) => {
  const { header, headerIcon, width, height, minWidth, maxWidth, children } = props

  return (
    <ChakraCard
      variant="standard"
      w={width}
      h={height}
      minW={minWidth}
      maxW={maxWidth}
      overflow="auto"
    >
      <CardHeader>
        <HStack justifyContent="space-between">
          <Heading fontSize="xs" size="xs" lineHeight="24px">
            {header}
          </Heading>
          {headerIcon}
        </HStack>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </ChakraCard>
  )
}

export default Card
