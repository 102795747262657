import { useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { AddedUser, GetMyselfRes, queryKeys } from '@services/user/constants'
import { IS_VPC_ENVIRONMENT, REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const baseURL = IS_VPC_ENVIRONMENT
  ? `${REACT_APP_CLEANLAB_API_URL}/api/users/vpc`
  : `${REACT_APP_CLEANLAB_API_URL}/api/users`

const axiosClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})

export const useDeleteUsersMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (userIds: string[]) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.post(
        '/deletes',
        { user_ids: userIds },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries(queryKeys.users.all())
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: `Deleted ${variables.length} users successfully.`,
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to delete users' })
    },
  })
}

export const useSetAccessLevelMutation = (props?: {
  onSuccess?: VoidFunction
  onError?: VoidFunction
}) => {
  const { onSuccess, onError } = props || {}
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      userId,
      accessLevel,
    }: {
      userId: string
      accessLevel: 'admin' | 'user'
    }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(
        `/${userId}`,
        { access_level: accessLevel },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      onSuccess?.()
      void queryClient.invalidateQueries(queryKeys.users.all())
    },
    onError: () => {
      onError?.()
    },
  })
}

export const useActivateMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (myself: GetMyselfRes) => void
  onError?: () => void
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (signupToken: string) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.post<GetMyselfRes>(
        '/v1/activate',
        { token: signupToken },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: async (res) => {
      await queryClient.invalidateQueries(queryKeys.users.myself())
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Account activated successfully',
          status: 'success',
        }),
      })
      onSuccess?.(res.data)
    },
    onError: () => {
      onError?.()
    },
  })
}

export const useActivateAccountMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (myself: GetMyselfRes) => void
  onError?: () => void
}) => {
  return useMutation({
    mutationFn: async ({
      firstName,
      lastName,
      email,
      phoneNumber,
      signupOrigin,
      signupAccountType,
      signupDiscoverySource,
    }: {
      firstName: string
      lastName: string
      email: string
      phoneNumber: string
      signupOrigin: string
      signupAccountType: string
      signupDiscoverySource: string
    }) => {
      const accessToken = await auth.getTokenSilently()
      const name = `${firstName} ${lastName}`
      return await axiosClient.post<GetMyselfRes>(
        '/activate_account',
        {
          name: name,
          email: email,
          phone_number: phoneNumber,
          signup_origin: signupOrigin,
          signup_account_type: signupAccountType,
          signup_discovery_source: signupDiscoverySource,
        },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: async (res) => {
      onSuccess?.(res.data)
    },
    onError: () => {
      onError?.()
    },
  })
}

export const useConfirmEmail = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (myself: GetMyselfRes) => void
  onError?: () => void
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async ({ token }: { token: string }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch<GetMyselfRes>(
        '/confirm_email',
        { token: token },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: async (res) => {
      await queryClient.invalidateQueries(queryKeys.users.myself())
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Email activated successfully',
          status: 'success',
        }),
      })
      onSuccess?.(res.data)
    },
    onError: () => {
      onError?.()
    },
  })
}

export const useRegisterForNotification = () => {
  return useMutation({
    mutationFn: async ({
      uploadId,
      eventId,
      cleansetId,
    }: {
      uploadId?: string
      eventId?: string
      cleansetId?: string
    }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.post(
        '/register_for_notify',
        { upload_id: uploadId, sentry_event_id: eventId, cleanset_id: cleansetId },
        createAuthHeaders(accessToken)
      )
    },
  })
}

export const useAddUsers = ({
  onSuccess,
  onError,
}: {
  onSuccess: VoidFunction
  onError: VoidFunction
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ addedUsers }: { addedUsers: AddedUser[] }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.post(
        '/invite',
        { invited_users: addedUsers },
        createAuthHeaders(accessToken)
      )
    },
    onError: () => {
      onError()
    },
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries(queryKeys.users.all())
    },
  })
}

export const useDeactivateUser = ({
  onSuccess,
  onError,
}: {
  onSuccess: VoidFunction
  onError: VoidFunction
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(
        '/deactivate',
        { deactivated_user: userId },
        createAuthHeaders(accessToken)
      )
    },
    onError: () => {
      onError()
    },
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries(queryKeys.users.all())
    },
  })
}

export const useReactivateUser = ({
  onSuccess,
  onError,
}: {
  onSuccess: VoidFunction
  onError: VoidFunction
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(
        '/reactivate',
        { reactivated_user: userId },
        createAuthHeaders(accessToken)
      )
    },
    onError: () => {
      onError()
    },
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries(queryKeys.users.all())
    },
  })
}

export const useDeleteAddedUser = ({
  onSuccess,
  onError,
}: {
  onSuccess: VoidFunction
  onError: VoidFunction
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.delete(userId, createAuthHeaders(accessToken))
    },
    onError: () => {
      onError()
    },
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries(queryKeys.users.all())
    },
  })
}

export const useConfirmUserEmails = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (userIds: string[]) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(
        '/manually_confirm_email',
        { user_ids: userIds },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries(queryKeys.users.all())
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: `Updated ${variables.length} users successfully`,
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to confirm user emails' })
    },
  })
}
