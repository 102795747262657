import { AxiosResponse } from 'axios'

export const downloadFileFromAxiosResponse = (response: AxiosResponse) => {
  if (response.status === 200) {
    const link = document.createElement('a')
    const temp = (response.headers['content-disposition'] as string).split('=')
    link.download = temp[temp.length - 1] // filename
    const blob = new Blob([response.data], { type: 'application/octet-stream' })
    link.href = URL.createObjectURL(blob)
    link.click()

    setTimeout(function () {
      window.URL.revokeObjectURL(link.href)
    }, 2000)
  }
}

export const downloadFileFromUrl = (url: string) => {
  const link = document.createElement('a')
  link.href = url
  link.click()

  setTimeout(function () {
    window.URL.revokeObjectURL(link.href)
  }, 2000)
}
