import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { HStack, Text, Tooltip, useColorModeValue } from '@chakra-ui/react'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'
import React from 'react'
import { FiAlertCircle } from 'react-icons/fi'

import { getWarningTooltipLabel } from './DatasetTableCellRenderer.helpers'

const DatasetTableCellRenderer = (params: ICellRendererParams) => {
  const value = params.value ?? '-'
  const displayValue = typeof value === 'boolean' ? value.toString() : value
  const rowData = params.node.data
  const column = params.colDef?.field
  const columnType = params.colDef?.headerComponentParams?.columnType
  const rowWarnings = rowData._cleanlab_warnings
  const cellContainsWarnings = rowWarnings && column && rowWarnings[column]
  const warningTypeDisplay =
    cellContainsWarnings && rowWarnings[column].warning_type.replace(/_/g, ' ')
  const tooltipBg = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const tooltipColor = useColorModeValue('neutral.100', 'neutralDarkMode.100')

  return cellContainsWarnings ? (
    <Tooltip
      hasArrow
      label={getWarningTooltipLabel(
        rowWarnings[column].warning_type,
        rowWarnings[column].original_value,
        columnType
      )}
      bg={tooltipBg}
      color={tooltipColor}
    >
      <HStack>
        <FiAlertCircle style={{ color: cleanlabColors.yellow[700] }} size={16} />
        <Text
          color="yellow.700"
          fontSize="12px"
          fontWeight={500}
          lineHeight="16px"
          _firstLetter={{ textTransform: 'uppercase' }}
        >
          {warningTypeDisplay}
        </Text>
      </HStack>
    </Tooltip>
  ) : (
    <Text>{displayValue}</Text>
  )
}

export default DatasetTableCellRenderer
