export const matchFieldName = (str: string | undefined, list: readonly string[]) => {
  if (typeof str !== 'string') {
    return false
  }
  const cleanStr = removeDelimeters(str).toLowerCase()
  return list.some((val) => val === cleanStr)
}

/** Removes the following characters from a string:
 * - '\s' white space
 * - '_' underscore
 * - '-' hyphen
 * - '–' en dash
 * - '—' em dash
 */
export const removeDelimeters = (str: string) => {
  return str.replace(/[\s_\-–—]/g, '')
}
