const ModelPerformanceTable = ({ data }: { data: [string, number][] }) => {
  return (
    <div className="type-body-100 mb-12 w-full overflow-hidden rounded-default border border-border-2">
      <table className="w-full [&_td]:p-6">
        <thead className="type-body-100-semibold rounded-t-default border-b bg-surface-2">
          <tr>
            <td width="50%">Performance Metric</td>
            <td width="50%">Score</td>
          </tr>
        </thead>
        <tbody>
          {data.map(([scoreName, scoreValue]) => (
            <tr key={scoreName} className="even:bg-surface-2">
              <td>{scoreName}</td>
              <td>{scoreValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ModelPerformanceTable
