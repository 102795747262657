import UploadDropzone from '@common/uploadDropzone/DatasetUploadDropzone'
import { Button } from '@components/button/Button'
import { FormEventHandler, useCallback, useState } from 'react'
import {
  CreateProjectContainer,
  CreateProjectContent,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  WithSidebarContainer,
} from 'src/pages/projectForm/CreateProject.helpers'

import { DatasetUploadActions } from '../DatasetUpload.helpers'
import { DatasetUploadSidebarStandard } from '../DatasetUploadSidebar'
import { UploadBackLink } from '../UploadBackLink'
import { useProcessDataset } from '../useProcessDataset'

export const LocalUpload = () => {
  const [uploadId, setUploadId] = useState<string | undefined>()
  const [uploadDone, setUploadDone] = useState(false)
  const { startProcessing, processingContent } = useProcessDataset()
  const canSubmit = !!uploadId && uploadDone

  const onSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (canSubmit) {
        startProcessing(uploadId, 'local')
      }
    },
    [canSubmit, startProcessing, uploadId]
  )

  return (
    <CreateProjectContainer>
      {!processingContent && <UploadBackLink />}
      <WithSidebarContainer>
        <CreateProjectContent>
          {processingContent ?? (
            <>
              <HeadingWrapper>
                <PageHeading>Import from your computer</PageHeading>
                <PageDescription>
                  To import your Dataset, select or drag-and-drop a file from your computer.
                </PageDescription>
              </HeadingWrapper>
              <form onSubmit={onSubmit} className="flex flex-col gap-8">
                <div>
                  <UploadDropzone
                    uploadType="dataset"
                    onProcessFile={(error) => {
                      if (error) {
                        setUploadId(undefined)
                        return
                      }
                      setUploadDone(true)
                    }}
                    setUploadId={setUploadId}
                    maxFileSize="10000MB"
                  />
                </div>
                <DatasetUploadActions>
                  <Button type="submit" variant="highContrast" disabled={!canSubmit}>
                    Import
                  </Button>
                </DatasetUploadActions>
              </form>
            </>
          )}
        </CreateProjectContent>
        <DatasetUploadSidebarStandard />
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}
