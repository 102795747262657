export const CLEANSET_TABS = ['Cleanset', 'Analytics']

export const returnInitialTabsState = (initialIndex?: string | null | undefined) => ({
  // null or undefined
  index: initialIndex == null ? 0 : parseInt(initialIndex),
})

export interface TabState {
  index: number
}

export type UseTabsReturnType = {
  state: TabState
  dispatch: React.Dispatch<TabState>
}
