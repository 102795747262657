import './Landing.css'

import CleanlabFullLogoBlack from '@assets/images/branding/cleanlab_logo_full.png'
import CleanlabFullLogoWhite from '@assets/images/branding/cleanlab_logo_full_white.png'
import LandingGraphicFallback from '@assets/images/branding/landing_graphic.png'
import LandingGraphic from '@assets/images/landing/landing_graphic.png'
import {
  Circle,
  HStack,
  Image,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import { Button } from '@components/button/Button'
import Link from '@components/link/Link'
import { useAuth } from '@hooks/useAuth'
import { AUTH_MODE_OAUTH } from '@utils/environmentVariables'
import { useEffect } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useSearchParams } from 'react-router-dom'

// used for SaaS: we show both "Sign up" and "Log in" buttons
const SignUpOrLogIn = () => {
  const { loginWithRedirect } = useAuth()

  return (
    <div className="flex w-full max-w-[254px] justify-between gap-6">
      <Button
        className="flex-1 justify-center"
        size="large"
        variant="primary"
        aria-label="sign up"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: 'signup',
            },
          })
        }
      >
        Sign up
      </Button>
      <Button
        className="flex-1 justify-center"
        size="large"
        variant="secondary"
        aria-label="log in"
        onClick={() => loginWithRedirect({})}
      >
        Log in
      </Button>
    </div>
  )
}

// used for VPC: we only show the log in button, because users can't sign up
// for a new account here, they already need an account with their identity
// provider
const LogIn = () => {
  const { loginWithRedirect } = useAuth()

  return (
    <Stack w="100%" direction={{ base: 'column', sm: 'row' }} spacing="4">
      <Button
        size="large"
        variant="secondary"
        aria-label="log in"
        onClick={() => loginWithRedirect({})}
      >
        Log in
      </Button>
    </Stack>
  )
}

const Landing = () => {
  const { colorMode } = useColorMode()
  const linkColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const logoSrc = colorMode === 'light' ? CleanlabFullLogoBlack : CleanlabFullLogoWhite
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('signup_origin') === 'TLM') {
      localStorage.setItem('signup_origin', 'TLM')
      searchParams.delete('signup_origin')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  return (
    <Layout requiresLogin={false}>
      <div className="flex h-screen w-screen justify-between">
        <div className="h-full w-full lg:flex lg:w-1/2 lg:flex-col lg:items-center">
          <div className="flex h-full flex-col justify-start gap-9 px-8 pt-12 sm:px-12 lg:justify-center lg:px-[120px] lg:pt-0 2xl:max-w-[520px] 2xl:px-0">
            <Stack spacing={{ base: '8', md: '12' }}>
              <div className="flex flex-col gap-9">
                <Link
                  href="https://cleanlab.ai"
                  isExternal
                  className="inline-flex items-center gap-4 self-start"
                >
                  <img src={logoSrc} height="100%" className="max-w-[152px]" alt="Cleanlab Logo" />
                </Link>
                <div className="flex flex-col gap-5">
                  <h1 className="type-display-200 text-text-strong">
                    Data curation for LLMs and the modern AI stack
                  </h1>
                  <h2 className="type-body-300 text-text-faint">
                    <span className="type-body-300-medium text-text-primary">Automatically</span>{' '}
                    find and <span className="type-body-300-medium text-text-primary">fix</span>{' '}
                    errors in your real-world data. Turn unreliable data into reliable models and
                    insights.
                  </h2>
                </div>
              </div>
            </Stack>
            {AUTH_MODE_OAUTH ? <LogIn /> : <SignUpOrLogIn />}
            <HStack
              cursor="pointer"
              gap="6px"
              onClick={() =>
                window.open('https://help.cleanlab.ai/guide/quickstart/web/', '_blank')
              }
            >
              <Text fontSize="16px" fontWeight="500" color={linkColor}>
                Explore docs and tutorials
              </Text>
              <FiExternalLink size={20} />
            </HStack>
          </div>
        </div>
        <div className="landing-graphic__container hidden items-center justify-start bg-surface-1 pl-5 dark lg:flex 2xl:justify-center">
          <Circle className="circle" />
          <Image
            className="h-[744px] w-[725px] max-w-none"
            src={LandingGraphic}
            alt=""
            fallback={<Image className="landing-graphic" src={LandingGraphicFallback} alt="" />}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Landing
