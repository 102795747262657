import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import AgGrid from '@common/grid/AgGrid'
import Loading from '@common/layout/loading/Loading'
import useGridClassname from '@hooks/useGridClassname'
import { centsToDollarsUSD } from '@utils/functions/centsToDollarsUSD'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'
import React, { Suspense, useCallback } from 'react'

import { CurrentPeriodUsageProps } from './PaymentsGrid.types'

const numRowsPerPage = 20

const CurrentPeriodUsage = (props: CurrentPeriodUsageProps) => {
  const { data } = props

  const PaymentNameCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return <Text>{rowData.description}</Text>
  }

  const AmountCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return <Text>{centsToDollarsUSD(rowData.amount)}</Text>
  }

  const PeriodEndCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return <Text>{`${new Date(rowData.period_end * 1000).toDateString()}`}</Text>
  }

  const LoadingMessage = useCallback(() => {
    return <Text size="md">No current billing period charges</Text>
  }, [])

  const columnDefs: ColDef[] = [
    {
      field: 'item',
      cellRenderer: 'paymentNameCellRenderer',
      headerName: 'Item',
    },
    {
      field: 'amount',
      tooltipField: 'amount',
      headerName: 'Amount',
      cellRenderer: 'amountCellRenderer',
      maxWidth: 150,
    },
    {
      field: 'periodEnd',
      headerName: 'Date',
      cellRenderer: 'periodEndCellRenderer',
      maxWidth: 150,
    },
  ]

  const gridOptions: GridOptions = {
    columnDefs: columnDefs,
    components: {
      customLoadingOverlay: LoadingMessage,
      paymentNameCellRenderer: PaymentNameCellRenderer,
      amountCellRenderer: AmountCellRenderer,
      periodEndCellRenderer: PeriodEndCellRenderer,
    },
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    },
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  return (
    <Suspense
      fallback={
        <Loading position="relative" type="chakra-spinner" text="Loading payment history..." />
      }
    >
      <Stack height="40vh" className="home-tour-datasets-table">
        <Flex justify="space-between" align="flex-end">
          <Text mb={1}>Current Billing Period Charges</Text>
        </Flex>
        <>
          <Box className={useGridClassname()} h="100%" w="100%">
            <AgGrid
              gridOptions={gridOptions}
              pagination
              paginationPageSize={numRowsPerPage}
              rowData={data}
              loadingOverlayComponent="customLoadingOverlay"
            />
          </Box>
        </>
      </Stack>
    </Suspense>
  )
}

export default CurrentPeriodUsage
