import { ActionType, CommandProps } from './CommandPalette.types'

type LabelType = string | number | boolean

const HEIGHT_FROM_TOP = 350
const DATA_LABELING_OFFSET = 80
const BANNER_HEIGHT = 50
interface GetPaletteCommandsProps {
  isUnlabeled: boolean
  isMultiSelect: boolean
  suggestedLabel: string
  givenLabel: string
  suggestExclude: boolean
  suggestedAction: ActionType | string
  labelToProbability: Record<string, number>
  hideSuggestion: boolean
  handleActionSelect: (action: string, label?: LabelType, isNewLabel?: boolean) => void
}

interface GetRelabelCommandsProps {
  labels: string[]
  isMultiSelect: boolean
  givenLabel: string
  hideSuggestion: boolean
  suggestedLabel: string
  labelToProba: Record<string, number>
  setInputValue: (value: string) => void
  handleActionSelect: (action: string, label?: LabelType, isNewLabel?: boolean) => void
}

export const EXCLUDE_COMMAND_NAME = 'Exclude from Dataset'

export const getPaletteCommands = (props: GetPaletteCommandsProps): CommandProps[] => {
  const {
    isUnlabeled,
    isMultiSelect,
    suggestedLabel,
    givenLabel,
    suggestExclude,
    suggestedAction,
    labelToProbability,
    hideSuggestion,
    handleActionSelect,
  } = props
  const commands: CommandProps[] = []
  if (isUnlabeled && !isMultiSelect) {
    commands.push({
      category: suggestedAction,
      name: `${suggestedLabel}`,
      shortcut: 'W',
      proba: labelToProbability[suggestedLabel],
      command: () => handleActionSelect(suggestedAction),
    })
  } else if (isMultiSelect) {
    commands.push({
      category: ActionType.AUTO_FIX,
      name: 'Auto-fix selected datapoints',
      shortcut: 'W',
      proba: labelToProbability[suggestedLabel],
      command: () => handleActionSelect(ActionType.AUTO_FIX),
    })
  } else if (!hideSuggestion) {
    commands.push(
      {
        category: suggestExclude ? ActionType.AUTO_FIX : suggestedAction,
        name: `${suggestedLabel}`,
        shortcut: 'W',
        proba: labelToProbability[suggestedLabel],
        command: () => handleActionSelect(suggestedAction),
      },
      {
        category: ActionType.KEEP,
        name: `${givenLabel}`,
        shortcut: 'Q',
        proba: labelToProbability[givenLabel],
        command: () => handleActionSelect(ActionType.KEEP),
      }
    )
  } else {
    commands.push({
      category: ActionType.KEEP,
      name: `${givenLabel}`,
      shortcut: 'Q',
      proba: labelToProbability[givenLabel],
      command: () => handleActionSelect(ActionType.KEEP),
    })
  }
  if (!suggestExclude || isMultiSelect) {
    commands.splice(1, 0, {
      category: ActionType.EXCLUDE,
      name: EXCLUDE_COMMAND_NAME,
      shortcut: 'E',
      command: () => handleActionSelect(ActionType.EXCLUDE),
    })
  }
  return commands
}

export const calculatePaletteListHeight = (
  windowHeight: number,
  isMultiSelect: boolean,
  showDataLabelingWorkflow: boolean | undefined,
  showNotificationBanner: boolean
) => {
  let adjustedHeight = windowHeight - HEIGHT_FROM_TOP
  if (isMultiSelect) {
    adjustedHeight -= BANNER_HEIGHT
  }
  if (showDataLabelingWorkflow) {
    adjustedHeight -= DATA_LABELING_OFFSET
  }
  if (showNotificationBanner) {
    adjustedHeight -= BANNER_HEIGHT
  }
  return `${adjustedHeight}px`
}

export const getRelabelCommands = (props: GetRelabelCommandsProps) => {
  const {
    isMultiSelect,
    givenLabel,
    hideSuggestion,
    suggestedLabel,
    labelToProba,
    handleActionSelect,
    setInputValue,
    labels,
  } = props
  return labels
    .filter((l) =>
      !isMultiSelect ? ![givenLabel, hideSuggestion ? null : suggestedLabel].includes(l) : true
    )
    .sort(
      isMultiSelect
        ? (a, b) => (a > b ? 1 : -1)
        : (a, b) => (labelToProba[b] ?? 0) - (labelToProba[a] ?? 0)
    ) // sort by descending proba
    .map((label, idx) => {
      return {
        category: ActionType.RELABEL,
        name: `${label}`,
        shortcut: idx + 1 <= 9 ? (idx + 1).toString() : null,
        proba: labelToProba[label],
        command: () => {
          isMultiSelect
            ? handleActionSelect('relabel', label)
            : handleActionSelect(`relabel-${label}`)
          setInputValue('')
        },
      }
    })
}

export const getAddLabelCommands = (
  inputValue: string,
  isMultiSelect: boolean,
  handleActionSelect: (action: string, label?: LabelType, isNewLabel?: boolean) => void,
  setInputValue: (value: string) => void
) => {
  return [
    {
      added: true,
      category: ActionType.ADD_LABEL,
      name: `${inputValue}`,
      command: () => {
        isMultiSelect
          ? handleActionSelect('relabel', inputValue, true)
          : handleActionSelect(`relabel-${inputValue}`, inputValue, true)
        setInputValue('')
      },
    },
  ]
}

export const getFilteredCommands = (
  fastMode: boolean,
  allFastCommandsOrdered: CommandProps[],
  inputValue: string,
  allSearchCommands: CommandProps[]
) => {
  if (fastMode) return allFastCommandsOrdered
  return allSearchCommands
    .filter((c) => c.name.toLowerCase().includes(inputValue.toLowerCase()))
    .sort((a: CommandProps, b: CommandProps) => {
      // sort first by substring position, secondarily by proba
      const substring = inputValue.toLowerCase()
      const substringPosDiff = a.name.indexOf(substring) - b.name.indexOf(substring)
      if (substringPosDiff !== 0) {
        return substringPosDiff
      }
      return (b.proba ?? 0) - (a.proba ?? 0)
    })
}
