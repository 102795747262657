// From Lucide: https://lucide.dev/icons/message-circle-question
import { ComponentProps } from 'react'

import { wrapFeatherIcon } from './wrapFeatherIcon'

export const IconMessageCircleQuestion = wrapFeatherIcon(
  ({ size, ...props }: ComponentProps<'svg'> & { size?: number }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-message-circle-question"
      {...props}
      width={size ?? 24}
      height={size ?? 24}
    >
      <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
      <path d="M12 17h.01" />
    </svg>
  )
)
