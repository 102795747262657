import { Spinner } from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import Card from '@components/card/Card'
import { FormField } from '@components/formField/FormField'
import { Input } from '@components/input/Input'
import { Tooltip } from '@components/tooltip/Tooltip'
import { EnrichmentJobType } from '@services/enrichment/constants'
import { useEnrichmentDatasetMutation } from '@services/enrichment/mutations'
import { useMemo, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'

import classNames from './EnrichmentSidebar.module.css'
import { FilterSidebarProps } from './EnrichmentSidebar.types'

const EnrichmentSidebar = ({
  projectId,
  newColumnName: newColumnNameFromProps,
  prompt,
  projectColumns,
  gridApi,
  mostRecentJobStatus,
  mostRecentJobType,
  constrainOutputs: constrainOutputsFromProps,
}: FilterSidebarProps) => {
  const [newColumnName, setNewColumnName] = useState(newColumnNameFromProps ?? '')
  const constrainOutputsAsString = constrainOutputsFromProps?.join(', ') ?? ''
  const [constrainOutputValue, setConstrainOutputValue] = useState(constrainOutputsAsString)
  const [userPrompt, setUserPrompt] = useState(prompt ?? '')
  const isFormUntouched =
    prompt !== undefined &&
    prompt === userPrompt &&
    newColumnNameFromProps === newColumnName &&
    constrainOutputsAsString === constrainOutputValue
  const { mutate: enrichDatasetMutate, isLoading: enrichmentIsRunning } =
    useEnrichmentDatasetMutation({
      projectId,
      onSuccess: () => {
        gridApi?.hideOverlay()
      },
      isPreview: !isFormUntouched,
    })
  const isLoading =
    (mostRecentJobType === EnrichmentJobType.ENRICHMENT && mostRecentJobStatus !== 'SUCCEEDED') ||
    enrichmentIsRunning

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    gridApi?.showLoadingOverlay()

    const topTenIds = gridApi
      ?.getRenderedNodes()
      .filter((node) => node.id !== undefined && node.id !== null)
      .map((node) => Number(node.id) - 1)
      .slice(0, 10)

    enrichDatasetMutate({
      prompt: userPrompt,
      newColumnName,
      indices: topTenIds,
      constrainOutput:
        constrainOutputValue.length > 0
          ? constrainOutputValue.split(',').map((value) => value.trim())
          : undefined,
    })
  }
  const dataForAutocomplete = useMemo(
    () =>
      projectColumns?.map((column) => ({
        id: column,
        display: column,
      })) ?? [],
    [projectColumns]
  )

  return (
    <Card header="Generate a new column" width="25%" minWidth="150px" maxWidth="300px">
      <div className="w-full p-6">
        <form className="flex flex-col gap-6" onSubmit={onSubmit}>
          <FormField label="New column name">
            <Input
              className="mt-4"
              required
              type="text"
              placeholder="New column name"
              value={newColumnName}
              disabled={isLoading}
              onChange={(e) => {
                setNewColumnName(e.target.value)
              }}
            />
          </FormField>
          <FormField label="Prompt">
            {/* Don't use tailwind on this component: It copies all classNames down to its children */}
            <MentionsInput
              classNames={classNames}
              disabled={isLoading}
              required
              value={userPrompt}
              rows={8}
              placeholder='Type your prompt here. You can reference existing columns using the "$" character (e.g. $item_value).'
              onChange={(e) => {
                setUserPrompt(e.target.value)
              }}
            >
              <Mention
                trigger="$"
                data={dataForAutocomplete}
                // eslint-disable-next-line no-template-curly-in-string
                markup="${__display__}"
                displayTransform={(id) => `$\{${id}}`}
                className={classNames.mentions__mention}
              />
            </MentionsInput>
          </FormField>
          <FormField label="Constrain Output (optional)">
            <p className="type-body-100">
              Constrain the output to a particular comma-separated set of answers.{' '}
            </p>
            <Input
              className="mt-4"
              type="text"
              placeholder="e.g. yes, no, maybe"
              value={constrainOutputValue}
              disabled={isLoading}
              onChange={(e) => {
                setConstrainOutputValue(e.target.value)
              }}
            />
          </FormField>
          <Tooltip
            disabled={!isFormUntouched || mostRecentJobType !== EnrichmentJobType.ENRICHMENT}
            content="The enrichment job has finished. Modify any of the values above in order to run
            a new test."
          >
            <Button
              className="mt-2 flex w-full justify-center text-center"
              variant="secondary"
              type="submit"
              disabled={
                userPrompt.length === 0 ||
                newColumnName.length === 0 ||
                isLoading ||
                (isFormUntouched &&
                  mostRecentJobType === EnrichmentJobType.ENRICHMENT &&
                  mostRecentJobStatus === 'SUCCEEDED')
              }
              iconStart={
                isLoading ? (
                  <div className="mr-2 flex justify-center">
                    <Spinner size="sm" />
                  </div>
                ) : undefined
              }
            >
              {isLoading && 'Running'}
              {!isLoading && !isFormUntouched && 'Run test'}
              {!isLoading && isFormUntouched && 'Generate for all rows'}
            </Button>
          </Tooltip>
        </form>
      </div>
    </Card>
  )
}

export default EnrichmentSidebar
