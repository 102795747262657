import { useKeyPress } from '@hooks/useKeyPress'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ActionType } from '../CommandPalette.types'
import { MultiLabelRowProps } from './MultiLabelRow.types'
import SmallMultiLabelRow from './smallMultiLabelRow/SmallMultiLabelRow'
import WideMultiLabelRow from './wideMultiLabelRow/WideMultiLabelRow'

const MultiLabelRow = (props: MultiLabelRowProps) => {
  const {
    label,
    isInSuggested,
    isInGiven,
    updateRowAction,
    probability,
    correctedLabelArray,
    isActionLoading,
    idx,
    showSmallView,
    isFetchingRows,
  } = props

  const [trueSelected, setTrueSelected] = useState(
    correctedLabelArray.length ? correctedLabelArray.includes(label) : isInGiven
  )

  const isError = (!isInGiven && isInSuggested) || (isInGiven && !isInSuggested)
  const isResolved = useMemo(
    () =>
      correctedLabelArray.length !== 0 &&
      ((correctedLabelArray.includes(label) && isInSuggested) ||
        (!correctedLabelArray.includes(label) && !isInSuggested) ||
        (!correctedLabelArray.includes(label) && isInGiven)),
    [correctedLabelArray, label, isInGiven, isInSuggested]
  )
  const labelProbability = probability ?? 0.01 // label probabilities <1% are not saved and evaluate to undefined
  const trueProbability = (labelProbability * 100).toFixed(0)
  const falseProbability = ((1 - labelProbability) * 100).toFixed(0)
  const isInSuggestedString = isInSuggested ? 'True' : 'False'
  const isUpdated =
    (trueSelected && trueProbability < falseProbability) ||
    (!trueSelected && falseProbability < trueProbability)

  // When an error occurs during an action, we want set checked value to snapshot of when action was initially taken
  const onError = useCallback(() => {
    setTrueSelected((prevState) => !prevState)
  }, [])

  const shortcut = idx + 1 <= 9 ? (idx + 1).toString() : null
  const command = useCallback(() => {
    if (!isActionLoading && !isFetchingRows) {
      if (trueSelected) {
        updateRowAction(`${ActionType.REMOVE_TAG_LABEL}-${label}`, onError)
      } else {
        updateRowAction(`${ActionType.ADD_TAG_LABEL}-${label}`, onError)
      }
      setTrueSelected(!trueSelected)
    }
  }, [isActionLoading, isFetchingRows, trueSelected, updateRowAction, label, onError])

  useKeyPress({
    callback: command,
    keys: [shortcut ? shortcut.toLowerCase() : 'NULL'],
  })

  const onTrueClick = () => {
    if (!trueSelected) {
      updateRowAction(`${ActionType.ADD_TAG_LABEL}-${label}`, onError)
      setTrueSelected(true)
    }
  }

  const onFalseClick = () => {
    if (trueSelected) {
      updateRowAction(`${ActionType.REMOVE_TAG_LABEL}-${label}`, onError)
      setTrueSelected(false)
    }
  }

  useEffect(() => {
    setTrueSelected(correctedLabelArray.length ? correctedLabelArray.includes(label) : isInGiven)
  }, [correctedLabelArray, isInGiven, label])

  const multiLabelRowProps = {
    ...props,
    shortcut: shortcut,
    isError: isError,
    isResolved: isResolved,
    isInSuggestedString: isInSuggestedString,
    isUpdated: isUpdated,
    trueProbability: trueProbability,
    falseProbability: falseProbability,
    trueSelected: trueSelected,
    onTrueClick: onTrueClick,
    onFalseClick: onFalseClick,
  }

  return showSmallView ? (
    <SmallMultiLabelRow {...multiLabelRowProps} />
  ) : (
    <WideMultiLabelRow {...multiLabelRowProps} />
  )
}

export default MultiLabelRow
