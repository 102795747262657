import {
  CreateProjectContainer,
  CreateProjectContent,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  WithSidebarContainer,
} from 'src/pages/projectForm/CreateProject.helpers'

import { InlineLink } from '../projectForm/Sidebar'
import { DatasetUploadSidebarStandard } from './DatasetUploadSidebar'
import { UploadTypeOptions } from './uploadTypeOptions/UploadTypeOptions'

export const DatasetUploadSelectType = () => {
  return (
    <CreateProjectContainer>
      <WithSidebarContainer>
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Import data</PageHeading>
            <PageDescription>
              Learn more about how to format your dataset in our{' '}
              <InlineLink to="https://help.cleanlab.ai/guide/concepts/datasets/">
                documentation
              </InlineLink>
              .
            </PageDescription>
          </HeadingWrapper>
          <UploadTypeOptions />
        </CreateProjectContent>
        <DatasetUploadSidebarStandard />
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}
