import { borderRadius } from '@assets/styles/borderRadius'
import { typography } from '@assets/styles/typography'
import { Select } from '@chakra-ui/react'
import { useFormField } from '@components/formField/FormFieldContext'
import { IconExpand } from '@components/icons'
import { cn } from '@utils/tailwindUtils'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

/**
 * Interim component to add consistent styling Chakra's Select component until
 * we can create a custom component
 */
export const CleanlabSelect = forwardRef<
  any,
  ComponentPropsWithoutRef<typeof Select> & { error: boolean }
>(({ error: errorProp, ...props }, ref) => {
  const { error: ctxError } = useFormField() || {}
  const error = errorProp || ctxError || undefined
  return (
    <Select
      ref={ref}
      height="40px"
      rounded={borderRadius['2']}
      _focusVisible={{}}
      borderColor={error ? 'hsl(var(--twc-red-700))' : undefined}
      _hover={{
        borderColor: error ? 'hsl(var(--twc-red-700))' : undefined,
      }}
      {...typography.body['100']}
      className={cn(
        'rounded-2 border-border-1 bg-surface-1 text-text-strong hover:border-border-1 focus-visible:border-blue-700 focus-visible:outline-none focus-visible:ring focus-visible:ring-focus focus-visible:hover:border-blue-700',
        error &&
          'border-red-700 ring ring-red-500 hover:border-red-700 focus-visible:border-red-700 focus-visible:ring-red-500 focus-visible:hover:border-red-700'
      )}
      icon={<IconExpand size={20} className="text-text-primary" />}
      {...props}
    />
  )
})
