import { isSafeNumber, parse } from 'lossless-json'

const customNumberParser = (value: any) => {
  return isSafeNumber(value) ? parseFloat(value) : BigInt(value)
}

export const losslessNumberParser = (response: string) => {
  return parse(response, undefined, customNumberParser)
}

// BigInt values aren't serialized in JSON by default. We can implement our own toJSON() method
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt#use_within_json
;(BigInt.prototype as any).toJSON = function () {
  return this.toString()
}
