import { useContext } from 'react'

import { TabsContext, TabsDispatchContext } from './TabsContext'
import { UseTabsReturnType } from './TabsState'

export const useCleansetTabs = (): UseTabsReturnType => {
  const tabsState = useContext(TabsContext)
  const tabsDispatch = useContext(TabsDispatchContext)

  if (!tabsState) {
    throw new Error('Filter state cannot be null within filter context provider')
  }

  if (!tabsDispatch) {
    throw new Error('Filter dispatch cannot be null within filter dispatch context provider')
  }

  return {
    state: tabsState,
    dispatch: tabsDispatch,
  }
}
