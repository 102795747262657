import QueryInferenceIcon from '@assets/images/deployment/query_inference.png'
import { Box, Center, Image, Link, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import SubscriptionAlert from '@common/alerts/subscriptionAlert/SubscriptionAlert'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import pluralize from 'pluralize'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import ExportModelGrid from '../exportModelGrid/ExportModelGrid'
import { InferenceResultsProps } from './InferenceResults.types'

const InferenceResults = ({
  queryHistory,
  deploymentId,
  fetchQueryHistory,
  cleansetDetails,
  inferenceModel,
  inferenceAccuracy,
  tasktype,
}: InferenceResultsProps) => {
  const {
    freeTrialPeriod,
    numInferenceExportRowsAvailable,
    payingCustomer,
    isIndividualPayingCustomer,
  } = useSubscription()
  const warningColor = useColorModeValue('orange.500', 'orange.300')

  return (
    <VStack h="100%" w="100%" mt="2rem">
      {queryHistory && queryHistory.length > 0 ? (
        <Box w="100%">
          {
            // TODO: also show accuracy for multi-label Projects when model summary is available
            tasktype === Tasktype.MULTICLASS && (
              <Text fontSize="md" fontWeight="semibold" textAlign="left">
                This deployed model uses {inferenceModel} for inference, with an accuracy of{' '}
                {(inferenceAccuracy * 100).toFixed(2)}%.
              </Text>
            )
          }
          {IS_VPC_ENVIRONMENT ? null : (freeTrialPeriod || payingCustomer) &&
            !isIndividualPayingCustomer ? (
            numInferenceExportRowsAvailable > 0 ? (
              <Text color={warningColor} pt={4} fontSize="sm">
                {numInferenceExportRowsAvailable}{' '}
                {pluralize('row', numInferenceExportRowsAvailable)} of inference remaining on your
                {freeTrialPeriod ? ' free trial' : ' plan'}.
              </Text>
            ) : (
              <Text color="red" pt={4} fontSize="sm">
                No model inferences remaining on your free trial. Reach out to{' '}
                <Link href="mailto:sales@cleanlab.ai" isExternal>
                  sales@cleanlab.ai
                </Link>{' '}
                to run more inferences.
              </Text>
            )
          ) : (
            !freeTrialPeriod &&
            !payingCustomer && (
              <SubscriptionAlert
                title="Free trial expired"
                message="to export your inference rows"
              />
            )
          )}
          <ExportModelGrid
            data={queryHistory}
            deploymentId={deploymentId ?? ''}
            refreshData={fetchQueryHistory}
            cleansetDetails={cleansetDetails}
          />
        </Box>
      ) : (
        <Box textAlign="center" h="100%" color="gray.500" pt="10%">
          <Center pb={6}>
            <Image src={QueryInferenceIcon} />
          </Center>
          <Text>
            You haven’t run the model on any new data yet; click “Predict New Labels” to get
            started.{' '}
          </Text>
          <Text pt={6}>
            Upload un-labeled data with the same structure as the original Dataset to infer new
            labels for the data points.
          </Text>
          <Text>Upload a labeled test to evaluate model prediction accuracy</Text>
        </Box>
      )}
    </VStack>
  )
}

export default InferenceResults
