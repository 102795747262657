import { RankedChart } from '../CleansetCharts.types'

export const generateDefaultCheckboxValues = (
  hasNoIssues: boolean,
  hasNoBestPerformers: boolean,
  hasUnlabeled: boolean,
  hasWellLabeled: boolean
) => {
  const defaultCheckboxValues = []
  if (!hasNoIssues) {
    defaultCheckboxValues.push(RankedChart.WorstPerformers)
  }
  if (!hasNoIssues && !hasNoBestPerformers) {
    defaultCheckboxValues.push(RankedChart.BestPerformers)
  }
  if (hasUnlabeled) {
    defaultCheckboxValues.push(RankedChart.MostUnlabeled)
  }
  if (hasWellLabeled) {
    defaultCheckboxValues.push(RankedChart.MostWellLabeled)
  }
  return defaultCheckboxValues
}
