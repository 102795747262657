import { CLEANLAB_FRONTEND_COLUMN } from '../../datasheet/Datasheet.types'
import { LabelType, RowData } from '../EditInterface.types'

export interface IssuesTableProps {
  resolverTextColor: string
  selectedRowData: RowData
  givenLabel: LabelType
  isMultiClass: boolean
}

export interface IssueStackProps {
  issueType: string
  score: number
}

export const issueColumns = [
  CLEANLAB_FRONTEND_COLUMN.ISSUE,
  CLEANLAB_FRONTEND_COLUMN.OUTLIER,
  CLEANLAB_FRONTEND_COLUMN.UNLABELED,
  CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS,
  CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE,
]
