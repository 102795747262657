import RefreshApiKeyPopover from '@common/apiKey/RefreshApiKeyPopover'
import { Input } from '@components/input/Input'
import { ComponentProps, ComponentPropsWithoutRef } from 'react'

export const InputApiKey = ({
  onRefreshApiKey,
  ...props
}: {
  onRefreshApiKey?: ComponentProps<typeof RefreshApiKeyPopover>['onRefresh']
} & ComponentPropsWithoutRef<typeof Input>) => {
  return (
    <Input
      variant="monospace"
      readOnly={true}
      canHide={true}
      canCopy={true}
      contentEnd={
        onRefreshApiKey ? <RefreshApiKeyPopover onRefresh={onRefreshApiKey} /> : undefined
      }
      {...props}
    />
  )
}
