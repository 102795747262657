import NameCellComponent from '@common/misc/nameCellComponent/NameCellComponent'
import { Button } from '@components/button/Button'
import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconArrowRight, IconTrash } from '@components/icons'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { DeploymentRowProps } from '@services/deploymentApi'
import { ICellRendererParams } from 'ag-grid-enterprise'
import { ComponentPropsWithoutRef, Dispatch, SetStateAction } from 'react'
import { Link } from 'react-router-dom'

import testIds from '../../../playwright/test_ids.json'
import { DateOnlyWithTooltip } from '../DateOnlyWithTooltip'
import { DEPLOYMENT_STATUS } from './DeploymentsGrid.types'
import DeploymentStatusCellComponent, {
  DeploymentStatusName,
} from './deploymentStatusCellComponent/DeploymentStatusCellComponent'

type DeploymentCellRendererParams = ICellRendererParams<DeploymentRowProps>

const getDeploymentRoute = (rowData: DeploymentRowProps | undefined) => {
  if (!rowData || !rowData.id || rowData.status !== DEPLOYMENT_STATUS.TRAINED) {
    return undefined
  }
  return `/deployments/${rowData.id}`
}

export const DeploymentNameCellRenderer = (params: DeploymentCellRendererParams) => {
  const rowData = params.node.data
  if (!rowData) {
    return null
  }
  return <NameCellComponent name={rowData.name} id={rowData.id} to={getDeploymentRoute(rowData)} />
}

export const DatasetNameCellRenderer = (params: DeploymentCellRendererParams) => {
  const rowData = params.node.data
  if (!rowData) {
    return null
  }
  return <NameCellComponent name={rowData.dataset_name} id={rowData.dataset_id} />
}

export const DeploymentStatusRenderer = (params: DeploymentCellRendererParams) => {
  const { trackEvent } = useEventTracking()
  const rowData = params.node.data
  if (!rowData) {
    return null
  }
  const status = params.data?.status as DEPLOYMENT_STATUS | undefined
  return (
    <DeploymentStatusCellComponent
      status={status ?? DEPLOYMENT_STATUS.FAILED}
      to={`/deployments/${rowData.id}`}
      onClick={() => {
        trackEvent(MixpanelEvents.clickViewModel, {
          modelId: rowData.id,
          modelName: rowData.name,
        })
      }}
    />
  )
}

export type ActionsCellParams = {
  setTargetRowData: Dispatch<SetStateAction<DeploymentRowProps | null | undefined>>
  onDeleteOpen: () => void
}

export const ActionsCellRenderer = (params: DeploymentCellRendererParams) => {
  const { trackEvent } = useEventTracking()
  const { setTargetRowData, onDeleteOpen } = (params.colDef
    ?.cellRendererParams as ActionsCellParams) || {
    setTargetRowData: () => {},
    onDeleteOpen: () => {},
  }
  const rowData = params.node.data
  const cleansetId = rowData?.cleanset_id

  const deleteLabel = 'Delete model'
  const viewCleansetLabel = 'View Cleanset'

  if (!rowData) {
    return null
  }

  return (
    // Add right pr-[15px] to accomodate scrollbar when system set to always show scrollbar
    <div className="flex h-full flex-shrink-0 items-center gap-5 pr-[15px]">
      {cleansetId && (
        <LinkBlock
          className="type-caption-medium"
          size="small"
          variant="secondary"
          iconEnd={<IconArrowRight />}
          asChild
        >
          <Link
            onClick={() => {
              trackEvent(MixpanelEvents.clickViewCleansetModelDeployment, {
                modelId: rowData.id,
                modelName: rowData.name,
              })
            }}
            to={`/cleansets/${cleansetId}`}
            data-testid={params.rowIndex === 0 ? testIds.dashboardPageCreateProjectButton : ''}
          >
            {viewCleansetLabel}
          </Link>
        </LinkBlock>
      )}
      <div className="flex h-full flex-shrink-0 items-center gap-4">
        <IconFrameButton
          variant="outline"
          size="xSmall"
          icon={<IconTrash />}
          onClick={() => {
            trackEvent(MixpanelEvents.clickGridDeleteModelButton, {
              deploymentData: rowData,
            })
            setTargetRowData(rowData)
            onDeleteOpen()
          }}
          tooltipContent={deleteLabel}
          aria-label={deleteLabel}
          data-testid={testIds.dashboardPageDeleteDatasetButton}
        />
        <ViewModelButton rowData={rowData} />
      </div>
    </div>
  )
}

const ViewModelButton = ({ rowData }: { rowData: DeploymentRowProps }) => {
  const { trackEvent } = useEventTracking()

  const status = rowData.status as DEPLOYMENT_STATUS | undefined
  const deploymentRoute = getDeploymentRoute(rowData)
  const statusDescription = status ? DeploymentStatusName[status] ?? '' : ''
  const label = deploymentRoute
    ? 'View model'
    : `Model ${statusDescription?.toLowerCase() || 'processing'}`

  const commonProps = {
    variant: 'secondaryFaint',
    size: 'xSmall',
    'aria-label': label,
    children: 'Open',
  } as const satisfies Partial<ComponentPropsWithoutRef<typeof Button>>

  return (
    <Tooltip content={label}>
      <span>
        {!deploymentRoute ? (
          <Button {...commonProps} disabled={true} />
        ) : (
          <Button {...commonProps} asChild>
            <Link
              to={deploymentRoute}
              onClick={() => {
                trackEvent(MixpanelEvents.clickViewModel, {
                  modelId: rowData.id,
                  modelName: rowData.name,
                })
              }}
            >
              {commonProps.children}
            </Link>
          </Button>
        )}
      </span>
    </Tooltip>
  )
}

export const UpdatedDateRenderer = (params: DeploymentCellRendererParams) => {
  const timestamp = params.node.data?.updated_at

  if (!timestamp) {
    return null
  }
  return <DateOnlyWithTooltip timestamp={timestamp} />
}
