import AgGrid from '@common/grid/AgGrid'
import Loading from '@common/layout/loading/Loading'
import { Suspense } from 'react'

import { CleanlabGridProps } from './CleanlabGrid.types'

export default function CleanlabGrid(props: CleanlabGridProps) {
  return (
    <Suspense fallback={<Loading position="relative" />}>
      <AgGrid {...props} />
    </Suspense>
  )
}
