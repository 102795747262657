import { cn } from '@utils/tailwindUtils'
import {
  cloneElement,
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactElement,
} from 'react'

export const ButtonInput = forwardRef(
  (
    { className, icon, ...props }: { icon: ReactElement } & ComponentPropsWithoutRef<'button'>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const iconClone = cloneElement(icon, { size: 20 })

    return (
      <button
        ref={ref}
        type="button"
        className={cn(
          'flex cursor-pointer items-center justify-center rounded-1 p-1 text-text-faint hover:text-text-primary focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-[-1px] focus-visible:outline-blue-600 focus-visible:ring focus-visible:ring-focus',
          className
        )}
        {...props}
      >
        {iconClone}
      </button>
    )
  }
)
