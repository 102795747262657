import { IconArrowLeft } from '@components/icons'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { DatasetRowProps } from '@services/datasetApi'
import { cn } from '@utils/tailwindUtils'
import { ComponentPropsWithoutRef, forwardRef } from 'react'
import testIds from 'src/playwright/testIds'

export const PROJECT_NAME_MAX_LEN = 100
const NO_PROJECT_NAME_ERROR = 'Please provide a name for your Project.'
export const checkProjectName = (value: string | undefined) => {
  if (!value) return NO_PROJECT_NAME_ERROR
  if (value.length > PROJECT_NAME_MAX_LEN) return 'Project name is too long.'
  return undefined
}

export const CreateProjectContainer = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        data-testid={testIds.createProjectPageContainer}
        ref={ref}
        // Using top padding of 28px to reach 48px total padding including 20px from <LayoutWrapper>
        className={cn(
          'mx-auto flex min-w-[960px] max-w-[1800px] flex-col justify-start gap-9 px-8 pb-10 pt-[28px]',
          className
        )}
        {...props}
      />
    )
  }
)

export const CreateProjectNav = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex w-full items-center justify-between gap-8', className)}
        {...props}
      />
    )
  }
)

export const CreateProjectContent = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('flex grow flex-col gap-8', className)} {...props} />
  }
)
export const WithSidebarContainer = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('flex items-start gap-11', className)} {...props} />
  }
)
export const HeadingWrapper = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('flex flex-col gap-2', className)} {...props} />
  }
)
export const PageHeading = forwardRef<HTMLHeadingElement, ComponentPropsWithoutRef<'h2'>>(
  ({ className, children, ...props }, ref) => {
    return (
      <h2 ref={ref} className={cn('type-display-100 text-text-strong', className)} {...props}>
        {children}
      </h2>
    )
  }
)

export const PageSubHeading = forwardRef<HTMLHeadingElement, ComponentPropsWithoutRef<'h3'>>(
  ({ className, children, ...props }, ref) => {
    return (
      <h3 ref={ref} className={cn('type-display-50 text-text-strong', className)} {...props}>
        {children}
      </h3>
    )
  }
)

export const PageDescription = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return <p ref={ref} className={cn('type-body-100 text-text-faint', className)} {...props}></p>
  }
)
export const PreviousLink = forwardRef<
  HTMLAnchorElement,
  ComponentPropsWithoutRef<typeof LinkBlock>
>((props, ref) => {
  return (
    <LinkBlock
      ref={ref}
      className="max-w-max grow-0"
      size="medium"
      variant="secondary"
      iconStart={<IconArrowLeft />}
      {...props}
    />
  )
})

export const getProjectNameFromDatasetName = (datasetName?: string) => {
  return datasetName ? `${datasetName} Project` : undefined
}

export const getProjectNameFromDatasetId = (datasetId: string, datasets: DatasetRowProps[]) => {
  const datasetName = datasets.find((d) => d.id === datasetId)?.name
  return getProjectNameFromDatasetName(datasetName)
}
