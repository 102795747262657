export type LoaderGlobals = {
  pageTitle?: string
}

export type LoaderData<Data extends Record<string, any> = Record<string, never>> = Data & {
  globals?: LoaderGlobals
}

/** Convenience function for returning JSON-ified data from a react-query loader */
export const dataLoaderJsonResponse = <Data extends Record<string, any>>(
  data: LoaderData<Data>
) => {
  return new Response(JSON.stringify(data), {
    status: 200,
    headers: {
      'Content-Type': 'application/json; utf-8',
    },
  })
}
