const BLACK = '#000'
const WHITE = '#fff'

const Colors = {
  joyride: {
    primaryColor: '#319795',
    light: {
      bg: WHITE,
      text: BLACK,
    },
    dark: {
      bg: '#4A5568',
      text: WHITE,
    },
  },
  CommandPalette: {
    light: {
      border: '#5c5c5c',
      text: BLACK,
    },
    dark: {
      border: '#59595b',
      text: 'rgb(172, 172, 172)',
    },
  },
  PaletteSuggestion: {
    bgPressed: '#bebebe',
    activeBG: '#919191',
    highlightSubstringColor: '#598cef',
    light: {
      bg: '#f8f6f6',
      hover: 'gray.100',
    },
    dark: {
      bg: '#2c313a',
      hover: 'gray.700',
    },
  },
  EditInterface: {
    light: {
      bg: 'gray.50',
    },
    dark: {
      bg: '#2c313a',
    },
  },
  CorrectionsHeatmap: {
    emptyColor: '#ffffff',
  },
  ExportDatasheetModal: {
    excludeText: 'rgba(246,89,120,0.85)',
  },
}

export default Colors
