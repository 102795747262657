import TopNavTabs from '@common/layout/topNavTabs/TopNavTabs'
import React from 'react'

import { DatasetDetailsNavProps } from './datasetDetailsNav.types'

const DatasetDetailsNav = ({ tabIndex }: DatasetDetailsNavProps) => {
  return <TopNavTabs tabNames={['Dataset', 'Metadata']} tabIndex={tabIndex} />
}

export default DatasetDetailsNav
