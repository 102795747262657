import { useToast } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import uploadApiService from '@services/upload/v1/uploadApi'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ProcessingDataset from '../datasetDetails/processingDataset/ProcessingDataset'

export const useProcessDataset = () => {
  const { trackEvent } = useEventTracking()
  const navigate = useNavigate()
  const toast = useToast()
  const [datasetId, setDatasetId] = useState<string | undefined>()
  const [uploadId, setUploadId] = useState<string | undefined>()
  const isProcessing = !!uploadId

  // Redirect to Dataset details once a datasetId is available
  useEffect(() => {
    if (datasetId) {
      navigate(`/datasets/${datasetId}`)
    }
  }, [datasetId, navigate])

  const handleFinishProcessing = useCallback(async () => {
    try {
      if (!uploadId) {
        return
      }
      const res = await uploadApiService.getDatasetId(uploadId)
      const datasetId = res.dataset_id
      trackEvent(MixpanelEvents.clickGoToDatasetDetailedView, { datasetId })
      setDatasetId(datasetId)
    } catch (err) {
      const error = err as AxiosError
      notifyAxiosError(toast, error)
      setDatasetId(undefined)
      setUploadId(undefined)
    }
  }, [toast, trackEvent, uploadId])

  const startProcessing = useCallback(
    async (uploadId: string, mode: 'url' | 'local') => {
      setUploadId(uploadId)
      if (mode === 'local') {
        try {
          await uploadApiService.confirmUpload(uploadId)
        } catch (err) {
          const error = err as AxiosError
          notifyAxiosError(toast, error)
        }
      }
    },
    [toast]
  )

  const uploadState = useMemo(
    () => ({
      isProcessing,
      startProcessing,
      processingContent: isProcessing ? (
        <ProcessingDataset
          uploadId={uploadId}
          initialUploadPhase={0}
          handleFinishProcessing={handleFinishProcessing}
        />
      ) : null,
    }),
    [isProcessing, startProcessing, uploadId, handleFinishProcessing]
  )

  return uploadState
}
