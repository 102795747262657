import { Button as ChakraButton } from '@chakra-ui/react'
import React from 'react'

import { PrimaryButtonWithIconProps } from './PrimaryButtonWithIcon.types'

const PrimaryButtonWithIcon = (props: PrimaryButtonWithIconProps) => {
  const { children, isDisabled, isLoading, height, fontSize, leftIcon, rightIcon, onClick } = props
  return (
    <ChakraButton
      isDisabled={isDisabled}
      isLoading={isLoading}
      height={height}
      fontSize={fontSize}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
      variant="primary"
    >
      {children}
    </ChakraButton>
  )
}

export default PrimaryButtonWithIcon
