import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  useColorModeValue,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'

import testIds from '../../../playwright/test_ids.json'
import { RerunConfirmationAlertProps } from './RerunConfirmationAlert.types'

const RerunConfirmationAlert = (props: RerunConfirmationAlertProps) => {
  const { isOpen, onClose, cancelRef, confirmCleanButton, issuesCorrectedPercentage } = props

  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent bg={modalBg}>
        <AlertDialogHeader>Improve issues found using your edits</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          This operation re-runs all analyses using the edits you’ve made to improve your Dataset.
          This is time intensive &ndash; the AI models that detect issues are re-trained. We
          recommend correcting at least 5% of the issues in your Dataset first. Currently, you’ve
          corrected {issuesCorrectedPercentage}% of the issues found in your Dataset.
        </AlertDialogBody>
        <AlertDialogFooter>
          <SecondaryButton height="36px" onClick={onClose} aria-label="do not improve issues found">
            Cancel
          </SecondaryButton>
          <Box pl={3}>
            <PrimaryButton
              height="36px"
              onClick={confirmCleanButton}
              aria-label="improve issues found"
              dataTestId={testIds.cleansetRerunCleanlabModalButton}
            >
              Improve Issues Found
            </PrimaryButton>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default RerunConfirmationAlert
