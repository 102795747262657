import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys } from '@services/cleanset/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/cleansets`,
  withCredentials: true,
})

export const useDeleteCleansetMutation = (projectId: string) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToast()
  return useMutation({
    mutationFn: async ({ cleansetId }: { cleansetId: string; numCleansets: number }) => {
      const accessToken = await auth.getTokenSilently()
      return axiosClient.delete(`/${cleansetId}`, createAuthHeaders(accessToken))
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries(queryKeys.cleanset.projectId(projectId))
      if (variables.numCleansets === 1) {
        // Last cleanset deleted
        navigate('/')
      }
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to delete cleanset' })
    },
  })
}

export const useCreateCleansetMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: AxiosResponse) => void
  onError?: (err: AxiosError) => void
}) => {
  return useMutation({
    mutationFn: async ({
      projectId,
      datasetId,
      cleansetId,
    }: {
      projectId: string
      datasetId: string
      cleansetId: string
    }) => {
      const accessToken = await auth.getTokenSilently()
      return axiosClient.post(
        '/clean',
        {
          project_id: projectId,
          dataset_id: datasetId,
          cleanset_id: cleansetId,
        },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: (res) => {
      onSuccess?.(res)
    },
    onError: (err) => {
      onError?.(err as AxiosError)
    },
  })
}
