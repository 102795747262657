import { FormControl, FormLabel, Switch, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

import { ShowDataWarningsSwitchProps } from './ShowDataWarningsSwitch.types'

const ShowDataWarningsSwitch = (props: ShowDataWarningsSwitchProps) => {
  const { showDataWarnings, setShowDataWarnings, isDisabled } = props

  return (
    <FormControl display="flex" alignItems="center" w="175px" isDisabled={isDisabled}>
      <FormLabel
        htmlFor="show-data-warnings"
        mb="0"
        fontWeight={600}
        fontSize="12px"
        lineHeight="16px"
        color={useColorModeValue('neutral.900', 'neutralDarkMode.900')}
      >
        Show data warnings
      </FormLabel>
      <Switch
        id="show-data-warnings"
        isChecked={showDataWarnings}
        size="sm"
        colorScheme="blue"
        onChange={() => {
          setShowDataWarnings(!showDataWarnings)
        }}
      />
    </FormControl>
  )
}

export default ShowDataWarningsSwitch
