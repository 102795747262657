export const getUpgradePlanModalTitle = (
  isIndividualPayingCustomer: boolean,
  upgradeComplete: boolean,
  isEnterprisePlan: boolean,
  freeTrialPeriod: boolean
) => {
  if (!isIndividualPayingCustomer && !upgradeComplete && !isEnterprisePlan && !freeTrialPeriod) {
    return <>Free Trial Expired &ndash; Upgrade Plan</>
  } else if (!upgradeComplete) {
    return <>Upgrade Plan</>
  } else {
    return <></>
  }
}
