import { Box, Radio, useColorModeValue } from '@chakra-ui/react'
import MaxWidthText from '@common/misc/maxWidthText/MaxWidthText'
import { Badge } from '@components/badge/Badge'
import {
  actionToHue,
  categoryDisplay,
} from 'src/pages/cleanset/datasheet/actionCellRenderer/actionCellTag/ActionCellTag.types'

import { FilterRadioButtonProps } from './FilterRadioButton.types'

const FilterRadioButton = (props: FilterRadioButtonProps) => {
  const { text, onSelect, isTag } = props

  const menuBg = useColorModeValue('white', 'neutralDarkMode.100')
  const borderColor = useColorModeValue('blue.500', 'blueDarkMode.500')

  return (
    <Box bg={menuBg} w="100%" p="0px 12px" borderRadius="lg">
      <Radio
        name={text}
        value={text}
        colorScheme="blue"
        borderColor={borderColor}
        w="100%"
        size="md"
        spacing="6px"
        onChange={onSelect}
      >
        <MaxWidthText maxWidth="200px" showLabel={false}>
          {isTag ? (
            <Badge round="small" variant="actionSubtle" hue={actionToHue[text]} size="small">
              {categoryDisplay[text] || '-'}
            </Badge>
          ) : (
            text
          )}
        </MaxWidthText>
      </Radio>
    </Box>
  )
}

export default FilterRadioButton
