import {
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { Button } from '@components/button/Button'

import { NewLabelAddedModalProps } from './NewLabelAddedModal.types'

const NewLabelAddedModal = (props: NewLabelAddedModalProps) => {
  const { isOpen, onClose, setHideNewLabelAddedModal } = props
  const textStrong = useColorModeValue('neutral.800', 'neutralDarkMode.800')

  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>New label added!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          You successfully created a new label. For best results, apply this label to several
          examples. New labels or other manual edits are not automatically reflected in the current
          analysis. After your edits are complete, to run another analysis, click “Improve Issues
          Found.”
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-between" w="100%">
            <Checkbox
              pl={1}
              onChange={(e) => {
                setHideNewLabelAddedModal(e.target.checked)
              }}
            >
              <Text color={textStrong}>Do not show next time</Text>
            </Checkbox>
            <Button
              onClick={() => {
                onClose()
              }}
              size="medium"
              variant="primary"
            >
              Continue
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NewLabelAddedModal
