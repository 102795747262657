import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import { IconRefresh } from '@components/icons'
import { ButtonInput } from '@components/input/ButtonInput'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useState } from 'react'

import { RefreshApiKeyPopoverProps } from './RefreshApiKeyPopover.types'

const RefreshKeyModal = ({
  isOpen,
  onClose,
  onRefresh,
}: { onRefresh: () => void } & UseModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generate new API key?</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="type-body-300 text-text-faint">
          <p>The current API key will no longer be usable. This process is irreversible.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="highContrast"
            onClick={() => {
              onRefresh()
              onClose()
            }}
            aria-label="confirm generate a new API key"
          >
            Generate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const BUTTON_LABEL = 'Generate new API key'

const RefreshApiKeyPopover = ({ onRefresh }: RefreshApiKeyPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Tooltip placement="top" content={BUTTON_LABEL}>
        <ButtonInput
          icon={<IconRefresh />}
          aria-label={BUTTON_LABEL}
          onClick={() => setIsOpen(true)}
        />
      </Tooltip>
      <RefreshKeyModal isOpen={isOpen} onClose={() => setIsOpen(false)} onRefresh={onRefresh} />
    </>
  )
}

export default RefreshApiKeyPopover
