import Loading from '@common/layout/loading/Loading'
import { ProjectDemoSelector } from '@common/projectDemos/ProjectDemoSelector'
import { useTemplateProjectsPreview } from '@services/templateProjects/queries'
import { Suspense } from 'react'
import { Link } from 'react-router-dom'

import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  HeadingWrapper,
  PageHeading,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { ProgressStepper } from './ProgressStepper'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionWelcome,
} from './Sidebar'

export const DEMO_PROJECT_TYPES = ['text', 'tabular', 'image'] as const
export type DemoProjectType = (typeof DEMO_PROJECT_TYPES)[number]

const THIS_STEP = 1
const STEP_COUNT = 2

const Fetcher = () => {
  const { data } = useTemplateProjectsPreview({ useQueryOptions: { suspense: true } })

  if (!data) {
    return null
  }

  return (
    <div className="flex flex-col gap-8">
      <ProjectDemoSelector variant="createProject" templates={data} />
      <ProjectWizardActions step={THIS_STEP} stepCount={STEP_COUNT} />
    </div>
  )
}

const CreateProjectFromDemo = () => {
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          <Link to="/clean">Create a new Project</Link>
        </PreviousLink>
        <ProgressStepper stepNum={THIS_STEP} stepCount={STEP_COUNT} />
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Select a demo Dataset</PageHeading>
          </HeadingWrapper>
          <Suspense fallback={<Loading />}>
            <Fetcher />
          </Suspense>
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcome />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/">Cleanlab Studio docs</ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}

export default CreateProjectFromDemo
