import { cleanlabColors } from '@assets/styles/CleanlabColors'
import {
  Box,
  Center,
  HStack,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import UserQuotaAlert from '@common/alerts/userQuotaAlert/UserQuotaAlert'
import { UserQuotaAlertInfo } from '@common/alerts/userQuotaAlert/UserQuotaAlert.types'
import EditableTitle from '@common/editableTitle/EditableTitle'
import { getFrontendColumnByFilterType } from '@common/filter/presetFilterRow/PresetFilterRow.helpers'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import Loading from '@common/layout/loading/Loading'
import { Badge } from '@components/badge/Badge'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useEventTracking } from '@hooks/useEventTracking'
import { useKeyPress } from '@hooks/useKeyPress'
import useKeyPressState from '@hooks/useKeyPressState'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { notifyAxiosError, showQuotaWarning, SOFT_QUOTA_STATUS } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { queryKeys as cleansetQueryKeys } from '@services/cleanset/constants'
import { useCreateCleansetMutation } from '@services/cleanset/mutations'
import { useCleansetsFromProject } from '@services/cleanset/queries'
import { queryKeys, RowCountType } from '@services/datasheet/constants'
import { useBatchActionMutation, useSaveGridLayout } from '@services/datasheet/mutations'
import {
  useCheckHighlyUnlabeledClass,
  useIssueColumns,
  useLabelOptions,
  useMetadataColumns,
  useRowCount,
} from '@services/datasheet/queries'
import datasheetApiService from '@services/datasheetApi'
import { useUpdateProjectNameMutation } from '@services/project/mutations'
import { createServerSideDatasource } from '@utils/functions/createServerSideDatasource'
import { ColDef, Column, IRowNode, SortDirection } from 'ag-grid-community'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Modality, Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import BatchActionsModal from '../batchActionsModal/BatchActionsModal'
import {
  getBatchActionRequestPath,
  getBatchActionVerb,
  getSortModel,
} from '../batchActionsModal/BatchActionsModal.helpers'
import { BatchAction } from '../batchActionsModal/BatchActionsModal.types'
import { setPresetFilterColumnConfig } from '../Cleanset.helpers'
import { PresetConfig } from '../Cleanset.types'
import { CleansetContext } from '../CleansetContext'
import DataLabelingIntroModal from '../dataLabelingIntroModal/DataLabelingIntroModal'
import DataLabelingStepper from '../dataLabelingStepper/DataLabelingStepper'
import DeployModelModal from '../deployModelModal/DeployModelModal'
import ExportDatasheetModal from '../exportDatasheetModal/ExportDatasheetModal'
import FilterMenu from '../filterMenu/FilterMenu'
import { useFilters } from '../filterReducer/useFilters'
import FilterSidebar from '../filterSidebar/FilterSidebar'
import { UNSUPERVISED_PRESET_FILTERS } from '../filterSidebar/FilterSidebar.types'
import IssuesResolvedSlider from '../issueStats/issuesResolvedSlider/IssuesResolvedSlider'
import RerunCleanlabStatus from '../rerunCleanlabStatus/RerunCleanlabStatus'
import { useCleansetTabs } from '../tabsReducer/useCleansetTabs'
import {
  applyDataLabelingFilters,
  applyHeaderHeight,
  applyThresholdFilter,
  createAutoLabelColumn,
  generateSplitGrid,
  getDisplayedColumnsForExport,
  goToPageAndSelectRow,
  setColumnSorting,
} from './Datasheet.helpers'
import { CLEANLAB_FRONTEND_COLUMN, DatasheetProps, NUM_ROWS_PER_PAGE } from './Datasheet.types'
import DatasheetActionButtons from './datasheetActionButtons/DatasheetActionButtons'
import { constructDatasheetGridOptions } from './DatasheetGridConfig'
import DatasheetPaginationInput from './datasheetPaginationInput/DatasheetPaginationInput'
import LastActionWidget from './lastActionWidget/LastActionWidget'
import { LastActionState } from './lastActionWidget/LastActionWidget.types'
import Resolver from './resolver/Resolver'

const Datasheet = (props: DatasheetProps) => {
  const queryClient = useQueryClient()
  const {
    cleansetId,
    projectDetails,
    allColumns,
    originalDatasetColumns,
    setScrollToSummary,
    accuracy,
    gridApi,
    setGridApi,
    columnApi,
    setColumnApi,
    firstGridDataRendered,
    setFirstGridDataRendered,
    isComplete,
    gridContext,
    hasPrevGridState,
  } = props

  const {
    showDataLabelingWorkflow,
    lastRowAboveThreshold,
    unlabeledSuggestedLabelConfidenceThreshold,
    isLoading: isDataLabelingWorkflowLoading,
  } = useCheckHighlyUnlabeledClass({
    cleansetId: cleansetId,
  })

  const [thresholdRowIndex, setThresholdRowIndex] = useState<number | null>(lastRowAboveThreshold)

  const initialGridOptions: GridOptions = useMemo(() => {
    return constructDatasheetGridOptions(
      projectDetails,
      allColumns,
      originalDatasetColumns,
      gridContext.current
    )
  }, [projectDetails, allColumns, originalDatasetColumns, gridContext])

  const { dispatch } = useFilters()

  const [gridOptions, setGridOptions] = useState<GridOptions>(initialGridOptions)
  const [resolverFailure, setResolverFailure] = useState<boolean>(false)

  const [currPageNum, setCurrPageNum] = useState(0)
  const [pageJumpNum, setPageJumpNum] = useState(0)
  const [isClicking, setIsClicking] = useState(false)
  const [isRightClick, setIsRightClick] = useState(false)
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [lastSelectedRow, setLastSelectedRow] = useState<IRowNode | null>(null)
  const [lastActionState, setLastActionState] = useState<LastActionState | null>(null)
  const [isFetchingRows, setIsFetchingRows] = useState(false)
  const [hasStartedDataLabelingWorkflow, setHasStartedDataLabelingWorkflow] = useState(false)
  const [dataLabelingActiveStep, setDataLabelingActiveStep] = useState<number | null>(null)
  const [quotaAlert, setQuotaAlert] = useState<UserQuotaAlertInfo | null>(null)
  const [dataLabelingWorkflowLoading, setDataLabelingWorkflowLoading] = useState(false)
  const [thresholdValueScore, setThresholdValueScore] = useState<number | null>(null)
  const [hasCreatedAutoLabelColumn, setHasCreatedAutoLabelColumn] = useState(false)
  const [hasAutoSelectedThresholdRow, setHasAutoSelectedThresholdRow] = useState(false)
  const [thresholdButtonClicked, setThresholdButtonClicked] = useState(false)
  const [hasStartedLabeling, setHasStartedLabeling] = useState(false)
  const [prevPresetConfig, setPrevPresetConfig] = useState<PresetConfig | null>(null)

  const tabState = useCleansetTabs()

  const searchParams = new URLSearchParams(window.location.search)

  const resolverOpen = searchParams.get('resolver')

  const [editMode, setLocalEditMode] = useState(resolverOpen === 'true')

  const setEditMode = useCallback(
    (open: boolean) => {
      const curUrl = new URL(window.location.href)
      curUrl.searchParams.delete('resolver')
      curUrl.searchParams.append('resolver', open ? 'true' : 'false')
      window.history.replaceState({}, '', curUrl)
      setLocalEditMode(open)
    },
    [setLocalEditMode]
  )

  const [isFilterSidebarCollapsed, setFilterSidebarCollapsed] = useState<boolean>(false)
  const handleFilterSidebarCollapse = () => {
    setFilterSidebarCollapsed(!isFilterSidebarCollapsed)
    // Find last displayed column and set column's flex to 1 when sidebar is closing
    if (!isFilterSidebarCollapsed) {
      const displayedColumns = columnApi?.getAllDisplayedColumns()
      if (displayedColumns && displayedColumns.length) {
        const lastColumn = displayedColumns[displayedColumns.length - 1] as Column
        lastColumn.setFlex(1)
      }
    }
  }

  // misc state
  const [showTooltip, setShowTooltip] = useState(false)

  const tableColor = useColorModeValue('ag-theme-balham', 'ag-theme-balham-dark')
  const userLabeledTagColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const userLabeledTagBorderColor = useColorModeValue('neutral.400', 'neutralDarkMode.400')

  const toast = useToast()
  const cleansetInfo = useContext(CleansetContext)
  const { numRows: numResolved } = useRowCount(
    cleansetId,
    RowCountType.RESOLVED,
    gridApi,
    firstGridDataRendered
  )
  const { numRows: numPreviouslyResolved } = useRowCount(
    cleansetId,
    RowCountType.PREVIOUSLY_RESOLVED,
    gridApi,
    firstGridDataRendered
  )
  const { numRows: numIssuesResolved } = useRowCount(
    cleansetId,
    RowCountType.ISSUES_RESOLVED,
    gridApi,
    firstGridDataRendered
  )

  const { numRows: numNonFiltered } = useRowCount(
    cleansetId,
    RowCountType.CUSTOM,
    gridApi,
    firstGridDataRendered
  )

  const { numRows: numUnlabeled } = useRowCount(
    cleansetId,
    RowCountType.UNLABELED,
    gridApi,
    firstGridDataRendered
  )

  const rowCountsFetched =
    numUnlabeled !== -1 &&
    numResolved !== -1 &&
    numPreviouslyResolved !== -1 &&
    numNonFiltered !== -1

  const { data: issueColumns } = useIssueColumns(cleansetId, firstGridDataRendered)
  const { data: metadataColumns } = useMetadataColumns(cleansetId, firstGridDataRendered)

  const cleansets = useCleansetsFromProject({
    projectId: projectDetails.projectId,
    useQueryOptions: { enabled: dataLabelingActiveStep === 2 },
    onError: () =>
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Failed to fetch Project status',
          status: 'warning',
        }),
      }),
  })

  // Track currently selected nodes.
  const selectedNodes = gridApi?.getSelectedNodes()
  const selectedRowIndex = selectedNodes?.[0]?.rowIndex ?? 0

  const isMultiLabel = projectDetails.tasktype === Tasktype.MULTILABEL

  const relabelOptions = useLabelOptions(projectDetails.labels, cleansetId, projectDetails.tasktype)

  const { showNotificationBanner } = useSubscription()

  const { trackEvent } = useEventTracking()

  const navigate = useNavigate()

  const isMacOS = navigator.userAgent.toLowerCase().includes('mac')

  // Multi-select keys
  const pressCmd = useKeyPressState('Meta', true)
  const pressCtrl = useKeyPressState('Control', true, isMacOS)
  const [pressedComboUpArrow, setPressedComboUpArrow] = useState(false)
  const [pressedComboDownArrow, setPressedComboDownArrow] = useState(false)

  const multiSelectKeyPressed =
    isClicking || pressCmd || pressCtrl || pressedComboDownArrow || pressedComboUpArrow

  const shouldAutoSelectRow =
    (showDataLabelingWorkflow !== undefined || projectDetails.tasktype === Tasktype.UNSUPERVISED) &&
    (!showDataLabelingWorkflow || (dataLabelingActiveStep ?? 0) > 0)

  const stopInputPropagation = (event: Event) => {
    event.stopImmediatePropagation()
  }

  // Modal useDisclosures
  const { isOpen: isExportOpen, onOpen: onExportOpen, onClose: onExportClose } = useDisclosure()
  const {
    isOpen: isBatchActionsOpen,
    onOpen: onBatchActionsOpen,
    onClose: onBatchActionsClose,
  } = useDisclosure()

  const {
    isOpen: isDeployModelOpen,
    onOpen: onDeployModelOpen,
    onClose: onDeployModelClose,
  } = useDisclosure()

  const {
    isOpen: isDataLabelingIntroOpen,
    onOpen: onDataLabelingIntroOpen,
    onClose: onDataLabelingIntroClose,
  } = useDisclosure()

  // Find AG-Grid's column search input and attach keydown listener to prevent propagation
  const agGridColumnInput = document.querySelector('[aria-label="Filter Columns Input"]')
  agGridColumnInput?.addEventListener('keydown', stopInputPropagation)
  // Remove listener when page is left
  window.onunload = () => document.body.removeEventListener('keydown', stopInputPropagation)

  // Note: our useKeyPress hook does not work correctly with cmd + key actions
  // The cmd key blocks keyUp events, so other key press stays true forever
  // onkeydown fires in browsers with a keydown event when a key is pressed.
  // This is shorthand for window.addEventListener("keydown", (event) => {do something})
  onkeydown = (e) => {
    // Regular expression for any key name except ArrowUp, ArrowDown, Control, and Meta (cmd key)
    const commandRegex = RegExp('^(?!.*(ArrowUp|ArrowDown|Meta|Control)).*$')
    if (e.shiftKey) {
      e.stopPropagation()
    }
    // Detect multiple keys at once. Check for cmd (metaKey) or ctrl held while up/down keys are pressed
    if ((e.metaKey || e.ctrlKey) && e.key === 'ArrowUp') {
      setPressedComboUpArrow(true)
    } else if ((e.metaKey || e.ctrlKey) && e.key === 'ArrowDown') {
      setPressedComboDownArrow(true)
    } else if ((e.metaKey || e.ctrlKey) && commandRegex.test(e.key)) {
      e.stopImmediatePropagation()
    } else {
      setPressedComboDownArrow(false)
      setPressedComboUpArrow(false)
    }
  }

  const { mutate: saveGridLayout } = useSaveGridLayout({
    cleansetId: cleansetId,
    columnApi: columnApi,
    gridApi: gridApi,
  })

  const { mutate: createCleanset, isLoading: isCreateCleansetLoading } = useCreateCleansetMutation({
    onSuccess: (res) => {
      if (res.data.status === SOFT_QUOTA_STATUS) {
        showQuotaWarning(toast, res.data.description)
      }

      void queryClient.invalidateQueries(
        cleansetQueryKeys.cleanset.projectId(projectDetails.projectId)
      )

      setDataLabelingWorkflowLoading(true)
      setTimeout(() => {
        setDataLabelingActiveStep(2)
        setDataLabelingWorkflowLoading(false)
      }, 1000)
    },
    onError: (error) => {
      if (error.response?.status === 429) {
        setQuotaAlert({
          // @ts-ignore TODO(Luke) rewrite with 422
          code: error.response.data.code,
        })
        // @ts-ignore TODO(Luke) rewrite with 422
      } else if (error.response?.data.code === 'nonrepresented_classes') {
        toast({
          ...defaultToastAlertProps,
          render: renderChakraToastAlert({
            heading: 'Error: nonrepresented classes',
            // @ts-ignore TODO(Luke) rewrite with 422
            description: error.response.data.error,
            status: 'warning',
          }),
        })
      } else {
        notifyAxiosError(toast, error)
      }
    },
  })

  const { mutate: updateProject, isLoading: isProjectUpdating } = useUpdateProjectNameMutation(
    () => gridApi && gridApi.refreshServerSide()
  )

  // Fetch curr row data for edit example interface
  const fetchAndUpdateCurrRowData = useCallback(
    (selectedRowIndex: number, retry = 10) => {
      if (gridApi) {
        const rowNode = gridApi.getDisplayedRowAtIndex(selectedRowIndex)
        // If row has not rendered yet, fetch until it's rendered.
        if (!rowNode?.data) {
          if (retry > 0) {
            setTimeout(() => fetchAndUpdateCurrRowData(selectedRowIndex, retry - 1), 300)
          } else {
            setResolverFailure(true)
          }
        } else {
          gridApi.deselectAll()
          rowNode.setSelected(true)
        }
      }
    },
    [gridApi]
  )

  // callback to close resolver and all selected rows to fix the following issues:
  // 1. the resolver shows data for a row no longer visible when a new filter is applied
  // 2. hotkeys applying to a row when it's no longer visible after applying a new filter
  const resetResolverAndRowSelection = useCallback(() => {
    setEditMode(false)
    // Brutish, but necessary for now. Selected row state lives both in this component's local state as well as the ag-grid's internal state.
    gridApi?.deselectAll()
    setSelectedRows([])
    setLastSelectedRow(null)
  }, [gridApi, setEditMode])

  // useEffects once gridApi is up
  useEffect(() => {
    if (gridApi) {
      setTimeout(
        () => selectedRows.length < 2 && gridApi.ensureIndexVisible(selectedRowIndex, 'middle'),
        0
      )
    }
  }, [gridApi, selectedRowIndex, selectedRows.length])

  useEffect(() => {
    if (columnApi && prevPresetConfig) {
      const sortColumn = columnApi.getColumn(prevPresetConfig.sort.column)
      sortColumn?.setSort(prevPresetConfig.sort.order as SortDirection)
    }
  }, [columnApi, prevPresetConfig])

  const displayedColumns = columnApi?.getAllDisplayedColumns()

  useEffect(() => {
    if (displayedColumns && displayedColumns.length && firstGridDataRendered && !isFetchingRows) {
      const lastColumn = displayedColumns[displayedColumns.length - 1] as Column
      setTimeout(() => lastColumn.setFlex(1), 500)
    }
  }, [columnApi, displayedColumns, firstGridDataRendered, isFetchingRows])

  // Detect if user should enter the data labeling workflow and assign step
  useEffect(() => {
    if (rowCountsFetched && showDataLabelingWorkflow) {
      if (numResolved === numUnlabeled && dataLabelingActiveStep === 0) {
        createCleanset({
          cleansetId: cleansetId,
          projectId: projectDetails.projectId,
          datasetId: projectDetails.datasetId,
        })
        void queryClient.invalidateQueries(
          cleansetQueryKeys.cleanset.projectId(projectDetails.projectId)
        )
      } else if (!hasStartedDataLabelingWorkflow) {
        setDataLabelingActiveStep(0)
        onDataLabelingIntroOpen()
      }
    }
  }, [
    cleansetId,
    createCleanset,
    dataLabelingActiveStep,
    hasStartedDataLabelingWorkflow,
    isDataLabelingIntroOpen,
    numResolved,
    numUnlabeled,
    onDataLabelingIntroOpen,
    projectDetails.datasetId,
    projectDetails.projectId,
    queryClient,
    rowCountsFetched,
    showDataLabelingWorkflow,
  ])

  // Sets up grid for data labeling workflow, depending on step
  useEffect(() => {
    if (gridApi && dataLabelingActiveStep !== null) {
      if (!hasStartedDataLabelingWorkflow) {
        applyDataLabelingFilters(dispatch)
      }
      if (dataLabelingActiveStep === 0 && !hasStartedDataLabelingWorkflow) {
        createAutoLabelColumn(
          thresholdValueScore ?? unlabeledSuggestedLabelConfidenceThreshold,
          lastRowAboveThreshold ?? 0,
          gridApi,
          gridOptions,
          setGridOptions,
          projectDetails.modality,
          unlabeledSuggestedLabelConfidenceThreshold
        )
        setDataLabelingActiveStep(0)
        setHasCreatedAutoLabelColumn(true)
      } else if (dataLabelingActiveStep === 1) {
        if (hasCreatedAutoLabelColumn) {
          columnApi?.setColumnVisible('auto-label', false)
        }
        const suggestedConfidenceScoreColumn = columnApi?.getColumn(
          CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE
        )
        columnApi?.setColumnVisible(CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE, true)
        suggestedConfidenceScoreColumn?.setSort('asc')
        gridApi?.onSortChanged()
        fetchAndUpdateCurrRowData(0)
        gridApi?.sizeColumnsToFit()
      }
      columnApi?.setColumnVisible(CLEANLAB_FRONTEND_COLUMN.SUGGESTED, true)
      columnApi?.setColumnVisible(CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE, true)
      setHasStartedDataLabelingWorkflow(true)
    }
  }, [
    columnApi,
    dataLabelingActiveStep,
    dispatch,
    fetchAndUpdateCurrRowData,
    gridApi,
    gridOptions,
    hasCreatedAutoLabelColumn,
    hasStartedDataLabelingWorkflow,
    lastRowAboveThreshold,
    projectDetails.modality,
    thresholdValueScore,
    unlabeledSuggestedLabelConfidenceThreshold,
    userLabeledTagBorderColor,
    userLabeledTagColor,
  ])

  // Go to threshold row's page and select when all data is ready
  useEffect(() => {
    if (
      gridApi &&
      firstGridDataRendered &&
      lastRowAboveThreshold !== null &&
      dataLabelingActiveStep === 0 &&
      !isFetchingRows &&
      !hasAutoSelectedThresholdRow &&
      Object.keys(gridApi.getFilterModel() ?? {}).length
    ) {
      gridApi.deselectAll()
      const pageNum = Math.floor(lastRowAboveThreshold / NUM_ROWS_PER_PAGE)
      setTimeout(
        () =>
          goToPageAndSelectRow(
            Math.max(lastRowAboveThreshold - 1, 0),
            gridApi,
            () => {
              setThresholdRowIndex(lastRowAboveThreshold ?? 0)
              setThresholdValueScore(unlabeledSuggestedLabelConfidenceThreshold)
              gridApi.sizeColumnsToFit()
              gridApi.ensureIndexVisible(lastRowAboveThreshold - 1, 'middle')
              setCurrPageNum(pageNum)
            },
            50
          ),
        0
      )
      setHasAutoSelectedThresholdRow(true)
    }
  }, [
    gridApi,
    lastRowAboveThreshold,
    unlabeledSuggestedLabelConfidenceThreshold,
    dataLabelingActiveStep,
    firstGridDataRendered,
    isFetchingRows,
    hasAutoSelectedThresholdRow,
  ])

  // fetch row data when curr row idx updates
  useEffect(() => {
    if (selectedRows.length < 2 && shouldAutoSelectRow) {
      fetchAndUpdateCurrRowData(selectedRowIndex)
    }
  }, [selectedRowIndex, fetchAndUpdateCurrRowData, selectedRows.length, shouldAutoSelectRow])

  // Show first record if there aren't any record shown in edit mode
  useEffect(() => {
    if (firstGridDataRendered && selectedRows.length < 2 && shouldAutoSelectRow) {
      fetchAndUpdateCurrRowData(selectedRowIndex)
    }
  }, [
    fetchAndUpdateCurrRowData,
    firstGridDataRendered,
    selectedRowIndex,
    selectedRows.length,
    shouldAutoSelectRow,
  ])

  useEffect(() => {
    if (
      firstGridDataRendered &&
      columnApi &&
      prevPresetConfig === null &&
      !showDataLabelingWorkflow
    ) {
      !hasPrevGridState &&
        setPresetFilterColumnConfig(columnApi, '', prevPresetConfig, setPrevPresetConfig)
      applyHeaderHeight(gridApi)
    }
  }, [
    firstGridDataRendered,
    columnApi,
    prevPresetConfig,
    showDataLabelingWorkflow,
    hasPrevGridState,
    gridApi,
  ])

  useEffect(() => {
    if (pressedComboUpArrow) {
      const selectedNodes = gridApi?.getSelectedNodes() ?? []
      const indices = selectedNodes.map((e) => e.rowIndex ?? 0)
      const minIndex = Math.min(...indices)

      if (minIndex >= 0) {
        const nextNode = gridApi?.getDisplayedRowAtIndex(minIndex - 1)
        nextNode?.setSelected(true)
        setPressedComboUpArrow(false)
      }
    }
  }, [gridApi, pressedComboUpArrow, selectedRows.length])

  useEffect(() => {
    if (pressedComboDownArrow) {
      const selectedNodes = gridApi?.getSelectedNodes() ?? []
      const indices = selectedNodes.map((e) => e.rowIndex ?? 0)
      const maxIndex = Math.max(...indices)

      if (maxIndex <= numNonFiltered - 1) {
        const nextNode = gridApi?.getDisplayedRowAtIndex(maxIndex + 1)
        nextNode?.setSelected(true)
        setPressedComboDownArrow(false)
      }
    }
  }, [gridApi, numNonFiltered, pressedComboDownArrow, selectedRows.length])

  // when grid is initialized, create datasource server and initialize everything
  const handleGridReady = (event: GridReadyEvent) => {
    const server = datasheetApiService.createServer()
    const datasource = createServerSideDatasource({
      datasetDetailsServer: server,
      cleansetId,
      toast,
      setInitialized: () => !firstGridDataRendered && setFirstGridDataRendered(true),
      setIsFetchingRows,
    })
    setGridApi(event.api)
    setColumnApi(event.columnApi)
    event.api.setServerSideDatasource(datasource)
    projectDetails.modality === 'tabular' &&
      projectDetails.tasktype !== Tasktype.UNSUPERVISED &&
      generateSplitGrid()
  }

  const { mutate: applyBatchAction, isLoading: isBatchActionLoading } = useBatchActionMutation(
    () => {
      void queryClient.invalidateQueries(
        queryKeys.datasheet.id(cleansetId).rowCount().filterType(RowCountType.ISSUES_RESOLVED)
      )
      void queryClient.invalidateQueries(
        queryKeys.datasheet.id(cleansetId).rowCount().filterType(RowCountType.RESOLVED)
      )
      setTimeout(() => gridApi?.refreshServerSideStore({}), 500)

      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: `Top ${
            thresholdRowIndex ?? 0
          } filtered datapoints have been ${getBatchActionVerb(BatchAction.autoFix)}!`,
          status: 'success',
        }),
      })

      if (thresholdRowIndex === numUnlabeled) {
        createCleanset({
          cleansetId: cleansetId,
          projectId: projectDetails.projectId,
          datasetId: projectDetails.datasetId,
        })
        void queryClient.invalidateQueries(
          cleansetQueryKeys.cleanset.projectId(projectDetails.projectId)
        )
        setDataLabelingActiveStep(2)
      } else {
        handleLabelingStepTransition()
      }
    }
  )

  const handleLabelingStepTransition = () => {
    applyThresholdFilter(dispatch, thresholdValueScore ?? 0)
    setColumnSorting(gridApi, gridOptions.columnDefs as ColDef[], false)
    setLastActionState(null)
    setDataLabelingActiveStep(1)

    gridOptions.getRowStyle = undefined
    columnApi?.setColumnVisible('auto-label', false)
    const suggestedConfidenceScoreColumn = columnApi?.getColumn(
      CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE
    )
    suggestedConfidenceScoreColumn?.setSort('asc')

    gridApi?.onSortChanged()
    gridApi?.paginationGoToFirstPage()
    fetchAndUpdateCurrRowData(0)
    setTimeout(() => setEditMode(true), 1000)
  }

  // clicking a datasheet row targets it and launches the edit interface
  const handleRowClicked = () => {
    if (!editMode) {
      trackEvent(MixpanelEvents.activateEditModeByRowClick, {
        ...cleansetInfo,
        isTemplate: projectDetails.isTemplate,
      })
    }
    // Row click can be a deselect event with multiselect, so set last remaining selected
    // row to currRowNum
    if (gridApi?.getSelectedRows().length === 1) {
      const selectedNodes = gridApi.getSelectedNodes()
      setLastSelectedRow(selectedNodes[0])
    }
    setEditMode(true)
  }

  const handleClearFilters = useCallback(() => {
    gridApi?.setFilterModel(null) // clears all filters
    columnApi && setPresetFilterColumnConfig(columnApi, '', prevPresetConfig, setPrevPresetConfig)
  }, [columnApi, gridApi, prevPresetConfig])

  const handleRangeSelectionChanged = () => {
    if (gridApi && isClicking) {
      const cellRanges = gridApi.getCellRanges() ?? []
      const rowIndices: number[] = []
      // Find start and end rows to support up and down mouse dragging
      cellRanges.map((e) => {
        const start = Math.min(e.startRow?.rowIndex ?? 0, e.endRow?.rowIndex ?? 0)
        const end = Math.max(e.startRow?.rowIndex ?? 0, e.endRow?.rowIndex ?? 0)
        for (let i = start; i <= end; i++) {
          rowIndices.push(i)
        }
        return null
      })
      // This should only run when user is actively holding click
      // Run through rows and select rows included in the cell ranges
      gridApi.forEachNode((node) => {
        // if this row is included in the call ranges, select row
        if (node.rowIndex !== null && rowIndices.includes(node.rowIndex)) {
          node.setSelected(true)
        }
        // deselect rows if user is not pressing cmd/ctrl
        else if (node.rowIndex !== null && !pressCmd && !pressCtrl && selectedRows.length > 1) {
          node.setSelected(false)
        }
      })
    }
  }

  const onDataLabelingInputChange = useCallback(
    (newThresholdIndex: number) => {
      // When debounced input changes, navigate to page and threshold row
      if (dataLabelingActiveStep === 0 && hasAutoSelectedThresholdRow && gridApi) {
        setThresholdRowIndex(newThresholdIndex)
        const pageNum = Math.floor(newThresholdIndex / NUM_ROWS_PER_PAGE)
        goToPageAndSelectRow(
          newThresholdIndex - 1,
          gridApi,
          () => {
            setCurrPageNum(pageNum)
            fetchAndUpdateCurrRowData(newThresholdIndex - 1)
            const newThresholdScore = gridApi.getDisplayedRowAtIndex(newThresholdIndex - 1)?.data
              ?._cleanlab_suggested_label_confidence_score
            setThresholdValueScore(newThresholdScore)
            createAutoLabelColumn(
              newThresholdScore,
              newThresholdIndex,
              gridApi,
              gridOptions,
              setGridOptions,
              projectDetails.modality,
              unlabeledSuggestedLabelConfidenceThreshold
            )
          },
          50
        )
      }
    },
    [
      dataLabelingActiveStep,
      fetchAndUpdateCurrRowData,
      gridApi,
      gridOptions,
      hasAutoSelectedThresholdRow,
      projectDetails.modality,
      unlabeledSuggestedLabelConfidenceThreshold,
    ]
  )

  const handleEscKey = useCallback(() => {
    if (!editMode) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [editMode, setEditMode])

  useKeyPress({ callback: handleEscKey, keys: ['Escape'] })

  const handleDownKey = () => {
    if (selectedNodes && selectedRowIndex < numNonFiltered - 1) {
      const nextRow = gridApi?.getDisplayedRowAtIndex(selectedRowIndex + 1)
      const pageNum = Math.floor((selectedRowIndex + 1) / NUM_ROWS_PER_PAGE)
      // We need to check if the next node is on a subsequent page because the node
      // might not exist yet (100 nodes exist at any given moment, rest aren't rendered yet).
      // If the node does not exist yet, update page number and rely on fetchAndUpdateCurrRowData
      // to select the row when it is rendered. Else, select row normally.
      if (pageNum !== currPageNum) {
        gridApi?.deselectAll()
        gridApi?.paginationGoToPage(pageNum)
        setCurrPageNum(pageNum)
        fetchAndUpdateCurrRowData(selectedRowIndex + 1)
      } else {
        nextRow?.setSelected(true)
      }
    }
  }

  const handleUpKey = () => {
    if (selectedNodes && selectedRowIndex > 0) {
      const prevRow = gridApi?.getDisplayedRowAtIndex(selectedRowIndex - 1)
      const pageNum = Math.floor((selectedRowIndex - 1) / NUM_ROWS_PER_PAGE)
      // See comment in handleDownKey
      if (pageNum !== currPageNum) {
        gridApi?.deselectAll()
        gridApi?.paginationGoToPage(pageNum)
        setCurrPageNum(pageNum)
        fetchAndUpdateCurrRowData(selectedRowIndex - 1)
      } else {
        prevRow?.setSelected(true)
      }
    }
  }

  useKeyPress({
    callback: handleDownKey,
    keys: ['ArrowDown'],
  })

  useKeyPress({
    callback: handleUpKey,
    keys: ['ArrowUp'],
  })

  if (dataLabelingActiveStep === 2) {
    return (
      <Center h="100%" w="100%">
        <RerunCleanlabStatus
          onGoToDashboardClicked={() => navigate('/')}
          onViewProjectClicked={() => navigate(`/cleansets/${cleansets[0].id}`)}
          isRerunComplete={
            cleansets.every((row) => row.is_ready || row.has_error) && cleansets.length > 1
          }
        />
      </Center>
    )
  }

  if (isDataLabelingWorkflowLoading) {
    return <Loading />
  }

  return (
    <>
      <VStack align="flex-start" w="100%" height={showNotificationBanner ? '83vh' : '87vh'}>
        <UserQuotaAlert info={quotaAlert} setInfo={setQuotaAlert} />
        {showDataLabelingWorkflow && (
          <DataLabelingIntroModal
            isOpen={isDataLabelingIntroOpen}
            onClose={() => {
              onDataLabelingIntroClose()
            }}
          />
        )}
        {gridApi && columnApi && (
          <ExportDatasheetModal
            onClose={onExportClose}
            isOpen={isExportOpen}
            cleansetId={cleansetId}
            gridApi={gridApi}
            displayedColumns={getDisplayedColumnsForExport(columnApi)}
            projectId={projectDetails.projectId}
            isTemplate={projectDetails.isTemplate}
            paidExport={projectDetails.paidExport}
            firstGridDataRendered={firstGridDataRendered}
          />
        )}
        {gridApi && (
          <DeployModelModal
            onClose={onDeployModelClose}
            isOpen={isDeployModelOpen}
            cleansetId={cleansetId}
            projectId={projectDetails.projectId}
            datasetId={projectDetails.datasetId}
            projectName={projectDetails.projectName}
            setTabIndex={(value: number) => tabState.dispatch({ index: value })}
            setScrollToSummary={setScrollToSummary}
            accuracy={accuracy}
            isTemplate={projectDetails.isTemplate}
          />
        )}
        {gridApi && columnApi && numNonFiltered !== -1 && (
          <BatchActionsModal
            onClose={() => {
              onBatchActionsClose()
              trackEvent(MixpanelEvents.closeBatchActionModal, {
                ...cleansetInfo,
                isTemplate: projectDetails.isTemplate,
              })
            }}
            isOpen={isBatchActionsOpen}
            cleansetId={cleansetId}
            gridApi={gridApi}
            relabelOptions={relabelOptions}
            isTemplate={projectDetails.isTemplate}
            columnState={columnApi?.getColumnState()}
            isMultiLabel={isMultiLabel}
            currPageNum={currPageNum}
            setCurrPageNum={setCurrPageNum}
            fetchAndUpdateCurrRowData={fetchAndUpdateCurrRowData}
            firstGridDataRendered={firstGridDataRendered}
          />
        )}
        <HStack h="100%" w="100%" spacing="16px">
          {gridApi && columnApi && !showDataLabelingWorkflow && (
            <FilterSidebar
              cleansetId={cleansetId}
              gridApi={gridApi}
              issueColumns={issueColumns}
              metadataColumns={metadataColumns}
              customPresetFilters={
                projectDetails.tasktype === Tasktype.UNSUPERVISED
                  ? UNSUPERVISED_PRESET_FILTERS
                  : undefined
              }
              isCollapsed={isFilterSidebarCollapsed}
              handleCollapse={handleFilterSidebarCollapse}
              modality={projectDetails.modality}
              columnApi={columnApi}
              firstGridDataRendered={firstGridDataRendered}
              resetResolverAndRowSelection={resetResolverAndRowSelection}
              presetConfigCallback={(filterType) =>
                setPresetFilterColumnConfig(
                  columnApi,
                  getFrontendColumnByFilterType(filterType),
                  prevPresetConfig,
                  setPrevPresetConfig
                )
              }
            />
          )}
          <VStack h="100%" w="100%">
            {gridApi && !showDataLabelingWorkflow && (
              <HStack w="100%" justify="space-between">
                <Box flex={1} pr={lastActionState ? 16 : 0}>
                  <VStack alignItems="flex-start" justifyContent="space-between">
                    <HStack>
                      <EditableTitle
                        title={projectDetails.projectName}
                        isLoading={isProjectUpdating}
                        onTitleChange={(projectName) => {
                          updateProject({ id: projectDetails.projectId, name: projectName })
                        }}
                      />
                      {!isComplete && (
                        <Badge
                          size="medium"
                          style={{ backgroundColor: cleanlabColors.yellow[200], opacity: 0.5 }}
                        >
                          Partially Ready
                        </Badge>
                      )}
                    </HStack>

                    <IssuesResolvedSlider
                      cleansetId={cleansetId}
                      gridApi={gridApi}
                      isResolving={showTooltip && tabState.state.index === 0}
                      setShowTooltip={setShowTooltip}
                      firstGridDataRendered={firstGridDataRendered}
                    />
                  </VStack>
                </Box>
                <Box flex={0} maxW="40%">
                  {lastActionState && (
                    <LastActionWidget
                      currPageNum={currPageNum}
                      gridApi={gridApi}
                      lastActionState={lastActionState}
                      setLastActionState={setLastActionState}
                      isMultiLabel={isMultiLabel}
                    />
                  )}
                </Box>
              </HStack>
            )}
            <VStack w="100%" h="100%" gap="2px">
              {showDataLabelingWorkflow && (
                <DataLabelingStepper
                  activeStep={dataLabelingActiveStep ?? 0}
                  initialThresholdRowIndex={lastRowAboveThreshold}
                  thresholdRowIndex={thresholdRowIndex ?? 0}
                  onInputChange={onDataLabelingInputChange}
                  isLoading={
                    isBatchActionLoading || isCreateCleansetLoading || dataLabelingWorkflowLoading
                  }
                  numUnlabeled={numUnlabeled}
                  numUnlabeledResolved={numResolved}
                  hasStartedLabeling={hasStartedLabeling}
                  thresholdButtonClicked={thresholdButtonClicked}
                  setThresholdButtonClicked={setThresholdButtonClicked}
                  onDoneLabelingClicked={() => {
                    createCleanset({
                      cleansetId: cleansetId,
                      projectId: projectDetails.projectId,
                      datasetId: projectDetails.datasetId,
                    })
                    void queryClient.invalidateQueries(
                      cleansetQueryKeys.cleanset.projectId(projectDetails.projectId)
                    )
                  }}
                  onAutoLabelClicked={() => {
                    if (thresholdRowIndex) {
                      applyBatchAction({
                        cleansetId: cleansetId,
                        batchAction: getBatchActionRequestPath(BatchAction.autoLabel),
                        k: Number(thresholdRowIndex),
                        relabel: '',
                        filterModel: gridApi?.getFilterModel() ?? {},
                        sort: getSortModel(columnApi?.getColumnState() ?? []),
                      })
                    } else {
                      handleLabelingStepTransition()
                    }
                  }}
                />
              )}
              {!showDataLabelingWorkflow && (
                <FilterMenu
                  cleansetId={cleansetId}
                  gridOptions={gridOptions}
                  handleClearFilters={handleClearFilters}
                  isMultilabel={isMultiLabel}
                  labelsIndexed={projectDetails.labelsIndexed}
                  firstGridDataRendered={firstGridDataRendered}
                  sortColumn={columnApi?.getColumn(prevPresetConfig?.sort.column) ?? null}
                  onOpenResolver={() => setEditMode(true)}
                  resetResolverAndRowSelection={resetResolverAndRowSelection}
                />
              )}

              <Box
                className={tableColor}
                w="100%"
                h="100%"
                zIndex={1}
                onMouseDown={() => {
                  if (!isBatchActionsOpen) {
                    setIsClicking(true)
                  }
                }}
                onMouseUp={(evt) => {
                  if (!isBatchActionsOpen) {
                    setIsClicking(false)
                    setSelectedRows(gridApi?.getSelectedRows() ?? [])
                    gridApi?.clearRangeSelection()
                    setIsRightClick(evt.nativeEvent.button === 2)
                  }
                }}
              >
                {!dataLabelingWorkflowLoading && (
                  <CleanlabGrid
                    gridOptions={gridOptions}
                    onGridReady={handleGridReady}
                    enableRangeSelection={true}
                    rowBuffer={projectDetails.modality === Modality.image ? 100 : 10}
                    rowSelection={multiSelectKeyPressed ? 'multiple' : 'single'}
                    pagination
                    paginationPageSize={NUM_ROWS_PER_PAGE}
                    suppressCellFocus
                    suppressCopyRowsToClipboard
                    onCellMouseOver={handleRangeSelectionChanged}
                    onSelectionChanged={() => {
                      const numSelectedRows = gridApi?.getSelectedRows().length
                      if (!numSelectedRows) {
                        // prevent deselecting last row
                        lastSelectedRow?.setSelected(true)
                      }
                      setSelectedRows(gridApi?.getSelectedRows() ?? selectedRows)
                    }}
                    onRangeSelectionChanged={(event) => {
                      // This is basically a mouseup event for range selection
                      if (event.finished && !event.started && !editMode && !isRightClick) {
                        setEditMode(true)
                      }
                    }}
                    onRowClicked={handleRowClicked}
                    getRowId={({ data }) => data[projectDetails.idColumn]}
                    onSortChanged={() => {
                      !showDataLabelingWorkflow && fetchAndUpdateCurrRowData(0, 10) // select first row when sort is applied
                      gridApi?.refreshHeader()
                      saveGridLayout()
                    }}
                    onFilterChanged={() => {
                      !showDataLabelingWorkflow && fetchAndUpdateCurrRowData(0) // select first row when filtered is applied
                      void queryClient.invalidateQueries(
                        queryKeys.datasheet
                          .id(cleansetId)
                          .rowCount()
                          .filterType(RowCountType.CUSTOM)
                      )
                      saveGridLayout()
                    }}
                    onColumnVisible={() => saveGridLayout()}
                    onColumnPinned={() => saveGridLayout()}
                    onColumnResized={(evt) => {
                      applyHeaderHeight(gridApi)
                      // Don't save changes until resizing is finished
                      if (evt.finished) {
                        saveGridLayout()
                      }
                    }}
                    onColumnMoved={(evt) => evt.finished && saveGridLayout()}
                    onColumnValueChanged={() => saveGridLayout()}
                  />
                )}
              </Box>
            </VStack>
            <HStack w="100%" justify="space-between" align="flex-start">
              {showDataLabelingWorkflow ? (
                <Box flex={0} maxW="40%">
                  {lastActionState && gridApi && (
                    <LastActionWidget
                      currPageNum={currPageNum}
                      gridApi={gridApi}
                      lastActionState={lastActionState}
                      setLastActionState={setLastActionState}
                      isMultiLabel={isMultiLabel}
                    />
                  )}
                </Box>
              ) : (
                <DatasheetActionButtons
                  cleansetId={cleansetId}
                  gridApi={gridApi}
                  onBatchActionsOpen={() => {
                    onBatchActionsOpen()
                    trackEvent(MixpanelEvents.clickBatchActionButton, {
                      ...cleansetInfo,
                      isTemplate: projectDetails.isTemplate,
                    })
                  }}
                  onExportOpen={onExportOpen}
                  onDeployModelOpen={onDeployModelOpen}
                  projectDetails={projectDetails}
                  isTemplate={projectDetails.isTemplate}
                  setShowTooltip={setShowTooltip}
                  numIssuesResolved={numIssuesResolved}
                  firstGridDataRendered={firstGridDataRendered}
                />
              )}
              <DatasheetPaginationInput
                pageJumpNum={pageJumpNum}
                setPageJumpNum={setPageJumpNum}
                setCurrPageNum={setCurrPageNum}
                numRowsPerPage={NUM_ROWS_PER_PAGE}
                gridApi={gridApi}
                fetchAndUpdateCurrRowData={fetchAndUpdateCurrRowData}
              />
            </HStack>
          </VStack>
        </HStack>
      </VStack>
      {gridApi && selectedNodes?.[0]?.data && (
        <Resolver
          cleansetId={cleansetId}
          gridApi={gridApi}
          originalDatasetColumns={originalDatasetColumns}
          projectDetails={projectDetails}
          selectedRowIndex={selectedRowIndex}
          editMode={editMode}
          onClose={() => setEditMode(false)}
          resolverFailure={resolverFailure}
          currPageNum={currPageNum}
          lastRowNum={numNonFiltered - 1}
          setCurrPageNum={setCurrPageNum}
          fetchAndUpdateCurrRowData={fetchAndUpdateCurrRowData}
          setLastActionState={setLastActionState}
          isFetchingRows={isFetchingRows}
          isFilterSidebarCollapsed={isFilterSidebarCollapsed}
          metadataColumns={metadataColumns}
          thresholdRowIndex={thresholdRowIndex}
          hasCustomTagsColumn={allColumns.includes(CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS)}
          showDataLabelingWorkflow={showDataLabelingWorkflow}
          dataLabelingActiveStep={dataLabelingActiveStep ?? 0}
          setShowTooltip={setShowTooltip}
          setHasStartedLabeling={() => {
            if (dataLabelingActiveStep === 1) {
              setHasStartedLabeling(true)
            }
          }}
          createAutoLabelColumn={() =>
            createAutoLabelColumn(
              thresholdValueScore ?? unlabeledSuggestedLabelConfidenceThreshold,
              thresholdRowIndex ?? 0,
              gridApi,
              gridOptions,
              setGridOptions,
              projectDetails.modality,
              unlabeledSuggestedLabelConfidenceThreshold
            )
          }
          onSetThresholdButtonClick={() => {
            setThresholdRowIndex((selectedNodes[0].rowIndex ?? 0) + 1)
            setThresholdButtonClicked(true)
            toast({
              ...defaultToastAlertProps,
              render: renderChakraToastAlert({
                heading: `Row ${selectedRowIndex + 1} set as threshold`,
                status: 'info',
              }),
            })
            setEditMode(false)
          }}
        />
      )}
    </>
  )
}

export default Datasheet
