import { Badge } from '@components/badge/Badge'
import { TruncatorMoreCount } from '@components/truncators/Truncator.helpers'
import { TruncatorBlockH } from '@components/truncators/TruncatorBlockH'
import { ForwardedRef, forwardRef, memo, ReactNode } from 'react'

const MoreIndicator = memo(
  forwardRef((_, ref: ForwardedRef<any>) => (
    <Badge
      ref={ref}
      size="small"
      variant="subtle"
      hue="neutralStrong"
      className="text-nowrap tabular-nums"
    >
      +<TruncatorMoreCount />
    </Badge>
  ))
)

export function ProjectDemoTagList({ tags }: Readonly<{ tags: Readonly<ReactNode[]> }>) {
  return (
    <TruncatorBlockH
      items={tags.map((tag, i) => ({
        label: tag,
        element: (
          <Badge
            key={`${tag}-${i}`}
            className="text-nowrap"
            size="small"
            round="medium"
            hue="neutral"
            variant="subtle"
            asChild
          >
            <li>{tag}</li>
          </Badge>
        ),
      }))}
      moreIndicator={<MoreIndicator />}
      aria-label="tags"
      className="flex gap-4"
      asChild
    >
      <ul />
    </TruncatorBlockH>
  )
}
