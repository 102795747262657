import { IconHelpCircle } from '@components/icons'
import { Tooltip } from '@components/tooltip/Tooltip'
import { cn } from '@utils/tailwindUtils'

import { CLEANLAB_FRONTEND_COLUMN } from '../../datasheet/Datasheet.types'
import { issueColumns } from '../../editInterface/issuesTable/IssuesTable.types'
import { ORDERED_METADATA_PRESET_FILTERS } from '../../filterSidebar/FilterSidebar.types'
import { ColumnDataItem } from './columnDataItem/ColumnDataItem'
import { constructAnalysesItems } from './MetadataDisplay.helpers'
import { ColumnInfo, MetadataDisplayProps } from './MetadataDisplay.types'

const MetadataDisplay = (props: MetadataDisplayProps) => {
  const { metadataColumns, selectedRowData, modality, showTallView } = props

  const applicableIssues = issueColumns.filter((e) => selectedRowData[e] !== undefined)

  const metadataPresetFilters = ORDERED_METADATA_PRESET_FILTERS.map((e) => e.filterType).filter(
    (presetFilter) => metadataColumns.includes(presetFilter)
  )

  const metadataAnalyses = constructAnalysesItems(metadataPresetFilters, selectedRowData)
  const generalAnalyses = constructAnalysesItems(applicableIssues, selectedRowData)

  const className = cn(
    'mt-2 flex w-full flex-col items-start overflow-y-auto border-t bg-surface-1 p-5',
    'h-[calc(100vh-300px)]', // margin-top + Palette heading height
    showTallView && 'h-[calc(100vh-340px)]' // margin-top + Palette heading height tall view
  )
  return (
    <div className={className}>
      <div className="flex w-full items-center gap-3 pb-2">
        <p className="type-body-200-semibold text-text-strong">Metadata</p>
        <Tooltip content="Cleanlab Studio runs many analyses of your dataset to find data issues and provide other useful metadata. These analyses include both a boolean flag indicating whether the attribute is estimated to be true or not, and a numeric score between 0 and 1 indicating the magnitude of the attribute.">
          <span>
            <IconHelpCircle />
          </span>
        </Tooltip>
      </div>
      <div className="flex w-full flex-col items-start pt-4">
        <p className="type-body-100-semibold pb-5 text-text-strong">General analyses</p>
        {generalAnalyses.length > 0 ? (
          <div className="flex flex-col gap-5">
            {generalAnalyses.map((item: ColumnInfo) => (
              <ColumnDataItem key={item.title.title} columnData={item} />
            ))}
          </div>
        ) : (
          <p className="type-body-100">Nothing to show</p>
        )}

        {metadataAnalyses.length > 0 && modality && (
          <>
            <p className="type-body-100-semibold py-5 text-text-strong">
              {`${modality.charAt(0).toUpperCase() + modality.slice(1)}-specific analyses`}
            </p>
            <div className="flex w-full flex-col items-start gap-5">
              {metadataAnalyses.map((item: ColumnInfo) => (
                <ColumnDataItem key={item.title.title} columnData={item} />
              ))}
              {modality === 'image' && (
                <p className="type-body-100-medium text-text-primary">
                  Aesthetic Score:{' '}
                  <span className="type-body-100 text-text-faint">
                    {`${(
                      (selectedRowData[CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE] as number) ?? 0
                    ).toFixed(3)}`}
                  </span>
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default MetadataDisplay
