import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionSecurity,
  SidebarSectionWelcome,
} from '../projectForm/Sidebar'

export const DatasetUploadSidebarStandard = () => (
  <CreateProjectSidebar>
    <SidebarSectionWelcome />
    <SidebarSectionSecurity />
    <SidebarResourcesSection>
      <ResourceLinkLi to="https://help.cleanlab.ai/guide/">
        Cleanlab Studio Documentation
      </ResourceLinkLi>
      <ResourceLinkLi to="https://help.cleanlab.ai/guide/FAQ/">Cleanlab Studio FAQ</ResourceLinkLi>
      <ResourceLinkLi to="https://help.cleanlab.ai/guide/concepts/projects/">
        Project guidebook
      </ResourceLinkLi>
    </SidebarResourcesSection>
  </CreateProjectSidebar>
)
