import { useToast } from '@chakra-ui/react'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { AxiosError } from 'axios'
import { useRef } from 'react'

import useIsTabActive from './useIsTabActive'

export enum ProgressStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  ERROR = 'error',
}

export interface ProgressData {
  status: ProgressStatus
  progress?: number
  error_message?: string
  error_type?: string
}

export const useProgress = (getProgressApi: (id: string) => Promise<ProgressData>) => {
  const pollIntervalIdRef = useRef<number | null>(null)
  const toast = useToast()
  const isTabActive = useIsTabActive()

  const getProgress = async (
    progressId: string,
    updateProgress: (progressPercent: number | null, extraData?: any) => void,
    onResultAvailable?: (res: any) => Promise<void>,
    onError?: (errorType?: string, errorMessage?: string) => void
  ) => {
    try {
      const data = await getProgressApi(progressId)
      if (data.status === ProgressStatus.ERROR) {
        onError && onError(data.error_type, data.error_message)
        clearPoll()
      } else if (data.status === ProgressStatus.COMPLETE) {
        updateProgress(1, data)
        onResultAvailable && onResultAvailable(data)
        clearPoll()
      } else {
        updateProgress(data.progress ?? 0, data)
      }
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError)
    }
  }

  const pollProgress = (
    progressId: string,
    pollInterval: number,
    updateProgress: (progressPercent: number | null, extraData?: any) => void,
    onResultAvailable?: (res: any) => Promise<void>,
    onError?: (errorType?: string, errorMessage?: string) => void
  ) => {
    if (pollIntervalIdRef.current === null) {
      getProgress(progressId, updateProgress, onResultAvailable, onError)
      const intervalId = window.setInterval(
        () => isTabActive && getProgress(progressId, updateProgress, onResultAvailable, onError),
        pollInterval
      )
      pollIntervalIdRef.current = intervalId
    }
  }

  const clearPoll = (updateProgress?: (progressPercent: number | null) => void) => {
    if (pollIntervalIdRef.current !== null) {
      clearInterval(pollIntervalIdRef.current)
      pollIntervalIdRef.current = null
      updateProgress && updateProgress(null)
    }
  }

  return { pollProgress, clearPoll }
}
