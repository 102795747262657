export interface TlmPricingItem {
  modelType: ModelOption
  inputTokenCostCents: string
  inputTokenCostDollarsPerThousand: string
  outputTokenCostCents: string
  outputTokenCostDollarsPerThousand: string
  qualityPreset: string
  createdAt: number
  updatedAt: number
}

export enum ModelOption {
  GPT3_35_TUBRO_16K = 'gpt-3.5-turbo-16k',
  GPT_4O = 'gpt-4o',
  GPT_4 = 'gpt-4',
  CLAUDE_3_HAIKU = 'claude-3-haiku',
  CLAUDE_3_SONNET = 'claude-3-sonnet',
  CLAUDE_3_5_SONNET = 'claude-3.5-sonnet',
  HYBRID_MODEL = 'Hybrid Model (Example)',
}
