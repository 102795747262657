import { Button, Heading, Stack, useToast, VStack } from '@chakra-ui/react'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import userEventService from '@services/userEventApi'
import { downloadFileFromAxiosResponse } from '@utils/functions/downloadFile'
import { AxiosError } from 'axios'
import React from 'react'

import { ExampleOutputTable } from './UserEventMetrics.helpers'

const UserEventMetrics = () => {
  const toast = useToast()

  const exportUserEvents = async () => {
    try {
      const response = await userEventService.exportUserEvents()
      downloadFileFromAxiosResponse(response)
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError)
    }
  }

  return (
    <VStack spacing="3rem" align="left" fontSize="md">
      <Heading size="md">User Events</Heading>
      <Stack spacing="1rem">
        <Button
          width={200}
          colorScheme="teal"
          size="md"
          onClick={() => exportUserEvents()}
          aria-label="export user events"
        >
          Export user events
        </Button>
      </Stack>
      <Stack justify="center" maxWidth="75%">
        <Heading size="xs">Example Output Table</Heading>
        <ExampleOutputTable />
      </Stack>
    </VStack>
  )
}

export default UserEventMetrics
