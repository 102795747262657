import { Button, Grid, GridItem, Heading, Text, VStack } from '@chakra-ui/react'
import queryApi from '@services/queryApi'
import React, { useState } from 'react'

import { ControlledFormInputElement, QueryResults } from './QueryInterface.helpers'

const QueryInterfaceFindProject = () => {
  const [userId, setUserId] = useState('')
  const [datasetId, setDatasetId] = useState('')
  const [projectId, setProjectId] = useState('')
  const [queryResults, setQueryResults] = useState(null)

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Query Projects</Heading>
      <Text>
        <strong>Instructions:</strong> Projects can be queried using UserID, DatasetID, or
        ProjectID.
      </Text>
      <Text>If a ProjectID is given, the UserID and the DatasetID are ignored.</Text>
      <Text>If a DatasetID is given, the UserID is ignored.</Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem>
          <ControlledFormInputElement
            id="user_id"
            type="text"
            label="UserID"
            value={userId}
            setValue={setUserId}
          />
        </GridItem>
        <GridItem>
          <ControlledFormInputElement
            id="dataset_id"
            type="text"
            label="DatasetID"
            value={datasetId}
            setValue={setDatasetId}
          />
        </GridItem>
        <GridItem>
          <ControlledFormInputElement
            id="project_id"
            type="text"
            label="ProjectID"
            value={projectId}
            setValue={setProjectId}
          />
        </GridItem>
      </Grid>

      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApi.query({
            resource: 'project',
            user_id: userId,
            dataset_id: datasetId,
            project_id: projectId,
          })
          setQueryResults(response.data)
        }}
      >
        Find Project
      </Button>

      <QueryResults data={queryResults} resource="projects" />
    </VStack>
  )
}

export default QueryInterfaceFindProject
