import { Box } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import { Button } from '@components/button/Button'
import useGridClassname from '@hooks/useGridClassname'
import { ColDef, CsvExportModule, GridApi, GridReadyEvent, ModuleRegistry } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'

import { CostReportGridProps } from './CostReportGrid.types'
ModuleRegistry.register(CsvExportModule)

const CostReportGrid = ({ data }: CostReportGridProps) => {
  const colorModeValue = useGridClassname()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const onExport = useCallback(() => {
    gridApi?.exportDataAsCsv()
  }, [gridApi])

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
  }

  const columnDefs = useMemo(() => {
    const baseColumnDefs: ColDef[] = []
    if (data === null || data.length === 0) {
      return baseColumnDefs
    }
    if (Object.keys(data[0]).includes('user_id')) {
      baseColumnDefs.push({ field: 'user_id', resizable: true, sortable: true })
    }
    baseColumnDefs.push({
      field: 'total_cost',
      resizable: true,
      sortable: true,
      valueFormatter: (params) => {
        if (params.data.total_cost === undefined) {
          return ''
        }
        return `$${params.data.total_cost?.toFixed(2)}`
      },
    })

    return Object.keys(data[0]).reduce((acc, field) => {
      if (field === 'user_id' || field === 'total_cost') {
        return acc
      }
      if (field === 'task_start_time' || field === 'task_end_time') {
        acc.push({
          field,
          valueFormatter: (params) => {
            if (params.data[field] === null) {
              return ''
            }
            return new Date(params.data[field]).toLocaleString()
          },
          resizable: true,
        })
      } else {
        acc.push({ field, resizable: true })
      }
      return acc
    }, baseColumnDefs)
  }, [data])

  if (data === null || data.length === 0) {
    return <div>No tasks found within time range.</div>
  }

  return (
    <Box className={colorModeValue} h="40vh" w="100%">
      <CleanlabGrid
        rowData={data}
        pagination
        paginationPageSize={10}
        columnDefs={columnDefs}
        onGridReady={handleGridReady}
      />
      <Button onClick={onExport} disabled={gridApi === null}>
        Export Results
      </Button>
    </Box>
  )
}

export default CostReportGrid
