import { Box } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import AutoSizer, { Size } from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import MultiLabelRow from '../multiLabelRow/MultiLabelRow'

interface MultilabelPaletteCommandsProps {
  labelToProba: Record<string, number>
  suggestedLabel: string
  givenLabel: string
  updateRowAction: (action: string, errorCallback?: VoidFunction) => void
  correctedLabel: string
  isActionLoading: boolean
  resolverWidth: number
  isFetchingRows: boolean
  selectedRowIndex: number
  multilabelsOrdered: string[]
  inputValue: string
  showNotificationBanner: boolean
  windowSize: {
    width: number
    height: number
  }
}

const MultilabelPaletteCommands = (props: MultilabelPaletteCommandsProps) => {
  const {
    labelToProba,
    suggestedLabel,
    givenLabel,
    updateRowAction,
    correctedLabel,
    isActionLoading,
    resolverWidth,
    isFetchingRows,
    selectedRowIndex,
    multilabelsOrdered,
    inputValue,
    windowSize,
    showNotificationBanner,
  } = props

  const [showSmallView, setShowSmallView] = useState(false)
  const [resolverWidthThreshold, setResolverWidthThreshold] = useState<number>(resolverWidth)

  const filteredMultilabelsOrdered = useMemo(
    () => multilabelsOrdered.filter((e) => e.toLowerCase().includes(inputValue.toLowerCase())),
    [multilabelsOrdered, inputValue]
  )

  const correctedLabelArray = useMemo(() => correctedLabel?.split(',') ?? [], [correctedLabel])

  return (
    <Box
      height={`calc(${windowSize.height}px - ${showSmallView ? 320 : 210}px - ${showNotificationBanner ? 50 : 0}px)`}
      data-testid="multilabel-palette-commands"
    >
      <AutoSizer>
        {({ height, width }: Size) => (
          <FixedSizeList
            height={height}
            itemCount={filteredMultilabelsOrdered.length}
            itemSize={showSmallView ? 146 : 66}
            width={width}
          >
            {({ index, style }) => (
              <div style={style}>
                <MultiLabelRow
                  key={selectedRowIndex + '-' + filteredMultilabelsOrdered[index]}
                  label={filteredMultilabelsOrdered[index]}
                  probability={labelToProba[filteredMultilabelsOrdered[index]]}
                  isInSuggested={suggestedLabel.includes(filteredMultilabelsOrdered[index])}
                  isInGiven={givenLabel.includes(filteredMultilabelsOrdered[index])}
                  updateRowAction={updateRowAction}
                  correctedLabelArray={correctedLabelArray}
                  isActionLoading={isActionLoading}
                  idx={index}
                  resolverWidth={resolverWidth}
                  resolverWidthThreshold={resolverWidthThreshold}
                  setResolverWidthThreshold={setResolverWidthThreshold}
                  showSmallView={showSmallView}
                  setShowSmallView={setShowSmallView}
                  isFetchingRows={isFetchingRows}
                />
              </div>
            )}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  )
}

export default MultilabelPaletteCommands
