import './pages/cleanset/datasheet/Datasheet.css' // NEEDS to be imported AFTER the CSS files above

import { extendedTheme } from '@assets/styles/theme'
import { ChakraProvider } from '@chakra-ui/react'
import { TooltipProvider } from '@components/tooltip/Tooltip'
import { AuthProviderWithHistory } from '@providers/authentication/AuthProviderWithHistory'
import SubscriptionProvider from '@providers/billing/SubscriptionProvider'
import ErrorBoundaryFallback from '@providers/errors/ErrorBoundaryFallback'
import * as Sentry from '@sentry/react'
import {
  INTERCOM_APP_ID,
  IS_VPC_ENVIRONMENT,
  PUBLIC_POSTHOG_HOST,
  PUBLIC_POSTHOG_KEY,
} from '@utils/environmentVariables'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import appRoutes from './AppRouter'
import { queryClient } from './queryClient'
import { ToastContainer } from './ToastContainer'

// AuthProviderWithHistory must be inside Router to access router history
const RouterRootLayout = () => {
  return (
    <>
      <ToastContainer />
      <AuthProviderWithHistory>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <SubscriptionProvider>
            <Outlet />
          </SubscriptionProvider>
        </ErrorBoundary>
      </AuthProviderWithHistory>
      <ScrollRestoration />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  )
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter([{ element: <RouterRootLayout />, children: appRoutes }])

// Remove PostHog provider if this is a VPC environment
const PostHogProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  if (IS_VPC_ENVIRONMENT) {
    return <>{children}</>
  } else {
    return (
      <PostHogProvider apiKey={PUBLIC_POSTHOG_KEY} options={{ api_host: PUBLIC_POSTHOG_HOST }}>
        {children}
      </PostHogProvider>
    )
  }
}

// QueryClientProvider must be outside ErrorBoundary, so that when ErrorBoundary catches
// an error, we don't get the error: No QueryClient set, use QueryClientProvider to set o
const App = () => (
  <QueryClientProvider client={queryClient}>
    <PostHogProviderWrapper>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <ChakraProvider theme={extendedTheme} resetCSS>
          <TooltipProvider>
            <RouterProvider router={router} />
          </TooltipProvider>
        </ChakraProvider>
      </IntercomProvider>
    </PostHogProviderWrapper>
  </QueryClientProvider>
)

export default Sentry.withProfiler(App)
