import { theme } from '@chakra-ui/pro-theme'
import { extendTheme, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { accordionTheme } from '@components/accordion/accordion.themes'
import { badgeTheme } from '@components/badge/badge.themes'
import { buttonTheme, closeButtonTheme } from '@components/buttons/button.themes'
import { cardTheme } from '@components/card/card.themes'
import { checkboxTheme } from '@components/checkbox/checkbox.themes'
import { inputTheme } from '@components/input/input.themes'
import { linkTheme } from '@components/link/link.themes'
import { StepsTheme as Steps } from 'chakra-ui-steps'

import { cleanlabColors } from './CleanlabColors'
import { getGridStyles } from './GridStyles'
import { typography } from './typography'

export const pageWidth = 900
export const pageWidthLarge = 1100
export const CLBlueLight = 'rgba(200,230,255,0.85)'
export const CLBlue = '#0097a7'
export const CLBlueDark = '#09616b'
export const CLOrangeLight = '#fdd7b1'
export const CLOrange = '#ffab40'
export const CLOrangeDark = '#d28c2f'
export const ChartColorGreen = '#ccebc5'
export const ChartColorRed = '#fbb4ae'

export const extendedTheme = extendTheme(
  {
    colors: {
      ...theme.colors,
      ...cleanlabColors,
    },
    fonts: {
      body: 'var(--cl-font-family-body)',
      heading: 'var(--cl-font-family-display)',
      mono: 'var(--cl-font-family-code)',
    },
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          color: mode('gray.700', 'whiteAlpha.900')(props),
          bg: mode('surface.0', 'surfaceDarkMode.0')(props),
          fontSize: '1.2em',
          '.deleted': {
            color: '#ff8383 !important',
            fontStyle: 'normal !important',
          },
          '.inserted': {
            color: '#b5f4a5 !important',
            fontStyle: 'normal !important',
          },
          ...getGridStyles(props),
        },
      }),
    },
    components: {
      Steps,
      FormLabel: {
        baseStyle: {
          fontWeight: '550',
          fontSize: 'md',
        },
      },
      Popover: {
        parts: ['popper'],
        baseStyle: (props: any) => ({
          popper: {
            zIndex: 10,
            maxW: 'xs',
            w: props.width,
          },
        }),
      },
      Tooltip: {
        baseStyle: (props: StyleFunctionProps) => {
          return {
            '--popper-arrow-bg': mode(
              cleanlabColors.neutral[900],
              cleanlabColors.neutralDarkMode[900]
            )(props),
            bg: mode('neutral.900', 'neutralDarkMode.900')(props),
            px: '8px',
            py: '4px',
            fontSize: 'xs',
            borderRadius: '4px',
            textAlign: 'center',
          }
        },
        variants: {
          wide: {
            width: '700px',
            maxWidth: '900px',
          },
          'low-z-index': {
            zIndex: 8,
          },
        },
      },
      Radio: {
        variants: {
          cleanlabBlue: ({ colorScheme = 'blue' }) => ({
            bg: mode('surface.1', 'surfaceDarkMode.1'),
            control: {
              _checked: {
                color: 'white',
                bg: `${colorScheme}.600`,
                borderColor: `${colorScheme}.700`,
              },
            },
          }),
        },
      },
      Link: linkTheme,
      Text: {
        variants: {
          warning: (props: StyleFunctionProps) => ({
            color: props.colorMode === 'light' ? 'orange.600' : 'orange.300',
            fontSize: 'xs',
            fontWeight: '500',
          }),
          success: (props: StyleFunctionProps) => ({
            color: props.colorMode === 'light' ? 'green.600' : 'green.300',
            fontSize: 'xs',
            fontWeight: '500',
          }),
        },
      },
      Kbd: {
        variants: {
          'resolver-button-hotkey': {
            size: '20px',
            borderColor: 'gray.200',
            bg: 'white',
            color: 'gray.700',
          },
          'data-labeling-hotkey': (props: StyleFunctionProps) => ({
            size: '20px',
            width: '24px',
            height: '20px',
            bg: mode('neutral.200', 'neutralDarkMode.200')(props),
            textAlign: 'center',
            pt: '2px',
            color: mode('neutral.700', 'neutralDarkMode.700')(props),
          }),
        },
      },
      Badge: badgeTheme,
      Button: buttonTheme,
      CloseButton: closeButtonTheme,
      Checkbox: checkboxTheme,
      Input: inputTheme,
      Card: cardTheme,
      Accordion: accordionTheme,
      Tab: {
        baseStyle: {
          _focus: { boxShadow: 'none' },
        },
      },
      Switch: {
        baseStyle: (props: StyleFunctionProps) => {
          return {
            track: {
              _checked: {
                bg: mode('blue.500', 'blueDarkMode.500')(props),
              },
            },
          }
        },
      },
      Modal: {
        parts: ['dialog'],
        baseStyle: (props: StyleFunctionProps) => {
          return {
            overlay: {
              backgroundColor: mode('rgba(0,0,0,0.48)', 'rgba(0,0,0,0.6)')(props),
            },
            closeButton: {
              top: '18px',
              right: '24px',
              width: '24px',
              height: '24px',
              svg: {
                width: '12px',
                height: '12px',
              },
            },
            header: {
              ...typography.body['300-medium'],
              paddingLeft: '24px',
              padingRight: '24px',
              paddingTop: '16px',
              paddingBottom: '16px',
              color: mode('text.strong', 'textDarkMode.strong')(props),
              minHeight: '60px',
              borderBottom: '1px solid',
              borderBottomColor: mode('border.1', 'borderDarkMode.1')(props),
            },
            footer: {
              paddingLeft: '24px',
              padingRight: '24px',
              paddingTop: '16px',
              paddingBottom: '16px',
              borderTop: '1px solid',
              borderTopColor: mode('border.1', 'borderDarkMode.1')(props),
            },
            body: {
              padding: '24px',
            },
            dialog: {
              padding: 0,
              border: '1px solid',
              borderColor: mode('border.2', 'borderDarkMode.2')(props),
              borderRadius: '12px',
              backgroundColor: mode('white', 'neutralDarkMode.50')(props),
            },
          }
        },
      },
    },
    Tag: {
      baseStyle: {
        rounded: 'lg',
      },
    },
    Alert: {
      variants: {
        'left-accent': (props: any) => {
          const { status } = props
          if (status === 'success') {
            return {
              container: {
                bg: mode('gray.100', 'gray.700')(props),
                borderInlineStartColor: mode('teal.500', 'teal.200')(props),
                mr: '80px',
              },
              icon: {
                color: mode('teal.500', 'teal.200')(props),
              },
            }
          }
          return {
            container: {
              bg: mode('gray.100', 'gray.700')(props),
              mr: '80px',
            },
          }
        },
      },
    },
  },
  theme
)
