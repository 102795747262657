import { ColumnState } from 'ag-grid-community'

export const queryKeys = {
  datasheet: {
    all: () => ['datasheets'] as const,
    id: (cleansetId: string) => ({
      all: () => [...queryKeys.datasheet.all(), cleansetId],
      gridLayout: () => [...queryKeys.datasheet.id(cleansetId).all(), 'gridLayout'],
      rowCount: () => ({
        all: () => [...queryKeys.datasheet.id(cleansetId).all(), 'rowCount'],
        filterType: (filterType: RowCountType) => [
          ...queryKeys.datasheet.id(cleansetId).rowCount().all(),
          filterType,
        ],
      }),
      rowCounts: () => [...queryKeys.datasheet.id(cleansetId).all(), 'rowCounts'],
      issueColumns: () => [...queryKeys.datasheet.id(cleansetId).all(), 'issueColumns'],
      columnValues: () => ({
        all: () => [...queryKeys.datasheet.id(cleansetId).all(), 'columnValues'],
        column: (columnName: string) => [
          ...queryKeys.datasheet.id(cleansetId).columnValues().all(),
          columnName,
        ],
      }),
      topImageExamples: () => ({
        all: () => [...queryKeys.datasheet.id(cleansetId).all(), 'topImageExamples'],
        label: (label: string) => [
          ...queryKeys.datasheet.id(cleansetId).topImageExamples().all(),
          label,
        ],
      }),
      errorsByColumn: () => ({
        all: () => [...queryKeys.datasheet.id(cleansetId).all(), 'errorsByColumn'],
        column: (column: string) => [
          ...queryKeys.datasheet.id(cleansetId).errorsByColumn().all(),
          column,
        ],
      }),
      checkHighlyUnlabeledClass: () => [
        ...queryKeys.datasheet.id(cleansetId).all(),
        'checkHighlyUnlabeledClass',
      ],
      metadataColumns: () => [...queryKeys.datasheet.id(cleansetId).all(), 'metadataColumns'],
      columns: () => [...queryKeys.datasheet.id(cleansetId).all(), 'columns'],
    }),
  },
}

export enum RowCountType {
  TOTAL = 'total',
  ISSUES = 'issues',
  RESOLVED = 'resolved',
  ISSUES_RESOLVED = 'issues_resolved',
  PREVIOUSLY_RESOLVED = 'previously_resolved',
  CUSTOM = 'custom',
  OUTLIERS = 'outliers',
  OUTLIERS_RESOLVED = 'outliers_resolved',
  LABEL_ISSUES = 'label_issues',
  LABEL_ISSUES_RESOLVED = 'label_issues_resolved',
  CUSTOM_WITH_ISSUES = 'custom_with_issues',
  UNLABELED = 'unlabeled',
  AMBIGUOUS = 'ambiguous',
  NEAR_DUPLICATE = 'is_near_duplicate',
  BEST_EXAMPLES = 'best_examples',
  IS_PII = 'is_PII',
  IS_INFORMAL = 'is_informal',
  IS_NON_ENGLISH = 'is_non_english',
  IS_TOXIC = 'is_toxic',
  IS_BIASED = 'is_biased',
  IS_DARK = 'is_dark',
  IS_LIGHT = 'is_light',
  IS_BLURRY = 'is_blurry',
  IS_ODD_SIZE = 'is_odd_size',
  IS_LOW_INFORMATION = 'is_low_information',
  IS_GRAYSCALE = 'is_grayscale',
  IS_ODD_ASPECT_RATIO = 'is_odd_aspect_ratio',
  IS_NSFW = 'is_NSFW',
}

export interface RowCountsRes {
  num_total: number
  num_label_issues: number
  num_previously_resolved: number
  num_outliers: number
  num_label_issues_non_filtered: number
  num_unlabeled: number
}

export interface TopImageExamples {
  label: string
  urls: string[]
}

export interface ErrorCountData {
  mostErrorsByCount: ErrorRank[]
  leastErrorsByCount: ErrorRank[]
  mostUnlabeledByCount: ErrorRank[]
  mostWellLabeledByCount: ErrorRank[]
  mostErrorsByPercentage: ErrorRank[]
  leastErrorsByPercentage: ErrorRank[]
  mostUnlabeledByPercentage: ErrorRank[]
  mostWellLabeledByPercentage: ErrorRank[]
}

export interface ErrorRank {
  ambiguous: number
  labelIssue: number
  totalIssuesCount: number
  totalIssuesPercentage: number
  outlier: number
  sourceValue: string
  total: number
  unlabeled: number
  wellLabeled: number
  nearDuplicate: number
}

export interface DatasheetColumnsRes {
  datasheet_columns: string[]
  dataset_columns: string[]
  datasheet_column_statuses: { [column_name: string]: string }
}

export interface GridLayoutRes {
  columnState: ColumnState[]
  filterModel: {
    [key: string]: any
  }
}
