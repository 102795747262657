import { CircularProgress, useColorModeValue } from '@chakra-ui/react'
import { cn } from '@utils/tailwindUtils'
import { Link } from 'react-router-dom'

import { DEPLOYMENT_STATUS } from '../DeploymentsGrid.types'
import { DeploymentStatusCellComponentProps } from './DeploymentStatusCellComponent.types'

const DeploymentStatusNumber: Record<Exclude<DEPLOYMENT_STATUS, 'FAILED'>, string> = {
  INITIALIZING: '1',
  TRAINING: '2',
  TRAINED: '3',
}

export const DeploymentStatusName: Record<DEPLOYMENT_STATUS, string> = {
  INITIALIZING: 'Initializing',
  TRAINING: 'Training',
  TRAINED: 'Deployed',
  FAILED: 'Failed',
}

const statusEnumToDesc = (status: DEPLOYMENT_STATUS) => {
  if (status === DEPLOYMENT_STATUS.TRAINED) {
    // check ready state first
    return DeploymentStatusName[status]
  } else if (
    Object.keys(DeploymentStatusNumber).includes(status) &&
    status !== DEPLOYMENT_STATUS.FAILED
  ) {
    return `(${DeploymentStatusNumber[status]}/3) ${DeploymentStatusName[status]}`
  } else {
    return DeploymentStatusName[status]
  }
}

const DeploymentStatusCellComponent = (props: DeploymentStatusCellComponentProps) => {
  const { status, to } = props

  const statusDescription = statusEnumToDesc(status)

  const progressColor = useColorModeValue('cyan.500', 'cyanDarkMode.500')
  const trackColor = useColorModeValue('surface.2-hover', 'surfaceDarkMode.2')

  switch (status) {
    case DEPLOYMENT_STATUS.TRAINED: {
      return (
        <Link
          className={cn(
            'truncate focus-visible:outline-none',
            'text-blue-700 hover:text-blue-800 hover:underline focus-visible:underline'
          )}
          to={to}
        >
          {statusDescription}
        </Link>
      )
    }
    case DEPLOYMENT_STATUS.FAILED: {
      return <>{statusDescription}</>
    }
    default: {
      return (
        <div className="flex h-full items-center gap-3">
          <CircularProgress
            aria-label="Project Status"
            isIndeterminate
            color={progressColor}
            trackColor={trackColor}
            thickness="12px"
            size="16px"
          />
          {statusDescription || 'Processing'}...
        </div>
      )
    }
  }
}

export default DeploymentStatusCellComponent
