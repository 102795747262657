import { ColumnState } from 'ag-grid-community'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'

export interface BatchActionsModalProps {
  isOpen: boolean
  onClose: VoidFunction
  gridApi: GridApi
  cleansetId: string
  relabelOptions: (string | number | boolean)[]
  isTemplate: boolean
  columnState: ColumnState[]
  isMultiLabel: boolean
  currPageNum: number
  setCurrPageNum: (currPageNum: number) => void
  fetchAndUpdateCurrRowData: (selectedRowIndex: number, retry?: number) => void
  firstGridDataRendered: boolean
}

export enum BatchAction {
  autoFix = 'Auto-fix',
  exclude = 'Exclude',
  label = 'Label',
  needsReview = 'Needs review',
  autoLabel = 'Auto label', // only used for data labeling workflow. don't include in batch actions modal options for now
}

export const options = ['Auto-fix', 'Exclude', 'Label', 'Needs review']

export const multiLabelOptions = ['Auto-fix', 'Exclude', 'Needs review']
