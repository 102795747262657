export const queryKeys = {
  users: {
    all: () => ['users'] as const,
    id: (userId: string) => [...queryKeys.users.all(), userId],
    guideStatus: () => [...queryKeys.users.all(), 'guide-status'],
    myself: () => [...queryKeys.users.all(), 'myself'],
    organizationId: (organizationId: string) => [...queryKeys.users.all(), organizationId],
  },
}

export interface UserRowRes {
  id: string
  email: string
  email_verified: boolean
  name: string | null
  api_key: string
  api_key_timestamp: number
  access_level: string
  signup_token: string
  signup_token_timestamp: number | null
  account_activated: boolean
  account_activated_at: number | null
  email_confirmed: boolean
  phone_number: string | null
  signup_origin: string | null
  account_status: string | null
}

export interface VpcUserRowRes {
  accessLevel: string
  accountActivated: boolean
  accountActivatedAt: number
  accountBlockReason: string
  accountStatus: AccountStatus
  apiKey: string
  apiKeyTimestamp: number
  email: string
  emailConfirmed: boolean
  id: string
  initialPlanIdV1: string
  middleName: string
  name: string
  organizationId: string
  phoneNumber: string
  showGuide: boolean
  showProjectGuide: boolean
  showUploadWizard: boolean
  signupOrigin: string
  signupToken: string
  signupTokenTimestamp: number
  updatedAt: number
}

export enum AccessLevel {
  ORGANIZATION_ADMIN = 'organization_admin',
  USER = 'user',
}

export enum AccountStatus {
  INVITED = 'invited',
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  BLOCKED = 'blocked',
}

export interface GetMyselfRes {
  accessLevel: AccessLevel
  activated: boolean
  accountActivated: boolean
  apiKey: string
  apiKeyTimestamp: number
  email: string
  emailConfirmed: boolean
  id: string
  initialPlanId: string | null
  name: string
  showGuide: boolean
  showUploadWizard: boolean
  signupToken: string
  signupTokenTimestamp: number | null
  updatedAt: number
  accountStatus: string
}

export interface AddedUser {
  email: string
  accessLevel: 'user' | 'organization_admin'
}
