import { Badge } from '@components/badge/Badge'

import { ActionCellTagProps, actionToHue, categoryDisplay } from './ActionCellTag.types'

const ActionCellTag = (props: ActionCellTagProps) => {
  const { category, action } = props

  return (
    <Badge round="small" size="small" variant="actionSubtle" hue={actionToHue[action]}>
      {categoryDisplay[category]}
    </Badge>
  )
}

export default ActionCellTag
