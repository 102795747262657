import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { Button } from '@components/button/Button'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { AccountStatus } from '@services/user/constants'
import { useDeactivateUser, useDeleteAddedUser, useReactivateUser } from '@services/user/mutations'

import { ButtonCloseModalChakra } from '../ButtonCloseChakraModal'
import { ModalProps } from '../Modal.types'

interface UpdateAccountStatusModalProps extends ModalProps {
  userId: string
  email: string
  accountStatus: AccountStatus
}

const UpdateAccountStatusModal = (props: UpdateAccountStatusModalProps) => {
  const { isOpen, onClose, userId, email, accountStatus } = props

  const toast = useToast()

  const surface0 = useColorModeValue('surface.0', 'surfaceDarkMode.0')

  const isDeactivation = accountStatus === AccountStatus.ACTIVE
  const isReactivation = accountStatus === AccountStatus.DEACTIVATED
  const isDeletion = accountStatus === AccountStatus.INVITED

  const onSuccess = () => {
    toast({
      ...defaultToastAlertProps,
      render: renderChakraToastAlert({
        heading: isDeactivation
          ? 'User deactivated'
          : isDeletion
            ? 'User invitation deleted'
            : 'User reactivated',
        status: isDeactivation || isDeletion ? 'warning' : 'success',
      }),
    })
    onClose()
  }

  const onError = () => {
    toast({
      ...defaultToastAlertProps,
      render: renderChakraToastAlert({
        heading: 'Failed to update user account status',
        status: 'warning',
      }),
    })
  }

  const { mutate: deactivateUser, isLoading: isDeactivateUserLoading } = useDeactivateUser({
    onSuccess: onSuccess,
    onError: onError,
  })
  const { mutate: reactivateUser, isLoading: isReactivateUserLoading } = useReactivateUser({
    onSuccess: onSuccess,
    onError: onError,
  })
  const { mutate: deleteAddedUser, isLoading: isDeleteUserLoading } = useDeleteAddedUser({
    onSuccess: onSuccess,
    onError: onError,
  })

  const isMutationLoading =
    isDeactivateUserLoading || isReactivateUserLoading || isDeleteUserLoading

  const submitOnClick = () => {
    if (isReactivation) {
      reactivateUser({ userId: userId })
    } else if (isDeactivation) {
      deactivateUser({ userId: userId })
    } else if (isDeletion) {
      deleteAddedUser({ userId: userId })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent bg={surface0}>
        <ModalHeader pb={0} className="text-text-strong">
          {isDeactivation ? 'Deactivate user?' : isDeletion ? 'Delete user?' : 'Reactivate user?'}
        </ModalHeader>
        <ButtonCloseModalChakra />
        <ModalBody pt={0}>
          <p className="type-body-200 pt-4 text-text-primary">
            {`Are you sure you want to ${isDeactivation ? 'deactivate' : isDeletion ? 'delete user' : 'reactivate'} ${email}? They will be ${isDeactivation || isDeletion ? 'removed from' : 'added back to'} your
            organization.`}
          </p>
        </ModalBody>
        <ModalFooter pt="12px" pb="24px">
          <div className="flex flex-row gap-5">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={isMutationLoading}
              variant={isDeactivation || isDeletion ? 'critical' : 'primary'}
              onClick={submitOnClick}
            >
              {isMutationLoading ? (
                <Spinner size="sm" />
              ) : isDeactivation ? (
                'Deactivate'
              ) : isDeletion ? (
                'Delete invitation'
              ) : (
                'Reactivate'
              )}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateAccountStatusModal
