import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { queryKeys } from '@services/organizationQuotas/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const baseURL = `${REACT_APP_CLEANLAB_API_URL}/api/organization_quotas/`

const axiosClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})

export const useEditOrgBudget = ({
  onSuccess,
  onError,
}: {
  onSuccess: VoidFunction
  onError: VoidFunction
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      organizationQuotaId,
      orgBudget,
    }: {
      organizationQuotaId: string
      orgBudget: number
    }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(
        organizationQuotaId,
        { total_budget_dollars: Number(orgBudget) },
        createAuthHeaders(accessToken)
      )
    },
    onError: () => {
      onError()
    },
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries(queryKeys.organizationQuotas.all())
    },
  })
}
