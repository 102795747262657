import { Stack, useRadioGroup } from '@chakra-ui/react'
import { RadioCardProps } from '@common/forms/radioCard/RadioCard.types'
import { RadioCardGroupProps } from '@common/forms/radioCard/RadioCardGroup.types'
import React from 'react'

export const RadioCardGroup = <T extends string>(props: RadioCardGroupProps<T>) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  })

  const cards = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<RadioCardProps>>(React.isValidElement)
        .map((card) => {
          return React.cloneElement(card, {
            radioProps: getRadioProps({
              value: card.props.value,
            }),
          })
        }),
    [children, getRadioProps]
  )

  return <Stack {...getRootProps(rest)}>{cards}</Stack>
}
