import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { Persist } from '@utils/zustand/zustand.types'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type DashboardSettingsStore = {
  /**
   * Don't show the demo Projects on the dashboard
   */
  hideProjectDemos: boolean
  setHideProjectDemos: (hide: boolean) => void
}

export const useDashboardSettings = create<DashboardSettingsStore>(
  (persist as Persist<DashboardSettingsStore>)(
    (set, get) => ({
      hideProjectDemos: !!IS_VPC_ENVIRONMENT,
      setHideProjectDemos: (hide: boolean) => {
        const current = get().hideProjectDemos
        if (IS_VPC_ENVIRONMENT && current !== hide) {
          set(() => ({ hideProjectDemos: true }))
          return
        }
        if (current === hide) return
        set(() => ({ hideProjectDemos: hide }))
      },
    }),
    {
      name: 'dashboardSettings',
      storage: createJSONStorage(() => localStorage),
      version: 0,
    }
  )
)
