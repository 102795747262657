import { RowCountType } from '@services/datasheet/constants'
import { ActionType } from 'src/pages/cleanset/commandpalette/CommandPalette.types'
import { CLEANLAB_FRONTEND_COLUMN } from 'src/pages/cleanset/datasheet/Datasheet.types'
import { getHeaderName } from 'src/pages/cleanset/filterMenu/FilterMenu.helpers'
import {
  CleansetFilterActions,
  FilterActionType,
  FilterData,
  FilterOperator,
} from 'src/pages/cleanset/filterReducer/FilterState.types'
import { ISSUE_TYPE, METADATA_TYPE } from 'src/pages/cleanset/filterSidebar/FilterSidebar.types'

export const getQuickFilterFunction = (filterType: string) => {
  if (filterType === RowCountType.TOTAL) {
    return clearFilters
  } else if (filterType === RowCountType.ISSUES_RESOLVED) {
    return viewResolvedIssues
  } else if (filterType === RowCountType.LABEL_ISSUES) {
    return applyIssueQuickFilter
  } else if (Object.values(ISSUE_TYPE).includes(filterType as ISSUE_TYPE)) {
    return applyIssueQuickFilter
  } else if (Object.values(METADATA_TYPE).includes(filterType as METADATA_TYPE)) {
    return applyMetadataQuickFilter
  } else {
    return null
  }
}

const clearFilters = (dispatch: React.Dispatch<FilterActionType>, _: string): void => {
  dispatch({
    type: CleansetFilterActions.CLEAR_ALL_FILTERS,
  })
}

const viewResolvedIssues = (dispatch: React.Dispatch<FilterActionType>, _: string): void => {
  const actionFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.ACTION,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.ACTION, false),
    filterType: FilterOperator.NotEquals,
    filterInput: ActionType.UNRESOLVED,
    agGridType: 'agTextColumnFilter',
    isOpen: false,
    isPreset: false,
  }
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [actionFilter],
  })
}

export const applyIssueQuickFilter = (
  dispatch: React.Dispatch<FilterActionType>,
  filterType: string
): void => {
  const cleanlabFrontendColumn = getFrontendColumnByFilterType(filterType)
  const issueFilter: FilterData = {
    property: cleanlabFrontendColumn,
    headerName: getHeaderName(cleanlabFrontendColumn, false),
    filterType: FilterOperator.Equals,
    filterInput: 'true',
    agGridType: '',
    isOpen: false,
    isPreset: true,
    isChecked: true,
    hide: true,
  }
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [issueFilter],
  })
}

export const applyMetadataQuickFilter = (
  dispatch: React.Dispatch<FilterActionType>,
  filterType: string
): void => {
  const cleanlabFrontendColumn = getFrontendColumnByFilterType(filterType)
  const metadataFilter: FilterData = {
    property: cleanlabFrontendColumn,
    headerName: getHeaderName(cleanlabFrontendColumn, false),
    filterType: FilterOperator.Equals,
    filterInput: 'true',
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [metadataFilter],
  })
}

export const getFrontendColumnByFilterType = (filterType: string) => {
  switch (filterType) {
    case RowCountType.ISSUES_RESOLVED:
      return CLEANLAB_FRONTEND_COLUMN.ACTION
    case RowCountType.LABEL_ISSUES:
      return CLEANLAB_FRONTEND_COLUMN.ISSUE
    case ISSUE_TYPE.OUTLIER:
      return CLEANLAB_FRONTEND_COLUMN.OUTLIER
    case ISSUE_TYPE.UNLABELED:
      return CLEANLAB_FRONTEND_COLUMN.UNLABELED
    case ISSUE_TYPE.AMBIGUOUS:
      return CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS
    case ISSUE_TYPE.IS_NEAR_DUPLICATE:
      return CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE
    case METADATA_TYPE.BEST_EXAMPLES:
      return CLEANLAB_FRONTEND_COLUMN.BEST_EXAMPLES
    case METADATA_TYPE.IS_PII:
      return CLEANLAB_FRONTEND_COLUMN.PII
    case METADATA_TYPE.IS_INFORMAL:
      return CLEANLAB_FRONTEND_COLUMN.INFORMAL
    case METADATA_TYPE.IS_NON_ENGLISH:
      return CLEANLAB_FRONTEND_COLUMN.NON_ENGLISH
    case METADATA_TYPE.IS_TOXIC:
      return CLEANLAB_FRONTEND_COLUMN.TOXIC
    case METADATA_TYPE.IS_BIASED:
      return CLEANLAB_FRONTEND_COLUMN.BIASED
    case METADATA_TYPE.IS_DARK:
      return CLEANLAB_FRONTEND_COLUMN.DARK
    case METADATA_TYPE.IS_LIGHT:
      return CLEANLAB_FRONTEND_COLUMN.LIGHT
    case METADATA_TYPE.IS_BLURRY:
      return CLEANLAB_FRONTEND_COLUMN.BLURRY
    case METADATA_TYPE.IS_ODD_SIZE:
      return CLEANLAB_FRONTEND_COLUMN.ODD_SIZE
    case METADATA_TYPE.IS_LOW_INFORMATION:
      return CLEANLAB_FRONTEND_COLUMN.LOW_INFORMATION
    case METADATA_TYPE.IS_GRAYSCALE:
      return CLEANLAB_FRONTEND_COLUMN.GRAYSCALE
    case METADATA_TYPE.IS_ODD_ASPECT_RATIO:
      return CLEANLAB_FRONTEND_COLUMN.ODD_ASPECT_RATIO
    case METADATA_TYPE.IS_NSFW:
      return CLEANLAB_FRONTEND_COLUMN.NSFW
    case 'label issue':
      return CLEANLAB_FRONTEND_COLUMN.ISSUE
    case 'near duplicate':
      return CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE
    default:
      return ''
  }
}
