import { ColumnApi, GridApi } from 'ag-grid-community'
import { useEffect, useMemo } from 'react'
import { restoreGridLayout } from 'src/pages/cleanset/Cleanset.helpers'
import { CLEANLAB_FRONTEND_COLUMN } from 'src/pages/cleanset/datasheet/Datasheet.types'
import { getHeaderName } from 'src/pages/cleanset/filterMenu/FilterMenu.helpers'
import {
  CleansetFilterActions,
  FilterActionType,
} from 'src/pages/cleanset/filterReducer/FilterState.types'

export const useRestoreGridSettings = (
  cleansetId: string,
  labelColumn: string,
  gridApi: GridApi | null,
  columnApi: ColumnApi | null,
  filterDispatch: React.Dispatch<FilterActionType>,
  suppressRestore: boolean
) => {
  const { columnState, filterModel } = useMemo(() => {
    try {
      return (
        JSON.parse(localStorage.getItem(cleansetId)!) ?? { columnState: null, filterModel: null }
      )
    } catch {
      return { columnState: null, filterModel: null }
    }
  }, [cleansetId])

  useEffect(() => {
    if (columnState && filterModel && !suppressRestore) {
      restoreGridLayout(columnState, filterModel, gridApi, columnApi)
      const filters = Object.keys(filterModel).map((key) => {
        const isGivenColumn = key === labelColumn
        return {
          property: isGivenColumn ? CLEANLAB_FRONTEND_COLUMN.GIVEN : key,
          headerName: getHeaderName(key, isGivenColumn),
          filterType: filterModel[key].type,
          filterInput: filterModel[key].filter,
          isPreset: false,
          isOpen: false,
          agGridType: '',
        }
      })
      filterDispatch({
        type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
        filterData: filters,
      })
    }
  }, [columnApi, columnState, filterModel, gridApi, labelColumn, filterDispatch, suppressRestore])

  return { columnState: columnState, filterModel: filterModel }
}
