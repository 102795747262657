import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys } from '@services/userNotification/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const apiVersion = 'v1'
const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/user_notifications`,
  withCredentials: true,
})

export const useAddUserNotificationMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: async (message: string) => {
      const accessToken = await auth.getTokenSilently()
      await axiosClient.post('/add_notification', { message }, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.notifications.all() })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}
export const useCloseNotificationMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: async () => {
      const accessToken = await auth.getTokenSilently()
      return axiosClient.patch('/seen', {}, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.notifications.all() })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}
