import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import {
  OrgInfoRes,
  OrgUsageDownloadUrlRes,
  OrgUsageSummaryRes,
  queryKeys,
} from '@services/organizations/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import camelCaseKeys from '@utils/functions/camelCaseKeys'
import axios from 'axios'
import { useQuery } from 'react-query'

const baseURL = `${REACT_APP_CLEANLAB_API_URL}/api/organizations/`

const vpcAxiosClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})

export const useOrg = (id: string) => {
  const { data } = useQuery<OrgInfoRes>({
    queryKey: queryKeys.organizations.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await vpcAxiosClient.get(id, createAuthHeaders(accessToken))
      return camelCaseKeys(res.data)
    },
    meta: { error: 'Failed to fetch organization info.' },
    suspense: true,
  })
  return data!
}

export const useOrgUsageSummary = (orgId: string) => {
  const { data } = useQuery<OrgUsageSummaryRes>({
    queryKey: queryKeys.organizations.usageSummary(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await vpcAxiosClient.get(`usage_summary/${orgId}`, createAuthHeaders(accessToken))
      return camelCaseKeys(res.data)
    },
    meta: { error: 'Failed to fetch organization usage.' },
    suspense: true,
    enabled: !!orgId,
  })
  return data!
}

export const useUsageDownloadUrl = (orgId: string) => {
  const { data } = useQuery<OrgUsageDownloadUrlRes>({
    queryKey: queryKeys.organizations.downloadUrl(),
    queryFn: async () => {
      try {
        const accessToken = await auth.getTokenSilently()
        const res = await vpcAxiosClient.get(
          `usage_summary/${orgId}/download_url`,
          createAuthHeaders(accessToken)
        )
        return camelCaseKeys(res.data)
      } catch {
        return { downloadUrl: '' }
      }
    },
    meta: { error: 'Failed to fetch usage download link.' },
    suspense: true,
    enabled: !!orgId,
  })
  return data!
}
