import { Center, Divider, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

import { StepperProps } from './Stepper.types'

const Stepper = ({ activeStep, stepOneIcon, stepTwoIcon }: StepperProps) => {
  const borderColor = useColorModeValue('neutral.300', 'neutralDarkMode.300')
  const inactiveBackgroundColor = useColorModeValue('neutral.50', 'neutralDarkMode.50')
  const activeTitleColor = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const inActiveTitleColor = useColorModeValue('neutral.600', 'neutralDarkMode.600')

  return (
    <HStack
      fontSize="sm"
      fontWeight="600"
      w="100%"
      h="44px"
      borderBottom="1px solid"
      borderTopEndRadius="8px"
      borderColor={borderColor}
      gap={0}
    >
      <HStack
        pl="16px"
        w="50%"
        h="100%"
        borderTopStartRadius="8px"
        alignItems="center"
        bg={activeStep === 0 ? 'transparent' : inactiveBackgroundColor}
      >
        {stepOneIcon}
        <Text color={activeStep === 0 ? activeTitleColor : inActiveTitleColor}>
          Step 1: Auto label confident data points
        </Text>
      </HStack>
      <Center height="100%">
        <Divider borderColor={borderColor} orientation="vertical" />
      </Center>
      <HStack
        pl="16px"
        w="50%"
        h="100%"
        alignItems="center"
        borderTopEndRadius="8px"
        bg={activeStep === 1 ? 'transparent' : inactiveBackgroundColor}
      >
        {stepTwoIcon}
        <Text color={activeStep === 1 ? activeTitleColor : inActiveTitleColor}>
          Step 2: Label low confidence data points
        </Text>
      </HStack>
    </HStack>
  )
}

export default Stepper
