import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useConfirmSubscriptionCancel } from '@services/billing/mutations'
import { useCurrentPeriodUsage, useSubscriptionDetails } from '@services/billing/queries'
import { timestampToDate } from '@utils/functions/timestampToDate'
import React, { useState } from 'react'

import { CancelSubscriptionModalProps } from './CancelSubscriptionModal.types'

const CancelSubscriptionModal = (props: CancelSubscriptionModalProps) => {
  const { isOpen, onClose, cancelRef } = props

  const { trackEvent } = useEventTracking()
  const subscriptionDetails = useSubscriptionDetails({ enabled: true })
  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  const [cancellationComplete, setCancellationComplete] = useState(false)

  const { mutate: confirmSubscriptionCancel, isLoading } = useConfirmSubscriptionCancel({
    onSuccess: () => {
      setCancellationComplete(true)
    },
  })
  const { periodEnd } = useCurrentPeriodUsage()

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent bg={modalBg}>
        <AlertDialogHeader>
          {cancellationComplete ? "We're sad to see you go!" : 'Cancel Subscription?'}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {cancellationComplete ? (
            <Text pt={4}>
              You will still be able to use your plan’s full benefits until the end of your current
              billing period, {timestampToDate(periodEnd)}{' '}
            </Text>
          ) : (
            <>
              <Text>
                By canceling your subscription to Cleanlab Studio, you will lose access to Dataset
                upload and Project creation at the end of your current billing period. You will
                still be able to access and export Projects you have already paid for.
              </Text>
              <Text pt={4}>Do you wish to proceed?</Text>
            </>
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          {!cancellationComplete && (
            <>
              <Button onClick={onClose} aria-label="do not cancel subscription">
                No
              </Button>
              <Button
                colorScheme="teal"
                isLoading={isLoading}
                isDisabled={isLoading}
                ml={3}
                onClick={() => {
                  confirmSubscriptionCancel()
                  trackEvent(MixpanelEvents.clickConfirmCancellationButton, {
                    ...subscriptionDetails,
                  })
                }}
                aria-label="yes cancel subscription"
              >
                Yes, cancel my plan
              </Button>
            </>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default CancelSubscriptionModal
