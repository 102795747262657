import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useState } from 'react'

export const useBetaFeatureButton = (featureName: string) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { trackEvent } = useEventTracking()

  const onButtonClick = () => {
    setIsOpen(true)
    trackEvent(MixpanelEvents.clickBetaFeatureButton, { featureName: featureName })
  }

  const onCloseModal = () => setIsOpen(false)

  return { isOpen, onButtonClick, onCloseModal, featureName }
}
