export const queryKeys = {
  signupSettings: {
    all: () => ['signupSettings'] as const,
  },
}

export interface SignupSettings {
  id: string
  account_activation_enabled: boolean
  allowed_activations_per_day: number
}
