import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useUpdateUserQuotaMutation } from '@services/userQuota/mutations'
import { useCallback, useState } from 'react'

import { UpdateQuotaModalProps } from './UpdateQuotaModal.types'

const UpdateQuotaModal = (props: UpdateQuotaModalProps) => {
  const { isOpen, onClose, selectedIds } = props
  const [dataStorageLimit, setDataStorageLimit] = useState<number>(100)
  const [tlmTokenLimit, setTlmTokenLimit] = useState<number>(0)
  const [tlmTokenPerMinuteLimit, setTlmTokenPerMinuteLimit] = useState<number>(0)
  const [tlmPreviewModelsRequestLimit, setTlmPreviewModelsRequestLimit] = useState<number>(0)
  const { mutate: updateQuota } = useUpdateUserQuotaMutation()
  const toast = useToast()
  const borderColor = 'gray.400'

  const areLimitsValid = useCallback(
    (limits: number[]) => limits.every((limit) => !isNaN(limit)),
    []
  )

  const handleConfirmUpdates = useCallback(() => {
    const isValid = areLimitsValid([
      dataStorageLimit,
      tlmTokenLimit,
      tlmTokenPerMinuteLimit,
      tlmPreviewModelsRequestLimit,
    ])
    if (!isValid) {
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Limits must be a valid number',
          status: 'warning',
        }),
      })
      return
    }
    for (const userId of selectedIds) {
      updateQuota({
        userId,
        dataStorageLimit,
        tlmTokenLimit,
        tlmTokenPerMinuteLimit,
        tlmPreviewModelsRequestLimit,
      })
    }
  }, [
    dataStorageLimit,
    tlmTokenLimit,
    tlmTokenPerMinuteLimit,
    tlmPreviewModelsRequestLimit,
    selectedIds,
    toast,
    updateQuota,
    areLimitsValid,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update User Quotas</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing="1.5rem">
            <FormControl>
              <FormLabel>Data Storage Limit (GB)</FormLabel>
              <NumberInput
                borderColor={borderColor}
                value={dataStorageLimit}
                onChange={(valueString) => {
                  setDataStorageLimit(Number(valueString))
                }}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>TLM Token Limit</FormLabel>
              <NumberInput
                borderColor={borderColor}
                value={tlmTokenLimit}
                onChange={(valueString) => {
                  setTlmTokenLimit(Number(valueString))
                }}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>TLM Token Per Minute Limit</FormLabel>
              <NumberInput
                borderColor={borderColor}
                value={tlmTokenPerMinuteLimit}
                onChange={(valueString) => {
                  setTlmTokenPerMinuteLimit(Number(valueString))
                }}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>TLM Preview Models Request Limit</FormLabel>
              <NumberInput
                borderColor={borderColor}
                value={tlmPreviewModelsRequestLimit}
                onChange={(valueString) => {
                  setTlmPreviewModelsRequestLimit(Number(valueString))
                }}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter mt={3}>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={() => {
              handleConfirmUpdates()
              onClose()
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateQuotaModal
