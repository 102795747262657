'use client'

import useResizeObserver from '@hooks/useResizeObserver'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { mergeRefs } from '@react-aria/utils'
import { cn } from '@utils/tailwindUtils'
import {
  type ComponentPropsWithoutRef,
  type ComponentPropsWithRef,
  forwardRef,
  memo,
  useMemo,
  useRef,
  useState,
} from 'react'

import { getUsageBgClass } from './MeterUsage'

const GAP = 4
const PILL_WIDTH = 7

const UsagePill = memo(({ filled, position }: { filled: boolean; position: number }) => {
  const colorClass = getUsageBgClass(position)

  return (
    <div
      style={{ width: PILL_WIDTH }}
      className="relative h-full overflow-clip rounded-full bg-surface-2"
    >
      <div
        className={cn(
          colorClass,
          'absolute bottom-0 left-0 right-0 top-0 translate-y-[100%] transition-transform duration-100',
          filled && 'translate-y-0'
        )}
      ></div>
    </div>
  )
})

const getPillCount = (ratio: number, availableWidth: number) => {
  return Math.floor((availableWidth + GAP) / (PILL_WIDTH + GAP))
}

type MeterUsageDisplayProps = { clasName?: string } & ComponentPropsWithoutRef<
  typeof ProgressPrimitive.Root
>

const MeterUsageDisplayBase = (
  { className, value, max = 100, ...props }: MeterUsageDisplayProps,
  refProp: ComponentPropsWithRef<typeof ProgressPrimitive.Root>['ref']
) => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  useResizeObserver(ref, (rect) => {
    setWidth(rect.width)
  })
  const mergedRef = mergeRefs(refProp ?? null, ref)
  const ratio = (value ?? 0) / max
  const pills = useMemo(() => {
    const pillCount = getPillCount(ratio, width)
    return new Array(pillCount).fill(null).map((_, i) => {
      const position = i / pillCount
      // including pillCount in the key ensures pill fill animations won't happen when resizing the meter
      return <UsagePill key={`${i}/${pillCount}`} filled={ratio > position} position={position} />
    })
  }, [ratio, width])
  return (
    <ProgressPrimitive.Root
      ref={mergedRef}
      className={cn('relative w-full overflow-hidden', className)}
      role="meter"
      {...props}
    >
      <ProgressPrimitive.Indicator className="flex h-[30px] items-stretch justify-between">
        {pills}
      </ProgressPrimitive.Indicator>
    </ProgressPrimitive.Root>
  )
}

export const MeterUsageDisplay = memo(forwardRef(MeterUsageDisplayBase))
MeterUsageDisplay.displayName = 'MeterUsageDisplay'
