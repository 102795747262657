import { HeatMapDatum, HeatMapSerie } from '@nivo/heatmap'
import { ColumnAndValue } from '@services/datasheetApi'

export interface CleansetChartsProps {
  scrollToSummary?: boolean
  setScrollToSummary?: (scrollToSummary: boolean) => void
  setAccuracy?: (accuracy: number) => void
  isTemplate?: boolean
  firstGridDataRendered?: boolean
  projectName: string
  isInitialFetch?: boolean
  columns?: string[]
  labelColumn?: string
}

export interface LabelIssuesDataType {
  all: ColumnAndValue[]
  issue: ColumnAndValue[]
}
export interface LabelDistributionDatapoint {
  label: string
  given: number
  suggested: number
}

export interface CorrectionsData {
  correction: string
  count: number
}

export interface CorrectionDistributionType {
  categories: string[]
  values: number[]
  heatmap_data: HeatMapSerie<HeatMapDatum, Record<string, unknown>>[] | string | null
}

export interface HeatMapColumnType {
  x: string | number
  y: number
}

export interface HeatMapRowType {
  data: HeatMapColumnType[]
  id: string | number
}

export enum RankedChart {
  WorstPerformers = 'Worst Performers',
  BestPerformers = 'Best Performers',
  MostUnlabeled = 'Most Unlabeled',
  MostWellLabeled = 'Most Well Labeled',
}

export enum RankedChartMode {
  Count = 'Count',
  Percentage = 'Percentage',
}

export enum RankedChartKey {
  MostErrorsCount = 'mostErrorsByCount',
  MostErrorsPercentage = 'mostErrorsByPercentage',
  LeastErrorsCount = 'leastErrorsByCount',
  LeastErrorsPercentage = 'leastErrorsByPercentage',
  MostUnlabeledCount = 'mostUnlabeledByCount',
  MostUnlabeledPercentage = 'mostUnlabeledByPercentage',
  MostWellLabeledCount = 'mostWellLabeledByCount',
  MostWellLabeledPercentage = 'mostWellLabeledByPercentage',
}
