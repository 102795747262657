import { ButtonClose } from '@components/buttonClose/ButtonClose'

interface PageAlertProps {
  message: string
  spanText: string
  onClose: VoidFunction
  buttonEnd?: React.ReactNode
  iconStart: React.ReactNode
}

const PageAlert = (props: PageAlertProps) => {
  const { message, spanText, buttonEnd, iconStart, onClose } = props

  return (
    <div className="flex w-full flex-row justify-between rounded-2 border border-border-1 bg-surface-1 px-6 py-5">
      <div className="flex flex-row gap-4">
        {iconStart}
        <div className="flex flex-row gap-2">
          <p className="type-body-100-medium text-text-strong">
            {message} <span className="type-body-100 pl-2 text-text-strong">{spanText}</span>
          </p>
        </div>
      </div>
      <div className="flex flex-row items-center gap-5">
        <span className="text-nowrap">{buttonEnd}</span>
        <ButtonClose size="xxSmall" onClick={onClose} aria-label="Close alert" />
      </div>
    </div>
  )
}

export default PageAlert
