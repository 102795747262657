import { Text, ToastId, useDisclosure, useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import UserQuotaAlert from '@common/alerts/userQuotaAlert/UserQuotaAlert'
import { UserQuotaAlertInfo } from '@common/alerts/userQuotaAlert/UserQuotaAlert.types'
import { Button } from '@components/button/Button'
import { IconRefresh } from '@components/icons'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { notifyAxiosError, showQuotaWarning, SOFT_QUOTA_STATUS } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useCreateCleansetMutation } from '@services/cleanset/mutations'
import { useOrgCostUsageRatio } from '@services/organizationQuotas/queries'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import RerunConfirmationAlert from '../rerunConfirmationAlert/RerunConfirmationAlert'
import { RerunCleanlabButtonProps } from './RerunCleanlabButton.types'

const RerunCleanlabButton = (props: RerunCleanlabButtonProps) => {
  const {
    projectId,
    datasetId,
    cleansetId,
    setShowTooltip,
    numIssuesResolved,
    totalIssues,
    isTemplate,
  } = props
  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const toast = useToast()
  const [quotaAlert, setQuotaAlert] = useState<UserQuotaAlertInfo | null>(null)
  const { trackEvent } = useEventTracking()
  const { featuresDisabled, numProjectsAvailable, onSubscriptionModalOpen } = useSubscription()
  const { costUsageRatio } = useOrgCostUsageRatio()

  const dataPointsCorrectedPercentage = totalIssues
    ? parseInt(((numIssuesResolved / totalIssues) * 100).toFixed(0))
    : 100

  const { mutate: createCleanset, isLoading: isCreateCleansetLoading } = useCreateCleansetMutation({
    onSuccess: (res) => {
      if (res.data.status === SOFT_QUOTA_STATUS) {
        showQuotaWarning(toast, res.data.description)
      }
      setTimeout(() => {
        navigate(`/projects/${projectId}`)
      }, 5000)
    },
    onError: (error) => {
      if (error.response?.status === 429) {
        setQuotaAlert({
          // @ts-ignore TODO(Luke) rewrite with 422
          code: error.response.data.code,
        })
        // @ts-ignore TODO(Luke) rewrite with 422
      } else if (error.response?.data.code === 'nonrepresented_classes') {
        toast({
          ...defaultToastAlertProps,
          render: renderChakraToastAlert({
            heading: 'Error: nonrespresented classes',
            // @ts-ignore TODO(Luke) rewrite with 422
            description: error.response.data.error,
            status: 'warning',
          }),
        })
      } else {
        notifyAxiosError(toast, error)
      }
    },
  })

  const handleCleanButtonClicked = () => {
    if (featuresDisabled || numProjectsAvailable < 1) {
      onSubscriptionModalOpen()
    } else if (numIssuesResolved < 1) {
      if (!toast.isActive(defaultToastAlertProps.id as ToastId)) {
        toast({
          ...defaultToastAlertProps,
          render: renderChakraToastAlert({
            heading: '',
            description:
              'This operation will train new ML models using the current version of your Dataset. Because this operation is quite time intensive, we recommend reviewing and correcting a significant portion (at least 5%) of your Dataset first. You must correct at least one data point before improving.',
            status: 'info',
          }),
        })
      }
    } else {
      onOpen()
      setShowTooltip(false)
    }
  }

  const handleCleanButtonConfirm = async () => {
    onClose()
    trackEvent(MixpanelEvents.rerunCleanlab, {
      projectId: projectId,
      datasetId: datasetId,
      cleansetId: cleansetId,
      numIssuesResolved: numIssuesResolved,
      isTemplate: isTemplate,
    })
    createCleanset({ projectId, datasetId, cleansetId })
  }

  return (
    <>
      <Tooltip
        content={
          <Text>Teaches our AI to more accurately find issues using the edits you’ve made.</Text>
        }
      >
        <Button
          variant="secondary"
          disabled={isCreateCleansetLoading || costUsageRatio >= 1}
          iconStart={<IconRefresh />}
          onClick={handleCleanButtonClicked}
          aria-label="Improve Issues Found"
        >
          {isCreateCleansetLoading ? 'Cleaning...' : 'Improve Issues Found'}
        </Button>
      </Tooltip>
      <RerunConfirmationAlert
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={cancelRef}
        issuesCorrectedPercentage={dataPointsCorrectedPercentage}
        confirmCleanButton={handleCleanButtonConfirm}
      />
      <UserQuotaAlert info={quotaAlert} setInfo={setQuotaAlert} />
    </>
  )
}

export default RerunCleanlabButton
