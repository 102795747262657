import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react'
import DestructiveButton from '@components/buttons/destructiveButton/DestructiveButton'
import React from 'react'

import { DeleteDatasetModalProps } from './DeleteDatasetModal.types'

const DeleteDatasetModal = (props: DeleteDatasetModalProps) => {
  const { isOpen, onClose, handleDeleteButtonClicked, datasetName } = props

  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Delete {datasetName}?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          You are about to permanently delete this Dataset and all of its data. Projects based on
          this Dataset will also be deleted.
          <br />
          <br />
          Do you wish to proceed?
        </ModalBody>

        <ModalFooter>
          <Button mx={3} variant="ghost" onClick={onClose} aria-label="cancel deletion">
            Cancel
          </Button>
          <DestructiveButton
            onClick={() => {
              handleDeleteButtonClicked()
              onClose()
            }}
          >
            Delete
          </DestructiveButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteDatasetModal
