import { cn } from '@utils/tailwindUtils'

type NavTitleProps = {
  title: string
  className?: string
}

const NavTitle = ({ title, className }: NavTitleProps) => {
  return (
    <h1
      className={cn(
        'type-body-300-medium min-w-[100px] shrink-0 truncate text-text-strong',
        className
      )}
    >
      {title}
    </h1>
  )
}

export default NavTitle
