import { HStack } from '@chakra-ui/react'
import { TopNavTabButton } from '@common/layout/topNavTabs/TopNavTabs'
import { useNavigate } from 'react-router-dom'

import { useCleansetTabs } from '../tabsReducer/useCleansetTabs'
import { DatasheetNavProps } from './DatasheetNav.types'
import DatasheetNavTabs from './datasheetNavTabs/DatasheetNavTabs'

const DatasheetNav = (props: DatasheetNavProps) => {
  const { projectId, isAnalyticsDisabled, isTemplate, isVersionsDisabled } = props
  const navigate = useNavigate()

  const { state } = useCleansetTabs()

  return (
    <HStack gap="12px" height="100%">
      <DatasheetNavTabs
        tabIndex={state.index}
        isAnalyticsDisabled={isAnalyticsDisabled}
        isTemplate={isTemplate}
      />
      {!isVersionsDisabled && (
        <TopNavTabButton
          className="cleanset-tour-version-button"
          aria-label="View Cleanset version history"
          onClick={() => navigate(`/projects/${projectId}`)}
        >
          Versions
        </TopNavTabButton>
      )}
    </HStack>
  )
}

export default DatasheetNav
