import { useState } from 'react'

const TABULAR_PIN_STORAGE = 'CleanlabTabularPins'

export const useTabularPins = (initial: string[]) => {
  const storedPins = localStorage.getItem(TABULAR_PIN_STORAGE)
  const [pins, setPins] = useState<string[]>(storedPins ? JSON.parse(storedPins) : initial)

  const setPinnedFeatures = (newPins: string[]) => {
    localStorage.setItem(TABULAR_PIN_STORAGE, JSON.stringify(newPins))
    setPins(newPins)
  }

  return { pinnedFeatures: pins, setPinnedFeatures }
}
