import { Flex, HStack, Image, VStack } from '@chakra-ui/react'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { modulo } from '@utils/functions/modulo'
import { useCallback, useMemo, useState } from 'react'

import { GivenLabelExampleImages, NonGivenLabelExampleImages } from './ImageDataDisplay.helpers'
import { ImageDataDisplayProps } from './ImageDataDisplay.types'

const ImageDataDisplay = (props: ImageDataDisplayProps) => {
  const { src, givenLabel, suggestedLabel, labels, isMulticlass, isLabelIssue } = props
  // Sort and rotate array so that suggested label (if any) comes first
  const nonGivenLabels = useMemo(() => {
    const labelsSorted = [...labels.filter((v) => ![givenLabel?.toString()].includes(v))].sort(
      (a, b) => a.localeCompare(b)
    )
    if (suggestedLabel !== null && labelsSorted.includes(suggestedLabel.toString())) {
      const suggestedLabelIdx = labelsSorted.indexOf(suggestedLabel.toString())
      return [...labelsSorted.slice(suggestedLabelIdx), ...labelsSorted.slice(0, suggestedLabelIdx)]
    } else {
      return labelsSorted
    }
  }, [givenLabel, labels, suggestedLabel])
  const numNonGivenLabels = nonGivenLabels.length
  const [shownLabelIndex, setShownLabelIndex] = useState(0)

  const { showNotificationBanner } = useSubscription()

  const stepShownLabelIndex = useCallback(
    (increment: 1 | -1) => {
      setShownLabelIndex(modulo(shownLabelIndex + increment, numNonGivenLabels))
    },
    [shownLabelIndex, numNonGivenLabels]
  )

  return (
    <VStack
      width="100%"
      h={showNotificationBanner ? 'calc(100% - 7rem)' : 'calc(100vh - 282px)'}
      m={0}
      gap={0}
      pt="1rem"
      pb={isMulticlass ? '0px' : '2rem'}
      justify="space-between"
      className="bg-surface-0"
    >
      <Flex
        align="flex-start"
        justify="justify"
        flexDir="column"
        h={isMulticlass && givenLabel !== null && isLabelIssue ? '50%' : '100%'}
      >
        <Image borderRadius="md" src={src} w="100%" h="100%" objectFit="contain" />
      </Flex>
      {isMulticlass && (
        <HStack spacing={6} align="center" justify="center" h="50%" w="90%">
          {givenLabel !== null && <GivenLabelExampleImages givenLabel={givenLabel} />}
          {isLabelIssue && (
            <NonGivenLabelExampleImages
              suggestedLabel={suggestedLabel}
              shownLabelIndex={shownLabelIndex}
              stepShownLabelIndex={stepShownLabelIndex}
              nonGivenLabels={nonGivenLabels}
            />
          )}
        </HStack>
      )}
    </VStack>
  )
}

export default ImageDataDisplay
