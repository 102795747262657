import debounce from '@utils/debounce'
import { useEffect, useMemo, useRef } from 'react'

interface ExtraMethods {
  clear: () => void
  flush: () => void
}

export type DebouncedState<T extends (...args: any) => ReturnType<T>> = ((
  ...args: Parameters<T>
) => ReturnType<T> | undefined) &
  ExtraMethods

export function useDebounce<T extends (...args: any) => ReturnType<T>>(
  func: T,
  delay = 500,
  leading = false
): DebouncedState<T> {
  const debouncedFunc = useRef<ReturnType<typeof debounce>>()

  useEffect(() => {
    return () => {
      if (debouncedFunc.current) {
        debouncedFunc.current.clear()
      }
    }
  }, [])

  const debounced = useMemo(() => {
    const debouncedFuncInstance = debounce(func, delay, leading)

    const wrappedFunc = (...args: any) => {
      return debouncedFuncInstance(...args)
    }

    wrappedFunc.clear = () => {
      debouncedFuncInstance.clear()
    }

    wrappedFunc.flush = () => {
      return debouncedFuncInstance.flush()
    }

    return wrappedFunc
  }, [func, delay, leading])

  // Update the debounced function ref whenever func, wait, or options change
  useEffect(() => {
    debouncedFunc.current = debounce(func, delay, leading)
  }, [func, delay, leading])

  return debounced
}
