import { Container, Kbd, TabPanel, TabPanels, Tabs, Text, useToast, VStack } from '@chakra-ui/react'
import EditableTitle from '@common/editableTitle/EditableTitle'
import Layout from '@common/layout/Layout'
import Loading from '@common/layout/loading/Loading'
import Link from '@components/link/Link'
import useIsTabActive from '@hooks/useIsTabActive'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import NonExistentResource from '@providers/errors/NonExistentResource'
import datasetApiService, {
  DatasetDetailsPageInfoProps,
  DatasetFieldTypesPropsV1,
} from '@services/datasetApi'
import { useUpdateDatasetNameMutation } from '@services/datasets/mutations'
import { useDatasetDetails } from '@services/datasets/queries'
import { isUUID4 } from '@utils/functions/isUUID4'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DatasetActionButtons from './datasetActionButtons/DatasetActionButtons'
import DatasetDetailsNav from './datasetDetailsNav/DatasetDetailsNav'
import DatasetMetadata from './datasetMetadata/DatasetMetadata'
import DatasetRowsLimitFloater from './datasetRowsLimitFloater/DatasetRowsLimitFloater'
import DatasetTableDetails from './datasetTableDetails/DatasetTableDetails'
import ProcessingDataset from './processingDataset/ProcessingDataset'
import { IMPORTING_DATA_PHASE } from './processingDataset/ProcessingDataset.types'
import { SchemaUpdatesProvider } from './SchemaUpdatesContext'

const DatasetDetails = () => {
  const { datasetId } = useParams<{ datasetId: string }>()
  const [invalidId, setInvalidId] = useState(false)
  const [datasetDetails, setDatasetDetails] = useState<DatasetDetailsPageInfoProps>()
  const [fieldTypes, setFieldTypes] = useState<DatasetFieldTypesPropsV1>()
  const [tabIndex, setTabIndex] = useState(0)
  const [uploadId, setUploadId] = useState('')
  const [showDataWarnings, setShowDataWarnings] = useState(true)
  const [schemaUpdateInProgress, setSchemaUpdateInProgress] = useState(false)

  const [isDeletingSelf, setIsDeletingSelf] = useState(false)
  const { name: datasetName } = useDatasetDetails({
    datasetId: datasetId,
    useQueryOptions: { enabled: !isDeletingSelf },
  })

  const toast = useToast()
  const isTabActive = useIsTabActive()
  const { freeTrialPeriod, freeTrialDatasetRowsLimit, datasetRowsLimit } = useSubscription()

  const { mutate: updateDatasetName, isLoading: isUpdatingDatasetName } =
    useUpdateDatasetNameMutation()

  const fetchDatasetDetails = useCallback(
    async (datasetId: string) => {
      try {
        if (isTabActive) {
          const data = await datasetApiService.getDatasetDetailsPageInfo(datasetId)
          const columnsData = await datasetApiService.getDatasetFieldTypesV1(datasetId)

          setDatasetDetails(data)
          setUploadId(data.upload_id)
          setFieldTypes(columnsData)
        }
      } catch (err) {
        const statusCode = (err as AxiosError).response?.status
        if (statusCode && statusCode >= 400 && statusCode < 500) {
          setInvalidId(true)
        } else {
          notifyAxiosError(toast, err as AxiosError, { title: 'Dataset fetch failed' })
        }
      }
    },
    [isTabActive, toast]
  )

  useEffect(() => {
    if (isUUID4(datasetId)) {
      void fetchDatasetDetails(datasetId as string)
    }
  }, [datasetId, fetchDatasetDetails])

  if (datasetId === undefined || !isUUID4(datasetId) || invalidId) {
    return (
      <Layout>
        <NonExistentResource errorMessage="No such dataset exists." />
      </Layout>
    )
  }
  if (!datasetDetails || !fieldTypes) {
    return (
      <Layout>
        <Loading text="Loading Dataset..." />
      </Layout>
    )
  }
  return (
    <Tabs
      variant="enclosed"
      index={tabIndex}
      onChange={(index) => {
        setTabIndex(index)
      }}
    >
      <Layout nav={<DatasetDetailsNav tabIndex={tabIndex} />}>
        <Container maxW="100%" maxH="86vh">
          <SchemaUpdatesProvider>
            <VStack>
              {tabIndex === 0 && datasetDetails.exceeds_rows_limit && (
                <DatasetRowsLimitFloater
                  freeTrialPeriod={freeTrialPeriod}
                  datasetRowsLimit={freeTrialPeriod ? freeTrialDatasetRowsLimit : datasetRowsLimit}
                />
              )}
              <div className="flex w-full flex-col items-center justify-between gap-5 pb-5 sm:max-lg:items-start lg:flex-row">
                <div className="flex flex-col">
                  <EditableTitle
                    title={datasetName}
                    isLoading={isUpdatingDatasetName}
                    onTitleChange={(name: string) =>
                      updateDatasetName({ id: datasetId, name: name })
                    }
                  />

                  <Text fontSize="14px" fontWeight={400} lineHeight="12px">
                    Learn more about this page in our{' '}
                    <Link href="https://help.cleanlab.ai/guide/concepts/datasets/" isExternal>
                      documentation
                    </Link>
                    . An asterisk <Kbd fontSize="14px">*</Kbd> denotes a modified column type that
                    has not yet been saved.
                  </Text>
                </div>
                <DatasetActionButtons
                  datasetId={datasetId}
                  isDatasetComplete={datasetDetails.complete}
                  datasetName={datasetName}
                  showDataWarnings={showDataWarnings}
                  setShowDataWarnings={setShowDataWarnings}
                  setIsDeletingSelf={setIsDeletingSelf}
                />
              </div>
              <TabPanels>
                <TabPanel p={0}>
                  {datasetDetails.complete ? (
                    <DatasetTableDetails
                      datasetId={datasetId}
                      datasetDetails={datasetDetails}
                      fieldTypes={fieldTypes}
                      refreshData={fetchDatasetDetails}
                      showDataWarnings={showDataWarnings}
                      showDatasetRowsLimit={tabIndex === 0 && datasetDetails.exceeds_rows_limit}
                      setSchemaUpdateInProgress={setSchemaUpdateInProgress}
                    />
                  ) : (
                    <ProcessingDataset
                      uploadId={uploadId}
                      initialUploadPhase={
                        schemaUpdateInProgress ? IMPORTING_DATA_PHASE + 1 : IMPORTING_DATA_PHASE
                      }
                      handleFinishProcessing={async () => {
                        setSchemaUpdateInProgress(false)
                        fetchDatasetDetails(datasetId)
                      }}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  <DatasetMetadata
                    datasetId={datasetId}
                    datasetDetails={datasetDetails}
                    refreshData={fetchDatasetDetails}
                  />
                </TabPanel>
              </TabPanels>
            </VStack>
          </SchemaUpdatesProvider>
        </Container>
      </Layout>
    </Tabs>
  )
}

export default DatasetDetails
