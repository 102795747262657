import { Kbd, useColorModeValue } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export const KeyPress: React.FC<PropsWithChildren> = ({ children }) => {
  const kbdStyle = {
    borderRadius: '4px',
    fontSize: '14px',
    marginRight: '3px',
    padding: '2px 4px',
  }
  return (
    <Kbd
      style={kbdStyle}
      bg={useColorModeValue('gray.100', 'gray.700')}
      borderColor={useColorModeValue('gray.200', 'gray.600')}
      color={useColorModeValue('gray.800', 'gray.400')}
    >
      {children}
    </Kbd>
  )
}

export const KeyPressLabelShortcut: React.FC<PropsWithChildren> = ({ children }) => {
  const kbdStyle = {
    marginRight: '15px',
    borderRadius: '4px',
    fontSize: '15px',
    padding: '3px 9px',
    align: 'center',
    justify: 'center',
  }
  return (
    <Kbd
      style={kbdStyle}
      bg={useColorModeValue('gray.200', 'gray.600')}
      borderColor={useColorModeValue('gray.300', 'gray.500')}
      color={useColorModeValue('gray.900', 'gray.300')}
    >
      {children}
    </Kbd>
  )
}

export const KeyPressShortcut: React.FC<PropsWithChildren> = ({ children }) => {
  const kbdStyle = {
    borderRadius: '4px',
    fontSize: '14px',
    padding: '2px 8px',
    align: 'center',
    justify: 'center',
  }
  return (
    <Kbd
      style={kbdStyle}
      bg={useColorModeValue('gray.200', 'gray.600')}
      borderColor={useColorModeValue('gray.300', 'gray.500')}
      color={useColorModeValue('gray.700', 'gray.300')}
    >
      {children}
    </Kbd>
  )
}
