import { Meter } from '@components/meter/Meter'
import { cn } from '@utils/tailwindUtils'
import { ComponentProps, ForwardedRef, forwardRef } from 'react'

export const ProgressCell = forwardRef(
  ({ className, ...props }: ComponentProps<'div'>, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={cn('flex h-full w-max items-center gap-3 tabular-nums', className)}
        {...props}
      ></div>
    )
  }
)

const IssuesFoundCell = ({ numIssues, numTotal }: { numIssues: number; numTotal: number }) => {
  return (
    <ProgressCell>
      <Meter variant="neutral" aria-label="" value={numIssues} max={numTotal} className="w-11" />
      {numIssues}/{numTotal}
    </ProgressCell>
  )
}

export default IssuesFoundCell
