import { EmptyState, EmptyStateButton } from '@components/emptyState/EmptyState'

import { DASHBOARD_GRID_VARIANTS, DashboardGridVariantsType } from '../Dashboard.types'

interface NoResultsOverlayProps {
  onClickClearSearch?: () => void
  gridType: DashboardGridVariantsType
}

const NoResultsOverlay = ({ onClickClearSearch, gridType }: NoResultsOverlayProps) => {
  const textDict = {
    [DASHBOARD_GRID_VARIANTS.project]: {
      heading: 'No Projects',
      description: 'Upload your own data or choose one from our demo Datasets to get started.',
    },
    [DASHBOARD_GRID_VARIANTS.dataset]: {
      heading: 'No Datasets',
      description: 'Upload your own Dataset to get started.',
    },
    [DASHBOARD_GRID_VARIANTS.deployment]: {
      heading: 'No deployed models',
      description:
        "You can deploy a model from a Project and use that model to obtain predictions on new data using Cleanlab Studio's Python API.",
    },
  }
  // Using the existence of the clearSearch function to determine if the user is searching
  // Can be subject to change later, just didn't want to introduce another prop for now
  const isVisibleFromUserSearch = !!onClickClearSearch

  return (
    <EmptyState
      className="mt-10"
      heading={isVisibleFromUserSearch ? 'No results found' : textDict[gridType].heading}
      description={
        isVisibleFromUserSearch
          ? 'Try adjusting your search results to find what you are looking for'
          : textDict[gridType].description
      }
      actions={
        isVisibleFromUserSearch ? (
          <EmptyStateButton onClick={onClickClearSearch}>Clear search</EmptyStateButton>
        ) : null
      }
    />
  )
}

export default NoResultsOverlay

NoResultsOverlay.displayName = 'NoResultsOverlay'
