import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { Box, Button, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import { useAuth } from '@hooks/useAuth'
import { FiAlertTriangle, FiArrowRight } from 'react-icons/fi'
import { InlineLink } from 'src/pages/projectForm/Sidebar'

import { ActivationMessageProps } from './ActivationMessage.types'

const ActivationMessage = (props: ActivationMessageProps) => {
  const { isSuccess, email } = props
  const warningTextColor = useColorModeValue('orange.700', 'orangeDarkMode.700')
  const successTextColor = useColorModeValue('green.500', 'greenDarkMode.500')
  const warningBorderColor = useColorModeValue('orange.500', 'orangeDarkMode.500')
  const successBorderColor = useColorModeValue('green.500', 'greenDarkMode.500')
  const successIconColor = useColorModeValue(
    cleanlabColors.green[600],
    cleanlabColors.greenDarkMode[600]
  )
  const warningIconColor = useColorModeValue(
    cleanlabColors.orange[600],
    cleanlabColors.orangeDarkMode[600]
  )

  const { logout } = useAuth()

  return (
    <Box
      borderRadius="12px"
      w="100%"
      border="1px solid"
      borderColor={isSuccess ? successBorderColor : warningBorderColor}
      padding="16px"
      mt="24px"
      fontSize="md"
      lineHeight="24px"
    >
      <HStack>
        {isSuccess ? (
          <CheckCircleIcon color={successIconColor} width="16px" height="16px" />
        ) : (
          <FiAlertTriangle color={warningIconColor} size={16} />
        )}
        <Text
          className="type-body-200-medium"
          color={isSuccess ? successTextColor : warningTextColor}
        >
          {isSuccess ? 'Check your email to start your free trial' : 'Sorry, we went viral!'}
        </Text>
      </HStack>
      <Box paddingLeft="24px">
        <Text className="type-body-200 text-text-faint">
          {isSuccess ? (
            <>
              <p>Click on the link in the email we sent to {email} to verify your email.</p>
              <p>
                <InlineLink to="https://cleanlab.ai/sales/">Book a session</InlineLink> with a
                Cleanlab AI expert to make the most out of your free trial.
              </p>
            </>
          ) : (
            'Thank you for your interest in trying Cleanlab Studio. Unfortunately, due to greater interest than anticipated, we had to limit the number of free trials. You’ve been added to the waitlist, and we’ll reach out as soon as we’re able to activate your account.'
          )}
        </Text>
        {!isSuccess && (
          <HStack spacing="16px" alignItems="center" mt="12px">
            <Button
              p="6px 12px"
              height="32px"
              fontSize="14px"
              variant="tlm-action-button"
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >
              Log out
            </Button>
            <Button
              h="min-content"
              bg="none"
              _hover={{ bg: 'none', textDecoration: 'underline' }}
              p={0}
              fontSize="14px"
              cursor="pointer"
              rightIcon={<FiArrowRight size={16} />}
              onClick={() => window.open('https://cleanlab.ai/sales/', '_blank')}
            >
              Contact sales
            </Button>
          </HStack>
        )}
      </Box>
    </Box>
  )
}

export default ActivationMessage
