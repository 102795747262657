import { HStack, Text, Tooltip, useColorModeValue } from '@chakra-ui/react'
import { Badge } from '@components/badge/Badge'

import { getIssueTagTooltipBody } from './CleanlabTag.helpers'
import { CleanlabTagProps, TagAbbreviation } from './CleanlabTag.types'

const CleanlabTag = (props: CleanlabTagProps) => {
  const { cleanlabTags } = props
  const tooltipBg = useColorModeValue('gray.100', 'gray.600')
  const tooltipColor = useColorModeValue('gray.800', 'gray.100')

  const tags =
    cleanlabTags && cleanlabTags.length > 0 ? (
      cleanlabTags.map((tag) => (
        <Tooltip hasArrow key={tag} label={getIssueTagTooltipBody(tag, tooltipBg, tooltipColor)}>
          <Badge>
            <Text>{TagAbbreviation[tag] ?? tag.replace('_', ' ')}</Text>
          </Badge>
        </Tooltip>
      ))
    ) : (
      <Text>-</Text>
    )

  return <HStack>{tags}</HStack>
}

export default CleanlabTag
