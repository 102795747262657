'use client'

import { Button, ButtonProps } from '@components/button/Button'
import { ButtonClose } from '@components/buttonClose/ButtonClose'
import { Kbd } from '@components/kbd/Kbd'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cn } from '@utils/tailwindUtils'
import {
  ComponentProps,
  createContext,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  useContext,
} from 'react'

import { modalVariants } from './modalVariants'
export const MODAL_VARIANTS = ['default', 'extended'] as const
type ModalVariant = (typeof MODAL_VARIANTS)[number]

export const MODAL_SIZES = ['small', 'medium', 'large'] as const
type ModalSize = (typeof MODAL_SIZES)[number]

export type ModalContextValue = {
  variant: ModalVariant
  size: ModalSize
  showCloseButton?: boolean
}

const ModalContext = createContext<ModalContextValue>({
  variant: 'default',
  size: 'medium',
  showCloseButton: false,
})
export const useModalContext = () => {
  return useContext(ModalContext)
}
export const ModalContextProvider = ModalContext.Provider

/**
 * Close button for the dialog. Used in the <ModalDialogHeader /> component.
 * Automatically closes the dialog when clicked, unless `preventDefault` is
 * called in the `onClick` handler. Should be provided as a child of the
 * <ModalDialogFooter /> component.
 */
const ModalDialogClose = forwardRef(
  ({ children, ...props }: ComponentProps<'div'>, ref: ForwardedRef<any>) => {
    return (
      <div className={modalVariants.close()} {...props}>
        <Kbd variant="subtle">esc</Kbd>
        <DialogPrimitive.Close ref={ref} asChild>
          <ButtonClose size="xSmall" aria-label="Close dialog" />
        </DialogPrimitive.Close>
      </div>
    )
  }
)
ModalDialogClose.displayName = 'ModalDialogClose'

/**
 * The header of the dialog. Will render a border at the bottom if the variant is
 * `extended`. Used as the container for the `<ModalDialogTitle />` and
 * `<ModalDialogDescription />` components. Should not contain
 * `<ModalDialogDescription />` for the `expanded variant`.
 */
export const ModalHeader = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const { variant, showCloseButton } = useModalContext()
  const { base, inner } = modalVariants.header({ variant: variant })
  return (
    <div className={base()} {...props}>
      <div className={cn(inner(), className)}>{children}</div>
      {showCloseButton && <ModalDialogClose />}
    </div>
  )
}
ModalHeader.displayName = 'ModalHeader'

export const ModalActionButton = forwardRef((props: ButtonProps, ref: ForwardedRef<any>) => {
  const { children, asChild, ...rest } = props
  return (
    <Button ref={ref} variant="highContrast" size="small" {...rest}>
      {children}
    </Button>
  )
})
ModalActionButton.displayName = 'ModalActionButton'

export const ModalCancelButton = forwardRef((props: ButtonProps, ref: ForwardedRef<any>) => {
  const { children, asChild, ...rest } = props
  return (
    <Button ref={ref} variant="secondary" size="small" {...rest}>
      {children || 'Cancel'}
    </Button>
  )
})
ModalCancelButton.displayName = 'ModalCancelButton'
