import {
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'
import { useNavigate } from 'react-router-dom'

import { DataLabelingIntroModalProps } from './DataLabelingIntroModal.types'

const DataLabelingIntroModal = (props: DataLabelingIntroModalProps) => {
  const { isOpen, onClose } = props

  const navigate = useNavigate()

  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent w="425px" bg={modalBg} pb="24px">
        <ModalHeader
          color={useColorModeValue('neutral.900', 'neutralDarkMode.900')}
          pb={0}
          fontWeight="600"
        >
          Data Labeling
        </ModalHeader>
        <ModalBody pb="32px">
          <Text fontSize="sm" color={useColorModeValue('neutral.700', 'neutralDarkMode.700')}>
            Based on our analysis, this Dataset does not meet our recommended threshold for labeled
            data points. To leverage Cleanlab Studio’s analyses to their greatest potential, we’ll
            first walk you through labeling more of your Dataset. Once you’re done we’ll rerun our
            analyses to give you the best results possible.
          </Text>
        </ModalBody>
        <ModalFooter py={0} height="36px" pt="24px">
          <HStack justify="flex-end">
            <SecondaryButton height="36px" onClick={() => navigate('/')}>
              <Text fontWeight="500" fontSize="sm">
                {' '}
                Back to dashboard
              </Text>
            </SecondaryButton>
            <PrimaryButton height="36px" onClick={onClose}>
              <Text fontSize="sm" fontWeight="500">
                Start
              </Text>
            </PrimaryButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DataLabelingIntroModal
