import { InputApiKey } from '@common/apiKey/InputApiKey'
import Loading from '@common/layout/loading/Loading'
import { useAuth } from '@hooks/useAuth'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useRefreshApiKey } from '@services/commandLine/mutations'
import { useApiKey } from '@services/commandLine/queries'

const UserProfile = () => {
  const { user } = useAuth()
  const { trackEvent } = useEventTracking()
  const { mutate: refreshApiKey } = useRefreshApiKey()
  const { apiKey } = useApiKey()

  if (!user || !apiKey) {
    return <Loading text="Loading user profile" position="relative" />
  }

  return (
    <table className="grid h-full w-full grid-cols-[max-content_1fr] flex-row items-center gap-5 rounded-2 border border-border-1 bg-surface-0 p-5">
      <tr className="contents">
        <th className="type-body-300-medium text-text-strong">Email</th>
        <td className="type-body-300 text-text-primary">{user.email}</td>
      </tr>
      <tr className="contents">
        <th className="type-body-300-medium text-text-strong"> API key</th>
        <td>
          <InputApiKey
            value={apiKey}
            onRefreshApiKey={refreshApiKey}
            onClickCopy={() => trackEvent(MixpanelEvents.clickApiKeyCopy)}
          />
        </td>
      </tr>
    </table>
  )
}

export default UserProfile
