import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react'
import Link from '@components/link/Link'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import React from 'react'

import { SubscriptionAlertProps } from './SubscriptionAlert.types'

const SubscriptionAlert = (props: SubscriptionAlertProps) => {
  const { message, title } = props
  const { onSubscriptionModalOpen } = useSubscription()

  return (
    <Alert fontSize="md" mt={3} status="error" w="fit-content">
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>
        Please{' '}
        <Link textDecoration="underline" onClick={onSubscriptionModalOpen}>
          upgrade your plan
        </Link>{' '}
        {message}
      </AlertDescription>
    </Alert>
  )
}

export default SubscriptionAlert
