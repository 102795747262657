'use client'

import { MeterRoot } from '@components/meter/Meter'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cn } from '@utils/tailwindUtils'
import { type ComponentPropsWithRef, forwardRef, memo, useMemo } from 'react'

import styles from './ProgressIndeterminate.module.css'

type IndicatorProps = Readonly<{
  complete: boolean
  paused: boolean
}>
type ProgressIndeterminateProps = Partial<IndicatorProps> &
  Readonly<{
    className?: string
  }>

const IndeterminateIndicator = ({ complete, paused }: IndicatorProps) => {
  const baseClasses = 'absolute h-full w-full'

  return (
    <ProgressPrimitive.Indicator>
      <AnimatedIndicator paused={paused} complete={complete} offset={0} />
      <AnimatedIndicator paused={paused} complete={complete} offset={0.5} />

      <div
        className={cn(
          baseClasses,
          'transition duration-150 ease-out',
          complete ? 'opacity-1 translate-x-0' : '-translate-x-100 opacity-0',
          'bg-green-500'
        )}
      />
    </ProgressPrimitive.Indicator>
  )
}

const ANIMATION_LENGTH = 5

const AnimatedIndicator = ({ complete, paused, offset }: IndicatorProps & { offset: number }) => {
  const commonAnimStyles = useMemo(
    () => ({
      animationDuration: `${ANIMATION_LENGTH}s`,
      animationTimingFunction: 'cubic-bezier(.20,.55,.80,.45)',
      animationIterationCount: 'infinite',
      animationPlayState: paused ? 'paused' : 'running',
      animationDelay: `${-offset * ANIMATION_LENGTH}s`,
    }),
    [offset, paused]
  )
  const baseClasses = 'absolute h-full w-full'

  return (
    <div
      className={cn(
        baseClasses,
        'transition-opacity duration-500 ease-in-out',
        complete ? 'opacity-0' : 'opacity-100'
      )}
    >
      <div
        className={baseClasses}
        style={{ ...commonAnimStyles, animationName: styles.keyframesOuter }}
      >
        <div
          style={{ ...commonAnimStyles, animationName: styles.keyframesInner }}
          className={cn(baseClasses, 'bg-cyan-500')}
        />
      </div>
    </div>
  )
}

const ROLE = 'progress'

const ProgressIndeterminateBase = (
  { className, paused = false, complete = false, ...props }: ProgressIndeterminateProps,
  ref: ComponentPropsWithRef<typeof ProgressPrimitive.Root>['ref']
) => (
  <MeterRoot
    ref={ref}
    className={cn('relative h-3 w-full overflow-hidden rounded-full bg-surface-2-hover', className)}
    role={ROLE}
    {...props}
  >
    <IndeterminateIndicator paused={paused} complete={complete} />
  </MeterRoot>
)

export const ProgressIndeterminate = memo(forwardRef(ProgressIndeterminateBase))
ProgressIndeterminate.displayName = ProgressPrimitive.Root.displayName
