// Solves issue with using Slots with nested children
// https://github.com/radix-ui/primitives/issues/1825#issuecomment-1865423970
import { Slot } from '@radix-ui/react-slot'
import { mergeProps, mergeRefs } from '@react-aria/utils'
import { cloneElement, ForwardedRef, forwardRef, isValidElement, ReactNode } from 'react'

export type SlotChildProps = {
  asChild?: boolean
  child: ReactNode
  children: ReactNode | ((child: ReactNode) => ReactNode)
}

function getContent({ children }: SlotChildProps, arg: ReactNode) {
  return typeof children === 'function' ? children(arg) : <>{children}</>
}

export const SlotChild = forwardRef((props: SlotChildProps, forwardedRef: ForwardedRef<any>) => {
  const { asChild, child, children, ...attrs } = props

  if (!isValidElement(child)) {
    return asChild ? null : <>{getContent(props, child)}</>
  }

  const slot = child.type === Slot
  const childSlot = !!child.props.asChild

  return (
    <>
      {cloneElement(
        child,
        {
          ...mergeProps(child.props, attrs),
          ref: mergeRefs((child as any).ref, forwardedRef),
        },
        slot || childSlot ? (
          <SlotChild asChild={asChild} child={child.props.children} children={children} />
        ) : (
          getContent(props, child.props.children)
        )
      )}
    </>
  )
})
