import { Button as ChakraButton } from '@chakra-ui/react'
import React from 'react'

import { SecondaryButtonWithIconProps } from './SecondaryButtonWithIcon.types'

const SecondaryButtonWithIcon = (props: SecondaryButtonWithIconProps) => {
  const { children, fontSize, height, isDisabled, isLoading, leftIcon, rightIcon, px, onClick } =
    props
  return (
    <ChakraButton
      isDisabled={isDisabled}
      isLoading={isLoading}
      fontSize={fontSize ?? 'sm'}
      px={px ?? '12px'}
      height={height}
      onClick={onClick}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      variant="secondary"
    >
      {children}
    </ChakraButton>
  )
}

export default SecondaryButtonWithIcon
