import { useEventTracking } from '@hooks/useEventTracking'
import { Slot } from '@radix-ui/react-slot'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { AsChildProps } from '@utils/AsChildProps'
import { isExternalUrl as checkIsExternalUrl } from '@utils/isExternalUrl'
import { cn } from '@utils/tailwindUtils'
import { ComponentProps, ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

export const CreateProjectSidebar = ({ children }: ComponentPropsWithoutRef<'aside'>) => {
  return (
    <aside className="flex w-[296px] shrink-0 flex-col gap-6 rounded-2 bg-surface-1 p-6 outline outline-1 outline-border-0">
      {children}
    </aside>
  )
}

export const CreateProjectSidebarHeading = forwardRef<
  HTMLHeadingElement,
  ComponentPropsWithoutRef<'h4'>
>(({ className, children, ...props }, ref) => {
  return (
    <h4
      ref={ref}
      className={cn('type-body-200-semibold mb-2 text-text-primary', className)}
      {...props}
    >
      {children}
    </h4>
  )
})

export const CreateProjectSidebarP = ({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<'h4'>) => {
  return (
    <p className={cn('with-text-links type-body-100 text-text-faint', className)} {...props}>
      {children}
    </p>
  )
}

export const SidebarResourcesSection = forwardRef<HTMLUListElement, ComponentPropsWithoutRef<'ul'>>(
  ({ className, children, ...props }, ref) => {
    return (
      <section ref={ref} className={cn('', className)} {...props}>
        <CreateProjectSidebarHeading className="mb-4">Resources</CreateProjectSidebarHeading>
        <ResourceLinkList>{children}</ResourceLinkList>
      </section>
    )
  }
)

export const ResourceLinkList = forwardRef<HTMLUListElement, ComponentPropsWithoutRef<'ul'>>(
  ({ className, ...props }, ref) => {
    return <ul ref={ref} className={cn('flex flex-col gap-4', className)} {...props}></ul>
  }
)

const ResourceLink = forwardRef(
  (
    { className, children, ...props }: ComponentPropsWithoutRef<typeof InlineLinkBare>,
    ref: ComponentProps<typeof InlineLinkBare>['ref']
  ) => {
    return (
      <InlineLinkBare
        ref={ref}
        className="type-body-100-link block text-text-primary underline"
        {...props}
      >
        {children}
      </InlineLinkBare>
    )
  }
)

export const ResourceLinkLi = forwardRef<
  HTMLAnchorElement,
  ComponentPropsWithoutRef<typeof ResourceLink>
>((props, ref) => {
  return (
    <li>
      <ResourceLink ref={ref} {...props} />
    </li>
  )
})

export const InlineLinkBare = forwardRef(
  (props: Omit<ComponentProps<typeof Link>, 'to'> & { to: string }, ref: ForwardedRef<any>) => {
    const isExternal = checkIsExternalUrl(props.to)
    if (isExternal) {
      return (
        <a
          ref={ref}
          id={props.id}
          className={props.className}
          href={props.to || ''}
          target="_blank"
          rel="noreferrer"
        >
          {props.children}
        </a>
      )
    }
    return <Link {...props} />
  }
)

export const InlineLink = forwardRef(
  (
    { className, children, asChild, ...props }: AsChildProps<ComponentProps<typeof InlineLinkBare>>,
    ref: ForwardedRef<any>
  ) => {
    const Comp = asChild ? Slot : (InlineLinkBare as any)

    return (
      <Comp
        className={cn(
          'relative -mx-[1px] -mb-[1px] cursor-pointer rounded-1 px-[1px] pb-[1px] text-blue-700 underline hover:text-blue-800 focus-visible:ring focus-visible:ring-focus',
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    )
  }
)

export const SidebarSectionNeedHelp = () => {
  const { trackEvent } = useEventTracking()
  const { show: showIntercomMessenger } = useIntercom()
  return (
    <section>
      <CreateProjectSidebarHeading>Need help?</CreateProjectSidebarHeading>
      <CreateProjectSidebarP>
        Start a chat with us{' '}
        <InlineLink
          to=""
          onClick={(e) => {
            e.preventDefault()
            trackEvent(MixpanelEvents.clickSupport)
            showIntercomMessenger()
          }}
        >
          now
        </InlineLink>
        .
      </CreateProjectSidebarP>
    </section>
  )
}

export const SidebarSectionWelcome = (
  props: Omit<ComponentPropsWithoutRef<'section'>, 'children'>
) => {
  return (
    <>
      <section {...props}>
        <CreateProjectSidebarHeading>Welcome to Cleanlab Studio</CreateProjectSidebarHeading>
        <CreateProjectSidebarP>
          <InlineLink to="https://cleanlab.ai/sales/">Book a demo</InlineLink> with us for a
          personalized tour.
        </CreateProjectSidebarP>
      </section>
      <SidebarSectionNeedHelp />
    </>
  )
}

export const SidebarSectionWelcomeML = (
  props: Omit<ComponentPropsWithoutRef<'section'>, 'children'>
) => {
  return (
    <>
      <section {...props}>
        <CreateProjectSidebarHeading>How come I don’t select a model?</CreateProjectSidebarHeading>
        <CreateProjectSidebarP>
          Unlike other AI products, with Cleanlab Studio there’s no need to select or optimize an ML
          model. With just a click, we deploy the optimal hardware and sequence of ML models that
          detect various issues, offering you the easiest data curation experience possible.
        </CreateProjectSidebarP>
      </section>
      <SidebarSectionNeedHelp />
    </>
  )
}

export const SidebarSectionSecurity = (
  props: Omit<ComponentPropsWithoutRef<'section'>, 'children'>
) => {
  return (
    <section {...props}>
      <CreateProjectSidebarHeading>Data security at Cleanlab</CreateProjectSidebarHeading>
      <CreateProjectSidebarP>
        At Cleanlab, trust and reliability are at the core of everything we do. Read more about our{' '}
        <InlineLink to="https://cleanlab.ai/trust">security</InlineLink> and{' '}
        <InlineLink to="https://cleanlab.ai/privacy/">privacy</InlineLink> or contact our{' '}
        <InlineLink to="https://cleanlab.ai/security">security team</InlineLink>.
      </CreateProjectSidebarP>
    </section>
  )
}
