import { Modality } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import { RowData } from '../../editInterface/EditInterface.types'

export interface MetadataDisplayProps {
  metadataColumns: string[]
  selectedRowData: RowData
  modality: Modality
  showTallView: boolean
}

export interface ColumnInfo {
  title: { title: string; value: any }
  score: number
  additionalInfo: { field: string; value: any }[]
}

export interface ScoreInfo {
  title: string
  value: number
}

export enum METADATA_TYPE_DISPLAY {
  PII_ITEMS = 'PII Items',
  PII_TYPES = 'PII Types',
  NEAR_DUPLICATE_ID = 'Near Duplicate ID',
  PREDICTED_LANGUAGE = 'Predicted Language',
  SPELLING_ISSUE_SCORE = 'Spelling Issue Score',
  GRAMMAR_ISSUE_SCORE = 'Grammar Issue Score',
  SLANG_ISSUE_SCORE = 'Slang Issue Score',
  GENDER_BIAS_SCORE = 'Gender Bias Score',
  RACIAL_BIAS_SCORE = 'Racial Bias Score',
  SEXUAL_ORIENTATION_BIAS_SCORE = 'Sexual Orientation Bias Score',
  AESTHETIC_SCORE = 'Aesthetic Score',
}
