import { Container, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import PageTitle from '@components/pageTitle/PageTitle'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { useEffect, useState } from 'react'

import { ACCOUNT_TABS } from './Account.helpers'
import Payment from './payment/Payment'
import UserProfile from './userProfile/UserProfile'

const Account = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const currentTab = searchParams.get('tab')
  // If finding tab is -1, default to 0
  const currentTabIndex = Math.max(
    ACCOUNT_TABS.findIndex((tab) => tab === searchParams.get('tab')),
    0
  )
  const [localTabIndex, setLocalTabIndex] = useState<number>(currentTabIndex)

  const setTabIndex = (index: number) => {
    const curUrl = new URL(window.location.href)
    curUrl.searchParams.delete('tab')
    curUrl.searchParams.append('tab', ACCOUNT_TABS[index])
    window.history.replaceState({}, '', curUrl)
    setLocalTabIndex(index)
  }

  useEffect(() => {
    if (!currentTab) {
      setTabIndex(0)
    }
  }, [currentTab])

  return (
    <Layout>
      <Container width="100%">
        <PageTitle>Account</PageTitle>
        <Tabs pt={4} index={localTabIndex} onChange={setTabIndex}>
          <TabList>
            <Tab>General</Tab>
            {!IS_VPC_ENVIRONMENT && <Tab>Usage & Billing</Tab>}
          </TabList>
          <TabPanels>
            <TabPanel>
              <UserProfile />
            </TabPanel>
            {!IS_VPC_ENVIRONMENT && (
              <TabPanel>
                <Payment />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Container>
    </Layout>
  )
}

export default Account
