import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'

export const ExampleOutputTable = () => {
  return (
    <Box border="2px solid" borderColor="gray.300">
      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            <Th>id</Th>
            <Th>user_id</Th>
            <Th>event</Th>
            <Th>event_info</Th>
            <Th>timestamp</Th>
            <Th>name</Th>
            <Th>email</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>1</Td>
            <Td>349335</Td>
            <Td>view_datasheet</Td>
            <Td>
              {JSON.stringify({
                cleansetId: '459u69234',
              })}
            </Td>
            <Td>1662086</Td>
            <Td>Mr. Clean</Td>
            <Td>mrclean@cleanlab.ai</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  )
}
