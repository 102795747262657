import { Button } from '@components/button/Button'
import { IconArrowRight } from '@components/icons'
import { ICON_SIZES } from '@components/icons/iconConsts'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { cn } from '@utils/tailwindUtils'
import { cloneElement, ForwardedRef, forwardRef, memo, ReactElement, ReactNode } from 'react'

type EmptyStateProps = Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> & {
  heading: string
  description?: string | null
  /**
   * Action(s) to be displayed below the description
   * Will work best a a single button/link or a pair of them in a React Fragment
   */
  actions?: React.ReactNode
  size?: EmptyStateSize
  icon?: ReactElement | null
}

type EmptyStateSize = 'medium' | 'small'

const iconSizeMap: {
  [key in EmptyStateSize]: (typeof ICON_SIZES)[number]
} = {
  medium: 16,
  small: 12,
}

const EmptyStateBase = (
  { actions, className, heading, description, size = 'medium', icon }: EmptyStateProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  let iconClone: ReturnType<typeof cloneElement> | undefined

  if (icon) {
    iconClone = cloneElement(icon, {
      size: iconSizeMap[size],
    })
  }

  return (
    <div
      ref={ref}
      className={cn(
        'pointer-events-auto flex h-full w-full max-w-4xl flex-col items-center justify-center gap-5 text-center',
        className
      )}
    >
      <div className="flex flex-col items-center gap-4">
        {iconClone ?? null}

        <div className="flex flex-col gap-1">
          <h1
            className={cn('self-stretch font-medium text-text-strong', {
              'type-body-300': size === 'medium',
              'type-body-200': size === 'small',
            })}
          >
            {heading}
          </h1>
          {description && (
            <p
              className={cn('text-text-faint', {
                'type-body-200': size === 'medium',
                'type-body-100': size === 'small',
              })}
            >
              {description}
            </p>
          )}
        </div>
      </div>

      {actions && <div className="flex items-center gap-6">{actions}</div>}
    </div>
  )
}

const EmptyStateButtonBase = (
  {
    children,
    onClick,
  }: {
    children: ReactNode
    onClick: () => void
  },
  ref: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <Button ref={ref} variant="secondary" onClick={onClick} size="small">
      {children}
    </Button>
  )
}

const EmptyStateLinkBlockBase = (
  { children, href }: { children: ReactNode; href: string },
  ref: ForwardedRef<HTMLAnchorElement>
) => {
  return (
    <LinkBlock ref={ref} href={href} iconEnd={<IconArrowRight />} variant="secondary" size="small">
      {children}
    </LinkBlock>
  )
}

export const EmptyState = memo(forwardRef(EmptyStateBase))
export const EmptyStateButton = memo(forwardRef(EmptyStateButtonBase))
export const EmptyStateLinkBlock = memo(forwardRef(EmptyStateLinkBlockBase))

EmptyState.displayName = 'EmptyState'
EmptyStateButton.displayName = 'EmptyStateButton'
EmptyStateLinkBlock.displayName = 'EmptyStateLinkBlock'
