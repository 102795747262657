import { IconInfo } from '@components/icons'
import PageAlert from '@components/pageAlert/PageAlert'
import useIsTabActive from '@hooks/useIsTabActive'
import { queryKeys as datasetsQueryKeys } from '@services/datasets/constants'
import { useDashboardDatasets } from '@services/datasets/queries'
import { deploymentsQueryKeys } from '@services/deployments/constants'
import { useDeployments } from '@services/deployments/queries'
import { useEnrichmentProjects } from '@services/enrichment/queries'
import { useOrgCostUsageRatio } from '@services/organizationQuotas/queries'
import { useProjects } from '@services/project/queries'
import { useTemplateProjectsPreview } from '@services/templateProjects/queries'
import { AccessLevel } from '@services/user/constants'
import { useMyself } from '@services/user/queries'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import { getVpcAlertMessage } from '../vpcAdminPanel/VpcAdminPanel.helpers'
import DatasetsGrid from './datasetsGrid/DatasetsGrid'
import DeploymentsGrid from './deploymentsGrid/DeploymentsGrid'
import { HelpfulResourcesSection } from './HelpfulResourcesSection'
import { DashboardProjectDemos } from './projectDemos/DashboardProjectDemos'
import ProjectsGrid, { NUM_PROJECTS_PER_PAGE } from './projectsGrid/ProjectsGrid'
import { useDashboardSettings } from './settings/useDashboardSettings'
import { WelcomeHeader } from './WelcomeHeader'

const Dashboard = () => {
  const { accessLevel } = useMyself()
  const isTabActive = useIsTabActive()
  const { costUsageRatio } = useOrgCostUsageRatio()
  const queryClient = useQueryClient()
  const { hideProjectDemos } = useDashboardSettings(({ hideProjectDemos }) => ({
    hideProjectDemos,
  }))

  const [showVpcAlert, setShowVpcAlert] = useState<boolean>(false)

  // Suspend until projects data is prefetched
  const { data: projects } = useProjects({
    startRow: 0,
    endRow: NUM_PROJECTS_PER_PAGE,
    useQueryOptions: { suspense: true },
  })
  // Suspend until enrichment projects data is prefetched
  const { data: enrichmentProjectsData } = useEnrichmentProjects({
    useQueryOptions: { suspense: true },
  })
  // Suspend until datasets data is prefetched
  const { data: datasetsData = [] } = useDashboardDatasets({ useQueryOptions: { suspense: true } })
  const { data: deploymentData = [] } = useDeployments({ useQueryOptions: { suspense: true } })

  const projectCount = projects?.row_count ?? 0
  const projectTemplateCount =
    projects?.rows?.reduce((n, project) => (project.is_template ? n + 1 : n), 0) ?? 0
  const realProjectCount = projectCount - projectTemplateCount

  const datasetCount = datasetsData.length || 0
  const datasetTemplateCount =
    datasetsData?.reduce((n, dataset) => (dataset.is_template ? n + 1 : n), 0) ?? 0

  const realdatasetCount = datasetCount - datasetTemplateCount

  const { data: templateProjectsData } = useTemplateProjectsPreview({
    useQueryOptions: { suspense: true },
  })

  const showProjectsGrid = hideProjectDemos || projectCount > 0
  const showDeploymentsGrid = hideProjectDemos || deploymentData.length > 0
  const showDatasetsGrid = hideProjectDemos || realdatasetCount > 0
  const showProjectDemos =
    !!templateProjectsData?.length &&
    !IS_VPC_ENVIRONMENT &&
    !hideProjectDemos &&
    realProjectCount === 0

  const vpcAlertMessage = getVpcAlertMessage(
    accessLevel === AccessLevel.ORGANIZATION_ADMIN,
    costUsageRatio
  )

  const location = useLocation()
  const deploymentsRef = useRef<HTMLDivElement | null>(null)

  const fetchDatasetsData = useCallback(async () => {
    if (isTabActive) {
      queryClient.invalidateQueries(datasetsQueryKeys.datasets.all())
    }
  }, [isTabActive, queryClient])

  const fetchDeploymentData = useCallback(async () => {
    if (isTabActive) {
      queryClient.invalidateQueries(deploymentsQueryKeys.deployments.all())
    }
  }, [isTabActive, queryClient])

  useEffect(() => {
    if (IS_VPC_ENVIRONMENT) {
      setShowVpcAlert((costUsageRatio ?? 0) >= 0.8)
    }
  }, [costUsageRatio])

  // Scroll to Deployments grid if redirected from Deploy Model button
  useEffect(() => {
    if (location.state?.scrollToDeployments && deploymentsRef.current && deploymentData) {
      setTimeout(() => {
        deploymentsRef.current?.scrollIntoView({ behavior: 'smooth' })
        deploymentsRef.current = null
      }, 100)
    }
  }, [deploymentData, location.state])

  const projectsGridClass = (() => {
    if (!showProjectDemos) return ''
    switch (projectCount) {
      case 1:
        return 'min-h-0 h-[calc(200px+(1*45px))]'
      case 2:
        return 'min-h-0 h-[calc(200px+(2*45px))]'
      case 3:
        return 'min-h-0 h-[calc(200px+(3*45px))]'
      default:
        return ''
    }
  })()

  return (
    <div className="flex max-w-full flex-col gap-9 px-8 pb-12 pt-5">
      {IS_VPC_ENVIRONMENT && showVpcAlert && (
        <div className="pb-6">
          <PageAlert
            message={vpcAlertMessage.message}
            spanText={vpcAlertMessage.spanText}
            onClose={() => setShowVpcAlert(false)}
            iconStart={<IconInfo size={20} className="text-red-600" />}
          />
        </div>
      )}
      {!showProjectsGrid && <WelcomeHeader />}
      {showProjectsGrid && <ProjectsGrid className={projectsGridClass} />}
      {showProjectDemos && <DashboardProjectDemos templates={templateProjectsData} />}
      {!!enrichmentProjectsData?.projects.length && <ProjectsGrid isEnrichment />}
      {showDatasetsGrid && <DatasetsGrid data={datasetsData} refreshData={fetchDatasetsData} />}
      {showDeploymentsGrid && (
        <div ref={deploymentsRef}>
          <DeploymentsGrid data={deploymentData ?? []} fetchDeploymentData={fetchDeploymentData} />
        </div>
      )}
      <HelpfulResourcesSection />
    </div>
  )
}

export default Dashboard
