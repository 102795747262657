import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Box, Heading, Text, Tooltip, useColorModeValue, VStack } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import useGridClassname from '@hooks/useGridClassname'
import { cn } from '@utils/tailwindUtils'
import { GetContextMenuItemsParams, ICellRendererParams } from 'ag-grid-community'
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import React from 'react'

import {
  CHART_SECTION_TOP_PADDING_IN_PRINT_IN_REM,
  CHART_TEXT_DICT,
} from '../CleansetCharts.helpers'
import chartStyles from '../CleansetCharts.module.css'
import { AgModelSummaryGridProps } from './AgModelSummaryGrid.types'

const AgModelSummaryGrid = (props: AgModelSummaryGridProps) => {
  const { data } = props
  const headerTextColor = useColorModeValue(
    cleanlabColors.neutral[900],
    cleanlabColors.neutralDarkMode[900]
  )
  const numRowsPerPage = 10

  const AccuracyCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <Text fontSize="sm">
        <Tooltip
          hasArrow
          label="Accuracy of predictions on held-out data from the current model fit to the current cleanset, estimated via cross-validation."
        >
          {rowData['Held-out Accuracy'].toFixed(6)}
        </Tooltip>
      </Text>
    )
  }

  const InferenceTimeCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <Text fontSize="sm">
        <Tooltip
          hasArrow
          label="Number of seconds spent producing a prediction for every data point in your Dataset during cross-validation"
        >
          {rowData['Inference Time (During Cross-Validation)'].toFixed(6)}
        </Tooltip>
      </Text>
    )
  }

  const columnDefs: ColDef[] = [
    {
      field: 'Model',
      headerName: 'Model Type',
    },
    {
      field: 'Held-out Accuracy',
      headerName: 'Accuracy',
      cellRenderer: AccuracyCellRenderer,
    },
    {
      field: 'Inference Time (During Cross-Validation)',
      headerName: 'Inference Time (Sec)',
      cellRenderer: InferenceTimeCellRenderer,
    },
    {
      field: 'Training Time (Sec)',
      headerName: 'Training Time (Sec)',
      valueFormatter: (params: ValueFormatterParams) =>
        params.data['Training Time (Sec)'].toFixed(6),
    },
  ]

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    rowHeight: 60,
    columnDefs,
    rowSelection: 'single',
    onGridReady: (params) => {
      params.api.sizeColumnsToFit()
    },
    defaultColDef: {
      filter: false,
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    suppressScrollOnNewData: true,
    // suppressPaginationPanel=true until we have need for pagination. for now, there's only ~3 rows
    suppressPaginationPanel: true,
    animateRows: false,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  return (
    <VStack
      as="section"
      className={cn(chartStyles.chartSectionContentContainer, chartStyles.analyticsGridSection)}
      gap="1rem"
      sx={{
        '@media print': {
          padding: `${CHART_SECTION_TOP_PADDING_IN_PRINT_IN_REM}rem 0 0`,
        },
      }}
    >
      <Heading
        fontSize="lg"
        lineHeight="120%"
        color={headerTextColor}
        className={chartStyles.chartSectionTitle}
      >
        {CHART_TEXT_DICT.titles.agModelSummary}
      </Heading>

      <Text display="block" className={chartStyles.chartSectionDescription}>
        {CHART_TEXT_DICT.descriptions.agModelSummary}
      </Text>

      <Box className={useGridClassname()} w="100%">
        <CleanlabGrid
          gridOptions={gridOptions}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={data}
        />
      </Box>
    </VStack>
  )
}

export default AgModelSummaryGrid
