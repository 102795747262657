import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { useRegisterForNotification } from '@services/user/mutations'
import React, { useCallback, useState } from 'react'
import { InlineLink } from 'src/pages/projectForm/Sidebar'

import { RegisterNotificationProps } from './RegisterNotification.types'

export const useAllowRegisterNotification = () => {
  const { payingCustomer, isEnterprisePlan } = useSubscription()
  return payingCustomer || isEnterprisePlan
}

const RegisterNotification = ({
  uploadId,
  eventId,
  cleansetId,
  children,
  onClick,
  onClose,
}: RegisterNotificationProps) => {
  const { mutate: registerForNotification, isLoading } = useRegisterForNotification()
  const [showModal, setShowModal] = useState(false)

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
    setShowModal(true)
    registerForNotification({ uploadId, eventId, cleansetId })
  }, [cleansetId, eventId, onClick, registerForNotification, uploadId])

  return (
    <>
      <InlineLink className="inline" asChild>
        <button onClick={handleClick}>{children}</button>
      </InlineLink>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
          onClose && onClose()
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Support</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="md" pb={8}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Text>
                A member of our team will be in contact with you once this issue is resolved!
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RegisterNotification
