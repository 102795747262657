import { ColDef, GridOptions } from 'ag-grid-community'

import { FilterData } from '../../filterReducer/FilterState.types'

export interface EditFilterMenuProps {
  gridOptions: GridOptions
  presetProperties: ColDef[]
  selectedProperty: string
  isOpen: boolean
  filterData: FilterData
  cleansetId: string
  isMultilabel: boolean
  labelsIndexed: Record<string, number> | null
  firstGridDataRendered: boolean
  resetResolverAndRowSelection: () => void
}

export enum EditFilterMenuOptions {
  EQUALS = 'Equals',
  NOT_EQUAL = 'Not equal',
  LESS_THAN = 'Less than',
  LESS_THAN_OR_EQUAL = 'Less than or equal',
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUAL = 'Greater than or equal',
  CONTAINS = 'Contains',
  NOT_CONTAINS = 'Not contains',
  STARTS_WITH = 'Starts with',
  ENDS_WITH = 'Ends with',
}
