import { Box, Text } from '@chakra-ui/react'
import { ComputedCell, HeatMapDatum, TooltipProps } from '@nivo/heatmap'

import { CHART_TEXT_DICT } from '../CleansetCharts.helpers'

const BaseTooltipComponent = ({
  isSuggestions,
  cell,
}: {
  isSuggestions: boolean
  cell: ComputedCell<HeatMapDatum>
}) => {
  const { data, serieId } = cell
  const background = 'neutral.900'
  const textColor = 'neutral.200'

  const givenLabel = serieId
  const suggestedLabel = data.x
  const value = data.y

  if (givenLabel.toString() === suggestedLabel.toString()) {
    return null
  }

  return (
    <Box
      bgColor={background}
      // these styles were copied from @nivo/tooltip to maintain consistency with other tooltips
      padding="5px 9px"
      borderRadius="8px"
      boxShadow="0 1px 2px rgba(0, 0, 0, 0.25)"
    >
      <Text color={textColor} fontSize={12}>
        <p>
          {CHART_TEXT_DICT.titles.givenLabel}: {givenLabel}
        </p>
        <p>
          {isSuggestions
            ? CHART_TEXT_DICT.titles.suggestedLabel
            : CHART_TEXT_DICT.titles.correctedLabel}
          : {suggestedLabel}
        </p>
        <p>
          {isSuggestions
            ? CHART_TEXT_DICT.titles.numberOfSuggestedCorrections
            : CHART_TEXT_DICT.titles.numberOfCorrectedExamples}
          : {value}
        </p>
      </Text>
    </Box>
  )
}

const CorrectionsHeatmapTooltip = (props: TooltipProps<HeatMapDatum>) => {
  return <BaseTooltipComponent cell={props.cell} isSuggestions={false} />
}

const SuggestionsHeatmapTooltip = (props: TooltipProps<HeatMapDatum>) => {
  return <BaseTooltipComponent cell={props.cell} isSuggestions />
}

export { CorrectionsHeatmapTooltip, SuggestionsHeatmapTooltip }
