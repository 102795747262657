import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { cleanlabTransformResponse } from '@services/common'
import {
  dashboardDatasetsQueryOptions,
  DatasetDetailsPageInfoProps,
  DatasetDetailsProps,
  DatasetRowProps,
} from '@services/datasetApi'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useQuery } from 'react-query'

import { UseQueryOptionsPassThrough } from '../useQuery.helpers'
import { queryKeys } from './constants'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasets`,
  withCredentials: true,
  transformResponse: cleanlabTransformResponse,
})

type UseDatasetsOptions = {
  useQueryOptions?: UseQueryOptionsPassThrough<DatasetRowProps[]>
}
export const datasetsQueryFn = async () => {
  const accessToken = await auth.getTokenSilently()
  const res = await axiosClient.get<{ rows: DatasetRowProps[] }>(
    '/',
    createAuthHeaders(accessToken)
  )
  return [...res.data.rows].sort((a, b) => b.upload_date - a.upload_date) ?? []
}

export const useDatasets = ({ useQueryOptions = {} }: UseDatasetsOptions = {}) => {
  const { meta = {}, ...options } = useQueryOptions
  const { data, ...rest } = useQuery<DatasetRowProps[]>({
    queryKey: queryKeys.datasets.all(),
    queryFn: datasetsQueryFn,
    meta: {
      error: 'Failed to fetch Datasets.',
      ...meta,
    },
    ...options,
  })
  return { data: data ?? [], ...rest }
}

export const useDashboardDatasets = ({ useQueryOptions = {} }: UseDatasetsOptions = {}) => {
  const { meta = {}, ...options } = useQueryOptions
  return useQuery<DatasetRowProps[]>({
    ...dashboardDatasetsQueryOptions(),
    meta: {
      error: 'Failed to fetch Datasets.',
      ...meta,
    },
    ...options,
  })
}

export const useDatasetDetailsPage = ({
  datasetId,
  useQueryOptions = {},
}: {
  datasetId?: string
  useQueryOptions?: UseQueryOptionsPassThrough<DatasetDetailsPageInfoProps>
}) => {
  const { meta = {}, enabled = true, ...options } = useQueryOptions
  const { data } = useQuery<DatasetDetailsPageInfoProps>({
    queryKey: datasetId ? queryKeys.datasets.details(datasetId) : undefined,
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(
        `/dataset_details_page/${datasetId}`,
        createAuthHeaders(accessToken)
      )
      return res.data
    },
    meta: { error: 'Failed to fetch Dataset details page.', ...meta },
    suspense: true,
    enabled: !!(datasetId && enabled),
    ...options,
  })
  return data!
}

export const useDatasetDetails = ({
  datasetId,
  useQueryOptions = {},
}: {
  datasetId?: string
  useQueryOptions?: UseQueryOptionsPassThrough<DatasetDetailsProps>
}) => {
  const { meta = {}, enabled = true, ...options } = useQueryOptions
  const { data } = useQuery<DatasetDetailsProps>({
    queryKey: datasetId ? queryKeys.datasets.id(datasetId) : undefined,
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(`/details/${datasetId}`, createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Failed to fetch Dataset details.', ...meta },
    suspense: true,
    enabled: !!(datasetId && enabled),
    ...options,
  })
  return data!
}
