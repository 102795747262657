import { Button, Grid, GridItem, Heading, Text, VStack } from '@chakra-ui/react'
import queryApi from '@services/queryApi'
import { useState } from 'react'

import { ControlledFormInputElement, QueryResults } from './QueryInterface.helpers'

const QueryInterfaceFindExperimentalJob = () => {
  const [userId, setUserId] = useState('')
  const [datasetId, setDatasetId] = useState('')
  const [jobId, setJobId] = useState('')
  const [queryResults, setQueryResults] = useState(null)

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Query Experimental Jobs</Heading>
      <Text>
        <strong>Instructions:</strong> Experimental Jobs can be queried using UserID, DatasetID, or
        JobID.
      </Text>
      <Text>If a DatasetID is given, the UserID is ignored.</Text>
      <Text>If a JobID is given, the UserID and the DatasetID are ignored.</Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem>
          <ControlledFormInputElement
            id="user_id"
            type="text"
            label="UserID"
            value={userId}
            setValue={setUserId}
          />
        </GridItem>
        <GridItem>
          <ControlledFormInputElement
            id="dataset_id"
            type="text"
            label="DatasetID"
            value={datasetId}
            setValue={setDatasetId}
          />
        </GridItem>
        <GridItem>
          <ControlledFormInputElement
            id="job_id"
            type="text"
            label="JobID"
            value={jobId}
            setValue={setJobId}
          />
        </GridItem>
      </Grid>

      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApi.query({
            resource: 'experimental_job',
            user_id: userId,
            experimental_dataset_id: datasetId,
            experimental_job_id: jobId,
          })
          setQueryResults(response.data)
        }}
        aria-label="find experimental job"
      >
        Find Experimental Job
      </Button>

      <QueryResults data={queryResults} resource="experimental jobs" />
    </VStack>
  )
}

export default QueryInterfaceFindExperimentalJob
