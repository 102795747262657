import { useDisclosure } from '@chakra-ui/react'
import TlmPricingModal from '@common/modals/tlmPricingModal/TlmPricingModal'
import { useAuth } from '@hooks/useAuth'
import { useCleanlabLocation } from '@hooks/useCleanlabLocation'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { queryKeys } from '@services/billing/constants'
import { useSubscriptionDetails } from '@services/billing/queries'
import { useMyself } from '@services/user/queries'
import { useCurrentNotification } from '@services/userNotification/queries'
import { useUserQuota } from '@services/userQuota/queries'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'

export interface SubscriptionContextInterface {
  cancelledAt: number | null
  cleansetExportRowsMonthlyLimit: number
  createdAt: number
  datasetRowsLimit: number
  description: string
  isEnterprisePlan: boolean
  isIndividualPayingCustomer: boolean
  freeCleansetExportRowsMonthlyLimit: number
  freeInferenceExportRowsMonthlyLimit: number
  freeModelsMonthlyLimit: number
  freeProjectsMonthlyLimit: number
  freeTrialDatasetRowsLimit: number
  freeTrialInferenceExportRowsLimit: number
  freeTrialLengthDays: number
  freeTrialModelsLimit: number
  freeTrialPeriod: boolean
  freeTrialPeriodEnd: number
  freeTrialProjectsLimit: number
  freeTrialPeriodRemaining: number
  inferenceExportRowsMonthlyLimit: number
  lastUpdated: number
  payingCustomer: boolean
  planName: string
  pricePerMonth: number
  pricePerRowCleansetExport: number
  pricePerRowInferenceExport: number
  pricePerYear: number
  tier: string
  userId: string
  featuresDisabled: boolean
  onSalesModalOpen: VoidFunction
  isSalesModalOpen: boolean
  onSalesModalClose: VoidFunction
  isUpgradePlanModalOpen: boolean
  onUpgradePlanModalClose: VoidFunction
  onUpgradePlanModalOpen: VoidFunction
  onSubscriptionModalOpen: VoidFunction
  showFreeTrialBanner: boolean
  showNotificationBanner: boolean
  notificationBannerMessage: string
  numProjectsAvailable: number
  numModelsAvailable: number
  numInferenceExportRowsAvailable: number
  days: number
  hours: number
  minutes: number
  timeRemaining: number
  showCelebration: boolean
  setShowCelebration: (showCelebration: boolean) => void
  isLoading: boolean
  isTlmPlan: boolean
  setIsTlmPricingModalOpen: (isTlmPricingModalOpen: boolean) => void
}

export const DEFAULT_SUBSCRIPTION_CONTEXT = {
  cancelledAt: null,
  cleansetExportRowsMonthlyLimit: 0,
  createdAt: 0,
  datasetRowsLimit: 0,
  description: '',
  isEnterprisePlan: false,
  isIndividualPayingCustomer: false,
  freeCleansetExportRowsMonthlyLimit: 0,
  freeInferenceExportRowsMonthlyLimit: 0,
  freeModelsMonthlyLimit: 0,
  freeProjectsMonthlyLimit: 0,
  freeTrialDatasetRowsLimit: 0,
  freeTrialInferenceExportRowsLimit: 0,
  freeTrialLengthDays: 0,
  freeTrialModelsLimit: 0,
  freeTrialPeriod: false,
  freeTrialPeriodEnd: 0,
  freeTrialProjectsLimit: 0,
  freeTrialPeriodRemaining: 0,
  inferenceExportRowsMonthlyLimit: 0,
  lastUpdated: 0,
  payingCustomer: false,
  planName: '',
  pricePerMonth: 0,
  pricePerRowCleansetExport: 0,
  pricePerRowInferenceExport: 0,
  pricePerYear: 0,
  tier: '',
  userId: '',
  featuresDisabled: false,
  onSalesModalOpen: () => {},
  isSalesModalOpen: false,
  onSalesModalClose: () => {},
  isUpgradePlanModalOpen: false,
  onUpgradePlanModalClose: () => {},
  onUpgradePlanModalOpen: () => {},
  onSubscriptionModalOpen: () => {},
  showFreeTrialBanner: false,
  showNotificationBanner: false,
  notificationBannerMessage: '',
  numProjectsAvailable: 0,
  numModelsAvailable: 0,
  numInferenceExportRowsAvailable: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  timeRemaining: 0,
  showCelebration: false,
  setShowCelebration: () => {},
  isLoading: true,
  isTlmPlan: false,
  setIsTlmPricingModalOpen: () => {},
} as const satisfies SubscriptionContextInterface

const SubscriptionContext = React.createContext<SubscriptionContextInterface>(
  DEFAULT_SUBSCRIPTION_CONTEXT
)

export const useSubscription = () => useContext(SubscriptionContext)

const SubscriptionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const { activated: isActivated, emailConfirmed } = useMyself()
  const { trackEvent } = useEventTracking()
  const path = useCleanlabLocation()
  const subscriptionDetails = useSubscriptionDetails({ enabled: emailConfirmed })

  const {
    isOpen: isSalesModalOpen,
    onClose: onSalesModalClose,
    onOpen: onSalesModalOpen,
  } = useDisclosure()

  const {
    isOpen: isUpgradePlanModalOpen,
    onClose: onUpgradePlanModalClose,
    onOpen: onUpgradePlanModalOpen,
  } = useDisclosure()

  const {
    isOpen: isTlmPricingModalOpen,
    onClose: onTlmPricingModalClose,
    onOpen: onTlmPricingModalOpen,
  } = useDisclosure()

  const { hasSeen, message } = useCurrentNotification(isAuthenticated)

  const {
    cancelledAt,
    cleansetExportRowsMonthlyLimit,
    createdAt,
    datasetRowsLimit,
    description,
    isEnterprisePlan,
    isIndividualPayingCustomer,
    freeCleansetExportRowsMonthlyLimit,
    freeInferenceExportRowsMonthlyLimit,
    freeModelsMonthlyLimit,
    freeProjectsMonthlyLimit,
    freeTrialDatasetRowsLimit,
    freeTrialInferenceExportRowsLimit,
    freeTrialLengthDays,
    freeTrialModelsLimit,
    freeTrialPeriod,
    freeTrialPeriodEnd,
    freeTrialProjectsLimit,
    freeTrialPeriodRemaining,
    inferenceExportRowsMonthlyLimit,
    lastUpdated,
    payingCustomer,
    planName,
    pricePerMonth,
    pricePerRowCleansetExport,
    pricePerRowInferenceExport,
    pricePerYear,
    tier,
    userId,
    isLoading,
  } = useSubscriptionDetails({ enabled: isActivated && emailConfirmed })

  const usage = useUserQuota({ enabled: isActivated && emailConfirmed })

  const showNotificationBanner = !!message && !hasSeen

  const numProjectsAvailable =
    (freeTrialPeriod ? freeTrialProjectsLimit : freeProjectsMonthlyLimit) -
    (usage?.numFreeProjectsUsed ?? 0)

  const numModelsAvailable =
    (freeTrialPeriod ? freeTrialModelsLimit : freeModelsMonthlyLimit) -
    (usage?.numFreeModelsUsed ?? 0)

  const numInferenceExportRowsAvailable =
    (freeTrialPeriod ? freeTrialInferenceExportRowsLimit : freeInferenceExportRowsMonthlyLimit) -
    (usage?.numFreeInferenceExportRowsUsed ?? 0)

  const [showCelebration, setShowCelebration] = useState<boolean>(false)

  const [timeRemaining, setTimeRemaining] = useState(freeTrialPeriodEnd * 1000 - Date.now())

  const queryClient = useQueryClient()

  // Used to display banner when remaining time is ready to be displayed
  const timerIsReady =
    (freeTrialPeriod && timeRemaining > 0) || // timer is set up and counting, OR...
    (!freeTrialPeriod && !payingCustomer) // no timer needed, free trial expired & not a paying customer yet

  const showFreeTrialBanner =
    !payingCustomer && isAuthenticated && freeTrialPeriodEnd > 0 && timerIsReady

  const onSubscriptionModalOpen = useCallback(() => {
    if ((isEnterprisePlan || payingCustomer) && !(isIndividualPayingCustomer && cancelledAt)) {
      onSalesModalOpen()
      trackEvent(MixpanelEvents.openEnterpriseSalesModal, { path: path, ...subscriptionDetails })
    } else {
      onUpgradePlanModalOpen()
      trackEvent(MixpanelEvents.openUpgradePlanModal, { path: path, ...subscriptionDetails })
    }
  }, [
    cancelledAt,
    isEnterprisePlan,
    isIndividualPayingCustomer,
    onSalesModalOpen,
    onUpgradePlanModalOpen,
    path,
    payingCustomer,
    subscriptionDetails,
    trackEvent,
  ])

  // countdown timer
  useEffect(() => {
    if (timeRemaining <= 0) {
      // Refetch subscription details when timer hits 0
      freeTrialPeriod && setTimeRemaining(freeTrialPeriodEnd * 1000 - Date.now())
      queryClient.invalidateQueries(queryKeys.billing.all())
      return
    }
    const intervalId = setInterval(() => {
      setTimeRemaining(timeRemaining - 1000)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [queryClient, timeRemaining, freeTrialPeriodEnd, freeTrialPeriod])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ctx = useMemo(() => {
    return {
      cancelledAt: cancelledAt,
      cleansetExportRowsMonthlyLimit: cleansetExportRowsMonthlyLimit,
      createdAt: createdAt,
      datasetRowsLimit: datasetRowsLimit,
      description: description,
      isEnterprisePlan: isEnterprisePlan,
      isIndividualPayingCustomer: isIndividualPayingCustomer,
      freeCleansetExportRowsMonthlyLimit: freeCleansetExportRowsMonthlyLimit,
      freeInferenceExportRowsMonthlyLimit: freeInferenceExportRowsMonthlyLimit,
      freeModelsMonthlyLimit: freeModelsMonthlyLimit,
      freeProjectsMonthlyLimit: freeProjectsMonthlyLimit,
      freeTrialDatasetRowsLimit: freeTrialDatasetRowsLimit,
      freeTrialInferenceExportRowsLimit: freeTrialInferenceExportRowsLimit,
      freeTrialLengthDays: freeTrialLengthDays,
      freeTrialModelsLimit: freeTrialModelsLimit,
      freeTrialPeriod: freeTrialPeriod,
      freeTrialPeriodEnd: freeTrialPeriodEnd,
      freeTrialProjectsLimit: freeTrialProjectsLimit,
      freeTrialPeriodRemaining: freeTrialPeriodRemaining,
      inferenceExportRowsMonthlyLimit: inferenceExportRowsMonthlyLimit,
      lastUpdated: lastUpdated,
      payingCustomer: payingCustomer,
      planName: planName,
      pricePerMonth: pricePerMonth,
      pricePerRowCleansetExport: pricePerRowCleansetExport,
      pricePerRowInferenceExport: pricePerRowInferenceExport,
      pricePerYear: pricePerYear,
      tier: tier,
      userId: userId,
      featuresDisabled: !freeTrialPeriod && !payingCustomer,
      onSalesModalOpen: onSalesModalOpen,
      isSalesModalOpen: isSalesModalOpen,
      onSalesModalClose: onSalesModalClose,
      isUpgradePlanModalOpen: isUpgradePlanModalOpen,
      onUpgradePlanModalClose: () => {
        setShowCelebration(false)
        onUpgradePlanModalClose()
      },
      onUpgradePlanModalOpen: onUpgradePlanModalOpen,
      onSubscriptionModalOpen: onSubscriptionModalOpen,
      showFreeTrialBanner: showFreeTrialBanner,
      showNotificationBanner: showNotificationBanner,
      notificationBannerMessage: message,
      numProjectsAvailable: numProjectsAvailable,
      numModelsAvailable: numModelsAvailable,
      numInferenceExportRowsAvailable: numInferenceExportRowsAvailable,
      days: Math.floor(timeRemaining / 1000 / 60 / 60 / 24),
      hours: Math.floor((timeRemaining / 1000 / 60 / 60) % 24),
      minutes: Math.floor((timeRemaining / 1000 / 60) % 60) + 1,
      timeRemaining: timeRemaining,
      showCelebration: showCelebration,
      setShowCelebration: setShowCelebration,
      isLoading: isLoading,
      isTlmPlan: planName.toLowerCase().includes('tlm'),
      setIsTlmPricingModalOpen: onTlmPricingModalOpen,
    }
  }, [
    cancelledAt,
    cleansetExportRowsMonthlyLimit,
    createdAt,
    datasetRowsLimit,
    description,
    freeCleansetExportRowsMonthlyLimit,
    freeInferenceExportRowsMonthlyLimit,
    freeModelsMonthlyLimit,
    freeProjectsMonthlyLimit,
    freeTrialDatasetRowsLimit,
    freeTrialInferenceExportRowsLimit,
    freeTrialLengthDays,
    freeTrialModelsLimit,
    freeTrialPeriod,
    freeTrialPeriodEnd,
    freeTrialPeriodRemaining,
    freeTrialProjectsLimit,
    inferenceExportRowsMonthlyLimit,
    isEnterprisePlan,
    isIndividualPayingCustomer,
    isLoading,
    isSalesModalOpen,
    isUpgradePlanModalOpen,
    lastUpdated,
    message,
    numInferenceExportRowsAvailable,
    numModelsAvailable,
    numProjectsAvailable,
    onSalesModalClose,
    onSalesModalOpen,
    onSubscriptionModalOpen,
    onTlmPricingModalOpen,
    onUpgradePlanModalClose,
    onUpgradePlanModalOpen,
    payingCustomer,
    planName,
    pricePerMonth,
    pricePerRowCleansetExport,
    pricePerRowInferenceExport,
    pricePerYear,
    showCelebration,
    showFreeTrialBanner,
    showNotificationBanner,
    tier,
    timeRemaining,
    userId,
  ])

  return (
    <SubscriptionContext.Provider value={ctx}>
      <>
        <TlmPricingModal isOpen={isTlmPricingModalOpen} onClose={onTlmPricingModalClose} />
        {children}
      </>
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionProvider
