import { Box, CloseButton, Container, Stack } from '@chakra-ui/react'
import MaxWidthText from '@common/misc/maxWidthText/MaxWidthText'
import { useCloseNotificationMutation } from '@services/userNotification/mutations'
import React from 'react'

import { NotificationBannerProps } from './NotificationBanner.types'

const NotificationBanner = (props: NotificationBannerProps) => {
  const { message } = props
  const { mutate: closeNotification } = useCloseNotificationMutation()
  return (
    <Box as="section" maxH="52px">
      <Box bg="bg-accent" color="on-accent" position="relative">
        <Container py={{ base: '4', md: '3.5' }}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify="center"
            spacing={{ base: '0.5', md: '1.5' }}
            pe={{ base: '4', sm: '0' }}
            fontSize="md"
          >
            <MaxWidthText fontWeight="medium" maxWidth="100%">
              {message}
            </MaxWidthText>
          </Stack>
          <CloseButton
            position="absolute"
            right="2"
            top="2.5"
            onClick={() => closeNotification()}
          />
        </Container>
      </Box>
    </Box>
  )
}

export default NotificationBanner
