import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

export const cardTheme = defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    standard: (props: StyleFunctionProps) => ({
      container: {
        borderWidth: '1px',
        borderRadius: '12px',
        borderColor: mode('neutral.100', 'neutralDarkMode.100')(props),
        bg: mode('white', 'neutralDarkMode.50')(props),
        boxShadow: 'sm',
      },
      header: {
        color: mode('neutral.700', 'neutralDarkMode.700')(props),
        textTransform: 'uppercase',
        padding: '12px 16px 8px 16px',
      },
      body: { padding: 0 },
    }),
    collapsed: (props: StyleFunctionProps) => ({
      container: {
        w: '48px',
        h: '48px',
        borderWidth: '1px 1px 1px 0px',
        borderRadius: '0px 8px 8px 0px',
        borderColor: mode('neutral.100', 'neutralDarkMode.100')(props),
        bg: mode('white', 'neutralDarkMode.50')(props),
        boxShadow: 'sm',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        marginLeft: '-24px',
      },
      body: { padding: '6px' },
    }),
  },
})
