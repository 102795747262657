import { FeatureImportanceData } from './constants'

type NormalizedOutput = Record<string, [string, number][]>

export const truncateDecimals = (input?: number | string, decimalPlaces = 6) => {
  const inputAsNumber = Number(input)

  return Number(inputAsNumber.toFixed(decimalPlaces))
}

export const normalizePerformanceData = (
  input: { scoreName: string; scoreValue: string }[] = []
): [string, number][] => {
  if (input.length === 0) {
    return []
  }

  // filter out NaN values
  return input.flatMap(({ scoreName, scoreValue }) => {
    const truncatedValue = truncateDecimals(scoreValue, 6)

    if (isNaN(truncatedValue)) {
      return []
    }

    return [[scoreName, truncatedValue]]
  })
}

export const normalizePerformancePerClassData = (
  input: Record<string, Record<string, number>> = {}
) => {
  const normalizedData: NormalizedOutput = {}
  const allClasses: string[] = []

  Object.entries(input).forEach(([className, scoreData]) => {
    normalizedData[className] = Object.entries(scoreData).map(([scoreName, scoreValue]) => [
      scoreName,
      truncateDecimals(scoreValue, 6),
    ])
    allClasses.push(className)
  })

  return {
    classData: normalizedData,
    allClasses,
  }
}

export const normalizeFeatureImportanceData = (
  input: null | undefined | Record<string, Record<string, number>> = {}
): FeatureImportanceData => {
  const entries = input === null ? [] : Object.entries(input)

  if (entries.length === 0) {
    return []
  }

  return entries.map(([featureName, data]) => [
    featureName,
    data.importance ? truncateDecimals(data.importance, 4) : 'N/A',
    data.pValue ? truncateDecimals(data.pValue, 4) : 'N/A',
  ])
}
