import { defineStyleConfig, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

export const badgeTheme = defineStyleConfig({
  baseStyle: (props: StyleFunctionProps) => ({
    color: mode('gray.700', 'neutralDarkMode.700')(props),
    bg: mode('gray.100', 'neutralDarkMode.100')(props),
    borderRadius: '4px',
    _groupHover: {
      color: mode('gray.700', 'neutralDarkMode.700')(props),
      bg: mode('gray.200', 'neutralDarkMode.200')(props),
    },
    _groupActive: {
      color: mode('gray.700', 'neutralDarkMode.700')(props),
      bg: mode('gray.300', 'neutralDarkMode.300')(props),
    },
  }),
  sizes: {
    xs: {
      fontSize: 'xs',
      px: 2,
      py: 0.5,
    },
  },
  defaultProps: {
    size: 'xs',
  },
})
