import { Button } from '@components/button/Button'
import { Checkbox } from '@components/checkbox/Checkbox'
import { FormField } from '@components/formField/FormField'
import { Input } from '@components/input/Input'
import { LabelInline } from '@components/label/LabelInline'
import { Tooltip } from '@components/tooltip/Tooltip'
import { MAX_NUM_DEFAULT_COLUMNS } from '@utils/ag-grid/consts'
import { useEffect, useId, useMemo, useState } from 'react'
import testIds from 'src/playwright/testIds'

import { PageDescription, PageSubHeading } from '../CreateProject.helpers'
import { SelectLabelForm } from '../CreateProjectSelectLabel'

const MAX_COLUMNS_MESSAGE =
  `*${MAX_NUM_DEFAULT_COLUMNS} feature columns are displayed at a time. Search to find specific feature columns.` as const

const CheckboxSelectionGrid = ({ form, choices }: { form: SelectLabelForm; choices: string[] }) => {
  const { labelColumn, featureColsIsDirty } = form.useStore((state) => ({
    labelColumn: state.values.labelColumn,
    featureColumns: state.values.featureColumns,
    featureColsIsDirty: !!state.fieldMeta.featureColumns?.isDirty,
    setValue: state,
  }))
  const setValue = form.setFieldValue
  const reservedColumns = useMemo(() => [labelColumn], [labelColumn])
  const labelId = useId()
  const descriptionId = useId()
  const choicesSorted = useMemo(() => [...choices].sort((a, b) => a.localeCompare(b)), [choices])
  const [columnSearch, setColumnSearch] = useState('')
  const choicesFiltered = useMemo(() => {
    return choicesSorted.filter((choice) =>
      choice.toLowerCase().includes(columnSearch.toLowerCase())
    )
  }, [choicesSorted, columnSearch])

  useEffect(() => {
    // Select all except reservedColumns when pristine and reservedColumns has changed
    // Using `setValue` instead of `handleChange` to avoid dirtying 'featureColumns'
    if (!featureColsIsDirty) {
      setValue('featureColumns', () => {
        return choices.filter((x) => !reservedColumns.includes(x))
      })
    }
  }, [choices, featureColsIsDirty, reservedColumns, setValue])

  return (
    <div className="flex flex-col gap-8" data-testid={testIds.createProjectLabelsSelectionGrid}>
      <div className="flex flex-col gap-3">
        <PageSubHeading id={labelId}>What are your feature fields?</PageSubHeading>
        <PageDescription id={descriptionId}>
          Specify which columns that Cleanlab should consider as predictors of the label. Select as
          many columns as possible, except for columns like IDs that you know are not predictive of
          the label.
        </PageDescription>
      </div>
      <form.Field
        name="featureColumns"
        validators={{
          onChangeListenTo: ['labelColumn'],
          onChange: ({ value: featureColumns, fieldApi }) => {
            const labelCol = fieldApi.form.getFieldValue('labelColumn')
            if (!featureColumns || featureColumns.length === 0) {
              return 'You must select at least one feature column'
            }
            if (featureColumns.includes(labelCol)) {
              fieldApi.setValue((prev) => prev.filter((x) => x !== labelCol))
            }
            return undefined
          },
        }}
      >
        {({ state, handleChange }) => {
          const error = state.meta.errors?.[0] || undefined
          const value = state.value

          return (
            <FormField error={error}>
              <div>
                <div className="flex gap-5 pb-6">
                  <Input
                    name="featureColumns"
                    placeholder="Search feature columns"
                    onChange={(evt) => setColumnSearch(evt.target.value)}
                  />
                  <Button
                    className="shrink-0"
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      handleChange(choices.filter((x) => !reservedColumns.includes(x)))
                    }}
                  >
                    Select all
                  </Button>
                  <Button
                    className="shrink-0"
                    variant="secondary"
                    size="small"
                    onClick={() => handleChange([])}
                  >
                    Deselect all
                  </Button>
                </div>
                <fieldset
                  className="border-t-1 grid w-full grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-8 border-t border-border-1 pt-6"
                  role="group"
                  aria-labelledby={labelId}
                  aria-describedby={descriptionId}
                >
                  {choicesFiltered.map((choice) => {
                    const checkDisabled = reservedColumns.includes(choice)
                    return (
                      <Tooltip
                        key={choice}
                        disabled={!checkDisabled}
                        content={`You cannot select '${labelColumn}' as both a label and a feature`}
                      >
                        <LabelInline
                          className="break-all"
                          key={choice}
                          content={choice}
                          disabled={checkDisabled}
                        >
                          <Checkbox
                            name="featureColumns"
                            checked={value.includes(choice)}
                            onCheckedChange={(checked) => {
                              const set = new Set(value)
                              if (checked === true) {
                                set.add(choice)
                              } else {
                                set.delete(choice)
                              }
                              handleChange(Array.from(set))
                            }}
                          />
                        </LabelInline>
                      </Tooltip>
                    )
                  })}
                </fieldset>
                {choices.length >= MAX_NUM_DEFAULT_COLUMNS && (
                  <p className="type-body-100 pt-5 text-yellow-800" data-testid="cols-truncated">
                    {MAX_COLUMNS_MESSAGE}
                  </p>
                )}
              </div>
            </FormField>
          )
        }}
      </form.Field>
    </div>
  )
}

export default CheckboxSelectionGrid
