import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Select, useColorModeValue } from '@chakra-ui/react'
import { ButtonClose } from '@components/buttonClose/ButtonClose'
import { FormField } from '@components/formField/FormField'
import ExpandIcon from '@components/icons/expandIcon/ExpandIcon'
import { Input } from '@components/input/Input'
import { AddedUser } from '@services/user/constants'
import { useId } from 'react'
import { UseFieldArrayRemove, UseFormRegister } from 'react-hook-form'

interface AddUserInputProps {
  register: UseFormRegister<{
    addedUsers: AddedUser[]
  }>
  index: number
  remove: UseFieldArrayRemove
}

const AddUserInput = (props: AddUserInputProps) => {
  const { register, index, remove } = props
  const selectBorderColor = useColorModeValue('border.1', 'borderDarkMode.1')
  const textPrimary = useColorModeValue(
    cleanlabColors.neutral[800],
    cleanlabColors.neutralDarkMode[800]
  )
  const blue600 = useColorModeValue('blue.600', 'blueDarkMode.600')

  const focusVisible = {
    _focusVisible: { outline: '2px solid', outlineColor: blue600, border: 'none' },
  }

  const roleSelectId = useId()

  return (
    <div className="flex flex-row items-end gap-3">
      <div className="flex grow flex-row items-center gap-6">
        <div className="w-1/2">
          <FormField label="Email address" required>
            <Input
              size="large"
              placeholder="email@company.ai"
              {...register(`addedUsers.${index}.email`, { required: true })}
            />
          </FormField>
        </div>
        <div className="w-1/2">
          <FormField label="Role" htmlFor={roleSelectId}>
            <Select
              id={roleSelectId}
              height="40px"
              borderRadius="8px"
              fontSize="sm"
              borderColor={selectBorderColor}
              className="text-text-strong"
              icon={<ExpandIcon width="20" height="20" color={textPrimary} />}
              defaultValue="user"
              {...focusVisible}
              {...register(`addedUsers.${index}.accessLevel`, { required: true })}
            >
              <option value="user">Member</option>
              <option value="organization_admin">Admin</option>
            </Select>
          </FormField>
        </div>
      </div>
      <ButtonClose onClick={() => remove(index)} size="large" aria-label="Remove user" />
    </div>
  )
}

export default AddUserInput
