import { IconButton as ChakraIconButton, Tooltip } from '@chakra-ui/react'

import { IconButtonProps } from './IconButton.types'

const IconButton = (props: IconButtonProps) => {
  const { icon, ariaLabel, iconColor, tooltipLabel, size, variant, minWidth, minHeight, onClick } =
    props
  return (
    <Tooltip label={tooltipLabel}>
      <ChakraIconButton
        aria-label={ariaLabel}
        minW={minWidth}
        minH={minHeight}
        height={minHeight}
        icon={icon}
        onClick={onClick}
        variant={variant ? variant : 'icon-button'}
        color={iconColor}
        boxSize={size}
      />
    </Tooltip>
  )
}

export default IconButton
