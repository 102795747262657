import { Flex } from '@chakra-ui/react'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'
import React from 'react'

import CleanlabTag from './cleanlabTag/CleanlabTag'

const CleanlabTagsCellRenderer = (params: ICellRendererParams) => {
  const cleanlabTags = params.value
  return (
    <Flex align="center" height="100%">
      <CleanlabTag cleanlabTags={cleanlabTags} />
    </Flex>
  )
}

export default CleanlabTagsCellRenderer
