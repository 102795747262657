import { useDisclosure } from '@chakra-ui/react'
import DeleteDatasetModal from '@common/modals/deleteDatasetModal/DeleteDatasetModal'
import { Button } from '@components/button/Button'
import { IconPlus, IconTrash } from '@components/icons'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import datasetApiService from '@services/datasetApi'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { SchemaUpdatesContext } from '../SchemaUpdatesContext'
import { DatasetActionButtonsProps } from './DatasetActionButtons.types'
import ShowDataWarningsSwitch from './showDataWarningsSwitch/ShowDataWarningsSwitch'

const DatasetActionButtons = ({
  datasetId,
  isDatasetComplete,
  datasetName,
  showDataWarnings,
  setShowDataWarnings,
  setIsDeletingSelf,
}: DatasetActionButtonsProps) => {
  const navigate = useNavigate()
  const { trackEvent } = useEventTracking()
  const {
    isOpen: isDeleteDatasetModalOpen,
    onOpen: onDeleteDatasetModalOpen,
    onClose: onDeleteDatasetModalClose,
  } = useDisclosure()
  const { schemaUpdates } = useContext(SchemaUpdatesContext)
  const schemaUpdateInProgress = Object.keys(schemaUpdates).length > 0

  const handleDeleteButtonClicked = async () => {
    trackEvent(MixpanelEvents.deleteDataset, { datasetId })
    setIsDeletingSelf(true)
    try {
      await datasetApiService.deleteRow(datasetId)
    } catch (error) {
      setIsDeletingSelf(false)
      return
    }
    navigate('/', {})
  }

  const handleCleanButtonClicked = () => {
    trackEvent(MixpanelEvents.clickCreateProject, { page: 'dataset details', datasetId })
    navigate(`/clean/${datasetId}`)
  }

  return (
    <div className="flex w-full justify-between gap-4 lg:w-min lg:justify-end">
      <DeleteDatasetModal
        isOpen={isDeleteDatasetModalOpen}
        onClose={onDeleteDatasetModalClose}
        handleDeleteButtonClicked={handleDeleteButtonClicked}
        datasetName={datasetName}
      />
      <ShowDataWarningsSwitch
        showDataWarnings={showDataWarnings}
        setShowDataWarnings={setShowDataWarnings}
        isDisabled={!isDatasetComplete}
      />
      <div className="flex gap-4">
        <Button
          className="w-[160px]"
          variant="critical"
          iconStart={<IconTrash />}
          onClick={onDeleteDatasetModalOpen}
          disabled={!isDatasetComplete}
        >
          Delete Dataset
        </Button>
        {schemaUpdateInProgress ? (
          <Tooltip content="Save or undo changes to proceed">
            <Button
              className="w-fit [&>*]:line-clamp-1"
              disabled={!isDatasetComplete || schemaUpdateInProgress}
              variant="primary"
              iconStart={<IconPlus />}
              onClick={handleCleanButtonClicked}
            >
              Create Project
            </Button>
          </Tooltip>
        ) : (
          <Button
            className="w-[160px]"
            disabled={!isDatasetComplete || schemaUpdateInProgress}
            onClick={handleCleanButtonClicked}
            variant="primary"
            iconStart={<IconPlus />}
          >
            Create Project
          </Button>
        )}
      </div>
    </div>
  )
}

export default DatasetActionButtons
