import { Button, HStack, Text, useDisclosure } from '@chakra-ui/react'
import MaxWidthText from '@common/misc/maxWidthText/MaxWidthText'
import { useCurrentNotification } from '@services/userNotification/queries'
import React from 'react'

import NotificationsModal from './notificationsModal/NotificationsModal'

const NotificationsPanel = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { message } = useCurrentNotification()

  return (
    <HStack justify="flex-end" spacing="5">
      {message && (
        <MaxWidthText fontSize="md" maxWidth="70%">
          Current User Notification: {message}
        </MaxWidthText>
      )}
      {!message && <Text>No message set</Text>}
      <Button colorScheme="pink" size="sm" onClick={onOpen}>
        Set New Notification
      </Button>
      <NotificationsModal isOpen={isOpen} onClose={onClose} />
    </HStack>
  )
}

export default NotificationsPanel
