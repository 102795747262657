import { Box } from '@chakra-ui/react'
import UploadDropzone from '@common/uploadDropzone/UploadDropzone'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'

import testIds from '../../../playwright/test_ids.json'
import { DeploymentUploadProps } from './DeploymentUpload.types'

const DeploymentUpload = (props: DeploymentUploadProps) => {
  const { setFiles, setFileReady } = props
  const { trackEvent } = useEventTracking()

  return (
    <Box width="75%" m="auto" pt={6}>
      <UploadDropzone
        testId={testIds.datasetUploadPageDropzoneContainer}
        onUpdateFiles={setFiles}
        uploadType="dataset"
        onProcessFile={(error, file) => {
          if (error === null) {
            trackEvent(MixpanelEvents.filepondTestFileReady, {
              fileType: file.fileExtension,
              fileSize: file.fileSize,
              fileName: file.filename,
            })
            setFileReady()
          }
        }}
        maxFileSize="600MB"
        isTestUpload={true}
      />
    </Box>
  )
}

export default DeploymentUpload
