import { EnrichmentProjectRowRes } from '@services/project/constants'

// TODO: This isn't finished. Will tackle an overhaul in another branch
export const queryKeys = {
  enrichmentProjects: {
    all: (props?: { page: number }) => ['enrichmentProjects', ...[props ? [props] : []]] as const,
    id: (projectId: string) => [...queryKeys.enrichmentProjects.all(), projectId] as const,
  },
  jobs: {
    all: () => ['enrichmentJobs'] as const,
    id: (jobId: string) => [...queryKeys.jobs.all(), jobId] as const,
    forProject: (projectId: string) => [...queryKeys.jobs.all(), projectId] as const,
  },
  jobStatus: {
    all: () => ['enrichmentJobStatus'] as const,
    id: (jobId: string) => [...queryKeys.jobStatus.all(), jobId] as const,
  },
}

export type EnrichmentProjectsRes = {
  projects: EnrichmentProjectRowRes[]
  total_count: number
}

export type EnrichmentStatus = 'SUCCEEDED' | 'CREATED' | 'IN_PROGRESS' | 'RUNNING' | 'FAILED'

export type EnrichmentProjectRes = {
  createdAt: string
  datasetId: string
  datasetName: string
  id: string
  name: string
  numRows: number
  status: EnrichmentStatus
  updatedAt: string
  userId: string
}

export const enum EnrichmentJobType {
  PREVIEW = 'PREVIEW',
  ENRICHMENT = 'ENRICHMENT',
}

export type EnrichmentJobDetailType = {
  id: string
  finalResult: string
  log: string | null
  rawResult: string
  rowId: number
  newColumnName: string
  prompt: string
  averageTrustworthinessScore: number
  type: EnrichmentJobType
  status: EnrichmentStatus
  constrainOutputs: string[]
  [key: string]: string | string[] | number | null
}

export type EnrichmentJobRes = Record<string, any>

export type EnrichmentJobStatusRes = {
  status: EnrichmentStatus
  jobId: string
  processedRows: number
  numRows: number
}
