import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import {
  OrgCostUsageRatioRes,
  OrgUsageRes,
  queryKeys,
} from '@services/organizationQuotas/constants'
import { IS_VPC_ENVIRONMENT, REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import camelCaseKeys from '@utils/functions/camelCaseKeys'
import axios from 'axios'
import { useQuery } from 'react-query'

const baseURL = `${REACT_APP_CLEANLAB_API_URL}/api/organization_quotas/`

const vpcAxiosClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})

export const useOrgBudget = (orgId: string) => {
  const { data } = useQuery({
    queryKey: queryKeys.organizationQuotas.orgBudget(orgId),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await vpcAxiosClient.get(orgId, createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Failed to fetch organization budget.' },
    suspense: true,
    enabled: IS_VPC_ENVIRONMENT,
  })
  return data!
}

export const useOrgUsage = () => {
  const { data } = useQuery<OrgUsageRes | null>({
    queryKey: queryKeys.organizationQuotas.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      try {
        const res = await vpcAxiosClient.get('quota_and_usage', createAuthHeaders(accessToken))
        return camelCaseKeys(res.data)
      } catch (e) {
        return { totalCost: null, totalBudgetDollars: null }
      }
    },
    meta: { error: 'Failed to fetch organization usage.' },
    suspense: true,
    enabled: IS_VPC_ENVIRONMENT,
  })
  return data!
}

export const useOrgCostUsageRatio = () => {
  const { data } = useQuery<OrgCostUsageRatioRes>({
    queryKey: queryKeys.organizationQuotas.costUsageRatio(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      try {
        const res = await vpcAxiosClient.get('cost_usage_ratio', createAuthHeaders(accessToken))
        return camelCaseKeys(res.data)
      } catch (e) {
        return { costUsageRatio: null }
      }
    },
    meta: { error: 'Failed to fetch organization cost usage ratio.' },
    suspense: true,
    enabled: IS_VPC_ENVIRONMENT,
  })
  return data ?? { costUsageRatio: 0 }
}
