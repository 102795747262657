import { PresetConfig } from '../Cleanset.types'
import { CLEANLAB_FRONTEND_COLUMN } from '../datasheet/Datasheet.types'

// Constraints for presets across all modalities

const wellLabeledConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    CLEANLAB_FRONTEND_COLUMN.BEST_EXAMPLES,
    CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE, order: 'asc' },
}

const issuesResolvedConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE, order: 'desc' },
}

const labelIssuesConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE, order: 'desc' },
}

const outliersConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    CLEANLAB_FRONTEND_COLUMN.OUTLIER_SCORE,
    CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.TEXT_CHARACTER_LENGTH,
    CLEANLAB_FRONTEND_COLUMN.EMPTY_TEXT,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.OUTLIER_SCORE, order: 'desc' },
}

const unlabeledConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.SUGGESTED_LABEL_CONFIDENCE_SCORE, order: 'desc' },
}

const ambiguousConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS_SCORE,
    CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.OUTLIER_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS_SCORE, order: 'desc' },
}

const nearDuplicateConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE_ID,
    CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE_ID, order: 'asc' },
}

// Constraints for Image Specific Presets

const darkConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.DARK,
    CLEANLAB_FRONTEND_COLUMN.DARK_SCORE,
    CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.DARK_SCORE, order: 'desc' },
}

const lightConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.LIGHT,
    CLEANLAB_FRONTEND_COLUMN.LIGHT_SCORE,
    CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.LIGHT_SCORE, order: 'desc' },
}

const blurryConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.BLURRY,
    CLEANLAB_FRONTEND_COLUMN.BLURRY_SCORE,
    CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.BLURRY_SCORE, order: 'desc' },
}

const oddSizeConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.ODD_SIZE,
    CLEANLAB_FRONTEND_COLUMN.ODD_SIZE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.ODD_SIZE_SCORE, order: 'desc' },
}

const oddAspectRatioConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.ODD_ASPECT_RATIO,
    CLEANLAB_FRONTEND_COLUMN.ODD_ASPECT_RATIO_SCORE,
    CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.ODD_ASPECT_RATIO_SCORE, order: 'desc' },
}

const grayscaleConfig = {
  columns: [CLEANLAB_FRONTEND_COLUMN.GRAYSCALE, CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE, order: 'desc' },
}

const nsfwConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.NSFW,
    CLEANLAB_FRONTEND_COLUMN.NSFW_SCORE,
    CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.NSFW_SCORE, order: 'desc' },
}

const lowInformationConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.LOW_INFORMATION,
    CLEANLAB_FRONTEND_COLUMN.LOW_INFORMATION_SCORE,
    CLEANLAB_FRONTEND_COLUMN.AESTHETIC_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.LOW_INFORMATION_SCORE, order: 'desc' },
}

// Constraints for Text Specific Presets

const piiConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.PII,
    CLEANLAB_FRONTEND_COLUMN.PII_SCORE,
    CLEANLAB_FRONTEND_COLUMN.PII_TYPES,
    CLEANLAB_FRONTEND_COLUMN.PII_ITEMS,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.PII_SCORE, order: 'desc' },
}

const informalConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.INFORMAL,
    CLEANLAB_FRONTEND_COLUMN.INFORMAL_SCORE,
    CLEANLAB_FRONTEND_COLUMN.SPELLING_ISSUE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.GRAMMAR_ISSUE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.SLANG_ISSUE_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.INFORMAL_SCORE, order: 'desc' },
}

const nonEnglishConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.NON_ENGLISH,
    CLEANLAB_FRONTEND_COLUMN.NON_ENGLISH_SCORE,
    CLEANLAB_FRONTEND_COLUMN.PREDICTED_LANGUAGE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.NON_ENGLISH_SCORE, order: 'desc' },
}

const defaultConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS,
  ],
  sort: { column: undefined, order: undefined },
}

const toxicConfig = {
  columns: [CLEANLAB_FRONTEND_COLUMN.TOXIC, CLEANLAB_FRONTEND_COLUMN.TOXIC_SCORE],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.TOXIC_SCORE, order: 'desc' },
}

const biasedConfig = {
  columns: [
    CLEANLAB_FRONTEND_COLUMN.BIASED,
    CLEANLAB_FRONTEND_COLUMN.BIAS_SCORE,
    CLEANLAB_FRONTEND_COLUMN.GENDER_BIAS_SCORE,
    CLEANLAB_FRONTEND_COLUMN.RACIAL_BIAS_SCORE,
    CLEANLAB_FRONTEND_COLUMN.SEXUAL_ORIENTATION_BIAS_SCORE,
  ],
  sort: { column: CLEANLAB_FRONTEND_COLUMN.BIAS_SCORE, order: 'desc' },
}

// Given a filter, return the column configuration for that filter
export const getCleansetViewConfig = (filter: string): PresetConfig => {
  switch (filter) {
    case CLEANLAB_FRONTEND_COLUMN.BEST_EXAMPLES: {
      return wellLabeledConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.ACTION: {
      return issuesResolvedConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.ISSUE: {
      return labelIssuesConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.OUTLIER: {
      return outliersConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.UNLABELED: {
      return unlabeledConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS: {
      return ambiguousConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE: {
      return nearDuplicateConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.LOW_INFORMATION: {
      return lowInformationConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.DARK: {
      return darkConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.LIGHT: {
      return lightConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.BLURRY: {
      return blurryConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.ODD_SIZE: {
      return oddSizeConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.ODD_ASPECT_RATIO: {
      return oddAspectRatioConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.GRAYSCALE: {
      return grayscaleConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.NSFW: {
      return nsfwConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.PII: {
      return piiConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.INFORMAL: {
      return informalConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.NON_ENGLISH: {
      return nonEnglishConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.TOXIC: {
      return toxicConfig
    }
    case CLEANLAB_FRONTEND_COLUMN.BIASED: {
      return biasedConfig
    }
    default:
      return defaultConfig
  }
}
