import { Tooltip } from '@components/tooltip/Tooltip'
import useResizeObserver from '@hooks/useResizeObserver'
import { mergeRefs } from '@react-aria/utils'
import { AsChildProps } from '@utils/AsChildProps'
import { cn } from '@utils/tailwindUtils'
import {
  cloneElement,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { TruncatorContextProvider, useTruncatorContext } from './Truncator.helpers'

const setVisuallyHidden = (element: HTMLElement | null | undefined, hidden: boolean) => {
  if (!element) return

  const props = {
    display: 'block',
    position: 'absolute',
    opacity: '0',
    width: '0',
    height: '0',
    top: '-99999px',
    left: '-99999px',
  }
  if (hidden) {
    Object.entries(props).forEach(([key, value]) => {
      element.style.setProperty(key, value)
    })
  } else {
    Object.keys(props).forEach((key) => {
      element.style.removeProperty(key)
    })
  }
}

const setVisiblities = ({
  containerRef,
  moreIndicatorRef,
  expandable,
  isExpanded,
  setHiddenCount,
}: {
  containerRef: RefObject<HTMLElement>
  moreIndicatorRef: RefObject<HTMLElement>
  expandable: boolean
  isExpanded: boolean
  setHiddenCount: (num: number) => void
}) => {
  const container = containerRef?.current
  const moreIndicator = moreIndicatorRef.current
  if (!container || !moreIndicator) return

  // Show all children elements and hide moreIndicator
  const childrenElts = [...(container.children ?? [])]
    ?.filter((child) => child !== moreIndicator)
    .reverse()
  childrenElts.forEach((child) => setVisuallyHidden(child as HTMLElement, false))
  setVisuallyHidden(moreIndicator, true)

  if (expandable && isExpanded) {
    setHiddenCount(0)
    return
  }

  let numHidden = 0
  const moreIndicatorCounter = moreIndicator.querySelector('[data-more-indicator-count]')
  if (container.clientWidth < (container.scrollWidth ?? 0)) {
    setVisuallyHidden(moreIndicator, false)
    for (const c of childrenElts) {
      ++numHidden
      setVisuallyHidden(c as HTMLElement, true)
      moreIndicatorCounter?.replaceChildren(`${numHidden}`)
      if (container.clientWidth >= container.scrollWidth || numHidden >= childrenElts.length) {
        break
      }
    }
  }
  setHiddenCount(numHidden)
}

const TruncatorBlockHBase = forwardRef(
  (
    {
      className,
      moreIndicator,
      expandable = false,
      asChild,
      children,
      items,
    }: {
      className?: string
      moreIndicator: ReactElement
      expandable?: boolean
      items: { element: ReactElement; label: ReactNode }[]
    } & AsChildProps<ComponentPropsWithoutRef<'ul'>>,
    refProp: ForwardedRef<any>
  ) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const { hiddenCount, setHiddenCount } = useTruncatorContext()
    const containerRef = useRef<HTMLElement>(null)
    const mergedContainerRef = mergeRefs(containerRef, refProp)
    const moreIndicatorRef = useRef<HTMLDivElement>(null)
    const onMoreIndicatorClick = useCallback<MouseEventHandler>(
      (e) => {
        moreIndicator.props.onClick?.(e)
        if (expandable) {
          setIsExpanded(true)
        }
      },
      [expandable, moreIndicator.props]
    )
    useEffect(() => {
      if (!expandable && isExpanded) {
        setIsExpanded(false)
      }
    }, [isExpanded, expandable])
    const setVis = useCallback(
      () =>
        setVisiblities({
          containerRef,
          moreIndicatorRef,
          expandable,
          isExpanded,
          setHiddenCount,
        }),
      [expandable, isExpanded, setHiddenCount, containerRef, moreIndicatorRef]
    )
    useResizeObserver(containerRef, setVis)
    // Remove hidden styles when expandable or isExpanded changes
    useEffect(() => setVis(), [setVis, items, moreIndicator])

    const moreIndicatorClone = cloneElement(moreIndicator, {
      onClick: onMoreIndicatorClick,
      tabIndex: 0,
    })
    const remainder = items.slice(items.length - hiddenCount, items.length)

    return cloneElement(asChild ? children : <div />, {
      ref: mergedContainerRef,
      className: cn(
        'flex w-full items-center',
        !isExpanded ? 'overflow-visible' : 'flex-wrap',
        className
      ),
      children: (
        <>
          {items.map(({ element, label }, i) => cloneElement(element, { key: `${label}--${i}` }))}
          <div ref={moreIndicatorRef} aria-hidden="true" className="contents">
            <Tooltip
              key="---indicator---"
              disabled={!hiddenCount}
              content={
                expandable ? (
                  'Show more'
                ) : (
                  <>
                    {remainder.map(({ label }, i) => (
                      <div key={`${label}--${i}`}>{label}</div>
                    ))}
                  </>
                )
              }
            >
              {moreIndicatorClone}
            </Tooltip>
          </div>
        </>
      ),
    })
  }
)

export const TruncatorBlockH = forwardRef(
  (
    props: ComponentPropsWithoutRef<typeof TruncatorBlockHBase>,
    ref: ComponentPropsWithRef<typeof TruncatorBlockHBase>['ref']
  ) => {
    return (
      <TruncatorContextProvider>
        <TruncatorBlockHBase {...props} ref={ref} />
      </TruncatorContextProvider>
    )
  }
)
