import {
  Box,
  Divider,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Link from '@components/link/Link'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { MdCheckCircle, MdInfo } from 'react-icons/md'

const PlanInfo = () => {
  const {
    pricePerMonth,
    freeProjectsMonthlyLimit,
    freeModelsMonthlyLimit,
    freeCleansetExportRowsMonthlyLimit,
    freeInferenceExportRowsMonthlyLimit,
    planName,
    isTlmPlan,
    setIsTlmPricingModalOpen,
    onUpgradePlanModalClose,
  } = useSubscription()

  const { trackEvent } = useEventTracking()

  const dividerColor = useColorModeValue('gray.200', 'gray.500')

  return (
    <Box>
      <Text fontSize="lg">{planName}</Text>
      <Text fontWeight="semibold" fontSize="4xl">
        {isTlmPlan ? <>Pay by usage</> : <>${pricePerMonth} per month</>}
      </Text>
      {!isTlmPlan ? (
        <>
          <Text fontSize="md">
            Includes {freeCleansetExportRowsMonthlyLimit} free Cleanset row exports per month
          </Text>
          <Divider my="1rem" borderColor={dividerColor} orientation="horizontal" />
          <Text fontWeight="bold">Plan includes:</Text>
          <List pt={3} spacing={3}>
            <ListItem>
              <ListIcon boxSize={5} as={MdCheckCircle} color="green.500" />
              {freeProjectsMonthlyLimit} free Projects per month
            </ListItem>
            <ListItem>
              <ListIcon boxSize={5} as={MdCheckCircle} color="green.500" />
              {freeModelsMonthlyLimit} free model deployments per month
            </ListItem>
            <ListItem>
              <ListIcon boxSize={5} as={MdCheckCircle} color="green.500" />
              {freeInferenceExportRowsMonthlyLimit} free inference rows export per month
            </ListItem>
            <ListItem>
              <HStack gap={0}>
                <ListIcon boxSize={5} as={MdInfo} color="blue.500" />
                <Text fontSize="sm" bg="transparent" m={0} p={0}>
                  Need more from your plan? Contact{' '}
                  <Link
                    href="mailto:sales@cleanlab.ai"
                    isExternal
                    onClick={() => trackEvent(MixpanelEvents.clickContactUsButton)}
                  >
                    sales@cleanlab.ai
                  </Link>
                </Text>
              </HStack>
            </ListItem>
          </List>
        </>
      ) : (
        <LinkBlock
          onClick={() => {
            onUpgradePlanModalClose()
            setIsTlmPricingModalOpen(true)
          }}
        >
          View detailed pricing
        </LinkBlock>
      )}
    </Box>
  )
}

export default PlanInfo
