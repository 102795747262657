import { losslessNumberParser } from '@utils/functions/losslessNumberParser'
import { LoadSuccessParams } from 'ag-grid-community'
import { AxiosResponseHeaders } from 'axios'

export const DEFAULT_STALE_TIME_MS = 1000 * 10 // 10 seconds

// TODO: Support enrichment response as well
export const convertDatasetApiResponseToAgGridFormat = (input: {
  rows: Record<string, any>[]
  row_count: number
}): LoadSuccessParams => ({
  rowData: input.rows ?? [],
  rowCount: input.row_count ?? 0,
})

export const cleanlabTransformResponse = (response: any, headers: AxiosResponseHeaders) => {
  if (headers['content-type'] === 'application/json') {
    return losslessNumberParser(response)
  }
}

export const triggerFileDownload = (data: string, filename: string) => {
  const objectUrl = window.URL.createObjectURL(new Blob([data]))
  const a = document.createElement('a')

  a.style.display = 'none'
  a.href = objectUrl
  a.download = filename
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(objectUrl)
  a.remove()
}
