import {
  Button,
  Container,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useClipboard,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import CopySnippet from '@common/misc/copySnippet/CopySnippetV1'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useApiKey } from '@services/commandLine/queries'
import { FiArrowUpRight } from 'react-icons/fi'

const TlmPage = () => {
  const textStrong = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const textFaint = useColorModeValue('neutral.700', 'neutralDarkMode.700')
  const { apiKey } = useApiKey()
  const { onCopy } = useClipboard(apiKey)
  const { trackEvent } = useEventTracking()
  return (
    <Container px="10%" mt="32px">
      <VStack alignItems="flex-start" spacing="24px">
        <Text fontSize="32px" fontWeight="600" lineHeight="40px" color={textStrong}>
          Trustworthy Language Model (TLM)
        </Text>
        <Text pt="8px" fontSize="18px" lineHeight="28px" color={textFaint}>
          Cleanlab TLM is a more reliable LLM that gives high-quality outputs and indicates when it
          is unsure of the answer to a question, making it suitable for applications where unchecked
          hallucinations are a show-stopper. You can find your API key below. Click the Tutorials
          button to get started today!
        </Text>
        <HStack w="100%" alignItems="flex-end" spacing="12px">
          <CopySnippet
            label="TLM API key"
            snippet={apiKey}
            canHide={true}
            isExternalCopy
            trackCopyEvent={() => trackEvent(MixpanelEvents.clickApiKeyCopy)}
          />
          <Popover placement="top">
            <PopoverTrigger>
              <span>
                <Button px="20px" variant="tlm-action-button" onClick={onCopy}>
                  Copy
                </Button>
              </span>
            </PopoverTrigger>
            <PopoverContent
              borderRadius="4px"
              color={useColorModeValue('neutral.0', '#000')}
              bg={useColorModeValue('neutral.900', '#FFF')}
              borderColor={useColorModeValue('neutral.900', '#FFF')}
              w="min"
              h="28px"
              alignItems="center"
              justifyContent="center"
            >
              <PopoverBody fontSize="xs">Copied!</PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </VStack>
      <HStack mt="44px" spacing="24px">
        <Button
          rightIcon={<FiArrowUpRight size={16} />}
          variant="tlm-action-button"
          onClick={() => {
            window.open('https://help.cleanlab.ai/tutorials/tlm/', '_blank')
          }}
        >
          Tutorials
        </Button>
        <Button
          rightIcon={<FiArrowUpRight size={16} />}
          variant="tlm-action-button"
          onClick={() => {
            window.open('https://tlm.cleanlab.ai', '_blank')
          }}
        >
          Playground
        </Button>
      </HStack>
    </Container>
  )
}

export default TlmPage
