import { IconX } from '@components/icons'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { TemplateProjectPreview } from '@services/templateProjects/constants'
import { useState } from 'react'

import { ProjectDemoSelector } from '../../../common/projectDemos/ProjectDemoSelector'
import { RemoveDashboardProjectsModal } from './RemoveDashboardProjectsModal'

export const DashboardProjectDemos = ({ templates }: { templates?: TemplateProjectPreview[] }) => {
  const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false)

  if (!templates || templates.length === 0) {
    return null
  }
  return (
    <div className="rounded-3 bg-surface-1 p-7 outline outline-1 -outline-offset-[1px] outline-border-1">
      <div className="flex flex-col gap-6">
        <div className="flex w-full items-center justify-between gap-4">
          <h3 className="type-display-50 text-text-strong">Get started with a demo Project</h3>
          <LinkBlock size="small" variant="secondary" padded={false} iconEnd={<IconX />} asChild>
            <button
              onClick={() => {
                setRemoveModalIsOpen(true)
              }}
            >
              Dismiss
            </button>
          </LinkBlock>
        </div>
        <ProjectDemoSelector variant="dashboard" templates={templates} />
      </div>
      <RemoveDashboardProjectsModal
        isOpen={removeModalIsOpen}
        onClose={() => setRemoveModalIsOpen(false)}
      />
    </div>
  )
}
