import { ChevronRightIcon } from '@chakra-ui/icons'
import { HStack, Image, Text, useColorModeValue, useToast, VStack } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useCardDetails } from '@services/billing/queries'
import React, { useEffect } from 'react'

import { getCreditCardImage } from './CardDetails.helpers'
import { CardDetailsProps } from './CardDetails.types'

const CardDetails = (props: CardDetailsProps) => {
  const { setCollectPayment, setHasCard, setIsAddingCard, setCollectCardDetails } = props
  const { trackEvent } = useEventTracking()
  const toast = useToast()

  const borderColor = useColorModeValue('gray.200', 'gray.600')

  const cardDetails = useCardDetails()

  useEffect(() => {
    if (setHasCard && cardDetails && cardDetails.last4) {
      setHasCard(true)
    }
  }, [toast, setHasCard, cardDetails])

  return (
    <HStack
      py={3}
      px={5}
      justify="space-between"
      cursor="pointer"
      fontSize="sm"
      border="1px solid"
      borderRadius="md"
      borderColor={borderColor}
      w="100%"
      onClick={() => {
        trackEvent(MixpanelEvents.clickEditCardDetailsButton)
        if (setIsAddingCard) {
          setIsAddingCard(true)
        }
        setCollectCardDetails?.(true)
        setCollectPayment?.(true)
      }}
    >
      {cardDetails && Object.keys(cardDetails).length > 0 ? (
        <HStack spacing={4}>
          <Image
            borderRadius="md"
            src={getCreditCardImage(cardDetails.cardBrand)}
            alt=""
            w="50px"
          />
          <VStack spacing={0} alignItems="flex-start">
            <Text fontWeight="bold">
              {(cardDetails.cardBrand ?? []).slice(0, 1).toUpperCase() +
                cardDetails.cardBrand.slice(1)}{' '}
              ••••{cardDetails.last4}
            </Text>
            <Text fontWeight="200" fontSize="sm">
              {cardDetails.name}
            </Text>
            <Text fontWeight="200" fontSize="sm">
              Expires {cardDetails.expMonth}/{cardDetails.expYear}
            </Text>
          </VStack>
        </HStack>
      ) : (
        <Text fontSize="md" fontStyle="italic">
          No payment options set up.
        </Text>
      )}
      <ChevronRightIcon boxSize="6" color={borderColor} />
    </HStack>
  )
}

export default CardDetails
