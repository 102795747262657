import {
  Box,
  Circle,
  createIcon,
  Flex,
  Icon,
  Stack,
  useColorModeValue,
  useId,
  useRadio,
  useStyleConfig,
} from '@chakra-ui/react'
import { RadioCardProps } from '@common/forms/radioCard/RadioCard.types'
import React from 'react'

export const RadioCard = React.forwardRef((props: RadioCardProps, ref) => {
  const {
    radioProps,
    children,
    noCircle = false,
    isDisabled = false,
    transparentBg = false,
    ...rest
  } = props
  const { getInputProps, getCheckboxProps, getLabelProps, state } = useRadio(radioProps)
  const id = useId(undefined, 'radio-button')
  const disabledBgColor = useColorModeValue('#D8DBE5', '#333') // needs a hex value
  const styles = useStyleConfig('RadioCard', props)
  const inputProps = getInputProps()
  const checkboxProps = getCheckboxProps()
  const labelProps = getLabelProps()

  const manualStyles: React.CSSProperties = {}
  if (transparentBg) {
    manualStyles.background = 'transparent'
  }
  if (isDisabled) {
    manualStyles.background = disabledBgColor
  }
  return (
    <Box ref={ref} sx={styles} {...checkboxProps} {...rest} style={manualStyles}>
      <Box
        id={id}
        as="label"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        {...labelProps}
        sx={{
          '.focus-visible + [data-focus]': {
            boxShadow: 'outline',
            zIndex: 1,
          },
        }}
        w="100%"
        h="100%"
      >
        <input {...inputProps} aria-labelledby={id} disabled={isDisabled} />
        <Stack direction="row" height="100%">
          <Flex direction="column" flex="1" justifyContent="space-between">
            {children}
          </Flex>
          {!noCircle && !isDisabled && (
            <>
              {state.isChecked ? (
                <Circle bg="accent" size="4">
                  <Icon as={CheckIcon} boxSize="2.5" color="inverted" />
                </Circle>
              ) : (
                <Circle borderWidth="2px" size="4" />
              )}
            </>
          )}
        </Stack>
      </Box>
    </Box>
  )
})

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 12 10',
  path: (
    <polyline
      fill="none"
      strokeWidth="2px"
      stroke="currentColor"
      strokeDasharray="16px"
      points="1.5 6 4.5 9 10.5 1"
    />
  ),
})
