import { Flex, HStack, Icon, Kbd, Text, useColorModeValue } from '@chakra-ui/react'
import SegmentedButtonGroup from '@common/commandPalette/segmentedButtonGroup/SegmentedButtonGroup'
import { useEffect, useRef } from 'react'
import { FiAlertCircle, FiCheck } from 'react-icons/fi'

import { MultiLabelRowVariationProps } from '../MultiLabelRow.types'

const WideMultiLabelRow = (props: MultiLabelRowVariationProps) => {
  const {
    label,
    resolverWidth,
    showSmallView,
    setShowSmallView,
    setResolverWidthThreshold,
    isFetchingRows,
    isError,
    isResolved,
    isUpdated,
    trueProbability,
    falseProbability,
    shortcut,
    isInSuggestedString,
    trueSelected,
    onTrueClick,
    onFalseClick,
  } = props

  const labelTextRef = useRef<HTMLDivElement>(null)

  const warningBorderColor = useColorModeValue('orange.500', 'orange.400')
  const warningIconColor = useColorModeValue('orange.600', 'orange.300')
  const successIconColor = useColorModeValue('green.600', 'green.300')
  const defaultBorderColor = useColorModeValue('gray.300', 'gray.600')
  const updatedColor = useColorModeValue('gray.700', 'gray.200')

  useEffect(() => {
    if (labelTextRef.current?.clientWidth) {
      if (!showSmallView && labelTextRef.current?.clientWidth < 15) {
        setShowSmallView(true)
        // Since label length can vary greatly, record resolver width in which
        // we snap to the smaller width view so that we can snap back when width is greater
        setResolverWidthThreshold(resolverWidth)
      }
    }
  }, [showSmallView, resolverWidth, setShowSmallView, setResolverWidthThreshold])

  return (
    <Flex
      border="1px solid"
      borderColor={defaultBorderColor}
      borderRadius="md"
      justify="space-between"
      p={3}
      w="100%"
      overflowWrap="anywhere"
      flexDir="row"
    >
      <Flex align="center" flexDir="row" gap={2}>
        {shortcut && (
          <Kbd boxSize="20px" textAlign="center" fontSize="xs">
            {shortcut}
          </Kbd>
        )}
        <Text
          ref={labelTextRef}
          pb={0}
          overflowWrap="anywhere"
          noOfLines={2}
          fontSize="xs"
          w="fit-content"
        >
          {label}
        </Text>
      </Flex>
      <Flex flexDir="row" align="center" gap={2}>
        {isError ? (
          <HStack gap="4px" w="150px" pl={5}>
            <Icon
              boxSize="14px"
              color={isResolved ? successIconColor : warningIconColor}
              as={isResolved ? FiCheck : FiAlertCircle}
            />
            <Text
              overflow="visible"
              noOfLines={1}
              whiteSpace="nowrap"
              variant={isResolved ? 'success' : 'warning'}
            >
              {isResolved ? 'Resolved' : `Suggested: ${isInSuggestedString}`}
            </Text>
          </HStack>
        ) : (
          isUpdated && (
            <HStack gap="4px" w="150px" fontSize="xs" pl={5}>
              <Icon boxSize="14px" color={updatedColor} as={FiCheck} />
              <Text overflow="visible" noOfLines={1} whiteSpace="nowrap" color={updatedColor}>
                Updated
              </Text>
            </HStack>
          )
        )}
        <SegmentedButtonGroup
          borderColor={isError && !isResolved ? warningBorderColor : defaultBorderColor}
          isButtonLeftActive={trueSelected}
          isDisabled={isFetchingRows}
          onLeftButtonClick={onTrueClick}
          onRightButtonClick={onFalseClick}
          buttonLeftText={`True ${trueProbability}%`}
          buttonRightText={`False ${falseProbability}%`}
          width="154px"
        />
      </Flex>
    </Flex>
  )
}

export default WideMultiLabelRow
