import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { useColorMode } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { BarDatum, ComputedDatum, ResponsiveBar } from '@nivo/bar'
import * as React from 'react'
import { useContext } from 'react'
import { CleansetContext } from 'src/pages/cleanset/CleansetContext'

import {
  BASE_FONT_SIZE,
  calculateChartMarginLeftAndLeftLegendOffset,
  CHART_BAR_BORDER_RADIUS,
  CHART_BASE_MARGINS,
  CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
  getChartTheme,
  getLabelTextColor,
  returnLongestLabelLength,
} from '../CleansetCharts.helpers'
import { LabelCountsChartProps } from './LabelCountsChart.types'

const LabelCountsChart = (props: LabelCountsChartProps) => {
  const { colorMode } = useColorMode()
  const {
    data,
    keys,
    indexBy,
    leftTitle,
    barClickEvent,
    onClick,
    onClose,
    colors,
    showPercentage,
  } = props

  const labelTruncateLimit = 20
  const longestLabelLength = Math.min(
    labelTruncateLimit,
    returnLongestLabelLength(data.map((v) => v[indexBy] as string))
  )
  const longestKeyLength = returnLongestLabelLength(keys)
  const [chartMarginLeft, chartLeftLabelOffset] =
    calculateChartMarginLeftAndLeftLegendOffset(longestLabelLength)
  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()

  const trackClickEvent = (bar: ComputedDatum<BarDatum>) => {
    const { indexValue, id, value } = bar
    const barData = {
      givenLabel: indexValue,
      barLabel: id,
      exampleCount: value,
    }

    if (barClickEvent) {
      trackEvent(barClickEvent, { ...cleansetInfo, ...barData })
    }

    if (onClick) {
      onClick(bar)
      onClose()
    }
  }

  return (
    <ResponsiveBar
      data={data}
      valueFormat={(value) => (showPercentage ? `${value}%` : `${value}`)}
      keys={keys}
      indexBy={indexBy}
      groupMode="stacked"
      margin={{
        ...CHART_BASE_MARGINS,
        bottom: CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
        left: chartMarginLeft,
        // TODO: sort of a magic number. Determine this more intelligently...
        // ... and tie it to the right legend's translateX value below
        right: 100,
      }}
      padding={0.25}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={colors}
      colorBy="id"
      borderRadius={CHART_BAR_BORDER_RADIUS}
      axisLeft={{
        legend: leftTitle,
        legendPosition: 'middle',
        legendOffset: chartLeftLabelOffset,
        tickSize: 0,
        format: (value) => {
          const valueString = String(value)

          return `${valueString.substring(0, labelTruncateLimit)}${valueString.length > labelTruncateLimit ? '...' : ''}`
        },
      }}
      onClick={trackClickEvent}
      enableGridY={false}
      enableGridX={true}
      labelSkipWidth={BASE_FONT_SIZE + 10}
      labelSkipHeight={BASE_FONT_SIZE}
      labelTextColor={getLabelTextColor(colorMode === 'light')}
      innerPadding={1}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'right',
          direction: 'column',
          justify: false,
          padding: 2,
          translateX: longestKeyLength > 7 ? 105 : 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          itemTextColor:
            colorMode === 'light'
              ? cleanlabColors.neutral[700]
              : cleanlabColors.neutralDarkMode[900],
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      theme={getChartTheme(colorMode === 'light')}
    />
  )
}

export default LabelCountsChart
