import { useColorMode } from '@chakra-ui/react'
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import React, { useMemo } from 'react'

import {
  BASE_FONT_SIZE,
  calculateChartMarginLeftAndLeftLegendOffset,
  CHART_BASE_MARGINS,
  CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
  getChartTheme,
  getLabelTextColor,
  returnLongestLabelLength,
} from '../CleansetCharts.helpers'
import { LabeledDataChartProps } from './LabeledDataChart.types'

const LabeledDataChart = (props: LabeledDataChartProps) => {
  const { colorMode } = useColorMode()
  const { leftTitle, data, onClick, showPercentage } = props

  const getLongestWordFromData = returnLongestLabelLength(
    data.map((item: BarDatum) => item.label as string)
  )
  const [chartMarginLeft, chartLeftLegendOffset] =
    calculateChartMarginLeftAndLeftLegendOffset(getLongestWordFromData)

  const descendingSortedData = useMemo(() => [...data].reverse(), [data])

  return (
    <ResponsiveBar
      valueFormat={(value) => (showPercentage ? `${value}%` : `${value}`)}
      data={descendingSortedData}
      keys={['count']}
      indexBy="label"
      groupMode="stacked"
      margin={{
        ...CHART_BASE_MARGINS,
        left: chartMarginLeft,
        bottom: CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
      }}
      padding={0.25}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#0E787A']}
      borderRadius={6}
      axisLeft={{
        legend: leftTitle,
        legendPosition: 'middle',
        legendOffset: chartLeftLegendOffset,
        tickSize: 0,
      }}
      onClick={onClick}
      enableGridY={false}
      enableGridX={true}
      labelSkipWidth={BASE_FONT_SIZE}
      labelSkipHeight={BASE_FONT_SIZE}
      labelTextColor={getLabelTextColor(colorMode === 'light')}
      theme={getChartTheme(colorMode === 'light')}
    />
  )
}

export default LabeledDataChart
