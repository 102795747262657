import { useToast } from '@chakra-ui/react'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import * as Sentry from '@sentry/browser'
import { Mixpanel } from '@services/analytics/Mixpanel'
import userEventService from '@services/userEventApi'
import { IS_PROD_ENV, IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { AxiosError } from 'axios'

export const useEventTracking = () => {
  const toast = useToast()
  const trackEvent = async (eventName: string, eventInfo?: object, sensitiveEventInfo?: object) => {
    try {
      const response = await userEventService.trackUserEvent(
        eventName,
        eventInfo,
        sensitiveEventInfo
      )
      if (IS_PROD_ENV && !IS_VPC_ENVIRONMENT) {
        // Send same event to Mixpanel
        const userId = response.user_id
        Mixpanel.identify(userId)
        Mixpanel.track(eventName, { ...eventInfo, userId })
      }
    } catch (err) {
      if (IS_PROD_ENV) {
        Sentry.captureException(`Error tracking user event: ${eventName}. Error: ${err}`)
      } else {
        notifyAxiosError(toast, err as AxiosError)
      }
    }
  }
  const setUserProperties = async (userId: string, name: string, email: string) => {
    try {
      if (!IS_VPC_ENVIRONMENT) {
        Mixpanel.people.set({
          $distinct_id: userId,
          $user_id: userId,
          $name: name,
          $email: email,
          $group: 'Customer',
        })
      }
    } catch (err) {
      if (IS_PROD_ENV && !IS_VPC_ENVIRONMENT) {
        Sentry.captureException(`Error setting properties for user: ${userId}`)
      } else {
        notifyAxiosError(toast, err as AxiosError)
      }
    }
  }
  return { trackEvent, setUserProperties }
}
