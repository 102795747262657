import { useMemo } from 'react'
import { UIMatch, useMatches } from 'react-router-dom'

import { LoaderData, LoaderGlobals } from './dataLoaderJsonResponse'

export const useDataLoaderGlobals = () => {
  const matches = useMatches() as UIMatch<LoaderData>[]
  return useMemo(() => {
    let globals: LoaderGlobals = {}
    matches?.forEach?.((match) => {
      globals = { ...globals, ...(match?.data?.globals ?? {}) }
    })
    return globals
  }, [matches])
}
