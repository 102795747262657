import './index.css'

import { ColorModeScript } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import {
  IS_PROD_ENV,
  IS_VPC_ENVIRONMENT,
  REACT_APP_CLEANLAB_API_URL,
} from '@utils/environmentVariables'
import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

// Performance and Exception Monitoring with Sentry
IS_PROD_ENV &&
  !IS_VPC_ENVIRONMENT &&
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    // TODO: Make an ignoreErrors const or file if this list grows much more
    ignoreErrors: [
      // https://github.com/getsentry/sentry-javascript/issues/3440
      'Non-Error promise rejection captured with value: Object Not Found Matching Id',
    ],
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: import.meta.env.REACT_APP_ENV_SETTINGS,
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(import.meta.env.REACT_APP_TRACE_SAMPLE_RATE),
    tracePropagationTargets: ['localhost', 'api.cleanlab.ai', 'app.cleanlab.ai', /^\//],
    tunnel: `${REACT_APP_CLEANLAB_API_URL}/api/sentry_api/`,
  })

const container = document.getElementById('root')
// eslint-disable-next-line
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>
)

// Performance Monitoring
reportWebVitals()
