import { SuppressKeyboardEventParams } from 'ag-grid-community'

const GRID_CELL_CLASSNAME = 'ag-cell'

function getAllFocusableElementsOf(el: HTMLElement) {
  return Array.from<HTMLElement>(
    el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
  ).filter((focusableEl) => {
    return focusableEl.tabIndex !== -1
  })
}

const getEventPath: (event: Event) => HTMLElement[] = (event: Event) => {
  const path: HTMLElement[] = []
  let currentTarget: any = event.target
  while (currentTarget) {
    path.push(currentTarget)
    currentTarget = currentTarget.parentElement
  }
  return path
}

/**
 * Pass to `suppressKeyboardEvent` on a `ColDef` to enable keyboard navigation
 * within an ag-grid cell.
 */
export function tabWithinCell({ event, ...props }: SuppressKeyboardEventParams<any>) {
  const { key, shiftKey } = event
  const path = getEventPath(event)
  const isTabForward = key === 'Tab' && shiftKey === false
  const isTabBackward = key === 'Tab' && shiftKey === true

  // Suppress editing cell when one of its children is focused
  if (key === 'Enter') {
    const eGridCell = path.find((el) => {
      if (el.classList === undefined) return false
      return el.classList.contains(GRID_CELL_CLASSNAME)
    })
    const hasFocusWithin = eGridCell?.matches(':focus-within')
    if (document.activeElement !== eGridCell && hasFocusWithin) {
      return true
    }
    return false
  }

  // Handle cell children tabbing
  if (isTabForward || isTabBackward) {
    const eGridCell = path.find((el) => {
      if (el.classList === undefined) return false
      return el.classList.contains(GRID_CELL_CLASSNAME)
    })

    if (!eGridCell) {
      return false
    }

    const focusableChildrenElements = getAllFocusableElementsOf(eGridCell)
    const lastCellChildEl = focusableChildrenElements[focusableChildrenElements.length - 1]
    const firstCellChildEl = focusableChildrenElements[0]

    // Suppress keyboard event if tabbing forward within the cell and the
    // current focused element is not the last child
    if (focusableChildrenElements.length === 0) {
      return false
    }

    const currentIndex = focusableChildrenElements.indexOf(document.activeElement as HTMLElement)

    if (isTabForward) {
      const isLastChildFocused = lastCellChildEl && document.activeElement === lastCellChildEl

      if (!isLastChildFocused) {
        if (currentIndex !== -1 || document.activeElement === eGridCell) {
          event.preventDefault()
          focusableChildrenElements[currentIndex + 1].focus()
        }
        return true
      }
      // Ugly hack to prevent tab focus from getting stuck in the last cell of
      // the table. Set tabIndex to -1 for all focusable children so ag-grid
      // won't set focus back the first focusable child, but restore it on the
      // next frame after ag-grid has handled the next focus event.
      const rowCount = props.api.getDisplayedRowCount()
      const isLastRow = props.node.rowIndex === rowCount - 1
      if (isLastRow) {
        focusableChildrenElements.forEach((el) => {
          const tabIndex = 'tabIndex'
          const originalTabIndex = el?.getAttribute(tabIndex)
          el?.setAttribute(tabIndex, '-1')
          window.requestAnimationFrame(() => {
            if (originalTabIndex === null || originalTabIndex === undefined) {
              el.removeAttribute(tabIndex)
            } else {
              el?.setAttribute(tabIndex, originalTabIndex)
            }
          })
        })
      }
      return false
    }
    // Suppress keyboard event if tabbing backwards within the cell, and the
    // current focused element is not the first child
    else {
      const cellHasFocusedChildren =
        eGridCell.contains(document.activeElement) && eGridCell !== document.activeElement

      // Manually set focus to the last child element if cell doesn't have focused children
      if (!cellHasFocusedChildren) {
        lastCellChildEl.focus()
        // Cancel keyboard press, so that it doesn't focus on the last child and then pass through the keyboard press to
        // move to the 2nd last child element
        event.preventDefault()

        return true
      }

      const isFirstChildFocused = firstCellChildEl && document.activeElement === firstCellChildEl
      if (!isFirstChildFocused) {
        if (currentIndex !== -1 || document.activeElement === eGridCell) {
          event.preventDefault()
          focusableChildrenElements[currentIndex - 1].focus()
        }
        return true
      }
    }
  }

  return false
}
