import { CreateToastFnReturn } from '@chakra-ui/react'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { enrichmentJobFullEnrichmentQueryFn } from '@services/enrichmentApi'
import logger from '@utils/logger'
import { LoadSuccessParams } from 'ag-grid-community'
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { AxiosError } from 'axios'
import {
  joinDatasetDetailsAndJobDetails,
  NormalizedEnrichmentJobPreviewData,
} from 'src/pages/enrichmentProject/enrichmentUtils'

export interface ServerType {
  getData: (cleansetId: string, request: IServerSideGetRowsRequest) => Promise<LoadSuccessParams>
}

// TODO: turn this into a hook so we can rely on other hooks/react-queries so we don't have to pass so many props in
// create the server side datasource for the table
export const createServerSideDatasource = ({
  datasetDetailsServer,
  cleansetId,
  toast,
  setInitialized,
  setIsFetchingRows,
  enrichmentData,
  isFullyEnriched,
  mostRecentJobId,
}: {
  datasetDetailsServer: ServerType
  cleansetId: string
  toast: CreateToastFnReturn
  setInitialized: VoidFunction
  setIsFetchingRows?: (isFetchingRows: boolean) => void
  enrichmentData?: NormalizedEnrichmentJobPreviewData[]
  isFullyEnriched?: boolean
  mostRecentJobId?: string
}): IServerSideDatasource => {
  logger.info('creating server side datasource from', datasetDetailsServer)

  return {
    getRows: function (params: IServerSideGetRowsParams) {
      logger.info(
        '[Datasource] - rows requested by grid: startRow = ' +
          params.request.startRow +
          ', endRow = ' +
          params.request.endRow
      )
      const fetchData = async () => {
        let datasetDetails: LoadSuccessParams

        setIsFetchingRows && setIsFetchingRows(true)

        try {
          if (isFullyEnriched && mostRecentJobId) {
            datasetDetails = (await enrichmentJobFullEnrichmentQueryFn(mostRecentJobId)) ?? []
          } else {
            datasetDetails = await datasetDetailsServer.getData(cleansetId, params.request)

            if (enrichmentData) {
              datasetDetails = joinDatasetDetailsAndJobDetails(datasetDetails, enrichmentData)
            }
          }

          params.success(datasetDetails)

          setInitialized()
        } catch (err) {
          console.error('createServerSideDataSource getData() failed', err)
          notifyAxiosError(toast, err as AxiosError)
          params.fail()
        }
        setIsFetchingRows && setIsFetchingRows(false)
      }
      fetchData()
    },
  }
}
