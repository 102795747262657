import { FilterOperator } from '../../filterReducer/FilterState.types'
import { FilterSymbol } from './FilterButton.types'

const getFilterSymbol = (filter: FilterOperator) => {
  switch (filter) {
    case FilterOperator.Equals: {
      return FilterSymbol.EQUALS
    }
    case FilterOperator.NotEquals: {
      return FilterSymbol.NOT_EQUAL
    }
    case FilterOperator.LessThan: {
      return FilterSymbol.LESS_THAN
    }
    case FilterOperator.LessThanOrEqual: {
      return FilterSymbol.LESS_THAN_OR_EQUAL
    }
    case FilterOperator.GreaterThanOrEqual: {
      return FilterSymbol.GREATER_THAN_OR_EQUAL
    }
    case FilterOperator.GreaterThan: {
      return FilterSymbol.GREATER_THAN
    }
    case FilterOperator.Contains: {
      return FilterSymbol.CONTAINS
    }
    case FilterOperator.NotContains: {
      return FilterSymbol.NOT_CONTAINS
    }
    case FilterOperator.StartsWith: {
      return FilterSymbol.STARTS_WITH
    }
    case FilterOperator.EndsWith: {
      return FilterSymbol.ENDS_WITH
    }
    case FilterOperator.Set: {
      return FilterSymbol.SET
    }
    default: {
      return FilterSymbol.ALL
    }
  }
}

export { getFilterSymbol }
