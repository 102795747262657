import { cleanlabColors } from '@assets/styles/CleanlabColors'
import globalStyles from '@assets/styles/global.module.css'
import { Flex, Heading, Text, Tooltip, useColorModeValue, VStack } from '@chakra-ui/react'
import React from 'react'
import { FiInfo } from 'react-icons/fi'

import chartStyles from '../CleansetCharts.module.css'
import { ChartCardContentProps } from './ChartCardContent.types'

export const ChartCardContent = (props: ChartCardContentProps) => {
  const { children, title, bottomLegendTitle, description, isInModal = false, chartHeight } = props
  const tooltipTextColor = useColorModeValue(
    cleanlabColors.neutral[0],
    cleanlabColors.neutralDarkMode[900]
  )
  const tooltipBackground = useColorModeValue(
    cleanlabColors.neutral[900],
    cleanlabColors.neutralDarkMode[100]
  )
  const titleAndLegendColor = useColorModeValue(
    cleanlabColors.neutral[900],
    cleanlabColors.neutralDarkMode[900]
  )

  return (
    <VStack
      className={chartStyles.chartSectionContentContainer}
      gap="1rem"
      minHeight={isInModal ? '60vh' : 'auto'}
      height="100%"
      width="100%"
    >
      <Flex gap="0.5rem" justifyContent="center" alignItems="center">
        <Heading
          fontSize="md"
          lineHeight="150%"
          color={titleAndLegendColor}
          className={chartStyles.chartSectionTitle}
        >
          {title}
        </Heading>

        {!isInModal && (
          // Tooltip won't take a className so surrounding it with this div
          <div className={globalStyles.screenOnly}>
            <Tooltip
              hasArrow
              label={description}
              placement="top"
              fontSize="md"
              padding="10px 29px"
              color={tooltipTextColor}
              bg={tooltipBackground}
              boxShadow="0px 2px 6px 0px #17191c1a, 0px 1px 2px -1px #17191c14"
            >
              <div>
                <FiInfo size="1rem" />
              </div>
            </Tooltip>
          </div>
        )}
      </Flex>

      <Text
        className={`${chartStyles.chartSectionDescription} ${
          isInModal ? chartStyles.chartSectionDescriptionInModal : ''
        }`}
      >
        {description}
      </Text>

      <VStack
        as="figure"
        justifyContent="space-between"
        gap="1rem"
        minHeight="inherit"
        height="100%"
        width="100%"
        padding="1rem 0 0"
      >
        <Flex
          width="100%"
          minHeight="inherit"
          height={chartHeight ? chartHeight : '100%'}
          aspectRatio="3/2"
          sx={{
            '& > div': {
              minHeight: 'inherit',
            },
          }}
        >
          {children}
        </Flex>

        {bottomLegendTitle && (
          <Text fontSize={12} textAlign="center" fontWeight={600} color={titleAndLegendColor}>
            {bottomLegendTitle}
          </Text>
        )}
      </VStack>
    </VStack>
  )
}
