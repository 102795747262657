import RainbowSpinner from '@components/rainbowSpinner/RainbowSpinner'
import { cn } from '@utils/tailwindUtils'
import { Link, useNavigate } from 'react-router-dom'

import { getProjectStatusDescription } from './ProjectStatusCellComponent.helpers'
import { ProjectStatusCellComponentProps } from './ProjectStatusCellComponent.types'

const ProjectStatusCellComponent = ({
  isReady,
  hasError = true,
  errorMessage,
  errorType,
  progressDescription,
  onOpenProjectErrorModal,
  onOpenNonViewableProject,
  isViewable,
  onErrorButtonRoute,
  onErrorButtonClick,
  requiredColumnsReady,
  to,
}: ProjectStatusCellComponentProps) => {
  const navigate = useNavigate()
  const baseClassName = 'truncate focus-visible:outline-none'
  if (isReady && isViewable && to) {
    return (
      <Link
        className={cn(
          baseClassName,
          'text-blue-700 hover:text-blue-800 hover:underline focus-visible:underline'
        )}
        to={to ?? ''}
      >
        Ready
      </Link>
    )
  } else if (isReady && !isViewable) {
    return (
      <button
        className="flex cursor-pointer items-center gap-3 text-red-600 underline"
        onClick={onOpenNonViewableProject}
      >
        Not viewable
      </button>
    )
  } else if (hasError) {
    return (
      <div
        className="flex cursor-pointer items-center gap-3 text-red-600 underline"
        onClick={() => {
          if (errorMessage && onOpenProjectErrorModal) {
            return onOpenProjectErrorModal?.()
          } else if (onErrorButtonRoute !== undefined) {
            navigate(onErrorButtonRoute)
          } else {
            onErrorButtonClick && onErrorButtonClick()
          }
        }}
      >
        {errorType ? errorType : progressDescription}
      </div>
    )
  } else {
    return (
      <div className="flex items-center gap-3">
        <RainbowSpinner />
        <div>{getProjectStatusDescription(progressDescription, requiredColumnsReady)}</div>
      </div>
    )
  }
}

export default ProjectStatusCellComponent
