import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useQuery } from 'react-query'

import { NotificationSetting, queryKeys } from './constants'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/user_notification_settings`,
  withCredentials: true,
})

export const useNotificationSetting = (notificationSetting: NotificationSetting): boolean => {
  const { data } = useQuery<boolean>({
    queryKey: queryKeys.notificationSettings.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(`/${notificationSetting}`, createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Error fetching current user notification settings', suppress: true },
  })
  return data ?? true
}
