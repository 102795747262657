import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { CleansetRowRes, queryKeys } from '@services/cleanset/constants'
import { UseQueryOptionsPassThrough } from '@services/useQuery.helpers'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import camelCaseKeys from '@utils/functions/camelCaseKeys'
import { isUUID4 } from '@utils/functions/isUUID4'
import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import {
  CleansetDetailsProps,
  ProjectDetailsProps,
} from '../../pages/cleanset/datasheet/Datasheet.types'
import {
  normalizeFeatureImportanceData,
  normalizePerformanceData,
  normalizePerformancePerClassData,
} from './utils'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/cleansets`,
  withCredentials: true,
})

export const useCleansetDetails = ({
  cleansetId,
  onError,
}: {
  cleansetId: string
  onError?: (err: AxiosError) => void
}): CleansetDetailsProps => {
  const { data } = useQuery<CleansetDetailsProps>({
    queryKey: queryKeys.cleanset.id(cleansetId).details(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(`/${cleansetId}`, createAuthHeaders(accessToken))
      const retval: CleansetDetailsProps = {
        id: res.data.id,
        projectId: res.data.project_id,
        datasetId: res.data.dataset_id,
        modelType: res.data.model_type,
        version: res.data.version,
        createdAt: res.data.created_at,
        isComplete: res.data.is_complete,
      }
      return retval
    },
    meta: { error: 'Failed to fetch Cleanset details.', onError: onError },
    enabled: isUUID4(cleansetId),
    suspense: true,
  })
  return data!
}

export const useCleansetsFromProject = ({
  projectId,
  onError,
  useQueryOptions,
}: {
  projectId: string
  onError?: (err: AxiosError) => void
  useQueryOptions?: UseQueryOptionsPassThrough<CleansetRowRes[]>
}): CleansetRowRes[] => {
  const { meta = {}, enabled = true, ...options } = useQueryOptions ?? {}
  const { data } = useQuery<CleansetRowRes[]>({
    queryKey: queryKeys.cleanset.projectId(projectId),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(`/v1/project/${projectId}`, createAuthHeaders(accessToken))
      return res.data
    },
    enabled: isUUID4(projectId) && enabled,
    suspense: true,
    meta: { error: 'Failed to fetch Cleansets for Project.', onError: onError, ...meta },
    refetchInterval: (data) => {
      if (!data) return false
      if (data.every((row) => row.is_ready || row.has_error)) {
        return false
      }
      return 10000
    },
    ...options,
  })
  return data!
}

// TODO add proper typing to data
export const useModelSummary = (cleansetId: string, enabled: boolean) => {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.cleanset.id(cleansetId).modelSummary(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(
        `/${cleansetId}/model_summary`,
        createAuthHeaders(accessToken)
      )
      return res.data
    },
    meta: { error: 'Failed to fetch Model Summary data.' },
    enabled: enabled,
  })
  return { data: data ?? [], isLoading }
}

export const useCleansetProjectDetails = ({
  cleansetId,
  onError,
}: {
  cleansetId: string
  onError?: (err: AxiosError) => void
}): ProjectDetailsProps | null => {
  const { data } = useQuery({
    queryKey: queryKeys.cleanset.id(cleansetId).projectDetails(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(
        `/${cleansetId}/project_details`,
        createAuthHeaders(accessToken)
      )
      const data = res.data
      return {
        projectId: data.id,
        projectName: data.name,
        idColumn: data.id_column,
        tasktype: data.tasktype,
        datasetId: data.dataset_id,
        datasetName: data.dataset_name,
        modelType: data.model_type,
        modality: data.modality,
        paidExport: data.paid_export,
        labelColumn: data.label_column,
        labels: data.labels,
        labelsIndexed: data.labels_indexed,
        predictiveColumns: data.predictive_columns,
        possibleNumericColumns: data.possible_numeric_columns,
        possibleTextColumns: data.possible_text_columns,
        datetimeColumns: data.datetime_columns,
        identifierColumns: data.identifier_columns,
        variableColumns: data.variable_columns,
        unusedColumns: data.unused_columns,
        imageColumns: data.image_columns,
        isTemplate: data.is_template,
      }
    },
    enabled: isUUID4(cleansetId),
    meta: { error: 'Failed to fetch Project details.', onError: onError },
  })
  return data ?? null
}

export const useDatasetUsageStatus = (datasetId: string): boolean => {
  const toast = useToast()
  const { data } = useQuery<boolean>({
    queryKey: queryKeys.cleanset.datasetInUse(datasetId),
    queryFn: async () => {
      try {
        const accessToken = await auth.getTokenSilently()
        const res = await axiosClient.get(
          `/datasets/${datasetId}/in_use`,
          createAuthHeaders(accessToken)
        )
        return res.data.is_dataset_used_by_cleanset
      } catch (err) {
        notifyAxiosError(toast, err as AxiosError, {
          title: 'Failed to fetch Dataset usage status.',
        })
        return true
      }
    },
    meta: { error: 'Failed to fetch Dataset usage status.' },
    suspense: true,
  })
  return data!
}

export const useCleansetModelPerformance = (cleansetId: string) => {
  const { data } = useQuery({
    queryKey: queryKeys.cleanset.id(cleansetId).modelPerformance(),
    queryFn: async () => {
      try {
        const accessToken = await auth.getTokenSilently()
        const res = await axiosClient.get(
          `/${cleansetId}/model_performance`,
          createAuthHeaders(accessToken)
        )
        return normalizePerformanceData(camelCaseKeys(res.data))
      } catch (err) {
        console.error(err)
      }
    },
    meta: { error: 'Error fetching model performance.' },
  })

  return data ?? []
}

export const useCleansetModelPerformanceByClass = (cleansetId: string) => {
  const { data } = useQuery({
    queryKey: queryKeys.cleanset.id(cleansetId).byClassPerformance(),
    queryFn: async () => {
      try {
        const accessToken = await auth.getTokenSilently()
        const res = await axiosClient.get(
          `/${cleansetId}/per_class_performance`,
          createAuthHeaders(accessToken)
        )
        return normalizePerformancePerClassData(res.data)
      } catch (err) {
        console.error(err)
      }
    },
    meta: { error: 'Error fetching model performance by class.' },
  })

  return data
}

export const useCleansetFeatureImportance = (cleansetId: string) => {
  const { data } = useQuery({
    queryKey: queryKeys.cleanset.id(cleansetId).featureImportance(),
    queryFn: async () => {
      try {
        const accessToken = await auth.getTokenSilently()
        const res = await axiosClient.get(
          `/${cleansetId}/feature_importance`,
          createAuthHeaders(accessToken)
        )

        return normalizeFeatureImportanceData(camelCaseKeys(res?.data))
      } catch (err) {
        console.error(err)
      }
    },
    meta: { error: 'Error fetching Cleanset feature importance metrics.' },
  })

  return data ?? []
}
