'use client'

import { Kbd } from '@components/kbd/Kbd'
import { SlotChild } from '@components/SlotChild'
import { Slot } from '@radix-ui/react-slot'
import { AsChildProps } from '@utils/AsChildProps'
import { cn, tv } from '@utils/tailwindUtils'
import {
  ButtonHTMLAttributes,
  cloneElement,
  ForwardedRef,
  forwardRef,
  memo,
  ReactElement,
  useMemo,
} from 'react'
import { UnionToIntersection } from 'type-fest'

type ButtonVariant =
  | 'primary'
  | 'primaryFaint'
  | 'secondary'
  | 'secondaryStrong'
  | 'secondaryFaint'
  | 'highContrast'
  | 'critical'

type ButtonSize = 'xSmall' | 'small' | 'medium' | 'large'

export type ButtonPropsCommon = AsChildProps<ButtonHTMLAttributes<HTMLButtonElement>> & {
  variant?: ButtonVariant
  size?: ButtonSize
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled']
}

export type ButtonProps = ButtonPropsUnion & ButtonPropsCommon
export type ButtonPropsUnion =
  /* Weird bug in prettier or eslint ¯\_(ツ)_/¯ that's fixed by adding a comment */
  /* Gets stuck in a loop of switching to/from single/multi-line */
  | {
      iconStart?: ReactElement
    }
  | { iconEnd?: ReactElement }
  | { shortcutKey?: string }

// For internal use only
export type ButtonPropsAll = ButtonPropsCommon & UnionToIntersection<ButtonPropsUnion>

const variants = tv({
  base: 'relative flex shrink-0 items-center rounded-2 outline outline-1 -outline-offset-1 transition-colors focus-visible:outline-1 focus-visible:ring focus-visible:ring-focus disabled:cursor-not-allowed disabled:bg-surface-disabled disabled:text-text-disabled disabled:outline-border-2',
  variants: {
    // Main variant
    variant: {
      primary:
        'bg-blue-800 text-text-high-contrast outline-blue-700 hover:bg-blue-700 hover:outline-blue-800 focus-visible:outline-blue-50 active:bg-blue-800 group-hover/button:bg-blue-700 group-hover/button:outline-blue-800',
      primaryFaint:
        'bg-surface-0 text-blue-600 outline-blue-200 hover:bg-blue-50 focus-visible:outline-blue-700  active:bg-blue-200 group-hover/button:bg-blue-50',
      secondary:
        'bg-surface-1 text-text-primary outline-border-1 hover:bg-surface-1-hover focus-visible:outline-blue-700 active:bg-surface-1-active group-hover/button:bg-surface-1-hover',
      secondaryStrong:
        'bg-surface-2 text-text-strong outline-border-2 hover:bg-surface-2-hover focus-visible:outline-blue-700 active:bg-surface-2-active group-hover/button:bg-surface-2-hover',
      secondaryFaint:
        'bg-surface-0 text-text-primary outline-border-1 hover:bg-surface-0-hover focus-visible:outline-blue-700 active:bg-surface-0-active group-hover/button:bg-surface-0-hover',
      highContrast:
        'bg-surface-high-contrast text-text-high-contrast outline-surface-high-contrast hover:bg-neutral-800 focus-visible:outline-blue-50 active:bg-neutral-700 group-hover/button:hover:bg-neutral-800',
      critical:
        'bg-red-50 text-red-700 outline-red-600 hover:bg-red-600 hover:text-red-50 hover:outline-red-700  focus-visible:outline-red-700 active:bg-red-600 active:text-red-50 group-hover/button:bg-red-600 group-hover/button:text-red-50 group-hover/button:outline-red-700',
    },
    // Additional options
    size: {
      xSmall: 'type-button-xs min-h-[24px] px-4',
      small: 'type-button-sm min-h-[32px] px-5 py-3',
      medium: 'type-button-md min-h-[36px] px-6 py-4',
      large: 'type-button-lg min-h-[40px] px-7 py-4',
    },
  },
  compoundVariants: [
    { variant: ['primary', 'primaryFaint'], class: '' },
    { variant: ['secondary', 'secondaryFaint', 'secondaryStrong'], class: '' },
  ],
})

export { variants as buttonVariants }

const iconSizes = {
  xSmall: 12,
  small: 16,
  medium: 16,
  large: 16,
} as const satisfies Record<ButtonSize, number>

const ButtonBase = (allProps: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const {
    className,
    children,
    variant = 'primary',
    size = 'medium',
    iconEnd,
    iconStart,
    shortcutKey,
    disabled,
    asChild = false,
    ...props
  } = allProps as ButtonPropsAll

  const Comp = asChild ? Slot : 'button'
  let iconStartClone: ReturnType<typeof cloneElement> | undefined
  let iconEndClone: ReturnType<typeof cloneElement> | undefined
  if (iconStart) {
    iconStartClone = cloneElement(iconStart, {
      size: iconSizes[size],
    })
  } else if (iconEnd) {
    iconEndClone = cloneElement(iconEnd, {
      size: iconSizes[size],
    })
  }
  const classN = useMemo(
    () => cn(variants({ variant, size }), className),
    [className, size, variant]
  )
  const ret = (
    <Comp
      ref={ref}
      className={classN}
      disabled={disabled}
      type={allProps.asChild ? undefined : allProps.type ?? 'button'}
      {...props}
    >
      <SlotChild asChild={!!asChild} child={children}>
        {(child) => (
          <>
            {iconStartClone && (
              <div
                className={cn('relative -ms-1 pe-2', {
                  'pe-3': size === 'medium' || size === 'large',
                })}
              >
                {iconStartClone}
              </div>
            )}
            <div className="relative">{child}</div>
            {iconEndClone && (
              <div
                className={cn('p relative -me-1 ps-2', {
                  'ps-3': size === 'medium' || size === 'large',
                })}
              >
                {iconEndClone}
              </div>
            )}
            {shortcutKey && !iconEnd && (
              <div
                className={cn('relative ps-2', {
                  'ps-3': size === 'medium',
                  'ps-4': size === 'large',
                })}
              >
                <Kbd
                  className="relative"
                  variant={disabled ? 'outline' : 'primary'}
                  disabled={disabled}
                >
                  {shortcutKey}
                </Kbd>
              </div>
            )}
          </>
        )}
      </SlotChild>
    </Comp>
  )
  return ret
}

/**
 * Button component
 */
export const Button = memo(forwardRef(ButtonBase))
Button.displayName = 'Button'
