import { Box } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import { InputSearch } from '@components/input/Input'
import useGridClassname from '@hooks/useGridClassname'
import { AccountStatus, VpcUserRowRes } from '@services/user/constants'
import { ColumnApi, GridApi } from 'ag-grid-community'
import { useEffect, useState } from 'react'

import { generateGridOptions } from './VpcAdminGrid.helpers'

const VpcAdminGrid = ({
  rowData,
  vpcAdminGridType,
  setGridApi,
}: {
  rowData: VpcUserRowRes[]
  vpcAdminGridType: AccountStatus
  setGridApi: (gridApi: GridApi) => void
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [columnApi, setColumnApi] = useState<ColumnApi | null>()
  const gridOptions = generateGridOptions(vpcAdminGridType)

  useEffect(() => {
    if (columnApi) {
      const colIds = columnApi.getColumns()?.map((column) => column.getColId()) ?? []
      columnApi.autoSizeColumns(colIds)
    }
  }, [columnApi])

  return (
    <Box className={useGridClassname()} w="100%">
      <InputSearch
        placeholder="Filter by name or email"
        className="mb-6"
        onChange={(evt) => setSearchInput(evt.target.value)}
      />
      <CleanlabGrid
        gridOptions={gridOptions}
        pagination
        onGridReady={(event) => {
          setGridApi(event.api)
          setColumnApi(event.columnApi)
        }}
        rowData={
          searchInput
            ? rowData.filter(
                (row) =>
                  row.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
                  row.email?.toLowerCase().includes(searchInput.toLowerCase())
              )
            : rowData
        }
      />
    </Box>
  )
}

export default VpcAdminGrid
