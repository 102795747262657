import {
  Avatar,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react'
import { useAuth } from '@hooks/useAuth'
import { AccessLevel } from '@services/user/constants'
import { useMyself } from '@services/user/queries'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { useNavigate } from 'react-router-dom'

const UserMenu = () => {
  const { user, logout } = useAuth()
  const { activated, accountActivated, emailConfirmed, accessLevel } = useMyself()
  const menuBg = useColorModeValue('white', 'neutralDarkMode.100')
  const menuHoverBg = useColorModeValue('gray.100', 'neutralDarkMode.200')

  const navigate = useNavigate()

  if (!user) {
    return null
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rounded="full"
        variant="link"
        cursor="pointer"
        _focus={{
          boxShadow:
            'var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)',
        }}
        minW={0}
        className="home-tour-user-menu-button focus-visible:outline-none focus-visible:ring focus-visible:ring-focus"
        aria-label="User Menu"
      >
        <Avatar size="sm" src={user.picture} name={user.name} iconLabel="User Image" />
      </MenuButton>
      <MenuList bg={menuBg} alignItems="center" zIndex={11} minWidth="250px" maxW="400px">
        <br />
        <Center>
          <Avatar size="2xl" src={user.picture} name={user.name} iconLabel="User Image" />
        </Center>
        <br />
        <div className="w-full px-5">
          <p className="type-body-200-bold">{user.name}</p>
        </div>
        <br />
        {activated && accountActivated && emailConfirmed && (
          <>
            <MenuDivider />
            <MenuItem bg={menuBg} _hover={{ bg: menuHoverBg }} onClick={() => navigate('/account')}>
              My account
            </MenuItem>
            {IS_VPC_ENVIRONMENT && accessLevel === AccessLevel.USER ? (
              <></>
            ) : (
              <MenuItem
                bg={menuBg}
                _hover={{ bg: menuHoverBg }}
                onClick={() =>
                  navigate(IS_VPC_ENVIRONMENT ? '/vpc-usage' : '/account?tab=Usage%26Billing')
                }
              >
                {IS_VPC_ENVIRONMENT ? 'Usage' : 'Usage & billing'}
              </MenuItem>
            )}

            {accessLevel === AccessLevel.ORGANIZATION_ADMIN && (
              <MenuItem
                bg={menuBg}
                _hover={{ bg: menuHoverBg }}
                onClick={() => navigate('/vpc-admin')}
              >
                Admin dashboard
              </MenuItem>
            )}
          </>
        )}
        <MenuItem
          bg={menuBg}
          _hover={{ bg: menuHoverBg }}
          onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        >
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default UserMenu
