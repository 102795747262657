// Must not use @ import path aliases so tailwind can import this
import { mapKeys } from 'lodash'
import { CSSProperties } from 'react'
import plugin from 'tailwindcss/plugin'
import { CSSRuleObject } from 'tailwindcss/types/config'

// These css custom props must be defined in the global styles
export const BODY_FONT_FAMILY = 'var(--cl-font-family-body)' as const
export const DISPLAY_FONT_FAMILY = 'var(--cl-font-family-display)' as const
export const CODE_FONT_FAMILY = 'var(--cl-font-family-code)' as const

type TypographyStyle = Required<
  Pick<
    CSSProperties,
    'fontFamily' | 'fontSize' | 'lineHeight' | 'letterSpacing' | 'fontWeight' | 'textTransform'
  >
>
type TypographyStack<Key extends string = string> = Record<
  Key,
  TypographyStyle | Record<string, TypographyStyle | Record<string, TypographyStyle>>
>

export const TYPOGRAPHY_PREFIX = 'type'

export const fontFamily = {
  display: DISPLAY_FONT_FAMILY,
  sans: BODY_FONT_FAMILY,
  mono: CODE_FONT_FAMILY,
} as const

export const fontWeight = {
  normal: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  link: '500',
} as const
type FontWeight = keyof typeof fontWeight

const generateStackWeights = (styles: TypographyStack, weights: (FontWeight | 'link')[]) => {
  return Object.fromEntries(
    Object.entries(styles).flatMap(([size, style]) => {
      return Object.entries(fontWeight)
        .filter(([weightName]) => weights.includes(weightName as FontWeight))
        .map(([weightName, weightValue]) => {
          return [
            `${size}${weightName === 'normal' ? '' : `-${weightName}`}`,
            {
              ...style,
              fontWeight: weightValue,
            },
          ]
        })
    })
  )
}

const displayBaseStyle = {
  fontFamily: DISPLAY_FONT_FAMILY,
  letterSpacing: '0px',
  fontWeight: fontWeight.semibold,
  textTransform: 'none',
} as const satisfies Partial<TypographyStyle>
const display = {
  '500': {
    ...displayBaseStyle,
    fontSize: '88px',
    lineHeight: '104px',
  },
  '400': {
    ...displayBaseStyle,
    fontSize: '64px',
    lineHeight: '84px',
  },
  '300': {
    ...displayBaseStyle,
    fontSize: '48px',
    lineHeight: '56px',
  },
  '200': {
    ...displayBaseStyle,
    fontSize: '32px',
    lineHeight: '40px',
  },
  '100': {
    ...displayBaseStyle,
    fontSize: '24px',
    lineHeight: '32px',
  },
  '50': {
    ...displayBaseStyle,
    fontSize: '20px',
    lineHeight: '24px',
  },
} as const satisfies TypographyStack

const bodyBaseStyle = {
  fontFamily: BODY_FONT_FAMILY,
  letterSpacing: '0px',
  fontWeight: fontWeight.normal,
  textTransform: 'none',
} as const satisfies Partial<TypographyStyle>
const bodyBase = {
  '300': {
    ...bodyBaseStyle,
    fontSize: '18px',
    lineHeight: '28px',
  },
  '200': {
    ...bodyBaseStyle,
    fontSize: '16px',
    lineHeight: '24px',
  },
  '100': {
    ...bodyBaseStyle,
    fontSize: '14px',
    lineHeight: '20px',
  },
} as const satisfies TypographyStack
const body = generateStackWeights(bodyBase, ['normal', 'medium', 'semibold', 'bold', 'link'])

const buttonBaseStyle = {
  fontFamily: BODY_FONT_FAMILY,
  letterSpacing: '0px',
  fontWeight: fontWeight.medium,
  textTransform: 'none',
} as const satisfies Partial<TypographyStyle>
const button = {
  lg: {
    ...buttonBaseStyle,
    fontSize: '16px',
    lineHeight: '24px',
  },
  md: {
    ...buttonBaseStyle,
    fontSize: '14px',
    lineHeight: '20px',
  },
  sm: {
    ...buttonBaseStyle,
    fontSize: '14px',
    lineHeight: '20px',
  },
  xs: {
    ...buttonBaseStyle,
    fontSize: '12px',
    lineHeight: '18px',
  },
} as const satisfies TypographyStack

const badgeBaseStyle = {
  fontFamily: BODY_FONT_FAMILY,
  letterSpacing: '0px',
  fontWeight: fontWeight.medium,
  textTransform: 'none',
} as const satisfies Partial<TypographyStyle>
const badgeCodeBaseStyle = {
  ...badgeBaseStyle,
  fontFamily: CODE_FONT_FAMILY,
} as const satisfies Partial<TypographyStyle>
const badge = {
  sm: {
    ...badgeBaseStyle,
    fontSize: '11px',
    lineHeight: '20px',
  },
  md: {
    ...badgeBaseStyle,
    fontSize: '12px',
    lineHeight: '24px',
  },
  lg: {
    ...badgeBaseStyle,
    fontSize: '14px',
    lineHeight: '28px',
  },
  'code-sm': {
    ...badgeCodeBaseStyle,
    fontSize: '11px',
    lineHeight: '20px',
  },
  'code-md': {
    ...badgeCodeBaseStyle,
    fontSize: '12px',
    lineHeight: '24px',
  },
  'code-lg': {
    ...badgeCodeBaseStyle,
    fontSize: '14px',
    lineHeight: '28px',
  },
} as const satisfies TypographyStack

const captionBase = {
  fontFamily: BODY_FONT_FAMILY,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0',
  fontWeight: fontWeight.normal,
  textTransform: 'none',
} as const satisfies TypographyStyle

const codeBaseStyle = {
  fontFamily: CODE_FONT_FAMILY,
  letterSpacing: '0px',
  fontWeight: fontWeight.normal,
  textTransform: 'none',
} as const satisfies Partial<TypographyStyle>
const codeBase = {
  '300': {
    ...codeBaseStyle,
    fontSize: '18px',
    lineHeight: '28px',
  },
  '200': {
    ...codeBaseStyle,
    fontSize: '16px',
    lineHeight: '24px',
  },
  '100': {
    ...codeBaseStyle,
    fontSize: '14px',
    lineHeight: '20px',
  },
} as const satisfies TypographyStack
const code = generateStackWeights(codeBase, ['normal', 'bold'])

const utilities = {
  label: {
    fontFamily: BODY_FONT_FAMILY,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontWeight: fontWeight.semibold,
    textTransform: 'none',
  },
  overline: {
    fontFamily: BODY_FONT_FAMILY,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    fontWeight: fontWeight.medium,
    textTransform: 'uppercase',
  },
  'overline-lg': {
    fontFamily: BODY_FONT_FAMILY,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontWeight: fontWeight.medium,
    textTransform: 'uppercase',
  },
  caption: captionBase,
  'caption-medium': {
    ...captionBase,
    fontWeight: fontWeight.medium,
  },
  'caption-semibold': {
    ...captionBase,
    fontWeight: fontWeight.semibold,
  },
  kbd: {
    fontFamily: CODE_FONT_FAMILY,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0px',
    fontWeight: fontWeight.normal,
    textTransform: 'none',
  },
} as const satisfies TypographyStack

export const typography = {
  display: display,
  body: body,
  button: button,
  badge: badge,
  code: code,
} as const

export const createTypeClass = (key: string, stackKey?: string) => {
  return `${TYPOGRAPHY_PREFIX}${stackKey ? `-${stackKey}` : ''}-${key}`
}

/**
 * Add typography classes to the tailwind config
 */
export const twTypographyPlugin = plugin(({ addComponents }) => {
  addComponents({
    ...Object.entries(typography).reduce((components, [stackKey, styles]) => {
      const stackStyles = mapKeys(styles, (_, key) => `.${createTypeClass(key, stackKey)}`)
      return { ...components, ...stackStyles }
    }, {} as CSSRuleObject),
    ...mapKeys(utilities, (_, key) => `.${createTypeClass(key)}`),
  })
})

/**
 * Get all typography keys to configure tailwind-merge
 */
export const typographyKeys = Object.entries(typography).reduce(
  (components, [stackKey, styles]) => {
    const styleKeys: string[] = Object.keys(styles).map((key) => `${stackKey}-${key}`)
    return [...components, ...styleKeys]
  },
  [] as string[]
)

export const typographyStyles = {
  ...typography,
  utilities,
} as const
