import { findBetween } from '@utils/stringUtils'

const keys = {
  templateProjects: {
    all: () => ['templateProjects'] as const,
    previews: () => [keys.templateProjects.all(), 'previews'] as const,
  },
}
export { keys as templateProjectsQueryKeys }

export type TemplateProjectPreviewRes = {
  id: string
  name: string
  dataset_id: string
  dataset_name: string
  tasktype: string
  modality: string
  model_type: string
  label_column: string
  labels: string[]
  predictive_columns: string[]
  example_dataset_rows: {
    columns: string[]
    rows: Record<string, string | number | boolean | null>[]
  }
}

export const addTemplateProjectMeta = (template: TemplateProjectPreviewRes) => {
  // Title will be all the text in the name until it encounters a ( or [
  const title = /^([^[(])*/.exec(template.name ?? '')?.[0].trim()
  // Tags will be in the format [[Blurry, Non English, Outliers]]
  const tagsString = findBetween(template.name ?? '', '[[', ']]')
  const tags =
    tagsString
      ?.split(',')
      .map((tag) => tag.trim())
      .filter((t) => !!t) ?? []
  const meta = {
    title: title ?? template.name,
    tags: tags ?? [],
  }
  return { ...template, meta }
}

export type TemplateProjectPreview = ReturnType<typeof addTemplateProjectMeta>
