import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { cleanlabTransformResponse, DEFAULT_STALE_TIME_MS } from '@services/common'
import {
  EnabledModelTypesRes,
  ProjectRes,
  ProjectsRes,
  queryKeys,
} from '@services/project/constants'
import { UseQueryOptionsPassThrough } from '@services/useQuery.helpers'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import { isUUID4 } from '@utils/functions/isUUID4'
import type { SortModelItem } from 'ag-grid-community/dist/lib/sortController'
import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/projects`,
  withCredentials: true,
})

const projectsV2Client = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/projects/v2`,
  withCredentials: true,
  transformResponse: cleanlabTransformResponse,
})

export const useProject = ({
  projectId,
  onError,
  useQueryOptions,
}: {
  projectId: string
  onError?: (err: AxiosError) => void
  useQueryOptions?: UseQueryOptionsPassThrough<ProjectRes>
}): ProjectRes | undefined => {
  const { meta = {}, ...options } = useQueryOptions ?? {}
  const { data } = useQuery<ProjectRes>({
    queryKey: queryKeys.projects.id(projectId).all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(`/${projectId}`, createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Failed to fetch Project details.', onError: onError, ...meta },
    enabled: isUUID4(projectId),
    ...options,
  })
  return data
}

export const useProjectTemplateStatus = (projectId: string): boolean => {
  const { data } = useQuery<boolean>({
    queryKey: queryKeys.projects.id(projectId).templateStatus(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(
        `/check_template/${projectId}`,
        createAuthHeaders(accessToken)
      )
      return res.data.is_template
    },
    suspense: true,
  })
  return data!
}

export const useEnabledModelTypes = (datasetId: string): EnabledModelTypesRes => {
  const { data } = useQuery({
    queryKey: queryKeys.projects.enabledModelTypes(datasetId),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get<{ enabled_model_types: EnabledModelTypesRes }>(
        `/enabled_model_types?dataset_id=${datasetId}`,
        createAuthHeaders(accessToken)
      )
      return res.data?.enabled_model_types
    },
  })
  return data ?? ({} as EnabledModelTypesRes)
}

const projectsQueryFn = async (
  startRow: number,
  endRow: number,
  sortModel?: SortModelItem[],
  filterModel?: { [key: string]: Record<string, unknown> }
) => {
  const accessToken = await auth.getTokenSilently()

  // only supporting sort on one column for now
  const sortOptions =
    sortModel === undefined || sortModel.length === 0
      ? ''
      : `${sortModel[0].colId},${sortModel[0].sort}`
  // only supporting contains filter on one column (without and/or conditions)
  const filterOptions =
    filterModel === undefined || Object.keys(filterModel).length === 0
      ? ''
      : `${Object.keys(filterModel)[0]},${Object.values(filterModel)[0].filter}`
  const url = `/?start_row=${startRow}&end_row=${endRow}&sort_options=${sortOptions}&filter_options=${filterOptions}`
  const response = await projectsV2Client.get<ProjectsRes>(url, createAuthHeaders(accessToken))
  return response.data
}

export const projectsQueryOptions = (
  startRow = 0,
  endRow = 0,
  sortModel: SortModelItem[] = [],
  filterModel: { [key: string]: Record<string, unknown> } = {}
) => {
  const queryKey = queryKeys.projects.allV2({ startRow, endRow, sortModel, filterModel })
  return {
    queryKey,
    queryFn: () => projectsQueryFn(startRow, endRow, sortModel, filterModel),
    staleTime: DEFAULT_STALE_TIME_MS,
  }
}

export const useProjects = ({
  startRow = 0,
  endRow = 0,
  sortModel,
  filterModel,
  useQueryOptions,
}: {
  startRow?: number
  endRow?: number
  sortModel?: SortModelItem[]
  filterModel?: { [key: string]: Record<string, unknown> }
  useQueryOptions?: UseQueryOptionsPassThrough<ProjectsRes>
}) => {
  const { meta = {}, ...options } = useQueryOptions ?? {}
  return useQuery<ProjectsRes>({
    ...projectsQueryOptions(startRow, endRow, sortModel, filterModel),
    meta: { error: 'Failed to fetch Projects.', ...meta },
    ...options,
  })
}
