'use client'
import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
} from 'react'

import { DEFAULT_ICON_SIZE, DEFAULT_ICON_VIEWBOX_SIZE } from './iconConsts'

type SvgBoxProps = {
  className?: string
  size?: number
  children: ReactNode
  viewBoxSize?: number
} & ComponentPropsWithoutRef<'svg'>

export const SvgBox = forwardRef(
  (
    { size = DEFAULT_ICON_SIZE, viewBoxSize = DEFAULT_ICON_VIEWBOX_SIZE, ...props }: SvgBoxProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        fill="none"
        stroke="currentColor"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        height={size}
        width={size}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      />
    )
  }
)

export const wrapSvgIcon = (
  node: ReactNode,
  defaultProps: Omit<ComponentPropsWithoutRef<typeof SvgBox>, 'children'> = {}
) =>
  memo(
    forwardRef(
      (
        props: Omit<ComponentPropsWithRef<typeof SvgBox>, 'children' | 'viewBoxSize'>,
        ref: ForwardedRef<SVGSVGElement>
      ) => {
        return (
          <SvgBox ref={ref} {...defaultProps} {...props}>
            {node}
          </SvgBox>
        )
      }
    )
  )
