'use client'

import { Badge } from '@components/badge/Badge'
import { Button } from '@components/button/Button'
import { IconFrame } from '@components/iconFrameButton/IconFrame'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { isExternalUrl } from '@utils/isExternalUrl'
import { cn } from '@utils/tailwindUtils'
import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  memo,
  MouseEventHandler,
  useCallback,
} from 'react'
import { Link } from 'react-router-dom'

import { UploadType } from '../DatasetUpload.types'
import { UploadTypeCardProps } from './UploadTypeCard.types'

const Wrapper = forwardRef(
  (
    { to, disabled, ...props }: HTMLAttributes<HTMLElement> & { to?: string; disabled?: boolean },
    ref: ForwardedRef<any>
  ) => {
    if (to && !disabled) {
      let extraLinkProps = {}
      if (isExternalUrl(to)) {
        extraLinkProps = {
          rel: 'noreferrer noopener',
          target: '_blank',
        }
      }
      return <Link ref={ref} to={to} {...extraLinkProps} {...props} />
    } else {
      return <button ref={ref} type="button" disabled={disabled} {...props} />
    }
  }
)

const UploadTypeCardBase = (
  {
    className,
    heading,
    disabled,
    description,
    icon,
    badgeContent,
    cta,
    url,
    isFeatureGated,
    uploadType,
    onClick: onClickProp,
    ...props
  }: HTMLAttributes<HTMLElement> & {
    disabled?: boolean
    uploadType: UploadType
  } & UploadTypeCardProps,
  ref: ForwardedRef<HTMLElement>
) => {
  const finalProps: ComponentProps<typeof Wrapper> = {
    ...props,
    to: url,
  }
  const { trackEvent } = useEventTracking()

  const { featuresDisabled, isEnterprisePlan, onSalesModalOpen, onUpgradePlanModalOpen } =
    useSubscription()

  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      onClickProp?.(e)
      trackEvent(MixpanelEvents.clickUploadButton, { uploadType: uploadType })
    },
    [onClickProp, trackEvent, uploadType]
  )

  if (isFeatureGated) {
    if (featuresDisabled && isEnterprisePlan) {
      finalProps.onClick = onSalesModalOpen
      finalProps.to = undefined
    } else if (featuresDisabled) {
      finalProps.onClick = onUpgradePlanModalOpen
      finalProps.to = undefined
    }
  }

  return (
    <Wrapper
      ref={ref as ForwardedRef<any>}
      className={cn(
        'group/button flex cursor-pointer flex-col justify-between gap-4 overflow-hidden rounded-2 bg-surface-0 p-6 text-start outline outline-1 -outline-offset-1 outline-border-1 transition-all focus-within:outline-blue-700 focus-within:ring focus-within:ring-focus dark:bg-surface-1',
        className
      )}
      disabled={disabled}
      onClick={onClick}
      {...finalProps}
    >
      <div className={cn('flex w-full flex-col items-start gap-4')}>
        <div className="flex w-full items-center justify-between gap-4">
          {icon && <IconFrame variant="outline" size="small" icon={icon} disabled={disabled} />}
          {badgeContent && (
            <Badge
              variant="subtle"
              round="full"
              className={cn(
                'w-max',
                disabled && 'bg-surface-disabled text-text-disabled outline-border-2'
              )}
            >
              {badgeContent}
            </Badge>
          )}
        </div>
        <div className="flex w-full flex-col gap-1">
          <div
            className={cn(
              'type-body-200-medium text-text-strong',
              disabled && 'text-text-disabled'
            )}
          >
            {heading}
          </div>
          <div className={cn('type-body-100 text-text-faint', disabled && 'text-text-disabled')}>
            {description}
          </div>
        </div>
      </div>
      {cta && (
        <Button className="self-end" size="medium" variant="secondary" asChild disabled={true}>
          <div>{cta}</div>
        </Button>
      )}
    </Wrapper>
  )
}

export const UploadTypeCard = memo(forwardRef(UploadTypeCardBase))
UploadTypeCard.displayName = 'UploadTypeCard'
