import React, { PropsWithChildren, useState } from 'react'

interface SchemaUpdates {
  schemaUpdates: Record<string, string>
  setSchemaUpdates: (schemaUpdates: Record<string, string>) => void
  addSchemaUpdate: (columnId: string, columnType: string) => void
  removeSchemaUpdate: (columnId: string) => void
  isDatasetUsedInProject: boolean
  setDatasetUsedInProject: (isDatasetUsedInProject: boolean) => void
  schemaUpdateInProgress: boolean
  setSchemaUpdateInProgress: (inProgress: boolean) => void
}

export const SchemaUpdatesContext = React.createContext<SchemaUpdates>({
  schemaUpdates: {},
  setSchemaUpdates: () => {},
  addSchemaUpdate: () => {},
  removeSchemaUpdate: () => {},
  isDatasetUsedInProject: false,
  setDatasetUsedInProject: () => {},
  schemaUpdateInProgress: false,
  setSchemaUpdateInProgress: () => {},
})

export const SchemaUpdatesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [schemaUpdates, setSchemaUpdates] = useState<Record<string, string>>({})
  const [isDatasetUsedInProject, setDatasetUsedInProject] = useState<boolean>(false)
  const [schemaUpdateInProgress, setSchemaUpdateInProgress] = useState<boolean>(false)

  const addSchemaUpdate = (columnId: string, columnType: string) => {
    const newSchemaUpdate = { [columnId]: columnType }
    setSchemaUpdates((prevSchemaUpdates) => {
      return { ...prevSchemaUpdates, ...newSchemaUpdate }
    })
  }
  const removeSchemaUpdate = (columnId: string) => {
    setSchemaUpdates((prevSchemaUpdates) => {
      const newSchemaUpdates = { ...prevSchemaUpdates }
      delete newSchemaUpdates[columnId]
      return newSchemaUpdates
    })
  }

  return (
    <SchemaUpdatesContext.Provider
      value={{
        schemaUpdates,
        setSchemaUpdates,
        addSchemaUpdate,
        removeSchemaUpdate,
        isDatasetUsedInProject,
        setDatasetUsedInProject,
        schemaUpdateInProgress,
        setSchemaUpdateInProgress,
      }}
    >
      {children}
    </SchemaUpdatesContext.Provider>
  )
}
