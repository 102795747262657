import { Button } from '@components/button/Button'
import { ModalAlertBasic } from '@components/modal/ModalAlertBasic'
import { ProgressBar } from '@components/progress/ProgressBar'
import { EnrichmentJobType } from '@services/enrichment/constants'
import { useEnrichmentJobStatus } from '@services/enrichment/queries'
import { Link } from 'react-router-dom'

import { ENRICHMENT_JOB_POLL_INTERVAL } from './EnrichmentProject'
import { EnrichmentStatusModalProps } from './EnrichmentStatusModal.types'

const EnrichmentStatusModal = (props: EnrichmentStatusModalProps) => {
  const { isOpen, jobId, jobType } = props

  const isFullEnrichmentJob = jobType === EnrichmentJobType.ENRICHMENT
  const { data } = useEnrichmentJobStatus(jobId!, {
    enabled: isFullEnrichmentJob && !!jobId && isOpen,
    refetchInterval: isOpen ? ENRICHMENT_JOB_POLL_INTERVAL : false,
  })
  const { processedRows, numRows } = data || {}

  if (processedRows !== undefined && processedRows === numRows) {
    window.location.reload()
  }

  return (
    <ModalAlertBasic
      open={isOpen}
      title="Cleanlab is working on your data"
      bodyContent={
        <div className="flex flex-col items-center gap-6">
          {isFullEnrichmentJob && processedRows !== undefined && numRows !== undefined && (
            <>
              <p className="type-body-200 text-center">
                {processedRows} of {numRows} rows processed
              </p>
              <ProgressBar className="w-full" value={processedRows} max={numRows} />
            </>
          )}
          <p className="type-body-200 text-center text-text-primary">
            Hang on while our LLMs process your dataset...
            <br />
            Check back later for your results.
          </p>
        </div>
      }
      footerContent={
        <Button asChild variant="highContrast">
          <Link to="/">Go to dashboard</Link>
        </Button>
      }
    />
  )
}

export default EnrichmentStatusModal
