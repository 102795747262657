import { timestampToDate } from '@utils/functions/timestampToDate'

import { ProjectDetailsProps } from '../../datasheet/Datasheet.types'

export const searchAlgorithm = (word: string, substring: string) => {
  return word.includes(substring)
}

// in place sort
export const getSortedData = (
  data: [string, string][],
  predictiveColumns: string[],
  pinnedList: string[]
) =>
  data.sort(([a], [b]) => {
    const aPinned = pinnedList.includes(a)
    const bPinned = pinnedList.includes(b)
    if (aPinned !== bPinned) {
      return Number(bPinned) - Number(aPinned)
    }

    const aPredictive = predictiveColumns.includes(a)
    const bPredictive = predictiveColumns.includes(b)
    if (aPredictive !== bPredictive) {
      return Number(bPredictive) - Number(aPredictive)
    }

    return a.localeCompare(b)
  })

export const formatTabularEditCell = (
  field: string,
  value: any,
  projectDetails: ProjectDetailsProps
) => {
  if (projectDetails.datetimeColumns.includes(field)) {
    return timestampToDate(value)
  }
  return String(value)
}
