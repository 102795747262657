import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'

import { AccordionProps } from './Accordion.types'

const Accordion = (props: AccordionProps) => {
  const { title, children, variant, defaultClose, tooltipLabel } = props
  const defaultIndex = defaultClose ? 1 : 0
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <ChakraAccordion allowToggle variant={variant} defaultIndex={defaultIndex}>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <h2>
              <Tooltip
                pointerEvents="all"
                hasArrow
                placement="right"
                closeDelay={3000}
                label={tooltipLabel}
                isOpen={isOpen}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
              >
                <AccordionButton onMouseEnter={onOpen} onMouseLeave={onClose}>
                  <Box fontWeight="semibold">{title}</Box>
                  {isExpanded ? (
                    <ChevronDownIcon fontSize="16px" />
                  ) : (
                    <ChevronRightIcon fontSize="16px" />
                  )}
                </AccordionButton>
              </Tooltip>
            </h2>
            <AccordionPanel>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </ChakraAccordion>
  )
}

export default Accordion
