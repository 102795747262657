import { Text, useColorModeValue } from '@chakra-ui/react'
import CollapseCardButton from '@common/filter/collapsedCard/collapseCardButton/CollapseCardButton'
import CollapsedCard from '@common/filter/collapsedCard/CollapsedCard'
import ColumnFilterRow from '@common/filter/columnFilterRow/ColumnFilterRow'
import DropdownHeader from '@common/filter/dropdownHeader/DropdownHeader'
import PresetFilterRow from '@common/filter/presetFilterRow/PresetFilterRow'
import Card from '@components/card/Card'
import { Input } from '@components/input/Input'
import Link from '@components/link/Link'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { RowCountType } from '@services/datasheet/constants'
import { Column } from 'ag-grid-community'
import { useContext, useMemo, useState } from 'react'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import { ColumnStatus } from '../Cleanset.types'
import { CleansetContext } from '../CleansetContext'
import {
  getColumnDisplayName,
  getColumnName,
  getColumnSelectorTooltip,
} from './FilterSidebar.helpers'
import {
  FilterSidebarProps,
  ORDERED_METADATA_PRESET_FILTERS,
  ORDERED_PRESET_FILTERS,
} from './FilterSidebar.types'

const FilterSidebar = (props: FilterSidebarProps) => {
  const {
    cleansetId,
    gridApi,
    issueColumns,
    metadataColumns,
    customPresetFilters,
    isCollapsed,
    handleCollapse,
    modality,
    columnApi,
    firstGridDataRendered,
    presetConfigCallback,
    resetResolverAndRowSelection,
  } = props
  const presetFilterColumns = [
    RowCountType.TOTAL,
    RowCountType.ISSUES_RESOLVED,
    RowCountType.LABEL_ISSUES,
    RowCountType.BEST_EXAMPLES,
    ...issueColumns,
  ]
  const orderedPresetFilters = customPresetFilters ?? ORDERED_PRESET_FILTERS
  const presetFilters = orderedPresetFilters.filter((presetFilter) =>
    presetFilterColumns.includes(presetFilter.filterType)
  )
  const metadataPresetFilters = ORDERED_METADATA_PRESET_FILTERS.filter((presetFilter) =>
    metadataColumns.includes(presetFilter.filterType)
  )
  const metadataPresetSectionColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')

  const [gridColumns, setGridColumns] = useState(columnApi.getColumns())
  const [columnSearchValue, setColumnSearchValue] = useState<string>('')

  const gridColumnsFiltered = useMemo(() => {
    return (
      gridColumns?.filter((gridColumn) => {
        const columnName = getColumnName(gridColumn, false).replace('_cleanlab_', '')
        return columnName.toLowerCase().includes(columnSearchValue.toLowerCase())
      }) ?? []
    )
  }, [columnSearchValue, gridColumns])

  const [currentPresetFilter, setCurrentPresetFilter] = useState<string>('')

  const unsupervisedColumns = useMemo(() => {
    return gridColumns?.filter((column) => {
      const headerName = column.getColDef().headerName
      return headerName !== 'Given' && headerName !== 'Confidence Score'
    })
  }, [gridColumns])

  const cleansetInfo = useContext(CleansetContext)

  const isUnsupervised = useMemo(
    () => cleansetInfo.tasktype === Tasktype.UNSUPERVISED,
    [cleansetInfo]
  )

  const { trackEvent } = useEventTracking()

  const setActivePresetFilter = (currentPresetFilter: string) => {
    setCurrentPresetFilter(currentPresetFilter)
  }

  const toggleColumnIsVisible = (gridColumn: Column) => {
    const isColumnVisible = !gridColumn.isVisible()
    const columnEventMetric = isColumnVisible
      ? MixpanelEvents.clickShowColumn
      : MixpanelEvents.clickHideColumn
    trackEvent(columnEventMetric, {
      ...cleansetInfo,
      columnDisplayName: getColumnDisplayName(gridColumn),
    })
    columnApi.setColumnVisible(gridColumn, isColumnVisible)
    setGridColumns(columnApi.getColumns())
  }

  const ColumnTooltipLabel = () => {
    return (
      <Text>
        Toggle which columns display in the grid. Cleanlab columns provide useful metadata. Learn
        More{' '}
        <Link
          variant="reversed"
          href="https://help.cleanlab.ai/guide/concepts/cleanlab_columns/"
          isExternal
        >
          here
        </Link>
      </Text>
    )
  }

  return isCollapsed ? (
    <CollapsedCard isCollapsed={isCollapsed} handleCollapse={handleCollapse} />
  ) : (
    <Card
      header="filter"
      width="20%"
      height="100%"
      minWidth="150px"
      maxWidth="220px"
      headerIcon={<CollapseCardButton isCollapsed={isCollapsed} handleCollapse={handleCollapse} />}
    >
      <DropdownHeader title="columns" defaultClose={true} tooltipLabel={<ColumnTooltipLabel />}>
        <div className="mx-5 py-2">
          <Input
            placeholder="Search columns"
            size="small"
            onChange={(evt) => setColumnSearchValue(evt.target.value)}
            onKeyDown={(evt) => evt.stopPropagation()}
          />
        </div>
        {(isUnsupervised ? unsupervisedColumns : gridColumnsFiltered)?.map((gridColumn, index) => {
          const columnName = getColumnName(gridColumn).replace('_cleanlab_', '')
          const columnStatuses = cleansetInfo.columnStatuses ?? {}
          const columnStatus = Object.keys(columnStatuses).includes(columnName)
            ? columnStatuses[columnName]
            : ColumnStatus.READY
          const tooltip = getColumnSelectorTooltip(columnStatus)

          return (
            <ColumnFilterRow
              label={getColumnDisplayName(gridColumn)}
              checked={gridColumn.isVisible()}
              onCheckedChange={() => toggleColumnIsVisible(gridColumn)}
              key={gridColumn.getUniqueId() + index}
              disabled={columnStatus !== ColumnStatus.READY}
              tooltip={tooltip}
            />
          )
        })}
      </DropdownHeader>
      <DropdownHeader title="presets">
        {presetFilters.map((presetFilter, index) => (
          <PresetFilterRow
            key={presetFilter.filterType + presetFilter.rowCountType + index}
            cleansetId={cleansetId}
            gridApi={gridApi}
            filterType={presetFilter.filterType}
            rowCountType={presetFilter.rowCountType}
            currentPresetFilter={currentPresetFilter}
            setActivePresetFilter={setActivePresetFilter}
            firstGridDataRendered={firstGridDataRendered}
            columnApi={columnApi}
            presetConfigCallback={presetConfigCallback}
            resetResolverAndRowSelection={resetResolverAndRowSelection}
          />
        ))}
        {metadataPresetFilters.length > 0 && (
          <Text
            fontSize="12px"
            fontWeight="600"
            lineHeight="16px"
            color={metadataPresetSectionColor}
            padding="8px 16px 6px"
          >{`${modality.charAt(0).toUpperCase() + modality.slice(1)}-specific analyses`}</Text>
        )}
        {metadataPresetFilters.map((presetFilter, index) => (
          <PresetFilterRow
            key={presetFilter.filterType + presetFilter.rowCountType + index}
            cleansetId={cleansetId}
            gridApi={gridApi}
            filterType={presetFilter.filterType}
            rowCountType={presetFilter.rowCountType}
            currentPresetFilter={currentPresetFilter}
            setActivePresetFilter={setActivePresetFilter}
            firstGridDataRendered={firstGridDataRendered}
            columnApi={columnApi}
            presetConfigCallback={presetConfigCallback}
            resetResolverAndRowSelection={resetResolverAndRowSelection}
          />
        ))}
      </DropdownHeader>
      {/* TODO: this toggle has not been working for a while, reenable when it's fixed
      {isRerun && (
        <ShowResolvedToggle
          showResolved={showResolved}
          setShowResolved={setShowResolved}
        ></ShowResolvedToggle>
      )} */}
    </Card>
  )
}

export default FilterSidebar
