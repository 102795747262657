import { FormField } from '@components/formField/FormField'
import { ColumnDiversity, DatasetDetailsProps } from '@services/datasetApi'
import { useMemo, useState } from 'react'

import testIds from '../../playwright/test_ids.json'
import { CleanlabSelect } from './CleanlabSelect'
import { PageDescription, PageSubHeading } from './CreateProject.helpers'
import {
  checkColumnDiversity,
  checkValidMultilabelColumn,
  SelectLabelForm,
} from './CreateProjectSelectLabel'
import { Modality, Tasktype } from './projectFormFields/ProjectFormFields.types'
import { InlineLink } from './Sidebar'

const multiClassDescription = (
  <>
    Choose your label column to be analyzed and corrected. This should be a column with a set of
    values (e.g., "red", "green", "blue", etc.)
  </>
)
const multilabelDescription = (
  <>
    {multiClassDescription} Multi-Label label columns must be formatted as a comma-separated string
    of labels (i.e. "smiling,wearing_hat") with no extra whitespace between labels.
  </>
)

const getLabelErrors = ({
  modality,
  label,
  textColumn,
  datasetDetails,
}: {
  modality?: Modality
  label?: string
  textColumn?: string
  datasetDetails?: DatasetDetailsProps
}) => {
  if (!label) {
    return 'Please select a label column'
  }
  if (modality === Modality.text && label === textColumn) {
    return 'This column is currently specified as the text column. The text column and label column must be different columns for text Projects.'
  }
  if (datasetDetails?.label_columns.length === 0) {
    return `Dataset contains no valid label columns (i.e. integer, string, or boolean column
      containing ≥2 classes with ≥5 examples each). Project cannot be created.`
  }
  return undefined
}

export function SelectLabelColumn({
  form,
  modality,
  taskType,
  datasetId,
  datasetDetails,
}: Readonly<{
  form: SelectLabelForm
  modality: Modality
  taskType: Tasktype
  datasetId: string
  datasetDetails: DatasetDetailsProps
}>) {
  const [labelColumnDiversity, setLabelColumnDiversity] = useState<ColumnDiversity | null>(null)
  const [validMultilabelColumn, setValidMultilabelColumn] = useState<boolean>(false)

  const sortedPossibleLabelColumns = useMemo(
    () => [...datasetDetails.possible_label_columns].sort((a, b) => a.localeCompare(b)),
    [datasetDetails.possible_label_columns]
  )

  return (
    <form.Field
      name="labelColumn"
      validators={{
        onMount: ({ value, fieldApi }) => {
          const textColumn = fieldApi.form.getFieldValue('textColumn')
          if (datasetDetails.possible_label_columns.length === 0) {
            return 'Dataset contains no possible label columns.'
          }
          if (getLabelErrors({ modality, label: value, textColumn, datasetDetails })) {
            fieldApi.setValue('')
          }
          return undefined
        },
        onChangeListenTo: ['textColumn'],
        onChange: ({ value, fieldApi }) => {
          const textColumn = fieldApi.form.getFieldValue('textColumn')
          return getLabelErrors({ modality, label: value, textColumn, datasetDetails })
        },
        onChangeAsync: async ({ value }) => {
          const validMultilabelErr = await checkValidMultilabelColumn({
            labelColumn: value,
            datasetId,
            setValidMultilabelColumn,
          })
          if (taskType === Tasktype.MULTILABEL && validMultilabelErr) {
            return validMultilabelErr
          }
          const colDiversityErr = await checkColumnDiversity({
            labelColumn: value,
            datasetId,
            modality,
            taskType,
            setLabelColumnDiversity,
          })
          if (colDiversityErr) {
            return colDiversityErr
          }
          return undefined
        },
      }}
    >
      {(f) => {
        const error = f.state.meta.errors?.[0] || undefined
        const value = f.state.value
        const { handleChange, handleBlur } = f

        const cautions = (
          <div className="text-yellow-800">
            {value &&
              taskType === Tasktype.MULTICLASS &&
              labelColumnDiversity &&
              labelColumnDiversity.has_minimal_diversity &&
              labelColumnDiversity.has_non_represented_classes && (
                <p>
                  Warning: Contains classes with less than 5 examples. Rows belonging to these
                  classes will not be analyzed.
                </p>
              )}
            {value && taskType === Tasktype.MULTICLASS && validMultilabelColumn && (
              <p>
                The selected label column appears to be a multi-label label column. Are you sure you
                don’t want to kick off training for a{' '}
                <InlineLink to={`/clean/${datasetId}/type`}>multi-label Project</InlineLink>{' '}
                instead?
              </p>
            )}
          </div>
        )
        return (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <PageSubHeading>Label column</PageSubHeading>
              <PageDescription>
                {taskType === Tasktype.MULTICLASS ? multiClassDescription : multilabelDescription}
              </PageDescription>
            </div>
            <FormField error={error} info={cautions}>
              <CleanlabSelect
                label="Label column"
                placeholder="Select label column"
                value={value}
                onChange={(e) => handleChange(e.currentTarget.value)}
                onBlur={handleBlur}
                data-testid={testIds.createProjectPageLabelColumnInput}
              >
                {sortedPossibleLabelColumns.map((v: string) => (
                  <option key={'label' + v} value={v}>
                    {v}
                  </option>
                ))}
              </CleanlabSelect>
            </FormField>
          </div>
        )
      }}
    </form.Field>
  )
}
