import { Container, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import NavTitle from '@common/layout/navTitle/NavTitle'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'

import NotFound from '../404/NotFound'
import AgilityApps from './admin/agilityApps/AgilityApps'
import Billing from './admin/billing/Billing'
import CopyAsset from './admin/copyAsset/CopyAsset'
import CostReport from './admin/costReport/CostReport'
import FeatureFlagGrid from './admin/featureFlag/featureFlagGrid/FeatureFlagGrid'
import SignupSettings from './admin/signupSettings/SignupSettings'
import TlmUsage from './admin/tlmUsage/TlmUsage'
import UserDataGrid from './admin/userDataGrid/UserDataGrid'
import UserQuotaGrid from './admin/userQuotaGrid/UserQuotaGrid'
import QueryInterface from './queryInterface/QueryInterface'
import UserEventMetrics from './userEventMetrics/UserEventMetrics'

const AdminPanel = () => {
  if (!checkFeatureFlag('IS_ADMIN')) {
    return <NotFound />
  }

  return (
    <Layout nav={<NavTitle title="Admin Panel" />}>
      <Container maxWidth="100%">
        <Tabs>
          <TabList>
            <Tab>Users</Tab>
            <Tab>Signup Settings</Tab>
            <Tab>Quotas</Tab>
            <Tab>Query Interface</Tab>
            <Tab>User Event Metrics</Tab>
            <Tab>Billing</Tab>
            <Tab>Feature Flags</Tab>
            <Tab>Copy Assets</Tab>
            <Tab>Cost Report</Tab>
            <Tab>Agility Apps</Tab>
            <Tab>TLM Usage</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <UserDataGrid />
            </TabPanel>
            <TabPanel>
              <SignupSettings />
            </TabPanel>
            <TabPanel>
              <UserQuotaGrid />
            </TabPanel>
            <TabPanel>
              <QueryInterface />
            </TabPanel>
            <TabPanel>
              <UserEventMetrics />
            </TabPanel>
            <TabPanel>
              <Billing />
            </TabPanel>
            <TabPanel>
              <FeatureFlagGrid />
            </TabPanel>
            <TabPanel>
              <CopyAsset />
            </TabPanel>
            <TabPanel>
              <CostReport />
            </TabPanel>
            <TabPanel>
              <AgilityApps />
            </TabPanel>
            <TabPanel>
              <TlmUsage />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Layout>
  )
}

export default AdminPanel
