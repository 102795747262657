import { Spinner } from '@chakra-ui/react'
import { IconWarning } from '@components/icons'
import { cn } from '@utils/tailwindUtils'

import { ErrorMessage } from './ErrorMessage'
export interface ErrorsCardProps {
  errorType?: string
  errorMessage: string
}

const DEFAULT_ERROR_TYPE = 'Internal server error'

const IngestionErrorsCard = ({ errorType, errorMessage }: ErrorsCardProps) => {
  return (
    <div
      className={cn(
        'self-between flex h-full w-full flex-col gap-4 rounded-2 border border-border-1 bg-surface-1 p-6'
      )}
    >
      <div className="flex items-center gap-4">
        <IconWarning className="text-red-600" size={20} />
        <h3 className="type-body-200-medium text-text-strong">
          {errorType ? <>Error: {errorType}</> : DEFAULT_ERROR_TYPE}
        </h3>
      </div>

      {errorMessage ? (
        <div className="flex flex-col gap-4">
          <ErrorMessage errorMessage={errorMessage} defaultHidden={!errorType} />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default IngestionErrorsCard
