import { Box, HStack, Text } from '@chakra-ui/react'
import { useBetaFeatureButton } from '@common/misc/betaFeatureButton/useBetaFeatureButton'
import BetaFeatureModal from '@common/modals/betaFeatureModal/BetaFeatureModal'
import { Button } from '@components/button/Button'
import PrimaryButtonWithIcon from '@components/buttons/primaryButtonWithIcon/PrimaryButtonWithIcon'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { RowCountType } from '@services/datasheet/constants'
import { useRowCount } from '@services/datasheet/queries'
import { useOrgCostUsageRatio } from '@services/organizationQuotas/queries'
import { useSetProjectTemplateStatusMutation } from '@services/project/mutations'
import { useProjectTemplateStatus } from '@services/project/queries'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { useContext } from 'react'
import { FiActivity, FiDownload, FiPlay } from 'react-icons/fi'
import { GrTemplate } from 'react-icons/gr'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import testIds from '../../../../playwright/test_ids.json'
import { CleansetContext } from '../../CleansetContext'
import RerunCleanlabButton from '../../rerunCleanlabButton/RerunCleanlabButton'
import { DatasheetActionButtonsProps } from './DatasheetActionButtons.types'

const DatasheetActionButtons = (props: DatasheetActionButtonsProps) => {
  const {
    cleansetId,
    gridApi,
    projectDetails,
    onBatchActionsOpen,
    onExportOpen,
    onDeployModelOpen,
    isTemplate,
    setShowTooltip,
    numIssuesResolved,
    firstGridDataRendered,
  } = props

  const { numRows: numRowsDisplayed } = useRowCount(
    cleansetId,
    RowCountType.CUSTOM,
    gridApi,
    firstGridDataRendered
  )

  const { numRows: totalRows } = useRowCount(
    cleansetId,
    RowCountType.ISSUES,
    gridApi,
    firstGridDataRendered
  )

  const displayedRowCount = gridApi?.getDisplayedRowCount() ?? 0

  const { trackEvent } = useEventTracking()
  const { featuresDisabled, numModelsAvailable, onSubscriptionModalOpen } = useSubscription()
  const templateStatus = useProjectTemplateStatus(projectDetails.projectId)
  const { mutate: setTemplateStatus } = useSetProjectTemplateStatusMutation(
    projectDetails.projectId
  )
  const cleansetInfo = useContext(CleansetContext)
  const { costUsageRatio } = useOrgCostUsageRatio()

  const { isOpen, onButtonClick, onCloseModal, featureName } =
    useBetaFeatureButton('Model Deployment')

  const deploymentEnabledForTasktype = (tasktype: string): boolean => {
    return tasktype === Tasktype.MULTICLASS || tasktype === Tasktype.MULTILABEL
  }

  return (
    <HStack>
      <Tooltip content={<Text>Export your cleaned Dataset via CSV download or API</Text>}>
        <Box className="cleanset-tour-export-button">
          <PrimaryButtonWithIcon
            height="32px"
            fontSize="sm"
            leftIcon={<FiDownload />}
            data-testid={testIds.cleansetExportPageExportButton}
            onClick={() => {
              trackEvent(MixpanelEvents.clickExportCleansetButton, {
                ...cleansetInfo,
                isTemplate: isTemplate,
              })
              onExportOpen()
            }}
          >
            Export Cleanset
          </PrimaryButtonWithIcon>
        </Box>
      </Tooltip>
      {checkFeatureFlag('TEMPLATE_PROJECT_ENABLED') && templateStatus !== null && (
        <Button
          onClick={() => setTemplateStatus(!templateStatus)}
          variant="secondary"
          iconStart={<GrTemplate />}
          aria-label={templateStatus ? 'Remove Project as Template' : 'Set Project as Template'}
        >
          {templateStatus ? 'Remove Project as Template' : 'Set Project as Template'}
        </Button>
      )}
      {projectDetails.tasktype !== Tasktype.UNSUPERVISED && (
        <>
          <Tooltip
            content={
              !numRowsDisplayed
                ? 'Select at least one data point using the filter panel'
                : 'Apply corrections to many data points at once'
            }
          >
            <Button
              variant="secondary"
              onClick={onBatchActionsOpen}
              iconStart={<FiActivity />}
              disabled={displayedRowCount < 1}
              aria-label="autofix top issues"
            >
              Clean Top K
            </Button>
          </Tooltip>
          <RerunCleanlabButton
            projectId={projectDetails.projectId}
            datasetId={projectDetails.datasetId}
            cleansetId={cleansetId}
            setShowTooltip={setShowTooltip}
            numIssuesResolved={numIssuesResolved}
            totalIssues={totalRows}
            isTemplate={projectDetails.isTemplate}
          />
          {projectDetails.modality !== 'image' &&
          deploymentEnabledForTasktype(projectDetails.tasktype) ? (
            <Tooltip
              content={
                <Text>
                  Train a more accurate model automatically using state-of-the-art machine learning
                  on the edits you made. Model will be deployed automatically for inference on new
                  data points.
                </Text>
              }
            >
              <Button
                variant="secondary"
                data-testid={testIds.cleansetDeployModelButton}
                disabled={costUsageRatio >= 1}
                onClick={() => {
                  trackEvent(MixpanelEvents.clickDeployModelButton, {
                    ...cleansetInfo,
                    isTemplate: isTemplate,
                  })
                  if (featuresDisabled || numModelsAvailable < 1) {
                    onSubscriptionModalOpen()
                  } else {
                    onDeployModelOpen()
                  }
                }}
                iconStart={<FiPlay />}
              >
                Train Improved Model
              </Button>
            </Tooltip>
          ) : (
            <Button variant="secondary" onClick={onButtonClick} iconStart={<FiPlay />}>
              Train Improved Model (Beta)
            </Button>
          )}
        </>
      )}
      <BetaFeatureModal isOpen={isOpen} onClose={onCloseModal} featureName={featureName} />
    </HStack>
  )
}

export default DatasheetActionButtons
