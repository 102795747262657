import { ColumnDataItemProps } from './ColumnDataItem.types'

export const ColumnDataItem = (props: ColumnDataItemProps) => {
  const { columnData } = props
  const spanClass = 'type-body-100 text-text-faint'
  const titleClass = 'type-body-100-medium text-text-primary'
  return (
    <div className="flex w-full flex-col items-start gap-1">
      <p className={titleClass}>
        {columnData.title.title}:{' '}
        <span className={spanClass}>{columnData.title.value ? 'True' : 'False'}</span>
      </p>
      <p className={titleClass}>
        Score:{' '}
        <span className={spanClass}>
          {columnData.score ? columnData.score.toFixed(3) : '0.000'}
        </span>
      </p>
      <div className="flex flex-col items-start gap-1">
        {columnData.additionalInfo.map(
          (info) =>
            info?.field.length > 0 &&
            info?.value > 0 &&
            `${info.value}`.length > 0 && (
              <div className="flex" key={info.field}>
                <p className={titleClass}>
                  {info.field}:{' '}
                  <span className={spanClass}>
                    {typeof info.value === 'number' ? `${info.value.toFixed(3)}` : `${info.value}`}
                  </span>
                </p>
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default ColumnDataItem
