import { IconArrowDown, IconArrowUp } from '@components/icons'
import { Column } from 'ag-grid-community'

export const getSortIndicator = (column: Column) => {
  if (column.isSortAscending()) {
    return <IconArrowUp />
  } else if (column.isSortDescending()) {
    return <IconArrowDown />
  } else {
    return <></>
  }
}
