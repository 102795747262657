import { defineStyleConfig, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    _disabled: {
      cursor: 'not-allowed',
    },
  },
  variants: {
    primary: (props: StyleFunctionProps) => ({
      borderColor: mode('blue.700', 'blueDarkMode.700')(props),
      bg: mode('blue.600', 'blueDarkMode.600')(props),
      color: mode('white', 'neutralDarkMode.0')(props),
      boxShadow: mode(
        '0px 2px 3px 0px rgba(23, 25, 28, 0.04), 0px 1px 1px -1px rgba(23, 25, 28, 0.08)',
        '0px 2px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 2px -1px rgba(243, 246, 247, 0.16)'
      )(props),
      _hover: {
        bg: mode('blue.700', 'blueDarkMode.700')(props),
        borderColor: mode('blue.800', 'blueDarkMode.800')(props),
        _disabled: {
          bg: mode('neutral.200', 'neutralDarkMode.200')(props),
          borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
          color: mode('neutral.500', 'neutralDarkMode.500')(props),
        },
      },
      _groupHover: {
        bg: mode('blue.700', 'blueDarkMode.700')(props),
        borderColor: mode('blue.800', 'blueDarkMode.800')(props),
      },
      _active: {
        bg: mode('blue.800', 'blueDarkMode.800')(props),
        borderColor: mode('blue.800', 'blueDarkMode.800')(props),
        color: mode('neutral.0', 'neutralDarkMode.0')(props),
      },
      _groupActive: {
        bg: mode('blue.800', 'blueDarkMode.800')(props),
        borderColor: mode('blue.800', 'blueDarkMode.800')(props),
        color: mode('neutral.0', 'neutralDarkMode.0')(props),
      },
      _disabled: {
        bg: mode('neutral.200', 'neutralDarkMode.200')(props),
        borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
        color: mode('neutral.500', 'neutralDarkMode.500')(props),
      },
      _focusVisible: {
        borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
        color: mode('neutral.0', 'neutralDarkMode.0')(props),
        border: '2px solid',
      },
    }),
    secondary: (props: StyleFunctionProps) => ({
      color: mode('neutral.800', 'neutralDarkMode.800')(props),
      bg: mode('neutral.50', 'neutralDarkMode.50')(props),
      boxShadow: mode(
        '0px 2px 3px 0px rgba(23, 25, 28, 0.04), 0px 1px 1px -1px rgba(23, 25, 28, 0.08)',
        '0px 2px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 2px -1px rgba(243, 246, 247, 0.16)'
      )(props),
      border: '1px solid',
      borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
      _hover: {
        bg: mode('neutral.100', 'neutralDarkMode.100')(props),
        borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
      },
      _groupHover: {
        bg: mode('neutral.100', 'neutralDarkMode.100')(props),
        borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
      },
      _active: {
        bg: mode('neutral.200', 'neutralDarkMode.200')(props),
        borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
      },
      _groupActive: {
        bg: mode('neutral.200', 'neutralDarkMode.200')(props),
        borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
      },
    }),
    destructive: (props: StyleFunctionProps) => ({
      border: '1px solid',
      bg: mode('red.50', 'redDarkMode.50')(props),
      color: mode('red.700', 'redDarkMode.700')(props),
      borderColor: mode('red.600', 'redDarkMode.600')(props),
      _hover: {
        bg: mode('red.600', 'redDarkMode.600')(props),
        borderColor: mode('red.700', 'redDarkMode.700')(props),
        color: mode('red.50', 'redDarkMode.50')(props),
      },
      _active: {
        bg: mode('red.700', 'redDarkMode.700')(props),
        borderColor: mode('red.700', 'redDarkMode.700')(props),
        color: mode('red.50', 'redDarkMode.50')(props),
      },
      _disabled: {
        bg: mode('neutral.200', 'neutralDarkMode.200')(props),
        borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
        color: mode('neutral.500', 'neutralDarkMode.500')(props),
      },
      _focusVisible: {
        border: '1px solid',
        borderColor: mode('blue.500', 'blueDarkMode.500')(props),
        boxShadow: 'none',
      },
    }),
    error: (props: StyleFunctionProps) => ({
      color: mode('red.600', 'redDarkMode.600')(props),
      _hover: {
        color: mode('red.700', 'redDarkMode.700')(props),
      },
      _disabled: {
        opacity: 1,
      },
      padding: 0,
    }),
    link: (props: StyleFunctionProps) => ({
      _hover: {
        textDecoration: 'underline',
        color: mode('blue.800', 'blueDarkMode.800')(props),
      },
    }),
    segmented: (props: StyleFunctionProps) => ({
      fontSize: 'xs',
      height: '100%',
      width: '50%',
      color: mode('gray.600', 'gray.100')(props),
      borderRadius: 'md',
      _hover: {
        bg: mode('gray.50', 'gray.900')(props),
        color: mode('gray.700', 'gray.100')(props),
      },
      _selected: {
        borderColor: mode('gray.200', 'gray.200')(props),
        bg: mode('gray.200', 'gray.200')(props),
      },
      _active: {
        bg: mode('gray.200', 'gray.600')(props),
        color: mode('gray.800', 'gray.100')(props),
        _hover: {
          color: mode('gray.900', 'gray.200')(props),
          bg: mode('gray.200', 'gray.500')(props),
        },
      },
      _focusVisible: {
        border: '1px solid',
        borderColor: mode('blue.500', 'blue.300')(props),
        boxShadow: 'none',
      },
    }),
    collapseCard: (props: StyleFunctionProps) => ({
      fontSize: 'xs',
      padding: '6px',
      borderRadius: '8px',
      borderWidth: '0.75px',
      color: mode('neutral.700', 'neutralDarkMode.700')(props),
      bg: mode('neutral.50', 'neutralDarkMode.50')(props),
      borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
      _hover: {
        bg: mode('neutral.100', 'neutralDarkMode.100')(props),
      },
      _active: {
        bg: mode('neutral.200', 'neutralDarkMode.200')(props),
      },
    }),
    'icon-button': (props: StyleFunctionProps) => ({
      border: '1px solid',
      borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
      bg: mode('neutral.50', 'neutralDarkMode.50')(props),
      borderRadius: '8px',
      _hover: {
        bg: mode('neutral.100', 'neutralDarkMode.100')(props),
      },
      _active: {
        bg: mode('neutral.200', 'neutralDarkMode.200')(props),
      },
    }),
    'resolver-icon-button': (props: StyleFunctionProps) => ({
      minWidth: '24px',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
      background: mode('neutral.50', 'neutralDarkMode.50')(props),
      boxSize: '24px',
      boxShadow: '0px 2px 3px 0px rgba(23, 25, 28, 0.04), 0px 1px 1px -1px rgba(23, 25, 28, 0.08)',
    }),
    'palette-button': (props: StyleFunctionProps) => ({
      fontWeight: '400',
      fontSize: 'sm',
      width: '100%',
      height: '36px',
      border: '1px solid',
      borderRadius: '8px',
      background: mode('white', 'black')(props),
      borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
      boxShadow: '0px 2px 3px 0px rgba(23, 25, 28, 0.04), 0px 1px 1px -1px rgba(23, 25, 28, 0.08)',
      px: '12px',
      py: '8px',
    }),
    'stepper-skip-button': (props: StyleFunctionProps) => ({
      fontWeight: '500',
      fontSize: 'sm',
      width: '54px',
      height: '32px',
      border: '1px solid',
      borderRadius: '8px',
      background: mode('neutral.50', 'neutralDarkMode.50')(props),
      borderColor: mode('neutral.200', 'neutralDarkMode.200')(props),
      boxShadow: '0px 2px 3px 0px rgba(23, 25, 28, 0.04), 0px 1px 1px -1px rgba(23, 25, 28, 0.08)',
      px: '12px',
      py: '8px',
      _hover: {
        bg: mode('neutral.100', 'neutralDarkMode.100')(props),
      },
    }),
    'tlm-action-button': (props: StyleFunctionProps) => ({
      fontWeight: '500',
      fontSize: '16px',
      padding: '8px 16px 8px 20px',
      border: '1px solid',
      borderRadius: '8px',
      background: mode('neutral.0', 'neutralDarkMode.0')(props),
      borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
      boxShadow: '0px 1px 1px -1px rgba(23, 25, 28, 0.08), 0px 2px 3px 0px rgba(23, 25, 28, 0.04)',
      color: mode('neutral.900', 'neutralDarkMode.900')(props),
      height: '40px',

      _hover: {
        background: mode('neutral.50', 'neutralDarkMode.50')(props),
      },

      _active: {
        background: mode('neutral.100', 'neutralDarkMode.100')(props),
      },

      _focus: {
        borderColor: mode('blue.700', 'blueDarkMode.700')(props),
        boxShadow: mode(
          '0px 0px 0px 2px #3C82F6 !important',
          '0px 0px 0px 2px #3FA6F6 !important'
        )(props),
      },
    }),
    columnSelectButton: (props: StyleFunctionProps) => ({
      color: mode('neutral.900', 'neutralDarkMode.900')(props),
      bg: 'transparent',
      fontSize: '14px',
      borderRadius: '8px',
      fontWeight: '400',
      lineHeight: '20px',
      padding: '6px 8px',
      _hover: {
        bg: mode('neutral.100', 'neutralDarkMode.100')(props),
      },
    }),
  },
})

export const closeButtonTheme = defineStyleConfig({
  variants: {
    transparent: {
      background: 'transparent',
      _hover: {
        background: 'transparent',
      },
      _active: {
        background: 'transparent',
      },
      _focus: {
        background: 'transparent',
      },
    },
  },
})
