import { Box, FormControl, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import { useAuth } from '@hooks/useAuth'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { queryKeys } from '@services/billing/constants'
import { useCardDetails } from '@services/billing/queries'
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { BillingInfoFormProps } from './BillingInfoForm.types'

const BillingInfoForm = (props: BillingInfoFormProps) => {
  const {
    onCardAdded,
    setCollectPayment,
    setHasCard,
    setIsAddingCard,
    setCollectCardDetails,
    setStripeLoading,
  } = props

  const stripe = useStripe()
  const elements = useElements()

  const [stripeElementsReady, setStripeElementsReady] = useState<boolean>(false)

  const stripeElements = elements?.getElement('payment')

  stripeElements?.on('ready', () => {
    setStripeElementsReady(true)
    setStripeLoading?.(false)
  })

  const stripeTheme = useColorModeValue('stripe', 'night')

  elements?.update({ appearance: { theme: stripeTheme } })

  const { trackEvent } = useEventTracking()
  const { isAuthenticated } = useAuth()
  const details = useCardDetails(isAuthenticated)
  const queryClient = useQueryClient()

  const [message, setMessage] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsLoading(true)

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setIsLoading(false)
      setMessage(error.message)
    } else if (setupIntent) {
      switch (setupIntent.status) {
        case 'succeeded':
          trackEvent(MixpanelEvents.clickAddCardButton)
          setTimeout(() => {
            setCollectCardDetails && setCollectCardDetails(false)
            setHasCard?.(true)
            setIsAddingCard?.(false)
            setIsLoading(false)
            setCollectPayment(false)
            queryClient.invalidateQueries(queryKeys.billing.cardDetails())
            onCardAdded?.()
          }, 3000)
          break

        case 'processing':
          setMessage("Processing payment details. We'll update you when processing is complete.")
          break
      }
    }
  }

  return (
    <FormControl minH={450} position="relative" w="100%" id="billing-info-form">
      <HStack spacing="50px" justify="space-between" alignItems="flex-start" w="100%">
        <AddressElement
          options={{
            mode: 'billing',
            defaultValues: {
              name: details.name,
              address: {
                line1: details.line1,
                line2: details.line2,
                city: details.city,
                state: details.state,
                country: details.country,
                postal_code: details.postalCode,
              },
            },
          }}
        />
        <PaymentElement id="payment-element" />
      </HStack>
      {stripeElementsReady && (
        <Box position="absolute" right={0} bottom={0}>
          <PrimaryButton
            onClick={handleSubmit}
            height="36px"
            isLoading={isLoading || !stripeElementsReady}
            isDisabled={isLoading || !stripeElementsReady || !stripe || !elements}
          >
            {`Add Card ${onCardAdded ? '& Upgrade' : ''}`}
          </PrimaryButton>
        </Box>
      )}
      {message && <Text id="payment-message">{message}</Text>}
    </FormControl>
  )
}
export default BillingInfoForm
