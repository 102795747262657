import { defineStyleConfig, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

export const linkTheme = defineStyleConfig({
  baseStyle: {}, // TODO: replace baseStyle with standard variant when ready to switch all buttones
  variants: {
    standard: (props: StyleFunctionProps) => ({
      color: mode('blue.700', 'blueDarkMode.700')(props),
      _hover: { color: mode('blue.800', 'blueDarkMode.800')(props) },
    }),
    // Handy for links in tooltips
    reversed: (props: StyleFunctionProps) => ({
      color: mode('blueDarkMode.700', 'blue.700')(props),
      _hover: { color: mode('blueDarkMode.800', 'blue.800')(props) },
    }),
  },
})
