import { Button as ChakraButton } from '@chakra-ui/react'

import { PrimaryButtonProps } from './PrimaryButton.types'

const PrimaryButton = (props: PrimaryButtonProps) => {
  const {
    children,
    isDisabled,
    isLoading,
    height,
    fontSize,
    type,
    dataTestId,
    ariaLabel,
    onClick,
  } = props
  return (
    <ChakraButton
      isDisabled={isDisabled}
      isLoading={isLoading}
      height={height}
      fontSize={fontSize}
      onClick={onClick}
      variant="primary"
      type={type}
      data-testid={dataTestId}
      aria-label={ariaLabel}
    >
      <span>{children}</span>
    </ChakraButton>
  )
}

export default PrimaryButton
