import { CliUpload } from './cliUpload/CliUpload'
import { LocalUpload } from './localUpload/LocalUpload'
import { PythonApiUpload } from './pythonApiUpload/PythonApiUpload'
import { UrlUpload } from './urlUpload/UrlUpload'

export enum UploadType {
  computer = 'computer',
  url = 'url',
  commandLine = 'commandLine',
  pythonApi = 'pythonApi',
  databricks = 'databricks',
  snowflake = 'snowflake',
  undefined = '',
}

export const UPLOAD_PATH_NAME = 'import'
export const UPLOAD_TYPE_PATHS = ['local', 'url', 'cli', 'api', 'warehouse', 'cloud'] as const
export type UploadTypePath = (typeof UPLOAD_TYPE_PATHS)[number]

export const UPLOAD_TYPES_TO_PATHS = {
  [UploadType.computer]: 'local',
  [UploadType.url]: 'url',
  [UploadType.commandLine]: 'cli',
  [UploadType.pythonApi]: 'api',
  [UploadType.databricks]: '',
  [UploadType.snowflake]: '',
  [UploadType.undefined]: '',
} as const satisfies Record<UploadType, UploadTypePath | ''>

export const UPLOAD_PATHS_TO_TYPES = Object.fromEntries(
  Object.entries(UPLOAD_TYPES_TO_PATHS).map(([key, value]) => [value, key])
) as unknown as Record<
  (typeof UPLOAD_TYPES_TO_PATHS)[keyof typeof UPLOAD_TYPES_TO_PATHS],
  UploadType
>

const NullElement = () => null

export const UPLOAD_TYPES_TO_ELEMENTS = {
  [UploadType.pythonApi]: <PythonApiUpload />,
  [UploadType.commandLine]: <CliUpload />,
  [UploadType.computer]: <LocalUpload />,
  [UploadType.url]: <UrlUpload />,
  [UploadType.databricks]: <NullElement />,
  [UploadType.snowflake]: <NullElement />,
  [UploadType.undefined]: <NullElement />,
} as const satisfies Record<UploadType, any>

export enum Tasktype {
  MULTICLASS = 'multi-class',
  MULTILABEL = 'multi-label',
  UNSUPERVISED = 'unsupervised',
}

export const Modalities = ['text', 'tabular', 'image'] // audio, predictive probabilities, model embeddings
