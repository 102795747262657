import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { InlineLink } from 'src/pages/projectForm/Sidebar'

import {
  salesEmail,
  salesMailToLinkWithSubject,
  slackCommunityInviteLink,
  supportEmail,
  supportMailToLinkWithSubject,
} from './BetaFeatureModal.helpers'
import { BetaFeatureModalProps } from './BetaFeatureModal.types'

const BetaFeatureModal = (props: BetaFeatureModalProps) => {
  const { isOpen, onClose, featureName } = props

  const { trackEvent } = useEventTracking()
  const { isEnterprisePlan, payingCustomer } = useSubscription()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{featureName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          {isEnterprisePlan && payingCustomer ? (
            <>
              <p className="type-body-200 text-text-primary">
                The <b className="type-body-200-medium">{featureName}</b> feature is currently in
                beta.
              </p>
              <p className="type-body-200 text-text-primary">
                To gain access to this feature, reach out to{' '}
                <InlineLink
                  to={supportMailToLinkWithSubject(featureName)}
                  onClick={() =>
                    trackEvent(MixpanelEvents.clickEmailTeamLink, { featureName: featureName })
                  }
                >
                  {supportEmail}
                </InlineLink>{' '}
                or message us directly on{' '}
                <InlineLink to={slackCommunityInviteLink}>Slack</InlineLink>.
              </p>
            </>
          ) : (
            <>
              <p className="type-body-200-medium text-text-primary">
                Early access to the <b>{featureName}</b> feature is currently only available to
                Cleanlab Studio users on the Enterprise plan.
              </p>
              <p className="type-body-200 text-text-primary">
                If you would like to join Cleanlab Studio Enterprise and gain access to this
                feature, please email{' '}
                <InlineLink
                  to={salesMailToLinkWithSubject(featureName)}
                  onClick={() =>
                    trackEvent(MixpanelEvents.clickEmailTeamLink, { featureName: featureName })
                  }
                >
                  {salesEmail}
                </InlineLink>
              </p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="flex flex-row-reverse gap-5">
            <Button variant="secondary" onClick={onClose} aria-label="close">
              Close
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default BetaFeatureModal
