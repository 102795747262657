import { UploadType } from '../DatasetUpload.types'
import { UploadTypeCard } from '../uploadTypeCard/UploadTypeCard'
import { UPLOAD_CARD_CONTENT } from '../uploadTypeCard/UploadTypeCard.types'

export const UploadTypeOptions = () => {
  return (
    <div className="grid grid-cols-2 gap-8">
      {Object.entries(UPLOAD_CARD_CONTENT).map(([uploadType, content]) => (
        <UploadTypeCard key={uploadType} uploadType={uploadType as UploadType} {...content} />
      ))}
    </div>
  )
}
