import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { cn } from '@utils/tailwindUtils'
import pluralize from 'pluralize'
import { ReactNode } from 'react'
import { LoaderFunction, redirect } from 'react-router-dom'

import { THIS_STEP } from '../CreateProjectSelectMLPrefs'
import {
  ProjectWizardStep,
  ProjectWizardTextStepper,
  useProjectWizardStore,
} from './ProjectWizard.store'

export const createProjectSelectMLPrefsLoader: LoaderFunction = async ({ params }) => {
  const { datasetId } = params
  const { stepMayProceed } = useProjectWizardStore.getState()

  if (!datasetId) {
    return redirect('/clean')
  }
  if (!stepMayProceed(THIS_STEP)) {
    return redirect(`/clean/${datasetId}/label`)
  }
  return null
}
const ProjectsRemainingInner = () => {
  const { numProjectsAvailable, freeTrialPeriod, payingCustomer } = useSubscription()

  if (numProjectsAvailable <= 0 || (!freeTrialPeriod && !payingCustomer)) {
    return null
  }
  let suffix = ''
  if (payingCustomer) {
    suffix = ' on your plan'
  } else if (freeTrialPeriod) {
    suffix = ' on your free trial'
  }

  return (
    <span>
      {numProjectsAvailable || 0} {pluralize('Project', numProjectsAvailable)} remaining{suffix}.
    </span>
  )
}

const ProjectsRemaining = ({ prefix }: { prefix: string }) => {
  if (IS_VPC_ENVIRONMENT) {
    return null
  }
  return (
    <>
      {prefix}
      <ProjectsRemainingInner />
    </>
  )
}

export const ProjectWizardActions = ({
  className,
  children,
  step,
  stepCount,
}: {
  className?: string
  children?: ReactNode
  step?: ProjectWizardStep | number
  stepCount?: number
}) => {
  return (
    <div className={cn('flex items-center justify-end gap-6', className)}>
      <div className="type-body-100 grow text-text-faint">
        <ProjectWizardTextStepper step={step} stepCount={stepCount} />
        <ProjectsRemaining prefix={step ? ' | ' : ''} />
      </div>
      <div className="flex flex-row-reverse justify-end gap-6">{children}</div>
    </div>
  )
}
