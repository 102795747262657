import { Button, useDisclosure } from '@chakra-ui/react'
import EditUserModal from '@common/modals/editUserModal/EditUserModal'
import UpdateAccountStatusModal from '@common/modals/updateAccountStatusModal/UpdateAccountStatusModal'
import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconMoreHorizontal } from '@components/icons'
import { AccountStatus } from '@services/user/constants'
import { useMyself } from '@services/user/queries'
import Tippy from '@tippy.js/react'
import { ICellRendererParams } from 'ag-grid-community'
import { forwardRef, LegacyRef, useState } from 'react'

interface VpcGridContextMenuProps {
  params: ICellRendererParams
}

const VpcGridContextMenu = (props: VpcGridContextMenuProps) => {
  const { params } = props

  const { id: userId } = useMyself()

  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const accountStatus = params.data.accountStatus
  const isCurrentUser = params.data.id === userId
  const isAdded = accountStatus === AccountStatus.INVITED
  const isDeactivated = accountStatus === AccountStatus.DEACTIVATED

  const {
    isOpen: isEditUserOpen,
    onOpen: onEditUserOpen,
    onClose: onEditUserClose,
  } = useDisclosure()

  const {
    isOpen: isUpdateUserOpen,
    onOpen: onUpdateUserOpen,
    onClose: onUpdateUserClose,
  } = useDisclosure()

  const dropdownContent = (
    <div className="flex flex-col gap-2 rounded-2 border border-border-1 bg-surface-0 p-4">
      <Button
        variant="columnSelectButton"
        justifyContent="flex-start"
        isDisabled={isCurrentUser} // Current admin cannot demote or deactivate themselves
        w="100%"
        onClick={() => {
          setVisible(false)
          onEditUserOpen()
        }}
      >
        <p className={isCurrentUser ? 'text-text-disabled' : 'text-text-primary'}>Edit user</p>
      </Button>
      <Button
        variant="columnSelectButton"
        justifyContent="flex-start"
        w="100%"
        onClick={() => {
          setVisible(false)
          onUpdateUserOpen()
        }}
        isDisabled={isCurrentUser}
      >
        <p
          className={
            isCurrentUser
              ? 'text-text-disabled'
              : isDeactivated
                ? 'text-text-primary'
                : 'text-red-600'
          }
        >
          {isAdded ? 'Delete user' : isDeactivated ? 'Reactivate user' : 'Deactivate user'}
        </p>
      </Button>
    </div>
  )

  const MenuButton = forwardRef((props, ref) => {
    return (
      <div
        className="flex items-center"
        {...props}
        ref={ref as LegacyRef<HTMLDivElement> | undefined}
      >
        <IconFrameButton
          variant="level0"
          size="xSmall"
          icon={<IconMoreHorizontal />}
          aria-label="Open more menu"
          onClick={() => (visible ? hide() : show())}
        />
      </div>
    )
  })

  return (
    <>
      <Tippy
        content={dropdownContent}
        visible={visible}
        arrow={false}
        appendTo={document.body}
        interactive={true}
        hideOnClick={true}
      >
        <MenuButton />
      </Tippy>
      <EditUserModal
        isOpen={isEditUserOpen}
        onClose={onEditUserClose}
        userId={params.data.id}
        accessLevel={params.data.accessLevel}
        accountStatus={params.data.accountStatus}
        name={params.data.name}
        email={params.data.email}
        isCurrentUser={isCurrentUser}
      />
      <UpdateAccountStatusModal
        isOpen={isUpdateUserOpen}
        onClose={onUpdateUserClose}
        userId={params.data.id}
        email={params.data.email}
        accountStatus={params.data.accountStatus}
      />
    </>
  )
}

export default VpcGridContextMenu
