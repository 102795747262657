import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { AgilityApp } from '@services/agilityApps/constants'
import React from 'react'

import { AgilityAppForm } from '../AgilityAppForm'

interface CreateAppModalProps {
  formData: Partial<AgilityApp>
  onFormDataChange: (data: Partial<AgilityApp>) => void
  isOpen: boolean
  onClose: () => void
  onSubmit: (newApp: AgilityApp) => void
}

const CreateAppModal: React.FC<CreateAppModalProps> = ({
  formData,
  isOpen,
  onClose,
  onSubmit,
  onFormDataChange,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Agility App</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AgilityAppForm
            initialData={formData}
            onSubmit={onSubmit}
            isUpdate={false}
            onFormDataChange={onFormDataChange}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateAppModal
