import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

export const inputTheme = defineMultiStyleConfig({
  baseStyle: {}, // TODO: replace baseStyle with standard variant when ready to switch all checkboxes
  variants: {
    standard: (props: StyleFunctionProps) => ({
      field: {
        color: mode('gray.700', 'grayDarkModeDeprecated.700')(props),
        bg: mode('gray.100', 'grayDarkModeDeprecated.100')(props),
        borderWidth: '1px',
        borderColor: mode('gray.300', 'grayDarkModeDeprecated.300')(props),
        borderRadius: '4px',
        fontWeight: '84px',
        padding: '8px',
        _focus: {
          color: mode('gray.900', 'grayDarkModeDeprecated.900')(props),
          borderColor: mode('blue.600', 'blue.200')(props),
        },
      },
    }),
    form: (props: StyleFunctionProps) => ({
      field: {
        color: mode('neutral.900', 'neutralDarkMode.900')(props),
        background: mode('neutral.50', 'neutralDarkMode.50')(props),
        borderWidth: '1px',
        borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
        borderRadius: '8px',
        fontWeight: '400',
        padding: '12px',
        fontSize: '14px',
        lineHeight: '21px',

        _placeholder: {
          color: mode('neutral.500', 'neutralDarkMode.500')(props),
        },

        _hover: {
          background: mode('neutral.100', 'neutralDarkMode.100')(props),
        },

        _active: {
          background: mode('neutral.50', 'neutralDarkMode.50')(props),
          borderColor: mode('blue.700', 'blueDarkMode.700')(props),
          boxShadow: mode('0px 0px 0px 2px #3C82F6', '0px 0px 0px 2px #3FA6F6')(props),
        },

        _focus: {
          background: mode('neutral.50', 'neutralDarkMode.50')(props),
          borderColor: mode('blue.700', 'blueDarkMode.700')(props),
          boxShadow: mode('0px 0px 0px 2px #3C82F6', '0px 0px 0px 2px #3FA6F6')(props),
        },
      },
    }),
    phone: (props: StyleFunctionProps) => ({
      field: {
        color: mode('neutral.900', 'neutralDarkMode.900')(props),
        background: mode('neutral.50', 'neutralDarkMode.50')(props),
        borderWidth: '1px',
        borderLeftWidth: '0.5px',
        borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
        borderRadius: '8px',
        borderLeftRadius: '0px',
        fontWeight: '400',
        padding: '12px',
        fontSize: '14px',
        lineHeight: '21px',

        _placeholder: {
          color: mode('neutral.500', 'neutralDarkMode.500')(props),
        },

        _hover: {
          background: mode('neutral.100', 'neutralDarkMode.100')(props),
        },

        _active: {
          background: mode('neutral.50', 'neutralDarkMode.50')(props),
          borderColor: mode('blue.700', 'blueDarkMode.700')(props),
          boxShadow: mode('0px 0px 0px 2px #3C82F6', '0px 0px 0px 2px #3FA6F6')(props),
        },

        _focus: {
          background: mode('neutral.50', 'neutralDarkMode.50')(props),
          borderColor: mode('blue.700', 'blueDarkMode.700')(props),
          boxShadow: mode('0px 0px 0px 2px #3C82F6', '0px 0px 0px 2px #3FA6F6')(props),
        },
      },
    }),
    disabled: (props: StyleFunctionProps) => ({
      field: {
        color: mode('neutral.500', 'neutralDarkMode.500')(props),
        background: mode('neutral.200', 'neutralDarkMode.200')(props),
        borderWidth: '1px',
        borderColor: mode('neutral.300', 'neutralDarkMode.300')(props),
        borderRadius: '8px',
        fontWeight: '400',
        padding: '12px',
        fontSize: '14px',
        lineHeight: '21px',
        cursor: 'not-allowed',
        boxShadow:
          '0px 1px 1px -1px rgba(23, 25, 28, 0.08), 0px 2px 3px 0px rgba(23, 25, 28, 0.04)',
      },
    }),
  },
})
