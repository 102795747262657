import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'
import React from 'react'

import { ConfirmSignupSettingsModalProps } from './ConfirmSignupSettingsModal.types'

const ConfirmSignupSettingsModal = (props: ConfirmSignupSettingsModalProps) => {
  const { isOpen, onClose, onConfirm, accountActivationEnabled, allowedActivationsPerDay } = props

  const modalBg = useColorModeValue('neutral.100', 'neutralDarkMode.100')
  const warningColor = useColorModeValue('orange.600', 'orangeDarkMode.600')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent bg={modalBg} pb="1rem">
        <ModalHeader>Confirm Signup Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="sm">
          <VStack alignItems="flex-start" spacing="24px">
            <Text color={warningColor}>
              Warning: clicking Confirm will update the signup settings for all incoming users.
              Please confirm that you intend to make this change:
            </Text>
            <Box fontWeight="bold">
              <Text>Account activation enabled: {accountActivationEnabled}</Text>
              <Text>Allowed activations per day: {allowedActivationsPerDay}</Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end">
            <SecondaryButton height="32px" onClick={onClose}>
              Cancel
            </SecondaryButton>

            <PrimaryButton height="32px" onClick={onConfirm}>
              Confirm
            </PrimaryButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmSignupSettingsModal
