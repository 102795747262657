import { dataLoaderJsonResponse } from '@utils/dataLoaders/dataLoaderJsonResponse'
import { RouteObject } from 'react-router-dom'

import {
  UPLOAD_PATH_NAME,
  UPLOAD_TYPES_TO_ELEMENTS,
  UPLOAD_TYPES_TO_PATHS,
  UploadType,
} from './pages/datasetUpload/DatasetUpload.types'
import { DatasetUploadSelectType } from './pages/datasetUpload/DatasetUploadSelectType'
import { loaderRedirectTo } from './utils/dataLoaders/loaderRedirectTo'

export const uploadDatasetRoutes: RouteObject[] = [
  {
    path: '/upload/*',
    loader: (args) => loaderRedirectTo(`/${UPLOAD_PATH_NAME}`, args),
  },
  {
    path: `/${UPLOAD_PATH_NAME}`,
    loader: () => {
      return dataLoaderJsonResponse({ globals: { pageTitle: 'Import data' } })
    },
    children: [
      {
        path: '',
        element: <DatasetUploadSelectType />,
      },
      {
        path: UPLOAD_TYPES_TO_PATHS[UploadType.commandLine],
        element: UPLOAD_TYPES_TO_ELEMENTS[UploadType.commandLine],
      },
      {
        path: UPLOAD_TYPES_TO_PATHS[UploadType.computer],
        element: UPLOAD_TYPES_TO_ELEMENTS[UploadType.computer],
      },
      {
        path: UPLOAD_TYPES_TO_PATHS[UploadType.pythonApi],
        element: UPLOAD_TYPES_TO_ELEMENTS[UploadType.pythonApi],
      },
      {
        path: UPLOAD_TYPES_TO_PATHS[UploadType.url],
        element: UPLOAD_TYPES_TO_ELEMENTS[UploadType.url],
      },
    ],
  },
]
