import { useColorModeValue } from '@chakra-ui/react'
import Link from '@components/link/Link'
import { AnyObject } from '@utils/types'
import { JSONTree } from 'react-json-tree'

export const AwsConsoleLinkRenderer = (value: any) => {
  const awsConsoleLinkRegex =
    /"(?<url>https:\/\/us-(?:east|west)-(?:[12])\.console\.aws\.amazon\.com\/.*)"/

  if (typeof value === 'string') {
    const maybeURLMatch = awsConsoleLinkRegex.exec(value)
    if (maybeURLMatch?.groups?.url) {
      return (
        <Link href={maybeURLMatch.groups.url} isExternal>
          {maybeURLMatch.groups.url}
        </Link>
      )
    }
  }
  return value
}

const JSONQueryResults = ({ data, resource }: { data: AnyObject | null; resource: string }) => {
  const colorMode = useColorModeValue('light', 'dark')
  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  }

  if (data) {
    return (
      <JSONTree
        data={data}
        hideRoot
        theme={theme}
        invertTheme={colorMode === 'light'}
        valueRenderer={AwsConsoleLinkRenderer}
      />
    )
  }
  return <div>No {resource} found.</div>
}

export default JSONQueryResults
