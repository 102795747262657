import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Box, Button, Container, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import { useAuth } from '@hooks/useAuth'
import { FiAlertTriangle } from 'react-icons/fi'

const AwaitingAdminInvitationPage = () => {
  const warningTextColor = useColorModeValue('orange.700', 'orangeDarkMode.700')
  const textFaint = useColorModeValue('neutral.700', 'neutralDarkMode.700')
  const warningBorderColor = useColorModeValue('orange.500', 'orangeDarkMode.500')
  const warningIconColor = useColorModeValue(
    cleanlabColors.orange[600],
    cleanlabColors.orangeDarkMode[600]
  )

  const { logout } = useAuth()

  return (
    <Container px="10%" mt="32px">
      <Box
        borderRadius="12px"
        w="100%"
        border="1px solid"
        borderColor={warningBorderColor}
        padding="16px"
        mt="24px"
        fontSize="md"
        lineHeight="24px"
      >
        <HStack>
          <FiAlertTriangle color={warningIconColor} size={16} />
          <Text fontWeight={500} color={warningTextColor}>
            No user found
          </Text>
        </HStack>
        <Box paddingLeft="24px">
          <Text color={textFaint}>
            In order to sign up to Cleanlab Studio, you need to be added by your organization
            administrator.
          </Text>
          <Button
            p="6px 12px"
            height="32px"
            fontSize="14px"
            variant="tlm-action-button"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            Log out
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default AwaitingAdminInvitationPage
