'use client'
import { Tooltip } from '@components/tooltip/Tooltip'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { AsChildProps } from '@utils/AsChildProps'
import { cn, tv } from '@utils/tailwindUtils'
import {
  ButtonHTMLAttributes,
  cloneElement,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react'
import { SetRequired } from 'type-fest'

type IconFrameRootVariant = 'level0' | 'level1' | 'level2' | 'outline' | 'iconOnly' | 'closeButton'
export type IconFrameVariant = Exclude<IconFrameRootVariant, 'closeButton'>
export type IconFrameRootSize = 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
export type IconFrameSize = Exclude<IconFrameRootSize, 'xxSmall'>

export type IconFrameRootProps = {
  className?: string
  icon: ReactElement
  variant: IconFrameRootVariant
  size: IconFrameRootSize
  asChild?: boolean
  clickable?: boolean
  children?: ReactNode
  disabled?: boolean
  tooltipContent?: ReactNode
}
type LimitedProps = Omit<IconFrameRootProps, 'clickable' | 'children' | 'variant'> & {
  variant: IconFrameVariant
}
export type IconFrameProps = LimitedProps & AsChildProps<HTMLAttributes<HTMLDivElement>>
export type IconButtonProps = LimitedProps &
  AsChildProps<SetRequired<ButtonHTMLAttributes<HTMLButtonElement>, 'aria-label'>>

export const frameVariants = tv({
  base: 'relative flex shrink-0 items-center justify-center outline outline-1 -outline-offset-1 focus-visible:outline-1',
  variants: {
    // Main variant
    variant: {
      level0: 'rounded-2 bg-surface-0 text-text-faint outline-border-0',
      level1: 'rounded-2 bg-surface-1 text-text-faint outline-border-1',
      level2: 'rounded-2 bg-surface-2 text-text-faint outline-border-2',
      outline: 'rounded-2 bg-surface-0 text-text-primary outline-border-1',
      iconOnly: 'rounded-2 bg-transparent text-text-primary outline-none',
      closeButton: 'rounded-1 bg-transparent text-text-primary outline-none',
      sidebar: 'rounded-2 bg-surface-0 text-text-primary outline-border-1',
      sidebarGlobal: 'rounded-2 bg-transparent text-text-primary outline-none',
    },
    // Additional options
    clickable: {
      true: 'cursor-pointer focus-visible:outline-blue-700 focus-visible:ring focus-visible:ring-focus',
    },
    size: {
      xxSmall: 'size-[20px]',
      xSmall: 'size-[24px]',
      small: 'size-[32px]',
      medium: 'size-[36px]',
      large: 'size-[40px]',
      xLarge: 'size-[48px]',
    },
    disabled: {
      true: 'cursor-not-allowed',
      false: '',
    },
  },
  compoundVariants: [
    { variant: ['level0', 'level1', 'level2'], clickable: true, class: 'shadow-elev-0' },
    {
      clickable: true,
      variant: 'level0',
      disabled: false,
      class: 'hover:bg-surface-0-hover active:bg-surface-0-active',
    },
    {
      clickable: true,
      variant: 'level1',
      disabled: false,
      class: 'hover:bg-surface-1-hover active:bg-surface-1-active',
    },
    {
      clickable: true,
      variant: 'level2',
      disabled: false,
      class: 'hover:bg-surface-2-hover active:bg-surface-2-active',
    },
    {
      clickable: true,
      variant: 'outline',
      disabled: false,
      class: 'hover:bg-surface-0-hover active:bg-surface-0-active',
    },
    {
      clickable: true,
      variant: ['iconOnly'],
      disabled: false,
      class: 'bg-transparent hover:bg-surface-0-hover active:bg-surface-0-active',
    },
    {
      clickable: true,
      variant: ['closeButton'],
      disabled: false,
      class: 'bg-transparent hover:bg-surface-2-hover active:bg-surface-2-active',
    },
    {
      clickable: true,
      variant: 'sidebar',
      disabled: false,
      class: 'hover:bg-surface-0-hover active:bg-surface-0-active',
    },
    {
      clickable: true,
      variant: 'sidebarGlobal',
      disabled: false,
      class: 'hover:bg-surface-1-hover active:bg-surface-1-active',
    },
    {
      variant: ['level0', 'level1', 'level2', 'outline', 'sidebar'],
      disabled: true,
      class: 'bg-surface-disabled text-text-disabled outline-border-2',
    },
    {
      variant: ['closeButton', 'iconOnly', 'sidebarGlobal'],
      disabled: true,
      class: 'bg-transparent text-text-disabled',
    },
  ],
})

const iconSizes = {
  xxSmall: 12,
  xSmall: 12,
  small: 16,
  medium: 20,
  large: 24,
  xLarge: 32,
} as const satisfies Record<IconFrameRootSize, number>

const IconFrameRootBase = (
  {
    className,
    variant,
    size,
    icon,
    asChild = false,
    clickable,
    children,
    tooltipContent,
    ...props
  }: IconFrameRootProps,
  ref: ForwardedRef<any>
) => {
  const elementType = clickable ? 'button' : 'div'
  const Comp = asChild ? Slot : elementType
  let iconClone: ReturnType<typeof cloneElement> | undefined
  if (icon) {
    iconClone = cloneElement(icon, {
      size: variant === 'closeButton' ? 16 : iconSizes[size],
    })
  }
  const classN = useMemo(
    () => cn(frameVariants({ variant, size, clickable, disabled: !!props.disabled }), className),
    [className, clickable, size, variant, props.disabled]
  )
  const content = (
    <Comp ref={ref} className={classN} {...(clickable ? { type: 'button' } : {})} {...props}>
      <Slottable>{children}</Slottable>
      {iconClone}
    </Comp>
  )

  if (tooltipContent) {
    return <Tooltip content={tooltipContent}>{content}</Tooltip>
  }
  return content
}
export const IconFrameRoot = forwardRef(IconFrameRootBase)
