import NameCellComponent from '@common/misc/nameCellComponent/NameCellComponent'
import { Button } from '@components/button/Button'
import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconInfo, IconTrash } from '@components/icons'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { CleansetRowRes } from '@services/cleanset/constants'
import { ProjectRowRes } from '@services/project/constants'
import { tabWithinCell } from '@utils/ag-grid/tabWithinCell'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { timestampToDate } from '@utils/functions/timestampToDate'
import {
  ColDef,
  NewValueParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'
import { isEqual, isNil } from 'lodash'
import { ComponentPropsWithoutRef, Dispatch, SetStateAction } from 'react'
import { FiUpload } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import testIds from '../../../playwright/test_ids.json'
import { DateOnlyWithTooltip } from '../DateOnlyWithTooltip'
import IssuesFoundCell from './issuesFoundCell/IssuesFoundCell'
import IssuesResolvedProgressCell from './issuesResolvedCell/IssuesResolvedCell'
import ProjectStatusCellComponent from './projectStatusCellComponent/ProjectStatusCellComponent'

export type ProjectsCellRendererParams = ICellRendererParams<ProjectRowRes>

export type ActionsCellParams = {
  setTargetRowData: Dispatch<SetStateAction<ProjectRowRes | null | undefined>>
  onDetailsModalOpen: () => void
  onDeleteOpen: () => void
  onNonViewableProjectModalOpen: () => void
  onEnrichmentProjectModalOpen?: () => void
}

export type StatusCellParams = {
  setTargetRowData: Dispatch<SetStateAction<ProjectRowRes | null | undefined>>
  onNonViewableProjectModalOpen: () => void
  onProjectErrorModalOpen: () => void
}

export const ProjectsEmptyState = () => {
  const navigate = useNavigate()
  return (
    <Button
      size="medium"
      variant="primary"
      iconStart={<FiUpload />}
      onClick={() => navigate('/clean')}
      aria-label="Create a Project"
    >
      Create a Project
    </Button>
  )
}

export const ProjectNameCellRenderer = (params: ProjectsCellRendererParams) => {
  const rowData = params.node.data
  if (!rowData) {
    return null
  }
  const { name, id: projectId, isEnrichment } = rowData

  if (!projectId) return null

  return (
    <NameCellComponent
      to={getProjectRoute(rowData)}
      name={name ?? ''}
      id={projectId}
      data-testid={testIds.dashboardPageProjectsListName}
      onEditClick={
        isEnrichment
          ? undefined
          : () => params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: 'name' })
      }
    />
  )
}

export const DatasetNameCellRenderer = (params: ProjectsCellRendererParams) => {
  const nodeData = params.node.data
  if (!nodeData) {
    return null
  }
  return <NameCellComponent name={nodeData.dataset_name} id={nodeData.dataset_id} />
}

export const numResolvedValueGetter = <T extends ProjectRowRes | CleansetRowRes>(
  params: ValueGetterParams<T>
) => {
  return {
    numIssuesResolved: params.data?.num_issues_resolved,
    numIssues: params.data?.num_issues,
    isReady: params.data?.is_ready,
    hasError: params.data?.has_error,
    isEnrichment: (params.data as ProjectRowRes)?.isEnrichment ?? false,
  }
}

export const NumResolvedRenderer = (params: ICellRendererParams) => {
  const value = params?.value as ReturnType<typeof numResolvedValueGetter>

  if (value.isEnrichment) {
    return <span>N/A</span>
  }

  return <IssuesResolvedProgressCell {...params.value} />
}

export const CreatedUpdatedDateRenderer = (params: ProjectsCellRendererParams) => {
  const rowData = params.node.data as any
  let timestamp = rowData?.last_updated || rowData?.created_at

  // Enrichment projects have a different timestamp format
  // Coerce it into epoch time here to match the other projects
  if (rowData?.isEnrichment) {
    timestamp = new Date(timestamp).getTime()
  }

  return <DateOnlyWithTooltip timestamp={timestamp} />
}

export const labelIssuesValueGetter = <T extends ProjectRowRes | CleansetRowRes>(
  params: ValueGetterParams<T>
) => {
  const { num_total, num_issues, is_ready, has_error } = params.data || {}
  return {
    numTotal: num_total,
    numIssues: num_issues,
    isEnrichment: (params.data as ProjectRowRes).isEnrichment ?? false,
    isReady: is_ready,
    hasError: has_error,
  }
}

export const LabelIssuesRenderer = ({ value }: ProjectsCellRendererParams) => {
  const { isEnrichment, numIssues, numTotal, isReady, hasError } = value as ReturnType<
    typeof labelIssuesValueGetter
  >
  if (isEnrichment) {
    return <span>N/A</span>
  }
  if (!isReady || hasError || isNil(numIssues) || isNil(numTotal)) {
    return null
  }

  return <IssuesFoundCell {...{ numIssues, numTotal }} />
}

export const ProjectStatusRenderer = (params: ICellRendererParams<ProjectRowRes>) => {
  const rowData = params.node.data
  const { setTargetRowData, onNonViewableProjectModalOpen, onProjectErrorModalOpen } = params.colDef
    ?.cellRendererParams as StatusCellParams

  if (!params.data) return null

  if (rowData?.isEnrichment) {
    return <span className="capitalize">{rowData.status?.toLowerCase()}</span>
  }

  const {
    is_ready,
    is_not_viewable_in_ui,
    has_error,
    progress_description,
    error_type,
    error_message,
    required_columns_ready,
    tasktype,
  } = params.data
  return (
    <ProjectStatusCellComponent
      isReady={is_ready}
      isViewable={!is_not_viewable_in_ui || tasktype === Tasktype.UNSUPERVISED}
      hasError={has_error}
      errorMessage={error_message}
      errorType={error_type}
      progressDescription={progress_description}
      onErrorButtonRoute={`/projects/${params.data.id}`}
      projectId={params.data.id}
      onOpenNonViewableProject={() => {
        setTargetRowData(params.data)
        onNonViewableProjectModalOpen()
      }}
      onOpenProjectErrorModal={() => {
        setTargetRowData(params.data)
        onProjectErrorModalOpen()
      }}
      requiredColumnsReady={required_columns_ready}
      to={getProjectRoute(rowData)}
    />
  )
}

const getProjectRoute = (projectRow: ProjectRowRes | undefined) => {
  const isPartiallyViewable =
    checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') && projectRow?.required_columns_ready

  if (projectRow?.isEnrichment) {
    return `/projects/enrich/${projectRow.id}`
  }

  if (
    !projectRow?.latest_cleanset_id ||
    (!projectRow.is_ready && !isPartiallyViewable) ||
    (projectRow?.is_not_viewable_in_ui && projectRow.tasktype !== Tasktype.UNSUPERVISED)
  ) {
    return undefined
  }
  return `/cleansets/${projectRow.latest_cleanset_id}`
}

export const ActionsCellRenderer = (params: ICellRendererParams<ProjectRowRes>) => {
  const { trackEvent } = useEventTracking()
  const rowData = params.node.data
  const deleteLabel = 'Delete Project'
  const { isEnrichment, tasktype } = rowData ?? { isEnrichment: false }
  const viewLabel = 'View more details'

  const {
    is_not_viewable_in_ui: isNotViewable,
    is_ready: isReady,
    latest_cleanset_id: latestCleansetId,
    is_template: isTemplate,
    id: projectId,
    required_columns_ready: requiredColumnsReady,
    error_message: errorMessage,
  } = params.data ?? { is_ready: false, is_not_viewable_in_ui: false }
  let openLabel = 'View Project'
  if (isNotViewable && tasktype !== Tasktype.UNSUPERVISED) {
    openLabel = 'Project not viewable'
  } else if (!isReady && !isEnrichment) {
    if (checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') && requiredColumnsReady) {
      openLabel = 'View partially ready Project'
    } else if (errorMessage) {
      openLabel = 'Cleanset error'
    } else {
      openLabel = 'Project initializing'
    }
  }

  const openButtonProps = {
    size: 'xSmall',
    variant: 'secondaryFaint',
    'aria-label': openLabel,
  } as const satisfies Partial<ComponentPropsWithoutRef<typeof Button>>
  const projectRoute = getProjectRoute(rowData)

  const { setTargetRowData, onDetailsModalOpen, onDeleteOpen, onNonViewableProjectModalOpen } =
    (params.colDef?.cellRendererParams as ActionsCellParams) || {
      setTargetRowData: () => {},
      onDetailsModalOpen: () => {},
    }

  return (
    // Add right pr-[15px] to accomodate scrollbar when system set to always show scrollbar
    <div className="flex h-full flex-shrink-0 items-center gap-4 pr-[15px]">
      <IconFrameButton
        variant="outline"
        size="xSmall"
        icon={<IconInfo />}
        onClick={() => {
          setTargetRowData(rowData || null)
          onDetailsModalOpen()
        }}
        tooltipContent={viewLabel}
        aria-label={viewLabel}
      />
      <IconFrameButton
        variant="outline"
        size="xSmall"
        icon={<IconTrash />}
        onClick={() => {
          setTargetRowData(rowData || null)
          onDeleteOpen()
        }}
        tooltipContent={deleteLabel}
        aria-label={deleteLabel}
      />
      <Tooltip content={openLabel}>
        {/* Need <span> wrapper for <Tooltip> to work when <Button> is using `asChild` */}
        <span className="rounded-2">
          {projectRoute && (
            <Button
              {...openButtonProps}
              asChild
              disabled={!checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') && !requiredColumnsReady}
            >
              <Link
                to={projectRoute}
                onClick={() => {
                  trackEvent(MixpanelEvents.clickReadyForReview, {
                    isTemplate: isTemplate,
                    projectId: projectId,
                    latestCleansetId: latestCleansetId,
                  })
                }}
              >
                Open
              </Link>
            </Button>
          )}
          {!projectRoute && (
            <Button
              {...openButtonProps}
              disabled={!isReady}
              onClick={() => {
                setTargetRowData(rowData || null)
                onNonViewableProjectModalOpen()
              }}
            >
              Open
            </Button>
          )}
        </span>
      </Tooltip>
    </div>
  )
}

export const colIssuesFound = {
  field: 'num_issues',
  headerName: 'Issues Found',
  headerComponentParams: {
    tooltipLabel:
      'Cleanlab detects many types of issues within your Dataset, such as label errors and outliers',
  },
  valueGetter: labelIssuesValueGetter,
  equals: isEqual,
  cellRenderer: LabelIssuesRenderer,
  filter: false,
  sortable: false,
  width: 190,
  minWidth: 190,
  maxWidth: 190 + 50,
} as const satisfies ColDef<CleansetRowRes | ProjectRowRes>

export const colIssuesResolved = {
  field: 'num_resolved',
  headerName: 'Issues Resolved',
  valueGetter: numResolvedValueGetter,
  equals: isEqual,
  cellRenderer: NumResolvedRenderer,
  filter: false,
  sortable: false,
  width: 190,
  minWidth: 190,
  maxWidth: 190 + 50,
} as const satisfies ColDef<CleansetRowRes | ProjectRowRes>

export const getColumnDefs = ({
  handleNameChange,
  actionsCellParams,
  statusCellParams,
  isEnrichment,
}: {
  handleNameChange: (evt: NewValueParams) => void
  actionsCellParams: ActionsCellParams
  statusCellParams: StatusCellParams
  isEnrichment?: boolean
}): ColDef<ProjectRowRes>[] => {
  let columnDefs = [
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: ProjectNameCellRenderer,
      suppressKeyboardEvent: tabWithinCell,
      editable: !isEnrichment,
      cellEditor: 'agTextCellEditor',
      singleClickEdit: false,
      minWidth: 250,
      flex: isEnrichment ? 1 : 3,
      onCellValueChanged: handleNameChange,
      filter: 'agTextColumnFilter',
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: !isEnrichment,
    },
    {
      field: 'dataset_name',
      headerName: 'Dataset',
      minWidth: 200,
      flex: 1,
      cellRenderer: DatasetNameCellRenderer,
      suppressKeyboardEvent: tabWithinCell,
      filter: false,
      sortable: !isEnrichment,
    },
    colIssuesFound,
    colIssuesResolved,
    {
      field: 'current_training_stage',
      headerName: 'Status',
      cellRenderer: ProjectStatusRenderer,
      cellRendererParams: statusCellParams,
      filter: false,
      sortable: false,
      width: 144,
      minWidth: 144,
      maxWidth: 200,
    },
    {
      field: 'last_updated',
      headerName: 'Created',
      filter: false,
      cellRenderer: CreatedUpdatedDateRenderer,
      valueGetter: (params: any) => {
        return typeof params.data?.last_updated === 'number'
          ? timestampToDate(params.data?.last_updated)
          : params.data?.last_updated
      },
      suppressKeyboardEvent: tabWithinCell,
      width: 110,
      minWidth: 110,
      maxWidth: 130,
      sortable: !isEnrichment,
    },
    {
      headerName: '',
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: actionsCellParams,
      cellClass: 'flex justify-end',
      suppressKeyboardEvent: tabWithinCell,
      filter: false,
      sortable: false,
      pinned: 'right',
      width: 158, // 143 + 15 for the scrollbar
      minWidth: 158,
      maxWidth: 158,
    } as const,
  ] as ColDef<ProjectRowRes>[]

  if (isEnrichment) {
    columnDefs = columnDefs.filter((col) => {
      if (col.field === 'num_issues' || col.field === 'num_resolved') {
        return false
      }

      return true
    })
  }

  return columnDefs
}
