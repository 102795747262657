import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Badge } from '@components/badge/Badge'
import { Button } from '@components/button/Button'
import { IconEye, IconFileText, IconGrid, IconImage } from '@components/icons'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { TemplateProjectPreview } from '@services/templateProjects/constants'
import { upperFirst } from 'lodash'
import { ComponentProps, useState } from 'react'
import { Link } from 'react-router-dom'
import { DatasetSampleGrid } from 'src/pages/projectForm/datasetSample/DatasetSample'

import { ProjectDemoTagList } from './ProjectDemoTagList'

const CreateProjectButton = ({
  template,
  ...props
}: { template: TemplateProjectPreview } & ComponentProps<typeof Button>) => (
  <Button size="small" variant="secondary" className="max-w-max" {...props} asChild>
    <Link to={`/clean/demo/${template.id}`}>Create Project</Link>
  </Button>
)

export const ProjectDemoCard = ({ template }: { template: TemplateProjectPreview }) => {
  const [showDataset, setShowDataset] = useState(false)
  const typeIcon = ((modality) => {
    switch (modality) {
      case 'image':
        return <IconImage />
      case 'tabular':
        return <IconGrid />
      default:
        return <IconFileText />
    }
  })((template.modality ?? '').toLowerCase())
  const title = template.meta.title
  return (
    <div className="flex flex-col gap-5 rounded-2 bg-surface-0 p-7 outline outline-1 -outline-offset-[1px] outline-border-1">
      <div className="flex items-start justify-between gap-5">
        <h6 className="type-body-200-medium text-text-strong">{title}</h6>
        <div className="flex gap-3">
          <Badge round="full" icon={typeIcon} size="small" variant="outline" hue="blue">
            {upperFirst(template.modality)}
          </Badge>
          <Badge round="full" size="small" variant="outline" hue="neutral">
            Demo
          </Badge>
        </div>
      </div>
      <ProjectDemoTagList tags={template.meta.tags} />
      <div className="flex flex-grow flex-col justify-end">
        <div className="flex flex-row-reverse justify-between gap-4">
          <CreateProjectButton template={template} />
          {!!template.example_dataset_rows.rows.length && (
            <LinkBlock iconStart={<IconEye />} size="small" variant="secondary" asChild>
              <button onClick={() => setShowDataset(true)}>Dataset</button>
            </LinkBlock>
          )}
        </div>
      </div>
      <SampleDatasetModal
        isOpen={showDataset}
        onClose={() => setShowDataset(false)}
        template={template}
      />
    </div>
  )
}

export const SampleDatasetModal = ({
  isOpen = false,
  onClose,
  template,
}: {
  isOpen: boolean
  onClose: () => void
  template: TemplateProjectPreview
}) => {
  const rows = template.example_dataset_rows.rows
  const columns = template.example_dataset_rows.columns
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="h-[calc(100vh-128px)] !max-w-[calc(100%-48px)]">
        <ModalHeader>{template.meta.title} &ndash; Dataset sample</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="type-body-300 flex w-full flex-col overflow-hidden text-text-faint">
          <DatasetSampleGrid
            className="h-full w-full"
            rows={rows}
            columns={columns.filter((col) => col !== 'cleanlab_row_ID')}
          />
        </ModalBody>
        <ModalFooter className="flex w-full flex-row-reverse !justify-start gap-4">
          <CreateProjectButton variant="highContrast" template={template} />
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
