import { Button, Checkbox, HStack, useDisclosure } from '@chakra-ui/react'
import {
  useConfirmUserEmails,
  useDeleteUsersMutation,
  useSetAccessLevelMutation,
} from '@services/user/mutations'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import React, { useCallback, useEffect, useRef } from 'react'

import ConfirmDeleteDialog from '../admin/confirmDeleteDialog/ConfirmDeleteDialog'
import MakeAdminDialog from '../admin/makeAdminDialog/MakeAdminDialog'

interface AdminButtonPanelProps {
  gridApi: GridApi
}

const AdminButtonPanel = (props: AdminButtonPanelProps) => {
  const { gridApi } = props
  const {
    isOpen: isDeleteUsersOpen,
    onOpen: onDeleteUsersOpen,
    onClose: onDeleteUsersClose,
  } = useDisclosure()
  const {
    isOpen: isMakeAdminOpen,
    onOpen: onMakeAdminOpen,
    onClose: onMakeAdminClose,
  } = useDisclosure()
  const { mutate: deleteUsers } = useDeleteUsersMutation()
  const { mutate: setAccessLevel } = useSetAccessLevelMutation()
  const { mutate: confirmUserEmail } = useConfirmUserEmails()

  const cancelDeleteRef = useRef(null)
  const cancelMakeRef = useRef(null)

  const handleConfirmDelete = useCallback(() => {
    const selectedIds = gridApi.getSelectedRows().map((row) => row.id)
    deleteUsers(selectedIds)
  }, [deleteUsers, gridApi])

  const handleConfirmUserEmail = useCallback(() => {
    const selectedIds = gridApi.getSelectedRows().map((row) => row.id)
    confirmUserEmail(selectedIds)
  }, [confirmUserEmail, gridApi])

  const handleSetAccessLevel = useCallback(
    (accessLevel: 'admin' | 'user') => {
      const selectedIds = gridApi.getSelectedRows().map((row) => row.id)
      selectedIds.forEach((userId) => {
        setAccessLevel({ userId, accessLevel })
      })
    },
    [gridApi, setAccessLevel]
  )

  const toggleUnusedSignupTokens = useCallback(
    (hideTokens: boolean) => {
      if (gridApi) {
        const emailFilter = hideTokens
          ? {
              filterType: 'text',
              type: 'notBlank',
            }
          : {}
        const filterModel = {
          ...gridApi.getFilterModel(),
          email: emailFilter,
        }
        gridApi.setFilterModel(filterModel)
        gridApi.onFilterChanged()
      }
    },
    [gridApi]
  )

  // hide unused signup tokens by default
  useEffect(() => {
    toggleUnusedSignupTokens(true)
  }, [toggleUnusedSignupTokens])

  return (
    <>
      <HStack>
        <Checkbox
          value="checked"
          defaultChecked={true}
          onChange={(evt) => toggleUnusedSignupTokens(evt.target.checked)}
        >
          Hide Unused Sign-Up Tokens
        </Checkbox>
        <Button colorScheme="whatsapp" size="sm" onClick={onMakeAdminOpen}>
          Make admin
        </Button>
        <Button colorScheme="messenger" size="sm" onClick={() => handleSetAccessLevel('user')}>
          Revoke admin
        </Button>
        <Button colorScheme="red" size="sm" onClick={onDeleteUsersOpen}>
          Delete user
        </Button>
        <Button colorScheme="yellow" size="sm" onClick={handleConfirmUserEmail}>
          Confirm user email
        </Button>
      </HStack>
      <ConfirmDeleteDialog
        onClose={onDeleteUsersClose}
        isOpen={isDeleteUsersOpen}
        cancelRef={cancelDeleteRef}
        onConfirm={handleConfirmDelete}
        item="user"
      />
      <MakeAdminDialog
        onClose={onMakeAdminClose}
        isOpen={isMakeAdminOpen}
        cancelRef={cancelMakeRef}
        onConfirm={() => handleSetAccessLevel('admin')}
      />
    </>
  )
}

export default AdminButtonPanel
