import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import {
  addTemplateProjectMeta,
  TemplateProjectPreview,
  TemplateProjectPreviewRes,
  templateProjectsQueryKeys,
} from '@services/templateProjects/constants'
import { templateProjectsClient } from '@services/templateProjects/templateProjectsClient'
import { UseQueryOptionsPassThrough } from '@services/useQuery.helpers'
import { useQuery } from 'react-query'

// Exclude specific deleted template project until backend endpoint is updated
// to exclude them
const EXCLUDE_PROJECT_IDS = ['61f4862f223e44f19586f8cfb4cde217']

export const useTemplateProjectsPreview = ({
  useQueryOptions,
}: {
  useQueryOptions?: UseQueryOptionsPassThrough<TemplateProjectPreview[]>
}) => {
  const { meta = {}, ...options } = useQueryOptions ?? {}
  return useQuery<TemplateProjectPreview[]>({
    queryKey: templateProjectsQueryKeys.templateProjects.previews(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await templateProjectsClient.get<TemplateProjectPreviewRes[]>(
        '/preview',
        createAuthHeaders(accessToken)
      )
      return (
        res.data
          ?.map(addTemplateProjectMeta)
          .filter((project) => !EXCLUDE_PROJECT_IDS.includes(project?.id))
          .sort((a, b) => a.meta.title?.localeCompare(b.meta.title ?? '') ?? 0) ?? []
      )
    },
    meta: { error: 'Failed to fetch Projects.', ...meta },
    ...options,
  })
}
