import { Button, Grid, Heading, Text, VStack } from '@chakra-ui/react'
import queryApi from '@services/queryApi'
import React, { useState } from 'react'

import { ControlledFormInputElement, QueryResults } from './QueryInterface.helpers'

const QueryInterfaceFindCleanset = () => {
  const [userId, setUserId] = useState('')
  const [datasetId, setDatasetId] = useState('')
  const [projectId, setProjectId] = useState('')
  const [cleansetId, setCleansetId] = useState('')
  const [queryResults, setQueryResults] = useState(null)

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Query Cleansets</Heading>
      <Text>
        <strong>Instructions:</strong> Cleansets can be queried using UserID, DatasetID, ProjectID,
        or CleansetID.
      </Text>
      <Text>
        If a CleansetID is given, the UserID, the DatasetID, and the ProjectID are ignored.
      </Text>
      <Text>If a ProjectID is given, the UserID and the DatasetID are ignored.</Text>
      <Text>If a DatasetID is given, the UserID is ignored.</Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <ControlledFormInputElement
          id="user_id"
          type="text"
          label="UserID"
          value={userId}
          setValue={setUserId}
        />
        <ControlledFormInputElement
          id="dataset_id"
          type="text"
          label="DatasetID"
          value={datasetId}
          setValue={setDatasetId}
        />
        <ControlledFormInputElement
          id="project_id"
          type="text"
          label="ProjectID"
          value={projectId}
          setValue={setProjectId}
        />
        <ControlledFormInputElement
          id="cleanset_id"
          type="text"
          label="CleansetID"
          value={cleansetId}
          setValue={setCleansetId}
        />
      </Grid>

      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApi.query({
            resource: 'cleanset',
            user_id: userId,
            dataset_id: datasetId,
            project_id: projectId,
            cleanset_id: cleansetId,
          })
          setQueryResults(response.data)
        }}
      >
        Find Cleanset
      </Button>

      <QueryResults data={queryResults} resource="cleansets" />
    </VStack>
  )
}

export default QueryInterfaceFindCleanset
