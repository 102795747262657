import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { AgilityApp, queryKeys } from '@services/agilityApps/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import camelCaseKeys from '@utils/functions/camelCaseKeys'
import axios from 'axios'
import { useQuery } from 'react-query'
const apiVersion = 'v0'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/agility`,
  withCredentials: true,
})

export const useAgilityApps = (): AgilityApp[] => {
  const { data } = useQuery({
    queryKey: queryKeys.apps.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get<{ rows: AgilityApp[] }>(
        '/apps',
        createAuthHeaders(accessToken)
      )
      return camelCaseKeys(res.data)
    },
    meta: { error: 'Failed to fetch agility apps data.' },
    refetchInterval: (data?: AgilityApp[]) => {
      if (data) {
        const shouldRefetch = data.some(
          (app) => app.status === 'CREATED' || app.status === 'IN_PROGRESS'
        )
        return shouldRefetch ? 10000 : false
      }
      return false
    },
  })
  return data ?? []
}
