import { Slot } from '@radix-ui/react-slot'
import { AsChildProps } from '@utils/AsChildProps'
import { cn } from '@utils/tailwindUtils'
import { ComponentProps } from 'react'

export const CodeInline = ({
  asChild,
  className,
  ...props
}: AsChildProps<ComponentProps<'code'>>) => {
  const Elt = asChild ? Slot : 'code'
  return (
    <Elt
      className={cn(
        'text-transform-[inherit] type-code-100 -z-10 mx-[0.15em] inline rounded-1 border border-border-1 bg-surface-1 px-[0.43em] py-[0.15em] text-[0.86em] leading-[inherit] text-text-primary',
        className
      )}
      {...props}
    />
  )
}
