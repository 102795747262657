import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { Button } from '@components/button/Button'
import { IconPlus } from '@components/icons'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { AddedUser } from '@services/user/constants'
import { useAddUsers } from '@services/user/mutations'
import snakeCaseKeys from '@utils/functions/snakeCaseKeys'
import { useFieldArray, useForm } from 'react-hook-form'

import AddUserInput from '../../addUserInput/AddUserInput'
import { ButtonCloseModalChakra } from '../ButtonCloseChakraModal'
import { ModalProps } from '../Modal.types'

const AddUsersModal = (props: ModalProps) => {
  const { isOpen, onClose } = props

  const toast = useToast()

  const surface0 = useColorModeValue('surface.0', 'surfaceDarkMode.0')

  const { register, control, reset, getValues, trigger, formState } = useForm({
    defaultValues: {
      addedUsers: [{ email: '', accessLevel: 'user' }] as AddedUser[],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addedUsers',
  })

  const onModalClose = () => {
    onClose()
    reset()
  }

  const { mutate: addUsers, isLoading: isAddUsersLoading } = useAddUsers({
    onSuccess: () => {
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: fields.length > 1 ? `${fields.length} users added` : 'User added',
          status: 'info',
        }),
      })
      onModalClose()
    },
    onError: () =>
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: `Failed to add user${fields.length > 1 ? 's' : ''}`,
          status: 'warning',
        }),
      }),
  })

  const onSubmit = () => {
    trigger()
    const formValues = getValues().addedUsers
    addUsers({ addedUsers: snakeCaseKeys(formValues) })
  }

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent bg={surface0}>
        <ModalHeader pb={0} pt="24px" className="text-text-strong">
          Add users
        </ModalHeader>
        <ButtonCloseModalChakra />
        <ModalBody py={0}>
          <div className="flex flex-col gap-6 pb-8 pt-4">
            <p className="type-body-200 text-text-primary">
              Add others to your organization. You can see pending invitations as well as edit each
              user’s settings from the dashboard.
            </p>
            {fields.map((field, index) => (
              <AddUserInput key={field.id} index={index} register={register} remove={remove} />
            ))}
            {fields.length <= 6 && (
              <LinkBlock
                size="small"
                iconEnd={<IconPlus />}
                className="type-body-100-medium w-fit text-blue-700"
                asChild
              >
                <button onClick={() => append({ email: '', accessLevel: 'user' })}>
                  Add another user
                </button>
              </LinkBlock>
            )}
            <ModalFooter p={0}>
              <div className="flex flex-row gap-5">
                <Button variant="secondary" onClick={onModalClose}>
                  Cancel
                </Button>
                <Button
                  disabled={!formState.isValid || isAddUsersLoading}
                  variant="primary"
                  onClick={onSubmit}
                >
                  {isAddUsersLoading ? <Spinner size="sm" /> : 'Add users'}
                </Button>
              </div>
            </ModalFooter>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AddUsersModal
