import { Tag, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

import { COLUMN_TYPE } from '../MultiLabelTagParams'
import { MultiLabelTagProps } from './MultiLabelTag.types'

const MultiLabelTag = (props: MultiLabelTagProps) => {
  const { label, isInGiven, isInSuggested, column } = props
  const baseStyles = { fontSize: '13px', size: 'sm' }
  const textColor = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const inactiveTextColor = useColorModeValue('blue.900', 'blueDarkMode.900')
  const correctedBgColor = useColorModeValue('neutral.300', 'neutralDarkMode.300')
  const suggestedTextColor = useColorModeValue('blue.900', 'blueDarkMode.900')
  const filledBg = useColorModeValue('blue.300', 'blueDarkMode.300')
  const borderColor = useColorModeValue('blue.300', 'blueDarkMode.300')

  if (column === COLUMN_TYPE.MULTI_LABEL_TAG) {
    return (
      <Tag
        borderRadius={50}
        border={isInGiven ? 'none' : '1px solid'}
        borderColor="gray.300"
        color={textColor}
        bg={isInGiven ? filledBg : 'transparent'}
        {...baseStyles}
      >
        {label ? <Text>{label}</Text> : null}
      </Tag>
    )
  } else if (column === COLUMN_TYPE.GIVEN) {
    return (
      <Tag
        borderRadius={50}
        maxH="20px"
        p="5.5px"
        bg={isInSuggested ? filledBg : 'transparent'}
        border="1px solid"
        borderColor={borderColor}
        {...baseStyles}
      >
        {label ? (
          <Text fontSize="xs" color={isInSuggested ? suggestedTextColor : inactiveTextColor}>
            {label}
          </Text>
        ) : null}
      </Tag>
    )
  } else if (column === COLUMN_TYPE.SUGGESTED) {
    return (
      <Tag
        borderRadius={50}
        maxH="20px"
        p="5.5px"
        bg={isInGiven ? filledBg : 'transparent'}
        border={isInGiven ? '1px solid' : '1px dotted'}
        borderColor={borderColor}
        {...baseStyles}
      >
        {label ? (
          <Text fontSize="xs" color={isInGiven ? suggestedTextColor : inactiveTextColor}>
            {label}
          </Text>
        ) : null}
      </Tag>
    )
  } else if (column === COLUMN_TYPE.CORRECTED) {
    return (
      <Tag maxH="20px" p="7px" borderRadius={50} bg={correctedBgColor} {...baseStyles}>
        <Text fontSize="xs" color={textColor}>
          {label}
        </Text>
      </Tag>
    )
  } else {
    return null
  }
}

export default MultiLabelTag
