import { Center, Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react'
import { useKeyPress } from '@hooks/useKeyPress'
import { usePaletteView } from '@hooks/usePaletteView'
import useResizeObserver from '@hooks/useResizeObserver'
import { useMultiSelectBatchActionMutation } from '@services/datasheet/mutations'
import { cn } from '@utils/tailwindUtils'
import { GridApi } from 'ag-grid-community'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import { Modality, Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import { EXCLUDE_COMMAND_NAME } from '../commandpalette/CommandPalette.helpers'
import { ActionType, CustomTagType } from '../commandpalette/CommandPalette.types'
import PaletteHeader from '../commandpalette/paletteHeader/PaletteHeader'
import PaletteSuggestion from '../commandpalette/paletteSuggestion/PaletteSuggestion'
import { ProjectDetailsProps } from '../datasheet/Datasheet.types'
import { LabelType, RowData } from '../editInterface/EditInterface.types'
import ResolverHeading from '../editInterface/resolverHeading/ResolverHeading'
import TabularDataDisplay from '../editInterface/tabularDataDisplay/TabularDataDisplay'
import TextDataDisplay from '../editInterface/textDataDisplay/TextDataDisplay'
import MetadataDisplay from './metadataDisplay/MetadataDisplay'

interface UnsupervisedResolverProps {
  gridApi: GridApi
  originalDatasetColumns: string[]
  projectDetails: ProjectDetailsProps
  selectedRowData: RowData
  onClose: () => void
  isTemplate: boolean
  isMultiSelect: boolean
  resolverFailure: boolean
  isFetchingRows: boolean
  metadataColumns: string[]
  updateRowAction: (action: ActionType | string) => void
  handleMultiSelectAction: (action: ActionType | string) => void
  updateRowCustomTag: (tag: CustomTagType | string) => void
  isActionLoading: boolean
  autoAdvance: boolean
  setAutoAdvance: (autoAdvance: boolean) => void
  setResolverWidth: React.Dispatch<React.SetStateAction<number>>
}

const UnsupervisedResolver = (props: UnsupervisedResolverProps) => {
  const {
    gridApi,
    originalDatasetColumns,
    projectDetails,
    selectedRowData,
    isTemplate,
    isMultiSelect,
    onClose,
    resolverFailure,
    isFetchingRows,
    metadataColumns,
    updateRowAction,
    handleMultiSelectAction,
    updateRowCustomTag,
    isActionLoading,
    autoAdvance,
    setAutoAdvance,
    setResolverWidth,
  } = props
  const { modality, labelColumn, predictiveColumns } = projectDetails

  const imageMode = projectDetails.modality === 'image'
  const { isLoading } = useMultiSelectBatchActionMutation(gridApi)

  const dataColValue = predictiveColumns.length === 1 ? selectedRowData[predictiveColumns[0]] : null

  const resolverBackgroundColor = useColorModeValue('neutral.50', 'neutralDarkMode.50')

  const [showHalfResolver, setShowHalfResolver] = useState(false)
  const [paletteWidth, setPaletteWidth] = useState<number>(200)
  const paletteRef = useRef<HTMLDivElement | null>(null)
  useResizeObserver(paletteRef, (rect) => setPaletteWidth(rect.width))
  const { showTallView, showXLView } = usePaletteView(paletteWidth)

  const currentRowAction = selectedRowData._cleanlab_action
  const correctedLabel = selectedRowData._cleanlab_clean_label
  const givenLabel = selectedRowData[labelColumn] as LabelType | null
  const givenLabelType = givenLabel ? typeof givenLabel : null

  const markUnresolvedAction = useCallback(() => {
    if (isMultiSelect) {
      handleMultiSelectAction(ActionType.MARK_UNRESOLVED)
    } else {
      updateRowAction(ActionType.MARK_UNRESOLVED)
    }
  }, [handleMultiSelectAction, isMultiSelect, updateRowAction])

  useEffect(() => {
    // If multi-select is active, halve the resolver width
    if (isMultiSelect && !showHalfResolver) {
      setShowHalfResolver(true)
      setResolverWidth((prevComponentWidth) => (prevComponentWidth ?? 0) / 2)
    } else if (showHalfResolver && !isMultiSelect) {
      setShowHalfResolver(false)
      setResolverWidth((prevComponentWidth) => (prevComponentWidth ?? 0) * 2)
    }
  }, [showHalfResolver, isMultiSelect, setResolverWidth])

  useKeyPress({
    callback: () => {
      if (isMultiSelect) {
        handleMultiSelectAction(CustomTagType.MARK_NEEDS_REVIEW)
      } else {
        updateRowCustomTag(CustomTagType.NEEDS_REVIEW)
      }
    },
    keys: ['n'],
  })

  useKeyPress({
    callback: () => {
      if (isMultiSelect) {
        handleMultiSelectAction(ActionType.EXCLUDE)
      } else {
        updateRowAction(ActionType.EXCLUDE)
      }
    },
    keys: ['e'],
  })

  useKeyPress({
    callback: () =>
      isMultiSelect
        ? handleMultiSelectAction(ActionType.MARK_UNRESOLVED)
        : updateRowAction(ActionType.MARK_UNRESOLVED),
    keys: ['c'],
  })

  // Row object without any cleanlab added columns.
  const omitColumns = [labelColumn]
  const originalCurrRowData = _.omit(_.pick(selectedRowData, originalDatasetColumns), omitColumns)

  const resolverLeftClass = cn('h-full w-[50%] overflow-x-clip border-r border-t border-border-1')

  const commandPaletteClass = cn(
    'h-full flex-col items-start border-t border-border-1 bg-surface-1',
    isMultiSelect ? 'w-full' : 'w-[50%]'
  )

  return (
    <div className="h-full w-full items-start overflow-y-hidden rounded-tl-2 border-l border-t border-border-1 bg-surface-0">
      <ResolverHeading
        autoAdvance={autoAdvance}
        onClose={onClose}
        isMultiSelect={isMultiSelect}
        setAutoAdvance={setAutoAdvance}
        isTemplate={isTemplate}
      />
      {selectedRowData && (
        <div className="flex h-[90%] w-full">
          {/* Left side of the resolver (datapoint) */}
          {!showHalfResolver && (
            <div className={resolverLeftClass}>
              {/* datapoint display for text and image modalities */}
              {modality === 'text' && (
                <TextDataDisplay
                  textColumnName={predictiveColumns?.[0]}
                  text={dataColValue as string}
                />
              )}
              {imageMode && (
                <div className="flex h-3/4 items-center overflow-hidden object-contain">
                  <img
                    className="h-min max-h-full w-full rounded-2 object-contain px-6"
                    alt=""
                    src={
                      selectedRowData[
                        `_cleanlab_media_url_${projectDetails.imageColumns[0]}`
                      ] as string
                    }
                  />
                </div>
              )}

              {/* datapoint display tabular modality */}
              {modality === 'tabular' && (
                <TabularDataDisplay
                  data={[...Object.entries(originalCurrRowData)]}
                  projectDetails={projectDetails}
                />
              )}
            </div>
          )}
          {/* Right side of the resolver (general analyses & metadata) */}
          <div className={commandPaletteClass}>
            <PaletteHeader
              selectedRowData={selectedRowData}
              updateRowCustomTag={updateRowCustomTag}
              handleMultiSelectAction={handleMultiSelectAction}
              isMultiSelect={isMultiSelect}
              showDataLabelingWorkflow={false}
            />
            <div className="p-4" ref={paletteRef}>
              <PaletteSuggestion
                inputValue=""
                taskType={Tasktype.UNSUPERVISED}
                labels={{}}
                isMultiSelect={isMultiSelect}
                givenLabelType={givenLabelType}
                correctedLabel={correctedLabel as string}
                markUnresolvedAction={markUnresolvedAction}
                currentRowAction={currentRowAction}
                showTallView={showTallView}
                showXLView={showXLView}
                newlyAddedLabels={[]}
                name={EXCLUDE_COMMAND_NAME}
                category="exclude"
                command={() => {
                  if (isMultiSelect) {
                    handleMultiSelectAction(ActionType.EXCLUDE)
                  } else {
                    updateRowAction(ActionType.EXCLUDE)
                  }
                }}
                disabled={isActionLoading || isFetchingRows || isLoading}
                shortcut="E"
              />
            </div>
            {isMultiSelect ? (
              <div className="type-body-100 h-full w-full p-6 text-center text-text-primary">
                Select a single row to view metadata anaylses
              </div>
            ) : (
              <MetadataDisplay
                metadataColumns={metadataColumns}
                selectedRowData={selectedRowData}
                modality={modality as Modality}
                showTallView={showTallView}
              />
            )}
          </div>
        </div>
      )}
      {resolverFailure && (
        <Center flexDirection="column" bg={resolverBackgroundColor} h="85vh" w="100%">
          <FiAlertTriangle size={52} color="red" />
          <Text mt={4} fontSize="lg">
            Could not load the Resolver. Please check your connection and try again.
          </Text>
        </Center>
      )}
      {isLoading && (
        <Flex bg={resolverBackgroundColor} w="100%" h="85vh" align="center" justify="center">
          <Spinner />
        </Flex>
      )}
    </div>
  )
}

export default UnsupervisedResolver
