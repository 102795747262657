export const queryKeys = {
  billing: {
    all: () => ['billing'] as const,
    defaultSubscriptions: () => [...queryKeys.billing.all(), 'defaultSubscriptions'] as const,
    defaultPlans: () => [...queryKeys.billing.all(), 'defaultPlans'] as const,
    billingSubscriptions: () => [...queryKeys.billing.all(), 'billingSubscriptions'] as const,
    billingPlans: () => [...queryKeys.billing.all(), 'billingPlans'] as const,
    cardDetails: () => [...queryKeys.billing.all(), 'cardDetails'] as const,
    cleansetExportDetails: (projectId: string) => [...queryKeys.billing.all(), projectId] as const,
    inferenceExportDetails: (queryId: string) => [...queryKeys.billing.all(), queryId] as const,
    currentPeriodUsage: () => [...queryKeys.billing.all(), 'currentPeriodUsage'] as const,
    paymentHistory: () => [...queryKeys.billing.all(), 'paymentHistory'] as const,
    tlm: {
      all: () => [...queryKeys.billing.all(), 'tlm'] as const,
      tlmCosts: () => [...queryKeys.billing.tlm.all(), 'tlmCost'] as const,
      tlmTokenCosts: () => [...queryKeys.billing.tlm.all(), 'tlmTokenCost'] as const,
    },
  },
}

export interface SubscriptionDetails {
  cancelledAt: number | null
  cleansetExportRowsMonthlyLimit: number
  createdAt: number
  datasetRowsLimit: number
  datasetSizeBytesLimit: number
  datasetNumColumnsLimit: number
  projectNumClassesLimit: number
  projectInstanceSizeLimit: string
  projectTrainingTimeLimit: number
  description: string
  isEnterprisePlan: boolean
  isIndividualPayingCustomer: boolean
  freeCleansetExportRowsMonthlyLimit: number
  freeInferenceExportRowsMonthlyLimit: number
  freeModelsMonthlyLimit: number
  freeProjectsMonthlyLimit: number
  freeTrialDatasetRowsLimit: number
  freeTrialInferenceExportRowsLimit: number
  freeTrialLengthDays: number
  freeTrialModelsLimit: number
  freeTrialPeriod: boolean
  freeTrialPeriodEnd: number
  freeTrialProjectsLimit: number
  freeTrialPeriodRemaining: number
  inferenceExportRowsMonthlyLimit: number
  lastUpdated: number
  payingCustomer: boolean
  planName: string
  pricePerMonth: number
  pricePerRowCleansetExport: number
  pricePerRowInferenceExport: number
  pricePerYear: number
  tier: string
  userId: string
  isLoading: boolean
}

export interface SubscriptionDetailsRes {
  cleanset_export_rows_monthly_limit: number
  created_at: number
  dataset_rows_limit: number
  dataset_size_limit_bytes: number
  dataset_num_columns_limit: number
  project_num_classes_limit: number
  project_instance_size_limit: string
  project_training_time_limit: number
  description: string
  is_enterprise_plan: boolean
  is_individual_paying_customer: boolean
  free_cleanset_export_rows_monthly_limit: number
  free_inference_export_rows_monthly_limit: number
  free_models_monthly_limit: number
  free_projects_monthly_limit: number
  free_trial_dataset_rows_limit: number
  free_trial_inference_export_rows_limit: number
  free_trial_length_days: number
  free_trial_models_limit: number
  free_trial_period: boolean
  free_trial_period_end: number
  free_trial_projects_limit: number
  free_trial_period_remaining: number
  inference_export_rows_monthly_limit: number
  last_updated: number
  paying_customer: boolean
  plan_name: string
  price_per_month: number
  price_per_row_cleanset_export: number
  price_per_row_inference_export: number
  price_per_year: number
  tier: string
  user_id: string
  cancelled_at: number | null
}

export interface SubscriptionData {
  cleanset_export_rows_monthly_limit: number
  free_cleanset_export_rows_monthly_limit: number
  free_inference_export_rows_monthly_limit: number
  free_models_monthly_limit: number
  free_projects_monthly_limit: number
  inference_export_rows_monthly_limit: number
  dataset_rows_limit: number
  dataset_size_limit_bytes: number
  dataset_num_columns_limit: number
  project_num_classes_limit: number
  project_instance_size_limit: string
  project_training_time_limit: number
  price_per_month: number
  price_per_year: number
  price_per_row_cleanset_export: number
  price_per_row_inference_export: number
  free_trial_dataset_rows_limit: number
  free_trial_length_days: number
  free_trial_models_limit: number
  free_trial_projects_limit: number
  free_trial_inference_export_rows_limit: number
  free_trial_period_end: number
  id: string
}
export interface GetBillingSubscriptionsRes {
  rows: SubscriptionData[]
}

export interface GetBillingPlansRes {
  rows: PlanData[]
}

export interface PlanData {
  cleanset_export_rows_monthly_limit: number
  free_cleanset_export_rows_monthly_limit: number
  free_inference_export_rows_monthly_limit: number
  free_models_monthly_limit: number
  free_projects_monthly_limit: number
  inference_export_rows_monthly_limit: number
  price_per_month: number
  price_per_year: number
  price_per_row_cleanset_export: number
  price_per_row_inference_export: number
  free_trial_dataset_rows_limit: number
  free_trial_length_days: number
  free_trial_models_limit: number
  free_trial_projects_limit: number
  free_trial_inference_export_rows_limit: number
  dataset_rows_limit: number
  dataset_size_limit_bytes: number
  dataset_num_columns_limit: number
  project_num_classes_limit: number
  project_instance_size_limit: string
  project_training_time_limit: number
  plan_name: string
  tier: string
  id: string
  description: string
}

export interface CleansetExportDetailsRes {
  previously_exported: boolean
  num_free_rows: number
  num_overage_rows: number
  price_per_row_export: number
  total_price: number
  num_free_cleanset_export_rows_remaining: number
  num_total_cleanset_export_rows_remaining: number
}

export interface CleansetExportDetailsData {
  previouslyExported: boolean
  numFreeRows: number
  numOverageRows: number
  pricePerRowExport: number
  totalPrice: number
  numFreeCleansetExportRowsRemaining: number
  numTotalCleansetExportRowsRemaining: number
}

export interface InferenceExportDetailsRes {
  previously_exported: boolean
  num_free_rows: number
  num_overage_rows: number
  price_per_row_export: number
  total_price: number
  num_free_inference_export_rows_remaining: number
  num_total_inference_export_rows_remaining: number
}

export interface InferenceExportDetailsData {
  previouslyExported: boolean
  numFreeRows: number
  numOverageRows: number
  pricePerRowExport: number
  totalPrice: number
  numFreeInferenceExportRowsRemaining: number
  numTotalInferenceExportRowsRemaining: number
}

export interface MonthlyTlmTokenUsageRes {
  totalTokens: number
}

export const planTiers = ['individual', 'enterprise']
