import { Box } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { IS_VPC_ENVIRONMENT, STRIPE_PUBLIC_KEY } from '@utils/environmentVariables'
import React, { PropsWithChildren } from 'react'

const stripePromise = IS_VPC_ENVIRONMENT ? null : loadStripe(STRIPE_PUBLIC_KEY)

const ElementsProvider: React.FC<PropsWithChildren & { clientSecret: string }> = ({
  children,
  clientSecret,
}) => {
  const options = {
    clientSecret,
  }
  return (
    <Box>
      {stripePromise && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          {children}
        </Elements>
      )}
    </Box>
  )
}
export default ElementsProvider
