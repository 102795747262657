'use client'

import { MeterIndicatorRoot } from '@components/meter/Meter'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cn } from '@utils/tailwindUtils'
import { type ComponentPropsWithoutRef, type ComponentPropsWithRef, forwardRef, memo } from 'react'

type ProgressProps = ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>

export const ProgressRoot = forwardRef(
  (
    { className, ...props }: ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>,
    ref: ComponentPropsWithRef<typeof ProgressPrimitive.Root>['ref']
  ) => {
    return (
      <ProgressPrimitive.Root
        ref={ref}
        className={cn(
          'relative h-3 w-full overflow-hidden rounded-full bg-surface-2-hover',
          className
        )}
        {...props}
      />
    )
  }
)

export const ProgressIndicator = ({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof MeterIndicatorRoot>) => {
  return <MeterIndicatorRoot className={cn('bg-cyan-500', className)} {...props} />
}

const ProgressBarBase = (
  { className, value, max = 100, ...props }: ProgressProps,
  ref: ComponentPropsWithRef<typeof ProgressPrimitive.Root>['ref']
) => (
  <ProgressRoot
    ref={ref}
    className={cn('relative h-3 w-full overflow-hidden rounded-full bg-surface-2-hover', className)}
    value={value}
    max={max}
    {...props}
  >
    <ProgressIndicator ratio={(value ?? 0) / max} />
  </ProgressRoot>
)

export const ProgressBar = memo(forwardRef(ProgressBarBase))
ProgressBar.displayName = ProgressPrimitive.Root.displayName
