/**
 * Finds the first occurrence of a string between two other strings.
 */
export const findBetween = (str: string, start: string, end: string) => {
  const startIndex = str.indexOf(start)
  if (startIndex === -1) {
    return ''
  }
  const endIndex = str.indexOf(end, startIndex + start.length)
  if (endIndex === -1) {
    return ''
  }
  return str.substring(startIndex + start.length, endIndex)
}
