import { Code, Tooltip, useColorModeValue } from '@chakra-ui/react'
import { RowCountType } from '@services/datasheet/constants'
import { ColumnStatus } from 'src/pages/cleanset/Cleanset.types'
import { ISSUE_TYPE, METADATA_TYPE } from 'src/pages/cleanset/filterSidebar/FilterSidebar.types'

import {
  PresetFilterRowTooltipProps,
  PresetFilterRowTooltipTitle,
} from './PresetFilterRowTooltip.types'

const PresetFilterRowTooltip = (props: PresetFilterRowTooltipProps) => {
  const { children, columnStatus, filterType } = props

  const tooltipCodeBg = useColorModeValue('neutral.100', 'neutralDarkMode.100')
  const tooltipCodeColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')

  const getTooltipHeader = (issue: string, status: string) => {
    switch (status) {
      case ColumnStatus.READY:
        return (
          <>
            Click to view <strong>{PresetFilterRowTooltipTitle[issue]}</strong>
          </>
        )
      case ColumnStatus.PENDING:
        return (
          <>
            Columns required to view <strong>{PresetFilterRowTooltipTitle[issue]}</strong> not yet
            ready
          </>
        )
      case ColumnStatus.ERROR:
        return (
          <>
            Error computing columns for <strong>{PresetFilterRowTooltipTitle[issue]}</strong>
          </>
        )
      default:
        return ''
    }
  }

  const getTooltipBody = (issue: string) => {
    switch (issue) {
      case RowCountType.TOTAL: {
        return <>Remove existing filters and show all data points, ordered by label issue score.</>
      }
      case RowCountType.ISSUES_RESOLVED: {
        return (
          <>
            These are data points with issues for which you’ve already taken a corrective action,
            such as auto-fixing the label or excluding the data point from the Dataset.
          </>
        )
      }
      case METADATA_TYPE.BEST_EXAMPLES: {
        return (
          <>
            Well-labeled data points likely have the correct given label, and they are good
            representatives of their given class.
          </>
        )
      }
      case RowCountType.LABEL_ISSUES: {
        return (
          <>
            Label issues are data points that are potentially mislabeled. The{' '}
            <Code bg={tooltipCodeBg} color={tooltipCodeColor} fontSize="xs">
              Suggested
            </Code>{' '}
            column shows the most likely alternative labels for these data points.
          </>
        )
      }
      case ISSUE_TYPE.OUTLIER: {
        return (
          <>
            Outliers are data points that are significantly different from other data. Such data
            might be appropriate to exclude from the Dataset.
          </>
        )
      }
      case ISSUE_TYPE.UNLABELED: {
        return <>Unlabeled data points do not have a given label in the original Dataset.</>
      }
      case ISSUE_TYPE.AMBIGUOUS: {
        return (
          <>
            Ambiguous data points are not well-described by any of the classes in this Dataset. Such
            data might be appropriate to exclude from the Dataset.
          </>
        )
      }
      case ISSUE_TYPE.IS_NEAR_DUPLICATE: {
        return (
          <>
            Near-duplicate data points are identical or near-identical examples. Duplicates might be
            appropriate to exclude from the Dataset.
          </>
        )
      }
      case METADATA_TYPE.IS_PII: {
        return (
          <>
            Personally Identifiable Information (PII) is information that could be used to identify
            an individual or is otherwise sensitive.
          </>
        )
      }
      case METADATA_TYPE.IS_INFORMAL: {
        return (
          <>
            Informal text contains casual language or language errors such as improper grammar or
            spelling.
          </>
        )
      }
      case METADATA_TYPE.IS_NON_ENGLISH: {
        return <>Non-English text may include foreign languages or nonsensical characters.</>
      }
      case METADATA_TYPE.IS_TOXIC: {
        return (
          <>
            Toxic language text may contain hateful speech, harmful language, aggression, or related
            toxic elements.
          </>
        )
      }
      case METADATA_TYPE.IS_BIASED: {
        return (
          <>
            Biased text may contain language that is prejudiced, unfair, or discriminatory in the
            categories of gender, race, and sexual orientation.
          </>
        )
      }
      case METADATA_TYPE.IS_DARK: {
        return (
          <>
            Dark images have insufficient brightness, appearing dim or underexposed, resulting in a
            lack of detail and clarity.
          </>
        )
      }
      case METADATA_TYPE.IS_LIGHT: {
        return (
          <>
            Light images are excessively overexposed or washed out due to an abundance of light or
            high brightness levels.
          </>
        )
      }
      case METADATA_TYPE.IS_BLURRY: {
        return (
          <>
            Blurry images lack sharpness and clarity, causing the subjects or details to appear
            hazy, out of focus, or indistinct.
          </>
        )
      }
      case METADATA_TYPE.IS_ODD_SIZE: {
        return <>Odd sized images are unusually large or small.</>
      }
      case METADATA_TYPE.IS_LOW_INFORMATION: {
        return (
          <>Low information images are lacking content (low entropy in values of their pixels).</>
        )
      }
      case METADATA_TYPE.IS_GRAYSCALE: {
        return <>Grayscale images lack color.</>
      }
      case METADATA_TYPE.IS_ODD_ASPECT_RATIO: {
        return (
          <>
            Odd aspect ratio images have unusually long width or height (dimensions) compared to the
            other images in the Dataset.
          </>
        )
      }
      case METADATA_TYPE.IS_NSFW: {
        return (
          <>
            NSFW (Not Safe For Work) images refer to any visual content that is not suitable for
            viewing in a professional or public environment, typically due to its explicit,
            pornographic, or graphic nature.
          </>
        )
      }
      default: {
        return ''
      }
    }
  }

  return (
    <Tooltip
      hasArrow
      label={
        <>
          {getTooltipHeader(filterType, columnStatus)}
          <br />
          <br />
          {getTooltipBody(filterType)}
        </>
      }
      placement="right"
    >
      {children}
    </Tooltip>
  )
}

export default PresetFilterRowTooltip
