import { Button, ButtonGroup } from '@chakra-ui/react'
import React from 'react'

import { SegmentedButtonGroupProps } from './SegmentedButtonGroup.types'

const SegmentedButtonGroup = (props: SegmentedButtonGroupProps) => {
  const {
    borderColor,
    isButtonLeftActive,
    isDisabled,
    onLeftButtonClick,
    onRightButtonClick,
    buttonLeftText,
    buttonRightText,
    width,
  } = props
  return (
    <ButtonGroup
      border="1px solid"
      borderColor={borderColor}
      borderRadius="lg"
      h="28px"
      p="2px"
      w={width}
      isAttached
    >
      <Button
        isActive={isButtonLeftActive}
        isDisabled={isDisabled}
        variant="segmented"
        onClick={onLeftButtonClick}
      >
        {buttonLeftText}
      </Button>
      <Button
        isActive={!isButtonLeftActive}
        isDisabled={isDisabled}
        variant="segmented"
        onClick={onRightButtonClick}
      >
        {buttonRightText}
      </Button>
    </ButtonGroup>
  )
}

export default SegmentedButtonGroup
