import { useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import RegisterNotification, {
  useAllowRegisterNotification,
} from '@common/misc/registerNotification/RegisterNotification'
import { Button } from '@components/button/Button'
import { IconMail } from '@components/icons'
import { ProgressBar } from '@components/progress/ProgressBar'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useEventTracking } from '@hooks/useEventTracking'
import { useProgress } from '@hooks/useProgress'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import uploadApiService from '@services/upload/v1/uploadApi'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { AxiosError } from 'axios'
import { ReactNode, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UPLOAD_PATH_NAME } from 'src/pages/datasetUpload/DatasetUpload.types'
import {
  ERROR_DESCRIPTIONS,
  PROCESSING_TEXT,
} from 'src/pages/datasetUpload/uploadErrors/ErrorDescriptions.text'
import IngestionErrorsCard from 'src/pages/datasetUpload/uploadErrors/IngestionErrorsCard'
import { PreviousLink } from 'src/pages/projectForm/CreateProject.helpers'

import { IMPORTING_DATA_PHASE, ProcessingDatasetProps } from './ProcessingDataset.types'

const CONTENT_WIDTH_LIMIT_CLASS = 'max-w-[460px]'

const BodyP = ({ children }: { children: ReactNode }) => (
  <p className={`type-body-200 text-center text-text-faint ${CONTENT_WIDTH_LIMIT_CLASS}`}>
    {children}
  </p>
)

const HandledError = () => (
  <>
    <BodyP>{ERROR_DESCRIPTIONS.handledErrorPartTwo}</BodyP>
    <BodyP>{ERROR_DESCRIPTIONS.handledErrorPartOne}</BodyP>
  </>
)

const UnhandledErrorVpc = () => <BodyP>{ERROR_DESCRIPTIONS.unhandledErrorVpc}</BodyP>

const UnhandledError = ({ uploadId }: { uploadId: string }) => {
  const allowRegisterNotification = useAllowRegisterNotification()

  return (
    <>
      <BodyP>
        {ERROR_DESCRIPTIONS.unhandledErrorPartOne}{' '}
        {allowRegisterNotification && (
          <>
            Click <RegisterNotification uploadId={uploadId}>here</RegisterNotification> to be
            notified by email once the issue is fixed.{' '}
          </>
        )}
        {ERROR_DESCRIPTIONS.unhandledErrorPartTwo}
      </BodyP>
      <BodyP>{ERROR_DESCRIPTIONS.unhandledErrorPartThree}</BodyP>
    </>
  )
}

const ImportingText = () => <BodyP>Importing your data...</BodyP>

const EmailNotificationButton = ({ uploadId }: { uploadId: string }) => {
  const toast = useToast()
  const [requestSuccessful, setRequestSuccessful] = useState(false)
  return (
    <Tooltip
      content={
        requestSuccessful
          ? PROCESSING_TEXT.emailButtonTooltipDisabled
          : PROCESSING_TEXT.emailButtonTooltip
      }
    >
      <Button
        variant="primary"
        size="medium"
        iconStart={<IconMail />}
        disabled={requestSuccessful}
        onClick={async () => {
          try {
            setRequestSuccessful(true)
            await uploadApiService.requestEmail(uploadId)
            toast({
              ...defaultToastAlertProps,
              render: renderChakraToastAlert({
                heading: PROCESSING_TEXT.emailToastText,
                status: 'default',
              }),
            })
          } catch (err) {
            toast({
              ...defaultToastAlertProps,
              render: renderChakraToastAlert({
                heading: 'Error requesting email',
                description: 'Please try again',
                status: 'warning',
              }),
            })
            setRequestSuccessful(false)
          }
        }}
      >
        {PROCESSING_TEXT.emailButtonText}
      </Button>
    </Tooltip>
  )
}

const GoToDashboardButton = () => {
  const { trackEvent } = useEventTracking()
  const navigate = useNavigate()
  return (
    <Button
      size="small"
      variant="secondary"
      onClick={() => {
        trackEvent(MixpanelEvents.clickGoToDashboard)
        navigate('/')
      }}
    >
      Go to dashboard
    </Button>
  )
}

const ProcessingText = ({ uploadId }: { uploadId: string }) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <BodyP>{PROCESSING_TEXT.p1}</BodyP>
        <BodyP>{PROCESSING_TEXT.p2}</BodyP>
      </div>
      <div className="flex justify-center gap-4">
        <EmailNotificationButton uploadId={uploadId} />
        <GoToDashboardButton />
      </div>
    </>
  )
}

const ProcessingDataset = ({
  uploadId,
  initialUploadPhase,
  handleFinishProcessing,
}: ProcessingDatasetProps) => {
  const [uploadPhase, setUploadPhase] = useState<number>(initialUploadPhase)
  const [progress, setProgress] = useState<number | null>(null)
  const [errorType, setErrorType] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const { pollProgress, clearPoll } = useProgress(uploadApiService.getUploadProgressByPhase)
  const toast = useToast()
  const MAX_PROGRESS = 1

  const updateProgress = (progressPercent: number | null, extraData?: any) => {
    setProgress(progressPercent)
    if (extraData?.phase !== undefined) {
      setUploadPhase(extraData.phase)
    }
  }

  useEffect(() => {
    try {
      if (errorMessage === undefined && progress !== MAX_PROGRESS) {
        pollProgress(
          uploadId,
          500,
          updateProgress,
          handleFinishProcessing,
          async (type, message) => {
            setErrorType(type)
            setErrorMessage(message)
          }
        )
      }
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError)
      clearPoll()
    }
    // eslint-disable-next-line
  }, [clearPoll, errorMessage, handleFinishProcessing, pollProgress, toast, uploadId, uploadPhase])

  const hasError = errorMessage !== undefined || errorType

  let errorTopText = <HandledError />
  if (!errorType) {
    if (IS_VPC_ENVIRONMENT) {
      errorTopText = <UnhandledErrorVpc />
    } else {
      errorTopText = <UnhandledError uploadId={uploadId} />
    }
  }

  return (
    <div>
      {hasError && (
        <PreviousLink asChild>
          <Link to={`/${UPLOAD_PATH_NAME}`}>Back</Link>
        </PreviousLink>
      )}
      <div className="flex w-full flex-col items-center justify-start gap-4 py-8">
        {hasError && (
          <div className="flex w-full flex-col items-center gap-4">
            {errorTopText}
            {errorMessage && (
              <div className="w-full max-w-[800px] py-3">
                <IngestionErrorsCard errorType={errorType} errorMessage={errorMessage} />
              </div>
            )}
            {errorType && <BodyP>{ERROR_DESCRIPTIONS.handledErrorPartThree}</BodyP>}
          </div>
        )}
        {!hasError && (
          <div className="flex flex-col gap-8">
            {uploadPhase <= IMPORTING_DATA_PHASE ? (
              <ImportingText />
            ) : (
              <ProcessingText uploadId={uploadId} />
            )}
            <div className={`flex h-4 w-full px-6 ${CONTENT_WIDTH_LIMIT_CLASS}`}>
              <ProgressBar className="w-full" value={progress ?? 0} max={1} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProcessingDataset
