import { InputApiKey } from '@common/apiKey/InputApiKey'
import { CodeInline } from '@components/code/CodeInline'
import { FormField } from '@components/formField/FormField'
import { Input } from '@components/input/Input'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useApiKey } from '@services/commandLine/queries'
import {
  CreateProjectContainer,
  CreateProjectContent,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  WithSidebarContainer,
} from 'src/pages/projectForm/CreateProject.helpers'
import { InlineLink } from 'src/pages/projectForm/Sidebar'

import { UploadType } from '../DatasetUpload.types'
import { DatasetUploadSidebarStandard } from '../DatasetUploadSidebar'
import { ApiKeyText } from '../pythonApiUpload/PythonApiUpload'
import { UploadBackLink } from '../UploadBackLink'

const CliUpload = () => {
  const { trackEvent } = useEventTracking()
  const { apiKey } = useApiKey()

  return (
    <CreateProjectContainer>
      <UploadBackLink />
      <WithSidebarContainer>
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Import via command line</PageHeading>
            <PageDescription>
              We offer a{' '}
              <InlineLink
                to="https://github.com/cleanlab/cleanlab-studio"
                onClick={() => trackEvent(MixpanelEvents.clickCliDocsLinks)}
              >
                command line interface
              </InlineLink>{' '}
              for importing larger Datasets. To upload a Dataset, first authenticate yourself with
              your API key using <CodeInline>cleanlab login</CodeInline>. <ApiKeyText />
            </PageDescription>
          </HeadingWrapper>
          <div className="flex flex-col gap-8">
            <FormField label="API key">
              <InputApiKey
                value={apiKey}
                onClickCopy={() =>
                  trackEvent(MixpanelEvents.clickApiKeyCopy, { uploadType: UploadType.commandLine })
                }
              />
            </FormField>
            <FormField label="Next, run the following command with your Dataset details">
              <Input canCopy={true} value="cleanlab dataset upload [filepath]" />
            </FormField>
          </div>
        </CreateProjectContent>
        <DatasetUploadSidebarStandard />
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}

export { CliUpload }
