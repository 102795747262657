import { cleanlabColors } from '@assets/styles/CleanlabColors'
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'
import { IconExpand } from '@components/icons/IconExpand'
import { useErrorsByColumn } from '@services/datasheet/queries'
import { useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { CHART_TEXT_DICT } from '../CleansetCharts.helpers'
import { RankedChart, RankedChartKey, RankedChartMode } from '../CleansetCharts.types'
import { generateDefaultCheckboxValues } from './IssuesByColumnModal.helpers'
import { IssuesByColumnModalProps } from './IssuesByColumnModal.types'

export const IssuesByColumnModal = (props: IssuesByColumnModalProps) => {
  const {
    onClose,
    isOpen,
    columns,
    selectedIssueColumn,
    setSelectedIssueColumn,
    setRankedChartsToShow,
    rankedChartsToShow,
    setRankedChartMode,
    rankedChartMode,
    cleansetId,
  } = props

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [columnSelection, setColumnSelection] = useState<string | null>(selectedIssueColumn)

  const popoverRef = useRef<HTMLDivElement | null>(null)
  const { control, watch, reset, setValue } = useForm()
  const rankChartValues = watch('rankCharts')
  const displayTypeValues = watch('displayType')

  const backgroundColor = useColorModeValue('neutral.50', 'neutralDarkMode.50')
  const textFaint = useColorModeValue('neutral.700', 'neutralDarkMode.700')
  const borderColor = useColorModeValue('neutral.200', 'neutralDarkMode.200')
  const textPrimary = useColorModeValue(
    cleanlabColors.neutral[800],
    cleanlabColors.neutralDarkMode[800]
  )

  const errorsByColumnData = useErrorsByColumn({
    cleansetId: cleansetId,
    column: columnSelection ?? '',
    enabled: true,
  })

  const mostErrorsKey = useMemo(
    () => `mostErrorsBy${rankedChartMode}` as RankedChartKey,
    [rankedChartMode]
  )

  const leastErrorsKey = useMemo(
    () => `leastErrorsBy${rankedChartMode}` as RankedChartKey,
    [rankedChartMode]
  )

  const mostUnlabeledKey = useMemo(
    () => `mostUnlabeledBy${rankedChartMode}` as RankedChartKey,
    [rankedChartMode]
  )

  const hasMostUnlabeled = useMemo(
    () => (errorsByColumnData?.[mostUnlabeledKey] ?? []).some((col) => col.unlabeled !== 0),
    [errorsByColumnData, mostUnlabeledKey]
  )

  const hasWellLabeled = useMemo(
    () =>
      (errorsByColumnData?.mostWellLabeledByPercentage ?? []).some((col) => col.wellLabeled !== 0),
    [errorsByColumnData]
  )

  const hasBestPerformers = useMemo(
    () =>
      (errorsByColumnData?.[leastErrorsKey] ?? []).some((col) => {
        if (rankedChartMode === RankedChartMode.Percentage) {
          return col.totalIssuesPercentage !== 0
        } else {
          return col.totalIssuesCount !== 0
        }
      }),
    [errorsByColumnData, leastErrorsKey, rankedChartMode]
  )

  const hasIssues = useMemo(
    () =>
      (errorsByColumnData?.[mostErrorsKey] ?? []).some((col) => {
        if (rankedChartMode === RankedChartMode.Percentage) {
          return col.totalIssuesPercentage !== 0
        } else {
          return col.totalIssuesCount !== 0
        }
      }),
    [errorsByColumnData, mostErrorsKey, rankedChartMode]
  )

  useOutsideClick({
    ref: popoverRef,
    handler: () => setIsPopoverOpen(false),
  })

  const onClearAll = () => {
    setValue('rankCharts', defaultCheckboxValues)
    setValue('displayType', RankedChartMode.Percentage)
    setRankedChartsToShow([])
    setRankedChartMode(RankedChartMode.Percentage)
    onClose()
  }

  // On selected column change, reset the form and set the default values
  const defaultCheckboxValues = useMemo(() => {
    if (columnSelection) {
      reset()
      return generateDefaultCheckboxValues(
        !hasIssues,
        !hasBestPerformers,
        hasMostUnlabeled,
        hasWellLabeled
      )
    } else {
      return []
    }
  }, [reset, hasBestPerformers, hasIssues, hasMostUnlabeled, hasWellLabeled, columnSelection])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW="40%" background={backgroundColor}>
        <ModalHeader
          borderBottom="1px solid"
          borderColor={borderColor}
          padding="16px 24px"
          fontSize="18px"
          color={useColorModeValue('neutral.900', 'neutralDarkMode.900')}
        >
          View issues by source
        </ModalHeader>
        <ModalBody maxW="100%" p="24px" flexGrow={1}>
          <HStack alignItems="flex-start" justify="space-between">
            <VStack alignItems="flex-start" spacing="4px">
              <Text fontSize="16px" fontWeight="500" color={textPrimary}>
                Select a column to view issues by column value
              </Text>
              <Text fontSize="14px" fontWeight="400" color={textFaint}>
                e.g. column source "annotator name"
              </Text>
            </VStack>
            <Popover placement="bottom-end" isOpen={isPopoverOpen}>
              <PopoverTrigger>
                <Button
                  variant="secondary"
                  fontSize="14px"
                  height="36px"
                  p="8px 12px 8px 16px"
                  rightIcon={<IconExpand />}
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  {columnSelection}
                </Button>
              </PopoverTrigger>
              <PopoverContent ref={popoverRef}>
                <PopoverBody mx="1px" p="8px">
                  <VStack alignItems="flex-start" overflowY="auto" maxH="25vh">
                    {columns.map((column) => (
                      <Button
                        key={column}
                        variant="columnSelectButton"
                        justifyContent="flex-start"
                        w="100%"
                        onClick={() => {
                          setColumnSelection(column)
                          setIsPopoverOpen(false)
                        }}
                      >
                        {column}
                      </Button>
                    ))}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
          {columnSelection && (
            <div>
              <Text pt="40px" fontSize="16px" fontWeight="500" color={textPrimary}>
                Select graphs to add:
              </Text>
              <Text fontSize="14px" fontWeight="400" color={textFaint}>
                Selected options will be added to your analytics page.
              </Text>
              <Controller
                control={control}
                name="rankCharts"
                key={columnSelection}
                defaultValue={defaultCheckboxValues}
                render={({ field: { value, onChange } }) => {
                  return (
                    <CheckboxGroup value={value} onChange={onChange}>
                      <Grid templateColumns="1fr 1fr" gap="1rem" marginTop="1rem">
                        <Checkbox
                          isDisabled={!hasIssues}
                          variant="cleanlabBlue"
                          value={RankedChart.WorstPerformers}
                        >
                          <Text color={textPrimary}>
                            {CHART_TEXT_DICT.titles.sourcesWithMostIssues}
                          </Text>
                        </Checkbox>
                        <Checkbox
                          isDisabled={!hasIssues || !hasBestPerformers}
                          variant="cleanlabBlue"
                          value={RankedChart.BestPerformers}
                        >
                          <Text color={textPrimary}>
                            {CHART_TEXT_DICT.titles.sourcesWithLeastIssues}
                          </Text>
                        </Checkbox>
                        <Checkbox
                          disabled={!hasMostUnlabeled}
                          variant="cleanlabBlue"
                          value={RankedChart.MostUnlabeled}
                        >
                          <Text color={textPrimary}>
                            {CHART_TEXT_DICT.titles.sourcesWithMostUnlabeled}
                          </Text>
                        </Checkbox>
                        <Checkbox
                          disabled={!hasWellLabeled}
                          variant="cleanlabBlue"
                          value={RankedChart.MostWellLabeled}
                        >
                          <Text color={textPrimary}>
                            {CHART_TEXT_DICT.titles.sourcesWithMostWellLabeled}
                          </Text>
                        </Checkbox>
                      </Grid>
                    </CheckboxGroup>
                  )
                }}
              />
              <Controller
                control={control}
                name="displayType"
                key={defaultCheckboxValues.length}
                defaultValue={rankedChartMode}
                render={({ field: { onChange } }) => {
                  return (
                    <Box fontWeight="500" pt="40px">
                      <Text fontSize="16px" color={textPrimary}>
                        Display type:
                      </Text>
                      <RadioGroup
                        pt="16px"
                        fontSize="14px"
                        color={textPrimary}
                        defaultValue={rankedChartMode}
                        onChange={onChange}
                      >
                        <Stack direction="row" spacing="6rem">
                          <Radio variant="cleanlabBlue" value={RankedChartMode.Percentage}>
                            Show as percentage
                          </Radio>
                          <Radio variant="cleanlabBlue" value={RankedChartMode.Count}>
                            Show as count
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Box>
                  )
                }}
              />
            </div>
          )}
        </ModalBody>
        <ModalCloseButton />
        <ModalFooter borderTop="1px solid" borderColor={borderColor}>
          <HStack w="100%" justify="space-between">
            {rankedChartsToShow.length ? (
              <SecondaryButton fontSize="14px" height="32px" onClick={onClearAll}>
                Clear all
              </SecondaryButton>
            ) : (
              <Box />
            )}
            <HStack>
              <SecondaryButton
                fontSize="14px"
                height="32px"
                onClick={() => {
                  onClose()
                  reset()
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                height="32px"
                fontSize="14px"
                type="submit"
                onClick={() => {
                  setRankedChartsToShow(rankChartValues ?? defaultCheckboxValues)
                  setRankedChartMode(displayTypeValues ?? rankedChartMode)
                  setSelectedIssueColumn(columnSelection ?? '')
                  onClose()
                }}
              >
                View
              </PrimaryButton>
            </HStack>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
