import { Button, HStack, Menu, MenuButton, Text, useDisclosure } from '@chakra-ui/react'
import { Badge } from '@components/badge/Badge'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { ColDef } from 'ag-grid-community'
import { FiChevronDown } from 'react-icons/fi'

import {
  actionToHue,
  categoryDisplay,
} from '../../datasheet/actionCellRenderer/actionCellTag/ActionCellTag.types'
import { FilterData, PresetHeaders } from '../../filterReducer/FilterState.types'
import { useFilters } from '../../filterReducer/useFilters'
import EditFilterMenu from '../editFilterMenu/EditFilterMenu'
import { getFilterSymbol } from './FilterButton.helpers'
import { FilterButtonProps } from './FilterButton.types'

const FilterButton = (props: FilterButtonProps) => {
  const {
    gridOptions,
    filterData,
    cleansetId,
    isMultilabel,
    labelsIndexed,
    firstGridDataRendered,
    resetResolverAndRowSelection,
  } = props

  const { isOpen, onOpen } = useDisclosure()

  const { state } = useFilters()

  const { trackEvent } = useEventTracking()

  const { headerName } = filterData

  const getPresetProperties = () => {
    if (headerName && headerName !== 'Add new filter') {
      return [...([gridOptions.columnDefs?.find((e) => e.headerName === headerName)] as ColDef[])]
    } else {
      return [
        ...(gridOptions.columnDefs?.slice(-6) as ColDef[]),
        ...(gridOptions.columnDefs?.slice(0, -6) as ColDef[]),
      ].filter((e) => !Object.values(PresetHeaders).includes(e.headerName as PresetHeaders))
    }
  }

  const presetProperties = getPresetProperties()
  const selectedProperty = presetProperties[0] ? (presetProperties[0].field as string) : headerName

  const renderFilterInput = (
    filterData: FilterData,
    headerName: string,
    allFilterData: FilterData[]
  ) => {
    if (headerName === PresetHeaders.Issues) {
      const checkedIssueFilters = allFilterData.filter((f) => f.isChecked)
      if (checkedIssueFilters.length > 0) {
        return checkedIssueFilters.map((f) => f.headerName).join(', ')
      }
    } else if (
      isMultilabel &&
      (headerName === PresetHeaders.Given || headerName === PresetHeaders.Suggested)
    ) {
      if (Array.isArray(filterData.filterInput) && filterData.filterInput.length > 0) {
        return filterData.filterInput.includes('_empty')
          ? 'No label'
          : filterData.filterInput.map((f) => f).join(', ')
      }
    } else if (headerName === 'Action') {
      const text = filterData.filterInput?.toString()
      return (
        <div className="flex items-center gap-3">
          {getFilterSymbol(filterData.filterType)}{' '}
          <Badge round="small" variant="actionSubtle" hue={actionToHue[text]} size="small">
            {categoryDisplay[text] || '-'}
          </Badge>
        </div>
      )
    }
    return getFilterSymbol(filterData.filterType) + ' ' + filterData.filterInput
  }

  return (
    <Menu
      key="newFilter"
      onOpen={() => {
        onOpen()
        trackEvent(MixpanelEvents.clickExistingFilterButton, {
          ...filterData,
          cleansetId: cleansetId,
        })
      }}
      closeOnSelect={false}
    >
      <MenuButton
        as={Button}
        rightIcon={<FiChevronDown />}
        colorScheme="gray"
        px="12px"
        height="2rem"
        fontSize="sm"
      >
        <HStack>
          <Text>{headerName.charAt(0).toUpperCase() + headerName.slice(1)}: </Text>
          <Text fontWeight="bold">
            {renderFilterInput(filterData, headerName, state.filterData)}
          </Text>
        </HStack>
      </MenuButton>
      <EditFilterMenu
        gridOptions={gridOptions}
        selectedProperty={selectedProperty as string}
        presetProperties={presetProperties}
        isOpen={isOpen}
        filterData={filterData}
        cleansetId={cleansetId}
        isMultilabel={isMultilabel}
        labelsIndexed={labelsIndexed}
        firstGridDataRendered={firstGridDataRendered}
        resetResolverAndRowSelection={resetResolverAndRowSelection}
      />
    </Menu>
  )
}

export default FilterButton
