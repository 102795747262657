import { accordionAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys)

export const accordionTheme = defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    dropdownHeader: (props: StyleFunctionProps) => ({
      root: {},
      container: { border: 0 },
      button: {
        color: mode('gray.900', 'neutralDarkMode.900')(props),
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        fontSize: '14px',
      },
      panel: { padding: 0 },
      icon: {
        color: mode('gray.800', 'grayDarkModeDeprecated.800')(props),
        _hover: {
          bg: mode('gray.50', 'grayDarkModeDeprecated.50')(props),
        },
      },
    }),
  },
})
