import { UseToastOptions } from '@chakra-ui/react'
import { ButtonClose } from '@components/buttonClose/ButtonClose'
import { IconCaution, IconCheckCircleFilled, IconInfo, IconWarning } from '@components/icons'
import { cn, tv } from '@utils/tailwindUtils'
import {
  cloneElement,
  ComponentProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  memo,
  ReactElement,
  ReactNode,
} from 'react'
import { Except } from 'type-fest'

export type ToastStatus = 'default' | 'info' | 'success' | 'warning' | 'caution'

type ToastProps = {
  heading: ReactNode
  description?: ReactNode
  status: ToastStatus
  onClose?: () => void
  actions?: ReactNode
  icon?: ReactElement | null | undefined
} & HTMLAttributes<HTMLDivElement>

export const ToastActions = (props: any) => {
  return <div {...props}></div>
}

const variants = tv(
  {
    slots: {
      base: 'flex w-full min-w-0 max-w-[500px] flex-col items-start justify-between gap-y-6 rounded-3 border border-border-1 bg-surface-1 p-6 align-middle shadow-elev-2',
      heading: 'type-body-200-medium text-text-primary',
      description: 'type-body-100 text-text-faint',
      icon: 'shrink-0 pt-[2px]',
    },
    variants: {
      status: {
        default: { heading: 'text-text-strong', icon: 'text-text-strong' },
        info: { heading: 'text-blue-600', icon: 'text-blue-600' },
        success: { heading: 'text-green-700', icon: 'text-green-700' },
        caution: { heading: 'text-yellow-800', icon: 'text-yellow-800' },
        warning: { heading: 'text-red-600', icon: 'text-red-600' },
      },
    },
  },
  { responsiveVariants: ['md'] }
)

const statusToIcon = {
  default: <IconWarning />,
  info: <IconInfo />,
  success: <IconCheckCircleFilled />,
  caution: <IconCaution />,
  warning: <IconWarning />,
} as const satisfies Record<ToastStatus, ReactNode>

const ToastAlertBase = (
  {
    heading,
    description,
    onClose,
    actions,
    status,
    icon: iconProp,
    className,
    ...props
  }: ToastProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    base: baseClass,
    heading: headingClass,
    description: descriptionClass,
    icon: iconClass,
  } = variants({ status })
  let icon = iconProp
  if (status !== 'default') {
    icon = statusToIcon[status] || undefined
  }
  icon = icon && cloneElement(icon, { size: 20 })

  return (
    <div ref={ref} className={cn(baseClass(), className)} {...props}>
      <div
        className={cn('flex w-full flex-row gap-x-4', description ? 'items-start' : 'items-center')}
      >
        <div className="flex grow items-start gap-x-4">
          {icon && <div className={cn(iconClass())}>{icon}</div>}
          <div className={cn('flex grow flex-col gap-1', !icon && 'col-span-2')}>
            <h2 className={headingClass()}>{heading}</h2>
            {description && <p className={descriptionClass()}>{description}</p>}
          </div>
        </div>
        {onClose && (
          <ButtonClose
            size="small"
            aria-label="Close notification"
            onClick={() => onClose()}
            className="shrink-0"
          />
        )}
      </div>
      {actions && <div className="flex w-full flex-row-reverse gap-4">{actions}</div>}
    </div>
  )
}

export const ToastAlert = memo(forwardRef(ToastAlertBase))

export const renderChakraToastAlert = ({
  ...props
}: Except<ComponentProps<typeof ToastAlert>, 'onClose'>): UseToastOptions['render'] => {
  return ({ onClose, isClosable }) => {
    return <ToastAlert {...props} {...(isClosable ? { onClose } : {})} />
  }
}
