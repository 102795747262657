import {
  Button,
  Center,
  Divider,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useInferenceExportDetails } from '@services/billing/queries'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { FiCheckCircle } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { ExportInferenceModalProps } from './ExportInferenceModal.types'

const ExportInferenceModal = (props: ExportInferenceModalProps) => {
  const { isOpen, onClose, queryId, handleExport, exportButtonLoading, exportSuccess } = props

  const { tier } = useSubscription()
  const {
    numFreeRows,
    numOverageRows,
    pricePerRowExport,
    totalPrice,
    numFreeInferenceExportRowsRemaining,
    numTotalInferenceExportRowsRemaining,
  } = useInferenceExportDetails({
    queryId: queryId,
    isIndividualPlan: tier === 'individual',
  })

  const navigate = useNavigate()
  const { trackEvent } = useEventTracking()

  const buttonColor = useColorModeValue('teal.500', 'teal.300')
  const borderColor = useColorModeValue('gray.200', 'gray.500')
  const linkColor = useColorModeValue('teal.600', 'teal.400')

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="xl" fontWeight="bold">
          Export Inference Rows
        </ModalHeader>
        {exportSuccess ? (
          <Stack w="100%" mb={12}>
            <VStack w="100%" textAlign="center" fontSize="md">
              <Center borderRadius={50} w="80px" h="80px" bgColor="green">
                <FiCheckCircle color="white" size="35%" />
              </Center>
              <Text fontSize="4xl">Export success!</Text>
              {!IS_VPC_ENVIRONMENT && (
                <Text
                  cursor="pointer"
                  color={linkColor}
                  fontSize="md"
                  textDecoration="underline"
                  onClick={() => {
                    trackEvent(MixpanelEvents.clickViewReceiptButton)
                    navigate('/account?tab=Usage%26Billing')
                  }}
                >
                  View usage
                </Text>
              )}
              <Text>Your download should begin automatically. Didn't work? </Text>
              <Text
                cursor="pointer"
                color={linkColor}
                textDecoration="underline"
                onClick={() => {
                  trackEvent(MixpanelEvents.clickTryDownloadingAgainButton)
                  handleExport(queryId)
                }}
              >
                Try downloading again.
              </Text>{' '}
            </VStack>
          </Stack>
        ) : (
          <ModalBody mx={6} mb={6} fontSize="sm">
            <Center pt={2}>
              <VStack
                px="24px"
                w="100%"
                alignItems="flex-start"
                spacing={4}
                border="1px solid"
                borderColor={borderColor}
                borderRadius="md"
              >
                <VStack pt={6} fontSize="sm" w="100%" gap={6}>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="500">Free rows remaining (monthly)</Text>
                    <Text>{numFreeInferenceExportRowsRemaining}</Text>
                  </HStack>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="500">Number of free rows used</Text>
                    <Text>{numFreeRows}</Text>
                  </HStack>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="500">Number of rows overage</Text>
                    <Text>{numOverageRows}</Text>
                  </HStack>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="500">Price per row overage</Text>
                    <Text>${pricePerRowExport / 100}</Text>
                  </HStack>
                  {totalPrice === 50 && (
                    <HStack w="100%" justify="space-between">
                      <Text fontWeight="500">Minimum payment</Text>
                      <Text>$0.50</Text>
                    </HStack>
                  )}
                </VStack>
                <Divider textAlign="center" color="#3C42571F" />
                <HStack
                  w="100%"
                  fontWeight="500"
                  fontSize="sm"
                  justify="space-between"
                  h="5rem"
                  align="start"
                  pt="12px"
                >
                  <Text>Total added to billing period</Text>
                  <Text>${(totalPrice / 100).toFixed(2)}</Text>
                </HStack>
              </VStack>
            </Center>
            <Text
              pt={5}
              color={
                numTotalInferenceExportRowsRemaining < numOverageRows + numFreeRows
                  ? 'red'
                  : undefined
              }
            >
              {numTotalInferenceExportRowsRemaining < numOverageRows + numFreeRows
                ? 'You will exceed your monthly inference export rows limit through this export. To increase your mothly limits, please contact us at '
                : 'You will be charged for any overage usage at the end of your current billing period. We offer discounts for a variety of groups, including enterprises, researchers, and startups. Contact us at '}{' '}
              <Link
                href="mailto:sales@cleanlab.ai"
                isExternal
                onClick={() => {
                  trackEvent(MixpanelEvents.clickContactUsButton)
                }}
              >
                sales@cleanlab.ai
              </Link>
              .
            </Text>
          </ModalBody>
        )}
        {!exportSuccess && (
          <ModalFooter>
            <HStack justify="space-between" w="100%">
              <Button
                variant="outline"
                color={buttonColor}
                borderColor={buttonColor}
                borderRadius="md"
                aria-label="export inference"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="teal"
                borderRadius="md"
                isDisabled={exportButtonLoading}
                isLoading={exportButtonLoading}
                onClick={() => {
                  trackEvent(MixpanelEvents.clickPayAndExportButton)
                  handleExport(queryId)
                }}
                aria-label="export inference"
              >
                <span>Export</span>
              </Button>
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ExportInferenceModal
