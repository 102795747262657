import globalStyles from '@assets/styles/global.module.css'
import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

import { ChartModal } from '../chartModal/ChartModal'
import { CHART_SECTION_TOP_PADDING_IN_PRINT_IN_REM } from '../CleansetCharts.helpers'
import { ChartCardProps } from './ChartCard.types'
import { ChartCardContent } from './ChartCardContent'

export const ChartCard = (props: ChartCardProps) => {
  const {
    children,
    chartHeight = '280px',
    chartId,
    title,
    bottomLegendTitle,
    description,
    onHover,
    isOpen,
    onOpen,
    onClose,
  } = props
  const backgroundColor = useColorModeValue('neutral.50', 'neutralDarkMode.50')
  const borderColor = useColorModeValue('neutral.200', 'neutralDarkMode.200')

  return (
    <Flex
      as="section"
      position="relative"
      background={backgroundColor}
      data-chart-id={chartId}
      borderRadius="12px"
      onMouseEnter={onHover}
      flexDirection="column"
      padding="1rem"
      /* Defining border like this because string interpolation with the Chakra variables don't seem to work */
      borderWidth="1px"
      borderStyle="solid"
      borderColor={borderColor}
      boxShadow="0px 2px 3px 0px rgba(23, 25, 28, 0.04), 0px 1px 1px -1px rgba(23, 25, 28, 0.08)"
      sx={{
        '@media print': {
          padding: `${CHART_SECTION_TOP_PADDING_IN_PRINT_IN_REM}rem 0 0`,
          border: 'none',
          borderRadius: 0,
          boxShadow: 'none',
          background: 'none',
          '&:first-of-type': {
            paddingTop: `${CHART_SECTION_TOP_PADDING_IN_PRINT_IN_REM - 2}rem`,
          },
        },
      }}
    >
      <ChartCardContent
        bottomLegendTitle={bottomLegendTitle}
        chartHeight={chartHeight}
        children={children}
        description={description}
        title={title}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />

      <Box position="absolute" top="1rem" right="1rem" className={globalStyles.screenOnly}>
        <ChartModal
          bottomLegendTitle={bottomLegendTitle}
          children={children}
          description={description}
          title={title}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Box>
    </Flex>
  )
}
