export interface PresetFilterRowTooltipProps {
  filterType: string
  columnStatus: string
  children?: React.ReactNode
}

export const PresetFilterRowTooltipTitle: Record<string, string> = {
  total: 'All Examples',
  issues_resolved: 'Issues Resolved',
  best_examples: 'Well Labeled',
  label_issues: 'Label Issues',
  outlier: 'Outlier',
  unlabeled: 'Unlabeled',
  ambiguous: 'Ambiguous',
  is_near_duplicate: 'Near Duplicates',
  is_PII: 'PII',
  is_informal: 'Informal',
  is_non_english: 'Non English',
  is_toxic: 'Toxic',
  is_biased: 'Biased',
  is_dark: 'Dark',
  is_light: 'Light',
  is_blurry: 'Blurry',
  is_odd_size: 'Odd Sized',
  is_low_information: 'Low Information',
  is_grayscale: 'Grayscale',
  is_odd_aspect_ratio: 'Odd Aspect Ratio',
  is_nsfw: 'NSFW',
}
