import { Box } from '@chakra-ui/react'
import { AnyObject } from '@utils/types'
import React from 'react'

import { JSONDataType } from './FormatSuggestions.types'

export const PrettyPrintJson = (props: AnyObject) => {
  const { data } = props
  return (
    <Box minW="400px">
      <pre>{JSON.stringify(data, null, 2)} </pre>
    </Box>
  )
}

export const getColumnsAndRowsFromJson = (json: JSONDataType) => {
  const rows = json
  const columns = Object.keys(rows[0])
  return { columns, rows }
}
