import {
  addTagFilter,
  applySingleFilter,
  closeAllFilters,
  removeSingleFilter,
  removeTagFilter,
} from './FilterReducer.helpers'
import { CleansetFilterActions, FilterActionType, FilterState } from './FilterState.types'
import { initialFilterState } from './InitialFilterState'

export const filterReducer = (state: FilterState, action: FilterActionType): FilterState => {
  switch (action.type) {
    case CleansetFilterActions.CLEAR_ALL_FILTERS:
      return { ...state, ...initialFilterState(state.tasktype) }
    case CleansetFilterActions.CLOSE_ALL_FILTERS:
      return closeAllFilters(state)
    case CleansetFilterActions.TOGGLE_FILTER_OPEN: {
      const prevFilterData = state.filterData
      const closedFiltersState = closeAllFilters(state)
      return {
        ...closedFiltersState,
        filterData: prevFilterData.map((filter, i) =>
          filter.property === action.filterProperty
            ? { ...filter }
            : closedFiltersState.filterData[i]
        ),
      }
    }
    case CleansetFilterActions.TOGGLE_ADD_FILTER_OPEN: {
      const prevFilterState = state.addFilterMenuOpen
      return {
        ...closeAllFilters(state),
        addFilterMenuOpen: !prevFilterState,
      }
    }
    case CleansetFilterActions.APPLY_FILTER:
      return {
        ...state,
        addFilterMenuOpen: false,
        filterData: applySingleFilter(state.filterData, action.filterData),
      }
    case CleansetFilterActions.REMOVE_FILTER:
      return {
        ...state,
        addFilterMenuOpen: false,
        filterData: removeSingleFilter(state.filterData, action.filterProperty, state.tasktype),
      }
    case CleansetFilterActions.ADD_TAG_FILTER:
      return {
        ...state,
        addFilterMenuOpen: false,
        filterData: addTagFilter(state.filterData, action.filterData),
      }
    case CleansetFilterActions.REMOVE_TAG_FILTER:
      return {
        ...state,
        addFilterMenuOpen: false,
        filterData: removeTagFilter(state.filterData, action.filterData),
      }
    case CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS: {
      let prevFilters = initialFilterState(state.tasktype).filterData.slice()
      action.filterData.forEach((filter) => {
        prevFilters = applySingleFilter(prevFilters.slice(), filter)
      })
      return {
        ...state,
        filterData: prevFilters,
      }
    }
  }
}
