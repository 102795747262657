import { DEFAULT_STALE_TIME_MS } from '@services/common'
import {
  enrichmentJobPreviewQueryOptions,
  enrichmentJobsQueryFn,
  enrichmentJobStatusQueryFn,
  enrichmentProjectQueryFn,
  enrichmentProjectsQueryOptions,
} from '@services/enrichmentApi'
import { UseQueryOptionsPassThrough } from '@services/useQuery.helpers'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { useQuery } from 'react-query'
import { NormalizedEnrichmentJobPreviewData } from 'src/pages/enrichmentProject/enrichmentUtils'

import {
  EnrichmentJobDetailType,
  EnrichmentJobStatusRes,
  EnrichmentProjectRes,
  EnrichmentProjectsRes,
  queryKeys as enrichmentQueryKeys,
} from './constants'

export const useEnrichmentProject = (
  projectId: string,
  useQueryOptions?: UseQueryOptionsPassThrough<EnrichmentProjectRes>
) => {
  const { meta = {}, ...options } = useQueryOptions ?? {}
  const queryKey = enrichmentQueryKeys.enrichmentProjects.id(projectId)

  const query = useQuery<EnrichmentProjectRes>({
    queryKey,
    queryFn: () => enrichmentProjectQueryFn(projectId),
    staleTime: DEFAULT_STALE_TIME_MS,
    meta: { error: 'Failed to fetch enrichment project.', ...meta },
    ...options,
  })

  return query
}

export const useEnrichmentProjects = ({
  page = 1,
  useQueryOptions,
}: {
  page?: number
  useQueryOptions?: UseQueryOptionsPassThrough<EnrichmentProjectsRes>
}) => {
  const { meta = {}, enabled, ...options } = useQueryOptions ?? {}
  return useQuery<EnrichmentProjectsRes>({
    ...enrichmentProjectsQueryOptions({ page }),
    meta: { error: 'Failed to fetch enrichment Projects.', ...meta },
    enabled: checkFeatureFlag('ENRICHMENT_UI_ENABLED') && enabled,
    ...options,
  })
}

export const useEnrichmentJobs = (
  projectId: string,
  useQueryOptions?: UseQueryOptionsPassThrough<EnrichmentJobDetailType[]>
) => {
  const { meta = {}, enabled, ...options } = useQueryOptions ?? {}
  const queryKey = enrichmentQueryKeys.jobs.forProject(projectId)

  return useQuery<EnrichmentJobDetailType[]>({
    queryKey,
    queryFn: () => enrichmentJobsQueryFn(projectId),
    staleTime: DEFAULT_STALE_TIME_MS,
    meta: { error: `Failed to fetch enrichment jobs for project ${projectId}`, ...meta },
    ...options,
  })
}

export const useEnrichmentJobPreview = (
  jobId: string,
  newColumnName: string,
  useQueryOptions?: UseQueryOptionsPassThrough<NormalizedEnrichmentJobPreviewData[]>
) => {
  const { meta = {}, ...options } = useQueryOptions ?? {}

  const query = useQuery<NormalizedEnrichmentJobPreviewData[]>({
    ...enrichmentJobPreviewQueryOptions(jobId, newColumnName),
    meta: { error: 'Failed to fetch enrichment preview.', ...meta },
    ...options,
  })

  return query
}

export const useEnrichmentJobStatus = (
  jobId: string,
  queryOptions?: UseQueryOptionsPassThrough<EnrichmentJobStatusRes>
) => {
  const queryKey = enrichmentQueryKeys.jobStatus.id(jobId)

  return useQuery<EnrichmentJobStatusRes>({
    ...queryOptions,
    queryKey,
    queryFn: () => enrichmentJobStatusQueryFn(jobId),
    staleTime: DEFAULT_STALE_TIME_MS,
  })
}
