import { Button } from '@components/button/Button'
import { useId } from 'react'
import { Link } from 'react-router-dom'

export const WelcomeHeader = () => {
  const createLabelId = useId()
  return (
    <div className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4">
      <h1 className="type-display-100 text-text-strong">👋 Welcome to Cleanlab Studio</h1>
      <div className="flex items-center gap-6 rounded-3 bg-surface-1 px-6 py-5 outline outline-1 -outline-offset-[1px] outline-border-1">
        <div id={createLabelId} className="type-body-300-medium text-text-strong">
          Create your first Project
        </div>
        <Button
          aria-labelledby={createLabelId}
          size="small"
          className="min-w-content shrink-0"
          asChild
        >
          <Link to="/clean">Start here</Link>
        </Button>
      </div>
    </div>
  )
}
