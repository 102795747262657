import { useLocation } from 'react-router-dom'

export const useCleanlabLocation = () => {
  // Remove the leading slash character
  let path = useLocation()?.pathname.slice(1)

  // Interpret empty string as home
  path = path === '' ? 'home' : path

  // Capitalize first letter for aria
  return path[0].toUpperCase() + path.slice(1)
}
