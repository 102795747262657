import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys } from '@services/datasets/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/datasets`,
  withCredentials: true,
})

export const useUpdateDatasetNameMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (data: { id: string; name: string }) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.patch(`/${data.id}`, data, createAuthHeaders(accessToken))
    },
    onSuccess: (_, data) => {
      queryClient.invalidateQueries(queryKeys.datasets.id(data.id))
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to edit Dataset' })
    },
  })
}
