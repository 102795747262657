import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from '@common/layout/Layout'
import Loading from '@common/layout/loading/Loading'
import { useOAuth } from '@hooks/useOAuth'
import { AUTH_MODE_OAUTH } from '@utils/environmentVariables'
import React, { useEffect } from 'react'

const OnRedirectingComponent = () => (
  <Layout>
    <Loading />
  </Layout>
)

const OAuthAuthenticationGuard = ({ component }: { component: React.ComponentType }) => {
  // emulate Auth0's withAuthenticationRequired, for OAuth
  const { loginWithRedirect, isAuthenticated, isLoading } = useOAuth()
  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return
    }
    // matches Auth0's default returnTo, see auth0-react/src/with-authentication-required.tsx
    const returnTo = `${window.location.pathname}${window.location.search}`
    ;(async () => {
      await loginWithRedirect({ appState: { returnTo } })
    })()
  }, [isLoading, isAuthenticated, loginWithRedirect])

  if (isAuthenticated) {
    // can't use JSX because component is lowercase
    return React.createElement(component)
  } else {
    return <OnRedirectingComponent />
  }
}

export const AuthenticationGuard = ({ component }: { component: React.ComponentType }) => {
  if (AUTH_MODE_OAUTH) {
    return <OAuthAuthenticationGuard component={component} />
  } else {
    // https://community.auth0.com/t/implementing-auth0-protected-routes-using-react-router-v6/75667/14
    const Component = withAuthenticationRequired(component, {
      onRedirecting: OnRedirectingComponent,
    })
    return <Component />
  }
}
