import Layout from '@common/layout/Layout'
import { useAuth } from '@hooks/useAuth'
import ErrorMessage from '@providers/errors/ErrorMessage'
import * as Sentry from '@sentry/browser'
import { IS_PROD_ENV } from '@utils/environmentVariables'
import logger from '@utils/logger'
import { FallbackProps } from 'react-error-boundary'

const ErrorBoundaryFallback = ({ error }: FallbackProps) => {
  const { user } = useAuth()
  const userId = user ? user.sub : null
  logger.error('error boundary caught an irrecoverable error')
  logger.error('error message', error.message)
  if (IS_PROD_ENV) {
    Sentry.withScope(() => {
      const eventId = Sentry.captureException(error, {
        extra: { severity: 'App Crashed', userId: userId },
      })
      Sentry.showReportDialog({ eventId })
    })
  }
  return (
    <Layout requiresLogin={false}>
      <ErrorMessage error={error} />
    </Layout>
  )
}

export default ErrorBoundaryFallback
