import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { planTiers } from '@services/billing/constants'
import { useState } from 'react'

import { AddPlanModalProps } from './AddPlanModal.types'

const AddPlanModal = (props: AddPlanModalProps) => {
  const { isOpen, onClose, onSubmit } = props
  const [planName, setPlanName] = useState<string>('New Plan')
  const [pricePerMonth, setPricePerMonth] = useState<number>(10)
  const [cleansetExportRowsMonthlyLimit, setCleansetExportRowsMonthlyLimit] =
    useState<number>(100000)
  const [freeCleansetExportRowsMonthlyLimit, setFreeCleansetExportRowsMonthlyLimit] =
    useState<number>(50000)
  const [freeInferenceExportRowsMonthlyLimit, setFreeInferenceExportRowsMonthlyLimit] =
    useState<number>(50000)
  const [freeModelsMonthlyLimit, setFreeModelsMonthlyLimit] = useState<number>(50)
  const [freeProjectsMonthlyLimit, setFreeProjectsMonthlyLimit] = useState<number>(50)
  const [inferenceExportRowsMonthlyLimit, setInferenceExportRowsMonthlyLimit] =
    useState<number>(50000)
  const [pricePerRowCleansetExport, setPricePerRowCleansetExport] = useState<number>(0.01)
  const [pricePerRowInferenceExport, setPricePerRowInferenceExport] = useState<number>(0.01)
  const [pricePerYear, setPricePerYear] = useState<number>(50)
  const [freeTrialDatasetRowsLimit, setFreeTrialDatasetRowsLimit] = useState<number>(10000)
  const [freeTrialInferenceExportRowsLimit, setFreeTrialInferenceExportRowsLimit] =
    useState<number>(10000)
  const [freeTrialLengthDays, setFreeTrialLengthDays] = useState<number>(14)
  const [freeTrialModelsLimit, setFreeTrialModelsLimit] = useState<number>(25)
  const [freeTrialProjectsLimit, setFreeTrialProjectsLimit] = useState<number>(50)
  const [datasetRowsLimit, setDatasetRowsLimit] = useState<number>(100000)
  const [datasetSizeInBytesLimit, setDatasetSizeInBytesLimit] = useState<number>(161061273600)
  const [datasetNumColumnsLimit, setDatasetNumColumnsLimit] = useState<number>(1500)
  const [projectNumClassesLimit, setProjectNumClassesLimit] = useState<number>(1500)
  const [projectInstanceSizeLimit, setProjectInstanceSizeLimit] = useState<string>('EXTRA_LARGE')
  const [projectTrainingTimeLimitSeconds, setProjectTrainingTimeLimitSeconds] =
    useState<number>(259200)
  const [description, setDescription] = useState<string>('')
  const [tier, setTier] = useState<string>('individual')

  const inputBg = useColorModeValue('gray.200', 'gray.600')

  const requiredFormValues = [
    planName,
    pricePerMonth,
    cleansetExportRowsMonthlyLimit,
    freeCleansetExportRowsMonthlyLimit,
    freeInferenceExportRowsMonthlyLimit,
    freeModelsMonthlyLimit,
    freeProjectsMonthlyLimit,
    inferenceExportRowsMonthlyLimit,
    pricePerRowCleansetExport,
    pricePerRowInferenceExport,
    pricePerYear,
    freeTrialDatasetRowsLimit,
    freeTrialInferenceExportRowsLimit,
    freeTrialLengthDays,
    freeTrialModelsLimit,
    freeTrialProjectsLimit,
    datasetRowsLimit,
    datasetSizeInBytesLimit,
    datasetNumColumnsLimit,
    projectNumClassesLimit,
    projectInstanceSizeLimit,
    projectTrainingTimeLimitSeconds,
    tier,
  ]

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Billing Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="1.5rem">
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem>
                <FormControl isRequired isInvalid={planName === ''}>
                  <FormLabel>Plan Name</FormLabel>
                  <Input
                    defaultValue={planName}
                    onChange={(evt) => {
                      setPlanName(evt.target.value)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormLabel>Description</FormLabel>
                <Input
                  defaultValue={description}
                  onChange={(evt) => {
                    setDescription(evt.target.value)
                  }}
                />
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={pricePerMonth !== null && isNaN(pricePerMonth)}>
                  <FormLabel>Price Per Month ($)</FormLabel>
                  <Input
                    defaultValue={pricePerMonth}
                    type="number"
                    onChange={(evt) => {
                      setPricePerMonth(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={pricePerYear !== null && isNaN(pricePerYear)}>
                  <FormLabel>Price Per Year ($)</FormLabel>
                  <Input
                    defaultValue={pricePerYear}
                    type="number"
                    onChange={(evt) => {
                      setPricePerYear(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={datasetRowsLimit !== null && isNaN(datasetRowsLimit)}
                >
                  <FormLabel>Dataset Rows Limit</FormLabel>
                  <Input
                    defaultValue={datasetRowsLimit}
                    type="number"
                    onChange={(evt) => {
                      setDatasetRowsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={datasetSizeInBytesLimit !== null && isNaN(datasetSizeInBytesLimit)}
                >
                  <FormLabel>Dataset Size in Bytes Limit</FormLabel>
                  <Input
                    defaultValue={datasetSizeInBytesLimit}
                    type="number"
                    onChange={(evt) => {
                      setDatasetSizeInBytesLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={datasetNumColumnsLimit !== null && isNaN(datasetNumColumnsLimit)}
                >
                  <FormLabel>Dataset Number of Columns Limit</FormLabel>
                  <Input
                    defaultValue={datasetNumColumnsLimit}
                    type="number"
                    onChange={(evt) => {
                      setDatasetNumColumnsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={projectNumClassesLimit !== null && isNaN(projectNumClassesLimit)}
                >
                  <FormLabel>Project Number of Classes Limit</FormLabel>
                  <Input
                    defaultValue={projectNumClassesLimit}
                    type="number"
                    onChange={(evt) => {
                      setProjectNumClassesLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Project Instance Size Limit</FormLabel>
                  <Select
                    bg={inputBg}
                    defaultValue={projectInstanceSizeLimit}
                    onChange={(evt) => setProjectInstanceSizeLimit(evt.target.value)}
                  >
                    <option value="SMALL">SMALL</option>
                    <option value="LARGE">LARGE</option>
                    <option value="EXTRA_LARGE">EXTRA LARGE</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={
                    projectTrainingTimeLimitSeconds !== null &&
                    isNaN(projectTrainingTimeLimitSeconds)
                  }
                >
                  <FormLabel>Project Training Time Limit (seconds)</FormLabel>
                  <Input
                    defaultValue={projectTrainingTimeLimitSeconds}
                    type="number"
                    onChange={(evt) => {
                      setProjectTrainingTimeLimitSeconds(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={
                    cleansetExportRowsMonthlyLimit !== null && isNaN(cleansetExportRowsMonthlyLimit)
                  }
                >
                  <FormLabel>Cleanset Export Rows Monthly Limit</FormLabel>
                  <Input
                    defaultValue={cleansetExportRowsMonthlyLimit}
                    type="number"
                    onChange={(evt) => {
                      setCleansetExportRowsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={
                    inferenceExportRowsMonthlyLimit !== null &&
                    isNaN(inferenceExportRowsMonthlyLimit)
                  }
                >
                  <FormLabel>Inference Export Rows Monthly Limit</FormLabel>
                  <Input
                    defaultValue={inferenceExportRowsMonthlyLimit}
                    type="number"
                    onChange={(evt) => {
                      setInferenceExportRowsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={pricePerRowCleansetExport !== null && isNaN(pricePerRowCleansetExport)}
                >
                  <FormLabel>Price Per Row Cleanset Export Overage (cents)</FormLabel>
                  <Input
                    defaultValue={pricePerRowCleansetExport}
                    type="number"
                    onChange={(evt) => {
                      setPricePerRowCleansetExport(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={
                    pricePerRowInferenceExport !== null && isNaN(pricePerRowInferenceExport)
                  }
                >
                  <FormLabel>Price Per Row Inference Export Overage (cents)</FormLabel>
                  <Input
                    type="number"
                    defaultValue={pricePerRowInferenceExport}
                    onChange={(evt) => {
                      setPricePerRowInferenceExport(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={
                    freeCleansetExportRowsMonthlyLimit !== null &&
                    isNaN(freeCleansetExportRowsMonthlyLimit)
                  }
                >
                  <FormLabel>Free Cleanset Export Rows Monthly Limit</FormLabel>
                  <Input
                    defaultValue={freeCleansetExportRowsMonthlyLimit}
                    type="number"
                    onChange={(evt) => {
                      setFreeCleansetExportRowsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={
                    freeInferenceExportRowsMonthlyLimit !== null &&
                    isNaN(freeInferenceExportRowsMonthlyLimit)
                  }
                >
                  <FormLabel>Free Inference Export Rows Monthly Limit</FormLabel>
                  <Input
                    defaultValue={freeInferenceExportRowsMonthlyLimit}
                    type="number"
                    onChange={(evt) => {
                      setFreeInferenceExportRowsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={freeModelsMonthlyLimit !== null && isNaN(freeModelsMonthlyLimit)}
                >
                  <FormLabel>Free Models Monthly Limit</FormLabel>
                  <Input
                    defaultValue={freeModelsMonthlyLimit}
                    type="number"
                    onChange={(evt) => {
                      setFreeModelsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={freeProjectsMonthlyLimit !== null && isNaN(freeProjectsMonthlyLimit)}
                >
                  <FormLabel>Free Projects Monthly Limit</FormLabel>
                  <Input
                    defaultValue={freeProjectsMonthlyLimit}
                    type="number"
                    onChange={(evt) => {
                      setFreeProjectsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={freeTrialDatasetRowsLimit !== null && isNaN(freeTrialDatasetRowsLimit)}
                >
                  <FormLabel>Free Trial Dataset Rows Limit</FormLabel>
                  <Input
                    defaultValue={freeTrialDatasetRowsLimit}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialDatasetRowsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={
                    freeTrialInferenceExportRowsLimit !== null &&
                    isNaN(freeTrialInferenceExportRowsLimit)
                  }
                >
                  <FormLabel>Free Trial Inference Export Rows Limit</FormLabel>
                  <Input
                    defaultValue={freeTrialInferenceExportRowsLimit}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialInferenceExportRowsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={freeTrialLengthDays !== null && isNaN(freeTrialLengthDays)}
                >
                  <FormLabel>Free Trial Length Days</FormLabel>
                  <Input
                    defaultValue={freeTrialLengthDays}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialLengthDays(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={freeTrialModelsLimit !== null && isNaN(freeTrialModelsLimit)}
                >
                  <FormLabel>Free Trial Models Limit</FormLabel>
                  <Input
                    defaultValue={freeTrialModelsLimit}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialModelsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={freeTrialProjectsLimit !== null && isNaN(freeTrialProjectsLimit)}
                >
                  <FormLabel>Free Trial Projects Limit</FormLabel>
                  <Input
                    defaultValue={freeTrialProjectsLimit}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialProjectsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Tier</FormLabel>
                  <Select
                    bg={inputBg}
                    defaultValue={tier}
                    onChange={(evt) => setTier(evt.target.value)}
                  >
                    {planTiers.map((plan) => (
                      <option key={plan} value={plan}>
                        {plan}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </ModalBody>
        <ModalFooter mt={3}>
          <Button
            colorScheme="teal"
            mr={3}
            isDisabled={requiredFormValues.includes('') || requiredFormValues.includes(NaN)}
            onClick={() => {
              onSubmit({
                cleanset_export_rows_monthly_limit: cleansetExportRowsMonthlyLimit ?? 0,
                free_cleanset_export_rows_monthly_limit: freeCleansetExportRowsMonthlyLimit ?? 0,
                free_inference_export_rows_monthly_limit: freeInferenceExportRowsMonthlyLimit ?? 0,
                free_models_monthly_limit: freeModelsMonthlyLimit ?? 0,
                free_projects_monthly_limit: freeProjectsMonthlyLimit ?? 0,
                inference_export_rows_monthly_limit: inferenceExportRowsMonthlyLimit ?? 0,
                price_per_month: pricePerMonth ?? 0,
                price_per_year: pricePerYear ?? 0,
                price_per_row_cleanset_export: pricePerRowCleansetExport ?? 0,
                price_per_row_inference_export: pricePerRowInferenceExport ?? 0,
                free_trial_dataset_rows_limit: freeTrialDatasetRowsLimit ?? 0,
                free_trial_length_days: freeTrialLengthDays ?? 0,
                free_trial_models_limit: freeTrialModelsLimit ?? 0,
                free_trial_projects_limit: freeTrialProjectsLimit ?? 0,
                free_trial_inference_export_rows_limit: freeTrialInferenceExportRowsLimit ?? 0,
                dataset_rows_limit: datasetRowsLimit ?? 0,
                dataset_size_limit_bytes: datasetSizeInBytesLimit ?? 0,
                dataset_num_columns_limit: datasetNumColumnsLimit ?? 0,
                project_num_classes_limit: projectNumClassesLimit ?? 0,
                project_instance_size_limit: projectInstanceSizeLimit ?? '',
                project_training_time_limit: projectTrainingTimeLimitSeconds ?? 0,
                plan_name: planName ?? '',
                tier: tier ?? '',
                description: description ?? '',
                id: '',
              })
              onClose()
            }}
          >
            Create
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AddPlanModal
