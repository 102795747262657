export const queryKeys = {
  cleanset: {
    all: () => ['cleansets'],
    id: (cleansetId: string) => ({
      all: () => [...queryKeys.cleanset.all(), cleansetId],
      details: () => [...queryKeys.cleanset.id(cleansetId).all(), 'details'],
      projectDetails: () => [...queryKeys.cleanset.id(cleansetId).all(), 'projectDetails'],
      modelSummary: () => [...queryKeys.cleanset.id(cleansetId).all(), 'modelSummary'],
      modelPerformance: () => [...queryKeys.cleanset.id(cleansetId).all(), 'modelPerformance'],
      byClassPerformance: () => [...queryKeys.cleanset.id(cleansetId).all(), 'byClassPerformance'],
      featureImportance: () => [...queryKeys.cleanset.id(cleansetId).all(), 'featureImportance'],
    }),
    projectId: (projectId: string) => [...queryKeys.cleanset.all(), 'project', projectId],
    datasetInUse: (datasetId: string) => [...queryKeys.cleanset.all(), 'datasetInUse', datasetId],
  },
}

export interface CleansetRowRes {
  id: string
  project_id: string
  dataset_name: string
  version: number
  num_total: number
  num_resolved: number
  num_issues_resolved: number
  num_issues: number
  progress_description: string
  is_ready: boolean
  has_error: boolean
  current_training_stage: number
  is_not_viewable_in_ui: boolean
  created_at: number
  required_columns_ready: boolean
}

export type FeatureImportanceData = [string, number | string, number | string][]
