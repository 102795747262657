import { useCallback, useEffect } from 'react'

export const useKeyPress = ({
  callback,
  keys,
  enabled = true,
  stopPropagation = true,
  preventDefault = true,
}: {
  callback: VoidFunction
  keys: string[]
  enabled?: boolean
  stopPropagation?: boolean
  preventDefault?: boolean
}) => {
  const handler = useCallback(
    (event: KeyboardEvent) => {
      if (!enabled) return
      if (keys.includes(event.key)) {
        if (stopPropagation) event.stopPropagation()
        if (preventDefault) event.preventDefault()
        callback()
      }
    },
    [callback, enabled, keys, preventDefault, stopPropagation]
  )
  useEffect(() => {
    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [handler])
}
