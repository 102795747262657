'use client'

import { MeterIndicatorRoot, MeterRoot } from '@components/meter/Meter'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cn } from '@utils/tailwindUtils'
import { type ComponentPropsWithoutRef, type ComponentPropsWithRef, forwardRef, memo } from 'react'

type ProgressProps = ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>

export const getUsageBgClass = (progress: number) => {
  if (progress < 0.25) {
    return 'bg-cyan-500'
  } else if (progress < 0.5) {
    return 'bg-green-500'
  } else if (progress < 0.75) {
    return 'bg-yellow-500'
  } else if (progress < 1) {
    return 'bg-orange-500'
  }
  return 'bg-red-500'
}

const IndicatorUsage = ({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof MeterIndicatorRoot>) => {
  const colorClass = getUsageBgClass(props.ratio)

  return <MeterIndicatorRoot className={cn(colorClass, className)} {...props} />
}

const MeterUsageBase = (
  { className, value, max = 100, ...props }: ProgressProps,
  ref: ComponentPropsWithRef<typeof ProgressPrimitive.Root>['ref']
) => (
  <MeterRoot
    ref={ref}
    className={cn('relative h-3 w-full overflow-hidden rounded-full bg-surface-2-hover', className)}
    role="meter"
    {...props}
  >
    <IndicatorUsage ratio={(value ?? 0) / max} />
  </MeterRoot>
)

export const MeterUsage = memo(forwardRef(MeterUsageBase))
MeterUsage.displayName = ProgressPrimitive.Root.displayName
