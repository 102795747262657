import './UploadDropzone.min.css'

import UserQuotaAlert from '@common/alerts/userQuotaAlert/UserQuotaAlert'
import { UserQuotaAlertInfo } from '@common/alerts/userQuotaAlert/UserQuotaAlert.types'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import uploadApiService from '@services/upload/v1/uploadApi'
import { FilePondErrorDescription } from 'filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'

import testIds from '../../playwright/test_ids.json'
import { UploadDropzoneV3Props } from './DatasetUploadDropzone.types'

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)

const UploadDropzone = (props: UploadDropzoneV3Props) => {
  const { onUpdateFiles, onProcessFile, onRemoveFile, timeout, maxFileSize, setUploadId } = props
  const [quotaAlert, setQuotaAlert] = useState<UserQuotaAlertInfo | null>(null)
  const { trackEvent } = useEventTracking()
  const acceptedFileTypes = [
    'application/zip',
    'application/x-zip-compressed', // MIME type of zip files uploaded on Windows
    'text/csv',
    'application/json',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.jsonl',
    // '.parquet',
  ]

  return (
    <div
      className="upload-tour-dropzone w-full"
      data-testid={testIds.datasetUploadPageDropzoneContainer}
    >
      <div onClick={() => trackEvent(MixpanelEvents.clickBrowse)}>
        <FilePond
          allowFileSizeValidation
          maxFileSize={maxFileSize || '100MB'} // NOSONAR
          allowFileTypeValidation
          onaddfilestart={(file) =>
            trackEvent(MixpanelEvents.addFilepondFile, {
              fileType: file.fileExtension,
              fileSize: file.fileSize,
              fileName: file.filename,
            })
          }
          onupdatefiles={onUpdateFiles}
          onprocessfile={onProcessFile}
          onremovefile={onRemoveFile}
          allowMultiple={false}
          maxFiles={1}
          acceptedFileTypes={acceptedFileTypes}
          fileValidateTypeDetectType={(source: File, type: string) => {
            return new Promise((resolve) => {
              if (source.name.endsWith('.parquet')) {
                resolve('.parquet')
              }
              if (source.name.endsWith('.jsonl')) {
                resolve('application/json')
              }
              resolve(type)
            })
          }}
          server={{
            // https://pqina.nl/filepond/docs/api/server/
            process: async (fieldName, file, metadata, load, error, progress, abort) => {
              const initializeUploadResponse = await uploadApiService.initializeUpload(file, error)
              if (initializeUploadResponse === undefined) {
                // Error occurred during initialization. Stop process here
                return
              }
              const {
                upload_id: uploadId,
                part_sizes: partSizes,
                presigned_posts: presignedPosts,
              } = initializeUploadResponse
              const uploadParts = await uploadApiService.uploadDatasetFileChunks(
                file,
                partSizes,
                presignedPosts,
                (progressAmount, totalAmount) => {
                  progress(true, progressAmount, totalAmount)
                },
                error
              )
              await uploadApiService.completeFileUpload(uploadId, uploadParts)
              setUploadId(uploadId)
              load(uploadId)
              return {
                abort: () => {
                  abort()
                },
              }
            },
            revert: (uniqueFieldId, load) => {
              load()
            },
            timeout: timeout,
          }}
          onerror={(response: FilePondErrorDescription) => {
            if (response.code === 429) {
              setQuotaAlert({
                code: 'user_quota_exceeded',
              })
            }
          }}
          labelFileProcessingError={(error) => {
            const serverErrorResponse = error?.response?.data?.error ?? 'Error during upload'
            return serverErrorResponse
          }}
          name="files"
          labelIdle='<div class="type-body-200 text-text-strong">Drag and drop your files or <span class="cursor-pointer text-blue-600 underline">browse</span>'
        />
        <UserQuotaAlert info={quotaAlert} setInfo={setQuotaAlert} />
      </div>
    </div>
  )
}

export default UploadDropzone
