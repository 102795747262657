import Accordion from '@components/accordion/Accordion'
import React from 'react'

import { DropdownHeaderProps } from './DropdownHeader.types'

const DropdownHeader = (props: DropdownHeaderProps) => {
  const { title, children, defaultClose, tooltipLabel } = props
  return (
    <Accordion
      title={title}
      tooltipLabel={tooltipLabel}
      variant="dropdownHeader"
      defaultClose={defaultClose}
    >
      {children}
    </Accordion>
  )
}

export default DropdownHeader
