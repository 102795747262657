import { Container, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react'
import CodeBlock from '@common/misc/codeBlock/CodeBlock'
import React from 'react'

import PlanGrid from './planGrid/PlanGrid'
import SubscriptionGrid from './subscriptionGrid/SubscriptionGrid'

const Billing = () => {
  return (
    <Container maxWidth="100%">
      <VStack spacing={4} align="left" width="100%" fontSize="md">
        <Text>
          <strong>Plans</strong> - Supports different tiers, e.g. individual and enterprise. We can
          leverage tiers to add/restrict features to different Studio users. If you want to create a
          new billing plan configuration that a user can be added to, click{' '}
          <CodeBlock text="Add Plan" />. You can edit existing plan configurations by clicking{' '}
          <CodeBlock text="Edit Plan" />. Note: this will only affect new users added to this plan.
          Updates to individual user’s billing plans can be done in the Subscriptions tab.
        </Text>
        <Text>
          <strong>Subscriptions</strong> - Every Studio user will have one subscription that’s tied
          to a billing plan. New and existing users will automatically be subscribed to the
          enterprise, contact-us plan. If you need to edit an existing subscription, click{' '}
          <CodeBlock text="Edit Subscription" />.
        </Text>
        <br />
      </VStack>
      <Tabs>
        <TabList>
          <Tab>Plans</Tab>
          <Tab>Subscriptions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <PlanGrid />
          </TabPanel>
          <TabPanel>
            <SubscriptionGrid />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  )
}

export default Billing
