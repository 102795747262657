import { FormErrorMessage } from '@chakra-ui/react'
import UserQuotaAlert from '@common/alerts/userQuotaAlert/UserQuotaAlert'
import { UserQuotaAlertInfo } from '@common/alerts/userQuotaAlert/UserQuotaAlert.types'
import { Button } from '@components/button/Button'
import { FormFieldProvider } from '@components/formField/FormFieldContext'
import { LabelRadioCard } from '@components/radioGroup/LabelRadioCard'
import { RadioGroup, RadioGroupItem } from '@components/radioGroup/RadioGroup'
import { Tooltip } from '@components/tooltip/Tooltip'
import { showQuotaWarning, SOFT_QUOTA_STATUS } from '@providers/errors/ErrorToast'
import { useCreateEnrichmentProjectMutation } from '@services/enrichment/mutations'
import { queryKeys } from '@services/userQuota/constants'
import { FormApi, useForm } from '@tanstack/react-form'
import { FormEventHandler, useCallback, useState } from 'react'
import { Link, LoaderFunction, redirect, useNavigate, useParams } from 'react-router-dom'
import { CREATE_PROJECT_ROUTES } from 'src/createProjectRoutes'
import { queryClient } from 'src/queryClient'
import { toast } from 'src/ToastContainer'

import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { useDatasetDetailsOutletContext } from './DatasetDetailsOutlet'
import {
  Modality,
  ProjectType,
  projectTypeChoices,
} from './projectFormFields/ProjectFormFields.types'
import {
  PROJECT_WIZARD_STEPS,
  ProjectWizardStep,
  useProjectWizardStore,
} from './projectWizard/ProjectWizard.store'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionWelcome,
} from './Sidebar'

type FormValues = { projectType: ProjectType }

type SelectProjectTypeForm = FormApi<FormValues>

const THIS_STEP = 'selectProjectType' as const satisfies ProjectWizardStep

export const createProjectSelectProjectTypesLoader: LoaderFunction = async ({ params }) => {
  const { stepMayProceed, projectName } = useProjectWizardStore.getState()

  const { datasetId } = params
  if (!datasetId) {
    return redirect('/clean')
  }
  if (!stepMayProceed(THIS_STEP) || !projectName) {
    return redirect('/clean/dataset')
  }
  return null
}

const ProjectTypeRadioSection = ({
  form,
  projectModality,
}: {
  form: SelectProjectTypeForm
  projectModality: Modality
}) => {
  return (
    <form.Field name="projectType">
      {({ state: { value, meta }, name, handleBlur, handleChange }) => {
        return (
          <FormFieldProvider error={meta.errors?.[0]}>
            <RadioGroup
              name={name}
              value={value}
              onValueChange={(value) => handleChange(value as ProjectType)}
              onBlur={handleBlur}
              className="grid grid-cols-2 gap-8"
            >
              {Object.entries(projectTypeChoices).map(([projectType, details]) => {
                const {
                  dataTestId,
                  description,
                  disabledText,
                  name,
                  isDisabled: isDisabledForModalityFn,
                } = details
                const isDisabled = isDisabledForModalityFn?.(projectModality)

                const content = (
                  <LabelRadioCard
                    key={projectType}
                    heading={name}
                    description={description}
                    data-testid={dataTestId}
                    disabled={isDisabled}
                  >
                    <RadioGroupItem value={projectType} />
                  </LabelRadioCard>
                )

                if (isDisabled) {
                  return (
                    <Tooltip key={projectType} content={disabledText}>
                      {content}
                    </Tooltip>
                  )
                }

                return content
              })}
            </RadioGroup>
            {meta.errors.map((error) => {
              return <FormErrorMessage key={error?.toString()}>{error}</FormErrorMessage>
            })}
          </FormFieldProvider>
        )
      }}
    </form.Field>
  )
}

const Form = () => {
  const navigate = useNavigate()
  const { datasetId, datasetDetails } = useDatasetDetailsOutletContext()
  const projectName = useProjectWizardStore((state) => state.projectName)
  const setValuesForStep = useProjectWizardStore((state) => state.setValuesForStep)
  const resetStateAfter = useProjectWizardStore((state) => state.resetStateAfter)
  const resetWizardState = useProjectWizardStore((state) => state.resetState)
  const [quotaAlert, setQuotaAlert] = useState<UserQuotaAlertInfo | null>(null)

  const initialProjectType =
    useProjectWizardStore((state) => state.projectType) ?? ProjectType.CURATION

  const { mutate: createEnrichmentProject, isLoading: createProjectIsLoading } =
    useCreateEnrichmentProjectMutation({
      onSuccess: (res) => {
        const { id, status } = res.data ?? {}

        if (status === SOFT_QUOTA_STATUS) {
          showQuotaWarning(toast, res.data.description)
        }

        navigate(`/projects/enrich/${id}`)
        setTimeout(() => {
          // Reset the wizard state after a delay to prevent the wizard from resetting before navigation is complete
          resetWizardState()
        }, 100)

        queryClient.invalidateQueries({ queryKey: queryKeys.quotas.self() })
      },
      onError: (error) => {
        if (error.response?.status === 429) {
          setQuotaAlert({
            // @ts-ignore
            code: error.response.data.code,
          })
        }
      },
    })

  const form = useForm<FormValues>({
    defaultValues: {
      projectType: initialProjectType,
    },
    onSubmit: ({ value }) => {
      const { projectType } = value

      if (!projectType || !projectName || !datasetId) {
        return
      }
      setValuesForStep(THIS_STEP, {
        projectType: value.projectType,
      })
      resetStateAfter('selectProjectType')

      if (projectType === ProjectType.CURATION) {
        navigate(`/clean/${datasetId}/${CREATE_PROJECT_ROUTES.TYPE}`)
      } else {
        createEnrichmentProject({
          name: projectName,
          datasetId,
        })
      }
    },
  })
  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      form.handleSubmit()
    },
    [form]
  )
  const formState = form.useStore((s) => ({
    canSubmit: s.canSubmit,
  }))
  const projectTypeFromForm = form.useStore((state) => state.values.projectType)
  const projectWizardActionsProps = {
    step: THIS_STEP,
    stepCount: projectTypeFromForm === ProjectType.ENRICHMENT ? 2 : PROJECT_WIZARD_STEPS.length,
  }

  return (
    <>
      <UserQuotaAlert info={quotaAlert} setInfo={setQuotaAlert} />
      <form className="flex flex-col gap-8" onSubmit={onSubmit}>
        <div className="flex flex-col">
          <CreateProjectContent>
            <HeadingWrapper>
              <PageHeading>Select a Project type</PageHeading>
            </HeadingWrapper>
            <div>
              <ul className="type-body-100 flex flex-wrap gap-3 pb-6 text-text-primary">
                <li>
                  <b className="type-body-100-medium">Dataset:</b> {datasetDetails.name}
                </li>
                <li className="text-text-primary">
                  <b className="type-body-100-medium">Project name:</b> {projectName}
                </li>
              </ul>
            </div>
          </CreateProjectContent>
          <CreateProjectContent>
            <HeadingWrapper>
              <PageDescription>
                Choose a project type depending on the actions you would like to perform on your
                dataset.
              </PageDescription>
            </HeadingWrapper>
            <ProjectTypeRadioSection form={form} projectModality={datasetDetails?.modality} />
          </CreateProjectContent>
        </div>
        <ProjectWizardActions {...projectWizardActionsProps}>
          <Button
            variant="highContrast"
            size="medium"
            type="submit"
            disabled={createProjectIsLoading || !formState.canSubmit}
          >
            {projectTypeFromForm === ProjectType.CURATION ? 'Next' : 'Create Project'}
          </Button>
        </ProjectWizardActions>
      </form>
    </>
  )
}

export const CreateProjectSelectProjectTypes = () => {
  const startPoint = useProjectWizardStore((state) => state.startPoint)
  const datasetId = useParams().datasetId
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          {startPoint === 'datasetId' ? (
            <Link to={`/clean/${datasetId}`}>Name your Project</Link>
          ) : (
            <Link to="/clean/dataset">Select an existing Dataset</Link>
          )}
        </PreviousLink>
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <Form />
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcome />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/">Cleanlab Studio docs</ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/data_labeling/">
              Data labeling in Cleanlab Studio
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/multilabel/">
              Multi-label data quality
            </ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}
