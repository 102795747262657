'use client'

import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cn } from '@utils/tailwindUtils'
import { type ComponentPropsWithoutRef, type ComponentPropsWithRef, forwardRef, memo } from 'react'

const METER_VARIANTS = ['default', 'neutral', 'completion'] as const
type MeterVariant = (typeof METER_VARIANTS)[number]
type ProgressProps = ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>

export const MeterIndicatorRoot = ({ ratio, className }: { ratio: number; className?: string }) => {
  return (
    <ProgressPrimitive.Indicator
      className={cn('h-full w-full flex-1 bg-cyan-500 transition-all duration-200', className)}
      style={{ transform: `translateX(-${Math.min(100, Math.max(0, 100 * (1 - ratio)))}%)` }}
    />
  )
}

const MeterIndicator = ({
  ratio,
  className,
  variant,
}: {
  ratio: number
  className?: string
  variant: MeterVariant
}) => {
  const variantClasses = (() => {
    switch (variant) {
      case 'neutral':
        return 'bg-neutral-500'
      case 'completion':
        return ratio < 1 ? 'bg-cyan-500' : 'bg-green-500'
      case 'default':
      default:
        return 'bg-cyan-500'
    }
  })()
  return <MeterIndicatorRoot className={cn(variantClasses, className)} ratio={ratio} />
}

export const MeterRoot = forwardRef(
  (
    { className, ...props }: ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>,
    ref: ComponentPropsWithRef<typeof ProgressPrimitive.Root>['ref']
  ) => {
    return (
      <ProgressPrimitive.Root
        ref={ref}
        className={cn(
          'relative h-3 w-full overflow-hidden rounded-full bg-surface-2-hover',
          className
        )}
        role="meter"
        {...props}
      />
    )
  }
)

const MeterBase = (
  { value, max = 100, variant = 'default', ...props }: ProgressProps & { variant: MeterVariant },
  ref: ComponentPropsWithRef<typeof ProgressPrimitive.Root>['ref']
) => (
  <MeterRoot ref={ref} role="meter" value={value} max={max} {...props}>
    <MeterIndicator ratio={(value ?? 0) / max} variant={variant} />
  </MeterRoot>
)

export const Meter = memo(forwardRef(MeterBase))
Meter.displayName = 'Meter'
