import { EnabledCleanlabColumnConfigs } from '../datasheet/columnConfigs/ColumnConfigs'
import { DatasheetColumnType } from '../datasheet/columnConfigs/ColumnConfigs.types'
import { PresetHeaders } from '../filterReducer/FilterState.types'

export const getHeaderName = (property: string, isGivenLabel: boolean) => {
  if (Object.keys(EnabledCleanlabColumnConfigs).includes(property)) {
    return EnabledCleanlabColumnConfigs[property].headerName
  } else if (isGivenLabel) {
    return PresetHeaders.Given
  } else {
    return property
  }
}

export const shouldUseBooleanFilterOptions = (property: string) => {
  return (
    Object.keys(EnabledCleanlabColumnConfigs).includes(property) &&
    EnabledCleanlabColumnConfigs[property].columnType === DatasheetColumnType.ISSUE
  )
}
