import { GridOptions } from 'ag-grid-community'

import { FilterData } from '../../filterReducer/FilterState.types'

export interface FilterButtonProps {
  gridOptions: GridOptions
  filterData: FilterData
  cleansetId: string
  isMultilabel: boolean
  labelsIndexed: Record<string, number> | null
  firstGridDataRendered: boolean
  resetResolverAndRowSelection: () => void
}

export enum FilterSymbol {
  EQUALS = '',
  NOT_EQUAL = '≠',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '≤',
  GREATER_THAN_OR_EQUAL = '≥',
  GREATER_THAN = '>',
  CONTAINS = 'Contains',
  NOT_CONTAINS = 'Not contains',
  STARTS_WITH = 'Starts with',
  ENDS_WITH = 'Ends with',
  SET = '',
  ALL = 'All',
}
