import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys } from '@services/signupSettings/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/signup_settings`,
  withCredentials: true,
})

export const useEditSignupSettings = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async ({
      account_activation_enabled,
      allowed_activations_per_day,
    }: {
      account_activation_enabled: boolean
      allowed_activations_per_day: number
    }) => {
      const accessToken = await auth.getTokenSilently()
      const body = { account_activation_enabled, allowed_activations_per_day }
      await axiosClient.patch('/', body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.signupSettings.all() })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}
