import { Button } from '@components/button/Button'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { ReactNode } from 'react'
import { Link, Outlet } from 'react-router-dom'

import { InlineLink } from '../Sidebar'
import { ProjectWizardModalAlert } from './ProjectWizardModalAlert'

export type BlockProjectsMessage = { heading: ReactNode; description: ReactNode }

const ContactSales = () => <InlineLink to="https://cleanlab.ai/sales/">contact sales</InlineLink>

export const ProjectWizardRootLayout = () => {
  const {
    isLoading: subscriptionIsLoading,
    freeTrialPeriod,
    payingCustomer,
    numProjectsAvailable,
  } = useSubscription()

  let blockMessage: BlockProjectsMessage | undefined
  if (!subscriptionIsLoading) {
    if (!freeTrialPeriod && !payingCustomer) {
      blockMessage = {
        heading: 'Your free trial has expired',
        description: (
          <>
            Please <ContactSales /> to extend your trial period.
          </>
        ),
      }
    } else if (numProjectsAvailable <= 0) {
      blockMessage = {
        heading: 'You are out of Projects',
        description: (
          <>
            To create a new Project please delete an older Project or <ContactSales /> to add more
            Projects to your account.
          </>
        ),
      }
    }
  }

  return (
    <>
      {blockMessage && (
        <ProjectWizardModalAlert
          isOpen={!!blockMessage}
          message={blockMessage}
          actions={
            <Button variant="highContrast" asChild>
              <Link to="/">Go to dashboard</Link>
            </Button>
          }
        />
      )}
      <Outlet />
    </>
  )
}
