import { Button, HStack, Tag, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { KeyPress } from '@common/misc/keyPress/KeyPress'
import { useSetDatasheetRow } from '@services/datasheet/mutations'
import { useCallback, useContext, useEffect } from 'react'
import { BiUndo } from 'react-icons/bi'

import { CleansetContext } from '../../CleansetContext'
import { ActionType } from '../../commandpalette/CommandPalette.types'
import { NUM_ROWS_PER_PAGE } from '../Datasheet.types'
import { getActionTag } from './LastActionWidget.helpers'
import { LastActionWidgetProps } from './LastActionWidget.types'

const LastActionWidget = ({
  lastActionState,
  setLastActionState,
  gridApi,
  isMultiLabel,
  currPageNum,
}: LastActionWidgetProps) => {
  const bgColor = useColorModeValue(undefined, 'gray.800')
  const { rowId, rowIdx, type, previousState, undone } = lastActionState
  const { cleansetId } = useContext(CleansetContext)

  const { mutate: setDatasheetRow, isLoading: isSetDatasheetRowLoading } = useSetDatasheetRow({
    gridApi,
    onSuccess: () => setLastActionState((state) => (state ? { ...state, undone: true } : null)),
  })
  const isMacOS = navigator.userAgent.toLowerCase().includes('mac')

  const onUndo = useCallback(() => {
    setDatasheetRow({ rowId, cleansetId, previousState })

    const previousRowNode = gridApi.getRowNode(String(rowId))
    const previousRowIndex = gridApi.getRowNode(String(rowId))?.rowIndex
    const pageNumOfPreviousRow =
      typeof previousRowIndex === 'number'
        ? Math.floor(previousRowIndex! / NUM_ROWS_PER_PAGE)
        : null

    if (pageNumOfPreviousRow === currPageNum) {
      // select the previous row and unselect everything else
      previousRowNode?.setSelected(true, true)
    }
  }, [cleansetId, currPageNum, gridApi, previousState, rowId, setDatasheetRow])

  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      if ((isMacOS ? evt.metaKey : evt.ctrlKey) && evt.key === 'z') {
        evt.preventDefault()
        onUndo()
      }
    },
    [onUndo, isMacOS]
  )

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <VStack
      className="border border-border-0 shadow-elev-1"
      borderRadius="md"
      p={2}
      fontSize="sm"
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={1}
      bg={bgColor}
      w="320px"
      h="calc(100% - 8px)"
    >
      <HStack>
        <Text fontWeight={600}>Last Action: </Text>
        <Text noOfLines={1} overflow="visible" whiteSpace="nowrap">
          {getActionTag(
            type.includes(ActionType.REMOVE_TAG_LABEL) ? ActionType.REMOVE_TAG_LABEL : type,
            undone
          )}
        </Text>
      </HStack>
      <HStack w="full" direction="row" justify="space-between">
        <Text>Row {rowIdx}</Text>
        {!undone && !isMultiLabel && (
          <Button
            variant="ghost"
            size="sm"
            px={1}
            leftIcon={<BiUndo />}
            aria-label="undo this action"
            onClick={onUndo}
            isLoading={isSetDatasheetRowLoading}
          >
            <KeyPress>{isMacOS ? 'cmd' : 'ctrl'} + z</KeyPress>
            <Text pl={1.5}>Undo</Text>
          </Button>
        )}
        {undone && (
          <Tag px={2} py={1} my={1}>
            Reverted
          </Tag>
        )}
      </HStack>
    </VStack>
  )
}

export default LastActionWidget
