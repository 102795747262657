import { CLEANLAB_FRONTEND_COLUMN } from '../../datasheet/Datasheet.types'
import { RowData } from '../../editInterface/EditInterface.types'
import { METADATA_TYPE } from '../../filterSidebar/FilterSidebar.types'
import { ColumnInfo, METADATA_TYPE_DISPLAY } from './MetadataDisplay.types'

export const constructAnalysesItems = (
  columns: string[],
  selectedRowData: RowData
): ColumnInfo[] => {
  const metadataAndIssues = columns

  return metadataAndIssues.map((column: string) => {
    return {
      title: {
        title: COLUMN_TITLES[column as CLEANLAB_FRONTEND_COLUMN],
        value:
          selectedRowData[column.includes('_cleanlab_') ? column : '_cleanlab_'.concat(column)],
      },
      score: selectedRowData[COLUMN_SCORE_NAMES[column]] ?? 0,
      additionalInfo:
        (RELATED_COLUMN_INFO[column] ?? []).map((col: CLEANLAB_FRONTEND_COLUMN) => {
          return {
            field: RELATED_INFO_COLUMN_DISPLAY_NAMES[col] ?? '',
            value: selectedRowData[col],
          }
        }) ?? [],
    } as ColumnInfo
  })
}

export const COLUMN_SCORE_NAMES: Record<string, string> = {
  [CLEANLAB_FRONTEND_COLUMN.ISSUE]: CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE,
  [CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS]: '_cleanlab_ambiguous_score', // hosted lint-and-test doesn't recognize the enum for some reason
  [CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE]: CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE_SCORE,
  [CLEANLAB_FRONTEND_COLUMN.OUTLIER]: CLEANLAB_FRONTEND_COLUMN.OUTLIER_SCORE,
  [METADATA_TYPE.IS_BIASED]: CLEANLAB_FRONTEND_COLUMN.BIAS_SCORE,
  [METADATA_TYPE.IS_BLURRY]: CLEANLAB_FRONTEND_COLUMN.BLURRY_SCORE,
  [METADATA_TYPE.IS_DARK]: CLEANLAB_FRONTEND_COLUMN.DARK_SCORE,
  [METADATA_TYPE.IS_INFORMAL]: CLEANLAB_FRONTEND_COLUMN.INFORMAL_SCORE,
  [METADATA_TYPE.IS_LIGHT]: CLEANLAB_FRONTEND_COLUMN.LIGHT_SCORE,
  [METADATA_TYPE.IS_LOW_INFORMATION]: CLEANLAB_FRONTEND_COLUMN.LOW_INFORMATION_SCORE,
  [METADATA_TYPE.IS_NON_ENGLISH]: CLEANLAB_FRONTEND_COLUMN.NON_ENGLISH_SCORE,
  [METADATA_TYPE.IS_NSFW]: CLEANLAB_FRONTEND_COLUMN.NSFW_SCORE,
  [METADATA_TYPE.IS_ODD_ASPECT_RATIO]: CLEANLAB_FRONTEND_COLUMN.ODD_ASPECT_RATIO_SCORE,
  [METADATA_TYPE.IS_PII]: CLEANLAB_FRONTEND_COLUMN.PII_SCORE,
  [METADATA_TYPE.IS_TOXIC]: CLEANLAB_FRONTEND_COLUMN.TOXIC_SCORE,
  [METADATA_TYPE.IS_ODD_SIZE]: CLEANLAB_FRONTEND_COLUMN.ODD_SIZE_SCORE,
}

export const COLUMN_TITLES: Record<string, string> = {
  [CLEANLAB_FRONTEND_COLUMN.ISSUE]: 'Issue',
  [CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS]: 'Ambiguous',
  [CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE]: 'Near duplicate',
  [CLEANLAB_FRONTEND_COLUMN.OUTLIER]: 'Outlier',
  [METADATA_TYPE.IS_BIASED]: 'Biased',
  [METADATA_TYPE.IS_BLURRY]: 'Blurry',
  [METADATA_TYPE.IS_DARK]: 'Dark',
  [METADATA_TYPE.IS_INFORMAL]: 'Informal',
  [METADATA_TYPE.IS_LIGHT]: 'Light',
  [METADATA_TYPE.IS_LOW_INFORMATION]: 'Low information',
  [METADATA_TYPE.IS_NON_ENGLISH]: 'Non-English',
  [METADATA_TYPE.IS_NSFW]: 'NSFW',
  [METADATA_TYPE.IS_ODD_ASPECT_RATIO]: 'Odd aspect ratio',
  [METADATA_TYPE.IS_ODD_SIZE]: 'Odd size',
  [METADATA_TYPE.IS_GRAYSCALE]: 'Grayscale',
  [METADATA_TYPE.IS_PII]: 'PII',
  [METADATA_TYPE.IS_TOXIC]: 'Toxic',
}

export const RELATED_COLUMN_INFO: Record<string, CLEANLAB_FRONTEND_COLUMN[]> = {
  [CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE]: [CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE_ID],
  [METADATA_TYPE.IS_NON_ENGLISH]: [CLEANLAB_FRONTEND_COLUMN.PREDICTED_LANGUAGE],
  [METADATA_TYPE.IS_PII]: [CLEANLAB_FRONTEND_COLUMN.PII_TYPES, CLEANLAB_FRONTEND_COLUMN.PII_ITEMS],
  [METADATA_TYPE.IS_INFORMAL]: [
    CLEANLAB_FRONTEND_COLUMN.SPELLING_ISSUE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.GRAMMAR_ISSUE_SCORE,
    CLEANLAB_FRONTEND_COLUMN.SLANG_ISSUE_SCORE,
  ],
  [METADATA_TYPE.IS_BIASED]: [
    CLEANLAB_FRONTEND_COLUMN.GENDER_BIAS_SCORE,
    CLEANLAB_FRONTEND_COLUMN.RACIAL_BIAS_SCORE,
    CLEANLAB_FRONTEND_COLUMN.SEXUAL_ORIENTATION_BIAS_SCORE,
  ],
}

export const RELATED_INFO_COLUMN_DISPLAY_NAMES: Record<string, METADATA_TYPE_DISPLAY> = {
  [CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE_ID]: METADATA_TYPE_DISPLAY.NEAR_DUPLICATE_ID,
  [CLEANLAB_FRONTEND_COLUMN.PREDICTED_LANGUAGE]: METADATA_TYPE_DISPLAY.PREDICTED_LANGUAGE,
  [CLEANLAB_FRONTEND_COLUMN.PII_ITEMS]: METADATA_TYPE_DISPLAY.PII_ITEMS,
  [CLEANLAB_FRONTEND_COLUMN.PII_TYPES]: METADATA_TYPE_DISPLAY.PII_TYPES,
  [CLEANLAB_FRONTEND_COLUMN.SPELLING_ISSUE_SCORE]: METADATA_TYPE_DISPLAY.SPELLING_ISSUE_SCORE,
  [CLEANLAB_FRONTEND_COLUMN.GRAMMAR_ISSUE_SCORE]: METADATA_TYPE_DISPLAY.GRAMMAR_ISSUE_SCORE,
  [CLEANLAB_FRONTEND_COLUMN.SLANG_ISSUE_SCORE]: METADATA_TYPE_DISPLAY.SLANG_ISSUE_SCORE,
  [CLEANLAB_FRONTEND_COLUMN.GENDER_BIAS_SCORE]: METADATA_TYPE_DISPLAY.GENDER_BIAS_SCORE,
  [CLEANLAB_FRONTEND_COLUMN.RACIAL_BIAS_SCORE]: METADATA_TYPE_DISPLAY.RACIAL_BIAS_SCORE,
  [CLEANLAB_FRONTEND_COLUMN.SEXUAL_ORIENTATION_BIAS_SCORE]:
    METADATA_TYPE_DISPLAY.SEXUAL_ORIENTATION_BIAS_SCORE,
}
