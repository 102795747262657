import { useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import Loading from '@common/layout/loading/Loading'
import { Button } from '@components/button/Button'
import { FormField } from '@components/formField/FormField'
import { Input } from '@components/input/Input'
import { ProgressIndeterminate } from '@components/progress/ProgressIndeterminate'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useCleansetsFromProject } from '@services/cleanset/queries'
import { ProjectRowRes, queryKeys } from '@services/project/constants'
import { useCopyTemplateProjectMutation } from '@services/project/mutations'
import { useTemplateProjectsPreview } from '@services/templateProjects/queries'
import { useMyself } from '@services/user/queries'
import { useForm } from '@tanstack/react-form'
import { FormEventHandler, Suspense, useCallback, useEffect, useId, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link, LoaderFunction, useNavigate, useParams } from 'react-router-dom'

import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  getProjectNameFromDatasetName,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { ProgressStepper } from './ProgressStepper'
import { ProjectNameField } from './ProjectNameField'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import { ProjectWizardModalAlert } from './projectWizard/ProjectWizardModalAlert'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionWelcome,
} from './Sidebar'

const PROCESSING_WAIT_MS = 5000

const THIS_STEP = 2
const STEP_COUNT = 2

export const createProjectFromDemoIdLoader: LoaderFunction = async () => {
  return null
}

const DemoProcessingModal = ({ isOpen, cleansetId }: { isOpen: boolean; cleansetId: any }) => {
  const [timeoutComplete, setTimeoutComplete] = useState(false)
  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        setTimeoutComplete(true)
      }, PROCESSING_WAIT_MS)
      return () => clearTimeout(timeout)
    }
  }, [isOpen])
  const navigate = useNavigate()
  const projectIsReady = timeoutComplete && !!cleansetId

  useEffect(() => {
    if (!projectIsReady || !cleansetId) return
    navigate(`/cleansets/${cleansetId}`)
  }, [projectIsReady, cleansetId, navigate])

  return (
    <ProjectWizardModalAlert
      isOpen={isOpen}
      message={{
        heading: '',
        description: (
          <div className="flex flex-col gap-6">
            Hang on while our AI processes your Dataset...
            <ProgressIndeterminate />
          </div>
        ),
      }}
    />
  )
}

const Form = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const projectId = useParams().demoId
  const me = useMyself()
  const [project, setProject] = useState<ProjectRowRes | undefined>(undefined)

  const { data: templatesData } = useTemplateProjectsPreview({
    useQueryOptions: { suspense: true },
  })
  const template = templatesData?.find((t) => t.id === projectId)

  const { mutate: copyTemplate, isLoading: templateIsCopying } = useCopyTemplateProjectMutation({
    useMutationOptions: {
      onSuccess: (project) => {
        queryClient.invalidateQueries(queryKeys.projects.all())
        setProject(project)
      },
      onError: (err) => {
        toast({
          ...defaultToastAlertProps,
          render: renderChakraToastAlert({
            heading: `Could not create project from ${template?.meta.title}`,
            description: err.message,
            status: 'warning',
          }),
        })
      },
    },
  })

  const cleansets = useCleansetsFromProject({
    projectId: project?.id.replaceAll('-', '') ?? '',
    useQueryOptions: {
      enabled: !!project?.id,
      suspense: false,
    },
  })
  const cleansetId = cleansets?.[0]?.id
  useEffect(() => {
    if (!cleansetId) {
      setProject(undefined)
    }
  }, [cleansetId])

  const datasetSelectId = useId()
  const datasetName = template?.meta.title
  const form = useForm({
    defaultValues: {
      projectName: getProjectNameFromDatasetName(datasetName) ?? '',
    },
    validators: {
      onMount: () => {
        if (!projectId) return 'Must have a valid projectID'
        if (!me.id) return 'Must be logged in'

        return undefined
      },
    },
    onSubmit: async ({ value }) => {
      if (!projectId) {
        return
      }
      copyTemplate({ userId: me.id, projectId: projectId, projectName: value.projectName })
    },
  })
  const formState = form.useStore((s) => {
    return {
      canSubmit: s.canSubmit,
    }
  })
  const onSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      form.handleSubmit()
    },
    [form]
  )

  const showProcessing = !!templateIsCopying || !!project || !!cleansetId

  const navigate = useNavigate()
  useEffect(() => {
    if (!template) navigate('/clean/demo')
  }, [navigate, template])
  if (!template) {
    return null
  }

  return (
    <form className="flex flex-col gap-8" onSubmit={onSubmit} noValidate>
      <DemoProcessingModal isOpen={showProcessing} cleansetId={cleansetId} />
      <div className="flex flex-col gap-6">
        <FormField htmlFor={datasetSelectId} label="Demo Dataset">
          <Input disabled={true} value={datasetName} />
        </FormField>
        <ProjectNameField form={form} />
      </div>
      <ProjectWizardActions step={THIS_STEP} stepCount={STEP_COUNT}>
        <Button variant="highContrast" size="medium" type="submit" disabled={!formState.canSubmit}>
          Create Project
        </Button>
      </ProjectWizardActions>
    </form>
  )
}

export const NameProjectPageHeading = () => (
  <HeadingWrapper>
    <PageHeading>Name your Project</PageHeading>
    <PageDescription>
      Use a unique Project name that might be a short description of the task. Don't worry, you can
      change this later.
    </PageDescription>
  </HeadingWrapper>
)

export const CreateProjectFromDemoId = () => {
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          <Link to="/clean/demo">Select a demo dataset</Link>
        </PreviousLink>
        <ProgressStepper stepNum={THIS_STEP} stepCount={STEP_COUNT} />
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <NameProjectPageHeading />
          <Suspense fallback={<Loading />}>
            <Form />
          </Suspense>
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcome />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/">Cleanlab Studio docs</ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/concepts/projects/">
              Projects guidebook
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/concepts/cleanset/#version-history">
              Dataset version history
            </ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}
