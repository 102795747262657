import { wrapSvgIcon } from './wrapSvgIcon'

export const IconMoreVerticalDouble = wrapSvgIcon(
  <svg width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.67887 3.1366C6.03454 3.1366 5.51221 3.65893 5.51221 4.30326C5.51221 4.9476 6.03454 5.46993 6.67887 5.46993C7.32321 5.46993 7.84554 4.9476 7.84554 4.30326C7.84554 3.65893 7.32321 3.1366 6.67887 3.1366Z"
      fill="#5B6371"
    />
    <path
      d="M6.67887 7.80326C6.03454 7.80326 5.51221 8.3256 5.51221 8.96993C5.51221 9.61426 6.03454 10.1366 6.67887 10.1366C7.32321 10.1366 7.84554 9.61426 7.84554 8.96993C7.84554 8.3256 7.32321 7.80326 6.67887 7.80326Z"
      fill="#5B6371"
    />
    <path
      d="M6.67887 12.4699C6.03454 12.4699 5.51221 12.9923 5.51221 13.6366C5.51221 14.2809 6.03454 14.8033 6.67887 14.8033C7.32321 14.8033 7.84554 14.2809 7.84554 13.6366C7.84554 12.9923 7.32321 12.4699 6.67887 12.4699Z"
      fill="#5B6371"
    />
    <path
      d="M10.6789 7.80326C10.0345 7.80326 9.51221 8.3256 9.51221 8.96993C9.51221 9.61426 10.0345 10.1366 10.6789 10.1366C11.3232 10.1366 11.8455 9.61426 11.8455 8.96993C11.8455 8.3256 11.3232 7.80326 10.6789 7.80326Z"
      fill="#5B6371"
    />
    <path
      d="M10.6789 3.1366C10.0345 3.1366 9.51221 3.65893 9.51221 4.30326C9.51221 4.9476 10.0345 5.46993 10.6789 5.46993C11.3232 5.46993 11.8455 4.9476 11.8455 4.30326C11.8455 3.65893 11.3232 3.1366 10.6789 3.1366Z"
      fill="#5B6371"
    />
    <path
      d="M10.6789 12.4699C10.0345 12.4699 9.51221 12.9923 9.51221 13.6366C9.51221 14.2809 10.0345 14.8033 10.6789 14.8033C11.3232 14.8033 11.8455 14.2809 11.8455 13.6366C11.8455 12.9923 11.3232 12.4699 10.6789 12.4699Z"
      fill="#5B6371"
    />
  </svg>,
  { fill: 'currentColor', stroke: 'none' }
)
