import { Button as ChakraButton } from '@chakra-ui/react'
import React from 'react'

import { DestructiveButtonProps } from './DestructiveButton.types'

const DestructiveButton = (props: DestructiveButtonProps) => {
  const { children, isDisabled, isLoading, onClick } = props
  return (
    <ChakraButton
      variant="destructive"
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
    >
      {children}
    </ChakraButton>
  )
}

export default DestructiveButton
