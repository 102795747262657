import { useToast } from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import { FormField } from '@components/formField/FormField'
import { IconLink } from '@components/icons'
import { Input } from '@components/input/Input'
import { useEventTracking } from '@hooks/useEventTracking'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import uploadApiService from '@services/upload/v1/uploadApi'
import { useForm } from '@tanstack/react-form'
import { AxiosError } from 'axios'
import { FormEvent, MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react'
import {
  CreateProjectContainer,
  CreateProjectContent,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  WithSidebarContainer,
} from 'src/pages/projectForm/CreateProject.helpers'

import { DatasetUploadActions } from '../DatasetUpload.helpers'
import { DatasetUploadSidebarStandard } from '../DatasetUploadSidebar'
import { UploadBackLink } from '../UploadBackLink'
import { useProcessDataset } from '../useProcessDataset'

const checkUrl = (url: string) => {
  return url ? undefined : 'Please enter a valid URL'
}

const useAutoFocus = <T extends HTMLElement>(
  ref: MutableRefObject<T> | RefObject<T>,
  condition = true
) => {
  useEffect(() => {
    if (condition && ref.current) {
      ref.current.focus()
    }
  }, [ref, condition])
}

export const UrlUpload = () => {
  const { startProcessing, processingContent } = useProcessDataset()
  const { trackEvent } = useEventTracking()

  const toast = useToast()

  const form = useForm({
    defaultValues: {
      url: '',
    },
    onSubmit: async ({ value }) => {
      trackEvent(MixpanelEvents.clickUrlUploadButton)
      try {
        const data = await uploadApiService.uploadFromUrl(value.url)
        startProcessing(data.upload_id, 'url')
      } catch (err) {
        const error = err as AxiosError
        notifyAxiosError(toast, error)
      }
    },
  })

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      e.stopPropagation()
      form.handleSubmit()
    },
    [form]
  )

  const formState = form.useStore((s) => ({
    canSubmit: s.canSubmit,
  }))

  const urlInputRef = useRef<HTMLInputElement | null>(null)
  useAutoFocus(urlInputRef)

  return (
    <CreateProjectContainer>
      {!processingContent && <UploadBackLink />}
      <WithSidebarContainer>
        <CreateProjectContent>
          {processingContent ?? (
            <>
              <HeadingWrapper>
                <PageHeading>Import from your computer</PageHeading>
                <PageDescription>
                  To upload your Dataset, paste a link in the field below.
                </PageDescription>
              </HeadingWrapper>
              <form onSubmit={onSubmit} className="flex flex-col gap-8">
                <form.Field
                  name="url"
                  validators={{
                    onChange: ({ value, fieldApi: { state, setMeta } }) => {
                      // Clear onBlur errors when the user starts typing
                      if (state.meta.errorMap.onBlur) {
                        setMeta((prevMeta) => {
                          const { onBlur, ...nextErrorMap } = prevMeta.errorMap
                          return { ...prevMeta, errorMap: nextErrorMap }
                        })
                      }
                      if (state.meta.isDirty || state.meta.errors.length) {
                        return checkUrl(value)
                      }
                      return undefined
                    },
                    onBlur: ({ value }) => {
                      return checkUrl(value)
                    },
                  }}
                >
                  {({ name, state, handleBlur, handleChange }) => (
                    <FormField label="Dataset URL" error={state.meta.errors?.[0]}>
                      <Input
                        ref={urlInputRef}
                        name={name}
                        iconStart={<IconLink />}
                        value={state.value}
                        onChange={(e) => handleChange(e.currentTarget.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  )}
                </form.Field>
                <DatasetUploadActions>
                  <Button type="submit" variant="highContrast" disabled={!formState.canSubmit}>
                    Import
                  </Button>
                </DatasetUploadActions>
              </form>
            </>
          )}
        </CreateProjectContent>
        <DatasetUploadSidebarStandard />
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}

export const UrlUploadForm = () => {}
