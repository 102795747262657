import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useQuery } from 'react-query'

import { queryKeys } from './constants'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/cli/v0`,
  withCredentials: true,
})

export const useApiKey = () => {
  const { data, isFetching } = useQuery({
    queryKey: queryKeys.commandLine.self(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const response = await axiosClient.get('/api_key', createAuthHeaders(accessToken))
      return response.data.api_key
    },
    meta: { error: 'Failed to fetch user API key.' },
  })
  return { apiKey: data ?? '...', isFetching: isFetching }
}
