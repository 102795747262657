import {
  IconCode,
  IconDatabricksLogo,
  IconLink,
  IconSnowflakeLogo,
  IconTerminal,
  IconUpload,
} from '@components/icons'
import { ReactElement, ReactNode } from 'react'
import testIds from 'src/playwright/testIds'

import { UPLOAD_PATH_NAME, UPLOAD_TYPES_TO_PATHS, UploadType } from '../DatasetUpload.types'

export type UploadTypeCardProps = {
  heading: ReactNode
  description: ReactNode
  icon: ReactElement
  cta: ReactNode
  badgeContent?: ReactNode
  url?: string
  isFeatureGated: boolean
}

export const UPLOAD_CARD_CONTENT = {
  [UploadType.computer]: {
    isFeatureGated: true,
    heading: 'Import from your computer',
    description: 'Import a file from your local machine, using your web browser.',
    icon: <IconUpload />,
    cta: 'Import',
    url: `/${UPLOAD_PATH_NAME}/${UPLOAD_TYPES_TO_PATHS[UploadType.computer]}`,
    'data-testid': testIds.datasetUploadPageUploadFromComputerButton,
  },
  [UploadType.url]: {
    isFeatureGated: true,
    heading: 'Import via URL',
    description: 'Import a file directly from an HTTP/HTTPS URL.',
    icon: <IconLink />,
    cta: 'Import',
    url: `/${UPLOAD_PATH_NAME}/${UPLOAD_TYPES_TO_PATHS[UploadType.url]}`,
    'data-testid': testIds.datasetUploadPageUploadFromUrlButton,
  },
  [UploadType.commandLine]: {
    isFeatureGated: false,
    heading: 'Import via command line',
    description: 'Import a file from your local machine, using the shell.',
    icon: <IconTerminal />,
    cta: 'Import',
    url: `/${UPLOAD_PATH_NAME}/${UPLOAD_TYPES_TO_PATHS[UploadType.commandLine]}`,
  },
  [UploadType.pythonApi]: {
    isFeatureGated: false,
    heading: 'Import via Python API',
    description: 'Upload a dataframe directly from a Jupyter Notebook or a Python application.',
    icon: <IconCode />,
    cta: 'Import',
    url: `/${UPLOAD_PATH_NAME}/${UPLOAD_TYPES_TO_PATHS[UploadType.pythonApi]}`,
  },
  [UploadType.databricks]: {
    isFeatureGated: false,
    heading: 'Databricks connector',
    description: 'Run Cleanlab Studio on data stored in the Databricks platform.',
    icon: <IconDatabricksLogo />,
    cta: 'Tutorial',
    url: 'https://help.cleanlab.ai/tutorials/databricks/',
  },
  [UploadType.snowflake]: {
    isFeatureGated: false,
    heading: 'Snowflake connector',
    description: 'Run Cleanlab Studio on data stored in the Snowflake platform.',
    icon: <IconSnowflakeLogo />,
    cta: 'Tutorial',
    url: 'https://help.cleanlab.ai/tutorials/snowflake/',
  },
} as const satisfies Record<
  Exclude<UploadType, UploadType.undefined>,
  UploadTypeCardProps & { 'data-testid'?: string }
>
