// Must not use @ import path aliases so tailwind can import this
import { darkModeColors, darkModeColorsForChakra } from './CleanlabColorsDark'
import { lightModeColors } from './CleanlabColorsLight'

export const cleanlabColors = {
  ...lightModeColors,
  ...darkModeColorsForChakra,
} as const

export const cleanlabColorsForTw = {
  light: lightModeColors,
  dark: darkModeColors,
} as const
