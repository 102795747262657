export const queryKeys = {
  organizations: {
    all: () => ['organizations'] as const,
    usageSummary: () => [...queryKeys.organizations.all(), 'usageSummary'],
    downloadUrl: () => [...queryKeys.organizations.all(), 'downloadUrl'],
  },
}

export interface OrgInfoRes {
  createdAt: number
  id: string
  name: string
}

export interface OrgUsageSummaryRes {
  isCostCalculationEnabled: boolean
  id: string
  name: string
  createdAt: string
  numUsers: number
  numModelsDeployed: number
  numCleansets: number
  numInferenceRowsExported: number
  numCleansetRowsExported: number
  numCleansetRowsAnalyzed: number
  numProjects: number
  numProjectsImage: number
  numProjectsText: number
  numProjectsTabular: number
  licenseStartDate: number
  licenseEndDate: number
}

export interface OrgUsageDownloadUrlRes {
  downloadUrl: string
}
