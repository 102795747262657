import { Meter } from '@components/meter/Meter'

import { ProgressCell } from '../issuesFoundCell/IssuesFoundCell'

export type IssuesResolvedProgressCellProps = {
  numIssuesResolved: number
  numIssues: number
  isReady: boolean
  hasError: boolean
}

const IssuesResolvedProgressCell = (props: IssuesResolvedProgressCellProps) => {
  const { numIssues, numIssuesResolved, isReady, hasError } = props
  const label = 'Issues resolved'

  if (hasError || !isReady) {
    return <></>
  }

  return (
    <ProgressCell className="type-caption text-text-primary">
      <Meter
        variant="default"
        aria-label={label}
        value={numIssuesResolved}
        max={numIssues}
        className="w-10"
      />
      {numIssuesResolved}/{numIssues}
    </ProgressCell>
  )
}

export default IssuesResolvedProgressCell
