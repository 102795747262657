import { HStack, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useContext, useMemo } from 'react'
import { FiArrowDown, FiArrowUp } from 'react-icons/fi'
import { RiAsterisk } from 'react-icons/ri'

import { SchemaUpdatesContext } from '../SchemaUpdatesContext'
import ColumnTypeMenu from './ColumnTypeMenu/ColumnTypeMenu'
import { getEditButtonDisabledText } from './DatasetTableHeader.helpers'
import { DatasetTableHeaderProps } from './DatasetTableHeader.types'

const DatasetTableHeader = (props: DatasetTableHeaderProps) => {
  const { column, columnType, dataType, mutable, setSort, disableSort } = props
  const columnNameTextColor = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const isDeprecatedDatasetSchema = columnType === undefined
  const originalColumnType = isDeprecatedDatasetSchema ? dataType : columnType
  const columnId = column.getColId()
  const { schemaUpdates, isDatasetUsedInProject } = useContext(SchemaUpdatesContext)
  const isColumnTypeUpdated = schemaUpdates[columnId] !== undefined
  const editButtonDisabledText = getEditButtonDisabledText(
    isDeprecatedDatasetSchema,
    mutable,
    isDatasetUsedInProject
  )
  const ariaSortValue = useMemo(() => {
    if (column.isSortNone()) {
      return 'none'
    }
    if (column.isSortAscending()) {
      return 'ascending'
    }
    return 'descending'
  }, [column])

  const onSortRequested = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disableSort) {
      const order = column.isSortNone() ? 'asc' : column.isSortAscending() ? 'desc' : null
      setSort(order, event.shiftKey)
    }
  }

  return (
    <Stack
      direction={dataType ? 'column' : 'row'}
      w="100%"
      h={dataType ? '50px' : '20px'}
      onClick={onSortRequested}
      spacing={0}
      justifyContent="space-between"
      aria-sort={ariaSortValue}
    >
      <HStack
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
        w="100%"
        spacing={0}
      >
        {column.isSortAscending() ? (
          <FiArrowUp size="16px" />
        ) : column.isSortDescending() ? (
          <FiArrowDown size="16px" />
        ) : (
          <div></div>
        )}
        {dataType && (
          <ColumnTypeMenu
            originalColumnType={originalColumnType}
            columnId={columnId}
            editButtonDisabledText={editButtonDisabledText}
          />
        )}
      </HStack>
      <HStack alignSelf="flex-start" spacing="4px">
        {isColumnTypeUpdated ? <RiAsterisk fontSize="20px" /> : null}
        <Text
          w="100%"
          fontSize="14px"
          fontWeight="600"
          lineHeight="20px"
          color={columnNameTextColor}
          textOverflow="ellipsis"
        >
          {column.getColDef().field}
        </Text>
      </HStack>
    </Stack>
  )
}

export default DatasetTableHeader
