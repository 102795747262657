import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'

import { PredictionErrorModalProps } from './PredictionErrorModal.types'

const PredictionErrorModal = (props: PredictionErrorModalProps) => {
  const { isOpen, onClose, datasetName, error } = props

  const borderColor = useColorModeValue('gray.100', 'gray.300')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="2px solid" borderColor={borderColor} p={2} pl={6}>
          <HStack spacing={6} mr={10}>
            <Icon color="red" as={WarningTwoIcon} />
            <Text fontWeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              <Text fontWeight="bold" as="span">
                Error:
              </Text>{' '}
              {datasetName}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="md" mt={2}>
          <Text>
            {error && error.length ? error : "We're sorry, something went wrong during inference."}
          </Text>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PredictionErrorModal
