import { Tag, Text } from '@chakra-ui/react'
import { ColumnState } from 'ag-grid-community'
import React from 'react'

import { BatchAction } from './BatchActionsModal.types'

export const getActionColorScheme = (action: string) => {
  switch (action) {
    case BatchAction.autoFix: {
      return 'yellow'
    }
    case BatchAction.exclude: {
      return 'red'
    }
    case BatchAction.needsReview: {
      return 'orange'
    }
    case BatchAction.label: {
      return 'blue'
    }
  }
}

export const getBatchActionRequestPath = (batchAction: string) => {
  switch (batchAction) {
    case BatchAction.autoFix: {
      return 'auto-fix'
    }
    case BatchAction.exclude: {
      return 'exclude'
    }
    case BatchAction.needsReview: {
      return 'mark-needs-review'
    }
    case BatchAction.label: {
      return 'relabel'
    }
    case BatchAction.autoLabel: {
      return 'auto-label'
    }
    default: {
      return ''
    }
  }
}

export const getBatchActionVerb = (batchAction: BatchAction) => {
  switch (batchAction) {
    case BatchAction.autoFix: {
      return 'auto-fixed'
    }
    case BatchAction.exclude: {
      return 'excluded'
    }
    case BatchAction.needsReview: {
      return 'marked as needs review'
    }
    case BatchAction.label: {
      return 'labeled'
    }
  }
}

export const getSortModel = (columnState: ColumnState[]) => {
  const sortedColumnState = columnState.sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0))
  const sortModel = []
  for (let i = 0; i < sortedColumnState.length; i++) {
    if (sortedColumnState[i].sort) {
      sortModel.push([sortedColumnState[i].colId, sortedColumnState[i].sort])
    }
  }

  return sortModel
}

export const getActionDescription = (batchAction: BatchAction) => {
  switch (batchAction) {
    case BatchAction.autoFix: {
      return 'Auto-fix applies Cleanlab Studio’s recommended action to the selected datapoints. Label issues will be corrected to the suggested label, Outliers will be excluded from the cleanset, and data points with no issue will be kept as-is.'
    }
    case BatchAction.exclude: {
      return 'Exclude removes a data point from the Dataset.'
    }
    case BatchAction.label: {
      return 'Label applies the selected label to a data point.'
    }
    case BatchAction.needsReview: {
      return (
        <Text>
          Needs review adds the{' '}
          <Tag fontWeight={600} mt={0.5} colorScheme="orange">
            needs review
          </Tag>{' '}
          tag to a data point.
        </Text>
      )
    }
  }
}
