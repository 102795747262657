import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Spinner,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { InputSearch } from '@components/input/Input'
import { useKeyPress } from '@hooks/useKeyPress'
import { NotificationSetting } from '@services/userNotificationSettings/constants'
import { useDisableNotificationSetting } from '@services/userNotificationSettings/mutations'
import { useNotificationSetting } from '@services/userNotificationSettings/queries'
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import { FiInfo } from 'react-icons/fi'
import AutoSizer, { Size } from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { Tasktype } from 'src/pages/datasetUpload/DatasetUpload.types'

import { getFilteredCommands } from '../commandpalette/CommandPalette.helpers'
import { ActionType, CommandProps } from '../commandpalette/CommandPalette.types'
import MultiSelectDisplay from '../commandpalette/multiSelectDisplay/MultiSelectDisplay'
import NewLabelAddedModal from '../commandpalette/newLabelAddedModal/NewLabelAddedModal'
import PaletteSuggestion from '../commandpalette/paletteSuggestion/PaletteSuggestion'
import {
  checkIsBadgeOverflowing,
  checkIsPaletteOverflowing,
  getItemSize,
} from '../commandpalette/paletteSuggestion/PaletteSuggestion.helpers'
import { isSuggestionHidden } from '../editInterface/EditInterface.helpers'
import {
  getAccordionHeight,
  getAccordionMaxHeight,
  getAccorionMarginTop,
  getDefaultDataLabelingCommands,
} from './DataLabelingCommandPalette.helpers'
import { DataLabelingCommandPaletteProps } from './DataLabelingCommandPalette.types'

const DataLabelingCommandPalette = (props: DataLabelingCommandPaletteProps) => {
  const {
    selectedRowIndex,
    labels,
    givenLabel,
    suggestedLabel,
    suggestedAction,
    labelToProba,
    updateRowAction,
    isLabelIssue,
    isUnlabeled,
    suggestExclude,
    taskType,
    selectedRows,
    handleMultiSelectAction,
    isLoading,
    isActionLoading,
    onSetThresholdButtonClick,
    currentThresholdRow,
    givenLabelType,
    correctedLabel,
    currentRowAction,
    autoAdvance,
    newlyAddedLabels,
  } = props

  const [inputValue, setInputValue] = useState('')
  const [isAccordionOpen, setIsAccordionOpen] = useState(true)
  const [hideNewLabelAddedModal, setHideNewLabelAddedModal] = useState<boolean>(false)
  const [showTallView, setShowTallView] = useState<boolean>(false)
  const [showXLView, setShowXLView] = useState<boolean>(false)
  const [paletteBreakPoint, setPaletteBreakPoint] = useState<number | null>(null)
  const [paletteWidth, setPaletteWidth] = useState<number>(0)
  const newlyAddedLabelsMemoized = JSON.stringify(newlyAddedLabels)

  const showThresholdButton =
    currentThresholdRow !== selectedRowIndex + 1 && selectedRows.length < 2

  const paletteRef = useRef<HTMLDivElement>(null)

  const markUnresolvedAction = () => {
    updateRowAction(ActionType.MARK_UNRESOLVED)
  }

  const {
    isOpen: isNewLabelAddedModalOpen,
    onOpen: onNewLabelAddedModalOpen,
    onClose: onNewLabelAddedModalClose,
  } = useDisclosure()

  const showNewAddedLabelModal = useNotificationSetting(NotificationSetting.ShowNewAddedLabelModal)
  const { mutate: disableNotificationSetting } = useDisableNotificationSetting(
    NotificationSetting.ShowNewAddedLabelModal
  )
  const handleNewLabelAddedModalClose = () => {
    if (hideNewLabelAddedModal) {
      disableNotificationSetting()
    }
    onNewLabelAddedModalClose()
  }

  // clear search input and unfocus it when selected row changes
  useEffect(() => {
    setInputValue('')
  }, [setInputValue, selectedRowIndex])

  useEffect(() => {
    const isLabelOverflowing = checkIsPaletteOverflowing()
    const isBadgeOverflowing = checkIsBadgeOverflowing()

    // If there is overflow, show the tall view and set breakpoint
    if (isLabelOverflowing && !showTallView) {
      setShowTallView(true)
      setPaletteBreakPoint(paletteWidth)
    }
    // If resolver is resized past the breakpoint, show small view
    else if (paletteBreakPoint && paletteWidth > paletteBreakPoint && showTallView) {
      setShowTallView(false)
      setShowXLView(false)
      // If showing tall view and the badges overflow, show XL view
    } else if (showTallView && isBadgeOverflowing && !showXLView) {
      setShowXLView(true)
      // Reset to tall view if badges no longer overflow
    } else if (showXLView && !isBadgeOverflowing) {
      setShowXLView(false)
    }
  }, [paletteBreakPoint, paletteWidth, showTallView, showXLView])

  const accordionBorderColor = useColorModeValue('neutral.200', 'neutralDarkMode.200')
  const accordionTitleColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const accordionBackgroundColor = useColorModeValue('white', 'black')

  const isMultiSelect = selectedRows.length > 1

  const handleActionSelect = (
    action: string,
    label?: string | number | boolean,
    isNewLabel = false
  ) => {
    if (isMultiSelect) {
      handleMultiSelectAction(action, label ?? '')
    } else {
      updateRowAction(action)
    }
    setInputValue('')
    if (isNewLabel) {
      onNewLabelAddedModalOpen()
    }
  }
  const fastMode = inputValue.length === 0

  const hideSuggestion = isSuggestionHidden(
    isLabelIssue,
    isUnlabeled,
    suggestExclude,
    givenLabel,
    suggestedLabel
  )

  const defaultCommands: CommandProps[] = getDefaultDataLabelingCommands({
    isUnlabeled: isUnlabeled,
    isMultiSelect: isMultiSelect,
    suggestedAction: suggestedAction,
    suggestedLabel: suggestedLabel,
    labelToProba: labelToProba,
    handleActionSelect: handleActionSelect,
    hideSuggestion: hideSuggestion,
    givenLabel: givenLabel,
  })

  const relabelCommands: CommandProps[] = labels
    .filter((l) =>
      !isMultiSelect ? ![givenLabel, hideSuggestion ? null : suggestedLabel].includes(l) : true
    )
    .sort(
      isMultiSelect
        ? (a, b) => (a > b ? 1 : -1)
        : (a, b) => (labelToProba[b] ?? 0) - (labelToProba[a] ?? 0)
    ) // sort by descending proba
    .map((label, idx) => {
      return {
        category: ActionType.RELABEL,
        name: `${label}`,
        shortcut: idx + 1 <= 9 ? (idx + 1).toString() : null,
        proba: labelToProba[label],
        command: () =>
          isMultiSelect
            ? handleActionSelect('relabel', label)
            : handleActionSelect(`relabel-${label}`),
      }
    })

  const addedLabelCommand: CommandProps[] = [
    {
      category: ActionType.ADD_LABEL,
      name: `${inputValue}`,
      command: () =>
        isMultiSelect
          ? handleActionSelect('relabel', inputValue, true)
          : handleActionSelect(`relabel-${inputValue}`, inputValue, true),
    },
  ]

  const allFastCommands: CommandProps[] = defaultCommands.concat(relabelCommands)

  // Note: we only want the order to shift dynamically if isMultiSelect, selectedRowIndex, autoAdvance, or newlyAddedLabels values change.
  // We do not want to recalculate when allFastCommands, correctedLabel, or currentRowAction change

  const allFastCommandsOrdered = useMemo(() => {
    if (!isMultiSelect && selectedRowIndex !== -1) {
      const targetCommandName = currentRowAction === 'exclude' ? 'Exclude' : correctedLabel
      const index = allFastCommands.findIndex((command) => command.name === targetCommandName)
      if (index !== -1) {
        const updatedCommands = [
          allFastCommands[index],
          ...allFastCommands.slice(0, index),
          ...allFastCommands.slice(index + 1),
        ]
        return updatedCommands
      }
    }
    return allFastCommands
  }, [isMultiSelect, selectedRowIndex, autoAdvance, newlyAddedLabelsMemoized]) // eslint-disable-line react-hooks/exhaustive-deps

  const allSearchCommands: CommandProps[] = defaultCommands.concat(relabelCommands).map((e) => {
    return { ...e, shortcut: null }
  })

  const filteredCommands = useMemo(() => {
    return getFilteredCommands(fastMode, allFastCommandsOrdered, inputValue, allSearchCommands)
  }, [allFastCommandsOrdered, allSearchCommands, fastMode, inputValue])

  const displayedCommands = filteredCommands.length > 0 ? filteredCommands : addedLabelCommand

  useKeyPress({
    callback: () =>
      isMultiSelect
        ? handleMultiSelectAction(ActionType.EXCLUDE)
        : updateRowAction(ActionType.EXCLUDE),
    keys: ['e'],
  })

  useKeyPress({
    callback: () =>
      isMultiSelect
        ? handleMultiSelectAction(ActionType.AUTO_FIX)
        : updateRowAction(suggestedAction),
    keys: ['w'],
    enabled: taskType === Tasktype.MULTILABEL,
  })

  useKeyPress({
    callback: () => updateRowAction(ActionType.KEEP),
    keys: ['q'],
    enabled: taskType === Tasktype.MULTILABEL,
  })

  useKeyPress({
    callback: () =>
      isMultiSelect
        ? handleMultiSelectAction(ActionType.MARK_UNRESOLVED)
        : updateRowAction(ActionType.MARK_UNRESOLVED),
    keys: ['c'],
  })

  const accordionHeight = getAccordionHeight(isAccordionOpen, displayedCommands.length)
  const accordionMaxHeight = getAccordionMaxHeight(showThresholdButton)
  const accordionMarginTop = getAccorionMarginTop(showThresholdButton)

  return (
    <VStack gap={0} h="100%" w="100%" alignItems="flex-start">
      {isMultiSelect && (
        <div className="w-full border-t border-border-0">
          <MultiSelectDisplay
            numRows={selectedRows?.length}
            onClearAll={() => handleMultiSelectAction(ActionType.MARK_UNRESOLVED)}
          />
        </div>
      )}
      <Box w="100%" h="100%">
        {isMultiSelect && taskType === Tasktype.MULTILABEL ? (
          <Center h="100%" fontSize="sm">
            Multi-select label actions are not currently supported for multi-label Projects
          </Center>
        ) : (
          <Box px={4} ref={paletteRef} h="100%" pt="16px" className="border-t border-border-1">
            {showThresholdButton && (
              <Button
                variant="primary"
                w="100%"
                rightIcon={
                  <Tooltip
                    label="All data above the threshold will be assigned Cleanlab Studio’s
                suggested label when you click Auto label"
                    aria-label="Set as threshold"
                    hasArrow
                  >
                    <span>
                      <FiInfo size="16px" />
                    </span>
                  </Tooltip>
                }
                onClick={onSetThresholdButtonClick}
              >
                Set as threshold
              </Button>
            )}
            <Accordion
              allowToggle
              mt={accordionMarginTop}
              border="1px solid"
              borderColor={accordionBorderColor}
              borderRadius="8px"
              defaultIndex={0}
              h={accordionHeight}
              maxH={accordionMaxHeight}
              bg={accordionBackgroundColor}
            >
              <AccordionItem border="none">
                <AccordionButton onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
                  <Box
                    fontSize="sm"
                    fontWeight="600"
                    as="span"
                    flex="1"
                    textAlign="left"
                    color={accordionTitleColor}
                  >
                    Manually label
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel h={accordionHeight} maxH={accordionMaxHeight}>
                  <div className="pb-5">
                    <InputSearch
                      value={inputValue}
                      placeholder="Type to search or create new"
                      onChange={(evt) => {
                        evt.stopPropagation()
                        setInputValue(evt.target.value)
                      }}
                      onKeyDown={(evt) => evt.stopPropagation()}
                    />
                  </div>
                  {isLoading || isActionLoading ? (
                    <Center h="100%">
                      <Spinner size="xl" />
                    </Center>
                  ) : (
                    <AutoSizer>
                      {({ height, width }: Size) => {
                        setPaletteWidth(width)
                        return (
                          <FixedSizeList
                            height={height - 72}
                            itemCount={displayedCommands.length}
                            itemSize={getItemSize(showTallView, showXLView)}
                            width={width}
                          >
                            {({ index, style }: { index: number; style: CSSProperties }) => {
                              return (
                                <div style={style} className="pb-5">
                                  <PaletteSuggestion
                                    key={selectedRowIndex + '-' + index}
                                    inputValue={inputValue}
                                    {...displayedCommands[index]}
                                    taskType={taskType}
                                    labels={labelToProba}
                                    isMultiSelect={selectedRows.length > 1}
                                    givenLabelType={givenLabelType}
                                    correctedLabel={correctedLabel}
                                    markUnresolvedAction={markUnresolvedAction}
                                    currentRowAction={currentRowAction}
                                    showTallView={showTallView}
                                    showXLView={showXLView}
                                    newlyAddedLabels={newlyAddedLabels}
                                  />
                                </div>
                              )
                            }}
                          </FixedSizeList>
                        )
                      }}
                    </AutoSizer>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        )}
      </Box>
      <NewLabelAddedModal
        isOpen={isNewLabelAddedModalOpen && showNewAddedLabelModal}
        onClose={handleNewLabelAddedModalClose}
        setHideNewLabelAddedModal={setHideNewLabelAddedModal}
      />
    </VStack>
  )
}

export default DataLabelingCommandPalette
