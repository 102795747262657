import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { BarDatum } from '@nivo/bar'
import { ErrorRank } from '@services/datasheet/constants'

import styles from './CleansetCharts.module.css'
import {
  CorrectionDistributionType,
  HeatMapRowType,
  LabelIssuesDataType,
  RankedChart,
} from './CleansetCharts.types'
import { HeatmapData } from './correctionsHeatmap/CorrectionsHeatmap.types'
import { ISSUE_TYPE } from './dataIssuesChart/DataIssuesChart.helpers'

export const CHART_TEXT_DICT = {
  titles: {
    agModelSummary: 'Performance of individual models',
    labelIssueChart: 'Potential label issues by class',
    labelCorrectionsChart: 'Label corrections by class',
    suggestedCorrectionsChart: 'Most frequently suggested label corrections',
    suggestedCorrectionsHeatmapChart: 'Distribution of suggested label corrections',
    correctionsChart: 'Most frequent label corrections',
    correctionsHeatmapChart: 'Distribution of label corrections',
    dataIssues: 'Data issues',
    dataIssuesChart: 'Data issues by type',
    modelPerformanceByClassSummary: 'Model performance per class',
    modelPerformanceSummary: 'Model performance summary',
    sourcesWithMostIssues: 'Sources with most issues',
    sourcesWithLeastIssues: 'Sources with least issues',
    sourcesWithMostUnlabeled: 'Sources with most unlabeled data',
    sourcesWithMostWellLabeled: 'Sources with most well-labeled data',
    givenLabel: 'Given label',
    suggestedLabel: 'Suggested label',
    correctedLabel: 'Corrected label',
    numberOfCorrectedExamples: 'Number of corrected examples',
    numberOfExamples: 'Number of examples',
    numberOfSuggestedCorrections: 'Number of suggested corrections',
    percentageOfExamples: 'Percentage of examples',
    percentageOfTotalExamples: 'Percentage of total examples',
  },
  descriptions: {
    agModelSummary:
      'Cleanlab trains and tunes thousands of hyperparameters and model-type possibilities for you automatically. This table summarizes some of the best-performing models found. The top model here is considered the best model for your Dataset (and may be an ensemble predictor of multiple models). Exactly what models are listed will depend on specifics of your Dataset and Project settings.',
    labelIssueChart:
      'This chart displays the number of label issues for the classes with the most issues in the Dataset. The overall length of each horizontal bar indicates the total number of datapoints labeled as the corresponding class. The green section indicates the number of label issues Cleanlab Studio identified within these datapoints. Click on a bar to view those datapoints in detail.',
    labelCorrectionsChart:
      'This chart displays the number of corrections you have made in your Dataset, grouped by given label. Each bar indicates the number of datapoints with the given label that you have corrected to have another label (in green) or decided to exclude from the Dataset (in red). Click on a bar to view those datapoints in detail.',
    suggestedCorrectionsChart:
      'This chart breaks down the different types of potential label errors in your Dataset. Each row shows the number of datapoints which have a Given Label in your Dataset that Cleanlab Studio believes should be corrected to the Suggested Label. Click on a bar to view those datapoints in detail.',
    suggestedCorrectionsHeatmapChart:
      'Each square indicates the number of datapoints in your Dataset which have the given label on the left, but we suggest correcting to the label on the bottom. The darkest squares represent the most commonly suggested corrections. Click on a square to view those datapoints in detail.',
    correctionsChart:
      'This chart breaks down the most common label corrections made in your Dataset. Each row shows the number of datapoints with the Given Label that you have changed to have the Corrected Label instead. Click on a bar to view those datapoints in detail.',
    correctionsHeatmapChart:
      'Each square indicates the number of datapoints in your Dataset with the Given Label on the left that you have changed to have the Corrected Label on the bottom instead. Darker squares represent the most common corrections you have made. Click on a square to view those datapoints in detail.',
    dataIssuesChart:
      'This chart breaks down the potential data issues identified in your Dataset. Data issues may negatively impact the performance of your machine learning model if not addressed. Click on a bar in the chart to view datapoints identified to potentially have that issue type.',
    modelPerformanceSummary:
      'To analyze your Dataset, Cleanlab automatically trains the most appropriate ML (and Foundation) models. This table summarizes the performance of the best model on held-out data (evaluated via cross-validation). Use the "Deploy Model" feature on the Cleanset page to deploy this best model for making predictions on future data.',
    modelPerformanceSummaryByClass:
      'This table shows the performance of the best model on held-out data, broken down by class.',
    sourcesWithMostIssues:
      'This chart displays the most problematic column values from your selected column.',
    sourcesWithLeastIssues:
      'This chart displays the least problematic column values from your selected column.',
    sourcesWithMostUnlabeled:
      'This chart displays the column values with the most unlabeled datapoints.',
    sourcesWithMostWellLabeled:
      'This chart displays the column values that have the largest percentage of well-labeled data (likely to be accurately labeled) amongst data labeled by that source.',
  },
} as const

export const getRankedChartsClass = (rankedCharts: RankedChart[], optionalChartsReady: boolean) => {
  let firstRowClass = ''
  let secondRowClass = ''

  // If no optional charts are enabled, remove grid template areas for optional charts
  if (rankedCharts.length === 0 || !optionalChartsReady) {
    return `${styles.chartsContainer} ${styles.noOptionalCharts}`
  }

  // If all optional charts are enabled, no change
  if (rankedCharts.length === 4) {
    return styles.chartsContainer
  }

  // if only one of Worst/Best performers enabled, expand chart
  if (
    (rankedCharts.includes(RankedChart.WorstPerformers) &&
      !rankedCharts.includes(RankedChart.BestPerformers)) ||
    (rankedCharts.includes(RankedChart.BestPerformers) &&
      !rankedCharts.includes(RankedChart.WorstPerformers))
  ) {
    firstRowClass = styles.firstRowExpanded
  }

  // if only one of Most Unlabeled or Most Well Labeled is enabled, expand chart
  if (
    (rankedCharts.includes(RankedChart.MostUnlabeled) &&
      !rankedCharts.includes(RankedChart.MostWellLabeled)) ||
    (rankedCharts.includes(RankedChart.MostWellLabeled) &&
      !rankedCharts.includes(RankedChart.MostUnlabeled))
  ) {
    secondRowClass = styles.secondRowExpanded
  }

  if (firstRowClass && secondRowClass) {
    return `${styles.chartsContainer} ${styles.bothRowsExpanded}`
  }

  return `${styles.chartsContainer} ${firstRowClass} ${secondRowClass}`
}

export const generateSuggestedLabelIssuesData = (data: LabelIssuesDataType): BarDatum[] => {
  return data.all
    .map((total, index) => {
      // total is [labelName, totalLabelCount]
      const label = (total[0] ?? '').toString()
      const totalCount = total[1] || 0
      // issue array has format [[labelName, labelIssueCount], ...]
      const issueCount = data.issue[index][1]
      return {
        label: label,
        issues: issueCount,
        correct: totalCount - issueCount,
        total: totalCount,
      }
    })
    .filter((item) => item.issues > 0) // only display rows for non-zero issue counts
    .sort((a, b) => a.issues - b.issues) // order by total issues
    .slice(-5) // show top 5 results (this is a summary)
}

// Creates bar data for the best and worst performers charts
export const generateIssuesByColumn = (data: ErrorRank[]): BarDatum[] => {
  return data.map((errorRank) => {
    return {
      label: errorRank.sourceValue,
      'label issue': errorRank.labelIssue ?? 0,
      outlier: errorRank.outlier ?? 0,
      unlabeled: errorRank.unlabeled ?? 0,
      ambiguous: errorRank.ambiguous ?? 0,
      'near duplicate': errorRank.nearDuplicate ?? 0,
    }
  })
}

// Creates bar data for the most unlabeled and most well-labeled charts
export const generateLabeledRank = (data: ErrorRank[], countType: string): BarDatum[] => {
  return data.map((labeledRank) => {
    return {
      label: labeledRank.sourceValue,
      count: countType === ISSUE_TYPE.UNLABELED ? labeledRank.unlabeled : labeledRank.wellLabeled,
    }
  })
}

export const generateCorrectedLabelIssuesData = (
  corrections: Record<string, number>,
  exclusions: Record<string, number>
): BarDatum[] => {
  return Object.keys(corrections)
    .map((key) => {
      const label = key.toString()
      // total is [labelName, totalLabelCount]
      const correctionCount = corrections[label] || 0
      const exclusionCount = exclusions[label] || 0
      return {
        label: label,
        corrections: correctionCount,
        exclusions: exclusionCount,
        total: correctionCount + exclusionCount,
      }
    })
    .filter((item) => item.total > 0) // only display rows for non-zero correction counts
    .sort((a, b) => a.total - b.total) // order by total corrections
    .slice(-5) // show top 5 results (this is a summary)
}

export const generateTopCorrectionsData = (data: CorrectionDistributionType): BarDatum[] => {
  const corrections = data.categories
  const values = data.values
  return (
    corrections
      .map((correction, idx: number) => ({
        // correction is "given->suggested"
        correction,
        given: correction.split('->')[0],
        suggested: correction.split('->')[1],
        corrections: values[idx],
      }))
      // filter out rows representing correcting to the same label as given and rows with no corrections
      .filter((item) => item.given !== item.suggested && item.corrections > 0)
      .slice(-10)
  ) // limit to top 10 corrections (this is a summary)
}

export const generateHeatmapData = (data: string | HeatmapData | null): HeatmapData | null => {
  if (!data) return null
  const parsedData = typeof data === 'string' ? JSON.parse(data) : data
  const filteredData = parsedData.map((row: HeatMapRowType) => {
    // filter out all entries representing correcting to the same label as given
    return {
      ...row,
      data: row.data.map((col) =>
        col.x.toString() === row.id.toString() ? { ...col, y: null } : col
      ),
    }
  })
  return filteredData
}

export const CHART_BAR_BORDER_RADIUS = 4

// TODO: Use consts for these values
export const CHART_COLOR_GRADIENT = [
  '#014636',
  '#016C59',
  '#02818A',
  '#3690C0',
  '#67A6CF',
  '#A6BDDB',
  '#D0D1E6',
  '#ECE2F0',
  '#FFF7FB',
]

export const CHART_DEFAULT_BAR_COLOR = CHART_COLOR_GRADIENT[2]

export const BASE_FONT_SIZE = 12
export const CHART_GAP_SIZE = 16
export const CHART_TEXT_LINE_HEIGHT = BASE_FONT_SIZE * 1.5
export const CHART_HEADING_FONT_SIZE = BASE_FONT_SIZE * 1.5
export const CHART_HEADING_LINE_HEIGHT = CHART_HEADING_FONT_SIZE * 1.5

// useful value because the chart will place text elements with their middle point centered at a given coordinate
export const CHART_TEXT_OFFSET = BASE_FONT_SIZE / 2

export const BAR_BORDER_RADIUS = 4

export const CHART_BASE_MARGINS = {
  top: CHART_TEXT_OFFSET,
  right: CHART_TEXT_OFFSET,
  bottom: CHART_TEXT_OFFSET,
  left: CHART_TEXT_LINE_HEIGHT,
}

// used in different spots to offset the fixed top header in the print styles
// keep in sync with var in global index.css file
export const CHART_SECTION_TOP_PADDING_IN_PRINT_IN_REM = 8

export const CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM =
  CHART_TEXT_OFFSET + CHART_TEXT_LINE_HEIGHT + CHART_TEXT_OFFSET

export const CHART_BOTTOM_LEGEND_OFFSET = -(CHART_TEXT_OFFSET + CHART_HEADING_LINE_HEIGHT)

export const returnLongestLabelLength = (arrayOfLabels: string[]) =>
  Math.max(...arrayOfLabels.map((v) => (v ? v.length : 0)))

export const calculateChartMarginLeftAndLeftLegendOffset = (
  lengthToAccommodate: number,
  maximumLength = 12
) => {
  lengthToAccommodate = Math.min(lengthToAccommodate, maximumLength)

  const marginLeft =
    CHART_TEXT_OFFSET +
    CHART_TEXT_LINE_HEIGHT +
    lengthToAccommodate * BASE_FONT_SIZE +
    CHART_GAP_SIZE +
    CHART_TEXT_OFFSET
  const legendOffset = -marginLeft + CHART_TEXT_LINE_HEIGHT

  return [marginLeft, legendOffset]
}

export const CHART_MARGIN_LEFT = CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM - CHART_TEXT_OFFSET

export const CHART_LEFT_LEGEND_OFFSET = CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM - CHART_TEXT_OFFSET

export const getLabelTextColor = (isLightMode: boolean) =>
  isLightMode ? cleanlabColors.neutral[0] : cleanlabColors.neutralDarkMode[900]

export const getRGBValues = (isLightMode: boolean, value: number, maxValue: number) => {
  const curValMultiplier = isLightMode ? 1 - value / maxValue : 1 + value / maxValue
  const r = (isLightMode ? 219 : 0) * curValMultiplier * 0.8 * (isLightMode ? 1 : 1.5)
  const g = (isLightMode ? 239 : 75) * curValMultiplier * 0.9 * (isLightMode ? 1 : 1.5)
  const b = (isLightMode ? 254 : 154) * (isLightMode ? 1 : curValMultiplier)
  return { r: r, g: g, b: b }
}

export const getChartTheme = (isLightMode: boolean) => ({
  labels: {
    text: {
      lineHeight: BASE_FONT_SIZE,
      letterSpacing: 0.5,
      fontWeight: 400,
      fontSize: BASE_FONT_SIZE,
      fill: isLightMode ? cleanlabColors.neutral[700] : cleanlabColors.neutralDarkMode[700],
    },
  },
  grid: {
    line: {
      stroke: isLightMode ? cleanlabColors.neutral[300] : cleanlabColors.neutralDarkMode[300],
      strokeWidth: 1,
    },
  },
  axis: {
    ticks: {
      line: {
        stroke: isLightMode ? cleanlabColors.neutral[300] : cleanlabColors.neutralDarkMode[300],
      },
      text: {
        fontSize: BASE_FONT_SIZE,
        fill: isLightMode ? cleanlabColors.neutral[700] : cleanlabColors.neutralDarkMode[700],
      },
    },
    legend: {
      text: {
        fontSize: BASE_FONT_SIZE,
        fontWeight: 700,
        fill: isLightMode ? cleanlabColors.neutral[800] : cleanlabColors.neutralDarkMode[800],
      },
    },
  },
  tooltip: {
    chip: {
      borderRadius: '50%',
    },
    container: {
      background: isLightMode ? cleanlabColors.neutral[900] : cleanlabColors.neutralDarkMode[100],
      color: isLightMode ? cleanlabColors.neutral[0] : cleanlabColors.neutralDarkMode[900],
      fontSize: BASE_FONT_SIZE,
      padding: '4px 12.75px',
      borderRadius: 6,
      boxShadow: '0px 2px 6px 0px #17191C1A, 0px 1px 2px -1px #17191C14',
      letterSpacing: 0.5,
    },
  },
})
