import { Tooltip } from '@components/tooltip/Tooltip'

import testIds from '../../playwright/test_ids.json'
import { getSortIndicator } from './ColumnHeaderTooltip.helpers'
import { ColumnHeaderTooltipProps } from './ColumnHeaderTooltip.types'

const ColumnHeaderTooltip = ({
  headerName,
  tooltipLabel,
  disableSorting,
  setSort,
  column,
}: ColumnHeaderTooltipProps) => {
  return (
    <Tooltip
      content={
        <div className="flex flex-col gap-2 text-center">
          <p>{tooltipLabel}</p>
          {!disableSorting && <p>Click to Sort by Values</p>}
        </div>
      }
    >
      <button
        className="flex h-full w-full cursor-pointer items-center justify-between text-start"
        data-testid={testIds.cleansetColumnHeaderTooltip}
        onClick={() => {
          if (!disableSorting) {
            setSort(column.getSort() === 'desc' ? 'asc' : 'desc')
          }
        }}
      >
        <p className="custom-header-tooltip line-clamp-2 w-full text-wrap">{headerName}</p>
        {getSortIndicator(column)}
      </button>
    </Tooltip>
  )
}

export default ColumnHeaderTooltip
