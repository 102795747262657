function debounce(function_: (...args: any[]) => any, wait = 250, leading = false) {
  let storedContext: undefined
  let storedArguments: any[]
  let timeoutId: string | number | NodeJS.Timeout | undefined
  let timestamp: number
  let result: any

  function run() {
    const callContext = storedContext
    const callArguments = storedArguments
    storedContext = undefined
    storedArguments = []
    result = function_.apply(callContext, callArguments)
    return result
  }

  function later() {
    const last = Date.now() - timestamp

    if (last < wait && last >= 0) {
      timeoutId = setTimeout(later, wait - last)
    } else {
      timeoutId = undefined

      if (!leading) {
        result = run()
      }
    }
  }

  const debounced = function (this: any, ...arguments_: any[]) {
    if (storedContext && this !== storedContext) {
      throw new Error('Debounced method called with different contexts.')
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    storedContext = this
    storedArguments = arguments_
    timestamp = Date.now()

    const callNow = leading && !timeoutId

    if (!timeoutId) {
      timeoutId = setTimeout(later, wait)
    }

    if (callNow) {
      result = run()
    }

    return result
  }

  debounced.clear = () => {
    if (!timeoutId) {
      return
    }

    clearTimeout(timeoutId)
    timeoutId = undefined
  }

  debounced.flush = () => {
    if (!timeoutId) {
      return
    }

    debounced.trigger()
  }

  debounced.trigger = () => {
    result = run()

    debounced.clear()
  }

  return debounced
}

export default debounce
