import { Button } from '@components/button/Button'
import { Kbd } from '@components/kbd/Kbd'

interface MultiSelectDisplayProps {
  numRows: number
  onClearAll: () => void
}

const MultiSelectDisplay = (props: MultiSelectDisplayProps) => {
  const { numRows, onClearAll } = props
  return (
    <div className="flex w-full justify-between border-b border-border-0 bg-surface-0 p-5">
      <div className="flex flex-col gap-3">
        <p className="type-caption-medium text-text-primary">Batch action</p>
        <p className="type-caption">{numRows} selected rows</p>
      </div>
      <Button variant="secondary" size="small" onClick={onClearAll} iconEnd={<Kbd>C</Kbd>}>
        Clear all
      </Button>
    </div>
  )
}

export default MultiSelectDisplay
