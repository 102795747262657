import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Box, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import Link from '@components/link/Link'
import React from 'react'
import { FiAlertTriangle } from 'react-icons/fi'

import { DatasetRowsLimitFloaterProps } from './DatasetRowsLimitFloater.types'

const DatasetRowsLimitFloater = ({
  datasetRowsLimit,
  freeTrialPeriod,
}: DatasetRowsLimitFloaterProps) => {
  const textColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const bgColor = useColorModeValue('neutral.50', 'neutralDarkMode.50')
  const borderColor = useColorModeValue('neutral.300', 'neutralDarkMode.300')
  const boxShadowColor = useColorModeValue(
    '0px 2px 3px 0px rgba(23, 25, 28, 0.04), 0px 1px 1px -1px rgba(23, 25, 28, 0.08)',
    '0px 2px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 2px -1px rgba(243, 246, 247, 0.16)'
  )

  return (
    <Box
      bg={bgColor}
      w="100%"
      padding="12px 16px"
      border="1px solid"
      borderRadius="12px"
      borderColor={borderColor}
      boxShadow={boxShadowColor}
      marginBottom="8px"
      justifyContent="center"
      alignItems="center"
    >
      <HStack spacing="8px" pt="5px" pb="5px" alignItems="flex-start">
        <FiAlertTriangle
          style={{ color: cleanlabColors.yellow[800], minWidth: 16, minHeight: 16, marginTop: 2 }}
          size={16}
        />
        <Text fontSize="14px" lineHeight="20px" fontWeight={400} color={textColor} pr="8px">
          {freeTrialPeriod ? 'The free trial' : 'Your current plan'} only supports Datasets up to{' '}
          {datasetRowsLimit} rows. The first {datasetRowsLimit} rows of your Dataset were uploaded.
          Email:{' '}
          <Link href="https://cleanlab.ai/sales/" isExternal>
            sales@cleanlab.ai
          </Link>{' '}
          to upgrade your plan.
        </Text>
      </HStack>
    </Box>
  )
}

export default DatasetRowsLimitFloater
