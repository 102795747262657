import { DatasetDetailsPageInfoProps, DatasetFieldTypesPropsV1 } from '@services/datasetApi'
import { GridApi, SideBarDef } from 'ag-grid-community'
import { NormalizedEnrichmentJobPreviewData } from 'src/pages/enrichmentProject/enrichmentUtils'

export interface DatasetTableDetailsProps {
  datasetId: string
  datasetDetails: DatasetDetailsPageInfoProps
  fieldTypes?: DatasetFieldTypesPropsV1
  enrichmentData?: NormalizedEnrichmentJobPreviewData[]
  isFullyEnriched?: boolean
  mostRecentJobId?: string
  refreshData: (datasetId: string) => Promise<void>
  showDataWarnings: boolean
  showDatasetRowsLimit: boolean
  setSchemaUpdateInProgress?: (inProgress: boolean) => void
  setGridApi?: (gridApi: GridApi) => void
}

export const datasetSideBar = {
  position: 'left',
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressPivotMode: true,
        suppressValues: true,
        suppressRowGroups: true,
      },
    },
  ],
  defaultToolPanel: '',
} as SideBarDef
