import {
  Box,
  Button,
  Center,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  RadioGroup,
  Spinner,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import SecondaryButtonWithIcon from '@components/buttons/secondaryButtonWithIcon/SecondaryButtonWithIcon'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useColumnValues, useIssueColumns } from '@services/datasheet/queries'
import { ColDef } from 'ag-grid-community'
import { CSSProperties, useCallback, useContext, useMemo, useState } from 'react'
import { FiChevronDown, FiX } from 'react-icons/fi'
import { FixedSizeList } from 'react-window'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import { CleansetContext } from '../../CleansetContext'
import { getBackendColName } from '../../datasheet/Datasheet.helpers'
import { CLEANLAB_FRONTEND_COLUMN } from '../../datasheet/Datasheet.types'
import {
  CleansetFilterActions,
  FilterData,
  FilterOperator,
  PRESET_FILTER_PROPERTIES,
  PresetHeaders,
} from '../../filterReducer/FilterState.types'
import { useFilters } from '../../filterReducer/useFilters'
import { getHeaderName, shouldUseBooleanFilterOptions } from '../FilterMenu.helpers'
import {
  CLEANLAB_FRONTEND_CUSTOM_METADATA_COLUMNS,
  CLEANLAB_FRONTEND_CUSTOM_TAGS,
  CLEANLAB_FRONTEND_ISSUE_COLUMNS,
  getFilterLabel,
  getFilterValue,
  issueColumnText,
  issueToFrontendColumn,
  numberOptions,
  textOptions,
} from './EditFilterMenu.helpers'
import { EditFilterMenuOptions, EditFilterMenuProps } from './EditFilterMenu.types'
import FilterCheckboxButton from './filterCheckboxButton/FilterCheckboxButton'
import FilterRadioButton from './filterRadioButton/FilterRadioButton'

const EditFilterMenu = (props: EditFilterMenuProps) => {
  const {
    gridOptions,
    selectedProperty,
    presetProperties,
    isOpen,
    filterData,
    cleansetId,
    isMultilabel,
    labelsIndexed,
    firstGridDataRendered,
    resetResolverAndRowSelection,
  } = props

  const { state, dispatch } = useFilters()

  const { trackEvent } = useEventTracking()

  const { filterType, filterInput } = filterData
  const { agGridType } = filterData
  const [searchInput, setSearchInput] = useState('')

  const colDefs = (gridOptions.columnDefs ?? []) as ColDef[]
  const defaultProperty = colDefs.find((e) => e.headerName === PresetHeaders.Given)?.field
  const isGivenLabel = selectedProperty === defaultProperty
  const isPresetColumn = isGivenLabel || PRESET_FILTER_PROPERTIES.includes(selectedProperty)
  const isNumber = filterData.agGridType === 'agNumberColumnFilter'

  const grayColors = useColorModeValue('neutral.200', 'neutralDarkMode.200')
  const menuBg = useColorModeValue('white', 'neutralDarkMode.100')

  const { data: issueColumns } = useIssueColumns(cleansetId, firstGridDataRendered)
  const { tasktype } = useContext(CleansetContext)

  const { data: colVals, isLoading } = useColumnValues(
    cleansetId,
    getBackendColName(selectedProperty),
    isOpen &&
      isPresetColumn &&
      !CLEANLAB_FRONTEND_ISSUE_COLUMNS.includes(selectedProperty) &&
      !isMultilabel &&
      tasktype !== Tasktype.UNSUPERVISED,
    true
  )

  const searchColVals = colVals.filter(
    (e) => e && `${e}`.toLowerCase().includes(searchInput.toLowerCase())
  )

  const fullLabelKeys = useMemo(() => {
    const fullLabels = { 'All examples': -2, 'No label': -1, ...labelsIndexed }
    return Object.keys(fullLabels)
  }, [labelsIndexed])

  const applyIssueFilter = (filterInput: string, isChecked: boolean) => {
    if (isChecked) {
      resetResolverAndRowSelection()
      dispatch({
        type: CleansetFilterActions.APPLY_FILTER,
        filterData: {
          property: issueToFrontendColumn[filterInput],
          filterType: FilterOperator.Equals,
          filterInput: 'true',
          headerName: getHeaderName(issueToFrontendColumn[filterInput], false),
          agGridType: filterData.agGridType,
          isOpen: false,
          isPreset: true,
          isChecked: true,
          hide: true,
        },
      })
    } else {
      dispatch({
        type: CleansetFilterActions.REMOVE_FILTER,
        filterProperty: issueToFrontendColumn[filterInput],
      })
    }
  }

  const applyMultilabelTagFilter = (filterInput: string, isChecked: boolean) => {
    const filterType = isChecked
      ? CleansetFilterActions.ADD_TAG_FILTER
      : CleansetFilterActions.REMOVE_TAG_FILTER
    const filterProperty = isGivenLabel ? CLEANLAB_FRONTEND_COLUMN.GIVEN : selectedProperty
    dispatch({
      type: filterType,
      filterData: {
        property: filterProperty,
        filterType: FilterOperator.All,
        filterInput: filterInput,
        headerName: getHeaderName(selectedProperty, isGivenLabel),
        agGridType: filterData.agGridType,
        isOpen: false,
        isPreset: false,
      },
    })
  }

  const isFilterChecked = (allFilterData: FilterData[], issueColumn: string): boolean => {
    const currentFilterData = allFilterData.filter(
      (f) => f.property === issueToFrontendColumn[issueColumn]
    )
    if (currentFilterData.length > 0) {
      return currentFilterData[0].isChecked ?? false
    }
    return false
  }

  const isMultilabelFilterChecked = (
    allFilterData: FilterData[],
    label: string,
    isGivenLabel: boolean
  ): boolean => {
    const currentFilter = isGivenLabel
      ? CLEANLAB_FRONTEND_COLUMN.GIVEN
      : CLEANLAB_FRONTEND_COLUMN.SUGGESTED
    const currentFilterData = allFilterData.find((filter) => filter.property === currentFilter)
    const currentFilterInput = currentFilterData?.filterInput
    if (!Array.isArray(currentFilterInput)) {
      return false
    } else {
      return label === 'all' ? currentFilterInput.length === 0 : currentFilterInput.includes(label)
    }
  }

  const applyFilters = useCallback(
    (filterInput: string | number | string[], filterType: FilterOperator) => {
      if (selectedProperty.length && !CLEANLAB_FRONTEND_ISSUE_COLUMNS.includes(selectedProperty)) {
        if (!filterInput) {
          dispatch({
            type: CleansetFilterActions.REMOVE_FILTER,
            filterProperty: selectedProperty,
          })
        }
        const applyFilterData = {
          property: isGivenLabel ? CLEANLAB_FRONTEND_COLUMN.GIVEN : selectedProperty,
          filterType: filterType === FilterOperator.All ? FilterOperator.Equals : filterType,
          filterInput: filterInput,
          headerName: getHeaderName(selectedProperty, isGivenLabel),
          agGridType,
          isOpen: true,
          isPreset: false,
          isChecked: false,
        }

        resetResolverAndRowSelection()
        dispatch({
          type: CleansetFilterActions.APPLY_FILTER,
          filterData: applyFilterData,
        })
        trackEvent(MixpanelEvents.applyExistingFilter, { ...applyFilterData })
      }
    },
    [selectedProperty, isGivenLabel, agGridType, resetResolverAndRowSelection, dispatch, trackEvent]
  )

  const generateValueOptions = () => {
    if (isMultilabel && (isGivenLabel || selectedProperty === CLEANLAB_FRONTEND_COLUMN.SUGGESTED)) {
      return (
        <div className="overflow-hidden rounded-3 border border-border-1">
          {labelsIndexed !== null && (
            <FixedSizeList
              direction="vertical"
              height={Math.min(window.innerHeight * 0.3, 31 * fullLabelKeys.length)}
              itemCount={fullLabelKeys.length}
              itemSize={30}
              width={300}
            >
              {({ index, style }: { index: number; style: CSSProperties }) => (
                <div style={style} key={index} className="p-4">
                  {index === 0 ? (
                    <FilterCheckboxButton
                      filterInput="All examples"
                      text="All examples"
                      applyCheckboxFilter={applyMultilabelTagFilter}
                      filterChecked={isMultilabelFilterChecked(
                        state.filterData,
                        'all',
                        isGivenLabel
                      )}
                      isDisabled
                      isTag={false}
                    />
                  ) : index === 1 ? (
                    <FilterCheckboxButton
                      filterInput="_empty"
                      text="No label"
                      applyCheckboxFilter={applyMultilabelTagFilter}
                      filterChecked={isMultilabelFilterChecked(
                        state.filterData,
                        '_empty',
                        isGivenLabel
                      )}
                      isDisabled={false}
                      isTag={false}
                    />
                  ) : (
                    <FilterCheckboxButton
                      filterInput={fullLabelKeys[index]}
                      text={`${index - 2} ${fullLabelKeys[index]}`}
                      applyCheckboxFilter={applyMultilabelTagFilter}
                      filterChecked={isMultilabelFilterChecked(
                        state.filterData,
                        fullLabelKeys[index],
                        isGivenLabel
                      )}
                      isDisabled={false}
                      isTag={false}
                    />
                  )}
                </div>
              )}
            </FixedSizeList>
          )}
        </div>
      )
    } else if (
      isPresetColumn &&
      selectedProperty !== CLEANLAB_FRONTEND_COLUMN.ISSUE &&
      selectedProperty !== CLEANLAB_FRONTEND_COLUMN.OUTLIER &&
      selectedProperty !== CLEANLAB_FRONTEND_COLUMN.UNLABELED &&
      selectedProperty !== CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS
    ) {
      // Preset filters minus Issue
      return (
        <Menu>
          <Box my="0.5rem">
            <MenuButton
              w="100%"
              defaultValue={EditFilterMenuOptions.EQUALS}
              as={Button}
              textAlign="left"
              rightIcon={<FiChevronDown />}
            >
              {filterType
                ? getFilterLabel(filterType, false)
                : filterData && filterData.filterType
                  ? getFilterLabel(filterData.filterType, false)
                  : EditFilterMenuOptions.EQUALS}
            </MenuButton>
          </Box>
          <MenuList bg={menuBg} defaultValue={EditFilterMenuOptions.EQUALS} zIndex={10}>
            {[EditFilterMenuOptions.EQUALS, EditFilterMenuOptions.NOT_EQUAL].map((e) => (
              <MenuItem
                key={e}
                fontSize="md"
                onClick={(ev) => {
                  applyFilters(filterInput, getFilterValue(e, false))
                  ev.stopPropagation()
                }}
              >
                {e}
              </MenuItem>
            ))}
          </MenuList>
          <Input
            mt={1}
            placeholder="Search"
            onKeyDown={(evt) => evt.stopPropagation()}
            onChange={(evt) => setSearchInput(evt.target.value)}
          />
          <Box mt={2} px={0.5} border="2px solid" borderColor={grayColors} borderRadius="xl">
            <Stack>
              {isLoading && (
                <Center h={150}>
                  <Spinner />
                </Center>
              )}
              {!isLoading && searchColVals.length > 0 && (
                <RadioGroup zIndex={5} pt={0.5} value={filterInput ? filterInput.toString() : ''}>
                  <FixedSizeList
                    height={Math.min(window.innerHeight * 0.3, 31 * searchColVals.length)}
                    itemCount={searchColVals.length}
                    itemSize={30}
                    width={300}
                  >
                    {({ index, style }) => (
                      <Box style={style}>
                        <FilterRadioButton
                          key={searchColVals[index]?.toString()}
                          text={searchColVals[index]?.toString()}
                          isTag={selectedProperty === CLEANLAB_FRONTEND_COLUMN.ACTION}
                          onSelect={() => {
                            applyFilters(searchColVals[index].toString(), filterType)
                          }}
                        />
                      </Box>
                    )}
                  </FixedSizeList>
                </RadioGroup>
              )}
              {!isLoading && searchColVals.length < 1 && (
                <Center color="gray.300" py={1} fontSize="sm" fontStyle="italic">
                  No results
                </Center>
              )}
            </Stack>
          </Box>
        </Menu>
      )
    } else if (selectedProperty === CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS) {
      return (
        <Box
          pb={1}
          px={0.5}
          border="2px solid"
          borderColor={grayColors}
          borderRadius="xl"
          overflow="auto"
          maxH="30vh"
        >
          <RadioGroup zIndex={5} value={filterInput ? filterInput.toString() : ''} pt={1}>
            {CLEANLAB_FRONTEND_CUSTOM_TAGS.map((e) => (
              <FilterRadioButton
                isTag={CLEANLAB_FRONTEND_CUSTOM_TAGS.includes(e)}
                key={e}
                text={e}
                onSelect={() => {
                  applyFilters(e, FilterOperator.Equals)
                }}
              />
            ))}
          </RadioGroup>
        </Box>
      )
    } else if (selectedProperty === CLEANLAB_FRONTEND_COLUMN.ISSUE_TAGS) {
      const noIssueFiltersApplied = state.filterData.every((filter) => !filter.isChecked)
      return (
        <Box
          pt={2}
          pb={2}
          px={0.5}
          border="2px solid"
          borderColor={grayColors}
          borderRadius="xl"
          overflow="auto"
          maxH="30vh"
        >
          <div className="flex flex-col gap-5 px-5">
            <FilterCheckboxButton
              key="all_examples"
              filterInput="all_examples"
              text="all_examples"
              applyCheckboxFilter={applyIssueFilter}
              filterChecked={noIssueFiltersApplied}
              isDisabled
              isTag
            />
            {issueColumns.map((issueColumn) => (
              <FilterCheckboxButton
                key={issueColumn}
                filterInput={issueColumn}
                text={issueColumnText[issueColumn]}
                applyCheckboxFilter={applyIssueFilter}
                filterChecked={isFilterChecked(state.filterData, issueColumn)}
                isDisabled={false}
                isTag
              />
            ))}
          </div>
        </Box>
      )
    } else if (shouldUseBooleanFilterOptions(selectedProperty)) {
      return (
        <Box border="2px solid" borderColor={grayColors} borderRadius="xl">
          <RadioGroup zIndex={5} value={filterInput ? filterInput.toString() : ''}>
            <Stack py={2}>
              {['true', 'false'].map((e) => (
                <FilterRadioButton
                  key={e}
                  text={e}
                  onSelect={() => {
                    applyFilters(e, FilterOperator.Equals)
                  }}
                />
              ))}
            </Stack>
          </RadioGroup>
        </Box>
      )
    } else if (CLEANLAB_FRONTEND_CUSTOM_METADATA_COLUMNS.includes(selectedProperty)) {
      return (
        <Menu>
          <Box my="0.5rem">
            <MenuButton w="100%" as={Button} textAlign="left" rightIcon={<FiChevronDown />}>
              {filterType
                ? getFilterLabel(filterType, isNumber)
                : filterData && filterData.filterType
                  ? getFilterLabel(filterData.filterType, isNumber)
                  : EditFilterMenuOptions.EQUALS}
            </MenuButton>
          </Box>
          <MenuList bg={menuBg}>
            {[EditFilterMenuOptions.EQUALS].map((e) => (
              <MenuItem
                key={e}
                fontSize="md"
                onClick={() => applyFilters(filterInput, getFilterValue(e, isNumber))}
              >
                {e}
              </MenuItem>
            ))}
          </MenuList>
          <Box pb={1} px={0.5}>
            <Input
              disabled={false}
              name="filterValueInput"
              value={filterInput}
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
              onChange={(val) => applyFilters(val.target.value, filterType)}
            />
          </Box>
        </Menu>
      )
    } else {
      // Quality + any other columns that aren't Cleanlab generated
      return (
        <Menu>
          <Box my="0.5rem">
            <MenuButton
              w="100%"
              defaultValue={isNumber ? 'Equals' : 'Contains'}
              as={Button}
              textAlign="left"
              rightIcon={<FiChevronDown />}
            >
              {filterType
                ? getFilterLabel(filterType, isNumber)
                : filterData && filterData.filterType
                  ? getFilterLabel(filterData.filterType, isNumber)
                  : isNumber
                    ? 'Equals'
                    : 'Contains'}
            </MenuButton>
          </Box>
          <MenuList bg={menuBg} defaultValue={isNumber ? 'equals' : 'contains'}>
            {(isNumber ? numberOptions : textOptions).map((e) => (
              <MenuItem
                key={e}
                fontSize="md"
                onClick={() => applyFilters(filterInput, getFilterValue(e, isNumber))}
              >
                {e}
              </MenuItem>
            ))}
          </MenuList>
          <Box pb={1} px={0.5}>
            <Input
              disabled={false}
              name="filterValueInput"
              value={filterInput}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(val) => applyFilters(val.target.value, filterType)}
            />
          </Box>
        </Menu>
      )
    }
  }

  return (
    <MenuList bg={menuBg} minWidth="300px" borderRadius="2xl" zIndex="overlay">
      <Box margin="4px 12px">
        <RadioGroup fontSize="lg" mt={0}>
          {presetProperties ? <>{generateValueOptions()}</> : <></>}
        </RadioGroup>
        {selectedProperty !== CLEANLAB_FRONTEND_COLUMN.ISSUE_TAGS && (
          <Box mt={2}>
            <SecondaryButtonWithIcon
              height="32px"
              rightIcon={<FiX />}
              onClick={() => {
                dispatch({
                  type: CleansetFilterActions.REMOVE_FILTER,
                  filterProperty: isGivenLabel ? CLEANLAB_FRONTEND_COLUMN.GIVEN : selectedProperty,
                })
                trackEvent(MixpanelEvents.clickClearSingleFilterButton, {
                  cleansetId: cleansetId,
                  filterProperty: isGivenLabel ? CLEANLAB_FRONTEND_COLUMN.GIVEN : selectedProperty,
                })
              }}
            >
              Clear
            </SecondaryButtonWithIcon>
          </Box>
        )}
      </Box>
    </MenuList>
  )
}

export default EditFilterMenu
