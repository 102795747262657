import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useBetaFeatureButton } from '@common/misc/betaFeatureButton/useBetaFeatureButton'
import { Button } from '@components/button/Button'
import { LabelInline } from '@components/label/LabelInline'
import { RadioGroup, RadioGroupItem } from '@components/radioGroup/RadioGroup'
import { useState } from 'react'

import BetaFeatureModal from '../BetaFeatureModal'
import { OtherMlTasksModalProps } from './OtherMLTasksModal.types'

const OtherMLTasksModal = (props: OtherMlTasksModalProps) => {
  const { isOpen, onClose } = props

  const [value, setValue] = useState('')

  const betaModalOpen = useBetaFeatureButton((value as string | undefined) ?? '')

  return betaModalOpen.isOpen ? (
    <BetaFeatureModal
      {...betaModalOpen}
      onClose={() => {
        betaModalOpen.onCloseModal()
        onClose()
      }}
    />
  ) : (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enterprise ML tasks</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-6">
          <p className="type-body-200-medium text-text-primary">
            Select the Machine Learning Task that you would like to find label errors in:
          </p>
          <RadioGroup
            className="flex flex-col gap-7"
            value={(value as string | undefined) ?? ''}
            onValueChange={setValue}
          >
            <LabelInline content="Named Entity Recognition">
              <RadioGroupItem value="Named entity recognition"></RadioGroupItem>
            </LabelInline>
            <LabelInline content="Object Detection">
              <RadioGroupItem value="Object Detection"></RadioGroupItem>
            </LabelInline>
            <LabelInline content="Image Segmentation">
              <RadioGroupItem value="Image Segmentation"></RadioGroupItem>
            </LabelInline>
            <LabelInline content="Regression">
              <RadioGroupItem value="Regression"></RadioGroupItem>
            </LabelInline>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <div className="flex flex-row-reverse gap-5">
            <Button
              variant="highContrast"
              disabled={!value}
              onClick={() => betaModalOpen.onButtonClick()}
            >
              Submit
            </Button>
            <Button variant="secondary" onClick={onClose} aria-label="close">
              Close
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default OtherMLTasksModal
