import { NameCellComponentProps } from '@common/misc/nameCellComponent/NameCellComponent.types'
import { Tooltip } from '@components/tooltip/Tooltip'
import { cn } from '@utils/tailwindUtils'
import { ComponentPropsWithoutRef } from 'react'
import { Link } from 'react-router-dom'

import EditButton from '../editButton/EditButton'
import { CopyIdTag } from './CopyIdTag'

const TruncateWithTooltip = ({
  children,
  className,
  to,
}: ComponentPropsWithoutRef<'span'> & { to?: string }) => {
  let content
  const baseClassName = 'truncate focus-visible:outline-none'
  if (to) {
    content = (
      <Link
        className={cn(
          baseClassName,
          'text-blue-700 hover:text-blue-800 hover:underline focus-visible:underline',
          className
        )}
        to={to}
      >
        {children}
      </Link>
    )
  } else {
    content = (
      <span tabIndex={0} className={cn(baseClassName, className)}>
        {children}
      </span>
    )
  }

  return (
    <Tooltip content={children} placement="top-start">
      {content}
    </Tooltip>
  )
}

const NameCellComponent = (props: NameCellComponentProps) => {
  const { name, id, dataTestId, onEditClick, to } = props

  return (
    <div
      className="group flex h-full w-full items-center justify-between gap-3"
      data-testid={dataTestId}
    >
      <TruncateWithTooltip to={to}>{name}</TruncateWithTooltip>
      <div className="align flex flex-1 flex-row items-center justify-between gap-2">
        <div className="group-focus-within:hidden group-hover:hidden" />
        {onEditClick ? <EditButton name={name} onEditClick={onEditClick} /> : <div />}
        <CopyIdTag id={id} />
      </div>
    </div>
  )
}

export default NameCellComponent
