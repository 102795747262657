import React from 'react'

import { DatasheetColumnStatuses } from './Cleanset.types'

export const CleansetContext = React.createContext<{
  projectId: string | null
  datasetId: string | null
  cleansetId: string | null
  modality: string | null
  tasktype: string | null
  version: number | null
  columnStatuses?: DatasheetColumnStatuses
  datasetColumns?: string[]
}>({
  projectId: null,
  datasetId: null,
  cleansetId: null,
  modality: null,
  tasktype: null,
  version: null,
  columnStatuses: undefined,
  datasetColumns: undefined,
})
