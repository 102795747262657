import TopNavTabs from '@common/layout/topNavTabs/TopNavTabs'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import { DeploymentNavProps } from './DeploymentNav.types'

const DeploymentNav = ({ tabIndex, tasktype }: DeploymentNavProps) => {
  const tabNames =
    tasktype === Tasktype.MULTICLASS
      ? ['Inference Results', 'Model Details', 'API']
      : ['Inference Results', 'API']
  return <TopNavTabs tabNames={tabNames} tabIndex={tabIndex} />
}

export default DeploymentNav
