import { cn } from '@utils/tailwindUtils'

const DEFAULT_SIZE = 32

const CleanlabLogo = ({
  size = DEFAULT_SIZE,
  className,
}: {
  className?: string
  size?: number
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 480 480"
      fill="none"
      className={cn('text-product-cleanlab', className)}
      style={{
        width: size,
        height: size,
      }}
      shapeRendering="geometricPrecision"
    >
      <title>Cleanlab logo</title>
      <g fill="currentColor">
        <path d="m235.418991 127.585051c-9.243912 16.011048-8.50328 35.037512.276643 49.894688 2.221588 3.759525 2.303935 8.406547.120378 12.188481l-33.643022 58.271545c-2.191851 3.796377-6.278358 6.037674-10.661767 5.990798-8.55041-.091483-17.23489 2.031595-25.177121 6.617044-23.64317 13.650391-31.744045 43.882935-18.093655 67.526105 13.650421 23.643223 43.88298 31.743879 67.52615 18.093488 7.942231-4.585449 14.123171-11.044785 18.31915-18.495399 2.151078-3.819634 6.135382-6.237952 10.519036-6.238015l67.286119.000008c4.348738.000009 8.338044 2.363791 10.470194 6.153951 8.717055 15.495842 25.53759 25.820783 44.722027 25.205452 26.410104-.847178 47.711946-22.800914 47.807114-49.224458.098611-27.384376-22.070779-49.61443-49.432127-49.61456-18.48793-.000062-34.595128 10.154676-43.071626 25.186818-2.145182 3.803967-6.128471 6.198653-10.495258 6.198633l-66.07807.000109c-10.468987-.000004-17.012139-11.333076-11.777649-20.399486l33.03913-57.225233c2.174392-3.76607 6.216122-6.039024 10.564502-5.990319 17.778364.198791 35.130322-9.20572 44.189634-26.127812 12.15091-22.697171 4.578221-51.366187-17.203176-65.091209-23.871709-15.042326-55.230559-7.127954-69.206607 17.079371zm-12.438916 157.336025c10.181366 17.634643 4.139266 40.184067-13.49543 50.365464-17.634696 10.181396-40.18409 4.139349-50.365517-13.4954s-4.139266-40.184067 13.49543-50.365464c17.634696-10.181396 40.18409-4.139349 50.365517 13.4954z" />
        <path d="m394.111973 409.04305c-32.396657 22.247613-71.509402 35.423863-113.674716 35.86518-113.445223 1.187357-206.893182-91.059891-207.108293-204.511124-.214932-113.356248 91.612866-205.316674 204.919215-205.316674 42.987943 0 82.884301 13.236895 115.835386 35.857109 1.331054.91374 3.139842-.019876 3.139859-1.634381l.000376-36.616211c.000007-.722243-.3875-1.391773-1.016198-1.747264-34.634746-19.583874-74.620089-30.809901-117.216932-30.938575-132.511304-.400281-240.741499 107.488665-240.74057 240.000575.000929 132.547536 107.45222 239.998315 239.999971 239.998315 42.873982 0 83.122162-11.242165 117.957543-30.939559.628589-.355431 1.016013-1.024802 1.01601-1.74692l-.000161-36.618411c-.000007-1.599342-1.793088-2.557439-3.111489-1.652059z" />
      </g>
    </svg>
  )
}

export { CleanlabLogo }
