import { FormField } from '@components/formField/FormField'
import { Input } from '@components/input/Input'
import { type FormApi } from '@tanstack/react-form'
import { useId } from 'react'

import { checkProjectName, PROJECT_NAME_MAX_LEN } from './CreateProject.helpers'

export const ProjectNameField = <T extends { projectName: string }>({
  form,
}: {
  form: FormApi<T, undefined>
}) => {
  const projectNameId = useId()
  const typecastForm = form as unknown as FormApi<{ projectName: string }, undefined>

  return (
    <typecastForm.Field
      name="projectName"
      validators={{
        onChange: ({ value, fieldApi: { state, setMeta } }) => {
          // Clear onBlur errors when the user starts typing
          if (state.meta.errorMap.onBlur) {
            setMeta((prevMeta) => {
              const { onBlur, ...nextErrorMap } = prevMeta.errorMap
              return { ...prevMeta, errorMap: nextErrorMap }
            })
          }
          if (state.meta.isDirty || state.meta.errors.length) {
            return checkProjectName(value)
          }
          return undefined
        },
        onBlur: ({ value, fieldApi: { state } }) => {
          // Let onChange handle errors unless value hasn't been changed
          if (state.meta.isPristine) {
            return checkProjectName(value)
          }
          return undefined
        },
      }}
    >
      {({ state, handleChange, handleBlur }) => {
        return (
          <FormField
            htmlFor={projectNameId}
            required={true}
            label="New Project name"
            error={state.meta.errors?.[0]}
            info={`${PROJECT_NAME_MAX_LEN - (state.value.length ?? 0)} of ${PROJECT_NAME_MAX_LEN} characters remaining`}
          >
            <Input
              name="projectName"
              value={state.value}
              onChange={(e) => {
                handleChange(e.target.value)
              }}
              onBlur={handleBlur}
              maxLength={PROJECT_NAME_MAX_LEN}
            />
          </FormField>
        )
      }}
    </typecastForm.Field>
  )
}
