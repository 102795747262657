import { Box, Container, Stack, Text, useDisclosure } from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import DeleteRowModal from '@common/modals/deleteRowModal/DeleteRowModal'
import PageTitle from '@components/pageTitle/PageTitle'
import useGridClassname from '@hooks/useGridClassname'
import useIsTabActive from '@hooks/useIsTabActive'
import NonExistentResource from '@providers/errors/NonExistentResource'
import { CleansetRowRes } from '@services/cleanset/constants'
import { useDeleteCleansetMutation } from '@services/cleanset/mutations'
import { useCleansetsFromProject } from '@services/cleanset/queries'
import { useProject } from '@services/project/queries'
import { isUUID4 } from '@utils/functions/isUUID4'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { DASHBOARD_DEFAULT_COLUMN_DEF, DashboardGrid } from '../dashboard/DashboardGrid'
import NonViewableProjectModal from '../dashboard/projectsGrid/nonViewableProjectModal/NonViewableProjectModal'
import { getColumnDefs } from './CleansetVersions.helpers'

export type CleansetTargetRowData = CleansetRowRes & { name: string }

const CleansetVersions = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const isTabActive = useIsTabActive()

  const [idIsInvalid, setIdIsInvalid] = useState(false)

  const project = useProject({
    projectId: projectId ?? '',
    onError: (err) => {
      const statusCode = err.response?.status
      if (statusCode && statusCode >= 400 && statusCode < 500) {
        setIdIsInvalid(true)
      }
    },
  })
  const rowData = useCleansetsFromProject({
    projectId: projectId ?? '',
    useQueryOptions: { enabled: isTabActive && !idIsInvalid },
  })
  const projectName = project?.name ?? ''
  const gridTheme = useGridClassname()
  const numCleansets = useMemo(() => rowData.length, [rowData])

  // delete modal
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const {
    isOpen: isNonViewableProjectModalOpen,
    onOpen: onNonViewableProjectModalOpen,
    onClose: onNonViewableProjectModalClose,
  } = useDisclosure()
  const [targetRowData, setTargetRowData] = useState<CleansetRowRes & { name: string }>()
  const { mutate: deleteCleanset } = useDeleteCleansetMutation(projectId ?? '')

  const handleDelete = useCallback(
    ({ id: cleansetId }: CleansetRowRes) => {
      deleteCleanset({ cleansetId, numCleansets })
    },
    [deleteCleanset, numCleansets]
  )

  const LoadingMessage = useCallback(() => {
    return <Text size="md">Fetching rows...</Text>
  }, [])

  const columnDefs = useMemo(
    () => getColumnDefs({ onNonViewableProjectModalOpen, onDeleteOpen, setTargetRowData }),
    [onDeleteOpen, onNonViewableProjectModalOpen]
  )

  const gridOptions = {
    rowHeight: 45,
    columnDefs: [...columnDefs],
    defaultColDef: DASHBOARD_DEFAULT_COLUMN_DEF,
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  if (!isUUID4(projectId) || idIsInvalid) {
    return (
      <Layout>
        <NonExistentResource errorMessage="No such Project exists." />
      </Layout>
    )
  }

  return (
    <Layout>
      <Container maxWidth="100%">
        <PageTitle>{`Project: ${projectName}`}</PageTitle>
        <Stack pt="16px" align="center" height="40ch">
          {targetRowData && (
            <DeleteRowModal
              isOpen={isDeleteOpen}
              onClose={onDeleteClose}
              rowData={targetRowData}
              deletionType="cleanset"
              numCleansets={numCleansets}
              handleDeleteButtonClicked={handleDelete}
            />
          )}
          {project && isNonViewableProjectModalOpen && (
            <NonViewableProjectModal
              isOpen={isNonViewableProjectModalOpen}
              onClose={onNonViewableProjectModalClose}
              tasktype={project.tasktype}
            />
          )}
          <Box className={gridTheme} h="70vh" w="100%">
            <DashboardGrid<CleansetRowRes>
              rowData={rowData}
              getRowId={({ data }) => data.id}
              gridOptions={gridOptions}
              columnDefs={columnDefs}
              loadingOverlayComponent={LoadingMessage}
            />
          </Box>
        </Stack>
      </Container>
    </Layout>
  )
}

export default CleansetVersions
