import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'

export const getProjectStatusDescription = (
  progressDescription: string,
  requiredColumnsReady: boolean
): string => {
  if (checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') && requiredColumnsReady) {
    return 'Partially ready...'
    // Blame Cris if you don't like this
  } else if (progressDescription === 'Initializing...') {
    return 'Processing...'
  } else {
    return 'Processing...'
  }
}
