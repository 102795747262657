import { LoaderFunctionArgs, redirect } from 'react-router-dom'

export const loaderRedirectTo = (to: string, loaderArgs: LoaderFunctionArgs) => {
  const searchParams = new URL(loaderArgs.request.url).searchParams
  const allParams = loaderArgs.params['*']
  const paramsString = allParams ? '/' + allParams : ''
  const searchParamsString = searchParams.entries().next() ? '?' + searchParams.toString() : ''
  const redirectUrl = `${to}${paramsString}${searchParamsString}`
  return redirect(redirectUrl)
}
