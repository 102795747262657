import { useToast } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import useResizeComponent from '@hooks/useResizeComponent'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { queryKeys } from '@services/datasheet/constants'
import { useMultiSelectBatchActionMutation } from '@services/datasheet/mutations'
import { AxiosError } from 'axios'
import { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Tasktype } from 'src/pages/datasetUpload/DatasetUpload.types'

import { CleansetContext } from '../../CleansetContext'
import DataLabelingResolver from '../../dataLabelingResolver/DataLabelingResolver'
import EditInterface from '../../editInterface/EditInterface'
import { getSlideMaxWidth } from '../../editInterface/EditInterface.helpers'
import { RowData } from '../../editInterface/EditInterface.types'
import UnsupervisedResolver from '../../unsupervisedResolver/UnsupervisedResolver'
import { invalidateRowActionQueries } from '../Datasheet.helpers'
import { CLEANLAB_BACKEND_COLUMN } from '../Datasheet.types'
import { LastActionState } from '../lastActionWidget/LastActionWidget.types'
import { updateRowAction, updateRowCustomTag } from './Resolver.helpers'
import ResolverSlideWrapper from './resolverSlideWrapper/ResolverSlideWrapper'

interface ResolverProps {
  cleansetId: string
  gridApi: any
  originalDatasetColumns: string[]
  projectDetails: any
  selectedRowIndex: number
  lastRowNum: number
  editMode: boolean
  onClose: VoidFunction
  resolverFailure: boolean
  currPageNum: number
  setCurrPageNum: (pageNum: number) => void
  fetchAndUpdateCurrRowData: (selectedRowIndex: number, retry?: number) => void
  setLastActionState: (lastActionState: LastActionState | null) => void
  isFetchingRows: boolean
  isFilterSidebarCollapsed: boolean
  metadataColumns: string[]
  thresholdRowIndex: number | null
  hasCustomTagsColumn: boolean
  showDataLabelingWorkflow: boolean
  dataLabelingActiveStep: number
  setShowTooltip: (showTooltip: boolean) => void
  setHasStartedLabeling: VoidFunction
  createAutoLabelColumn: VoidFunction
  onSetThresholdButtonClick: VoidFunction
}

const Resolver = (props: ResolverProps) => {
  const {
    projectDetails,
    lastRowNum,
    currPageNum,
    setCurrPageNum,
    thresholdRowIndex,
    showDataLabelingWorkflow,
    dataLabelingActiveStep,
    gridApi,
    cleansetId,
    fetchAndUpdateCurrRowData,
    setShowTooltip,
    setLastActionState,
    editMode,
    isFilterSidebarCollapsed,
  } = props

  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()
  const queryClient = useQueryClient()
  const { mutateAsync: applyMultiSelectAction, isLoading: isMutationLoading } =
    useMultiSelectBatchActionMutation(gridApi)

  const [isActionLoading, setIsActionLoading] = useState(false)
  const [autoAdvance, setAutoAdvance] = useState(true)

  const selectedRows = gridApi.getSelectedRows()

  const toast = useToast()

  const refreshServerSideStore = () => setTimeout(() => gridApi.refreshServerSide({}), 0)

  const {
    componentWidth,
    resizeComponent,
    componentRef: resolverRef,
    setComponentWidth: setResolverWidth,
  } = useResizeComponent(0.3)

  const onUpdateRowAction = async (action: string, errorCallback?: VoidFunction) => {
    if (selectedRows?.[0][projectDetails.idColumn] === undefined) {
      return
    }

    trackEvent(
      MixpanelEvents.resolveLabel,
      {
        ...cleansetInfo,
        action,
        isTemplate: projectDetails.isTemplate,
      },
      { rowId: selectedRows[projectDetails.idColumn] }
    )
    try {
      setIsActionLoading(true)
      updateRowAction(action, {
        gridApi,
        projectDetails,
        currPageNum,
        lastRowNum,
        setCurrPageNum,
        fetchAndUpdateCurrRowData,
        setLastActionState,
        cleansetId,
        autoAdvance,
      })
      refreshServerSideStore()
      invalidateRowActionQueries(queryClient, cleansetId)
      setShowTooltip(true)
      setTimeout(() => setShowTooltip(false), 2500)
    } catch (err) {
      errorCallback && errorCallback()
      notifyAxiosError(toast, err as AxiosError)
    }
    setIsActionLoading(false)
  }

  const handleMultiSelectAction = async (action: string, label?: string | number | boolean) => {
    const selectedRows = gridApi.getSelectedRows()
    const rowIds = selectedRows.map((e: RowData) => e._cleanlab_id)

    setIsActionLoading(true)

    trackEvent(
      MixpanelEvents.resolveLabel,
      { ...cleansetInfo, action, multilabelTagAction: true, isTemplate: projectDetails.isTemplate },
      { rowId: JSON.stringify(rowIds) }
    )

    try {
      await applyMultiSelectAction({
        cleansetId: cleansetId,
        batchAction: action,
        rowIds: rowIds,
        label: label ?? '',
      })
      setLastActionState(null)
      invalidateRowActionQueries(queryClient, cleansetId)
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError)
    } finally {
      setIsActionLoading(false)
    }
  }

  const onUpdateRowCustomTag = async (tag: string) => {
    if (selectedRows?.[0][projectDetails.idColumn] === undefined) {
      return
    }
    trackEvent(
      MixpanelEvents.marksNeedsReview,
      { ...cleansetInfo, tag, isTemplate: projectDetails.isTemplate },
      { rowId: selectedRows?.[0][projectDetails.idColumn] }
    )
    try {
      setIsActionLoading(true)
      updateRowCustomTag(tag, {
        gridApi,
        projectDetails,
        currPageNum,
        lastRowNum,
        setCurrPageNum,
        fetchAndUpdateCurrRowData,
        setLastActionState,
        cleansetId,
        autoAdvance,
      })
      refreshServerSideStore()
      void queryClient.invalidateQueries(
        queryKeys.datasheet
          .id(cleansetId)
          .columnValues()
          .column(CLEANLAB_BACKEND_COLUMN.CUSTOM_TAGS)
      )
      setIsActionLoading(false)
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError)
    }
  }

  const getResolver = () => {
    if (showDataLabelingWorkflow && dataLabelingActiveStep !== null && selectedRows?.[0]) {
      return (
        <DataLabelingResolver
          selectedRows={selectedRows}
          selectedRowData={selectedRows[0]}
          isTemplate={projectDetails.isTemplate}
          currentThresholdRow={thresholdRowIndex ?? 0}
          updateRowAction={onUpdateRowAction}
          handleMultiSelectAction={handleMultiSelectAction}
          updateRowCustomTag={onUpdateRowCustomTag}
          isActionLoading={isActionLoading || isMutationLoading}
          autoAdvance={autoAdvance}
          setAutoAdvance={setAutoAdvance}
          componentWidth={componentWidth}
          setResolverWidth={setResolverWidth}
          {...props}
        />
      )
    } else if (projectDetails.tasktype === Tasktype.UNSUPERVISED) {
      return (
        <UnsupervisedResolver
          selectedRowData={selectedRows[0] ?? {}}
          isTemplate={projectDetails.isTemplate}
          updateRowAction={onUpdateRowAction}
          handleMultiSelectAction={handleMultiSelectAction}
          updateRowCustomTag={onUpdateRowCustomTag}
          isActionLoading={isActionLoading || isMutationLoading}
          isMultiSelect={selectedRows.length > 1}
          autoAdvance={autoAdvance}
          setAutoAdvance={setAutoAdvance}
          setResolverWidth={setResolverWidth}
          {...props}
        />
      )
    } else {
      return (
        <EditInterface
          selectedRows={selectedRows}
          handleMultiSelectAction={handleMultiSelectAction}
          isActionLoading={isActionLoading || isMutationLoading}
          selectedRowData={selectedRows[0] ?? {}}
          isTemplate={projectDetails.isTemplate}
          updateRowAction={onUpdateRowAction}
          autoAdvance={autoAdvance}
          setAutoAdvance={setAutoAdvance}
          componentWidth={componentWidth}
          setResolverWidth={setResolverWidth}
          {...props}
        />
      )
    }
  }

  return (
    <ResolverSlideWrapper
      editMode={editMode}
      resolverRef={resolverRef}
      componentWidth={componentWidth}
      maxWidth={getSlideMaxWidth(isFilterSidebarCollapsed)}
      setResolverWidth={setResolverWidth}
      resizeComponent={resizeComponent}
    >
      {getResolver()}
    </ResolverSlideWrapper>
  )
}

export default Resolver
