import { ColumnApi, GridApi } from 'ag-grid-community'

import { DatasheetColumnStatuses } from '../Cleanset.types'

export interface DatasheetProps {
  cleansetId: string
  projectDetails: ProjectDetailsProps
  allColumns: string[]
  originalDatasetColumns: string[]
  setScrollToSummary: (scrollToSummary: boolean) => void
  accuracy: number
  gridApi: GridApi | null
  setGridApi: (gridApi: GridApi) => void
  columnApi: ColumnApi | null
  setColumnApi: (columnApi: ColumnApi) => void
  firstGridDataRendered: boolean
  setFirstGridDataRendered: (firstDataRendered: boolean) => void
  isComplete: boolean
  gridContext: React.MutableRefObject<DatasheetGridContext>
  hasPrevGridState: boolean
}

export interface DatasheetGridContext {
  datasheetColumnStatuses: DatasheetColumnStatuses
}

export interface RowCountsInterface {
  numTotal: number
  numNonFiltered: number
  numPreviouslyResolved: number
  numLabelIssues: number
  numOutliers: number
  numLabelIssuesNonFiltered: number
  numUnlabeled: number
}

export interface CleansetDetailsProps {
  id: string
  projectId: string
  datasetId: string
  modelType: string
  version: number
  createdAt: number
  isComplete: boolean
}

export interface ProjectDetailsProps {
  projectId: string
  projectName: string
  tasktype: string
  datasetId: string
  datasetName: string
  modelType: string
  modality: string
  paidExport: boolean
  idColumn: string
  isTemplate: boolean
  labels: string[]
  labelsIndexed: Record<string, number>
  labelColumn: string
  predictiveColumns: string[]
  possibleNumericColumns: string[]
  possibleTextColumns: string[]
  datetimeColumns: string[]
  identifierColumns: string[]
  unusedColumns: string[]
  variableColumns: string[]
  imageColumns: string[]
}

export enum CLEANLAB_FRONTEND_COLUMN {
  ROW = 'Row',
  GIVEN = '_cleanlab_given_label',
  QUALITY = '_cleanlab_label_quality',
  SUGGESTED = '_cleanlab_suggested_label',
  CORRECTED = '_cleanlab_clean_label',
  ACTION = '_cleanlab_action',
  ISSUE = '_cleanlab_issue',
  OUTLIER = '_cleanlab_outlier',
  UNLABELED = '_cleanlab_unlabeled',
  CUSTOM_TAGS = '_cleanlab_custom_tags',
  ISSUE_TAGS = '_cleanlab_issue_tags',
  OUTLIER_SCORE = '_cleanlab_outlier_score',
  AMBIGUOUS = '_cleanlab_ambiguous',
  AMBIGUOUS_SCORE = '_cleanlab_ambiguous_score',
  NEAR_DUPLICATE = '_cleanlab_is_near_duplicate',
  NEAR_DUPLICATE_SCORE = '_cleanlab_near_duplicate_score',
  NEAR_DUPLICATE_ID = '_cleanlab_near_duplicate_id',
  BEST_EXAMPLES = '_cleanlab_best_examples',
  LABEL_ISSUE_SCORE = '_cleanlab_label_issue_score',
  SUGGESTED_LABEL_CONFIDENCE_SCORE = '_cleanlab_suggested_label_confidence_score',
  PII = '_cleanlab_is_PII',
  PII_SCORE = '_cleanlab_PII_score',
  PII_TYPES = '_cleanlab_PII_types',
  PII_ITEMS = '_cleanlab_PII_items',
  INFORMAL = '_cleanlab_is_informal',
  INFORMAL_SCORE = '_cleanlab_informal_score',
  SPELLING_ISSUE_SCORE = '_cleanlab_spelling_issue_score',
  GRAMMAR_ISSUE_SCORE = '_cleanlab_grammar_issue_score',
  SLANG_ISSUE_SCORE = '_cleanlab_slang_issue_score',
  NON_ENGLISH = '_cleanlab_is_non_english',
  NON_ENGLISH_SCORE = '_cleanlab_non_english_score',
  PREDICTED_LANGUAGE = '_cleanlab_predicted_language',
  TOXIC = '_cleanlab_is_toxic',
  TOXIC_SCORE = '_cleanlab_toxic_score',
  BIASED = '_cleanlab_is_biased',
  BIAS_SCORE = '_cleanlab_bias_score',
  GENDER_BIAS_SCORE = '_cleanlab_gender_bias_score',
  RACIAL_BIAS_SCORE = '_cleanlab_racial_bias_score',
  SEXUAL_ORIENTATION_BIAS_SCORE = '_cleanlab_sexual_orientation_bias_score',
  DARK = '_cleanlab_is_dark',
  DARK_SCORE = '_cleanlab_dark_score',
  LIGHT = '_cleanlab_is_light',
  LIGHT_SCORE = '_cleanlab_light_score',
  BLURRY = '_cleanlab_is_blurry',
  BLURRY_SCORE = '_cleanlab_blurry_score',
  ODD_SIZE = '_cleanlab_is_odd_size',
  ODD_SIZE_SCORE = '_cleanlab_odd_size_score',
  LOW_INFORMATION = '_cleanlab_is_low_information',
  LOW_INFORMATION_SCORE = '_cleanlab_low_information_score',
  GRAYSCALE = '_cleanlab_is_grayscale',
  ODD_ASPECT_RATIO = '_cleanlab_is_odd_aspect_ratio',
  ODD_ASPECT_RATIO_SCORE = '_cleanlab_odd_aspect_ratio_score',
  NSFW = '_cleanlab_is_NSFW',
  NSFW_SCORE = '_cleanlab_NSFW_score',
  AESTHETIC_SCORE = '_cleanlab_aesthetic_score',
  MEDIA_URL = '_cleanlab_media_url',
  TEXT_CHARACTER_LENGTH = '_cleanlab_text_num_characters',
  EMPTY_TEXT = '_cleanlab_is_empty_text',
}

export enum CLEANLAB_BACKEND_COLUMN {
  SUGGESTED = 'suggested_label',
  CORRECTED = 'clean_label',
  ACTION = 'action',
  ISSUE = 'issue',
  OUTLIER = 'outlier',
  UNLABELED = 'unlabeled',
  CUSTOM_TAGS = 'custom_tags',
}

export const unsortableColumns = [
  CLEANLAB_FRONTEND_COLUMN.ROW,
  CLEANLAB_FRONTEND_COLUMN.ISSUE_TAGS,
  CLEANLAB_FRONTEND_COLUMN.MEDIA_URL,
]

export const NUM_ROWS_PER_PAGE = 50
