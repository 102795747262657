export const getVpcAlertMessage = (
  isAdmin: boolean,
  fractionUsed: number
): { message: string; spanText: string } => {
  if (fractionUsed >= 1) {
    return {
      message: 'You have hit your total budget.',
      spanText: 'All work will be paused until the budget is resolved.',
    }
  } else if (fractionUsed >= 0.8) {
    return {
      message: 'Your org is approaching the total budget limit.',
      spanText: isAdmin
        ? 'To prevent any disruptions, consider adding more funds to your total budget.'
        : 'To prevent disruption, consider asking your admin to add more funds to your total budget.',
    }
  }

  return { message: '', spanText: '' }
}
