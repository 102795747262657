import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  VStack,
} from '@chakra-ui/react'
import useIsTabActive from '@hooks/useIsTabActive'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { useDatasetUsageStatus } from '@services/cleanset/queries'
import datasetApiService from '@services/datasetApi'
import { timestampToISOFormat } from '@utils/functions/timestampToDate'
import { AxiosError } from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { MdCheckCircle, MdWarning } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import DatasetChangesFooter from '../datasetChangesFooter/DatasetChangesFooter'
import { SchemaUpdatesContext } from '../SchemaUpdatesContext'
import { DatasetMetadataProps } from './DatasetMetadata.types'

const DatasetMetadata = ({ datasetId, datasetDetails, refreshData }: DatasetMetadataProps) => {
  const [datasetName, setDatasetName] = useState(datasetDetails.name)
  const [isSaving, setIsSaving] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()
  const isTabActive = useIsTabActive()
  const { setDatasetUsedInProject } = useContext(SchemaUpdatesContext)
  const isDatasetUsedInProject = useDatasetUsageStatus(datasetId)
  setDatasetUsedInProject(isDatasetUsedInProject)

  useEffect(() => {
    if (!datasetDetails.complete && isTabActive) {
      setTimeout(() => refreshData(datasetId), 3000)
    }
  }, [datasetId, datasetDetails, refreshData, isTabActive])

  const cancelChanges = () => {
    setDatasetName(datasetDetails.name)
  }

  const saveChanges = async () => {
    try {
      setIsSaving(true)
      await datasetApiService.updateRow({ id: datasetId, name: datasetName })
      navigate(0)
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError)
      setIsSaving(false)
    }
  }

  const statusRenderer = (isComplete: boolean) => {
    return isComplete ? (
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<MdCheckCircle color="green" />} />
        <Input isDisabled value="Uploaded successfully" textColor="green" />
      </InputGroup>
    ) : (
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<MdWarning color="red" />} />
        <Input isDisabled value="Upload failed" textColor="red" />
      </InputGroup>
    )
  }

  return (
    <>
      <Box minH="75vh">
        <VStack justify="center" display="flex" w="100%">
          <HStack justify="center" w="100%" spacing="2%">
            <FormControl w="35%">
              <FormLabel>Creation date/time</FormLabel>
              <Input
                type="datetime-local"
                isDisabled
                value={timestampToISOFormat(datasetDetails.upload_date)}
              />
            </FormControl>
            <FormControl w="35%">
              <FormLabel>Source</FormLabel>
              <Input isDisabled value="Uploaded file" />
            </FormControl>
          </HStack>
          <HStack justify="center" w="100%" spacing="2%">
            <FormControl w="35%">
              <FormLabel>Number of rows</FormLabel>
              <Input isDisabled value={datasetDetails.num_rows} />
            </FormControl>
            <FormControl w="35%">
              <FormLabel>Status</FormLabel>
              {statusRenderer(datasetDetails.complete)}
            </FormControl>
          </HStack>
          <HStack justify="center" w="100%">
            <FormControl w="72%">
              <FormLabel>Dataset Name</FormLabel>
              <Input value={datasetName} onChange={(e) => setDatasetName(e.target.value)} />
            </FormControl>
          </HStack>
        </VStack>
      </Box>
      <DatasetChangesFooter
        hasChanges={datasetName !== datasetDetails.name}
        onCancel={cancelChanges}
        onSave={saveChanges}
        isSaving={isSaving}
      />
    </>
  )
}

export default DatasetMetadata
