import { Center } from '@chakra-ui/react'
import React from 'react'

import { BorderedIconProps } from './BorderedIcon.types'

const BorderedIcon = ({ icon, borderColor, iconBackgroundColor }: BorderedIconProps) => {
  return (
    <Center
      height="40px"
      width="40px"
      border="1px solid"
      boxShadow="0px 2px 3px 0px rgba(23, 25, 28, 0.04), 0px 1px 1px -1px rgba(23, 25, 28, 0.08)"
      bg={iconBackgroundColor}
      borderColor={borderColor}
      borderRadius="8px"
    >
      {icon}
    </Center>
  )
}

export default BorderedIcon
