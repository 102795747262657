import React from 'react'

import spinnerStyles from './RainbowSpinner.module.css'

const RainbowSpinner = () => {
  return (
    <div className={spinnerStyles['rainbow-spinner-border']}>
      <div className={spinnerStyles.border}></div>
      <div className={spinnerStyles.glow}></div>
    </div>
  )
}

export default RainbowSpinner
