import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react'
import React from 'react'
import { InlineLink } from 'src/pages/projectForm/Sidebar'

const SupportModal = (props: UseModalProps) => {
  const { isOpen, onClose } = props

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Support</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="type-body-200 flex flex-col gap-4 text-text-faint">
          <p>
            Have questions about Cleanlab Studio? Ask us anything about best practices with Cleanlab
            Studio or Data-Centric AI.
          </p>
          <ul className="flex flex-col gap-4">
            <li>
              <span className="type-body-200-medium">By email:</span> Contact us at{' '}
              <InlineLink to="mailto:support@cleanlab.ai" target="_blank">
                support@cleanlab.ai
              </InlineLink>
              .
              <br />
            </li>
            <li>
              <span className="type-body-200-medium">On Slack:</span> Get real-time support from our
              engineers in our{' '}
              <InlineLink to="https://cleanlab.ai/slack/" target="_blank">
                Slack community
              </InlineLink>
              .
            </li>
          </ul>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}

export default SupportModal
