import { Box, Text, Tooltip } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

import testIds from '../../playwright/test_ids.json'
import { TextOverflowTooltipProps } from './TextOverFlowTooltip.types'

const checkOverflow = (textContainer: HTMLParagraphElement | null): boolean => {
  if (textContainer) {
    return textContainer.offsetWidth < textContainer.scrollWidth
  }
  return false
}

const TextOverflowTooltip = (props: TextOverflowTooltipProps) => {
  const { text, setSort, disableSorting, suppressEllipsis, openDelay } = props

  const textAsString = String(text)

  const [sortOrder, setSortOrder] = useState<string>('desc')

  const [overflowActive, setOverflowActive] = useState<boolean>(false)

  const textComponentRef = useRef<HTMLParagraphElement>(null)
  useEffect(() => {
    const node = textComponentRef.current
    if (!node) return

    const resizeObserver = new ResizeObserver(() => {
      if (checkOverflow(node)) {
        setOverflowActive(true)
      } else {
        setOverflowActive(false)
      }
    })
    resizeObserver.observe(node)
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const limitedText =
    textAsString.length > 1200 ? `${textAsString.substring(0, 1200)}...` : textAsString

  return (
    <Box
      w="100%"
      onClick={() => {
        if (!disableSorting && setSort) {
          setSort(sortOrder === 'desc' ? 'asc' : 'desc')
          setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')
        }
      }}
      data-testid={testIds.textOverflowTooltip}
    >
      <Tooltip
        label={limitedText}
        isDisabled={!overflowActive}
        openDelay={openDelay}
        variant={textAsString.length > 1200 ? 'wide' : undefined}
        hasArrow
      >
        <Text
          whiteSpace="nowrap"
          textOverflow={suppressEllipsis ? undefined : 'ellipsis'}
          overflow="hidden"
          ref={textComponentRef}
        >
          {textAsString}
        </Text>
      </Tooltip>
    </Box>
  )
}

export default TextOverflowTooltip
