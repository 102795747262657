import { timestampToDate } from '@utils/functions/timestampToDate'
import {
  ColDef,
  GetContextMenuItemsParams,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community'

export const columnDefs: ColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 200,
    filter: 'agTextColumnFilter',
    pinned: 'left',
    checkboxSelection: true,
  },
  {
    field: 'tier',
    headerName: 'Tier',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'plan_name',
    headerName: 'Plan Name',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'description',
    headerName: 'Description',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'price_per_month',
    headerName: 'Price Per Month ($)',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'price_per_year',
    headerName: 'Price Per Year ($)',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'price_per_row_cleanset_export',
    headerName: 'Price Per Row Cleanset Export (cents)',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'price_per_row_inference_export',
    headerName: 'Price Per Row Inference Export (cents)',
    filter: 'agTextColumnFilter',
  },
  { field: 'dataset_rows_limit', headerName: 'Dataset Rows Limit', filter: 'agNumberColumnFilter' },
  {
    field: 'cleanset_export_rows_monthly_limit',
    headerName: 'Cleanset Export Rows Monthly Limit',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'inference_export_rows_monthly_limit',
    headerName: 'Inference Export Rows Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_projects_monthly_limit',
    headerName: 'Free Projects Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_models_monthly_limit',
    headerName: 'Free Models Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_cleanset_export_rows_monthly_limit',
    headerName: 'Free Cleanset Export Rows Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_inference_export_rows_monthly_limit',
    headerName: 'Free Inference Export Rows Monthly Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_length_days',
    headerName: 'Free Trial Length Days',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_dataset_rows_limit',
    headerName: 'Free Trial Dataset Rows Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_projects_limit',
    headerName: 'Free Trial Projects Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_models_limit',
    headerName: 'Free Trial Models Limit',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'free_trial_inference_export_rows_limit',
    headerName: 'Free Trial Inference Export Rows Limit',
    filter: 'agNumberColumnFilter',
  },
  { field: 'created_at', headerName: 'Created At', filter: 'agDateColumnFilter' },
  {
    field: 'last_updated',
    headerName: 'Last Updated',
    cellRenderer: 'timeFormatter',
    filter: 'agDateColumnFilter',
  },
]

const monthlyPriceFormatter = (params: ICellRendererParams) => {
  const rowData = params.node.data
  const pricePerMonth = Number(rowData.price_per_month) / 100
  return `${pricePerMonth}`
}

const exportPriceFormatter = (params: ICellRendererParams) => {
  const rowData = params.node.data
  const pricePerRowExport = Number(rowData.price_per_row_export)
  return `${pricePerRowExport}`
}

const dataStorageFormatter = (params: ICellRendererParams) => {
  const rowData = params.node.data
  const dataStorage = Number(rowData.data_storage) / 1e9
  return `${dataStorage}`
}

const timeFormatter = (param: ICellRendererParams) => timestampToDate(param.value)

export const planGridOptions: GridOptions = {
  columnDefs: columnDefs,
  rowSelection: 'single',
  components: {
    monthlyPriceFormatter,
    exportPriceFormatter,
    dataStorageFormatter,
    timeFormatter,
  },
  defaultColDef: {
    filter: true,
    sortable: true,
    resizable: true,
    flex: 1,
    width: 100,
    minWidth: 100,
  },
  animateRows: true,
  getContextMenuItems: (params: GetContextMenuItemsParams) => {
    if (params.value === undefined) {
      return []
    } else {
      return ['copy']
    }
  },
}
