import { Heading, Image, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

interface NonExistentResourceProps {
  errorMessage?: string
}

const NonExistentResource = (props: NonExistentResourceProps) => {
  return (
    <VStack w="100%" h="100%" align="center" justify="center">
      <Image src="/images/nonexistent_resource.svg" h="400px" />
      <br />
      <VStack spacing={2}>
        <Heading size="sm">You seem to have lost your way.</Heading>
        <Text fontSize="lg">{props.errorMessage || 'No such resource exists.'}</Text>
      </VStack>
    </VStack>
  )
}

export default NonExistentResource
