import { useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { AgilityApp, queryKeys } from '@services/agilityApps/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import { GridApi } from 'ag-grid-community'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useEffect, useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'

const apiVersion = 'v0'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/agility`,
  withCredentials: true,
})

const handleAxiosError = (toast: ReturnType<typeof useToast>, err: AxiosError) => {
  if (err.response && err.response.status >= 400 && err.response.status < 500) {
    const errorMessage = (err.response.data as any)?.error || 'An error occurred'
    toast({
      ...defaultToastAlertProps,
      render: renderChakraToastAlert({
        heading: 'Error',
        status: 'warning',
        description: errorMessage,
      }),
    })
  } else {
    toast({
      ...defaultToastAlertProps,
      render: renderChakraToastAlert({
        heading: 'Something went wrong',
        status: 'warning',
      }),
    })
  }
}

export const useCreateAgilityApp = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (agilityApp: AgilityApp) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        ...agilityApp,
        system_instructions: agilityApp.system_instructions ?? null,
        additional_system_instructions: agilityApp.additional_system_instructions ?? null,
        crawler_config: JSON.parse(agilityApp.crawler_config),
      }
      await axiosClient.post('/apps/create', body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.apps.all() })
    },
    onError: (err: AxiosError) => {
      handleAxiosError(toast, err)
    },
  })
}

export const useDeleteAgilityApp = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: async (appId: string) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.delete(`/apps/${appId}`, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.apps.all() })
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Deleted agility app',
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useDeleteAgilityApps = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (appIds: string[]) => {
      const accessToken = await auth.getTokenSilently()

      return await Promise.all(
        appIds.map(async (appId) => {
          await axiosClient.delete(`/apps/${appId}`, createAuthHeaders(accessToken))
        })
      )
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.apps.all() })
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Deleted agility apps',
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useUpdateAgilityApp = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const gridApiRef = useRef<GridApi | null>(null)

  const mutation = useMutation({
    mutationFn: async (agilityApp: AgilityApp) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        ...agilityApp,
        system_instructions: agilityApp.system_instructions ?? null,
        additional_system_instructions: agilityApp.additional_system_instructions ?? null,
        crawler_config: JSON.parse(agilityApp.crawler_config),
      }
      await axiosClient.put(`/apps/${agilityApp.id}`, body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.apps.all() })
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Updated agility app',
          status: 'success',
        }),
      })
    },
    onError: (err: AxiosError) => {
      handleAxiosError(toast, err)
    },
  })

  useEffect(() => {
    if (mutation.isSuccess && gridApiRef.current) {
      gridApiRef.current.deselectAll()
    }
  }, [mutation.isSuccess])

  const updateAgilityApp = (agilityApp: AgilityApp, gridApi?: GridApi) => {
    gridApiRef.current = gridApi || null
    mutation.mutate(agilityApp)
  }

  return { updateAgilityApp, ...mutation }
}

export const useExportScrapedUrls = (onSuccess: (res: AxiosResponse) => void) => {
  const toast = useToast()
  return useMutation({
    mutationFn: async (appId: string) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.get(`/apps/${appId}/scraped_urls`, {
        ...createAuthHeaders(accessToken),
        responseType: 'arraybuffer',
      })
    },
    onSuccess: (res: AxiosResponse) => {
      onSuccess(res)
    },
    onError: (err: AxiosError) => {
      notifyAxiosError(toast, err)
    },
  })
}

export const useExportDebugInfo = (onSuccess: (res: AxiosResponse) => void) => {
  const toast = useToast()
  return useMutation({
    mutationFn: async (appId: string) => {
      const accessToken = await auth.getTokenSilently()
      return await axiosClient.get(`/apps/${appId}/debug_info`, {
        ...createAuthHeaders(accessToken),
        responseType: 'arraybuffer',
      })
    },
    onSuccess: (res: AxiosResponse) => {
      onSuccess(res)
    },
    onError: (err: AxiosError) => {
      notifyAxiosError(toast, err)
    },
  })
}
