import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { Checkbox } from '@components/checkbox/Checkbox'
import { InputSearch } from '@components/input/Input'
import { useTabularPins } from '@hooks/useTabularPins'
import { useState } from 'react'
import { AiOutlineExperiment } from 'react-icons/ai'

import { formatTabularEditCell, getSortedData, searchAlgorithm } from './TabularDataDisplay.helpers'
import { TabularEditInterfaceProps } from './TabularDataDisplay.types'

const TabularDataDisplay = ({ data, projectDetails }: TabularEditInterfaceProps) => {
  const { predictiveColumns, modality } = projectDetails
  const defaultPinnedFeatures = data.map(([feature]) => feature)
  const { pinnedFeatures, setPinnedFeatures } = useTabularPins(defaultPinnedFeatures)
  const [searchTerm, setSearchTerm] = useState('')

  // filter the data to search terms.
  const searchedData = data.filter(([feature, value]) => {
    const keyPresence = searchAlgorithm(feature, searchTerm)
    let valuePresence = false
    if (typeof value === 'string' || typeof value === 'number') {
      valuePresence = searchAlgorithm(String(value), searchTerm)
    }
    return keyPresence || valuePresence
  })

  // reorder the data.
  const orderedData = getSortedData(searchedData, predictiveColumns, pinnedFeatures)

  if (modality !== 'tabular') {
    return null
  }

  return (
    <VStack h="calc(100vh - 225px)" px={4} pt={4}>
      <InputSearch
        placeholder="Search columns or values"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
      />

      <TableContainer m={0} w="100%" overflowY="auto" overflowX="auto">
        <Table
          variant="striped"
          size={orderedData.length > 7 ? 'sm' : 'md'}
          overflowY="auto"
          overflowX="hidden"
          borderRadius="md"
          h="calc(100% - 228px)"
        >
          <Thead>
            <Tr>
              <Th width={12}>
                <Checkbox
                  checked={
                    pinnedFeatures.length > 0 && pinnedFeatures.length < orderedData.length
                      ? 'indeterminate'
                      : pinnedFeatures.length === orderedData.length
                  }
                  onCheckedChange={(checked) =>
                    setPinnedFeatures(checked ? orderedData.map(([feature]) => feature) : [])
                  }
                />
              </Th>
              <Th width="40%">Column</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orderedData.map(([feature, value]) => (
              <Tr key={feature} verticalAlign="top">
                <Td>
                  <Checkbox
                    checked={pinnedFeatures.includes(feature)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setPinnedFeatures([...pinnedFeatures, feature])
                      } else {
                        setPinnedFeatures(pinnedFeatures.filter((f) => f !== feature))
                      }
                    }}
                  />
                </Td>
                <Td style={{ whiteSpace: 'break-spaces' }}>
                  <HStack align="center" spacing="0.5rem">
                    {predictiveColumns.includes(feature) && (
                      <Tooltip label="This column was used for model prediction" shouldWrapChildren>
                        <AiOutlineExperiment />
                      </Tooltip>
                    )}
                    <Box>{feature}</Box>
                  </HStack>
                </Td>
                <Td style={{ lineBreak: 'anywhere', whiteSpace: 'break-spaces' }}>
                  {formatTabularEditCell(feature, value, projectDetails)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}

export default TabularDataDisplay
