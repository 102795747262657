import { HStack } from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'
import React from 'react'

import { DatasetChangesFooterProps } from './DatasetChangesFooter.types'

const DatasetChangesFooter = ({
  hasChanges,
  onCancel,
  onSave,
  isSaving,
}: DatasetChangesFooterProps) => {
  return (
    <HStack align="flex-end" justify="flex-end" width="100%">
      <SecondaryButton height="36px" onClick={onCancel}>
        Cancel
      </SecondaryButton>
      <PrimaryButton height="36px" isLoading={isSaving} isDisabled={!hasChanges} onClick={onSave}>
        Save
      </PrimaryButton>
    </HStack>
  )
}

export default DatasetChangesFooter
