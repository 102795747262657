import {
  FormControl,
  FormLabel,
  HStack,
  NumberInput,
  NumberInputField,
  Select,
  useDisclosure,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'
import { useEditSignupSettings } from '@services/signupSettings/mutations'
import { useSignupSettings } from '@services/signupSettings/queries'
import React, { useState } from 'react'

import ConfirmSignupSettingsModal from './confirmSignupSettingsModal/ConfirmSignupSettingsModal'

const SignupSettings = () => {
  const [accountActivationEnabled, setAccountActivationEnabled] = useState<string | null>()
  const [allowedActivationsPerDay, setAllowedActivationsPerDay] = useState<number | null>()
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { isOpen, onOpen, onClose: onCloseConfirmModal } = useDisclosure()

  const signupSettings = useSignupSettings()

  const { mutate: onConfirmSignupSettings } = useEditSignupSettings()

  const onCancel = () => {
    setAccountActivationEnabled(`${signupSettings.account_activation_enabled}`)
    setAllowedActivationsPerDay(signupSettings.allowed_activations_per_day)
    onClose()
  }

  const onClose = () => {
    setIsEditing(false)
    onCloseConfirmModal()
  }

  if (signupSettings === undefined) {
    return <div>loading...</div>
  }
  return (
    <div>
      <FormControl width="400px">
        <FormLabel>Account activation enabled</FormLabel>
        <Select
          isDisabled={!isEditing}
          placeholder="Select plan"
          mb="20px"
          onChange={(evt) => setAccountActivationEnabled(evt.target.value)}
          value={accountActivationEnabled ?? `${signupSettings.account_activation_enabled}`}
        >
          {['true', 'false'].map((val) => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </Select>

        <FormLabel>Allowed activations per day</FormLabel>
        <NumberInput
          isDisabled={!isEditing}
          value={allowedActivationsPerDay ?? signupSettings.allowed_activations_per_day}
          min={0}
          onChange={(evt) => setAllowedActivationsPerDay(Number(evt))}
        >
          <NumberInputField id="numActivations" />
        </NumberInput>
        <HStack pt="18px" justify="flex-end">
          {isEditing && (
            <SecondaryButton height="32px" onClick={onCancel}>
              Cancel
            </SecondaryButton>
          )}
          <PrimaryButton
            height="32px"
            onClick={() => {
              if (!isEditing) {
                setIsEditing(true)
              } else {
                onOpen()
              }
            }}
          >
            {isEditing ? 'Confirm' : 'Edit'}
          </PrimaryButton>
        </HStack>
      </FormControl>
      <ConfirmSignupSettingsModal
        onConfirm={() => {
          onConfirmSignupSettings({
            account_activation_enabled: JSON.parse(
              accountActivationEnabled ?? `${signupSettings.account_activation_enabled}`
            ),
            allowed_activations_per_day:
              allowedActivationsPerDay ?? signupSettings.allowed_activations_per_day,
          })
          onClose()
        }}
        isOpen={isOpen}
        onClose={onClose}
        accountActivationEnabled={
          accountActivationEnabled ?? `${signupSettings.account_activation_enabled}`
        }
        allowedActivationsPerDay={
          allowedActivationsPerDay ?? signupSettings.allowed_activations_per_day
        }
      />
    </div>
  )
}
export default SignupSettings
