import { TextDataDisplayProps } from './TextDataDisplay.types'

const TextDataDisplay = (props: TextDataDisplayProps) => {
  const { text, textColumnName } = props
  return (
    <div className="type-body-100 flex h-[calc(100vh-290px)] w-full flex-col gap-3 overflow-auto p-5">
      <p className="type-caption-medium text-text-primary">{textColumnName}</p>
      <p className="type-body-100">{text}</p>
    </div>
  )
}

export default TextDataDisplay
