import {
  Button,
  Grid,
  GridItem,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import datasetApiService from '@services/datasetApi'
import { useCopyProjectMutation } from '@services/project/mutations'
import React, { useState } from 'react'

import { ControlledFormInputElement } from '../../queryInterface/QueryInterface.helpers'

const CopyAsset = () => {
  const [userId, setUserId] = useState('')
  const [assetId, setAssetId] = useState('')
  const [isProject, setIsProject] = useState('1')
  const { mutate: copyProject } = useCopyProjectMutation()

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Copy Asset</Heading>
      <Text>
        <strong>Instructions:</strong> Copy a Project or a Dataset by including the destination user
        ID and the target asset ID below.
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem>
          <ControlledFormInputElement
            id="user_id"
            type="text"
            label="Destination User ID"
            value={userId}
            setValue={setUserId}
          />
        </GridItem>
        <GridItem>
          <ControlledFormInputElement
            id="asset_id"
            type="text"
            label="Asset ID"
            value={assetId}
            setValue={setAssetId}
          />
        </GridItem>
      </Grid>

      <RadioGroup onChange={setIsProject} value={isProject}>
        <Stack direction="row">
          <Radio value="1">Project</Radio>
          <Radio value="2">Dataset</Radio>
        </Stack>
      </RadioGroup>

      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          if (isProject === '1') {
            copyProject({ userId, projectId: assetId })
          } else {
            await datasetApiService.copyDataset(userId, assetId)
          }
        }}
        aria-label="Copy Project"
      >
        Copy
      </Button>
    </VStack>
  )
}

export default CopyAsset
