import CleanlabFullLogoBlack from '@assets/images/branding/cleanlab_logo_full_black_vector.svg'
import { cleanlabColors } from '@assets/styles/CleanlabColors'
import globalStyles from '@assets/styles/global.module.css'
import { Box, HStack, Image, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

const PrintHeader = ({ projectName }: { projectName: string }) => {
  return (
    <Box
      as="header"
      position="fixed"
      width="100%"
      padding="0.5rem 2.5rem"
      background={useColorModeValue(cleanlabColors.white, cleanlabColors.whiteDarkMode)}
      top={0}
      left={0}
      zIndex={1000}
      className={globalStyles.printOnly}
      color={useColorModeValue(cleanlabColors.neutral[900], cleanlabColors.neutralDarkMode[900])}
    >
      <HStack justify="space-between" height="30px" width="100%" marginBottom="2rem">
        <HStack height="100%" gap="1rem" alignItems="center">
          <Image src={CleanlabFullLogoBlack} height="100%" />
          <Text fontSize="md">Analytics</Text>
        </HStack>

        <HStack height="100%">
          <Text fontSize="md">
            {new Date().toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
          </Text>
          {/* <Divider orientation="vertical" borderWidth={1} />
          <Text fontSize="md" className={chartStyles.pageCounter} /> */}
        </HStack>
      </HStack>

      <Text fontSize="lg" fontWeight="600">
        {projectName}
      </Text>
    </Box>
  )
}

export default PrintHeader
