import { useToast } from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys as enrichmentProjectQueryKeys } from '@services/enrichment/constants'
import { enrichmentProjectApi } from '@services/enrichmentApi'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { CreateEnrichmentProjectReq } from 'src/pages/projectForm/ProjectForm.types'

export const useCreateEnrichmentProjectMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: AxiosResponse) => void
  onError?: (err: AxiosError) => void
}) => {
  const toast = useToast()
  return useMutation({
    mutationFn: async (req: CreateEnrichmentProjectReq) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        name: req.name,
        dataset_id: req.datasetId,
      }

      return enrichmentProjectApi.post('projects', body, createAuthHeaders(accessToken))
    },
    onSuccess,
    onError: (err: AxiosError) => {
      onError?.(err)
      notifyAxiosError(toast, err, { title: 'Failed to create project' })
    },
  })
}

export const useDeleteEnrichmentProjectMutation = (refreshData: () => void) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (projectId: string) => {
      const accessToken = await auth.getTokenSilently()
      return await enrichmentProjectApi.delete(
        `projects/${projectId}`,
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(enrichmentProjectQueryKeys.enrichmentProjects.all())
      refreshData()
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Deleted enrichment Project',
          status: 'success',
        }),
      })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, { title: 'Failed to delete enrichment Project' })
    },
  })
}

export const useEnrichmentDatasetMutation = ({
  projectId,
  onSuccess,
  isPreview,
}: {
  projectId: string
  onSuccess?: (res: AxiosResponse) => void
  onFailure?: (res: AxiosResponse) => void
  isPreview?: boolean
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: async ({
      prompt,
      newColumnName,
      tlmQualityPreset = 'low',
      indices,
      constrainOutput,
    }: {
      prompt: string
      newColumnName: string
      tlmQualityPreset?: 'low' | 'medium' | 'high'
      indices?: number[]
      constrainOutput?: string[]
    }) => {
      const accessToken = await auth.getTokenSilently()
      const endpoint = isPreview ? 'preview' : 'enrich_all'

      return await enrichmentProjectApi.post(
        endpoint,
        {
          prompt,
          project_id: projectId,
          new_column_name: newColumnName,
          tlm_quality_preset: tlmQualityPreset,
          constrain_outputs: constrainOutput,
          ...(isPreview && {
            // if not supplied, just default to 0 through 9
            indices: indices ?? [...Array(10)].map((_v, i) => i),
          }),
        },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: (response) => {
      void queryClient.invalidateQueries(enrichmentProjectQueryKeys.jobs.all())
      onSuccess?.(response)
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, {
        title: `Failed to ${isPreview ? 'run test' : 'generate for all rows'}. Please try again`,
      })
    },
  })
}

export const useEnrichmentTestRun = ({
  projectId,
  onSuccess,
  isPreview,
}: {
  projectId: string
  onSuccess?: (res: AxiosResponse) => void
  onFailure?: (res: AxiosResponse) => void
  isPreview?: boolean
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: async ({
      prompt,
      newColumnName,
      tlmQualityPreset = 'low',
    }: {
      prompt: string
      newColumnName: string
      tlmQualityPreset?: 'low' | 'medium' | 'high'
    }) => {
      const accessToken = await auth.getTokenSilently()
      const endpoint = isPreview ? 'preview' : 'enrich_all'

      return await enrichmentProjectApi.post(
        endpoint,
        {
          prompt,
          project_id: projectId,
          new_column_name: newColumnName,
          tlm_quality_preset: tlmQualityPreset,
          ...(isPreview && {
            indices: [...Array(10)].map((_v, i) => i),
          }),
        },
        createAuthHeaders(accessToken)
      )
    },
    onSuccess: (response) => {
      void queryClient.invalidateQueries(enrichmentProjectQueryKeys.jobs.all())
      onSuccess?.(response)
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError, {
        title: `Failed to ${isPreview ? 'run test' : 'generate for all rows'}. Please try again`,
      })
    },
  })
}
