import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import RegisterNotification, {
  useAllowRegisterNotification,
} from '@common/misc/registerNotification/RegisterNotification'
import { Button } from '@components/button/Button'
import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconTrash } from '@components/icons'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { CleansetRowRes } from '@services/cleanset/constants'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { ComponentPropsWithoutRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { DateOnlyWithTooltip } from '../dashboard/DateOnlyWithTooltip'
import { colIssuesFound, colIssuesResolved } from '../dashboard/projectsGrid/ProjectsGrid.helpers'
import ProjectStatusCellComponent from '../dashboard/projectsGrid/projectStatusCellComponent/ProjectStatusCellComponent'
import { CleansetTargetRowData } from './CleansetVersions'

type CleansetCellRendererParams = ICellRendererParams<CleansetRowRes>

type ActionsCellParams = {
  setTargetRowData: (rowData: CleansetTargetRowData) => void
  onDeleteOpen: () => void
}

type CleansetStatusRendererParams = {
  onNonViewableProjectModalOpen: () => void
}

const CleansetStatusRenderer = (params: CleansetCellRendererParams) => {
  const rowData = params.node.data
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [hide, setHide] = useState(false)
  const allowRegisterNotification = useAllowRegisterNotification()

  if (!rowData) return null
  const { onNonViewableProjectModalOpen } = (params.colDef
    ?.cellRendererParams as CleansetStatusRendererParams) || {
    onNonViewableProjectModalOpen: () => {},
  }

  return (
    <>
      <ProjectStatusCellComponent
        isReady={rowData.is_ready}
        hasError={rowData.has_error}
        onOpenNonViewableProject={onNonViewableProjectModalOpen}
        isViewable={!rowData.is_not_viewable_in_ui}
        progressDescription={rowData.progress_description}
        onErrorButtonClick={onToggle}
        projectId={rowData.id}
        requiredColumnsReady={rowData.required_columns_ready}
      />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalContent hidden={hide}>
          <ModalHeader>Support</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="md" pb={8}>
            {IS_VPC_ENVIRONMENT ? (
              <Text>
                We experienced an internal error while training your Project. If this issue
                persists, please contact your organization administrator.
              </Text>
            ) : (
              <>
                <Text>
                  We experienced an internal error while training your Project. Our engineers have
                  been alerted and are working on a fix.{' '}
                </Text>
                {allowRegisterNotification && (
                  <>
                    <br />
                    Click{' '}
                    <RegisterNotification
                      cleansetId={rowData.id}
                      onClick={() => setHide(true)}
                      onClose={() => {
                        onClose()
                        setHide(false)
                      }}
                    >
                      here
                    </RegisterNotification>{' '}
                    to be notified by email once the issue is fixed. Thank you for your patience!
                  </>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

const ActionsCellRenderer = (params: CleansetCellRendererParams) => {
  const { trackEvent } = useEventTracking()
  const rowData: CleansetTargetRowData | undefined = params.node.data
    ? {
        ...(params.node.data ?? {}),
        name: 'cleanset', // Ensures Delete modal shows "Delete cleanset?" in heading
      }
    : undefined
  const { setTargetRowData, onDeleteOpen } = (params.colDef
    ?.cellRendererParams as ActionsCellParams) || {
    setTargetRowData: () => {},
    onDeleteOpen: () => {},
  }
  if (!rowData) return null
  const openButtonRoute = `/cleansets/${rowData.id}`

  const deleteLabel = 'Delete Cleanset'
  let openLabel = 'Initializing Cleanset'
  if (rowData.has_error) {
    openLabel = 'Cleanset error'
  } else if (rowData.is_ready) {
    openLabel = 'View Cleanset'
  } else if (checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') && rowData.required_columns_ready) {
    openLabel = 'View partially ready Cleanset'
  }

  const openButtonProps = {
    size: 'xSmall',
    variant: 'secondaryFaint',
    'aria-label': openLabel,
  } as const satisfies Partial<ComponentPropsWithoutRef<typeof Button>>
  return (
    <div className="flex h-full flex-shrink-0 items-center gap-4 pr-[15px]">
      <IconFrameButton
        size="xSmall"
        icon={<IconTrash />}
        variant="outline"
        onClick={() => {
          setTargetRowData(rowData)
          onDeleteOpen()
        }}
        aria-label={deleteLabel}
        tooltipContent={deleteLabel}
      />
      <Tooltip content={openLabel}>
        {/* Need <span> wrapper for <Tooltip> to work when <Button> is using `asChild` */}
        <span className="rounded-2">
          {rowData.is_ready ||
          (checkFeatureFlag('INCREMENTAL_PROJECT_RESULTS') && rowData.required_columns_ready) ? (
            <Button {...openButtonProps} asChild>
              <Link
                to={openButtonRoute}
                onClick={() => {
                  trackEvent(MixpanelEvents.clickViewCleansetModelDeployment, {
                    modelId: rowData.id,
                    modelName: rowData.name,
                  })
                }}
              >
                Open
              </Link>
            </Button>
          ) : (
            <Button {...openButtonProps} disabled>
              Open
            </Button>
          )}
        </span>
      </Tooltip>
    </div>
  )
}

const colVersion = {
  field: 'version',
  headerName: 'Version',
  width: 100,
  minWidth: 100,
  flex: 1,
} as const satisfies ColDef<CleansetRowRes>

const colStatusBase = {
  field: 'status',
  headerName: 'Status',
  cellRenderer: CleansetStatusRenderer,
  width: 144,
  minWidth: 144,
  maxWidth: 200,
  flex: 1,
} as const satisfies ColDef<CleansetRowRes>

const colCreatedAt = {
  field: 'created_at',
  headerName: 'Created',
  valueGetter: (params) =>
    typeof params.data?.created_at === 'number'
      ? timestampToDate(params.data?.created_at)
      : params.data?.created_at,
  cellRenderer: (params: CleansetCellRendererParams) => {
    if (typeof params.data?.created_at !== 'number') {
      return null
    }
    return <DateOnlyWithTooltip timestamp={params.data?.created_at} />
  },
  width: 110,
  minWidth: 110,
  maxWidth: 130,
} as const satisfies ColDef<CleansetRowRes>

const colActionsBase = {
  field: 'delete',
  headerName: '',
  filter: false,
  sortable: false,
  resizable: false,
  cellRenderer: ActionsCellRenderer,
  cellClass: 'flex justify-end',
  pinned: 'right',
  width: 124,
  minWidth: 124,
  maxWidth: 224,
} as const satisfies ColDef<CleansetRowRes>

export const getColumnDefs = ({
  onNonViewableProjectModalOpen,
  setTargetRowData,
  onDeleteOpen,
}: ActionsCellParams & CleansetStatusRendererParams) => {
  return [
    colVersion,
    colIssuesFound,
    colIssuesResolved,
    {
      ...colStatusBase,
      cellRendererParams: { onNonViewableProjectModalOpen } satisfies CleansetStatusRendererParams,
    },
    colCreatedAt,
    {
      ...colActionsBase,
      cellRendererParams: { setTargetRowData, onDeleteOpen } satisfies ActionsCellParams,
    },
  ] satisfies ColDef<CleansetRowRes>[]
}
