import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { ColumnTypeV1 } from '@services/datasetApi'
import React from 'react'
import { FiCheck, FiChevronRight } from 'react-icons/fi'

import { getColumnTypeDisplayValue } from '../ColumnTypeMenu.types'
import { ColumnSubtypeMenuButtonProps } from './ColumnSubtypeMenuButton.types'

const ColumnSubtypeMenuButton = (props: ColumnSubtypeMenuButtonProps) => {
  const { columnType, subTypes, currentColumnType, handleSelectColumnType } = props
  const buttonColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const buttonHoverBgColor = useColorModeValue('neutral.100', 'neutralDarkMode.100')
  const columnTypeDisplayValue = getColumnTypeDisplayValue(currentColumnType)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: ColumnTypeV1 | undefined
  ) => {
    handleSelectColumnType(e, value)
    onClose()
  }

  return (
    <Menu placement="right-start" isOpen={isOpen} offset={[0, 0]}>
      <MenuButton
        as={Button}
        leftIcon={
          columnType.toLowerCase() === columnTypeDisplayValue ? <FiCheck /> : <Box boxSize="14px" />
        }
        rightIcon={<FiChevronRight />}
        variant="ghost"
        h="100%"
        w="100%"
        onClick={(e) => {
          e.stopPropagation()
        }}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        borderRadius="8px"
        fontSize="14px"
        textAlign="left"
        color={buttonColor}
        fontWeight="400px"
        lineHeight="20px"
        padding="6px"
        _hover={{
          bg: buttonHoverBgColor,
        }}
        _active={{
          bg: buttonHoverBgColor,
        }}
      >
        {columnType}
      </MenuButton>
      <MenuList zIndex={9999} padding="8px" onMouseEnter={onOpen} onMouseLeave={onClose}>
        {subTypes &&
          Object.entries(subTypes).map(([columnType, value]) => {
            return (
              <MenuItem
                onClick={(e) => {
                  handleClick(e, value)
                }}
                borderRadius="8px"
                fontSize="14px"
                fontWeight="400px"
                lineHeight="20px"
                color={buttonColor}
                _hover={{
                  bg: buttonHoverBgColor,
                }}
                icon={value === currentColumnType ? <FiCheck /> : <Box boxSize="14px" />}
              >
                {columnType}
              </MenuItem>
            )
          })}
      </MenuList>
    </Menu>
  )
}

export default ColumnSubtypeMenuButton
