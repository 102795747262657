export const usageTableBlockList = [
  'isCostCalculationEnabled',
  'id',
  'name',
  'createdAt',
  'licenseStartDate',
  'licenseEndDate',
]

export const UsageSummaryDict = {
  id: 'id',
  name: 'Name',
  createdAt: 'Created at',
  numUsers: 'User accounts',
  numCleansets: 'Cleansets created',
  numModelsDeployed: 'Models deployed',
  numInferenceRowsExported: 'Inference rows exported',
  numCleansetRowsExported: 'Cleanset rows exported',
  numCleansetRowsAnalyzed: 'Cleanset rows analyzed',
  numProjects: 'Projects created',
  numProjectsImage: 'Image Projects',
  numProjectsText: 'Text Projects',
  numProjectsTabular: 'Tabular Projects',
  isCostCalculationEnabled: 'Is cost calculation enabled',
}
