import {
  CLEANLAB_FRONTEND_COLUMN,
  CleansetDetailsProps,
  ProjectDetailsProps,
} from './datasheet/Datasheet.types'

export interface CleansetInternalProps {
  projectDetails: ProjectDetailsProps
  cleansetDetails: CleansetDetailsProps
  allColumns: string[]
  originalDatasetColumns: string[]
  columnStatuses: { [columnName: string]: string }
}

export interface PresetConfig {
  columns: CLEANLAB_FRONTEND_COLUMN[]
  sort: { column: CLEANLAB_FRONTEND_COLUMN | undefined; order: string | undefined }
}

export enum ColumnStatus {
  PENDING = 'pending',
  READY = 'ready',
  ERROR = 'error',
}

export type DatasheetColumnStatuses = { [columnName: string]: string }
