export const enum TooltipLabel {
  ACTION = 'What action was applied to each data point. Data points flagged with issues but no action was applied yet are marked “unresolved”.',
  AMBIGUOUS = 'Data points that are confusing or not well-described by any classes in your Dataset. Consider having experts review these.',
  AMBIGUOUS_SCORE = 'How ambiguous each data point’s true label might be. Higher values indicate data points where your data annotators are more likely to disagree.',
  CLEANLAB_ROW_ID = 'The row index of each data point in your original Dataset ordering.',
  CORRECTED = 'Final label to use for this data point. Will remain as the given label for data points where this field is empty (meaning no actions were applied).',
  CUSTOM_TAGS = 'Miscellaneous information about each data point.',
  DEFAULT = 'Click to Sort by Values',
  EMPTY_TEXT = 'Whether each data point has no text at all.',
  GIVEN = 'Given label in the original Dataset for each data point.',
  HAS_LABEL_ISSUE = 'Whether each data point’s given label is inferred to be potentially incorrect. True values indicate problematic labels you should review.',
  NEAR_DUPLICATE = 'Data points that are identical or nearly identical to other data. Extra copies might be appropriate to exclude from the Dataset.',
  NEAR_DUPLICATE_SCORE = 'Similarity between each data point and its nearest neighbor in the Dataset. Equals 1.0 for exactly duplicated data points.',
  NEAR_DUPLICATE_CLUSTER_ID = 'Filter/sort the data by this integer to see the near duplicates of a particular data point.',
  ISSUE_TAGS = 'Issues that were flagged for each data point.',
  OUTLIER = 'How atypical each data point appears relative to the rest of Dataset. Higher values indicate data points less similar to the rest of the data.',
  LABEL_ISSUE_SCORE = 'Likelihood that each data point is mislabeled. Higher scores indicate more worrisome data points.',
  SUGGESTED = 'Label that Cleanlab estimates may be more appropriate than the given label.',
  TEXT_LENGTH = 'Number of characters in the text for each data point.',
  UNLABELED = 'Whether each data point is missing a label in the original Dataset.',
  WELL_LABELED = 'Whether each data point’s label in the original data is confidently correct. You don’t need to review data points where this value is True.',
}
