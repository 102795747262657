import { useClipboard } from '@chakra-ui/react'
import { IconCheck, IconCopy } from '@components/icons'
import { Tooltip } from '@components/tooltip/Tooltip'
import React, {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from 'react'

const Tag = forwardRef(
  ({ ...props }: ComponentPropsWithoutRef<'button'>, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <button
        ref={ref}
        className="type-badge-code-sm flex cursor-pointer items-center gap-2 rounded-1 bg-surface-1 px-3 text-text-faint outline outline-1 -outline-offset-1 outline-border-1 hover:bg-surface-1-hover focus-visible:outline-blue-700 focus-visible:ring focus-visible:ring-focus"
        {...props}
      />
    )
  }
)

export const CopyTagBase = (
  {
    children,
    copyText,
    tooltipContent = 'Click to copy',
    copiedTooltipContent = 'Copied!',
    onClick,
    ...props
  }: {
    children: ReactNode
    copyText: string
    tooltipContent?: ReactNode
    copiedTooltipContent?: ReactNode
  } & ComponentPropsWithoutRef<typeof Tag>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const { onCopy } = useClipboard(copyText)
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false)
      }, 2000)
      return () => clearTimeout(timeout)
    }
  }, [copied])

  const copyToClipboard: React.MouseEventHandler<HTMLSpanElement> = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    onCopy()
    setCopied(true)
    onClick?.(e)
  }

  return (
    <Tooltip content={copied ? copiedTooltipContent : tooltipContent}>
      <Tag ref={ref} onClick={copyToClipboard} {...props}>
        {children}
        {!copied && <IconCopy size={11} aria-label="Copy" />}
        {copied && <IconCheck size={11} aria-label="Copied" />}
      </Tag>
    </Tooltip>
  )
}
const CopyTag = forwardRef(CopyTagBase)

export const CopyIdTagBase = (
  {
    id,
    ...props
  }: { id: string } & Omit<ComponentPropsWithoutRef<typeof CopyTag>, 'copyText' | 'children'>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const truncatedId = id.substring(0, 7)

  return (
    <CopyTag
      ref={ref}
      copyText={id}
      tooltipContent={
        <>
          Click to copy full ID: <span className="font-mono">{truncatedId}</span>…
        </>
      }
      {...props}
    >
      ID
    </CopyTag>
  )
}
export const CopyIdTag = forwardRef(CopyIdTagBase)
