import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import { CLEANLAB_FRONTEND_COLUMN } from '../datasheet/Datasheet.types'
import { FilterData, FilterOperator, FilterState, PresetHeaders } from './FilterState.types'

const presetFilters: (tasktype: Tasktype) => FilterData[] = (tasktype: Tasktype) => [
  {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Given,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    hide: tasktype === Tasktype.UNSUPERVISED,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Suggested,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    hide: tasktype === Tasktype.UNSUPERVISED,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.ISSUE,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Issue,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.OUTLIER,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Outlier,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.UNLABELED,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Unlabeled,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Ambiguous,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.NearDuplicate,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    isChecked: false,
    hide: true,
  },
  {
    property: CLEANLAB_FRONTEND_COLUMN.ISSUE_TAGS,
    filterType: FilterOperator.All,
    filterInput: '',
    headerName: PresetHeaders.Issues,
    agGridType: '',
    isOpen: false,
    isPreset: false,
    hide: tasktype === Tasktype.UNSUPERVISED,
  },
]

const presetMultiLabelFeatures = (tasktype: Tasktype) =>
  presetFilters(tasktype).map((filter) => {
    if (
      filter.property === CLEANLAB_FRONTEND_COLUMN.GIVEN ||
      filter.property === CLEANLAB_FRONTEND_COLUMN.SUGGESTED
    ) {
      return { ...filter, filterInput: [] }
    }
    return filter
  })

export const getPresetFilters = (tasktype: Tasktype): FilterData[] => {
  return tasktype === Tasktype.MULTILABEL
    ? presetMultiLabelFeatures(tasktype)
    : presetFilters(tasktype)
}

export const initialFilterState = (tasktype: Tasktype): FilterState => ({
  filterData: getPresetFilters(tasktype),
  addFilterMenuOpen: false,
  tasktype: tasktype,
})
