import { Link } from 'react-router-dom'
import { CreateProjectNav, PreviousLink } from 'src/pages/projectForm/CreateProject.helpers'

import { UPLOAD_PATH_NAME } from './DatasetUpload.types'

export const UploadBackLink = () => (
  <CreateProjectNav>
    <PreviousLink asChild>
      <Link to={`/${UPLOAD_PATH_NAME}`}>Import data</Link>
    </PreviousLink>
  </CreateProjectNav>
)
