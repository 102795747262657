import { ForwardedRef, forwardRef, memo } from 'react'

import { DEFAULT_PRECISION, ratioFormatter, RatioFormatterProps } from './ratioFormatter'

const ProbabilityBase = (
  {
    p,
    ...props
  }: Pick<RatioFormatterProps, 'percentPadding' | 'roundDirection' | 'format'> & {
    p: RatioFormatterProps['ratio']
  },
  ref: ForwardedRef<HTMLSpanElement>
) => (
  <span className="tabular-nums" ref={ref}>
    {ratioFormatter({
      ...props,
      ratio: p,
      precision: props.format === 'percent' ? DEFAULT_PRECISION - 2 : DEFAULT_PRECISION,
      probabilityMode: true,
    })}
  </span>
)

export const Probability = memo(forwardRef(ProbabilityBase))
