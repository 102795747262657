import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys } from '@services/userQuota/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const apiVersion = 'v1'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/user-quotas`,
  withCredentials: true,
})

export const useUpdateUserQuotaMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async ({
      userId,
      dataStorageLimit,
      tlmTokenLimit,
      tlmTokenPerMinuteLimit,
      tlmPreviewModelsRequestLimit,
    }: {
      userId: string
      dataStorageLimit: number
      tlmTokenLimit: number
      tlmTokenPerMinuteLimit: number
      tlmPreviewModelsRequestLimit: number
    }) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        data_storage_limit: (dataStorageLimit * 1e9).toString(),
        tlm_token_limit: tlmTokenLimit.toString(),
        tlm_token_per_minute_limit: tlmTokenPerMinuteLimit.toString(),
        tlm_preview_models_request_limit: tlmPreviewModelsRequestLimit.toString(),
      }
      await axiosClient.patch(`/${userId}`, body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.quotas.all })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}

export const useDeleteUserQuotasMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async (userIds: string[]) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        user_ids: userIds,
      }
      await axiosClient.post('/deletes', body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.quotas.all })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}
