import { Badge } from '@components/badge/Badge'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { ReactNode } from 'react'

import testIds from '../../../playwright/test_ids.json'

export interface ModelTypeDetails {
  name: string
  description: string
  dataTestId?: string
}

export enum AdminModelType {
  FAST = 'FAST',
  REGULAR = 'REGULAR',
  FAKE = 'FAKE',
  HYPER_FAST = 'HYPER_FAST',
}

export enum ModelType {
  FAST = 'FAST',
  REGULAR = 'REGULAR',
}

export const DEFAULT_MODEL_TYPE = ModelType.REGULAR

const fastModelChoice = {
  name: 'Fast',
  description: 'Quickest results for early Project iterations. Ready in 15-90 minutes.',
  dataTestId: testIds.createProjectPageFastModeRadioCard,
} as const satisfies ModelTypeDetails

const regularModelChoice = {
  name: 'Regular',
  description: 'Deepest analysis and highest quality results. Average time is 5 hours.',
  dataTestId: testIds.createProjectPageRegularModeRadioCard,
} as const satisfies ModelTypeDetails

export const adminModelChoices: Record<AdminModelType, ModelTypeDetails> = {
  [AdminModelType.FAST]: fastModelChoice,
  [AdminModelType.REGULAR]: regularModelChoice,
  [AdminModelType.FAKE]: {
    name: 'Fake mode',
    description: 'Quick debugging for admins. Ready in 0-5 minutes',
    dataTestId: testIds.createProjectPageFakeModeRadioCard,
  },
  [AdminModelType.HYPER_FAST]: {
    name: 'Hyperfast mode',
    description: 'Quick debugging with real training for admins. Ready in 5-30 minutes.',
    dataTestId: testIds.createProjectPageHyperFastModeRadioCard,
  },
}

export const modelChoices = {
  [ModelType.FAST]: fastModelChoice,
  [ModelType.REGULAR]: regularModelChoice,
} as const satisfies Record<ModelType, ModelTypeDetails>

export enum Modality {
  text = 'text',
  tabular = 'tabular',
  image = 'image',
  other = 'other',
}

export function isModality(modality: string): modality is Modality {
  const mod = modality as Modality
  return (
    mod === Modality.text ||
    mod === Modality.tabular ||
    mod === Modality.image ||
    mod === Modality.other
  )
}

export interface ModalityDetails {
  name: string
  description: string
  disabledText: string
  dataTestId: string
}

export const modalityChoices = {
  [Modality.text]: {
    name: 'Text classification',
    description:
      'Analyze and fix PII, bias, toxicity, outliers, label errors, and more in a text column',
    disabledText: 'Text Modality is disabled because the Dataset has no text columns',
    dataTestId: testIds.createProjectPageTextModalityRadioCard,
  },
  [Modality.tabular]: {
    name: 'Tabular classification',
    description: 'Analyze and fix tabular data based on several columns of features.',
    disabledText: 'Tabular Modality is disabled because the Dataset has no feature columns',
    dataTestId: testIds.createProjectPageTabularModalityRadioCard,
  },
  [Modality.image]: {
    name: 'Image classification',
    description:
      'Analyze and fix NSFW, duplicates, darkness, blur, outliers, label errors, and more for each image.',
    disabledText: 'Image Modality is disabled because this is not an image Dataset',
    dataTestId: testIds.createProjectPageImageModalityRadioCard,
  },
  [Modality.other]: {
    name: 'Enterprise ML tasks',
    description:
      'Auto-curate and improve data in AI use cases: Named Entity Recognition, Tagging, Object Detection, and more.',
    disabledText: '',
    dataTestId: testIds.createProjectPageOtherModalityRadioCard,
  },
} as const satisfies Record<Modality, ModalityDetails>

export enum ProjectType {
  CURATION = 'curation',
  ENRICHMENT = 'enrichment',
}

export enum Tasktype {
  MULTICLASS = 'multi-class',
  MULTILABEL = 'multi-label',
  UNSUPERVISED = 'unsupervised',
}

export enum WizardTasktype {
  MULTICLASS = 'multi-class',
  MULTILABEL = 'multi-label',
}

export enum BetaTasktype {
  NAMED_ENTITY_RECOGNITION = 'named-entity-recognition',
  OBJECT_DETECTION = 'object-detection',
  IMAGE_SEGMENTATION = 'image-segmentation',
  REGRESSION = 'regression',
}

export interface ProjectTypeDetails {
  name: ReactNode
  description: ReactNode
  dataTestId: string
  disabledText?: string
  isDisabled?: (arg: any) => boolean
}

export const projectTypeChoices = {
  [ProjectType.CURATION]: {
    name: (
      <div className="flex items-center justify-between gap-5">
        <div>Data Curation</div>
      </div>
    ),
    description: 'Find and fix data issues, label data, train & deploy supervised ML model.',
    dataTestId: testIds.createProjectPageProjectTypeCurationRadio,
  },
  [ProjectType.ENRICHMENT]: {
    name: 'Data Enrichment',
    description: 'Generate a new column of metadata based on the existing data rows.',
    dataTestId: testIds.createProjectPageProjectTypeEnrichmentRadio,
    disabledText: checkFeatureFlag('ENRICHMENT_UI_ENABLED')
      ? 'Data enrichment project are not available for image datasets'
      : 'This feature is currently disabled',
    isDisabled: (modality) =>
      modality === Modality.image || !checkFeatureFlag('ENRICHMENT_UI_ENABLED'),
  },
} as const as Record<ProjectType, ProjectTypeDetails>

export interface TasktypeDetails {
  name: ReactNode
  description: ReactNode
  dataTestId?: string
}

export const tasktypeChoices = {
  [Tasktype.MULTICLASS]: {
    name: (
      <div className="flex items-center justify-between gap-5">
        <div>Multi-class</div>
        <Badge size="small" variant="subtle" round="full" hue="neutral">
          Most common
        </Badge>
      </div>
    ),
    description: 'Each data point belongs to exactly 1 out of K classes. e.g. [dog, cat, or bear].',
    dataTestId: testIds.createProjectPageTaskTypeMulticlassRadio,
  },
  [Tasktype.MULTILABEL]: {
    name: 'Multi-label',
    description:
      'Each data point belongs to 0 or more of K classes. e.g. tagging photos with zero or more of {smiling, has_hat, has_glasses}.',
    dataTestId: testIds.createProjectPageTaskTypeMultilabelRadio,
  },
  [Tasktype.UNSUPERVISED]: {
    name: 'Unsupervised learning',
    description: 'Data points are not associated with explicit labels.',
    dataTestId: testIds.createProjectPageTaskTypeUnsupervisedRadio,
  },
} as const satisfies Record<Tasktype, TasktypeDetails>

export const betaTasktypeChoices = {
  [BetaTasktype.NAMED_ENTITY_RECOGNITION]: {
    name: 'Named-entity Recognition',
    description:
      'Locate and classify named entities mentioned in unstructured text into 1 of K categories.',
  },
  [BetaTasktype.OBJECT_DETECTION]: {
    name: 'Object Detection',
    description:
      'Locate and classify zero or more objects in an image, with each object belonging to exactly 1 out of K classes.',
  },
  [BetaTasktype.IMAGE_SEGMENTATION]: {
    name: 'Image Segmentation',
    description:
      'Partition an image into multiple regions, classifying each region as belonging to exactly 1 out of K classes.',
  },
  [BetaTasktype.REGRESSION]: {
    name: 'Regression',
    description:
      'Estimate a real-valued or vector-valued dependent variable based on independent variables.',
  },
} as const satisfies Record<BetaTasktype, TasktypeDetails>
