import { FEATURE_FLAG_COOKIE_NAME } from '../environmentVariables'
export const FEATURE_FLAG_DELIMINATOR = '|'

export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return (parts.pop() ?? '').split(';').shift()
}

export const FEATURE_FLAGS = [
  'IS_ADMIN',
  'ENRICHMENT_UI_ENABLED',
  'INCREMENTAL_PROJECT_RESULTS',
  'TEMPLATE_PROJECT_ENABLED',
] as const

export const checkFeatureFlag = (flag_name: (typeof FEATURE_FLAGS)[number]): boolean => {
  const cookie = getCookie(FEATURE_FLAG_COOKIE_NAME)
  const flag_index = cookie?.split(FEATURE_FLAG_DELIMINATOR).indexOf(flag_name)
  return flag_index !== undefined ? flag_index !== -1 : false
}
