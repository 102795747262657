import { useContext } from 'react'

import { FilterContext, FilterDispatchContext } from './FilterContext'
import { UseFiltersReturnType } from './FilterState.types'

export const useFilters = (): UseFiltersReturnType => {
  const filterState = useContext(FilterContext)
  const filterDispatch = useContext(FilterDispatchContext)

  if (!filterState) {
    throw new Error('Filter state cannot be null within filter context provider')
  }

  if (!filterDispatch) {
    throw new Error('Filter dispatch cannot be null within filter dispatch context provider')
  }

  return {
    state: filterState,
    dispatch: filterDispatch,
  }
}
