import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import Link from '@components/link/Link'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import React from 'react'

import { SalesModalProps } from './SalesModal.types'

const SalesModal = (props: SalesModalProps) => {
  const { isOpen, onClose } = props
  const { trackEvent } = useEventTracking()
  const { freeTrialPeriod, payingCustomer, isIndividualPayingCustomer } = useSubscription()
  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>
          Upgrade Your Plan -{' '}
          {!payingCustomer || isIndividualPayingCustomer ? 'Contact Sales' : 'Contact Support'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems="flex-start" spacing={6} fontSize="md">
            {freeTrialPeriod || isIndividualPayingCustomer ? (
              <>
                {!isIndividualPayingCustomer && (
                  <Text>
                    You are currently on the free trial of Cleanlab Studio. The trial allows you to
                    access no-code automated data curation, state-of-the-art ML model deployment,
                    the API, and more.
                  </Text>
                )}
                <Text>
                  Certain features and increased usage limits are only available in Cleanlab Studio
                  for Enterprise.
                </Text>
              </>
            ) : !payingCustomer ? (
              <Text>
                Your Free Trial of Cleanlab Studio has expired - many of the core functionalities
                are disabled. You can still view projects you previously created and export
                previously processed data.
              </Text>
            ) : (
              <>
                <Text>
                  You've reached your quota for this billing period on your current billing plan.
                </Text>
                <Text>
                  To increase your limits, reach out to{' '}
                  <Link href="mailto:support@cleanlab.ai" isExternal>
                    support@cleanlab.ai
                  </Link>{' '}
                  or <Link href="https://cleanlab.ai/sales/">schedule a meeting</Link> with us.
                </Text>
              </>
            )}
            {(!payingCustomer || isIndividualPayingCustomer) && (
              <Text>
                {freeTrialPeriod || isIndividualPayingCustomer
                  ? 'To learn more about Cleanlab Studio, enterprise features, or pricing'
                  : 'To continue using Cleanlab Studio'}
                , email{' '}
                <Link
                  href="mailto:sales@cleanlab.ai"
                  isExternal
                  onClick={() => {
                    trackEvent(MixpanelEvents.clickContactUsButton)
                  }}
                >
                  sales@cleanlab.ai
                </Link>{' '}
                or{' '}
                <Link href="https://cleanlab.ai/sales/" isExternal>
                  schedule a demo / chat
                </Link>{' '}
                with us!
              </Text>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SalesModal
