export enum ISSUE_TYPE {
  LABEL_ISSUE = 'label issue',
  UNLABELED = 'unlabeled',
  AMBIGUOUS = 'ambiguous',
  OUTLIER = 'outlier',
  NEAR_DUPLICATE = 'near duplicate',
}

export enum ISSUE_TYPE_COLOR {
  LABEL_ISSUE = '#feb2b2',
  UNLABELED = '#9ae6b4',
  AMBIGUOUS = '#9bdaf3',
  OUTLIER = '#fbd38d',
  NEAR_DUPLICATE = '#bf83bf',
}
