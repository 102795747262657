import { PREDICTION_STATUS } from './ExportModelGrid.types'

const PredictionStatusNumber: Record<PREDICTION_STATUS, string> = {
  INITIALIZING: '1',
  INFERRING: '2',
  COMPLETE: '3',
  FAILED: '',
  QUOTA_FAILURE: '',
}

const PredictionStatusName: Record<PREDICTION_STATUS, string> = {
  INITIALIZING: 'Initializing',
  INFERRING: 'Inferring',
  COMPLETE: 'Complete',
  FAILED: '',
  QUOTA_FAILURE: '',
}

const statusEnumToDesc = (status: PREDICTION_STATUS) => {
  if (status === PREDICTION_STATUS.COMPLETE) {
    // check ready state first
    return PredictionStatusName[status]
  } else if (
    Object.keys(PredictionStatusNumber).includes(status) &&
    status !== PREDICTION_STATUS.FAILED &&
    status !== PREDICTION_STATUS.QUOTA_FAILURE
  ) {
    return `(${PredictionStatusNumber[status]}/3) ${PredictionStatusName[status]}`
  } else {
    return PredictionStatusName[status]
  }
}

export default statusEnumToDesc
