import {
  ENV_SETTINGS,
  EnvironmentType,
  REACT_APP_CLEANLAB_API_URL,
} from '@utils/environmentVariables'
import mixpanel from 'mixpanel-browser'

// Public project tokens for Mixpanel
const MIXPANEL_PROJECT_TOKENS: Record<EnvironmentType, string> = {
  production: 'e569563a0df071ffb04bf2b70af48523',
  staging: '10f08e46aa6a7bdd9501ddd769ea2d6c',
  local_staging: '10f08e46aa6a7bdd9501ddd769ea2d6c',
  development: '506599dc8e880dce0f87ce82fab9ccc4',
  infra_dev: '13ad0292ade46c26a596be004cdbd1ee',
  test: '84cf742bdde09a4d7f2edbbdbf010264',
  hosted_dev: '13ad0292ade46c26a596be004cdbd1ee', // same project as infra_dev
  vpc: '13ad0292ade46c26a596be004cdbd1ee', // same project as infra_dev
}
export const MIXPANEL_PROJECT_TOKEN = MIXPANEL_PROJECT_TOKENS[ENV_SETTINGS]

MIXPANEL_PROJECT_TOKEN &&
  mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
    api_host: `${REACT_APP_CLEANLAB_API_URL}/api/user_events/mixpanel`,
    ignore_dnt: true,
  })

const actions = {
  identify: (id: string) => {
    mixpanel.identify(id)
  },
  alias: (id: string) => {
    mixpanel.alias(id)
  },
  track: (name: string, props?: object) => {
    mixpanel.track(name, props)
  },
  people: {
    set: (props: object) => {
      mixpanel.people.set(props)
    },
  },
}

export const Mixpanel = actions
