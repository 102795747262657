import { cn } from '@utils/tailwindUtils'
import { ForwardedRef, forwardRef, memo, ReactElement, useMemo } from 'react'

const ProgressStep = memo(({ complete }: { complete: boolean }) => {
  return (
    <div className={'h-8 w-3 rounded-full ' + (complete ? 'bg-blue-600' : 'bg-surface-2-hover')} />
  )
})

type ProgressStepperProps = { stepCount: number; stepNum: number; className?: string }
export const ProgressStepperBase = (
  { stepCount, stepNum, className }: ProgressStepperProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const steps = useMemo(() => {
    const steps: ReactElement[] = []
    for (let i = 0; i < stepCount; i++) {
      steps.push(<ProgressStep key={i} complete={i < stepNum} />)
    }
    return steps
  }, [stepCount, stepNum])

  return (
    <div ref={ref} className={cn('contents', className)}>
      <div className="sr-only">
        Step {stepNum + 1} of {stepCount}
      </div>
      <div className="flex gap-4" aria-hidden>
        {steps}
      </div>
    </div>
  )
}

export const ProgressStepper = memo(forwardRef(ProgressStepperBase))
ProgressStepper.displayName = 'ProgressStepper'
