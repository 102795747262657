import { Button, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import queryApiService from '@services/queryApi'
import React, { useState } from 'react'

import { ControlledFormInputElement, QueryResults } from './QueryInterface.helpers'

const QueryInterfaceFindUser = () => {
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [queryResults, setQueryResults] = useState([])

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Query Cleanlab Studio Users</Heading>
      <Text>Search a user of Cleanlab studio by id, name or email. Two possibilities:</Text>
      <Text>
        <strong>Singleton Search</strong> - Enter the exact email of the user or the exact id of the
        user.
      </Text>
      <Text>
        <strong>Multi User Search</strong> - Enter a partial sequence of user’s name. Eg. Enter Joh
        to search for John Doe.
      </Text>
      <Text>If an email is given, name is ignored.</Text>
      <HStack>
        <ControlledFormInputElement id="id" type="id" label="Id" value={id} setValue={setId} />

        <ControlledFormInputElement
          id="email"
          type="email"
          label="Email"
          value={email}
          setValue={setEmail}
        />

        <ControlledFormInputElement
          id="name"
          type="text"
          label="Name"
          value={name}
          setValue={setName}
        />
      </HStack>
      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApiService.query({
            resource: 'user',
            id,
            name,
            email,
          })
          setQueryResults(response.data)
        }}
        aria-label="find user"
      >
        Find User
      </Button>

      <QueryResults data={queryResults} resource="users" />
    </VStack>
  )
}

export default QueryInterfaceFindUser
