import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import { NotificationSetting, queryKeys } from './constants'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/user_notification_settings`,
  withCredentials: true,
})

export const useDisableNotificationSetting = (notificationSetting: NotificationSetting) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const accessToken = await auth.getTokenSilently()
      await axiosClient.patch(`/disable/${notificationSetting}`, {}, createAuthHeaders(accessToken))
    },
    meta: { error: 'Error disabling current user notification settings', suppress: true },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.notificationSettings.all())
    },
  })
}
