import { EmailIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  HStack,
  Text,
} from '@chakra-ui/react'
import { UserQuotaAlertProps } from '@common/alerts/userQuotaAlert/UserQuotaAlert.types'
import React from 'react'

const UserQuotaAlert = (props: UserQuotaAlertProps) => {
  const { info, setInfo } = props
  const cancelRef = React.useRef(null)

  const renderQuotaBody = () => {
    return (
      <>
        <Text>You have reached the free tier limit!</Text>
        <br />
        <Text>
          To continue using Cleanlab, either delete unused resources or contact us to discuss your
          use case.
        </Text>
        <br />
        <HStack spacing="1rem">
          <EmailIcon color="teal.400" />
          <Text>support@cleanlab.ai</Text>
        </HStack>
        <br />
      </>
    )
  }

  return (
    info && (
      <>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={() => setInfo(null)}
          isOpen={info.code === 'user_quota_exceeded'}
          size="lg"
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>Free Tier Quota Reached</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>{renderQuotaBody()}</AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      </>
    )
  )
}

export default UserQuotaAlert
