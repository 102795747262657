import { cn, tw } from '@utils/tailwindUtils'
import React, { ForwardedRef, forwardRef, memo } from 'react'

import { DEFAULT_ICON_SIZE, ICON_SIZES } from './iconConsts'

type IconSize = (typeof ICON_SIZES)[number]
// TODO: Check with design team to determine these final values
const classes = tw({
  12: '[&>svg]:stroke-[1.8px]',
  14: '[&>svg]:stroke-[1.7px]',
  16: '[&>svg]:stroke-[1.65px]',
  24: '[&>svg]:stroke-[1.5px]',
  32: '[&>svg]:stroke-[1.25px]',
} as const satisfies Record<IconSize, string>)

const findNearestSize = (num: number) => {
  for (let i = 1; i < ICON_SIZES.length; i++) {
    if (num < ICON_SIZES[i]) {
      return ICON_SIZES[i - 1]
    }
  }
  return ICON_SIZES[ICON_SIZES.length - 1]
}

const WrapperBase = (
  {
    size = DEFAULT_ICON_SIZE,
    className,
    children,
    ...props
  }: Omit<React.ComponentProps<'div'>, 'children'> & {
    children: React.ReactElement
    size?: number
  },
  ref: any
) => {
  const child = React.Children.only(children)
  if (!child) {
    return null
  }
  const clone = React.cloneElement(child, {
    size: size,
  })
  const nearestSize = findNearestSize(size)

  return (
    <div ref={ref} className={cn(classes[nearestSize], className)} role="img" {...props}>
      {clone}
    </div>
  )
}
const Wrapper = forwardRef(WrapperBase)

export const wrapFeatherIcon = (Icon: React.ComponentType) => {
  const baseComponent = (
    props: Omit<React.ComponentProps<typeof Wrapper>, 'children'>,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Wrapper ref={ref} {...props}>
      <Icon />
    </Wrapper>
  )
  const component = memo(forwardRef(baseComponent))
  component.displayName = `FeatherIcon(${Icon.displayName || Icon.name || 'UnknownIcon'})`
  return component
}
