import { Button, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import queryApi from '@services/queryApi'
import React, { useState } from 'react'

import { ControlledFormInputElement, QueryResults } from './QueryInterface.helpers'

const QueryInterfaceFindDataset = () => {
  const [userId, setUserId] = useState('')
  const [datasetId, setDatasetId] = useState('')
  const [queryResults, setQueryResults] = useState(null)

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Query Datasets</Heading>
      <Text>
        <strong>Instructions:</strong> Datasets can be queried using UserID or DatasetID.
      </Text>
      <Text>If a UserID is given, all Datasets for a given user is returned.</Text>
      <Text>If a DatasetID is given, the UserID is ignored.</Text>
      <HStack>
        <ControlledFormInputElement
          id="user_id"
          type="text"
          label="UserID"
          value={userId}
          setValue={setUserId}
        />
        <ControlledFormInputElement
          id="dataset_id"
          type="text"
          label="DatasetID"
          value={datasetId}
          setValue={setDatasetId}
        />
      </HStack>
      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApi.query({
            resource: 'dataset',
            user_id: userId,
            dataset_id: datasetId,
          })
          setQueryResults(response.data)
        }}
      >
        Find Dataset
      </Button>

      <QueryResults data={queryResults} resource="datasets" />
    </VStack>
  )
}

export default QueryInterfaceFindDataset
