import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import Link from '@components/link/Link'
import { useTlmTokenCosts } from '@services/billing/queries'
import { useMyself } from '@services/user/queries'
import { useMemo, useState } from 'react'

import { ModelOption, TlmPricingItem } from './TlmPricingModal.types'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const TlmPricingModal = (props: Props) => {
  const { isOpen, onClose } = props
  const { emailConfirmed } = useMyself()
  const tlmTokenCosts = useTlmTokenCosts(emailConfirmed)
  const [model, setModel] = useState<ModelOption | null>(null)

  const modelOptions = [
    ...new Set(
      tlmTokenCosts?.map((item: TlmPricingItem) => item.modelType) ?? Object.keys(ModelOption)
    ),
  ]

  const filteredTlmTokenCosts = useMemo(
    () =>
      [...tlmTokenCosts].filter(
        (item) => item.modelType === (model ?? tlmTokenCosts?.[0]?.modelType)
      ),
    [model, tlmTokenCosts]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>TLM Pricing</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <div className="overflow-x-auto">
            <div className="type-body-100 flex flex-col gap-6 text-text-primary">
              <p>
                TLM pricing varies by the underlying model and the quality preset chosen (default
                preset is “medium”). The table below shows costs per 1000 tokens.
              </p>
              <p>
                Daily TLM costs incurred will show up as a single line item in your usage and
                billing dashboard. You will be billed at the end of each month. If you have
                questions, contact{' '}
                <Link href="mailto:support@cleanlab.ai">support@cleanlab.ai</Link>.
              </p>
            </div>
            <div className="flex items-center gap-4 pb-4 pt-5">
              <Select
                maxW="fit-content"
                onChange={(evt) => setModel(evt.target.value as ModelOption)}
              >
                {modelOptions.map((option: ModelOption) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </div>
            <div className="border-1 overflow-hidden rounded-2 border">
              <table className="min-w-full divide-y divide-border-0">
                <thead className="type-body-200 bg-surface-0 text-text-primary">
                  <tr className="text-left">
                    <th scope="col" className="tracking-wider px-6 py-3">
                      Quality Preset
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Input
                    </th>
                    <th scope="col" className=" px-6 py-3">
                      Output
                    </th>
                  </tr>
                </thead>

                <tbody className="type-body-100 divide-y divide-border-0 bg-surface-0 text-text-primary">
                  {filteredTlmTokenCosts.map((item) => (
                    <tr key={`${item.modelType}-${item.qualityPreset}`}>
                      <td className="text-sm w-1/3 whitespace-nowrap px-6 py-4 font-medium">
                        {item.qualityPreset}
                      </td>
                      <td className="text-sm w-1/3 whitespace-nowrap px-6 py-4">
                        ${item.inputTokenCostDollarsPerThousand}
                      </td>
                      <td className="text-sm w-1/3 whitespace-nowrap px-6 py-4">
                        ${item.outputTokenCostDollarsPerThousand}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex flex-row-reverse gap-5">
            <Button variant="secondary" onClick={onClose} aria-label="close">
              Close
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TlmPricingModal
