import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Flex, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { ComputedCell, HeatMapDatum, ResponsiveHeatMap } from '@nivo/heatmap'
import { useContext } from 'react'
import { CleansetContext } from 'src/pages/cleanset/CleansetContext'

import {
  CHART_BASE_MARGINS,
  CHART_MARGIN_LEFT,
  CHART_TEXT_OFFSET,
  CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
  getChartTheme,
  getRGBValues,
  returnLongestLabelLength,
} from '../CleansetCharts.helpers'
import { CorrectionsHeatmapTooltip, SuggestionsHeatmapTooltip } from './CorrectionsHeatmap.helpers'
import { CorrectionsHeatmapProps } from './CorrectionsHeatmap.types'

const CorrectionsHeatmap = (props: CorrectionsHeatmapProps) => {
  const { colorMode } = useColorMode()
  const isLightMode = colorMode === 'light'
  const { data, leftTitle, tooltip, squareClickEvent, onClick, onClose } = props

  const zeroColor = useColorModeValue('rgb(244, 247, 250)', 'rgb(39, 45, 58)')
  const zeroTextColor = useColorModeValue('rgb(39, 45, 58)', 'rgb(244, 247, 250)')

  const emptyColor = useColorModeValue('rgb(233,237, 241)', 'rgb(61, 69, 87)')

  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()

  const trackClickEvent = (square: ComputedCell<HeatMapDatum>) => {
    const { value, data, serieId } = square
    const barData = {
      givenLabel: serieId,
      newLabel: data.x,
      squareCount: value,
    }

    if (squareClickEvent) {
      trackEvent(squareClickEvent, { ...cleansetInfo, ...barData })
    }

    if (onClick) {
      onClick(square)
      onClose()
    }
  }

  const labels = data.map((v) => v.id)
  const maxBottomAxisLabelLength = 10
  const maxLeftAxisLabelLength = 18
  const greatestLabelLength = returnLongestLabelLength(labels)
  const additionalLeftMargin = returnLongestLabelLength(labels) * 7.5

  const leftOffset = additionalLeftMargin + 35
  const bottomTickRotation = -20

  const allValues = data.map((d) => d.data.map((v) => v.y || 0)).flat()
  const maxValue = Math.max(...allValues)
  const noHeatmapBackground = useColorModeValue('gray.100', 'gray.800')
  if (data.length > 20) {
    return (
      <Flex
        w="100%"
        h="100%"
        justify="center"
        align="center"
        fontStyle="italic"
        bgColor={noHeatmapBackground}
      >
        Too many label classes for heatmap to be rendered.
      </Flex>
    )
  }

  return (
    <ResponsiveHeatMap
      data={data}
      onClick={trackClickEvent}
      margin={{
        ...CHART_BASE_MARGINS,
        left:
          greatestLabelLength < maxLeftAxisLabelLength
            ? 0
            : maxLeftAxisLabelLength * CHART_TEXT_OFFSET + CHART_MARGIN_LEFT,
        bottom:
          CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM +
          (greatestLabelLength < 20 ? greatestLabelLength + 7 : greatestLabelLength),
      }}
      tooltip={tooltip === 'suggestions' ? SuggestionsHeatmapTooltip : CorrectionsHeatmapTooltip}
      valueFormat=">-.3~s"
      forceSquare={greatestLabelLength < maxLeftAxisLabelLength}
      axisBottom={{
        tickSize: 5,
        tickPadding: 8,
        tickRotation: greatestLabelLength > 1 ? bottomTickRotation : 0,
        format: (value) => {
          const valueString = String(value)

          return `${valueString.substring(0, maxBottomAxisLabelLength)}${
            valueString.length > maxBottomAxisLabelLength ? '...' : ''
          }`
        },
      }}
      axisTop={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: leftTitle,
        legendPosition: 'middle',
        legendOffset: -leftOffset - 10,
        format: (value) => {
          const valueString = String(value)

          return `${valueString.substring(0, maxLeftAxisLabelLength)}${
            valueString.length > maxLeftAxisLabelLength ? '...' : ''
          }`
        },
      }}
      colors={(datum) => {
        // use custom coloring function to make the distinction between 0 and the minValue > 0 more clear
        if (!datum.data.y) {
          return zeroColor
        }
        // number >= 0, bigger numbers make nonzero squares more blueish

        const { r, g, b } = getRGBValues(isLightMode, datum.data.y, maxValue)

        return `rgb(${r} ${g} ${b})`
      }}
      emptyColor={emptyColor}
      labelTextColor={(datum) => {
        if (!datum.data.y) {
          return zeroTextColor
        }
        const { r, g, b } = getRGBValues(isLightMode, datum.data.y, maxValue)
        const useDarkColor = r * 0.299 + g * 0.587 + b * 0.114 > 150
        return useDarkColor ? cleanlabColors.neutral[900] : cleanlabColors.neutral[0]
      }}
      theme={getChartTheme(colorMode === 'light')}
    />
  )
}

export default CorrectionsHeatmap
