import { Box, Button, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { FiCheck } from 'react-icons/fi'

import { getColumnTypeDisplayValue } from '../ColumnTypeMenu.types'
import { ColumnTypeMenuButtonProps } from './ColumnTypeMenuButton.types'

const ColumnTypeMenuButton = (props: ColumnTypeMenuButtonProps) => {
  const { columnType, currentColumnType } = props
  const buttonColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const buttonHoverBgColor = useColorModeValue('neutral.100', 'neutralDarkMode.100')
  const columnTypeDisplayValue = getColumnTypeDisplayValue(currentColumnType)

  return (
    <Button
      variant="ghost"
      borderRadius="8px"
      fontSize="14px"
      fontWeight="400px"
      lineHeight="20px"
      padding="6px"
      h="100%"
      color={buttonColor}
      _hover={{
        bg: buttonHoverBgColor,
      }}
      leftIcon={
        columnType.toLowerCase() === columnTypeDisplayValue ? <FiCheck /> : <Box boxSize="14px" />
      }
    >
      {columnType}
    </Button>
  )
}

export default ColumnTypeMenuButton
