import { useDisclosure, useToast } from '@chakra-ui/react'
import Loading from '@common/layout/loading/Loading'
import LoadingMessage from '@common/loadingMessage/LoadingMessage'
import DeleteRowModal from '@common/modals/deleteRowModal/DeleteRowModal'
import { InputSearch } from '@components/input/Input'
import { useDashboardFilter } from '@hooks/useDashboardFilter'
import { useEventTracking } from '@hooks/useEventTracking'
import useIsTabActive from '@hooks/useIsTabActive'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { DatasetRowProps } from '@services/datasetApi'
import deploymentApiService, { DeploymentRowProps } from '@services/deploymentApi'
import { tabWithinCell } from '@utils/ag-grid/tabWithinCell'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { GetContextMenuItemsParams, GridApi, GridReadyEvent } from 'ag-grid-community'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { AxiosError } from 'axios'
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import testIds from 'src/playwright/testIds'

import { DASHBOARD_DEFAULT_COLUMN_DEF, DashboardGrid } from '../DashboardGrid'
import NoResultsOverlay from '../noResultsOverlay/NoResultsOverlay'
import {
  ActionsCellParams,
  ActionsCellRenderer,
  DatasetNameCellRenderer,
  DeploymentNameCellRenderer,
  DeploymentStatusRenderer,
  UpdatedDateRenderer,
} from './DeploymentsGrid.helpers'
import { DeploymentsGridProps } from './DeploymentsGrid.types'

const numRowsPerPage = 20

const updatedDateValueGetter: ColDef<DeploymentRowProps>['valueGetter'] = (params) =>
  timestampToDate(params.data?.updated_at || '')

const DeploymentsGrid = (props: DeploymentsGridProps) => {
  const { data, fetchDeploymentData } = props
  const toast = useToast()
  const isTabActive = useIsTabActive()

  const { trackEvent } = useEventTracking()

  // delete modal
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

  const [targetRowData, setTargetRowData] = useState<DeploymentRowProps | null | undefined>()

  const handleDeleteButtonClicked = async (rowData: DatasetRowProps) => {
    trackEvent(MixpanelEvents.clickModalGridDeleteModelButton, {
      deploymentData: rowData,
    })
    try {
      await deploymentApiService.deleteDeployment(rowData.id)
      fetchDeploymentData()
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError, { title: 'Delete failed' })
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          tooltipField: 'name',
          cellRenderer: DeploymentNameCellRenderer,
          suppressKeyboardEvent: tabWithinCell,
          minWidth: 100,
          flex: 2,
          filter: 'agTextColumnFilter',
          menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        },
        {
          field: 'dataset_name',
          tooltipField: 'dataset',
          headerName: 'Dataset',
          cellRenderer: DatasetNameCellRenderer,
          suppressKeyboardEvent: tabWithinCell,
          minWidth: 100,
          flex: 1,
        },
        {
          field: 'status',
          headerName: 'Status',
          filter: false,
          sortable: false,
          cellRenderer: DeploymentStatusRenderer,
          minWidth: 144,
          maxWidth: 160,
        },
        {
          field: 'updated_at',
          headerName: 'Last updated',
          filter: false,
          cellRenderer: UpdatedDateRenderer,
          suppressKeyboardEvent: tabWithinCell,
          valueGetter: updatedDateValueGetter,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          field: '',
          filter: false,
          sortable: false,
          cellRenderer: ActionsCellRenderer,
          cellRendererParams: {
            setTargetRowData,
            onDeleteOpen,
          } as const satisfies ActionsCellParams,
          cellClass: 'flex justify-end',
          suppressKeyboardEvent: tabWithinCell,
          pinned: 'right',
          width: 240,
          minWidth: 240,
        },
      ] satisfies ColDef<DeploymentRowProps>[],
    [onDeleteOpen]
  )

  const gridOptions: GridOptions = {
    columnDefs: columnDefs,
    defaultColDef: DASHBOARD_DEFAULT_COLUMN_DEF,
    suppressScrollOnNewData: true,
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }
  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const {
    isGridReady,
    onFilterInputChange,
    updateEmptyState,
    noRowsOverlayComponentParams,
    filterValue,
  } = useDashboardFilter({
    gridApi,
    debounceWait: 100,
    runImmediately: true,
    gridType: 'deployment',
  })

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api)
      fetchDeploymentData()
    },
    [fetchDeploymentData]
  )

  useEffect(() => {
    if (data) {
      if (
        !data.every((row) => row.status === 'TRAINED' || row.status === 'FAILED') &&
        isTabActive
      ) {
        setTimeout(() => fetchDeploymentData(), 3000)
      }
    }
  }, [data, fetchDeploymentData, isTabActive])

  return (
    <Suspense
      fallback={<Loading position="relative" type="chakra-spinner" text="Loading Deployments..." />}
    >
      <div
        className="home-tour-datasets-table flex h-[40vh] min-h-[400px] flex-col gap-5"
        data-testid={testIds.dashboardPageDeploymentsGrid}
      >
        <div className="flex w-full items-end justify-between">
          <h2 className="type-display-50 text-text-strong">Models</h2>
        </div>
        {targetRowData && (
          <DeleteRowModal
            isOpen={isDeleteOpen}
            onClose={onDeleteClose}
            rowData={targetRowData}
            handleDeleteButtonClicked={handleDeleteButtonClicked}
            deletionType="deployment"
          />
        )}
        <InputSearch
          disabled={!isGridReady}
          placeholder="Filter by model name"
          value={filterValue}
          onChange={onFilterInputChange}
        />

        <DashboardGrid<DeploymentRowProps>
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onModelUpdated={updateEmptyState}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={data}
          getRowId={({ data }) => data.id}
          loadingOverlayComponent={LoadingMessage}
          noRowsOverlayComponent={NoResultsOverlay}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        />
      </div>
    </Suspense>
  )
}

export default DeploymentsGrid
