import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    standard: (props: StyleFunctionProps) => ({
      control: {
        borderWidth: '1px',
        borderColor: mode('neutral.500', 'neutralDarkMode.500')(props),
        _hover: {
          bg: mode('neutral.50', 'neutralDarkMode.50')(props),
          borderColor: mode('neutral.600', 'neutralDarkMode.600')(props),
        },
        _groupHover: {
          bg: mode('neutral.50', 'neutralDarkMode.50')(props),
          borderColor: mode('neutral.600', 'neutralDarkMode.600')(props),
        },
        _focus: {
          boxShadow: 'none',
        },
        _checked: {
          bg: mode('neutral.900', 'neutralDarkMode.900')(props),
          borderColor: mode('neutral.900', 'neutralDarkMode.900')(props),
          _hover: {
            bg: mode('neutral.900', 'neutralDarkMode.900')(props),
            borderColor: mode('neutral.900', 'neutralDarkMode.900')(props),
          },
          _groupHover: {
            bg: mode('neutral.900', 'neutralDarkMode.900')(props),
            borderColor: mode('neutral.900', 'neutralDarkMode.900')(props),
          },
        },
      },
      icon: {
        bg: mode('neutral.900', 'neutralDarkMode.900')(props),
      },
      container: {
        padding: '4px 16px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'stretch',
      },
      label: {
        fontSize: '12px',
        fontWeight: '84px',
        lineHeight: '16px',
        width: '150px',
        color: mode('neutral.700', 'neutralDarkMode.700')(props),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    }),
    cleanlabBlue: (props: StyleFunctionProps) => ({
      control: {
        borderWidth: '1px',
        _checked: {
          bg: mode('blue.600', 'blueDarkMode.600')(props),
          borderColor: mode('blue.700', 'blueDarkMode.700')(props),
        },
      },
    }),
  },
})
