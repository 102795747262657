import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { deploymentApi, DeploymentRowProps } from '@services/deploymentApi'
import { useQuery } from 'react-query'

import { UseQueryOptionsPassThrough } from '../useQuery.helpers'
import { deploymentsQueryKeys } from './constants'

type UseDeploymentsOptions = {
  useQueryOptions?: UseQueryOptionsPassThrough<DeploymentRowProps[]>
}
export const deploymentsQueryFn = async (): Promise<any[]> => {
  const accessToken = await auth.getTokenSilently()
  const response = await deploymentApi.get('/', createAuthHeaders(accessToken))
  return response.data ?? []
}

export const useDeployments = ({ useQueryOptions = {} }: UseDeploymentsOptions = {}) => {
  const { meta = {}, ...options } = useQueryOptions
  const { data, ...rest } = useQuery<DeploymentRowProps[]>({
    queryKey: deploymentsQueryKeys.deployments.all(),
    queryFn: deploymentsQueryFn,
    meta: {
      error: 'Failed to fetch deployments.',
      ...meta,
    },
    ...options,
  })
  return { data: data ?? [], ...rest }
}
