import { cn, tv } from '@utils/tailwindUtils'
import { ForwardedRef, forwardRef, HTMLAttributes, memo, ReactNode } from 'react'

type KbdProps = {
  children: ReactNode
  className?: string
  variant?: 'outline' | 'primary' | 'highContrast' | 'subtle'
  disabled?: boolean
} & HTMLAttributes<HTMLDivElement>
const variants = tv({
  base: 'type-kbd flex min-h-[20px] items-center rounded-1 px-3 text-center text-text-faint outline outline-1 outline-border-2',
  variants: {
    variant: {
      outline: '',
      primary: 'bg-surface-2',
      highContrast: 'bg-surface-high-contrast text-text-high-contrast outline-border-0',
      subtle: 'bg-surface-1 text-text-primary outline-border-1',
    },
  },
})

const KbdBase = (
  { className, variant = 'primary', disabled, ...props }: KbdProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <div
      ref={ref}
      className={cn(variants({ variant }), disabled && 'text-text-disabled', className)}
      {...props}
    ></div>
  )
}

/**
 * Kbd component
 */
export const Kbd = memo(forwardRef(KbdBase))
Kbd.displayName = 'Kbd'
