import { LabelType } from './EditInterface.types'

// for newer datasheets where suggested can be null, only the last clause is necessary
// the first two clauses are for backwards compatibility
export const isSuggestionHidden = (
  isIssue: boolean,
  isUnlabeled: boolean,
  suggestExclude: boolean,
  givenLabel: LabelType | null,
  suggestedLabel: LabelType | null
): boolean => {
  return (!isIssue && !isUnlabeled && !suggestExclude) || givenLabel === suggestedLabel
}

export const EXCLUDE_EXAMPLE_TEXT = 'Exclude'
export const EXCLUDE_EXAMPLE_TEXT_BATCH = 'Exclude'

export const getSuggestedLabelDisplay = (
  suggestedLabel: LabelType | null,
  suggestExclude: boolean,
  isMultiClass: boolean
): string => {
  if (suggestExclude && isMultiClass) {
    return EXCLUDE_EXAMPLE_TEXT
  } else if (suggestedLabel === null) {
    return 'No suggested label'
  } else {
    return suggestedLabel.toString()
  }
}

export const getGivenLabelDisplay = (givenLabel: LabelType | null): string => {
  if (givenLabel === null) {
    return 'No given label'
  } else {
    return givenLabel.toString()
  }
}

export const getSlideMaxWidth = (isFilterSidebarCollapsed: boolean) => {
  if (isFilterSidebarCollapsed) {
    return '90%'
  } else {
    return '80%'
  }
}

export const getSlideIn = (editMode: boolean, tourEnabled: boolean, currentTourIndex: number) => {
  return editMode || (tourEnabled && currentTourIndex === 2) || currentTourIndex === 3
}

export const getLabelToProba = (topLabels: string[], topProbs: number[]) => {
  return topLabels.reduce((acc: Record<LabelType, number>, currLabel: LabelType, idx: number) => {
    return { ...acc, [currLabel]: Number(topProbs[idx]) }
  }, {})
}
