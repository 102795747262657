import UserQuotaAlert from '@common/alerts/userQuotaAlert/UserQuotaAlert'
import { UserQuotaAlertInfo } from '@common/alerts/userQuotaAlert/UserQuotaAlert.types'
import { Button } from '@components/button/Button'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import datasetApiService, { ColumnDiversity } from '@services/datasetApi'
import { useOrgCostUsageRatio } from '@services/organizationQuotas/queries'
import { FormApi, useForm } from '@tanstack/react-form'
import { useMemo, useState } from 'react'
import { Link, LoaderFunction, redirect, useNavigate } from 'react-router-dom'
import { CREATE_PROJECT_ROUTES } from 'src/createProjectRoutes'

import testIds from '../../playwright/test_ids.json'
import CheckboxSelectionGrid from './checkboxSelectionGrid/CheckboxSelectionGrid'
import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  PageSubHeading,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { useDatasetDetailsOutletContext } from './DatasetDetailsOutlet'
import DatasetSample from './datasetSample/DatasetSample'
import { findBestMatchingString } from './projectFormFields/ProjectFormFields.helpers'
import { Modality, Tasktype } from './projectFormFields/ProjectFormFields.types'
import {
  PROJECT_WIZARD_STEPS,
  ProjectWizardStep,
  useProjectWizardStore,
} from './projectWizard/ProjectWizard.store'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import { SelectLabelColumn } from './SelectLabelColumn'
import { SelectTextColumn } from './SelectTextColumn'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionWelcome,
} from './Sidebar'

export type SelectLabelFormValues = {
  textColumn: string
  labelColumn: string
  featureColumns: string[]
}

export type SelectLabelForm = FormApi<SelectLabelFormValues, undefined>

const THIS_STEP = 'selectLabels' as const satisfies ProjectWizardStep

export const checkColumnDiversity = async ({
  labelColumn,
  datasetId,
  modality,
  taskType,
  setLabelColumnDiversity,
}: {
  labelColumn: any
  datasetId: string
  modality: Modality
  taskType: Tasktype
  setLabelColumnDiversity: (arg: ColumnDiversity | null) => void
}) => {
  try {
    const diversity = await datasetApiService.checkColumnDiversity(datasetId, labelColumn)
    setLabelColumnDiversity(diversity)
    const dataLabelingEnabled =
      modality === Modality.text ||
      (modality === Modality.image && taskType === Tasktype.MULTICLASS)
    if (
      (dataLabelingEnabled || taskType === Tasktype.MULTILABEL) &&
      !diversity.has_minimal_diversity_data_labeling
    ) {
      // Checks whether there are at least 2 classes
      // Run the check both for data-labeling and multi-label Projects
      return 'This column does not contain ≥2 classes.'
    } else if (
      !dataLabelingEnabled &&
      taskType === Tasktype.MULTICLASS &&
      !diversity.has_minimal_diversity
    ) {
      return 'This column does not contain ≥2 classes with ≥5 examples each.'
    }
    return undefined
  } catch (err) {
    return 'There was a problem checking column diversity. Please try again.'
  }
}

export const checkValidMultilabelColumn = async ({
  labelColumn,
  datasetId,
  setValidMultilabelColumn,
}: {
  labelColumn: any
  datasetId: string
  setValidMultilabelColumn: (arg: boolean) => void
}) => {
  try {
    const response = await datasetApiService.checkValidMultilabelColumn(datasetId, labelColumn)
    setValidMultilabelColumn(!!response.is_valid_multilabel_column)
    if (response.is_valid_multilabel_column) {
      return undefined
    }
    return 'Label column must be of type string and formatted as a comma-separated string of labels for multilabel (i.e. "wearing_hat,has_glasses").'
  } catch (err) {
    return 'Could not verify multilabel column validity. Please try again.'
  }
}

export const createProjectSelectLabelLoader: LoaderFunction = async ({ params }) => {
  const { datasetId } = params
  const { stepMayProceed } = useProjectWizardStore.getState()

  if (!datasetId) {
    return redirect('/clean')
  }
  if (!stepMayProceed(THIS_STEP)) {
    return redirect('/clean/dataset')
  }
  return null
}

const Form = () => {
  const { datasetId, datasetDetails } = useDatasetDetailsOutletContext()
  const [quotaAlert, setQuotaAlert] = useState<UserQuotaAlertInfo | null>(null)
  const setValuesForStep = useProjectWizardStore((s) => s.setValuesForStep)

  const navigate = useNavigate()
  const { numProjectsAvailable, featuresDisabled } = useSubscription()
  const { costUsageRatio } = useOrgCostUsageRatio()

  const textColumnFromStore = useProjectWizardStore((s) => s.textColumn)
  const labelColumnFromStore = useProjectWizardStore((s) => s.labelColumn)
  const projectName = useProjectWizardStore((state) => state.projectName)
  const taskType = useProjectWizardStore((state) => state.tasktype)
  const modality = useProjectWizardStore((state) => state.modality)

  const textColumnDefaultValue = useMemo(() => {
    if (modality !== Modality.text) {
      return ''
    }
    return (
      textColumnFromStore ??
      findBestMatchingString(datasetDetails.possible_text_columns, ['text']) ??
      ''
    )
  }, [datasetDetails.possible_text_columns, modality, textColumnFromStore])
  const labelColumnDefaultValue = useMemo(
    () =>
      labelColumnFromStore ??
      findBestMatchingString(
        datasetDetails.possible_label_columns.filter((option) => option !== textColumnDefaultValue),
        ['label', 'annotation', 'class', 'tag']
      ) ??
      '',
    [datasetDetails.possible_label_columns, labelColumnFromStore, textColumnDefaultValue]
  )
  const featureColumnsDefaultValue = useMemo(() => {
    return datasetDetails.distinct_columns.filter(
      (col) => col !== labelColumnDefaultValue && col !== textColumnDefaultValue
    )
  }, [datasetDetails.distinct_columns, labelColumnDefaultValue, textColumnDefaultValue])

  const form = useForm<SelectLabelFormValues>({
    onSubmit: async ({ value }) => {
      setValuesForStep(THIS_STEP, {
        labelColumn: value.labelColumn,
        featureColumns: value.featureColumns,
        textColumn: value.textColumn,
      })
      navigate(`/clean/${datasetId}/${CREATE_PROJECT_ROUTES.ML}`)
    },
    defaultValues: {
      labelColumn: labelColumnDefaultValue,
      featureColumns: featureColumnsDefaultValue,
      textColumn: textColumnDefaultValue,
    },
  })

  if (!modality || !taskType || !projectName) {
    redirect(`/clean/${datasetId}/type`)
    return null
  }

  const canSubmit = form.useStore((state) => state.canSubmit)

  return (
    <>
      <UserQuotaAlert info={quotaAlert} setInfo={setQuotaAlert} />
      <form
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <div className="flex flex-col gap-8">
          {modality === Modality.text && (
            <SelectTextColumn form={form} datasetDetails={datasetDetails} modality={modality} />
          )}
          {taskType !== Tasktype.UNSUPERVISED && (
            <SelectLabelColumn
              {...{
                form,
                modality: modality as Modality,
                datasetId,
                taskType: taskType as Tasktype,
                datasetDetails,
              }}
            />
          )}
          <div className="flex flex-col gap-5">
            <PageSubHeading>Dataset sample</PageSubHeading>
            <DatasetSample numRows={3} datasetId={datasetId} />
          </div>
          {modality === Modality.tabular && (
            <CheckboxSelectionGrid form={form} choices={datasetDetails.distinct_columns} />
          )}
        </div>
        <ProjectWizardActions
          step={THIS_STEP}
          stepCount={PROJECT_WIZARD_STEPS.length}
          className="pt-8"
        >
          <Button
            variant="highContrast"
            type="submit"
            onClick={() => null}
            disabled={
              !canSubmit || featuresDisabled || numProjectsAvailable < 1 || costUsageRatio >= 1
            }
            data-testid={testIds.createProjectPageCleanButton}
          >
            Next
          </Button>
        </ProjectWizardActions>
      </form>
    </>
  )
}

export const CreateProjectSelectLabel = () => {
  const { datasetId } = useDatasetDetailsOutletContext()
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          <Link to={`/clean/${datasetId}/type`}>Select a type of data</Link>
        </PreviousLink>
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Verify your data</PageHeading>
            <PageDescription>
              You’re almost done. We’ve rendered a sample of your Dataset below. Verify that the
              columns look correct.
            </PageDescription>
          </HeadingWrapper>
          <Form />
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcome />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/">Cleanlab Studio docs</ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/concepts/cleanset/">
              What is a Cleanset?
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/guide/concepts/cleanlab_columns/">
              Project metadata guidebook
            </ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}
