import { CreateToastFnReturn } from '@chakra-ui/react'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { ColumnApi, ColumnState, GridApi } from 'ag-grid-community'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { PresetConfig } from './Cleanset.types'
import { getCleansetViewConfig } from './cleansetViewConfigs/CleansetViewConfigs'

export const setInvalidIdOrRaiseToast = (
  toast: CreateToastFnReturn,
  err: AxiosError,
  setInvalidId: (invalid: boolean) => void
) => {
  const statusCode = err.response?.status
  if (statusCode && statusCode >= 400 && statusCode < 500) {
    setInvalidId(true)
  } else {
    notifyAxiosError(toast, err)
  }
}

export const useParamsCleansetId = (): string => {
  const navigate = useNavigate()
  const { cleansetId } = useParams<{ cleansetId: string }>()
  if (cleansetId === undefined) {
    navigate('/')
    return ''
  } else {
    return cleansetId
  }
}

export const setPresetFilterColumnConfig = (
  columnApi: ColumnApi,
  preset: string,
  prevPresetConfig: PresetConfig | null,
  setPrevPresetConfig: (prevPresetConfig: PresetConfig) => void
) => {
  const columnConfig = getCleansetViewConfig(preset)
  if (prevPresetConfig !== columnConfig) {
    // Hide old preset config columns
    columnApi.setColumnsVisible(prevPresetConfig?.columns ?? [], false)
    // Show new preset config columns
    columnApi.setColumnsVisible(columnConfig.columns, true)
    // moveColumns works backwards on the array of columns, so we need to reverse the order.
    // We also shouldn't mutate the original array, so we create a copy.
    columnApi.moveColumns([...columnConfig.columns].reverse(), -1)
    setPrevPresetConfig(columnConfig)
  }
}

export const deepCompareColumnStatuses = (
  prevState: { [columnName: string]: string },
  currentState: { [columnName: string]: string }
): boolean => {
  if (Object.keys(prevState).length !== Object.keys(currentState).length) {
    return false
  }
  return Object.keys(prevState).every(
    (columnName) => prevState[columnName] === currentState[columnName]
  )
}

// If the grid layout is saved in localStorage, restore it and return true. Otherwise, return false.
export const restoreGridLayout = (
  columnState: ColumnState[],
  filterModel: Record<string, any>,
  gridApi: GridApi | null,
  columnApi: ColumnApi | null
): boolean => {
  if (columnState.length || Object.keys(filterModel).length) {
    columnApi?.applyColumnState({ state: columnState, applyOrder: true })
    gridApi?.setFilterModel(filterModel)
    return true
  }
  return false
}
