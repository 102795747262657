import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys } from '@services/featureFlags/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

const apiVersion = 'v0'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/feature_flags`,
  withCredentials: true,
})

export const useEnableFeatureFlagMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async ({ userId, featureFlagId }: { userId: string; featureFlagId: string }) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        user_id: userId,
        feature_flag_id: featureFlagId,
      }
      await axiosClient.post('/enable', body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.featureFlags.all() })
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 409) {
        notifyAxiosError(toast, err, {
          title: 'Feature flag override already exists for user',
        })
      } else {
        notifyAxiosError(toast, err)
      }
    },
  })
}

export const useDisableFeatureFlagMutation = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async ({ userId, featureFlagId }: { userId: string; featureFlagId: string }) => {
      const accessToken = await auth.getTokenSilently()
      const body = {
        user_id: userId,
        feature_flag_id: featureFlagId,
      }
      await axiosClient.post('/disable', body, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.featureFlags.all() })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}
