import { useTab, useTabList } from '@chakra-ui/react'
import { mergeRefs } from '@react-aria/utils'
import { cn } from '@utils/tailwindUtils'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

import { TopNavTabsProps } from './TopNavTabs.types'

const COMMON_TAB_CLASSES = ''

/**
 * Button for additional TopNav links
 */
export const TopNavTabButton = forwardRef<
  HTMLButtonElement,
  {
    active?: boolean
  } & ComponentPropsWithoutRef<'button'>
>(({ children, className, active = false, ...props }, ref) => {
  return (
    <button
      type="button"
      ref={ref}
      className={cn(
        'type-button-sm flex h-9 items-center rounded-2 bg-transparent px-4 py-3 text-text-strong hover:bg-neutral-100 focus-visible:ring  focus-visible:ring-focus dark:hover:bg-neutral-200',
        active &&
          'bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-200 hover:dark:bg-neutral-300',
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
})

/**
 * Top Nav buttons with chakra Tabs context applied
 */
const TopNavTab = forwardRef<HTMLButtonElement, any>(
  (
    {
      selectedTabIndex,
      ...props
    }: { selectedTabIndex: number } & Omit<
      ComponentPropsWithoutRef<typeof TopNavTabButton>,
      'active'
    >,
    refProp
  ) => {
    const { ref: tabRef, ...tabProps } = useTab({ ...props })
    const ref = mergeRefs(refProp, tabRef)

    return (
      <TopNavTabButton
        ref={ref}
        {...tabProps}
        active={tabProps['aria-selected'] ?? selectedTabIndex === tabProps.tabIndex}
      >
        {tabProps.children}
      </TopNavTabButton>
    )
  }
)

const TopNavTabList = forwardRef<HTMLDivElement, any>(({ children, ...props }, ref) => {
  const tabListProps = useTabList(props)
  return (
    <div ref={ref} className="flex h-full gap-6" {...tabListProps}>
      {children}
    </div>
  )
})

const TopNavTabs = ({ additionalProps, clickHandlers, tabNames, tabIndex }: TopNavTabsProps) => {
  return (
    <TopNavTabList>
      {tabNames.map((name, i) => {
        const { className, ...props } = additionalProps?.[i] ?? {}
        return (
          <TopNavTab
            key={name}
            className={cn(COMMON_TAB_CLASSES, className)}
            onClick={() => clickHandlers?.[i]?.()}
            selectedTabIndex={tabIndex}
            {...props}
          >
            {name}
          </TopNavTab>
        )
      })}
    </TopNavTabList>
  )
}

export default TopNavTabs
