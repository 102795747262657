import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'

export const columnDefs: ColDef[] = [
  {
    field: 'userId',
    headerName: 'User ID',
    pinned: 'left',
    checkboxSelection: true,
    minWidth: 300,
  },
  {
    field: 'featureFlagId',
    headerName: 'Feature Flag Id',
  },
  { field: 'name', headerName: 'Name' },
  { field: 'email', headerName: 'Email' },
]
