import { useAuth } from '@hooks/useAuth'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { AccountStatus, GetMyselfRes, queryKeys, UserRowRes } from '@services/user/constants'
import { IS_VPC_ENVIRONMENT, REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useQuery } from 'react-query'

const baseURL = IS_VPC_ENVIRONMENT
  ? `${REACT_APP_CLEANLAB_API_URL}/api/users/vpc`
  : `${REACT_APP_CLEANLAB_API_URL}/api/users`

const axiosClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})

export const useUsers = () => {
  const { data } = useQuery<UserRowRes[]>({
    queryKey: queryKeys.users.all(),
    queryFn: async () => {
      try {
        const accessToken = await auth.getTokenSilently()
        const res = await axiosClient.get('/', createAuthHeaders(accessToken))
        return res.data.rows
      } catch {
        throw new Error('Failed to fetch users')
      }
    },
    suspense: true,
  })
  return data!
}

export const useUsersByOrganization = (organizationId: string) => {
  const { data } = useQuery<UserRowRes[]>({
    queryKey: queryKeys.users.organizationId(organizationId),
    queryFn: async () => {
      try {
        const accessToken = await auth.getTokenSilently()
        const res = await axiosClient.get(`/${organizationId}`, createAuthHeaders(accessToken))
        return res.data.rows
      } catch {
        return []
      }
    },
    meta: { error: 'Failed to fetch users.' },
    suspense: true,
  })
  return data!
}

export const useMyself = () => {
  const { isAuthenticated, logout } = useAuth()
  const { data, isLoading } = useQuery<GetMyselfRes>({
    queryKey: queryKeys.users.myself(),
    queryFn: async () => {
      try {
        const accessToken = await auth.getTokenSilently()
        const res = await axiosClient.get('/v1/myself', createAuthHeaders(accessToken))
        const data = res.data
        return {
          id: data.id,
          accessLevel: data.access_level,
          activated: data.activated,
          accountActivated: data.account_activated,
          apiKey: data.api_key,
          apiKeyTimestamp: data.api_key_timestamp,
          email: data.email,
          emailConfirmed: data.email_confirmed,
          emailVerified: data.email_verified,
          initialPlanId: data.initial_plan_id,
          name: data.name,
          showGuide: data.show_guide,
          showUploadWizard: data.show_upload_wizard,
          signupToken: data.signup_token,
          signupTokenTimestamp: data.signup_token_timestamp,
          updatedAt: data.updated_at,
          accountStatus: data.account_status,
          organizationId: data.organization_id,
        }
      } catch (e) {
        if (
          (e as { error: string })?.error === 'missing_refresh_token' ||
          (e as { error: string })?.error === 'invalid_grant'
        ) {
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
        return {
          activated: null,
          accountActivated: null,
          apiKey: '',
          email: '',
          emailConfirmed: false,
          isLoading: false,
          accountStatus: null,
          id: '',
          organizationId: '',
          name: '',
        } as unknown as GetMyselfRes
      }
    },
    meta: { error: 'Failed to fetch user data.' },
    suspense: true,
    enabled: !!isAuthenticated,
    retry: false,
    staleTime: Infinity,
  })
  if (data?.accountStatus === AccountStatus.DEACTIVATED) {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }
  return data
    ? { ...data, isLoading: isLoading }
    : {
        accessLevel: null,
        activated: false,
        accountActivated: false,
        apiKey: '',
        email: '',
        emailConfirmed: false,
        isLoading: isLoading,
        accountStatus: null,
        id: '',
        organizationId: '',
        name: '',
      }
}
