import { Persist } from '@utils/zustand/zustand.types'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { CreateProjectReq } from '../ProjectForm.types'
import { ProjectType } from '../projectFormFields/ProjectFormFields.types'

export const PROJECT_NAME_MAX_LEN = 100
const NO_PROJECT_NAME_ERROR = 'Please provide a name for your Project.'
export const checkProjectName = (value: string | undefined) => {
  if (!value) return NO_PROJECT_NAME_ERROR
  if (value.length > PROJECT_NAME_MAX_LEN) return 'Project name is too long.'
  return undefined
}

type ProjectWizardData = Omit<CreateProjectReq, 'modelType' | 'predProbsFilename'> & {
  startPoint: 'datasetId' | 'empty'
  projectType: ProjectType
}

const initialData = {
  datasetId: undefined,
  projectName: undefined,
  projectType: undefined,
  modality: undefined,
  tasktype: undefined,
  labelColumn: undefined,
  featureColumns: undefined,
  textColumn: undefined,
  startPoint: undefined,
} as const satisfies Partial<ProjectWizardData>

export const PROJECT_WIZARD_STEPS = [
  'selectDataset',
  'selectProjectType',
  'selectTypes',
  'selectLabels',
  'selectMLPrefs',
] as const
export type ProjectWizardStep = (typeof PROJECT_WIZARD_STEPS)[number]

const getStepNum = (step: ProjectWizardStep) =>
  PROJECT_WIZARD_STEPS.findIndex((s) => s === step) + 1

export const ProjectWizardTextStepper = ({
  step,
  stepCount,
}: {
  step?: ProjectWizardStep | number
  stepCount?: number
}) => {
  if (!step) return null
  return (
    <>
      Step {typeof step === 'number' ? step : getStepNum(step)}
      {typeof stepCount === 'number' ? ` of ${stepCount}` : ''}
    </>
  )
}

const parts = {
  selectDataset: ['projectName', 'datasetId', 'startPoint'],
  selectProjectType: ['projectType'],
  selectTypes: ['modality', 'tasktype'],
  selectLabels: ['labelColumn', 'featureColumns', 'textColumn'],
  // We don't need to persist the last step's values, so we don't include them here
  selectMLPrefs: [],
} as const satisfies Record<ProjectWizardStep, Readonly<Readonly<keyof ProjectWizardData>[]>>

type StepValueKey<T extends ProjectWizardStep> = (typeof parts)[T][number]
type StepValues<T extends ProjectWizardStep> = { [K in StepValueKey<T>]: ProjectWizardData[K] }

type ProjectWizardStore = Partial<ProjectWizardData> & {
  /**
   * Fully reset the store state
   * @returns
   */
  resetState: () => void
  resetStateAfter: (step: ProjectWizardStep) => void
  setValuesForStep: <T extends ProjectWizardStep>(step: T, values: StepValues<T>) => void
  /**
   * Check if values for steps preceeding `step` have been set
   * @param step The step you'd like to proceed to
   * @returns Whether the wizard may proceed to the given step
   */
  stepMayProceed: (step: ProjectWizardStep) => boolean
}

/**
 * Checks if the given `step` has any values set for it in the store
 * @param step
 * @param store
 * @returns Whether the step has any values set
 */
const stepIsSet = (step: ProjectWizardStep, store: ProjectWizardStore) => {
  for (const part of parts[step]) {
    if (store[part]) return true
  }
  return false
}

export const useProjectWizardStore = create<ProjectWizardStore>(
  (persist as Persist<ProjectWizardStore>)(
    (set, get) => ({
      ...initialData,
      resetState: () => set(() => initialData),
      /**
       * Resets all values in the store that belong to steps after the given step
       * @param step The step after which to reset the state
       */
      resetStateAfter: (step) => {
        const stepI = PROJECT_WIZARD_STEPS.indexOf(step)
        const nextState = {} as Partial<ProjectWizardData>
        for (let i = stepI + 1; i < PROJECT_WIZARD_STEPS.length; i++) {
          for (const part of parts[PROJECT_WIZARD_STEPS[i]]) {
            nextState[part] = undefined
          }
        }
        set(() => nextState)
      },
      /**
       * Set all the values for a given step
       * Will automatically reset all the values after this given @step
       * @param step
       * @param values
       */
      setValuesForStep: (step, values) => {
        set(() => values)
        get().resetStateAfter(step)
      },
      stepMayProceed: (step) => {
        const values = get()
        for (const s of PROJECT_WIZARD_STEPS) {
          // Made it to the current step, so it's okay to proceed
          if (s === step) return true
          if (!stepIsSet(s, values)) return false
        }
        return false
      },
    }),
    {
      name: 'createProject',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
