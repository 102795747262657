export const constructDateFromTimestampNumber = (timestamp: number): Date => {
  let dateObj
  const timestampLength = timestamp.toString().length
  if (timestampLength > 16) {
    // nanoseconds
    dateObj = new Date(timestamp / 1e6)
  } else if (timestampLength > 13) {
    // microseconds
    dateObj = new Date(timestamp / 1e3)
  } else if (timestampLength > 10) {
    // milliseconds
    dateObj = new Date(timestamp)
  } else {
    // seconds
    dateObj = new Date(timestamp * 1e3)
  }
  return dateObj
}

export const timestampToDate = (timestamp: number | string) => {
  if (typeof timestamp === 'number') {
    const dateObj = constructDateFromTimestampNumber(timestamp)
    return dateObj.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
      timeZone: 'UTC',
    })
  } else {
    return timestamp
  }
}

export const timeStampToDateOnly = (timestamp: number | string) => {
  if (typeof timestamp === 'number') {
    const dateObj = constructDateFromTimestampNumber(timestamp)
    return dateObj.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
  } else {
    return timestamp
  }
}

export const timestampToISOFormat = (timestamp: number) => {
  const dateObj = constructDateFromTimestampNumber(timestamp)
  return new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000)
    .toISOString()
    .split('.')[0]
}

export const getMDYDate = (timestamp: number) =>
  Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(constructDateFromTimestampNumber(timestamp))

export const getCurrentDateISO = () => {
  const date = new Date()
  return date.toISOString().split('T')[0]
}

export const getNDaysAgoISO = (n: number) => {
  const date = new Date()
  date.setDate(date.getDate() - n)
  return date.toISOString().split('T')[0]
}
