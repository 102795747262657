import { EnrichmentJobDetailType } from '@services/enrichment/constants'
import { LoadSuccessParams } from 'ag-grid-community'

import { truncateDecimals } from './../../services/cleanset/utils'

export interface NormalizedEnrichmentJobPreviewData {
  rowId: number
  newColumnName: string
  newColumnValue: string
  newColumnTrustworthinessScore: number
}

export const normalizeEnrichmentJobPreviewData = (
  input: EnrichmentJobDetailType[],
  newColumnName: string
) => {
  const trustworthinessScoreColumnName = `${newColumnName}_trustworthiness_score`

  return input.map(
    (row) =>
      ({
        rowId: row.row_id,
        newColumnName,
        newColumnValue: row[newColumnName],
        newColumnTrustworthinessScore: truncateDecimals(
          row[trustworthinessScoreColumnName] as number,
          3
        ),
      }) as NormalizedEnrichmentJobPreviewData
  )
}

export const joinDatasetDetailsAndJobDetails = (
  datasetDetails: LoadSuccessParams,
  enrichedRows: NormalizedEnrichmentJobPreviewData[]
): LoadSuccessParams => {
  if (!enrichedRows || enrichedRows.length === 0 || datasetDetails.rowData.length === 0) {
    return datasetDetails
  }

  const firstJobDetailsRow = enrichedRows[0]
  const { newColumnName } = firstJobDetailsRow

  if (!newColumnName) {
    return datasetDetails
  }

  const trustworthinessScoreColumnName = `${newColumnName}_trustworthiness_score`
  const output = {
    rowData: datasetDetails.rowData.map((originalDatasetRow) => {
      const matchingEnrichedRow = enrichedRows.find(
        // rowId and cleanlab_row_ID are offset by one. Intentional? Ask backend
        (enrichedRow) => enrichedRow.rowId === originalDatasetRow.cleanlab_row_ID - 1
      )

      return {
        ...originalDatasetRow,
        [newColumnName]: matchingEnrichedRow?.newColumnValue,
        [trustworthinessScoreColumnName]: matchingEnrichedRow?.newColumnTrustworthinessScore,
      }
    }),
    rowCount: datasetDetails.rowCount,
  }

  return output
}
