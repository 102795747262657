import { Flex } from '@chakra-ui/react'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'

import { ActionType } from '../../commandpalette/CommandPalette.types'
import ActionCellTag from './actionCellTag/ActionCellTag'

const ActionCellRenderer = (params: ICellRendererParams) => {
  const rowData = params.node.data
  const action = rowData._cleanlab_action
  const issueTags = rowData._cleanlab_issue_tags

  if (
    !action ||
    ((action === 'unresolved' || action === ActionType.MARK_UNRESOLVED) && !issueTags?.length)
  ) {
    return '-'
  }
  const category = action.startsWith('relabel') ? 'relabel' : params.value

  return (
    <Flex align="center" height="100%">
      <ActionCellTag category={category} action={action} />
    </Flex>
  )
}

export default ActionCellRenderer
