import { useColorMode } from '@chakra-ui/react'
import { applyIssueQuickFilter } from '@common/filter/presetFilterRow/PresetFilterRow.helpers'
import { useEventTracking } from '@hooks/useEventTracking'
import { BarDatum, ComputedDatum, ResponsiveBar } from '@nivo/bar'
import { RowCountType } from '@services/datasheet/constants'
import { useRowCount } from '@services/datasheet/queries'
import React, { useContext } from 'react'
import { CleansetContext } from 'src/pages/cleanset/CleansetContext'
import { useFilters } from 'src/pages/cleanset/filterReducer/useFilters'
import { returnInitialTabsState } from 'src/pages/cleanset/tabsReducer/TabsState'
import { useCleansetTabs } from 'src/pages/cleanset/tabsReducer/useCleansetTabs'

import {
  BASE_FONT_SIZE,
  calculateChartMarginLeftAndLeftLegendOffset,
  CHART_BASE_MARGINS,
  CHART_COLOR_GRADIENT,
  CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
  getChartTheme,
  getLabelTextColor,
  returnLongestLabelLength,
} from '../CleansetCharts.helpers'
import { ISSUE_TYPE } from './DataIssuesChart.helpers'
import { DataIssuesChartProps } from './DataIssuesChart.types'

const DataIssuesChart = (props: DataIssuesChartProps) => {
  const { colorMode } = useColorMode()
  const { leftTitle, barClickEvent, cleansetId, firstGridDataRendered, onClose, isInitialFetch } =
    props

  const { numRows: numLabelIssues } = useRowCount(
    cleansetId,
    RowCountType.LABEL_ISSUES,
    null,
    firstGridDataRendered,
    isInitialFetch
  )
  const { numRows: numOutliers } = useRowCount(
    cleansetId,
    RowCountType.OUTLIERS,
    null,
    firstGridDataRendered,
    isInitialFetch
  )
  const { numRows: numUnlabeled } = useRowCount(
    cleansetId,
    RowCountType.UNLABELED,
    null,
    firstGridDataRendered,
    isInitialFetch
  )
  const { numRows: numAmbiguous } = useRowCount(
    cleansetId,
    RowCountType.AMBIGUOUS,
    null,
    firstGridDataRendered,
    isInitialFetch
  )
  const { numRows: numNearDuplicate } = useRowCount(
    cleansetId,
    RowCountType.NEAR_DUPLICATE,
    null,
    firstGridDataRendered,
    isInitialFetch
  )

  const { dispatch } = useFilters()
  const tabs = useCleansetTabs()

  const dataIssues: BarDatum[] = [
    // Add newer data issues to the top of the list. nivo renders in reverse order
    {
      issueType: ISSUE_TYPE.NEAR_DUPLICATE,
      count: numNearDuplicate,
    },
    {
      issueType: ISSUE_TYPE.AMBIGUOUS,
      count: numAmbiguous,
    },
    {
      issueType: ISSUE_TYPE.UNLABELED,
      count: numUnlabeled,
    },
    {
      issueType: ISSUE_TYPE.OUTLIER,
      count: numOutliers,
    },
    {
      issueType: ISSUE_TYPE.LABEL_ISSUE,
      count: numLabelIssues,
    },
  ]

  const dataIssuesDisplayed = dataIssues.filter((issue) => (issue.count as number) > 0)

  const keys = ['count']
  const indexBy = 'issueType'

  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()

  const trackClickEvent = (bar: ComputedDatum<BarDatum>) => {
    const { indexValue, id, value } = bar
    const barData = {
      issueType: indexValue.toString(),
      barLabel: id,
      exampleCount: value,
    }

    if (barClickEvent) {
      trackEvent(barClickEvent, { ...cleansetInfo, ...barData })
    }
    tabs.dispatch(returnInitialTabsState())
    applyIssueQuickFilter(dispatch, barData.issueType)
    onClose()
  }

  const getLongestWordFromData = returnLongestLabelLength(
    dataIssues.map((issue) => issue.issueType as string)
  )
  const [chartMarginLeft, chartLeftLegendOffset] =
    calculateChartMarginLeftAndLeftLegendOffset(getLongestWordFromData)

  return (
    <ResponsiveBar
      data={dataIssuesDisplayed}
      keys={keys}
      indexBy={indexBy}
      groupMode="stacked"
      margin={{
        ...CHART_BASE_MARGINS,
        left: chartMarginLeft,
        bottom: CHART_WITH_BOTTOM_LEGEND_MARGIN_BOTTOM,
      }}
      padding={0.25}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={CHART_COLOR_GRADIENT}
      colorBy="indexValue"
      borderRadius={6}
      axisLeft={{
        legend: leftTitle,
        legendPosition: 'middle',
        legendOffset: chartLeftLegendOffset,
        tickSize: 0,
      }}
      onClick={trackClickEvent}
      enableGridY={false}
      enableGridX={true}
      labelSkipWidth={BASE_FONT_SIZE}
      labelSkipHeight={BASE_FONT_SIZE}
      labelTextColor={getLabelTextColor(colorMode === 'light')}
      theme={getChartTheme(colorMode === 'light')}
    />
  )
}

export default DataIssuesChart
