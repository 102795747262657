import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Box, Button, Flex, HStack, Input, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import CircleDashed from '@components/icons/circle-dashed/CircleDashed'
import CircleHalf from '@components/icons/circleHalf/CircleHalf'
import { useDebouncedValue } from '@hooks/useDebouncedValue'
import { useWindowSize } from '@hooks/useWindowSize'
import { useEffect, useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'

import { DataLabelingStepperProps } from './DataLabelingStepper.types'
import Stepper from './stepper/Stepper'

const DataLabelingStepper = ({
  activeStep,
  onAutoLabelClicked,
  onInputChange,
  onDoneLabelingClicked,
  thresholdRowIndex,
  initialThresholdRowIndex,
  numUnlabeled,
  numUnlabeledResolved,
  isLoading,
  hasStartedLabeling,
  thresholdButtonClicked,
  setThresholdButtonClicked,
}: DataLabelingStepperProps) => {
  const [inputThresholdRowIndex, setInputThresholdRowIndex] =
    useState<number>(initialThresholdRowIndex)
  const borderColor = useColorModeValue('neutral.300', 'neutralDarkMode.300')
  const textColor = useColorModeValue('neutral.700', 'neutralDarkMode.700')
  const iconColor = useColorModeValue(cleanlabColors.blue[600], cleanlabColors.blueDarkMode[600])
  const inactiveIconColor = useColorModeValue(
    cleanlabColors.neutral[600],
    cleanlabColors.neutralDarkMode[600]
  )

  const debounceInput = useDebouncedValue(inputThresholdRowIndex, 500)

  const showVerticalView = useWindowSize().width < 600

  useEffect(() => {
    if (thresholdButtonClicked) {
      setInputThresholdRowIndex(thresholdRowIndex)
      setThresholdButtonClicked(false)
    }
  }, [thresholdRowIndex, thresholdButtonClicked, setThresholdButtonClicked])

  useEffect(() => {
    if (debounceInput <= numUnlabeled) {
      onInputChange(debounceInput)
    }
  }, [debounceInput, numUnlabeled, onInputChange])

  return (
    <Box w="100%" border="1px solid" borderColor={borderColor} borderRadius="8px" mb="24px">
      <Stepper
        activeStep={activeStep}
        stepOneIcon={
          activeStep === 0 ? (
            <CircleHalf color={iconColor} width="20" height="20" />
          ) : (
            <BsFillCheckCircleFill size="20px" color={iconColor} />
          )
        }
        stepTwoIcon={
          activeStep === 3 ? (
            <BsFillCheckCircleFill size="18px" color={iconColor} />
          ) : activeStep === 1 ? (
            <CircleHalf color={iconColor} width="20" height="20" />
          ) : (
            <CircleDashed color={inactiveIconColor} width="20" height="20" />
          )
        }
      />

      {activeStep === 0 && (
        <Box p="16px" maxW="100%" position="relative">
          <Flex w="100%" justify="space-between" flexDir={showVerticalView ? 'column' : 'row'}>
            <Text maxW={showVerticalView ? '100%' : '75%'} fontSize="sm" color={textColor}>
              Cleanlab Studio’s AI has automatically suggested labels for all of your
              originally-unlabeled data (shown below). Your data is sorted by how confident the AI
              is in its suggestion. Auto label your data in bulk by selecting a confidence
              threshold. All datapoints above that confidence threshold will be labeled with their
              suggested label (including data from prior pages to the one shown, if your Dataset is
              spread across many pages). To identify a good threshold, inspect the data points
              around our candidate threshold. We suggest setting the threshold directly above the
              first data point where the suggested label looks incorrect to you. If you notice any
              incorrect suggested labels, you are welcome to label any data point manually by
              clicking on a row and selecting a label from the resolver panel on the right.
            </Text>
            <Flex
              pl={showVerticalView ? '0px' : '12px'}
              pt={showVerticalView ? '16px' : '0px'}
              gap="12px"
              alignItems="flex-end"
              w="fit-content"
              bottom="16px"
            >
              <VStack alignItems="flex-start" gap={0}>
                <Text fontWeight="600" fontSize="xs">
                  Threshold row
                </Text>
                <Input
                  value={inputThresholdRowIndex}
                  w={showVerticalView ? '100%' : '124px'}
                  h="32px"
                  type="number"
                  onKeyDown={(e) => {
                    // prevent command palette options from capturing event
                    e.stopPropagation()
                  }}
                  onChange={(evt) => setInputThresholdRowIndex(evt.target.valueAsNumber)}
                />
              </VStack>
              <PrimaryButton
                isDisabled={thresholdRowIndex < 0 || debounceInput > numUnlabeled}
                isLoading={isLoading}
                fontSize="sm"
                height="32px"
                onClick={onAutoLabelClicked}
              >
                {thresholdRowIndex ? 'Auto label' : 'Skip'}
              </PrimaryButton>
            </Flex>
          </Flex>
        </Box>
      )}
      {activeStep === 1 && (
        <Box p="16px" maxW="100%">
          <Flex w="100%" justify="space-between" flexDir={showVerticalView ? 'column' : 'row'}>
            <Text maxW={showVerticalView ? '100%' : '75%'} fontSize="sm" color={textColor}>
              Here, you can review the remaining data points that were not auto labeled and
              (optionally) manually label some of them to further improve model training. By
              default, the Dataset is sorted to show the least confident examples first, which will
              be most informative to manually label. For best results, we recommend labeling at
              least 30 data points total, or at least 5 per class (labeling diverse data will also
              help).
            </Text>
            <Flex
              gap="12px"
              alignItems="flex-end"
              bottom="16px"
              pt={showVerticalView ? '16px' : '0px'}
              pl={showVerticalView ? '0px' : '12px'}
            >
              <HStack>
                {hasStartedLabeling ? (
                  <Text fontSize="xs" fontWeight="600" noOfLines={1} whiteSpace="nowrap">
                    {numUnlabeledResolved}/{numUnlabeled} labeled
                  </Text>
                ) : (
                  <Button
                    isLoading={isLoading}
                    variant="stepper-skip-button"
                    onClick={onDoneLabelingClicked}
                  >
                    Skip
                  </Button>
                )}
                <PrimaryButton
                  isDisabled={!hasStartedLabeling}
                  isLoading={isLoading}
                  height="32px"
                  fontSize="sm"
                  onClick={onDoneLabelingClicked}
                >
                  Done labeling
                </PrimaryButton>
              </HStack>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default DataLabelingStepper
