import { ExportModelRowProps } from '@services/deploymentApi'
import { CleansetDetailsProps } from 'src/pages/cleanset/datasheet/Datasheet.types'

export interface ExportModelGridProps {
  data: ExportModelRowProps[]
  deploymentId: string
  refreshData: VoidFunction
  cleansetDetails: CleansetDetailsProps
}

export interface QueryRowProps {
  id: string
  dataset: string
}

export enum PREDICTION_STATUS {
  COMPLETE = 'COMPLETE',
  INFERRING = 'INFERRING',
  INITIALIZING = 'INITIALIZING',
  FAILED = 'FAILED',
  QUOTA_FAILURE = 'QUOTA_FAILURE',
}
