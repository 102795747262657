import { Box, Divider, HStack, Text } from '@chakra-ui/react'
import { useCurrentPeriodUsage } from '@services/billing/queries'
import { centsToDollarsUSD } from '@utils/functions/centsToDollarsUSD'
import { timestampToDate } from '@utils/functions/timestampToDate'
import React from 'react'

import CurrentPeriodUsage from '../../currentPeriodUsage/CurrentPeriodUsage'

const PaymentDetails = () => {
  const { amountDue, taxAmount, lineItems, periodEnd } = useCurrentPeriodUsage()

  return (
    <Box>
      <CurrentPeriodUsage data={lineItems} />
      <Divider />
      <Box mt="2rem">
        {taxAmount && taxAmount > 0 ? (
          <HStack fontSize="sm" justify="space-between" maxW="33%">
            <Text>Tax</Text>
            <Text>{`${centsToDollarsUSD(taxAmount)}`}</Text>
          </HStack>
        ) : null}
        <HStack fontSize="sm" justify="space-between" maxW="33%">
          <Text>Total</Text>
          <Text>{centsToDollarsUSD(amountDue ? amountDue : 0)}</Text>
        </HStack>
      </Box>
      <Text mt="2rem" fontSize="sm" mb="2rem">
        {periodEnd
          ? `You will be charged the total amount on ${timestampToDate(periodEnd)}.`
          : 'You have not started a billing period yet.'}
      </Text>
    </Box>
  )
}

export default PaymentDetails
