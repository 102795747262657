import {
  Box,
  Divider,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import SubscriptionAlert from '@common/alerts/subscriptionAlert/SubscriptionAlert'
import DestructiveButton from '@components/buttons/destructiveButton/DestructiveButton'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import Link from '@components/link/Link'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useCurrentPeriodUsage, useSubscriptionDetails } from '@services/billing/queries'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { useRef } from 'react'

import CancelSubscriptionModal from './cancelSubscriptionModal/CancelSubscriptionModal'
import { getPlanPriceText, getPlanText, getTotalThisMonth } from './PlanDetails.helpers'
import PlanDetailsUsage from './planDetailsUsage/PlanDetailsUsage'

const PlanDetails = () => {
  const {
    isEnterprisePlan,
    payingCustomer,
    freeTrialPeriod,
    planName,
    freeTrialPeriodEnd,
    pricePerMonth,
    isIndividualPayingCustomer,
    cancelledAt,
    onSalesModalOpen,
    isTlmPlan,
    setIsTlmPricingModalOpen,
  } = useSubscription()

  const subscriptionDetails = useSubscriptionDetails({ enabled: true })

  const { lineItems, amountDue, taxAmount } = useCurrentPeriodUsage()
  const {
    onOpen: onCancelSubscriptionOpen,
    isOpen: isCancelSubscriptionOpen,
    onClose: onCancelSubscriptionClose,
  } = useDisclosure()
  const { trackEvent } = useEventTracking()

  const borderColor = useColorModeValue('gray.200', 'gray.500')
  const warningColor = useColorModeValue('orange.500', 'orange.300')

  const cancelRef = useRef()

  const individualSubscriptionActive = isIndividualPayingCustomer && !cancelledAt
  const enterpriseSubscriptionActive = isEnterprisePlan && payingCustomer

  const overageChargesAmounts =
    lineItems?.map((e) =>
      !e.description.includes('Monthly Plan Price') && !e.description.includes('TLM') ? e.amount : 0
    ) ?? []
  const overageChargesTotal = overageChargesAmounts?.reduce((a: number, b: number) => a + b, 0) ?? 0
  const tlmChargesAmounts =
    lineItems?.map((e) => (e.description.includes('TLM') ? e.amount : 0)) ?? []
  const tlmChargesTotal = tlmChargesAmounts?.reduce((a: number, b: number) => a + b, 0) ?? 0

  if (!payingCustomer && !freeTrialPeriod) {
    return (
      <SubscriptionAlert title="Free trial expired" message="to continue using Cleanlab Studio." />
    )
  } else {
    return (
      <>
        <CancelSubscriptionModal
          isOpen={isCancelSubscriptionOpen}
          onClose={onCancelSubscriptionClose}
          cancelRef={cancelRef}
        />
        <HStack w="100%" h="100%" spacing={6} alignItems="flex-start">
          <Box w="100%" h="100%">
            <Text pb={2}>Plan Details</Text>
            <Box
              p={6}
              fontSize="sm"
              border="1px solid"
              borderRadius="md"
              borderColor={borderColor}
              w="100%"
              h="max-content"
            >
              <Stack>
                <HStack h="100%" justify="space-between">
                  <Text>
                    {getPlanText(freeTrialPeriod, enterpriseSubscriptionActive, planName)}
                    {freeTrialPeriod ? ` expires ${timestampToDate(freeTrialPeriodEnd)}` : ''}
                  </Text>
                  <Text>
                    {getPlanPriceText(freeTrialPeriod, enterpriseSubscriptionActive, pricePerMonth)}
                  </Text>
                </HStack>
                {isTlmPlan && (
                  <div className="flex h-full justify-between">
                    <p className="flex items-center gap-2">
                      TLM usage{' '}
                      <LinkBlock
                        className="type-body-100 pb-[1px]"
                        onClick={() => {
                          setIsTlmPricingModalOpen(true)
                        }}
                      >
                        (view TLM pricing)
                      </LinkBlock>
                    </p>
                    <p>${(tlmChargesTotal / 100).toFixed(2)}</p>
                  </div>
                )}
                {!enterpriseSubscriptionActive &&
                  !freeTrialPeriod &&
                  overageChargesAmounts &&
                  overageChargesAmounts.length > 0 && (
                    <>
                      <HStack h="100%" justify="space-between">
                        <Text>Overage charges</Text>
                        <Text>${(overageChargesTotal / 100).toFixed(2)}</Text>
                      </HStack>
                      <HStack h="100%" justify="space-between">
                        <Text>Tax</Text>
                        <Text>${(taxAmount / 100).toFixed(2)}</Text>
                      </HStack>
                    </>
                  )}
                {!enterpriseSubscriptionActive && (
                  <>
                    <Divider my="0.5rem" borderColor={borderColor} orientation="horizontal" />
                    <HStack justify="space-between">
                      <Text>Total this month</Text>
                      <Text>{getTotalThisMonth(freeTrialPeriod, amountDue, pricePerMonth)}</Text>
                    </HStack>
                  </>
                )}
              </Stack>
            </Box>
            {individualSubscriptionActive ? (
              <HStack spacing={4} mt={6}>
                <DestructiveButton
                  onClick={() => {
                    onCancelSubscriptionOpen()
                    trackEvent(MixpanelEvents.clickCancelSubscriptionButton, {
                      ...subscriptionDetails,
                    })
                  }}
                >
                  Cancel Subscription
                </DestructiveButton>
                <PrimaryButton
                  height="40px"
                  onClick={() => {
                    onSalesModalOpen()
                    trackEvent(MixpanelEvents.clickUpgradeSubscriptionButton, {
                      ...subscriptionDetails,
                    })
                  }}
                >
                  Upgrade Subscription
                </PrimaryButton>
              </HStack>
            ) : isIndividualPayingCustomer && cancelledAt ? (
              <Text mt={6} fontSize="md" color={warningColor}>
                You have cancelled your subscription to Cleanlab Studio.
                <br /> To reactivate your subscription, contact us at{' '}
                <Link
                  href="mailto:sales@cleanlab.ai?subject=Account Reactivation"
                  isExternal
                  onClick={() => {
                    trackEvent(MixpanelEvents.clickContactUsButton)
                  }}
                >
                  sales@cleanlab.ai
                </Link>
              </Text>
            ) : (
              <></>
            )}
          </Box>
          <PlanDetailsUsage offset={2.5} />
        </HStack>
      </>
    )
  }
}

export default PlanDetails
