import { Tooltip } from '@components/tooltip/Tooltip'
import { timestampToDate, timeStampToDateOnly } from '@utils/functions/timestampToDate'

export const DateOnlyWithTooltip = ({ timestamp }: { timestamp: string | number }) => {
  if (!timestamp) return null
  return (
    <Tooltip content={timestampToDate(timestamp)}>
      <span tabIndex={0} className="focus-visible:outline-none">
        {timeStampToDateOnly(timestamp)}
      </span>
    </Tooltip>
  )
}
