import { FeatureImportanceData } from '@services/cleanset/constants'
import { cn } from '@utils/tailwindUtils'

import chartStyles from '../CleansetCharts.module.css'

const FeatureImportanceTable = ({ data }: { data: FeatureImportanceData }) => {
  return (
    <section
      className={cn(
        chartStyles.chartSectionContentContainer,
        chartStyles.analyticsGridSection,
        'flex flex-col items-center gap-5'
      )}
    >
      <h2 className={`${chartStyles.chartSectionTitle} type-body-300-semibold text-text-primary`}>
        Predictive Importance of each Feature
      </h2>

      <p className={cn(chartStyles.chartSectionDescription, 'type-body-200 !block')}>
        How important each feature is for predicting the label accurately. These scores are
        determined via permutation feature importance scoring, in which the values of each feature
        column are shuffled across rows and the corresponding drop in predictive accuracy is
        recorded. Features with importance near or less than 0 do not help with label prediction. We
        also provide the p-value of a statistical test for whether the importance is greater than 0
        or not.
      </p>

      <div className="type-body-100 mb-12 w-full overflow-hidden rounded-default border border-border-2">
        <table className="w-full [&_td]:p-6">
          <thead className="type-body-100-semibold rounded-t-default border-b bg-surface-2">
            <tr>
              <td width="50%">Feature</td>
              <td width="25%">Importance</td>
              <td width="25%">P-Value</td>
            </tr>
          </thead>
          <tbody>
            {data.map(([featureName, importance, pValue]) => (
              <tr key={featureName} className="even:bg-surface-2">
                <td>{featureName}</td>
                <td>{importance}</td>
                <td>{pValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default FeatureImportanceTable
