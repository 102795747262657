'use client'

import { SlotChild } from '@components/SlotChild'
import { Slot } from '@radix-ui/react-slot'
import { AsChildProps } from '@utils/AsChildProps'
import { cn, tv } from '@utils/tailwindUtils'
import {
  AnchorHTMLAttributes,
  cloneElement,
  ForwardedRef,
  forwardRef,
  memo,
  ReactElement,
  useMemo,
} from 'react'
import { UnionToIntersection } from 'type-fest'

type LinkBlockVariant = 'primary' | 'secondary'

type LinkBlockSize = 'xs' | 'small' | 'medium' | 'large'

export type LinkBlockPropsCommon = AsChildProps<AnchorHTMLAttributes<HTMLAnchorElement>> & {
  variant?: LinkBlockVariant
  size?: LinkBlockSize
  padded?: boolean
}

export type LinkBlockProps = LinkBlockPropsUnion & LinkBlockPropsCommon
export type LinkBlockPropsUnion =
  | {
      iconStart?: ReactElement
    }
  | { iconEnd?: ReactElement }

// For internal use only
type LinkBlockPropsAll = LinkBlockPropsCommon & UnionToIntersection<LinkBlockPropsUnion>

const variants = tv({
  slots: {
    base: 'group relative flex cursor-pointer items-center transition-colors hover:underline focus:underline focus-visible:outline-none focus-visible:ring-0',
    bg: 'absolute bottom-0 left-0 right-0 top-0 rounded-1 group-focus-visible:outline group-focus-visible:outline-1 group-focus-visible:-outline-offset-1 group-focus-visible:outline-blue-700 group-focus-visible:ring group-focus-visible:ring-focus',
  },
  variants: {
    // Main variant
    variant: {
      primary: { base: 'text-blue-700 hover:text-blue-800', bg: 'group-active:bg-blue-50' },
      secondary: {
        base: 'text-text-primary hover:text-text-strong',
        bg: 'group-active:bg-surface-1-active',
      },
    },
    // Additional options
    size: {
      xs: { base: 'type-body-100-medium' },
      small: { base: 'type-body-100-medium' },
      medium: { base: 'type-body-200-medium' },
      large: { base: 'type-body-300-medium' },
    },
    padded: {
      true: { base: 'px-4 py-2' },
      false: { bg: '-left-[0.3em] -right-[0.3em]' },
    },
  },
})

const iconSizes = {
  xs: 12,
  small: 16,
  medium: 20,
  large: 20,
} as const satisfies Record<LinkBlockSize, number>

const LinkBlockBase = (allProps: LinkBlockProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const {
    className,
    children,
    variant = 'primary',
    size = 'medium',
    padded = false,
    iconEnd,
    iconStart,
    asChild = false,
    ...props
  } = allProps as LinkBlockPropsAll

  const Comp = asChild ? Slot : 'a'
  let iconStartClone: ReturnType<typeof cloneElement> | undefined
  let iconEndClone: ReturnType<typeof cloneElement> | undefined
  if (iconStart) {
    iconStartClone = cloneElement(iconStart, {
      size: iconSizes[size],
    })
  } else if (iconEnd) {
    iconEndClone = cloneElement(iconEnd, {
      size: iconSizes[size],
    })
  }
  const { baseClass, bgClass } = useMemo(() => {
    const { base, bg } = variants({ variant, size, padded })
    return {
      baseClass: cn(base(), className),
      bgClass: bg(),
    }
  }, [className, padded, size, variant])

  const ret = (
    <Comp ref={ref} className={baseClass} {...props}>
      <SlotChild asChild={!!asChild} child={children}>
        {(child) => (
          <>
            {/* 
            Render background as separate absolutely positioned element to 
            be able increase its horizontal padding  without impacting layout 
            */}
            <div className={bgClass} />

            {/* Use em-based margin to optically adjust icon alignment proportional to text size */}
            {iconStartClone && <div className="relative -ms-[0.0625em] pe-2">{iconStartClone}</div>}

            <div className="relative">{child}</div>

            {/* Use em-based margin to optically adjust icon alignment proportional to text size */}
            {iconEndClone && <div className="p relative -me-[0.0625em] ps-2">{iconEndClone}</div>}
          </>
        )}
      </SlotChild>
    </Comp>
  )
  return ret
}

/**
 * LinkBlock component
 */
export const LinkBlock = memo(forwardRef(LinkBlockBase))
LinkBlock.displayName = 'LinkBlock'
