'use client'

import { SlotChild } from '@components/SlotChild'
import { Slot } from '@radix-ui/react-slot'
import { AsChildProps } from '@utils/AsChildProps'
import { cn, tv } from '@utils/tailwindUtils'
import {
  ButtonHTMLAttributes,
  cloneElement,
  ForwardedRef,
  forwardRef,
  memo,
  ReactElement,
  useMemo,
} from 'react'

type ButtonSubtleVariant = 'default'
type ButtonSubtleSize = 'xSmall'

export type ButtonSubtleProps = AsChildProps<ButtonHTMLAttributes<HTMLButtonElement>> & {
  iconEnd?: ReactElement
}

const variants = tv({
  base: 'relative flex cursor-pointer items-center rounded-2 transition-colors focus-visible:outline focus-visible:outline-1 focus-visible:-outline-offset-1 focus-visible:outline-blue-700 focus-visible:ring focus-visible:ring-focus',
  variants: {
    // Main variant
    variant: {
      default: 'bg-surface-0 text-text-primary hover:bg-surface-0-hover active:bg-surface-0-active',
    },
    // Additional options
    size: {
      xSmall: 'type-caption-medium px-3 py-2',
    },
  },
})

const iconSizes = {
  xSmall: 12,
} as const satisfies Record<ButtonSubtleSize, number>

const ButtonSubtleBase = (
  { className, children, iconEnd, asChild = false, ...props }: ButtonSubtleProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const variant: ButtonSubtleVariant = 'default'
  const size: ButtonSubtleSize = 'xSmall'

  const Comp = asChild ? Slot : 'button'
  let iconEndClone: ReturnType<typeof cloneElement> | undefined
  if (iconEnd) {
    iconEndClone = cloneElement(iconEnd, {
      size: iconSizes[size],
    })
  }
  const baseClass = useMemo(() => {
    return cn(variants({ variant, size }), className)
  }, [className, size, variant])

  const ret = (
    <Comp ref={ref} className={baseClass} {...props}>
      <SlotChild asChild={!!asChild} child={children}>
        {(child) => (
          <>
            <div className="relative">{child}</div>
            {/* Use em-based margin to optically adjust icon alignment proportional to text size */}
            {iconEndClone && <div className="p relative -me-[0.0625em] ps-3">{iconEndClone}</div>}
          </>
        )}
      </SlotChild>
    </Comp>
  )
  return ret
}

/**
 * ButtonSubtle component
 */
export const ButtonSubtle = memo(forwardRef(ButtonSubtleBase))
ButtonSubtle.displayName = 'ButtonSubtle'
