import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { Button } from '@components/button/Button'
import { FormField } from '@components/formField/FormField'
import { IconHelpCircle } from '@components/icons'
import { Input } from '@components/input/Input'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useEditOrgBudget } from '@services/organizationQuotas/mutations'
import { useOrgUsage } from '@services/organizationQuotas/queries'
import { useForm } from 'react-hook-form'

import { ButtonCloseModalChakra } from '../ButtonCloseChakraModal'
import { ModalProps } from '../Modal.types'

type EditBudgetModalProps = ModalProps

const EditBudgetModal = (props: EditBudgetModalProps) => {
  const { isOpen, onClose } = props
  const { register, watch, formState, reset, getValues } = useForm()
  const toast = useToast()
  const surface0 = useColorModeValue('surface.0', 'surfaceDarkMode.0')

  const { mutate: editOrgBudget, isLoading: isEditBudgetLoading } = useEditOrgBudget({
    onSuccess: () => {
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Budget updated',
          status: 'success',
        }),
      })
      onModalClose()
    },
    onError: () =>
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Failed to update budget',
          status: 'warning',
        }),
      }),
  })

  const { organizationQuotaId, totalBudgetDollars, totalCost } = useOrgUsage()

  const inputValue = watch('totalBudgetDollars')

  const onModalClose = () => {
    reset()
    onClose()
  }

  const onSubmit = () => {
    const formValues = getValues()
    editOrgBudget({
      organizationQuotaId: organizationQuotaId,
      orgBudget: formValues.totalBudgetDollars ?? 0,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent bg={surface0}>
        <ModalHeader pb={0} className="text-text-strong">
          Edit budget
        </ModalHeader>
        <ButtonCloseModalChakra />
        <ModalBody py={0}>
          <div className="flex flex-col gap-6 pb-8 pt-4">
            <p className="type-body-200 text-text-primary">
              You can edit your organization’s total budget. For additional help feel free to reach
              out for additional support.
            </p>
            <FormField
              label={
                <div className="flex w-full flex-row justify-between">
                  <p className="text-text-strong">Total budget</p>
                  <p className="text-text-faint">
                    Total remaining: ${totalBudgetDollars - (totalCost > 0 ? totalCost : 0)}
                  </p>
                </div>
              }
            >
              <Input
                type="number"
                error={
                  formState.isDirty && (!inputValue || inputValue < totalCost || inputValue < 0)
                }
                size="large"
                {...register('totalBudgetDollars')}
                placeholder={`$${totalBudgetDollars}`}
              />
            </FormField>
            <ModalFooter p={0} justifyContent="space-between">
              <LinkBlock
                href="mailto:support@cleanlab.ai"
                size="small"
                iconStart={<IconHelpCircle />}
                variant="secondary"
              >
                Contact support
              </LinkBlock>
              <div className="flex flex-row gap-5">
                <Button type="button" variant="secondary" onClick={onModalClose}>
                  Cancel
                </Button>
                <Button
                  disabled={
                    !inputValue || inputValue < totalCost || inputValue < 0 || isEditBudgetLoading
                  }
                  variant="primary"
                  onClick={onSubmit}
                >
                  {isEditBudgetLoading ? <Spinner size="sm" /> : 'Save budget'}
                </Button>
              </div>
            </ModalFooter>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EditBudgetModal
