import { FileStatus } from 'filepond'

export const findBestMatchingString = (options: string[], orderedKeywords: string[]) => {
  for (const keyword of orderedKeywords) {
    return options.find((s) => s.toLowerCase().includes(keyword.toLowerCase()))
  }
}

export const isUploadReady = (file?: { status?: number }) => {
  return file?.status === FileStatus.PROCESSING_COMPLETE
}

export const uploadHasError = (file?: { status?: number }) => {
  return (
    file?.status === FileStatus.PROCESSING_ERROR ||
    file?.status === FileStatus.PROCESSING_REVERT_ERROR ||
    file?.status === FileStatus.LOAD_ERROR
  )
}
