import { Box, FormControl, FormLabel, Input } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import useGridClassname from '@hooks/useGridClassname'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import React from 'react'

import { ControlledFormInputElementProps, QueryResultsProps } from './QueryInterface.types'

export const ControlledFormInputElement = (props: ControlledFormInputElementProps) => {
  const { id, type, label, value, setValue } = props
  return (
    <FormControl>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <Input
        id={id}
        type={type}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
      />
    </FormControl>
  )
}

const timestampFields = ['upload_date', 'signup_token_timestamp', 'last_updated', 'created_at']

export const QueryResults = (props: QueryResultsProps) => {
  const { data, resource } = props
  const colorModeValue = useGridClassname()

  if (data === null || data.length === 0) {
    return <div>No {resource} found.</div>
  }

  const baseColumnDefs: ColDef[] = [
    { field: 'id', resizable: true },
    { field: 'name', resizable: true },
  ]
  const columnDefs: ColDef[] = Object.keys(data[0]).reduce((acc, field) => {
    if (field === 'id' || field === 'name') {
      return acc
    }
    if (
      timestampFields.map((timestampField) => field === timestampField).some((boolean) => boolean)
    ) {
      acc.push({
        field,
        valueFormatter: (params) => timestampToDate(params.data[field]),
        resizable: true,
      })
    } else {
      acc.push({ field, resizable: true })
    }
    return acc
  }, baseColumnDefs)

  return (
    <Box className={colorModeValue} h="40vh" w="100%">
      <CleanlabGrid rowData={data} pagination paginationPageSize={10} columnDefs={columnDefs} />
    </Box>
  )
}
