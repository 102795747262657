import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { queryKeys, SignupSettings } from '@services/signupSettings/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useQuery } from 'react-query'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/signup_settings`,
  withCredentials: true,
})

export const useSignupSettings = (): SignupSettings => {
  const { data } = useQuery({
    queryKey: queryKeys.signupSettings.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/', createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Failed to fetch signup settings.' },
  })
  return data
}
