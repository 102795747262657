import { ModalCloseButton } from '@chakra-ui/react'
import { ButtonClose } from '@components/buttonClose/ButtonClose'
import { ComponentPropsWithoutRef, ComponentPropsWithRef, forwardRef } from 'react'

export const ButtonCloseModalChakra = forwardRef(
  (
    props: ComponentPropsWithoutRef<typeof ModalCloseButton>,
    ref: ComponentPropsWithRef<typeof ModalCloseButton>['ref']
  ) => {
    return (
      <ModalCloseButton
        as="div"
        aria-label={undefined}
        type={undefined}
        variant="transparent"
        ref={ref}
        {...props}
      >
        <ButtonClose size="small" aria-label="Close dialog" />
      </ModalCloseButton>
    )
  }
)
