import { Box } from '@chakra-ui/react'
import Colors from '@common/Colors'
import { Badge } from '@components/badge/Badge'
import { IconPlus, IconWarning } from '@components/icons'
import { cn } from '@utils/tailwindUtils'

import { EXCLUDE_COMMAND_NAME } from '../CommandPalette.helpers'
import { ActionType } from '../CommandPalette.types'

const BADGE_HEIGHT = 50
const ITEM_HEIGHT = 60
const TALL_ITEM_HEIGHT = 88
const XL_ITEM_HEIGHT = 118

export const highlightSubstring = (target: string, substring: string) => {
  const startIndex = target.toLowerCase().indexOf(substring.toString())
  if (startIndex === -1) {
    return target
  }

  return (
    <>
      <Box as="span">{target.substring(0, startIndex)}</Box>
      <Box as="span" color={Colors.PaletteSuggestion.highlightSubstringColor} fontWeight={700}>
        {target.substring(startIndex, startIndex + substring.length)}
      </Box>
      <Box as="span">{target.substring(startIndex + substring.length, target.length)}</Box>
    </>
  )
}

export const displayedTag = (
  category: ActionType | string,
  isAppliedAction: boolean,
  isNewlyAddedLabel: boolean,
  showXLView: boolean,
  name: string
) => {
  const badgeContainerClass = cn(
    'flex flex-wrap items-center gap-4',
    showXLView ? 'items-start' : ''
  )
  switch (category) {
    case 'auto-fix':
      return (
        <div className={badgeContainerClass}>
          <Badge round="small" size="medium" variant="subtle" hue="neutral">
            <p className="text-nowrap">Our suggestion</p>
          </Badge>
          {name === EXCLUDE_COMMAND_NAME ? (
            <Badge round="small" size="medium" variant="actionSubtle" hue="pink">
              {getActionTense(ActionType.EXCLUDE, isAppliedAction)}
            </Badge>
          ) : (
            <Badge
              className="min-w-fit"
              round="small"
              size="medium"
              variant="actionSubtle"
              hue="green"
            >
              {getActionTense(category, isAppliedAction)}
            </Badge>
          )}
        </div>
      )
    case 'exclude':
      return (
        <div className={badgeContainerClass}>
          <Badge round="small" size="medium" variant="actionSubtle" hue="pink">
            {getActionTense(category, isAppliedAction)}
          </Badge>
        </div>
      )
    case 'keep':
      return (
        <div className={badgeContainerClass}>
          <Badge round="small" size="medium" variant="subtle" hue="neutral">
            <p className="text-nowrap">Given label</p>
          </Badge>
          <Badge
            className="min-w-fit"
            round="small"
            size="medium"
            variant="actionSubtle"
            hue="purple"
          >
            {isAppliedAction ? 'Kept' : 'Keep'}
          </Badge>
        </div>
      )
    case ActionType.ADD_LABEL:
      return (
        <div className={badgeContainerClass}>
          <Badge hue="cyan">
            <p className="text-nowrap">Create new</p>
          </Badge>
          <IconPlus />
        </div>
      )
    default:
      return (
        <div className={badgeContainerClass}>
          {isNewlyAddedLabel && (
            <Badge round="small" size="medium" variant="subtle" hue="blue">
              <p className="text-nowrap">New</p>
            </Badge>
          )}
          <Badge
            className="min-w-fit"
            round="small"
            size="medium"
            variant="actionSubtle"
            hue="blue"
          >
            {getActionTense(category, isAppliedAction)}
          </Badge>
        </div>
      )
  }
}

export const getActionTense = (category: string, isActionApplied: boolean) => {
  switch (category) {
    case ActionType.AUTO_FIX:
      return isActionApplied ? 'Auto-fixed' : 'Auto-fix'
    case ActionType.EXCLUDE:
      return isActionApplied ? 'Excluded' : 'Exclude'
    case ActionType.KEEP:
      return isActionApplied ? 'Kept' : 'Keep'
    case ActionType.RELABEL:
      return isActionApplied ? 'Corrected' : 'Correct'
    default:
      return 'Correct'
  }
}

export const capAndRoundProbability = (probability: number | undefined) => {
  if (probability === undefined) {
    return '<1'
  }
  const rounded = Math.round(probability * 100)
  if (rounded < 1) {
    // Adding this for backwards compatibility. Now we only saved predicted probabilities >= 1%.
    // Projects created before this will have probabilities < 1%, which would display as 0% after rounding
    return '<1'
  }
  const capped = Math.min(rounded, 99)
  return `${capped}`
}

export const InvalidLabelTypeWarning = () => {
  return (
    <div className="my-2 flex flex-row items-center gap-4 rounded-2 border border-border-1 p-4 text-red-600">
      <IconWarning size={20} />
      <p style={{ fontSize: '16px' }}>
        Invalid label type. New label must have same type as given label.
      </p>
    </div>
  )
}

export const getBorderStyle = (category: string, isAppliedAction: boolean): string => {
  if (category === ActionType.ADD_LABEL) {
    return 'border border-dashed border-cyan-600'
  } else if (isAppliedAction) {
    return 'border-2 border-cyan-600'
  } else {
    return 'border'
  }
}

export const checkIsPaletteOverflowing = () => {
  const paletteTextElements = document.getElementsByClassName('palette-label-text')
  const paletteRowElements = document.getElementsByClassName('palette-row')
  for (const element of paletteTextElements) {
    const isOverflowing =
      element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight

    if (isOverflowing) {
      return true
    }
  }

  for (const element of paletteRowElements) {
    const isOverflowing = element.clientWidth < element.scrollWidth

    if (isOverflowing) {
      return true
    }
  }
  return false
}

export const checkIsBadgeOverflowing = () => {
  const paletteBadgeElements = document.getElementsByClassName('palette-badges-tall')
  for (const element of paletteBadgeElements) {
    const isWrapping = element.clientHeight > BADGE_HEIGHT

    if (isWrapping) {
      return true
    }
  }
  return false
}

export const getItemSize = (showTallView: boolean, showXLView: boolean) => {
  if (showXLView) {
    return XL_ITEM_HEIGHT
  } else if (showTallView) {
    return TALL_ITEM_HEIGHT
  }
  return ITEM_HEIGHT
}
