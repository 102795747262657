import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'

import { PresetHeaders } from '../../filterReducer/FilterState.types'
import { TooltipLabel } from '../TooltipLabels'
import { DatasheetColumnConfig, DatasheetColumnType } from './ColumnConfigs.types'

const CleanlabColumnConfigs: { [columnName: string]: DatasheetColumnConfig } = {
  _cleanlab_issue_tags: {
    headerName: 'Issues',
    tooltip: 'issueTags',
    isInitiallyHidden: false,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_suggested_label: {
    headerName: PresetHeaders.Suggested,
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_suggested_label_confidence_score: {
    headerName: PresetHeaders.SuggestedScore,
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_clean_label: {
    headerName: PresetHeaders.Corrected,
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_issue: {
    headerName: 'Label Issue',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_label_issue_score: {
    headerName: 'Label Issue Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_outlier: {
    headerName: 'Outlier',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
    tooltipLabel: TooltipLabel.OUTLIER,
  },
  _cleanlab_outlier_score: {
    headerName: 'Outlier Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_ambiguous: {
    headerName: 'Ambiguous',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_ambiguous_score: {
    headerName: 'Ambiguous Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_near_duplicate: {
    headerName: 'Near Duplicate',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_near_duplicate_score: {
    headerName: 'Near Duplicate Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_near_duplicate_id: {
    headerName: 'Near Duplicate Cluster ID',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_unlabeled: {
    headerName: 'Unlabeled',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_action: {
    headerName: 'Action',
    isInitiallyHidden: false,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_custom_tags: {
    headerName: 'Tags',
    tooltip: 'tags',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_best_examples: {
    headerName: 'Well Labeled',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_is_PII: {
    headerName: 'PII',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_PII_score: {
    headerName: 'PII Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_PII_types: {
    headerName: 'PII Types',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_PII_items: {
    headerName: 'PII Items',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_is_informal: {
    headerName: 'Informal',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_informal_score: {
    headerName: 'Informal Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_spelling_issue_score: {
    headerName: 'Spelling Issue Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_grammar_issue_score: {
    headerName: 'Grammar Issue Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_slang_issue_score: {
    headerName: 'Slang Issue Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_toxic: {
    headerName: 'Toxic',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_toxic_score: {
    headerName: 'Toxic Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_biased: {
    headerName: 'Biased',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_bias_score: {
    headerName: 'Bias Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_gender_bias_score: {
    headerName: 'Gender Bias Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_racial_bias_score: {
    headerName: 'Racial Bias Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_sexual_orientation_bias_score: {
    headerName: 'Sexual Orientation Bias Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_non_english: {
    headerName: 'Non English',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_non_english_score: {
    headerName: 'Non English Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_predicted_language: {
    headerName: 'Predicted Language',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
  },
  _cleanlab_sentiment_score: {
    headerName: 'Sentiment Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_text_num_characters: {
    headerName: 'Text Character Length',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.CUSTOM,
    isNumberColumn: true,
  },
  _cleanlab_is_empty_text: {
    headerName: 'Empty Text',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_is_dark: {
    headerName: 'Dark',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_dark_score: {
    headerName: 'Dark Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_light: {
    headerName: 'Light',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_light_score: {
    headerName: 'Light Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_blurry: {
    headerName: 'Blurry',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_blurry_score: {
    headerName: 'Blurry Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_odd_size: {
    headerName: 'Odd Size',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_odd_size_score: {
    headerName: 'Odd Size Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_low_information: {
    headerName: 'Low Information',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_low_information_score: {
    headerName: 'Low Information Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_grayscale: {
    headerName: 'Grayscale',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_grayscale_score: {
    headerName: 'Grayscale Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_odd_aspect_ratio: {
    headerName: 'Odd Aspect Ratio',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_odd_aspect_ratio_score: {
    headerName: 'Odd Aspect Ratio Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_aesthetic_score: {
    headerName: 'Aesthetic Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_is_NSFW: {
    headerName: 'NSFW',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
  _cleanlab_NSFW_score: {
    headerName: 'NSFW Score',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.SCORE,
  },
  _cleanlab_has_rare_class: {
    headerName: 'Has Rare Class',
    isInitiallyHidden: true,
    columnType: DatasheetColumnType.ISSUE,
  },
}

export const EnabledCleanlabColumnConfigs = Object.fromEntries(
  Object.entries(CleanlabColumnConfigs).filter(([_, config]) => {
    return config.featureFlag === undefined || checkFeatureFlag(config.featureFlag)
  })
)
