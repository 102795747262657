import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { AgilityApp } from '@services/agilityApps/constants'
import React from 'react'

import { AgilityAppForm } from '../AgilityAppForm'

interface UpdateAppModalProps {
  isOpen: boolean
  app: AgilityApp
  onClose: () => void
  onSave: (updatedApp: AgilityApp) => void
}

export const UpdateAppModal: React.FC<UpdateAppModalProps> = ({ isOpen, app, onClose, onSave }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Agility App</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AgilityAppForm initialData={app} onSubmit={onSave} isUpdate={true} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
