import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { queryKeys } from '@services/user/constants'
import { RUNNING_IN_PUPPETEER } from '@utils/environmentVariables'
import { AxiosError } from 'axios'
import { QueryCache, QueryClient } from 'react-query'

import { toast } from './ToastContainer'

export const queryClient = new QueryClient({
  defaultOptions: {},
  queryCache: new QueryCache({
    onError: (error, query) => {
      const errorMessage = query?.meta?.error as string
      const err = error as AxiosError
      const suppressToast = query?.meta?.suppress ?? false
      const metaOnError = query?.meta?.onError as ((err: AxiosError) => void) | undefined

      if (`${error}`.toLowerCase().includes('refresh token')) {
        queryClient.invalidateQueries(queryKeys.users.all())
      } else {
        metaOnError && metaOnError(error as AxiosError)

        !suppressToast &&
          !RUNNING_IN_PUPPETEER &&
          notifyAxiosError(toast, err, {
            title: errorMessage || err.name,
            description: errorMessage,
          })
      }
    },
  }),
})
