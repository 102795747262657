import { Button } from '@components/button/Button'
import { useAuth } from '@hooks/useAuth'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth()
  return (
    <Button
      className="shrink-0"
      size="small"
      variant="secondary"
      onClick={() => loginWithRedirect({})}
      aria-label="Login"
    >
      Log in
    </Button>
  )
}

export default LoginButton
