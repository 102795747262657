import { wrapSvgIcon } from './wrapSvgIcon'

export const IconExpand = wrapSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L6.46967 8.46967C6.17678 8.76256 6.17678 9.23744 6.46967 9.53033C6.76256 9.82322 7.23744 9.82322 7.53033 9.53033L12 5.06066L16.4697 9.53033C16.7626 9.82322 17.2374 9.82322 17.5303 9.53033C17.8232 9.23744 17.8232 8.76256 17.5303 8.46967L12.5303 3.46967ZM7.53033 14.4697C7.23744 14.1768 6.76256 14.1768 6.46967 14.4697C6.17678 14.7626 6.17678 15.2374 6.46967 15.5303L11.4697 20.5303C11.7626 20.8232 12.2374 20.8232 12.5303 20.5303L17.5303 15.5303C17.8232 15.2374 17.8232 14.7626 17.5303 14.4697C17.2374 14.1768 16.7626 14.1768 16.4697 14.4697L12 18.9393L7.53033 14.4697Z"
  />,
  { fill: 'currentColor', stroke: 'none' }
)
