import { useEffect, useRef, useState } from 'react'

const useResizeComponent = (minWidth: number) => {
  const componentRef = useRef<HTMLInputElement>(null)
  const [componentWidth, setComponentWidth] = useState(componentRef.current?.clientWidth ?? 0)

  // if no component width is set (first render), set initial component width
  // to the element's width before resizing starts.
  useEffect(() => {
    if (!componentWidth) {
      setComponentWidth(componentRef.current?.clientWidth ?? 0)
    }
  }, [componentWidth])

  const resizeComponent = () => {
    const onMouseMove = (mouseMoveEvent: MouseEvent) => {
      mouseMoveEvent.stopPropagation()
      mouseMoveEvent.preventDefault()
      const width = window.innerWidth - mouseMoveEvent.pageX
      if (width > window.innerWidth * minWidth) {
        setComponentWidth(window.innerWidth - mouseMoveEvent.pageX)
      } else {
        setComponentWidth(window.innerWidth * minWidth)
      }
    }
    const onMouseUp = () => {
      document.body.removeEventListener('mousemove', onMouseMove)
    }

    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  return { componentWidth, resizeComponent, componentRef, setComponentWidth }
}

export default useResizeComponent
