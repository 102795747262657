import { Select } from '@chakra-ui/react'
import { cn } from '@utils/tailwindUtils'
import * as React from 'react'

import { CHART_TEXT_DICT } from '../CleansetCharts.helpers'
import chartStyles from '../CleansetCharts.module.css'
import ModelPerformanceTable from '../modelPerformanceTable/ModelPerformanceTable'

type Props =
  | {
      isPerClass: true
      data: {
        allClasses: string[]
        classData: Record<string, [string, number][]>
      }
    }
  | {
      isPerClass?: false
      data: [string, number][]
    }

const ModelPerformanceSection = (props: Props) => {
  const { data, isPerClass } = props
  const [currentClass, setCurrentClass] = React.useState(isPerClass ? data?.allClasses?.[0] : '')
  const tableData = isPerClass ? data.classData[currentClass] : data

  return (
    <section
      className={cn(
        chartStyles.chartSectionContentContainer,
        chartStyles.analyticsGridSection,
        'flex flex-col items-center gap-5'
      )}
    >
      <h2 className={`${chartStyles.chartSectionTitle} type-body-300-semibold text-text-primary`}>
        {isPerClass
          ? CHART_TEXT_DICT.titles.modelPerformanceByClassSummary
          : CHART_TEXT_DICT.titles.modelPerformanceSummary}
      </h2>

      <p className={cn(chartStyles.chartSectionDescription, 'type-body-200 !block')}>
        {isPerClass
          ? CHART_TEXT_DICT.descriptions.modelPerformanceSummaryByClass
          : CHART_TEXT_DICT.descriptions.modelPerformanceSummary}
      </p>

      <div className="w-full">
        {isPerClass && (
          <label className="flex items-center gap-5">
            <p className="type-body-200-semibold">Class:</p>
            {/* TODO: Chakra component: remove when ready */}
            <Select onChange={(e) => setCurrentClass(e.target.value)} value={currentClass}>
              {data?.allClasses.map((className) => {
                return (
                  <option key={className} value={className}>
                    {className}
                  </option>
                )
              })}
            </Select>
          </label>
        )}

        {tableData && (
          <div className="mt-4 flex-grow">
            <ModelPerformanceTable data={tableData} />
          </div>
        )}
      </div>
    </section>
  )
}

export default ModelPerformanceSection
