import { Center, Flex, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { queryKeys } from '@services/datasheet/constants'
import { useMultiSelectBatchActionMutation } from '@services/datasheet/mutations'
import { useLabelOptions } from '@services/datasheet/queries'
import datasheetApiService from '@services/datasheetApi'
import { cn } from '@utils/tailwindUtils'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import { useQueryClient } from 'react-query'
import { Tasktype } from 'src/pages/datasetUpload/DatasetUpload.types'

import { CleansetContext } from '../CleansetContext'
import CommandPalette from '../commandpalette/CommandPalette'
import { ActionType } from '../commandpalette/CommandPalette.types'
import MultiSelectDisplay from '../commandpalette/multiSelectDisplay/MultiSelectDisplay'
import PaletteHeader from '../commandpalette/paletteHeader/PaletteHeader'
import { CLEANLAB_BACKEND_COLUMN } from '../datasheet/Datasheet.types'
import {
  getGivenLabelDisplay,
  getLabelToProba,
  getSuggestedLabelDisplay,
} from './EditInterface.helpers'
import { EditInterfaceProps, LabelType, RowData } from './EditInterface.types'
import ImageDataDisplay from './imageDataDisplay/ImageDataDisplay'
import IssuesTable from './issuesTable/IssuesTable'
import ResolverHeading from './resolverHeading/ResolverHeading'
import TabularDataDisplay from './tabularDataDisplay/TabularDataDisplay'
import TextDataDisplay from './textDataDisplay/TextDataDisplay'

const EditInterface = (props: EditInterfaceProps) => {
  const queryClient = useQueryClient()
  const {
    cleansetId,
    gridApi,
    originalDatasetColumns,
    projectDetails,
    selectedRowIndex,
    selectedRowData,
    hasCustomTagsColumn,
    isTemplate,
    selectedRows,
    onClose,
    resolverFailure,
    setLastActionState,
    isFetchingRows,
    updateRowAction,
    handleMultiSelectAction,
    isActionLoading,
    autoAdvance,
    setAutoAdvance,
    componentWidth,
    setResolverWidth,
  } = props
  const { idColumn, modality, labels, labelColumn, predictiveColumns, tasktype } = projectDetails
  const imageMode = projectDetails.modality === 'image'
  const cleansetInfo = useContext(CleansetContext)
  const { trackEvent } = useEventTracking()
  const { isLoading: isMutationLoading } = useMultiSelectBatchActionMutation(gridApi)

  const labelOptions = useLabelOptions(labels, cleansetId, projectDetails.tasktype)
  const newlyAddedLabels = labelOptions.filter((label) => !labels.includes(label))

  const refreshServerSideStore = () => setTimeout(() => gridApi.refreshServerSide({}), 0)

  const resolverTextColor = useColorModeValue('neutral.700', 'neutralDarkMode.700')

  const isMultiSelect = selectedRows.length > 1
  const dataColValue = selectedRowData[predictiveColumns?.[0]]
  const isIssue = selectedRowData._cleanlab_issue
  const correctedLabel = selectedRowData._cleanlab_clean_label
  const suggestedLabel = selectedRowData._cleanlab_suggested_label
  const givenLabel = selectedRowData[labelColumn] as LabelType | null
  const givenLabelType = givenLabel ? typeof givenLabel : null
  const isOutlier = selectedRowData?._cleanlab_outlier ?? false
  const isAmbiguous = selectedRowData?._cleanlab_ambiguous ?? false
  const suggestExclude = isOutlier || isAmbiguous
  const isUnlabeled = selectedRowData?._cleanlab_unlabeled ?? false
  const customTagsValue = hasCustomTagsColumn ? selectedRowData?._cleanlab_custom_tags ?? [] : []

  const suggestedLabelDisplay = getSuggestedLabelDisplay(
    suggestedLabel,
    suggestExclude,
    projectDetails.tasktype === Tasktype.MULTICLASS
  )
  const givenLabelDisplay = getGivenLabelDisplay(givenLabel)

  const toast = useToast()

  const topLabels = selectedRowData._cleanlab_top_labels || []
  const topProbs = selectedRowData._cleanlab_top_probs || []
  const currentRowAction = selectedRowData._cleanlab_action

  const resolverBackgroundColor = useColorModeValue('white', 'neutralDarkMode.100')

  const [showHalfResolver, setShowHalfResolver] = useState(false)

  const commandPaletteClass = cn(
    'h-full flex-col items-start border-t border-border-1 bg-surface-1',
    isMultiSelect ? 'w-full' : 'w-[50%]'
  )

  const labelToProb = getLabelToProba(topLabels, topProbs)

  useEffect(() => {
    // If multi-select is active, halve the resolver width
    if (isMultiSelect && !showHalfResolver) {
      setShowHalfResolver(true)
      setResolverWidth((prevComponentWidth) => (prevComponentWidth ?? 0) / 2)
    } else if (showHalfResolver && !isMultiSelect) {
      setShowHalfResolver(false)
      setResolverWidth((prevComponentWidth) => (prevComponentWidth ?? 0) * 2)
    }
  }, [showHalfResolver, isMultiSelect, setResolverWidth])

  const updateRowCustomTag = async (tag: string) => {
    if (selectedRowData[idColumn] === undefined) {
      return
    }
    trackEvent(
      MixpanelEvents.marksNeedsReview,
      { ...cleansetInfo, tag, isTemplate: isTemplate },
      { rowId: selectedRowData[idColumn] }
    )
    try {
      const selectedNode = gridApi.getSelectedNodes()[0]
      const selectedRowData = selectedNode.data as RowData
      const selectedRowId = selectedRowData[idColumn] as string
      const isTagRemoval = customTagsValue.includes(tag)
      await datasheetApiService.updateRowCustomTag(cleansetId, selectedRowId, tag)
      refreshServerSideStore()
      void queryClient.invalidateQueries(
        queryKeys.datasheet
          .id(cleansetId)
          .columnValues()
          .column(CLEANLAB_BACKEND_COLUMN.CUSTOM_TAGS)
      )
      setLastActionState({
        rowIdx: (selectedNode.rowIndex ?? 0) + 1,
        rowId: selectedRowId,
        type: isTagRemoval ? 'remove ' + tag : tag,
        previousState: {
          action: selectedRowData._cleanlab_action,
          tags: selectedRowData._cleanlab_custom_tags,
          correctedLabel: selectedRowData._cleanlab_clean_label,
        },
        undone: false,
      })
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError)
    }
  }

  // Row object without any cleanlab added columns.
  const omitColumns = [labelColumn]
  const originalCurrRowData = _.omit(_.pick(selectedRowData, originalDatasetColumns), omitColumns)

  const resolverLeftClass = cn('h-full w-[50%] overflow-x-clip border-r border-t border-border-1')

  return (
    <div className="h-full w-full items-start overflow-y-hidden rounded-tl-2 border-l border-t border-border-1 bg-surface-0">
      <ResolverHeading
        autoAdvance={autoAdvance}
        onClose={onClose}
        isMultiSelect={isMultiSelect}
        setAutoAdvance={setAutoAdvance}
        isTemplate={isTemplate}
      />
      {selectedRowData && (
        <div className="flex h-[94%] w-full">
          {/* Left side of the resolver (datapoint) */}
          {!showHalfResolver && (
            <div className={resolverLeftClass}>
              <IssuesTable
                resolverTextColor={resolverTextColor}
                selectedRowData={selectedRowData}
                givenLabel={givenLabel ?? ''}
                isMultiClass={projectDetails.tasktype === Tasktype.MULTICLASS}
              />
              {/* Edit interface for text and image modalities */}
              {modality === 'text' && (
                <TextDataDisplay
                  textColumnName={predictiveColumns?.[0]}
                  text={dataColValue as string}
                />
              )}
              {imageMode && (
                <ImageDataDisplay
                  src={
                    selectedRowData[
                      `_cleanlab_media_url_${projectDetails.imageColumns[0]}`
                    ] as string
                  }
                  labels={labels}
                  givenLabel={givenLabel}
                  suggestedLabel={suggestedLabel}
                  isLabelIssue={isIssue}
                  isMulticlass={projectDetails.tasktype === Tasktype.MULTICLASS}
                />
              )}

              {/* Edit interface for tabular modality */}
              {modality === 'tabular' && (
                <TabularDataDisplay
                  data={[...Object.entries(originalCurrRowData)]}
                  projectDetails={projectDetails}
                />
              )}
            </div>
          )}
          {/* Right side of the resolver (actions) */}
          <div className={commandPaletteClass}>
            {isMultiSelect && (
              <MultiSelectDisplay
                numRows={selectedRows?.length}
                onClearAll={() => handleMultiSelectAction(ActionType.MARK_UNRESOLVED)}
              />
            )}
            <PaletteHeader
              selectedRowData={selectedRowData}
              updateRowCustomTag={updateRowCustomTag}
              handleMultiSelectAction={handleMultiSelectAction}
              isMultiSelect={isMultiSelect}
              showDataLabelingWorkflow={false}
            />
            <CommandPalette
              selectedRowIndex={selectedRowIndex}
              labels={labelOptions}
              givenLabel={givenLabelDisplay}
              suggestedLabel={suggestedLabelDisplay}
              correctedLabel={correctedLabel?.toString() ?? null}
              suggestedAction={suggestExclude ? ActionType.EXCLUDE : ActionType.AUTO_FIX}
              labelToProba={labelToProb}
              updateRowAction={updateRowAction}
              handleMultiSelectAction={handleMultiSelectAction}
              updateRowCustomTag={updateRowCustomTag}
              isLabelIssue={isIssue}
              isUnlabeled={isUnlabeled}
              suggestExclude={suggestExclude}
              taskType={tasktype}
              projectDetails={projectDetails}
              selectedRows={selectedRows}
              isLoading={isMutationLoading || isFetchingRows}
              isActionLoading={isActionLoading}
              resolverWidth={componentWidth ?? 0}
              suggestedLabelDisplay={suggestedLabelDisplay}
              isFetchingRows={isFetchingRows}
              givenLabelType={givenLabelType}
              currentRowAction={currentRowAction}
              autoAdvance={autoAdvance}
              newlyAddedLabels={newlyAddedLabels}
            />
          </div>
        </div>
      )}
      {resolverFailure ? (
        <Center flexDirection="column" bg={resolverBackgroundColor} h="85vh" w="100%">
          <FiAlertTriangle size={52} color="red" />
          <Text mt={4} fontSize="lg">
            Could not load the Resolver. Please check your connection and try again.
          </Text>
        </Center>
      ) : (
        <Flex bg={resolverBackgroundColor} w="100%" h="85vh" align="center" justify="center">
          <Spinner />
        </Flex>
      )}
    </div>
  )
}

export default EditInterface
