'use client'

import { FormFieldProvider, useFormField } from '@components/formField/FormFieldContext'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { cn, tv } from '@utils/tailwindUtils'
import { ComponentPropsWithRef, forwardRef, memo, ReactNode } from 'react'
import * as React from 'react'

const Circle = ({ className, size = 24 }: { className: string; size?: number }) => {
  const radius = size / 2
  return (
    <svg
      className={className}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={radius} cy={radius} r={radius} />
    </svg>
  )
}

type RadioGroupProps = Omit<
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>,
  'defaultValue' | 'checked' | 'defaultChecked' | 'onCheckedChange' | 'value'
> & { value: string }

const RadioGroupBase = (
  {
    className,
    id: idProp,
    required: requiredProp,
    disabled: disabledProp,
    ...props
  }: RadioGroupProps,
  ref: ComponentPropsWithRef<typeof RadioGroupPrimitive.Root>['ref']
) => {
  const formFieldContext = useFormField()
  const id = idProp || formFieldContext?.htmlFor || undefined
  const required =
    requiredProp !== null && requiredProp !== undefined
      ? !!requiredProp
      : !!formFieldContext?.required
  const disabled =
    disabledProp !== null && disabledProp !== undefined
      ? !!disabledProp
      : !!formFieldContext?.disabled

  return (
    <FormFieldProvider disabled={disabled}>
      <RadioGroupPrimitive.Root
        id={id}
        required={required}
        disabled={disabled}
        className={cn('grid gap-2', className)}
        {...props}
        ref={ref}
      />
    </FormFieldProvider>
  )
}
const RadioGroup = memo(forwardRef(RadioGroupBase))
RadioGroup.displayName = 'RadioGroup'

const variants = tv({
  base: 'group flex h-6 w-6 shrink-0 grow-0 items-center justify-center rounded-full shadow-elev-0 outline outline-1 -outline-offset-1 outline-border-1 transition-all focus-visible:outline-1',
  variants: {
    variant: {
      default: '',
      highContrast: '',
    },
    focusIsVisible: {
      true: 'focus-visible:ring focus-visible:ring-focus',
      false: 'focus-visible:ring-0',
    },
    disabled: {
      true: 'cursor-not-allowed bg-surface-disabled text-text-disabled outline-transparent',
      false:
        'text-text-high-contrast data-[state=unchecked]:bg-surface-1 data-[state=unchecked]:outline-border-1 hover:data-[state=unchecked]:bg-surface-1-hover',
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      disabled: false,
      class: 'bg-blue-600 outline-transparent hover:bg-blue-700',
    },
    {
      variant: 'default',
      focusIsVisible: true,
      class: 'focus-visible:outline-blue-50 data-[state=unchecked]:focus-visible:outline-blue-700',
    },
    {
      variant: 'highContrast',
      disabled: false,
      class: 'bg-surface-high-contrast outline-transparent hover:bg-neutral-800',
    },
    {
      variant: 'highContrast',
      focusIsVisible: true,
      class:
        'focus-visible:outline-neutral-0 data-[state=unchecked]:focus-visible:outline-neutral-900',
    },
  ],
})

type RadioGroupItemContextValue = { focusIsVisible: boolean }
const RadioGroupItemContext = React.createContext<RadioGroupItemContextValue>({
  focusIsVisible: true,
})
export const RadioGroupItemProvider = ({
  value,
  children,
}: {
  children: ReactNode
  value: RadioGroupItemContextValue
}) => {
  return <RadioGroupItemContext.Provider value={value}>{children}</RadioGroupItemContext.Provider>
}
export const useRadioGroupItemContext = () => {
  return React.useContext(RadioGroupItemContext)
}

type RadioGroupItemProps = React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
  variant?: 'default' | 'highContrast'
}
const RadioGroupItemBase = (
  {
    className,
    id: idProp,
    required: requiredProp,
    disabled: disabledProp,
    variant = 'default',
    ...props
  }: RadioGroupItemProps,
  ref: ComponentPropsWithRef<typeof RadioGroupPrimitive.Item>['ref']
) => {
  const formFieldContext = useFormField()
  const id = idProp || formFieldContext?.htmlFor || undefined
  const required =
    requiredProp !== null && requiredProp !== undefined
      ? !!requiredProp
      : !!formFieldContext?.required
  const disabled =
    disabledProp !== null && disabledProp !== undefined
      ? !!disabledProp
      : !!formFieldContext?.disabled
  const focusIsVisible = !!useRadioGroupItemContext()?.focusIsVisible

  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      id={id}
      required={required}
      disabled={disabled}
      className={cn(variants({ variant, disabled: disabled, focusIsVisible }), className)}
      {...props}
    >
      <RadioGroupPrimitive.Indicator
        forceMount={true}
        className="flex scale-0 items-center justify-center transition-all duration-400 ease-scale-out-bounce data-[state=checked]:scale-100 data-[state=unchecked]:duration-100 data-[state=unchecked]:ease-in"
      >
        <Circle className="h-3 w-3 fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
}

const RadioGroupItem = memo(forwardRef(RadioGroupItemBase))
RadioGroupItem.displayName = 'RadioGroupItem'

export { RadioGroup, RadioGroupItem }
