import { CSSProperties, useEffect, useRef, useState } from 'react'
import AutoSizer, { Size } from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import { LabelType } from '../../editInterface/EditInterface.types'
import { CommandProps } from '../CommandPalette.types'
import PaletteSuggestion from '../paletteSuggestion/PaletteSuggestion'
import {
  checkIsBadgeOverflowing,
  checkIsPaletteOverflowing,
  getItemSize,
} from '../paletteSuggestion/PaletteSuggestion.helpers'

interface MulticlassPaletteCommandsProps {
  displayedCommands: CommandProps[]
  inputValue: string
  labelToProba: Record<string, number>
  selectedRowIndex: number
  selectedRows: any[]
  givenLabelType: LabelType | null
  correctedLabel: string
  markUnresolvedAction: any
  currentRowAction: string
  newlyAddedLabels: string[]
}

const MulticlassPaletteCommands = (props: MulticlassPaletteCommandsProps) => {
  const {
    displayedCommands,
    inputValue,
    labelToProba,
    selectedRowIndex,
    selectedRows,
    givenLabelType,
    correctedLabel,
    markUnresolvedAction,
    currentRowAction,
    newlyAddedLabels,
  } = props

  const [paletteWidth, setPaletteWidth] = useState<number>(0)
  const [showTallView, setShowTallView] = useState<boolean>(false)
  const [showXLView, setShowXLView] = useState<boolean>(false)
  const [paletteBreakPoint, setPaletteBreakPoint] = useState<number | null>(null)

  const listRef = useRef<FixedSizeList | null>(null)

  // Reset FixedSizedList scroll position when selectedRowIndex changes
  useEffect(() => {
    listRef?.current?.scrollToItem(0)
  }, [selectedRowIndex])

  useEffect(() => {
    const isLabelOverflowing = checkIsPaletteOverflowing()
    const isBadgeOverflowing = checkIsBadgeOverflowing()

    // If there is overflow, show the tall view and set breakpoint
    if (isLabelOverflowing && !showTallView) {
      setShowTallView(true)
      setPaletteBreakPoint(paletteWidth)
    }
    // If resolver is resized past the breakpoint, show small view
    else if (paletteBreakPoint && paletteWidth > paletteBreakPoint && showTallView) {
      setShowTallView(false)
      setShowXLView(false)
      // If showing tall view and the badges overflow, show XL view
    } else if (showTallView && isBadgeOverflowing && !showXLView) {
      setShowXLView(true)
      // Reset to tall view if badges no longer overflow
    } else if (showXLView && !isBadgeOverflowing) {
      setShowXLView(false)
    }
  }, [paletteBreakPoint, paletteWidth, showTallView, showXLView])

  return (
    <div data-testid="multiclass-palette-commands" className="h-full w-full">
      <AutoSizer>
        {({ height, width }: Size) => {
          setPaletteWidth(width)
          return (
            <FixedSizeList
              height={height + 30}
              itemCount={displayedCommands.length}
              itemSize={getItemSize(showTallView, showXLView)}
              width={width}
              ref={listRef}
            >
              {({ index, style }: { index: number; style: CSSProperties }) => {
                return (
                  <div style={style} className="pb-5">
                    <PaletteSuggestion
                      key={selectedRowIndex + '-' + index}
                      inputValue={inputValue}
                      taskType={Tasktype.MULTICLASS}
                      labels={labelToProba}
                      isMultiSelect={selectedRows.length > 1}
                      givenLabelType={givenLabelType}
                      correctedLabel={correctedLabel}
                      markUnresolvedAction={markUnresolvedAction}
                      currentRowAction={currentRowAction}
                      showTallView={showTallView}
                      showXLView={showXLView}
                      {...displayedCommands[index]}
                      newlyAddedLabels={newlyAddedLabels}
                    />
                  </div>
                )
              }}
            </FixedSizeList>
          )
        }}
      </AutoSizer>
    </div>
  )
}

export default MulticlassPaletteCommands
