import { Flex, HStack, Text, Tooltip } from '@chakra-ui/react'

import MultiLabelLegend from '../multiLabelLegend/MultiLabelLegend'
import MultiLabelTag from './multiLabelTag/MultiLabelTag'
import { COLUMN_TYPE, MultiLabelTagRendererParams } from './MultiLabelTagParams'

const MultiLabelTagCellRenderer = (params: MultiLabelTagRendererParams) => {
  const rowData = params.node.data

  const generateSortedTagIndices = (tags: string[]): { name: string; index: string }[] => {
    return tags
      ? tags
          .filter((tag) => tag !== '')
          .map((tag) => ({
            name: tag,
            index: params.projectDetails ? params.projectDetails.labelsIndexed[tag] : -1,
          }))
          .sort((tagA, tagB) => tagA.index - tagB.index)
          .map((tag) => ({ ...tag, index: tag.index < 0 ? '' : tag.index.toString() }))
      : []
  }

  switch (params.columnType) {
    case COLUMN_TYPE.MULTI_LABEL_TAG: {
      return (
        <Flex align="center" height="100%">
          <MultiLabelTag
            label=""
            isInGiven={rowData._cleanlab_given_label_tags.includes(params.tagValue)}
            isInSuggested={false}
            column={params.columnType}
          />
        </Flex>
      )
    }
    case COLUMN_TYPE.GIVEN: {
      const sortedTags = generateSortedTagIndices(rowData._cleanlab_given_label_tags)

      return (
        <Tooltip
          placement="auto"
          hasArrow
          label={
            <>
              <Text>
                <strong>Given label: </strong>
                {sortedTags.map((tag) => tag.name).join(', ')}
              </Text>
              <br />
              <MultiLabelLegend column="Given" />
            </>
          }
        >
          <HStack spacing="4px">
            {sortedTags.map((tag) => (
              <MultiLabelTag
                key={tag.index}
                label={tag.index}
                isInSuggested={
                  rowData._cleanlab_suggested_label
                    ? rowData._cleanlab_suggested_label.includes(tag.name)
                    : []
                }
                column={params.columnType}
              />
            ))}
          </HStack>
        </Tooltip>
      )
    }
    case COLUMN_TYPE.SUGGESTED: {
      const sortedTags = generateSortedTagIndices(rowData._cleanlab_suggested_label)
      const hasSuggested =
        rowData._cleanlab_suggested_label && rowData._cleanlab_suggested_label.length > 0
      return (
        <Tooltip
          placement="auto"
          hasArrow
          label={
            <>
              <Text>
                <strong>Suggested label: </strong>
                {hasSuggested ? sortedTags.map((tag) => tag.name).join(', ') : '<none>'}
              </Text>
              <br />
              <Text>
                The Suggested label is <strong>NOT</strong> listed as a set of proposed changes to
                the Given label, rather it is listed as the entire proposed set of tags which we
                predict should be True in this example’s label.
              </Text>
              <br />
              <MultiLabelLegend column="Suggested" />
            </>
          }
        >
          <HStack spacing="4px">
            {hasSuggested ? (
              sortedTags.map((tag) => (
                <MultiLabelTag
                  key={tag.index}
                  label={tag.index}
                  isInGiven={rowData._cleanlab_given_label_tags.includes(tag.name)}
                  column={params.columnType}
                />
              ))
            ) : (
              <Text>-</Text>
            )}
          </HStack>
        </Tooltip>
      )
    }
    case COLUMN_TYPE.CORRECTED: {
      const sortedTags = generateSortedTagIndices(rowData._cleanlab_clean_label)

      const hasCorrectedLabel = sortedTags && sortedTags.length > 0

      return (
        <Tooltip
          placement="auto"
          hasArrow
          label={
            <>
              <Text>
                <strong>Corrected label: </strong>
                {hasCorrectedLabel ? sortedTags.map((tag) => tag.name).join(', ') : '<none>'}
              </Text>
            </>
          }
        >
          <HStack spacing="4px">
            {hasCorrectedLabel ? (
              sortedTags.map((tag) => (
                <MultiLabelTag key={tag.index} label={tag.index} column={params.columnType} />
              ))
            ) : (
              <Text>-</Text>
            )}
          </HStack>
        </Tooltip>
      )
    }
    default: {
      return <></>
    }
  }
}

export default MultiLabelTagCellRenderer
