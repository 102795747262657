import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import React from 'react'

import { CollapseCardButtonProps } from './CollapseCardButton.types'

const CollapseCardButton = (props: CollapseCardButtonProps) => {
  const { isCollapsed, handleCollapse } = props

  return (
    <IconButton
      aria-label="collapse card"
      variant="collapseCard"
      icon={isCollapsed ? <ArrowForwardIcon /> : <ArrowBackIcon />}
      onClick={handleCollapse}
      size="xs"
    />
  )
}

export default CollapseCardButton
