import './DeploymentAnalyticsGrid.css'

import { Box, Stack, Text, Tooltip } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import useGridClassname from '@hooks/useGridClassname'
import { GetContextMenuItemsParams, ICellRendererParams } from 'ag-grid-community'
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import React from 'react'

import { DeploymentAnalyticsGridProps } from './DeploymentAnalyticsGrid.types'

const DeploymentAnalyticsGrid = (props: DeploymentAnalyticsGridProps) => {
  const { data } = props

  const numRowsPerPage = 10

  function formatFloat(value: any): string {
    if (typeof value === 'number') {
      return value.toFixed(6)
    }
    return 'N/A'
  }

  const AccuracyCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <Text fontSize="sm">
        <Tooltip
          hasArrow
          label="Accuracy of predictions on held-out data from the current model fit to the current Cleanset, estimated via cross-validation."
        >
          {formatFloat(rowData['Held-out Accuracy'])}
        </Tooltip>
      </Text>
    )
  }

  const OriginalAccuracyCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <Text fontSize="sm">
        <Tooltip
          hasArrow
          label="Accuracy of predictions on held-out data from the original model (of the same type) previously fit to the original unmodified Dataset, estimated via cross-validation. You can compare this value vs. the accuracy of the current model to quantify the benefits of data correction."
        >
          {formatFloat(rowData['Original Accuracy'])}
        </Tooltip>
      </Text>
    )
  }

  const InferenceTimeCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    return (
      <Text fontSize="sm">
        <Tooltip
          hasArrow
          label="Number of seconds spent producing a prediction for every data point in your Dataset during cross-validation"
        >
          {formatFloat(rowData['Inference Time (During Cross-Validation)'])}
        </Tooltip>
      </Text>
    )
  }

  const ModelTypeCellRenderer = (params: ICellRendererParams) => {
    const rowData = params.node.data
    const modelType = rowData.Model
    const isEnsemble = modelType === 'Ensemble Predictor'
    return isEnsemble ? (
      <Text fontSize="sm">
        <Tooltip
          hasArrow
          placement="left"
          label="This type of model combines the predictions from the other models listed here."
        >
          {modelType}
        </Tooltip>
      </Text>
    ) : (
      <Text fontSize="sm">{modelType}</Text>
    )
  }

  const columnDefs: ColDef[] = [
    {
      field: 'Model',
      headerName: 'Model Type',
      cellRenderer: ModelTypeCellRenderer,
    },
    {
      field: 'Held-out Accuracy',
      headerName: 'Accuracy',
      cellRenderer: AccuracyCellRenderer,
    },
    {
      field: 'Original Accuracy',
      headerName: 'Original Accuracy',
      cellRenderer: OriginalAccuracyCellRenderer,
    },
    {
      field: 'Inference Time (During Cross-Validation)',
      headerName: 'Inference Time (Sec)',
      cellRenderer: InferenceTimeCellRenderer,
    },
    {
      field: 'Training Time (Sec)',
      headerName: 'Training Time (Sec)',
      valueFormatter: (params: ValueFormatterParams) =>
        formatFloat(params.data['Training Time (Sec)']),
    },
    {
      field: 'Used for Inference',
      headerName: 'Model',
      hide: true,
    },
  ]

  const gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    rowHeight: 60,
    columnDefs,
    rowSelection: 'multiple',
    rowClassRules: {
      'row-pass': (params) => params.api.getValue('Used for Inference', params.node),
    },
    onGridReady: (params) => {
      params.api.sizeColumnsToFit()
    },

    defaultColDef: {
      filter: false,
      sortable: false,
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    },
    suppressScrollOnNewData: true,
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  return (
    <Stack>
      <Box className={useGridClassname()} w="100%">
        <CleanlabGrid
          gridOptions={gridOptions}
          pagination
          paginationPageSize={numRowsPerPage}
          // Sort data such that the model with Used for Inference === true is first in the grid
          rowData={data.sort(
            (a, b) => Number(b['Used for Inference']) - Number(a['Used for Inference'])
          )}
        />
      </Box>
    </Stack>
  )
}

export default DeploymentAnalyticsGrid
