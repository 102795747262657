import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React from 'react'

import { PrettyPrintJson } from '../../datasetUpload/formatSuggestions/FormatSuggestions.helpers'
import { PredProbsInfoModalProps } from './PredProbsInfoModal.types'
import SimpleTable from './simpleTable/SimpleTable'

const classes: ('positive' | 'negative' | 'neutral')[] = ['positive', 'negative', 'neutral']

const predProbsJson: { [key: string]: { positive: number; negative: number; neutral: number } } = {
  ur735: {
    positive: 0.277,
    negative: 0.631,
    neutral: 0.092,
  },
  cc982: {
    positive: 0.335,
    negative: 0.359,
    neutral: 0.306,
  },
  mc117: {
    positive: 0.283,
    negative: 0.538,
    neutral: 0.179,
  },
}

const tableRows = Object.keys(predProbsJson).reduce(
  (acc, rowId) => {
    const entry: { positive: number; negative: number; neutral: number } = predProbsJson[rowId]
    const values: number[] = classes.map((c) => entry[c])
    const row = ([rowId] as (string | number)[]).concat(values)
    acc.push(row)
    return acc
  },
  [] as (string | number)[][]
)

const PredProbsInfoModal = (props: PredProbsInfoModalProps) => {
  const { isOpen, onClose } = props
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Formats for uploading predicted probabilities</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box width="400px">
            <Text fontSize="md" fontWeight={200}>
              CSV / Excel
            </Text>
            <SimpleTable size="sm" columns={['id'].concat(classes)} rows={tableRows} />
            <br />
            <Text fontSize="md" fontWeight={200} mb={3}>
              JSON
            </Text>
            <PrettyPrintJson data={predProbsJson} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PredProbsInfoModal
