'use client'

import { ForwardedRef, forwardRef, memo } from 'react'

import { IconButtonProps, IconFrameRoot } from './IconFrameRoot'

const IconButtonBase = (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  return <IconFrameRoot ref={ref} {...props} clickable={true} />
}

/**
 * IconFrameButton component
 */
export const IconFrameButton = memo(forwardRef(IconButtonBase))
IconFrameButton.displayName = 'IconFrameButton'
