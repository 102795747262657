import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { usePostHog } from 'posthog-js/react'
import { useContext } from 'react'
import { AuthContext } from 'react-oauth2-code-pkce'
import { useNavigate } from 'react-router-dom'

export interface OAuthContextInterface {
  error?: Error
  getAccessTokenSilently: (options?: any) => Promise<string>
  isAuthenticated: boolean
  isLoading: boolean
  loginWithRedirect: (options?: any) => Promise<void>
  logout: (options?: any) => void
  user?: {
    email?: string
    name?: string
    picture?: string
    sub?: string
  }
}

export const useOAuth = (): OAuthContextInterface => {
  const { token, tokenData, login, logOut, error, loginInProgress } = useContext(AuthContext)
  const navigate = useNavigate()
  const posthog = usePostHog()

  const user = tokenData
    ? { sub: tokenData.sub, name: tokenData.name, email: tokenData.email }
    : undefined

  const isAuthenticated = typeof token === 'string' && token.length > 0

  const loginWithRedirect = (options?: any): Promise<void> => {
    // Here, we only understand some of the options that are used for Auth0.
    // Auth0 uses `options.appState.returnTo` to specify where to go after
    // login, and we use that here. The other option we use with Auth0 is the
    // screen hint, to jump straight to the signup page, but that is only
    // applicable in the SaaS context.

    const returnTo = options?.appState?.returnTo ?? null

    // we save our actual returnTo location in session storage, and send a
    // "key" as the state
    const state = new Array(16)
      .fill(0)
      .map(() => {
        // note, we don't need cryptographic randomness here, the PKCE flow is
        // secure without a nonce in the state
        //
        // see https://danielfett.de/2020/05/16/pkce-vs-nonce-equivalent-or-not/
        const choices = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        // rejection sampling is ok:
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const byteArray = new Uint8Array(1)
          const n = window.crypto.getRandomValues(byteArray)[0]
          if (n < choices.length) {
            return choices[n]
          }
        }
      })
      .join('')

    const key = `@@rocp@@_returnTo_${state}`

    sessionStorage.setItem(key, JSON.stringify(returnTo))

    // pass state; we'll later recieve the state back and use it to find our
    // returnTo in sessionStorage
    login(state)

    // we return a promise that never resolves: this is intentional; login
    // will navigate to a different page
    return new Promise(() => {})
  }

  return {
    error: error !== null ? new Error(error) : undefined,
    getAccessTokenSilently: (): Promise<string> => {
      // XXX we don't handle the case of loginInProgress here; ideally, we'd
      // just wait until login is complete, but react-oauth2-code-pkce doesn't
      // give us a nice way to do that; we never actually rely on this
      // behavior, we show a loading screen until isAuthenticated, so it should
      // be okay to not handle this case

      if (!isAuthenticated) {
        loginWithRedirect()
        return new Promise(() => {}) // never resolves
      }

      return new Promise((resolve) => {
        resolve(token)
      })
    },
    isAuthenticated: isAuthenticated,
    isLoading: loginInProgress,
    loginWithRedirect: loginWithRedirect,
    logout: (): void => {
      if (!IS_VPC_ENVIRONMENT && posthog) {
        posthog.reset()
      }
      logOut()
      // XXX this just clears local state/tokens, but doesn't call the OAuth
      // logout endpoint
      navigate('/')
    },
    user: user,
  }
}
