export interface ActivateFormState {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  signupAccountType: string
  signupDiscoverySource: string
  termsAndConditions: boolean
}

export enum SignupAccountType {
  Business = 'business',
  Academic = 'academic',
  Individual = 'individual',
}
