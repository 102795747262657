import { TagProps } from '@chakra-ui/react'

export interface CleanlabTagProps extends TagProps {
  cleanlabTags: string[] | null
}

export const TagAbbreviation: Record<string, string> = {
  label_issue: 'L',
  outlier: 'O',
  unlabeled: 'U',
  ambiguous: 'A',
  near_duplicate: 'D',
}
