import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconEdit } from '@components/icons'
import { Input } from '@components/input/Input'
import { useRef, useState } from 'react'

interface Props {
  title: string
  onTitleChange: (newTitle: string) => void
  isLoading: boolean
}

const EditableTitle = (props: Props) => {
  const { title, onTitleChange, isLoading } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const [isEditing, setIsEditing] = useState(false)
  const [newTitle, setNewTitle] = useState(title)

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation()
    if (e.key === 'Enter' && !isLoading) {
      setIsEditing(false)
      onTitleChange(newTitle)
    } else if (e.key === 'Escape') {
      setIsEditing(false)
    }
  }

  return isEditing || isLoading ? (
    <Input
      value={newTitle}
      onChange={handleTitleChange}
      onKeyDown={handleKeyDown}
      onBlur={() => setIsEditing(false)}
      onFocus={(e) => e.currentTarget.select()}
      ref={inputRef}
      variant="title"
      disabled={isLoading}
    />
  ) : (
    <div className="flex flex-row gap-1">
      <p className="type-display-50 self-center text-text-strong">{title}</p>
      <IconFrameButton
        variant="iconOnly"
        onClick={() => {
          setIsEditing(true)
          setTimeout(() => inputRef.current?.focus(), 200)
        }}
        size="small"
        icon={<IconEdit />}
        aria-label={`Edit ${title} title`}
      />
    </div>
  )
}

export default EditableTitle
