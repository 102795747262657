export const queryKeys = {
  notifications: {
    all: () => ['notifications'] as const,
  },
}

export interface UserNotificationData {
  hasSeen: boolean
  message: string
}

export interface GetUserNotificationRes {
  has_seen: boolean
  message: string | null
}
