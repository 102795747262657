// XXX we shouldn't be peeking into internals of auth; also, this only works on
// SaaS. Once we fix this, we should no longer export AUDIENCE and CLIENT_ID
// from Auth0ProviderWithHistory.
import {
  AUDIENCE,
  CLIENT_ID,
} from '@providers/authentication/auth0ProviderWithHistory/Auth0ProviderWithHistory'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_HEADLESS_BROWSER_API_URL } from '@utils/environmentVariables'
import logger from '@utils/logger'
import axios from 'axios'

const headlessBrowserApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_HEADLESS_BROWSER_API_URL}/headless_browser_api`,
  withCredentials: true,
})

const getAuthInfoForRequest = () => {
  // Get the Auth0 cached tokens that we store in localStorage
  // XXX this does not work with OAuth auth / VPC environment
  const userKey = `@@auth0spajs@@::${CLIENT_ID}::@@user@@`
  const openIdKey = `@@auth0spajs@@::${CLIENT_ID}::${AUDIENCE}::openid profile email offline_access`
  const authInfo = JSON.stringify({
    [userKey]: localStorage.getItem(userKey),
    [openIdKey]: localStorage.getItem(openIdKey),
  })
  return authInfo
}

const generatePdf = async (cleansetId: string) => {
  const accessToken = await auth.getTokenSilently()
  const headers = createAuthHeaders(accessToken).headers
  headers.accept = 'application/pdf'

  const authInfo = getAuthInfoForRequest()
  const body = {
    cleansetId: cleansetId,
    authInfo: authInfo,
  }

  const response = await headlessBrowserApi.post('generatePdf', body, {
    headers,
    responseType: 'blob',
  })
  logger.log(response)
  return response.data
}

const apiMethods = {
  generatePdf,
}

export default apiMethods
