import { Label } from '@components/label/Label'
import { cn } from '@utils/tailwindUtils'
import { ForwardedRef, forwardRef, memo, ReactNode } from 'react'

import { FormFieldContextValue, FormFieldProvider, useFormField } from './FormFieldContext'

type RenderProp = (props: ReturnType<typeof useFormField>) => ReactNode

type FormFieldRootProps = FormFieldContextValue & {
  className?: string
  children: ReactNode
}

export type FormFieldProps = Omit<FormFieldRootProps, 'children'> & {
  label?: ReactNode
  description?: ReactNode
  info?: ReactNode
} & ({ children: ReactNode } | { render: RenderProp })

export const FormFieldRoot = forwardRef(
  (
    { className, htmlFor, required, error, disabled, ...props }: FormFieldRootProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <FormFieldProvider htmlFor={htmlFor} required={required} error={error} disabled={disabled}>
        <div ref={ref} className={cn('flex flex-col gap-y-3', className)} {...props} />
      </FormFieldProvider>
    )
  }
)
FormFieldRoot.displayName = 'FormFieldRoot'

const FormFieldRender = ({ children }: { children: RenderProp }) => {
  const formField = useFormField()
  return typeof children === 'function' ? <>{children(formField)}</> : <>{children}</>
}

export const FormFieldBase = (
  {
    // @ts-expect-error
    render,
    // @ts-expect-error
    children,
    label,
    description,
    info,
    ...props
  }: FormFieldProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const hasErrorContent = props.error && typeof props.error !== 'boolean'
  return (
    <FormFieldRoot ref={ref} {...props}>
      {(label || description) && <Label description={description}>{label}</Label>}
      {render ? <FormFieldRender>{render}</FormFieldRender> : children}
      {(hasErrorContent || info) && (
        <div className={cn('type-caption text-text-faint', props.error && 'text-red-500')}>
          {hasErrorContent ? props.error : info}
        </div>
      )}
    </FormFieldRoot>
  )
}

export const FormField = memo(forwardRef(FormFieldBase))
FormField.displayName = 'FormField'
