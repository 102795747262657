import './RerunCleanlabStatus.css'

import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Box, Center, Text, useColorModeValue } from '@chakra-ui/react'
import BorderedIcon from '@components/borderedIcon/BorderedIcon'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'
import React from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { FiLoader } from 'react-icons/fi'

import { RerunCleanlabStatusProps } from './RerunCleanlabStatus.types'

const RerunCleanlabStatus = ({
  onGoToDashboardClicked,
  onViewProjectClicked,
  isRerunComplete,
}: RerunCleanlabStatusProps) => {
  const checkFillColor = useColorModeValue(
    cleanlabColors.green[600],
    cleanlabColors.greenDarkMode[600]
  )
  const borderColor = useColorModeValue('neutral.200', 'neutralDarkMode.200')
  const iconBgColor = useColorModeValue('neutral.50', 'neutralDarkMode.50')
  const loadingIconColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const titleColor = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const textColor = useColorModeValue('neutral.700', 'neutralDarkMode.700')

  return (
    <Center
      flexDir="column"
      w="440px"
      p="24px"
      border="1px solid"
      borderColor={borderColor}
      borderRadius="16px"
    >
      <BorderedIcon
        icon={
          isRerunComplete ? (
            <BsFillCheckCircleFill color={checkFillColor} />
          ) : (
            <Box className="spinner">
              <FiLoader color={loadingIconColor} />
            </Box>
          )
        }
        iconBackgroundColor={iconBgColor}
        borderColor={borderColor}
      />
      <Text pt="12px" pb="4px" fontWeight="600" fontSize="lg" lineHeight="28px" color={titleColor}>
        {isRerunComplete ? 'Congratulations' : 'Improving Dataset'}
      </Text>
      <Text pb="16px" textAlign="center" fontSize="sm" lineHeight="20px" color={textColor}>
        {isRerunComplete
          ? 'Your Project is ready to view. From the Project view, you’ll be able to export your improved Dataset, further improve it by correcting data and label issues, or deploy a model to process new data!'
          : 'Cleanlab Studio is now analyzing your data, taking your new labels into account.  This may take a while - we’ll send you an email when your Project is ready. Once processing is complete, you can continue to label the rest of your Dataset, or find and fix labels that were chosen incorrectly. In the Project, the Given Label column will contain the labels you selected here.'}
      </Text>
      {isRerunComplete ? (
        <PrimaryButton fontSize="sm" height="36px" onClick={onViewProjectClicked}>
          View Project
        </PrimaryButton>
      ) : (
        <SecondaryButton fontSize="sm" height="36px" onClick={onGoToDashboardClicked}>
          Go to dashboard
        </SecondaryButton>
      )}
    </Center>
  )
}

export default RerunCleanlabStatus
