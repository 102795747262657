import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

import { LabelItemProps, LabelStackProps } from './CorrectionsChart.types'

const LabelItem = (props: LabelItemProps) => {
  const { value, barPaddingMultiplier, fontSize, color } = props
  return (
    <>
      <Flex alignItems="center" justify="end" flex={1 - barPaddingMultiplier} width="100%">
        <Text
          fontSize={fontSize}
          color={color}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign="right"
        >
          {value}
        </Text>
      </Flex>
      <Box flex={barPaddingMultiplier} />
    </>
  )
}

const LabelStack = (props: LabelStackProps) => {
  const { header, items, fontSize, titleColor, headingFontWeight } = props
  return (
    <VStack height="100%" alignItems="flex-end" minWidth="10%" marginRight={4} maxWidth="25%">
      <Box height={18} flex={0}>
        <Text fontSize={fontSize} color={titleColor} fontWeight={headingFontWeight}>
          {header}
        </Text>
      </Box>
      <Flex
        justifyContent="space-evenly"
        flexDirection="column"
        flex={1}
        width="100%"
        paddingBottom="30px"
        paddingTop="2px"
        // have to override top margin to get exact alignment with chart bars
        marginTop="0px !important"
      >
        {items}
      </Flex>
    </VStack>
  )
}

export { LabelItem, LabelStack }
