import { chakra, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

const CodeBlock = ({ text }: { text: string }) => {
  return (
    <chakra.span bgColor={useColorModeValue('gray.200', 'gray.600')} p={1}>
      {text}
    </chakra.span>
  )
}

export default CodeBlock
