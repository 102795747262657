import { Box, Center, Text, VStack } from '@chakra-ui/react'
import CopySnippet from '@common/misc/copySnippet/CopySnippetV1'
import Link from '@components/link/Link'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useApiKey } from '@services/commandLine/queries'
import { UploadType } from 'src/pages/datasetUpload/DatasetUpload.types'

import { ModelApiProps } from './ModelApi.types'

const ModelApi = (props: ModelApiProps) => {
  const { modelId } = props
  const { trackEvent } = useEventTracking()
  const { apiKey } = useApiKey()

  return (
    <Center paddingTop="5%">
      <VStack align="flex-start" spacing={6} fontSize="md">
        <Text>
          We offer a{' '}
          <Link
            isExternal
            href="https://help.cleanlab.ai/guide/quickstart/api"
            onClick={() => trackEvent(MixpanelEvents.clickPythonApiLink)}
          >
            Python API
          </Link>{' '}
          for programmatic integration.
        </Text>
        <Text>
          Check out the{' '}
          <Link
            isExternal
            href="https://help.cleanlab.ai/reference/python/inference/"
            onClick={() => trackEvent(MixpanelEvents.clickPythonInferenceLink)}
          >
            Inference Section
          </Link>{' '}
          for detailed documentation on how to upload to your model and download inference results
        </Text>
        <Text>
          For an end to end walkthrough, see our guide on{' '}
          <Link
            isExternal
            href="https://help.cleanlab.ai/tutorials/inference_api/"
            onClick={() => trackEvent(MixpanelEvents.clickInferenceApiTutorialLink)}
          >
            Deploying Reliable Models in Production
          </Link>
        </Text>

        <CopySnippet
          label="Model ID"
          snippet={modelId}
          canHide={false}
          trackCopyEvent={() =>
            trackEvent(MixpanelEvents.clickApiKeyCopy, { uploadType: UploadType.pythonApi })
          }
        />
        <CopySnippet
          label="API key"
          snippet={apiKey}
          canHide={true}
          trackCopyEvent={() => trackEvent(MixpanelEvents.clickApiKeyCopy)}
        />
        <Box>
          Your personal API key is shown above (Do not share this!). You may also generate a new API
          key in your <Link href="/account?tab=General">Account page</Link>.
        </Box>
      </VStack>
    </Center>
  )
}

export default ModelApi
