enum WarningType {
  NULL_VALUE = 'null_value',
  INVALID_MEDIA_URL = 'invalid_media_url',
  TYPE_MISMATCH = 'type_mismatch',
  MISSING_MEDIA_FILE = 'missing_media_file',
}

export const getWarningTooltipLabel = (
  warningType: string,
  originalValue: string,
  columnType: string
): string => {
  switch (warningType) {
    case WarningType.NULL_VALUE:
      return 'Data warning: Null value found'
    case WarningType.INVALID_MEDIA_URL:
      return `Data warning: Invalid media URL found - ${originalValue}`
    case WarningType.TYPE_MISMATCH:
      return `Data warning: Could not cast ${originalValue} to type ${columnType}`
    case WarningType.MISSING_MEDIA_FILE:
      return `Data warning: Missing media file ${originalValue} in zipfile`
    default:
      return `Data warning found for value: ${originalValue}`
  }
}
