import {
  Container,
  CreateToastFnReturn,
  Flex,
  HStack,
  Spinner,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import Layout from '@common/layout/Layout'
import DeleteRowModal from '@common/modals/deleteRowModal/DeleteRowModal'
import PrimaryButtonWithIcon from '@components/buttons/primaryButtonWithIcon/PrimaryButtonWithIcon'
import SecondaryButtonWithIcon from '@components/buttons/secondaryButtonWithIcon/SecondaryButtonWithIcon'
import PageTitle from '@components/pageTitle/PageTitle'
import { renderChakraToastAlert } from '@components/toast/ToastAlert'
import { useEventTracking } from '@hooks/useEventTracking'
import useIsTabActive from '@hooks/useIsTabActive'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import NonExistentResource from '@providers/errors/NonExistentResource'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useSubscriptionDetails } from '@services/billing/queries'
import { useCleansetDetails, useCleansetProjectDetails } from '@services/cleanset/queries'
import deploymentApiService, {
  DeploymentRowProps,
  ModelSummaryRowProps,
} from '@services/deploymentApi'
import { useMyself } from '@services/user/queries'
import { queryKeys } from '@services/userQuota/constants'
import { useUserQuota } from '@services/userQuota/queries'
import { AxiosError } from 'axios'
import { FilePondFile } from 'filepond'
import { useCallback, useEffect, useState } from 'react'
import { FiTrash } from 'react-icons/fi'
import { MdLightbulb } from 'react-icons/md'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import DeploymentNav from './deploymentNav/DeploymentNav'
import InferenceResults from './inferenceResults/InferenceResults'
import ModelApi from './modelApi/ModelApi'
import ModelDetails from './modelDetails/ModelDetails'
import PredictNewLabelsModal from './predictNewLabelsModal/PredictNewLabelsModal'

const setInvalidIdOrRaiseToast = (
  toast: CreateToastFnReturn,
  err: AxiosError,
  setInvalidId: (invalid: boolean) => void
) => {
  const statusCode = err.response?.status
  if (statusCode && statusCode >= 400 && statusCode < 500) {
    setInvalidId(true)
  } else {
    notifyAxiosError(toast, err)
  }
}

const Deployment = () => {
  const { deploymentId } = useParams<{ deploymentId: string }>()
  const [invalidId, setInvalidId] = useState(false)

  // TODO (Caleb) deprecate cleansetId state after deployment refactor
  const [cleansetId, setCleansetId] = useState<string>('')
  const cleansetDetails = useCleansetDetails({ cleansetId })
  const [deploymentDetails, setDeploymentDetails] = useState<DeploymentRowProps | null>(null)
  const [modelSummaryDetails, setModelSummaryDetails] = useState<ModelSummaryRowProps[] | null>(
    null
  )
  const [predictionLoading, setPredictionLoading] = useState<boolean>(false)
  const [queryHistory, setQueryHistory] = useState([])
  const [files, setFiles] = useState<FilePondFile[]>([])
  const [tabIndex, setTabIndex] = useState(0)
  const [inferenceModel, setInferenceModel] = useState('')
  const [inferenceAccuracy, setInferenceAccuracy] = useState(0)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

  const { trackEvent } = useEventTracking()

  const toast = useToast()

  const navigate = useNavigate()

  const isTabActive = useIsTabActive()

  const queryClient = useQueryClient()

  const { activated: isActivated, emailConfirmed } = useMyself()

  const projectDetails = useCleansetProjectDetails({
    cleansetId,
    onError: (err) => setInvalidIdOrRaiseToast(toast, err, setInvalidId),
  })

  const subscriptionDetails = useSubscriptionDetails({
    enabled: isActivated && emailConfirmed,
  })
  const usageDetails = useUserQuota({ enabled: isActivated && emailConfirmed })
  const { featuresDisabled, onSubscriptionModalOpen } = useSubscription()

  const fetchQueryHistory = useCallback(async () => {
    try {
      if (isTabActive) {
        const queryHistory = await deploymentApiService.queryHistoryForDeployment(
          deploymentId ?? ''
        )
        setQueryHistory(queryHistory)
      }
    } catch (err) {
      setInvalidIdOrRaiseToast(toast, err as AxiosError, setInvalidId)
    }
  }, [isTabActive, deploymentId, toast])

  useEffect(() => {
    const fetchDeploymentDetails = async (deploymentId: string) => {
      try {
        const data = await deploymentApiService.getDeployment(deploymentId)
        setDeploymentDetails(data)
        fetchQueryHistory()
        setCleansetId(data.cleanset_id)
        if (data.tasktype === Tasktype.MULTICLASS) {
          fetchModelSummary(deploymentId, data.cleanset_id)
        }
      } catch (err) {
        setInvalidIdOrRaiseToast(toast, err as AxiosError, setInvalidId)
      }
    }

    const fetchModelSummary = async (deploymentId: string, cleansetId: string) => {
      try {
        const modelSummaryDetails = await deploymentApiService.getModelSummary(
          deploymentId,
          cleansetId
        )
        setModelSummaryDetails(modelSummaryDetails)

        const model = modelSummaryDetails.find((e) => e['Used for Inference'])
        setInferenceModel(model ? model.Model : '')
        setInferenceAccuracy(model ? model['Held-out Accuracy'] : 0)
      } catch (err) {
        setInvalidIdOrRaiseToast(toast, err as AxiosError, setInvalidId)
      }
    }

    fetchDeploymentDetails(deploymentId ?? '')
  }, [toast, deploymentId, fetchQueryHistory])

  if (invalidId) {
    return (
      <Layout>
        <NonExistentResource errorMessage="No such model exists." />
      </Layout>
    )
  }

  if (!projectDetails || !cleansetDetails || !deploymentDetails) {
    return (
      <Layout>
        <Flex width="100%" height="100%" align="center" justify="center">
          <Spinner />
        </Flex>
      </Layout>
    )
  }

  const handlePredictNewLabels = async () => {
    trackEvent(MixpanelEvents.clickModalPredictNewLabels, {
      ...cleansetDetails,
      ...projectDetails,
      ...deploymentDetails,
      ...subscriptionDetails,
      ...usageDetails,
    })
    setPredictionLoading(true)
    try {
      const formData = new FormData()
      formData.append('file', files[0].file)
      formData.append('query_type', 'BATCH')
      formData.append('data_source', 'FILE_IN_REQUEST_SOURCE')
      await deploymentApiService.createQuery(deploymentId ?? '', files[0])
      await fetchQueryHistory()
      void queryClient.invalidateQueries({ queryKey: queryKeys.quotas.all })
      onClose()
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError, { title: 'Label prediction failed.' })
    } finally {
      setPredictionLoading(false)
    }
  }

  const deleteModel = async () => {
    trackEvent(MixpanelEvents.clickModalDeleteModelButton, {
      ...cleansetDetails,
      ...projectDetails,
      ...deploymentDetails,
    })
    try {
      await deploymentApiService.deleteDeployment(deploymentDetails.id)
      navigate('/')
      toast({
        ...defaultToastAlertProps,
        render: renderChakraToastAlert({
          heading: 'Your model has been successfully deleted',
          status: 'success',
        }),
      })
    } catch (err) {
      setInvalidIdOrRaiseToast(toast, err as AxiosError, setInvalidId)
    }
  }

  const handleClose = () => {
    setFiles([])
    onClose()
  }

  const apiTabIndex = projectDetails.tasktype === Tasktype.MULTICLASS ? 2 : 1

  return (
    <Tabs
      variant="enclosed"
      index={tabIndex}
      onChange={(index) => {
        setTabIndex(index)
        if (index === 0 || (index === 1 && projectDetails.tasktype === Tasktype.MULTICLASS)) {
          trackEvent(
            index === 0
              ? MixpanelEvents.clickInferenceResultsTab
              : MixpanelEvents.clickModelDetailsTab,
            {
              ...cleansetDetails,
              ...projectDetails,
              ...deploymentDetails,
            }
          )
        }
      }}
    >
      <Layout nav={<DeploymentNav tabIndex={tabIndex} tasktype={deploymentDetails.tasktype} />}>
        <Container maxW="100%" h="86vh">
          <DeleteRowModal
            isOpen={isDeleteOpen}
            onClose={onDeleteClose}
            rowData={{ name: deploymentDetails.name }}
            deletionType="deployment"
            handleDeleteButtonClicked={deleteModel}
          />
          <PredictNewLabelsModal
            isOpen={isOpen}
            onClose={handleClose}
            handlePredictNewLabels={handlePredictNewLabels}
            files={files}
            setFiles={setFiles}
            isLoading={predictionLoading}
          />
          {tabIndex !== apiTabIndex && (
            <HStack justify="space-between">
              <PageTitle>{`Model: ${deploymentDetails.name}`}</PageTitle>
              <HStack justify="flex-end">
                <SecondaryButtonWithIcon
                  height="40px"
                  leftIcon={<FiTrash />}
                  onClick={() => {
                    trackEvent(MixpanelEvents.clickDeleteModelButton, {
                      ...cleansetDetails,
                      ...projectDetails,
                      ...deploymentDetails,
                    })
                    onDeleteOpen()
                  }}
                >
                  Delete Model
                </SecondaryButtonWithIcon>
                <PrimaryButtonWithIcon
                  height="40px"
                  fontSize="sm"
                  leftIcon={<MdLightbulb size="16px" />}
                  onClick={() => {
                    trackEvent(MixpanelEvents.clickPredictNewLabels, {
                      ...cleansetDetails,
                      ...projectDetails,
                      ...deploymentDetails,
                    })
                    if (featuresDisabled) {
                      onSubscriptionModalOpen()
                    } else {
                      onOpen()
                    }
                  }}
                >
                  Predict New Labels
                </PrimaryButtonWithIcon>
              </HStack>
            </HStack>
          )}
          <TabPanels h="100%">
            <TabPanel h="100%" p={0}>
              <InferenceResults
                deploymentId={deploymentDetails.id}
                queryHistory={queryHistory}
                fetchQueryHistory={fetchQueryHistory}
                cleansetDetails={cleansetDetails}
                inferenceModel={inferenceModel}
                inferenceAccuracy={inferenceAccuracy}
                tasktype={deploymentDetails.tasktype}
              />
            </TabPanel>
            {modelSummaryDetails && (
              <TabPanel>
                <ModelDetails
                  deploymentDetails={deploymentDetails}
                  projectDetails={projectDetails}
                  data={modelSummaryDetails}
                />
              </TabPanel>
            )}
            <TabPanel>
              <ModelApi modelId={deploymentDetails.id} />
            </TabPanel>
          </TabPanels>
        </Container>
      </Layout>
    </Tabs>
  )
}

export default Deployment
