const slackCommunityInviteLink =
  'https://communityinviter.com/apps/cleanlab-community/cleanlab-community'

const teamEmail = 'support@cleanlab.ai'

const salesEmail = 'sales@cleanlab.ai'

const supportEmail = 'support@cleanlab.ai'

const teamMailToLink = `mailto:${teamEmail}`

const teamMailToLinkWithSubject = (subject: string) =>
  `mailto:${teamEmail}?subject=${encodeURIComponent(subject)}`

const salesMailToLinkWithSubject = (subject: string) =>
  `mailto:${salesEmail}?subject=${encodeURIComponent(subject)}`

const supportMailToLinkWithSubject = (subject: string) =>
  `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}`

export {
  salesEmail,
  salesMailToLinkWithSubject,
  slackCommunityInviteLink,
  supportEmail,
  supportMailToLinkWithSubject,
  teamEmail,
  teamMailToLink,
  teamMailToLinkWithSubject,
}
