import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Center, Progress, Text, Tooltip, useColorModeValue, VStack } from '@chakra-ui/react'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { useMonthlyTlmTokenUsage } from '@services/billing/queries'
import { useMyself } from '@services/user/queries'
import { useUserQuota } from '@services/userQuota/queries'

import { getProgressColorScheme } from '../PlanDetails.helpers'
import { PlanDetailsUsageProps } from './PlanDetailsUsage.types'

const PlanDetailsUsage = (props: PlanDetailsUsageProps) => {
  const { offset } = props

  const {
    payingCustomer,
    freeTrialPeriod,
    isEnterprisePlan,
    cleansetExportRowsMonthlyLimit,
    inferenceExportRowsMonthlyLimit,
    freeCleansetExportRowsMonthlyLimit,
    freeProjectsMonthlyLimit,
    freeInferenceExportRowsMonthlyLimit,
    freeModelsMonthlyLimit,
    freeTrialInferenceExportRowsLimit,
    freeTrialProjectsLimit,
    freeTrialModelsLimit,
    isTlmPlan,
  } = useSubscription()

  const { activated, accountActivated } = useMyself()

  const borderColor = useColorModeValue('gray.200', 'gray.500')
  const usageDetails = useUserQuota({ enabled: activated && accountActivated })
  const { totalTokens: totalTlmTokens } = useMonthlyTlmTokenUsage()

  return (
    <Box h="100%" w="100%">
      <Text pb={2}>Usage</Text>
      <Box
        p={6}
        fontSize="sm"
        border="1px solid"
        borderRadius="md"
        borderColor={borderColor}
        w="100%"
      >
        <Text pb={3}>This billing period</Text>
        {isTlmPlan && !freeTrialPeriod ? (
          <VStack w="100%" spacing={10} alignItems="flex-start">
            <div className="flex w-full justify-between">
              <p>TLM usage</p>
              <p>{totalTlmTokens ?? 0} tokens</p>
            </div>
          </VStack>
        ) : (
          <VStack w="100%" spacing={10} alignItems="flex-start">
            {payingCustomer && (
              <Box w="100%">
                <Center position="relative" w="100%" pb={2}>
                  <Text position="absolute" left={0}>
                    0
                  </Text>
                  <Text>
                    Cleanset Rows Exported - {usageDetails?.numFreeCleansetExportRowsUsed} /{' '}
                    {cleansetExportRowsMonthlyLimit} (
                    {Math.round(
                      ((usageDetails?.numFreeCleansetExportRowsUsed ?? 0) /
                        cleansetExportRowsMonthlyLimit) *
                        100 *
                        10
                    ) / 10}
                    %)
                  </Text>
                  <Text position="absolute" right={0}>
                    {cleansetExportRowsMonthlyLimit}
                  </Text>
                  {!isEnterprisePlan && (
                    <Tooltip
                      hasArrow
                      label={`${freeCleansetExportRowsMonthlyLimit} free rows included in plan`}
                    >
                      <ChevronDownIcon
                        position="absolute"
                        boxSize="24px"
                        left={`${
                          (freeCleansetExportRowsMonthlyLimit / cleansetExportRowsMonthlyLimit) *
                            100 -
                          offset
                        }%`}
                        top="12px"
                      />
                    </Tooltip>
                  )}
                </Center>
                <Progress
                  size="sm"
                  colorScheme={getProgressColorScheme(
                    cleansetExportRowsMonthlyLimit,
                    usageDetails?.numFreeCleansetExportRowsUsed ?? 0,
                    (usageDetails?.numFreeCleansetExportRowsUsed ?? 0) >=
                      freeCleansetExportRowsMonthlyLimit
                  )}
                  h="12px"
                  w="100%"
                  value={usageDetails?.numFreeCleansetExportRowsUsed}
                  max={cleansetExportRowsMonthlyLimit}
                />
              </Box>
            )}
            <Box w="100%">
              <Center position="relative" w="100%" pb={2}>
                <Text position="absolute" left={0}>
                  0
                </Text>
                <Text>
                  Inference Rows - {usageDetails?.numFreeInferenceExportRowsUsed} /{' '}
                  {freeTrialPeriod
                    ? freeTrialInferenceExportRowsLimit
                    : inferenceExportRowsMonthlyLimit}{' '}
                  (
                  {Math.round(
                    ((usageDetails?.numFreeInferenceExportRowsUsed ?? 0) /
                      (freeTrialPeriod
                        ? freeTrialInferenceExportRowsLimit
                        : inferenceExportRowsMonthlyLimit)) *
                      100 *
                      10
                  ) / 10}
                  %)
                </Text>
                <Text position="absolute" right={0}>
                  {freeTrialPeriod
                    ? freeTrialInferenceExportRowsLimit
                    : inferenceExportRowsMonthlyLimit}
                </Text>
                {payingCustomer && !isEnterprisePlan && (
                  <Tooltip
                    hasArrow
                    label={`${freeInferenceExportRowsMonthlyLimit} free rows included in plan`}
                  >
                    <ChevronDownIcon
                      boxSize="24px"
                      position="absolute"
                      left={`${
                        (freeInferenceExportRowsMonthlyLimit / inferenceExportRowsMonthlyLimit) *
                          100 -
                        offset
                      }%`}
                      top="12px"
                    />
                  </Tooltip>
                )}
              </Center>
              <Progress
                size="sm"
                colorScheme={getProgressColorScheme(
                  freeTrialPeriod
                    ? freeTrialInferenceExportRowsLimit
                    : inferenceExportRowsMonthlyLimit,
                  usageDetails?.numFreeInferenceExportRowsUsed ?? 0,
                  (usageDetails?.numFreeInferenceExportRowsUsed ?? 0) >=
                    freeInferenceExportRowsMonthlyLimit
                )}
                h="12px"
                w="100%"
                value={usageDetails?.numFreeInferenceExportRowsUsed}
                max={
                  freeTrialPeriod
                    ? freeTrialInferenceExportRowsLimit
                    : inferenceExportRowsMonthlyLimit
                }
              />
            </Box>
            <Box w="100%">
              <Center position="relative" w="100%" pb={2}>
                <Text position="absolute" left={0}>
                  0
                </Text>
                <Text>
                  Projects Created - {usageDetails?.numFreeProjectsUsed} /{' '}
                  {freeTrialPeriod ? freeTrialProjectsLimit : freeProjectsMonthlyLimit} (
                  {Math.round(
                    ((usageDetails?.numFreeProjectsUsed ?? 0) /
                      (freeTrialPeriod ? freeTrialProjectsLimit : freeProjectsMonthlyLimit)) *
                      100 *
                      10
                  ) / 10}
                  %)
                </Text>
                <Text position="absolute" right={0}>
                  {freeTrialPeriod ? freeTrialProjectsLimit : freeProjectsMonthlyLimit}
                </Text>
              </Center>
              <Progress
                size="sm"
                colorScheme={getProgressColorScheme(
                  freeTrialPeriod ? freeTrialProjectsLimit : freeProjectsMonthlyLimit,
                  usageDetails?.numFreeProjectsUsed ?? 0,
                  (usageDetails?.numFreeProjectsUsed ?? 0) >= freeProjectsMonthlyLimit
                )}
                h="12px"
                w="100%"
                value={usageDetails?.numFreeProjectsUsed}
                max={freeTrialPeriod ? freeTrialProjectsLimit : freeProjectsMonthlyLimit}
              />
            </Box>
            <Box w="100%">
              <Center position="relative" w="100%" pb={2}>
                <Text position="absolute" left={0}>
                  0
                </Text>
                <Text>
                  Model Deployments - {usageDetails?.numFreeModelsUsed} /{' '}
                  {freeTrialPeriod ? freeTrialModelsLimit : freeModelsMonthlyLimit} (
                  {Math.round(
                    ((usageDetails?.numFreeModelsUsed ?? 0) /
                      (freeTrialPeriod ? freeTrialModelsLimit : freeModelsMonthlyLimit)) *
                      100 *
                      10
                  ) / 10}
                  %)
                </Text>
                <Text position="absolute" right={0}>
                  {freeTrialPeriod ? freeTrialModelsLimit : freeModelsMonthlyLimit}
                </Text>
              </Center>
              <Progress
                size="sm"
                colorScheme={getProgressColorScheme(
                  freeTrialPeriod ? freeTrialModelsLimit : freeModelsMonthlyLimit,
                  usageDetails?.numFreeModelsUsed ?? 0,
                  (usageDetails?.numFreeModelsUsed ?? 0) >= freeModelsMonthlyLimit
                )}
                h="12px"
                w="100%"
                value={usageDetails?.numFreeModelsUsed}
                max={freeTrialPeriod ? freeTrialModelsLimit : freeModelsMonthlyLimit}
              />
            </Box>
          </VStack>
        )}
      </Box>
    </Box>
  )
}

export default PlanDetailsUsage
