import { Container, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import QueryInterfaceFindCleanset from './QueryInterfaceFindCleanset'
import QueryInterfaceFindDataset from './QueryInterfaceFindDataset'
import QueryInterfaceFindExperimentalDataset from './QueryInterfaceFindExperimentalDataset'
import QueryInterfaceFindExperimentalJob from './QueryInterfaceFindExperimentalJob'
import QueryInterfaceFindProject from './QueryInterfaceFindProject'
import QueryInterfaceFindSchema from './QueryInterfaceFindSchema'
import QueryInterfaceFindTask from './QueryInterfaceFindTask'
import QueryInterfaceFindUser from './QueryInterfaceFindUser'
import QueryInterfaceSessionData from './QueryInterfaceSessionData'

const QueryInterface = () => {
  return (
    <Container maxWidth="100%">
      <Tabs>
        <TabList>
          <Tab>Find User</Tab>
          <Tab>Dataset</Tab>
          <Tab>Schema</Tab>
          <Tab>Project</Tab>
          <Tab>Cleanset</Tab>
          <Tab>Task</Tab>
          <Tab>Session Data</Tab>
          <Tab>Experimental Dataset</Tab>
          <Tab>Experimental Job</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <QueryInterfaceFindUser />
          </TabPanel>
          <TabPanel>
            <QueryInterfaceFindDataset />
          </TabPanel>
          <TabPanel>
            <QueryInterfaceFindSchema />
          </TabPanel>
          <TabPanel>
            <QueryInterfaceFindProject />
          </TabPanel>
          <TabPanel>
            <QueryInterfaceFindCleanset />
          </TabPanel>
          <TabPanel>
            <QueryInterfaceFindTask />
          </TabPanel>
          <TabPanel>
            <QueryInterfaceSessionData />
          </TabPanel>
          <TabPanel>
            <QueryInterfaceFindExperimentalDataset />
          </TabPanel>
          <TabPanel>
            <QueryInterfaceFindExperimentalJob />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  )
}

export default QueryInterface
