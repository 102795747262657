import { ActionType } from '../pages/cleanset/commandpalette/CommandPalette.types'

export const TagToColorScheme: Record<ActionType | string, string> = {
  given: 'blue',
  suggested: 'yellow',
  quality: 'purple',
  issue: 'pink',
  'no issue': 'green',
  corrected: 'green',
  'auto-fix': 'yellow',
  keep: 'blue',
  exclude: 'red',
  're-label': 'orange',
  unresolved: 'gray',
  needs_review: 'orange',
  unlabeled: 'green',
  outlier: 'orange',
  label_issue: 'red',
  ambiguous: 'linkedin',
  near_duplicate: 'purple',
  all_examples: 'facebook',
  'remove-tag-label': 'orange',
}
