import './UploadDropzone.min.css'

import { useToast } from '@chakra-ui/react'
import { UserQuotaAlertInfo } from '@common/alerts/userQuotaAlert/UserQuotaAlert.types'
import { useAuth } from '@hooks/useAuth'
import { useEventTracking } from '@hooks/useEventTracking'
import { createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import logger from '@utils/logger'
import { AxiosError } from 'axios'
import { FilePondErrorDescription } from 'filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { useEffect, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'

import UserQuotaAlert from '../alerts/userQuotaAlert/UserQuotaAlert'
import UploadDropzoneProps from './UploadDropzone.types'

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)

const UploadDropzone = (props: UploadDropzoneProps) => {
  const {
    onUpdateFiles,
    uploadType,
    onProcessFile,
    onRemoveFile,
    timeout,
    maxFileSize,
    testId,
    isZipUpload,
    isTestUpload,
  } = props
  const { getAccessTokenSilently } = useAuth()
  const [accessToken, setAccessToken] = useState<string>('')
  const [quotaAlert, setQuotaAlert] = useState<UserQuotaAlertInfo | null>(null)
  const toast = useToast()
  const { trackEvent } = useEventTracking()
  const FIVE_MINUTES = 1000 * 60 * 5
  const acceptedFileTypes = isZipUpload
    ? [
        'application/zip',
        'application/x-zip-compressed', // MIME type of zip files uploaded on Windows
      ]
    : [
        'text/csv',
        'application/json',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // '.parquet',
      ]

  useEffect(() => {
    const setToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently()
        setAccessToken(accessToken)
      } catch (err) {
        notifyAxiosError(toast, err as AxiosError, { title: 'Failed to authenticate' })
      }
    }
    setToken()
  }, [getAccessTokenSilently, toast])

  return (
    <div className="upload-tour-dropzone w-full" data-testid={testId}>
      <FilePond
        allowFileSizeValidation
        maxFileSize={maxFileSize || '100MB'}
        allowFileTypeValidation
        onupdatefiles={onUpdateFiles}
        onprocessfile={onProcessFile}
        onremovefile={onRemoveFile}
        onaddfilestart={(file) => {
          if (isTestUpload) {
            trackEvent(MixpanelEvents.addFilepondTestFile, {
              fileType: file.fileExtension,
              fileSize: file.fileSize,
              fileName: file.filename,
            })
          }
        }}
        allowMultiple={false}
        maxFiles={1}
        acceptedFileTypes={acceptedFileTypes}
        // @ts-ignore
        fileValidateTypeDetectType={(source: File, type: string) => {
          logger.info('file validate props', { source, type })
          return new Promise((resolve) => {
            if (source.name.endsWith('.parquet')) {
              resolve('.parquet')
            }
            resolve(type)
          })
        }}
        server={{
          // https://pqina.nl/filepond/docs/api/server/
          url: `${REACT_APP_CLEANLAB_API_URL}/api/filepond/upload?type=${uploadType}`,
          process: {
            url: '',
            timeout: timeout || FIVE_MINUTES,
            ...createAuthHeaders(accessToken),
          },
          revert: {
            url: '',
            timeout: timeout || FIVE_MINUTES,
            ...createAuthHeaders(accessToken),
          },
        }}
        onerror={(response: FilePondErrorDescription) => {
          if (response.code === 429) {
            setQuotaAlert({
              code: 'user_quota_exceeded',
            })
          }
        }}
        name="files"
        labelIdle='<div class="body-200-medium text-text-strong">Drag and drop your files or <span class="cursor-pointer text-blue-600 underline">browse</span>'
      />
      <UserQuotaAlert info={quotaAlert} setInfo={setQuotaAlert} />
    </div>
  )
}

export default UploadDropzone
