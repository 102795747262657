import { Checkbox } from '@components/checkbox/Checkbox'
import { LabelInline } from '@components/label/LabelInline'
import { Tooltip } from '@components/tooltip/Tooltip'

import { ColumnFilterRowProps } from './ColumnFilterRow.types'

const ColumnFilterRow = (props: ColumnFilterRowProps) => {
  const { label, checked, onCheckedChange, disabled, tooltip } = props

  const columnFilterRow = (
    <LabelInline content={label} variant="textSubtle" className="px-6 py-3">
      <Checkbox
        variant="highContrast"
        checked={checked}
        onCheckedChange={() => onCheckedChange?.()}
        disabled={disabled}
      />
    </LabelInline>
  )

  return tooltip ? <Tooltip content={tooltip}>{columnFilterRow}</Tooltip> : columnFilterRow
}

export default ColumnFilterRow
