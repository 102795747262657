import { Box, Button, HStack, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { useEventTracking } from '@hooks/useEventTracking'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import uploadApiService from '@services/upload/v1/uploadApi'
import { AxiosError } from 'axios'
import React, { useContext, useState } from 'react'

import { SchemaUpdatesContext } from '../SchemaUpdatesContext'
import { SaveSchemaChangesFloaterProps } from './SaveSchemaChangesFloater.types'

const SaveSchemaChangesFloater = ({
  datasetId,
  refreshData,
  setSchemaUpdateInProgress,
}: SaveSchemaChangesFloaterProps) => {
  const { schemaUpdates, setSchemaUpdates } = useContext(SchemaUpdatesContext)
  const { trackEvent } = useEventTracking()
  const toast = useToast()

  const textColor = useColorModeValue('neutral.100', 'neutralDarkMode.100')
  const bgColor = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const undoButtonColor = useColorModeValue('neutral.200', 'neutralDarkMode.200')
  const undoButtonHoverColor = useColorModeValue('neutral.100', 'neutralDarkMode.100')
  const saveButtonColor = useColorModeValue('blue.700', 'blueDarkMode.700')
  const saveButtonHoverColor = useColorModeValue('blue.600', 'blueDarkMode.600')
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSaveChangesButtonClicked = async () => {
    if (schemaUpdates) {
      try {
        setSubmitting(true)
        setSchemaUpdateInProgress(true)
        await uploadApiService.updateSchema(datasetId, schemaUpdates)
        trackEvent(MixpanelEvents.clickSaveSchemaChanges, { datasetId })
        refreshData(datasetId)
        setSchemaUpdates({})
      } catch (err) {
        const error = err as AxiosError
        notifyAxiosError(toast, error)
        setSubmitting(false)
        setSchemaUpdateInProgress(false)
      }
    }
  }

  return (
    <Box
      position="fixed"
      bottom={3}
      left={0}
      right={0}
      margin="auto"
      zIndex={9999}
      bg={bgColor}
      w="390px"
      padding="12px"
      borderRadius="12px"
    >
      <HStack spacing="8px">
        <Text fontSize="12px" lineHeight="16px" fontWeight={600} color={textColor} pr="8px">
          You have unsaved changes
        </Text>
        <Button
          onClick={() => setSchemaUpdates({})}
          fontSize="14px"
          bg={undoButtonColor}
          _hover={{
            bg: undoButtonHoverColor,
          }}
          h="32px"
          padding="6px 12px"
        >
          Undo Changes
        </Button>
        <Button
          onClick={handleSaveChangesButtonClicked}
          isDisabled={isSubmitting}
          fontSize="14px"
          bg={saveButtonColor}
          color={textColor}
          _hover={{
            bg: saveButtonHoverColor,
          }}
          h="32px"
          padding="6px 12px"
        >
          Save
        </Button>
      </HStack>
    </Box>
  )
}

export default SaveSchemaChangesFloater
