import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { ColumnTypeV1 } from '@services/datasetApi'
import React, { useContext, useRef } from 'react'
import ReactDOM from 'react-dom'
import { FiEdit3 } from 'react-icons/fi'

import { SchemaUpdatesContext } from '../../SchemaUpdatesContext'
import ColumnSubtypeMenuButton from './ColumnSubtypeMenuButton/ColumnSubtypeMenuButton'
import {
  ColumnTypeMenuProps,
  DatasetColumnTypes,
  getColumnTypeDisplayValue,
} from './ColumnTypeMenu.types'
import ColumnTypeMenuButton from './ColumnTypeMenuButton/ColumnTypeMenuButton'

const ColumnTypeMenu = (props: ColumnTypeMenuProps) => {
  const { originalColumnType, columnId, editButtonDisabledText } = props
  const buttonColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const buttonHoverBgColor = useColorModeValue('neutral.100', 'neutralDarkMode.100')
  const { schemaUpdates, addSchemaUpdate, removeSchemaUpdate } = useContext(SchemaUpdatesContext)
  const currentColumnType = schemaUpdates[columnId] ?? originalColumnType
  const { isOpen, onOpen, onClose } = useDisclosure()

  // We are controlling open/close with useDisclosure, so need to manually close on outside click
  const ref = useRef<HTMLDivElement | null>(null)
  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  })

  const handleSelectColumnType = (e: any, columnType: ColumnTypeV1 | undefined) => {
    if (columnType) {
      e.stopPropagation()
      const newColumnType = columnType.toString()
      if (originalColumnType === newColumnType) {
        removeSchemaUpdate(columnId)
      } else {
        addSchemaUpdate(columnId, newColumnType)
      }
      onClose()
    }
  }

  return (
    <Menu gutter={4} isOpen={isOpen}>
      <Tooltip label={editButtonDisabledText}>
        <MenuButton
          as={Button}
          size="14px"
          padding="6px 8px 6px 12px"
          onClick={(e) => {
            e.stopPropagation()
            onOpen()
          }}
          rightIcon={<FiEdit3 />}
          isDisabled={editButtonDisabledText !== null}
          variant="ghost"
          textTransform="capitalize"
          alignSelf="flex-end"
          color={buttonColor}
          borderRadius="8px"
          _hover={{
            bg: buttonHoverBgColor,
          }}
          _groupHover={{
            borderColor: useColorModeValue('neutral.200', 'neutralDarkMode.200'),
          }}
          _active={{ bg: useColorModeValue('neutral.0', 'neutralDarkMode.0') }}
        >
          {getColumnTypeDisplayValue(currentColumnType)}
        </MenuButton>
      </Tooltip>
      {ReactDOM.createPortal(
        <MenuList zIndex={9999} padding="8px">
          {Object.entries(DatasetColumnTypes).map(([columnType, value]) => {
            return (
              <MenuItem
                onClick={(e) => handleSelectColumnType(e, value.columnType)}
                borderRadius="8px"
                fontSize="14px"
                fontWeight="400px"
                lineHeight="20px"
                color={buttonColor}
                _hover={{
                  bg: buttonHoverBgColor,
                }}
                _active={{
                  bg: buttonHoverBgColor,
                }}
                _focus={{
                  bg: buttonHoverBgColor,
                }}
                p={0}
              >
                {value.columnType !== undefined ? (
                  <ColumnTypeMenuButton
                    columnType={columnType}
                    currentColumnType={currentColumnType}
                  />
                ) : (
                  <ColumnSubtypeMenuButton
                    columnType={columnType}
                    subTypes={value.subTypes}
                    currentColumnType={currentColumnType}
                    handleSelectColumnType={handleSelectColumnType}
                  />
                )}
              </MenuItem>
            )
          })}
        </MenuList>,
        document.body
      )}
    </Menu>
  )
}

export default ColumnTypeMenu
