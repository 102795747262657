import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  salesEmail,
  salesMailToLinkWithSubject,
  slackCommunityInviteLink,
  supportEmail,
  supportMailToLinkWithSubject,
} from '@common/modals/betaFeatureModal/BetaFeatureModal.helpers'
import Link from '@components/link/Link'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'

import { NonViewableProjectModalProps } from './NonViewableProjectModal.types'

const NonViewableProjectModal = (props: NonViewableProjectModalProps) => {
  const { isOpen, onClose, tasktype, skipSubscriptionCheck } = props

  const { trackEvent } = useEventTracking()
  const { isEnterprisePlan, payingCustomer } = useSubscription()
  const modalBg = useColorModeValue('white', 'neutralDarkMode.200')
  const formattedTasktype = tasktype.charAt(0).toUpperCase() + tasktype.slice(1)
  const featureName = `${formattedTasktype} Project`

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader textAlign="center">Can&#x2019;t View Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {(isEnterprisePlan && payingCustomer) || skipSubscriptionCheck ? (
            <Text>
              {formattedTasktype} Projects are currently only available through the API. Contact{' '}
              <Link
                href={supportMailToLinkWithSubject(featureName)}
                isExternal={true}
                onClick={() =>
                  trackEvent(MixpanelEvents.clickEmailTeamLink, { featureName: featureName })
                }
              >
                {supportEmail}
              </Link>{' '}
              or message us directly on{' '}
              <Link href={slackCommunityInviteLink} isExternal={true}>
                Slack
              </Link>{' '}
              to learn more.
            </Text>
          ) : (
            <Text>
              {formattedTasktype} Projects are only available through the API on your current plan.
              Contact{' '}
              <Link
                isExternal={true}
                href={salesMailToLinkWithSubject(featureName)}
                onClick={() =>
                  trackEvent(MixpanelEvents.clickEmailTeamLink, { featureName: featureName })
                }
              >
                {salesEmail}
              </Link>{' '}
              for more information.
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} aria-label="close">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NonViewableProjectModal
