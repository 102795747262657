import { TlmPricingItem } from '@common/modals/tlmPricingModal/TlmPricingModal.types'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import camelCaseKeys from '@utils/functions/camelCaseKeys'
import axios from 'axios'
import { useQuery } from 'react-query'
import { InvoiceData } from 'src/pages/account/payment/currentPeriodUsage/CurrentPeriodUsage.types'
import { CardDetailsType } from 'src/pages/account/payment/paymentSetup/cardDetails/CardDetails.types'

import {
  CleansetExportDetailsData,
  CleansetExportDetailsRes,
  GetBillingPlansRes,
  GetBillingSubscriptionsRes,
  InferenceExportDetailsData,
  InferenceExportDetailsRes,
  MonthlyTlmTokenUsageRes,
  PlanData,
  queryKeys,
  SubscriptionDetails,
  SubscriptionDetailsRes,
} from './constants'

const apiVersion = 'v1'
const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/billing`,
  withCredentials: true,
})

export const useSubscriptionDetails = ({ enabled }: { enabled: boolean }): SubscriptionDetails => {
  const { data, isLoading } = useQuery<SubscriptionDetailsRes>({
    queryKey: queryKeys.billing.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/subscription-info', createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Error fetching subscription details.' },
    enabled: !!enabled,
  })
  return {
    cleansetExportRowsMonthlyLimit: data?.cleanset_export_rows_monthly_limit ?? 0,
    createdAt: data?.created_at ?? 0,
    datasetRowsLimit: data?.dataset_rows_limit ?? 0,
    datasetNumColumnsLimit: data?.dataset_num_columns_limit ?? 0,
    datasetSizeBytesLimit: data?.dataset_size_limit_bytes ?? 0,
    projectNumClassesLimit: data?.project_num_classes_limit ?? 0,
    projectInstanceSizeLimit: data?.project_instance_size_limit ?? '',
    projectTrainingTimeLimit: data?.project_training_time_limit ?? 0,
    description: data?.description ?? '',
    isEnterprisePlan: data?.is_enterprise_plan ?? false,
    isIndividualPayingCustomer: data?.is_individual_paying_customer ?? false,
    freeCleansetExportRowsMonthlyLimit: data?.free_cleanset_export_rows_monthly_limit ?? 0,
    freeInferenceExportRowsMonthlyLimit: data?.free_inference_export_rows_monthly_limit ?? 0,
    freeModelsMonthlyLimit: data?.free_models_monthly_limit ?? 0,
    freeProjectsMonthlyLimit: data?.free_projects_monthly_limit ?? 0,
    freeTrialDatasetRowsLimit: data?.free_trial_dataset_rows_limit ?? 0,
    freeTrialInferenceExportRowsLimit: data?.free_trial_inference_export_rows_limit ?? 0,
    freeTrialLengthDays: data?.free_trial_length_days ?? 0,
    freeTrialModelsLimit: data?.free_trial_models_limit ?? 0,
    freeTrialPeriod: data?.free_trial_period ?? false,
    freeTrialPeriodEnd: data?.free_trial_period_end ?? 0,
    freeTrialProjectsLimit: data?.free_trial_projects_limit ?? 0,
    inferenceExportRowsMonthlyLimit: data?.inference_export_rows_monthly_limit ?? 0,
    lastUpdated: data?.last_updated ?? 0,
    payingCustomer: data?.paying_customer ?? false,
    planName: data?.plan_name ?? '',
    pricePerMonth: data?.price_per_month ?? 0,
    pricePerRowCleansetExport: data?.price_per_row_cleanset_export ?? 0,
    pricePerRowInferenceExport: data?.price_per_row_inference_export ?? 0,
    pricePerYear: data?.price_per_year ?? 0,
    tier: data?.tier ?? '',
    userId: data?.user_id ?? '',
    freeTrialPeriodRemaining: data?.free_trial_period_remaining ?? 0,
    cancelledAt: data?.cancelled_at ?? null,
    isLoading: isLoading,
  }
}

export const useGetBillingSubscriptions = (isAuthenticated = true) => {
  const { data } = useQuery<GetBillingSubscriptionsRes>({
    queryKey: queryKeys.billing.billingSubscriptions(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/billing-subscriptions', createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Error fetching billing subscriptions.' },
    enabled: isAuthenticated,
  })
  return { rows: data?.rows ?? [] }
}

export const useGetBillingPlans = (isAuthenticated = true): PlanData[] => {
  const { data } = useQuery<GetBillingPlansRes>({
    queryKey: queryKeys.billing.billingPlans(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/billing-plans', createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Error fetching billing plans.' },
    enabled: isAuthenticated,
  })
  return data?.rows ?? []
}

export const useCardDetails = (isAuthenticated = true): CardDetailsType => {
  const { data } = useQuery({
    queryKey: queryKeys.billing.cardDetails(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/card-details', createAuthHeaders(accessToken))
      return res.data
    },
    enabled: isAuthenticated,
    meta: { error: 'Failed to fetch card details.' },
  })
  return data ?? {}
}

export const useCleansetExportDetails = ({
  isAuthenticated = true,
  isIndividualPlan = false,
  projectId = '',
}): CleansetExportDetailsData => {
  const { data } = useQuery<CleansetExportDetailsRes>({
    queryKey: queryKeys.billing.cleansetExportDetails(projectId),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(
        `/paid-cleanset-export-info/${projectId}`,
        createAuthHeaders(accessToken)
      )
      return res.data
    },
    meta: { error: 'Error fetching cleanset export details.' },
    enabled: isAuthenticated && isIndividualPlan,
  })
  return {
    previouslyExported: data?.previously_exported ?? false,
    numFreeRows: data?.num_free_rows ?? 0,
    pricePerRowExport: data?.price_per_row_export ?? 0,
    numOverageRows: data?.num_overage_rows ?? 0,
    totalPrice: data?.total_price ?? 0,
    numFreeCleansetExportRowsRemaining: data?.num_free_cleanset_export_rows_remaining ?? 0,
    numTotalCleansetExportRowsRemaining: data?.num_total_cleanset_export_rows_remaining ?? 0,
  }
}

export const useInferenceExportDetails = ({
  isAuthenticated = true,
  isIndividualPlan = false,
  queryId = '',
}): InferenceExportDetailsData => {
  const { data } = useQuery<InferenceExportDetailsRes>({
    queryKey: queryKeys.billing.inferenceExportDetails(queryId),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get(
        `/paid-inference-export-info/${queryId}`,
        createAuthHeaders(accessToken)
      )
      return res.data
    },
    meta: { error: 'Error fetching cleanset export details.' },
    enabled: isAuthenticated && isIndividualPlan,
  })
  return {
    previouslyExported: data?.previously_exported ?? false,
    numFreeRows: data?.num_free_rows ?? 0,
    pricePerRowExport: data?.price_per_row_export ?? 0,
    numOverageRows: data?.num_overage_rows ?? 0,
    totalPrice: data?.total_price ?? 0,
    numFreeInferenceExportRowsRemaining: data?.num_free_inference_export_rows_remaining ?? 0,
    numTotalInferenceExportRowsRemaining: data?.num_total_inference_export_rows_remaining ?? 0,
  }
}

export const useCurrentPeriodUsage = (isAuthenticated = true): InvoiceData => {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.billing.currentPeriodUsage(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/current-period-usage', createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Error fetching current period usage.' },
    enabled: isAuthenticated,
  })
  return data
    ? {
        lineItems: data.line_items,
        amountDue: data.amount_due,
        taxAmount: data.tax_amount,
        periodEnd: data.period_end,
        isLoading: isLoading,
      }
    : {
        amountDue: 0,
        taxAmount: 0,
        periodEnd: 0,
        lineItems: [],
        isLoading: isLoading,
      }
}

export const usePaymentHistory = (isAuthenticated = true) => {
  const { data } = useQuery({
    queryKey: queryKeys.billing.paymentHistory(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/payment-history', createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Error fetching payment history.' },
    enabled: isAuthenticated,
  })
  return data ?? []
}

export const useTlmTokenCosts = (isAuthenticated = true): TlmPricingItem[] => {
  const { data } = useQuery({
    queryKey: queryKeys.billing.tlm.tlmTokenCosts(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/tlm-token-costs', createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Error fetching TLM token costs.' },
    enabled: isAuthenticated,
  })
  return camelCaseKeys(data) ?? []
}

export const useMonthlyTlmTokenUsage = (isAuthenticated = true): MonthlyTlmTokenUsageRes => {
  const { data } = useQuery({
    queryKey: queryKeys.billing.tlm.tlmCosts(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get('/monthly-tlm-token-usage', createAuthHeaders(accessToken))
      return res.data
    },
    meta: { error: 'Error fetching monthly TLM token usage.' },
    enabled: isAuthenticated,
  })
  return camelCaseKeys(data) ?? []
}
