import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useColumnValues } from '@services/datasheet/queries'
import { ColDef } from 'ag-grid-community'
import { useCallback, useContext, useMemo, useState } from 'react'
import { FiChevronDown, FiSearch } from 'react-icons/fi'
import { FixedSizeList } from 'react-window'
import { Tasktype } from 'src/pages/projectForm/projectFormFields/ProjectFormFields.types'

import { CleansetContext } from '../../CleansetContext'
import { getBackendColName } from '../../datasheet/Datasheet.helpers'
import { CLEANLAB_FRONTEND_COLUMN } from '../../datasheet/Datasheet.types'
import {
  CleansetFilterActions,
  FilterOperator,
  PRESET_FILTER_PROPERTIES,
  PresetHeaders,
} from '../../filterReducer/FilterState.types'
import { useFilters } from '../../filterReducer/useFilters'
import { getHeaderName, shouldUseBooleanFilterOptions } from '../FilterMenu.helpers'
import { AddNewFilterMenuProps } from './AddNewFilterMenu.types'
import {
  CLEANLAB_FRONTEND_CUSTOM_METADATA_COLUMNS,
  CLEANLAB_FRONTEND_CUSTOM_TAGS,
  CLEANLAB_FRONTEND_ISSUE_COLUMNS,
  getFilterLabel,
  getFilterValue,
  numberOptions,
  textOptions,
} from './EditFilterMenu.helpers'
import { EditFilterMenuOptions } from './EditFilterMenu.types'
import FilterRadioButton from './filterRadioButton/FilterRadioButton'

const AddNewFilterMenu = (props: AddNewFilterMenuProps) => {
  const {
    gridOptions,
    resetResolverAndRowSelection,
    setSelectedProperty,
    setFilterInput,
    selectedProperty,
    filterInput,
    presetProperties,
    isOpen,
    cleansetId,
    onClose,
  } = props

  const { tasktype } = useContext(CleansetContext)

  const { dispatch } = useFilters()

  const { trackEvent } = useEventTracking()

  const [filterValues, setFilterValues] = useState('')

  const [propertySearchInput, setPropertySearchInput] = useState('')
  const [filterType, setFilterType] = useState(FilterOperator.Equals)

  const [searchInput, setSearchInput] = useState('')

  const colDefs = (gridOptions.columnDefs ?? []) as ColDef[]
  const defaultProperty = colDefs.find((e) => e.headerName === PresetHeaders.Given)?.field
  const isPresetColumn =
    selectedProperty === defaultProperty || PRESET_FILTER_PROPERTIES.includes(selectedProperty)
  const isNumber = filterValues === 'agNumberColumnFilter'

  const filteredProperties = useMemo(() => {
    return presetProperties
      .filter((presetProperty) => {
        const lowerCasePropertySearchInput = propertySearchInput.toLowerCase()
        return (
          presetProperty?.headerName?.toLowerCase().includes(lowerCasePropertySearchInput) ||
          presetProperty?.field?.toLowerCase().includes(lowerCasePropertySearchInput)
        )
      })
      .sort((a, b) => (a.headerName ?? a.field ?? '').localeCompare(b.headerName ?? b.field ?? ''))
  }, [presetProperties, propertySearchInput])

  const grayColors = useColorModeValue('neutral.200', 'neutralDarkMode.200')
  const menuBg = useColorModeValue('white', 'neutralDarkMode.100')

  const isQueryEnabled =
    isOpen &&
    isPresetColumn &&
    !CLEANLAB_FRONTEND_ISSUE_COLUMNS.includes(selectedProperty) &&
    selectedProperty !== CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS &&
    tasktype !== Tasktype.UNSUPERVISED

  const { data: colVals, isLoading } = useColumnValues(
    cleansetId,
    getBackendColName(selectedProperty as CLEANLAB_FRONTEND_COLUMN),
    isQueryEnabled,
    // Set to normally query once if property is Corrected or Action. Will requery if action is taken.
    selectedProperty === CLEANLAB_FRONTEND_COLUMN.CORRECTED ||
      selectedProperty === CLEANLAB_FRONTEND_COLUMN.ACTION
  )

  const searchColVals = colVals.filter((e) =>
    `${e}`.toLowerCase().includes(searchInput.toLowerCase())
  )

  const applyFilters = useCallback(() => {
    setSearchInput('')
    if (selectedProperty.length && filterInput) {
      resetResolverAndRowSelection()
      dispatch({
        type: CleansetFilterActions.APPLY_FILTER,
        filterData: {
          property: selectedProperty,
          filterType: filterType === FilterOperator.All ? FilterOperator.Equals : filterType,
          filterInput: filterInput,
          headerName: getHeaderName(selectedProperty, selectedProperty === defaultProperty),
          agGridType: filterValues,
          isOpen: false,
          isPreset: false,
        },
      })
      setSelectedProperty('')
      setFilterInput('')
    }
  }, [
    selectedProperty,
    filterInput,
    resetResolverAndRowSelection,
    dispatch,
    filterType,
    defaultProperty,
    filterValues,
    setSelectedProperty,
    setFilterInput,
  ])

  const generateValueOptions = () => {
    if (isPresetColumn && selectedProperty !== CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS) {
      return (
        <Menu>
          <Box my="0.5rem">
            <MenuButton
              w="100%"
              defaultValue={EditFilterMenuOptions.EQUALS}
              as={Button}
              textAlign="left"
              rightIcon={<FiChevronDown />}
            >
              {filterType ? getFilterLabel(filterType, false) : EditFilterMenuOptions.EQUALS}
            </MenuButton>
          </Box>
          <MenuList defaultValue={EditFilterMenuOptions.EQUALS}>
            {[EditFilterMenuOptions.EQUALS, EditFilterMenuOptions.NOT_EQUAL].map((e) => (
              <MenuItem
                key={e}
                fontSize="md"
                onClick={(ev) => {
                  setFilterType(getFilterValue(e, false))
                  ev.stopPropagation()
                }}
              >
                {e}
              </MenuItem>
            ))}
          </MenuList>
          {selectedProperty === CLEANLAB_FRONTEND_COLUMN.CORRECTED && (
            <Input
              mt={1}
              placeholder="Search"
              onKeyDown={(evt) => evt.stopPropagation()}
              onChange={(evt) => setSearchInput(evt.target.value)}
            />
          )}
          <Box
            mt={3}
            px={0.5}
            border="2px solid"
            borderColor={grayColors}
            borderRadius="xl"
            overflow="hidden"
          >
            <Stack maxHeight="20vh" overflowY="auto">
              {isLoading && (
                <Center h={150}>
                  <Spinner />
                </Center>
              )}
              {!isLoading && searchColVals.length > 0 && (
                <RadioGroup pt={1} value={filterInput ? filterInput.toString() : ''}>
                  <FixedSizeList
                    height={Math.min(window.innerHeight * 0.25, 31 * searchColVals.length)}
                    itemCount={searchColVals.length}
                    itemSize={30}
                    width={300}
                  >
                    {({ index, style }) => (
                      <Box style={style}>
                        <FilterRadioButton
                          key={searchColVals[index]?.toString()}
                          text={(searchColVals[index] || '-').toString()}
                          isTag={selectedProperty === CLEANLAB_FRONTEND_COLUMN.ACTION}
                          onSelect={() => setFilterInput((searchColVals[index] || '-').toString())}
                        />
                      </Box>
                    )}
                  </FixedSizeList>
                </RadioGroup>
              )}
              {!isLoading && searchColVals.length < 1 && (
                <Center color="gray.300" py={1} fontSize="sm" fontStyle="italic">
                  No results
                </Center>
              )}
            </Stack>
          </Box>
        </Menu>
      )
    } else if (selectedProperty === CLEANLAB_FRONTEND_COLUMN.CUSTOM_TAGS) {
      return (
        <Box pb={1} px={0.5} border="2px solid" borderColor={grayColors} borderRadius="xl">
          <RadioGroup value={filterInput ? filterInput.toString() : ''} pt={1}>
            {['needs_review'].map((e) => (
              <FilterRadioButton
                isTag={CLEANLAB_FRONTEND_CUSTOM_TAGS.includes(e.toString())}
                key={e.toString()}
                text={e.toString()}
                onSelect={() => {
                  setFilterInput(e.toString())
                  setFilterType(FilterOperator.Equals)
                }}
              />
            ))}
          </RadioGroup>
        </Box>
      )
    } else if (shouldUseBooleanFilterOptions(selectedProperty)) {
      return (
        <Box border="2px solid" borderColor={grayColors} borderRadius="xl">
          <RadioGroup zIndex={5} value={filterInput ? filterInput.toString() : ''}>
            <Stack py={2}>
              {['true', 'false'].map((e) => (
                <FilterRadioButton
                  key={e}
                  text={e}
                  onSelect={() => {
                    setFilterInput(e)
                    setFilterType(FilterOperator.Equals)
                  }}
                />
              ))}
            </Stack>
          </RadioGroup>
        </Box>
      )
    } else if (CLEANLAB_FRONTEND_CUSTOM_METADATA_COLUMNS.includes(selectedProperty)) {
      return (
        <Menu>
          <Box my="0.5rem">
            <MenuButton w="100%" as={Button} textAlign="left" rightIcon={<FiChevronDown />}>
              {filterType ? getFilterLabel(filterType, isNumber) : EditFilterMenuOptions.EQUALS}
            </MenuButton>
          </Box>
          <MenuList>
            {[EditFilterMenuOptions.EQUALS].map((e) => (
              <MenuItem
                key={e}
                fontSize="md"
                onClick={() => setFilterType(getFilterValue(e, isNumber))}
              >
                {e}
              </MenuItem>
            ))}
          </MenuList>
          <Box pb={1} px={0.5}>
            <Input
              disabled={false}
              name="filterValueInput"
              value={filterInput}
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => setFilterInput(e.target.value)}
            />
          </Box>
        </Menu>
      )
    } else {
      // Quality + any other columns that aren't Cleanlab generated
      return (
        <Menu>
          <Box my="0.5rem">
            <MenuButton
              w="100%"
              defaultValue={isNumber ? 'Equals' : 'Contains'}
              as={Button}
              textAlign="left"
              rightIcon={<FiChevronDown />}
            >
              {filterType ? getFilterLabel(filterType, isNumber) : isNumber ? 'Equals' : 'Contains'}
            </MenuButton>
          </Box>
          <MenuList defaultValue={isNumber ? 'equals' : 'contains'}>
            {(isNumber ? numberOptions : textOptions).map((e) => (
              <MenuItem
                key={e}
                fontSize="md"
                onClick={() => setFilterType(getFilterValue(e, isNumber))}
              >
                {e}
              </MenuItem>
            ))}
          </MenuList>
          <Box pb={1} px={0.5}>
            <Input
              disabled={false}
              name="filterValueInput"
              value={filterInput}
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => setFilterInput(e.target.value)}
            />
          </Box>
        </Menu>
      )
    }
  }

  return (
    <MenuList
      bg={menuBg}
      minWidth="300px"
      maxH="70vh"
      overflowY="auto"
      borderRadius="2xl"
      zIndex="overlay"
    >
      <Box margin="2px 12px">
        <Box>
          <Text fontSize="lg" fontWeight="bold" margin="4px 4px">
            Add new filter
          </Text>
          <InputGroup marginBottom="1rem">
            <InputRightElement pointerEvents="none" children={<FiSearch size="24px" />} />
            <Input
              value={propertySearchInput}
              borderRadius="xl"
              type="text"
              key="propSearch"
              placeholder="Search"
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
              onChange={(event) => {
                setPropertySearchInput(event.target.value)
              }}
            />
          </InputGroup>
          <RadioGroup
            defaultValue="_cleanlab_clean_label"
            fontSize="lg"
            value={getHeaderName(selectedProperty, false)}
            key="propertyGroup"
          >
            {filteredProperties.length ? (
              <Box overflow="hidden" border="2px solid" borderColor={grayColors} borderRadius="xl">
                <Stack maxH="18vh" py={2} overflowY="scroll">
                  {filteredProperties.map((property) => {
                    return (
                      <FilterRadioButton
                        key={property.field}
                        text={
                          property.headerName
                            ? property.headerName
                            : property.field
                              ? property.field
                              : ''
                        }
                        onSelect={() => {
                          setSelectedProperty(property.field as string)
                          setFilterValues(property.filter)
                        }}
                      />
                    )
                  })}
                </Stack>
              </Box>
            ) : (
              <Box textAlign="center">
                <Text as="i" fontSize="sm">
                  No properties found
                </Text>
              </Box>
            )}
          </RadioGroup>
        </Box>
        <RadioGroup fontSize="lg" mt={2}>
          {filteredProperties.length ? (
            <>
              <Text mx="4px" mb={0} fontWeight="bold">
                Values
              </Text>
              {generateValueOptions()}
              <Flex justifyContent="right" width="100%" mt={2}>
                <PrimaryButton
                  fontSize="sm"
                  height="32px"
                  onClick={() => {
                    applyFilters()
                    onClose()
                    trackEvent(MixpanelEvents.clickApplyNewFilter, {
                      filterData: {
                        property: selectedProperty,
                        filterType:
                          filterType === FilterOperator.All ? FilterOperator.Equals : filterType,
                        filterInput: filterInput,
                        headerName: getHeaderName(
                          selectedProperty,
                          selectedProperty === defaultProperty
                        ),
                        agGridType: filterValues,
                        isPreset: false,
                      },
                    })
                  }}
                >
                  Apply
                </PrimaryButton>
              </Flex>
            </>
          ) : (
            <></>
          )}
        </RadioGroup>
      </Box>
    </MenuList>
  )
}

export default AddNewFilterMenu
