'use client'
import { ForwardedRef, forwardRef, memo } from 'react'

import { IconFrameProps, IconFrameRoot } from './IconFrameRoot'

const IconFrameBase = (props: IconFrameProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <IconFrameRoot ref={ref} {...props} clickable={false} />
}

/**
 * IconFrame component
 */
export const IconFrame = memo(forwardRef(IconFrameBase))
IconFrame.displayName = 'IconFrame'
