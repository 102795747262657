export const queryKeys = {
  organizationQuotas: {
    all: () => ['organizationQuotas'] as const,
    orgBudget: (orgId: string) => [...queryKeys.organizationQuotas.all(), 'orgBudget', orgId],
    costUsageRatio: () => [...queryKeys.organizationQuotas.all(), 'costUsageRatio'],
  },
}

export interface OrgUsageRes {
  createdAt: number
  dailyBudgetDollars: number
  lastUpdated: number
  maxBudgetDollars: number
  organizationId: string
  organizationQuotaId: string
  totalBudgetDollars: number
  totalCost: number
}

export interface OrgCostUsageRatioRes {
  costUsageRatio: number
}
