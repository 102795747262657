'use client'
import {
  arrow,
  autoUpdate,
  flip,
  FloatingDelayGroup,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useDelayGroup,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import { cn } from '@utils/tailwindUtils'
import {
  cloneElement,
  ComponentPropsWithoutRef,
  createContext,
  ElementRef,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  isValidElement,
  memo,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Transition } from 'react-transition-group'

import { getSideAlignment, TOOLTIP_ARROW_HEIGHT, TooltipArrow } from './TooltipArrow'

type TooltipOptions = {
  initialOpen?: boolean
  placement?: Placement
  open?: boolean
  onOpenChange?: (open: boolean) => void
  sideOffset?: number
  collisionPadding?: number
  arrowPadding?: number
  disabled?: boolean
}

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  sideOffset = 4,
  collisionPadding = 12,
  arrowPadding = 4,
  disabled = false,
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const arrowRef = useRef<HTMLDivElement>(null)

  const open = disabled ? false : controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(sideOffset + TOOLTIP_ARROW_HEIGHT),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: collisionPadding,
      }),
      shift({ padding: collisionPadding }),
      arrow({ element: arrowRef, padding: arrowPadding }),
    ],
  })
  const { delay } = useDelayGroup(data.context)

  const context = data.context

  const hover = useHover(context, {
    move: false,
    enabled: disabled ? false : controlledOpen == null,
    delay,
  })
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      refs: {
        ...data.refs,
        arrow: arrowRef,
      },
    }),
    [open, setOpen, interactions, data]
  )
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = createContext<ContextType>(null)

export const useTooltipContext = () => {
  const context = useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  function TooltipTrigger({ children, ...props }, propRef) {
    const context = useTooltipContext()
    const childrenRef = (children as any).ref
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

    if (isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        })
      )
    } else {
      throw new Error('TooltipTrigger requires a valid React element as its child')
    }
  }
)

const TooltipContentPortal = ({ open, children }: { open: boolean; children: ReactElement }) => {
  return (
    <Transition in={open} appear={true} timeout={300} mountOnEnter={true} unmountOnExit={true}>
      <FloatingPortal>{children}</FloatingPortal>
    </Transition>
  )
}

const TooltipContent = forwardRef<ElementRef<'div'>, ComponentPropsWithoutRef<'div'>>(
  ({ className, children, style, ...props }, refProp) => {
    const context = useTooltipContext()
    const ref = useMergeRefs([context.refs.setFloating, refProp])
    const { side, alignment } = getSideAlignment(context.placement)
    const arrow = context.middlewareData.arrow
    const arrowXOffset = arrow && arrow?.x !== null ? `${arrow.x}px` : '50%'
    const arrowYOffset = arrow && arrow?.y !== null ? `${arrow.y}px` : '50%'

    const transformOrigin = {
      top: `${arrowXOffset} calc(100% + ${TOOLTIP_ARROW_HEIGHT}px)`,
      left: `calc(100% + ${TOOLTIP_ARROW_HEIGHT}px) ${arrowYOffset}`,
      right: `-${TOOLTIP_ARROW_HEIGHT}px ${arrowYOffset}`,
      bottom: `${arrowXOffset} -${TOOLTIP_ARROW_HEIGHT}px`,
    }[side]

    return (
      <Transition
        in={context.open}
        appear={true}
        timeout={300}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <FloatingPortal>
          <div
            ref={ref}
            style={{
              zIndex: 100,
              ...context.floatingStyles,
              ...style,
            }}
            {...context.getFloatingProps(props)}
          >
            <div
              data-side={side}
              data-alignment={alignment || 'center'}
              data-state={context.open ? 'open' : 'closed'}
              className={cn(
                // Layout
                'type-caption pointer-events-none relative max-w-[300px] rounded-1 bg-neutral-300 px-4 py-2 text-text-strong shadow-elev-0 outline outline-1 -outline-offset-1 outline-neutral-400 dark:bg-neutral-400 dark:outline-neutral-500',
                // Animations
                'duration-100 animate-in fade-in-0 zoom-in-75 fill-mode-forwards data-[state=closed]:duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-75',
                className
              )}
              {...props}
              style={{ transformOrigin: transformOrigin }}
            >
              {children}
              <TooltipArrow
                ref={context.refs.arrow as any}
                context={context.context}
                data-arrow=""
              />
            </div>
          </div>
        </FloatingPortal>
      </Transition>
    )
  }
)
TooltipContent.displayName = 'TooltipContent'

type TooltipProps = {
  children: ReactElement
  content: ReactNode
} & TooltipOptions
type TooltipRef = ForwardedRef<HTMLDivElement>

const TooltipBase = (
  {
    children,
    content,
    initialOpen,
    // useTooltip() options
    /**
     * The placement of the tooltip.
     * @default 'top'
     */
    placement,
    /**
     * Controlled open state.
     * @default undefined
     */
    open,
    onOpenChange,
    sideOffset,
    /**
     * Minimum distance between the tooltip and the viewport.
     * @default 12
     */
    collisionPadding,
    /**
     * Minimum inline distance between the arrow and the tooltip content.
     * @default 4
     */
    arrowPadding,
    disabled = false,
    // TooltipContent props
    ...props
  }: TooltipProps,
  ref: TooltipRef
) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip({
    initialOpen,
    placement,
    open,
    onOpenChange,
    sideOffset,
    collisionPadding,
    arrowPadding,
    disabled,
  })
  return (
    <TooltipContext.Provider value={tooltip}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContentPortal open={tooltip.open}>
        <TooltipContent ref={ref} {...props}>
          {content}
        </TooltipContent>
      </TooltipContentPortal>
    </TooltipContext.Provider>
  )
}

export const Tooltip = memo(forwardRef(TooltipBase))
Tooltip.displayName = 'Tooltip'

export const TooltipProvider = memo(({ children }: { children: ReactNode }) => (
  <FloatingDelayGroup delay={{ open: 500, close: 0 }} timeoutMs={400}>
    {children}
  </FloatingDelayGroup>
))
