import Loading from '@common/layout/loading/Loading'
import { useAuth } from '@hooks/useAuth'
import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Activate = () => {
  const navigate = useNavigate()
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  if (token && localStorage !== null) {
    localStorage.setItem('CleanlabSignupToken', token)
  }
  if (isLoading) {
    return <Loading />
  } else if (!isAuthenticated) {
    void loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })
  }

  navigate('/')
  return null
}

export default Activate
