import { useColorModeValue } from '@chakra-ui/react'
import { cn } from '@utils/tailwindUtils'

import { getIssueStacks } from './IssuesTable.helpers'
import { IssuesTableProps } from './IssuesTable.types'

const IssuesTable = (props: IssuesTableProps) => {
  const { resolverTextColor, selectedRowData, givenLabel, isMultiClass } = props
  const tooltipBg = useColorModeValue('gray.100', 'grayDarkModeDeprecated.100')
  const tooltipColor = useColorModeValue('gray.800', 'grayDarkModeDeprecated.800')

  const issuesStacks = getIssueStacks(selectedRowData, resolverTextColor, tooltipBg, tooltipColor)

  const className = cn(
    'h-4rem min-h-4rem flex w-full items-start overflow-x-auto border-b border-border-1 p-5',
    issuesStacks.length < 1 && 'flex-col gap-4'
  )

  const givenLabelClassName = cn('flex flex-col gap-3', issuesStacks.length < 1 && 'flex-row')

  const noIssuesClassName = cn('type-caption-semibold pl-6', issuesStacks.length < 1 && 'pl-0')

  return (
    <div className={className}>
      {issuesStacks.length > 0 && <div className="flex">{issuesStacks}</div>}
      {isMultiClass && (
        <div className={givenLabelClassName}>
          <p className="type-caption-semibold whitespace-nowrap">Given label:</p>
          <p className="type-caption line-clamp-2 break-all">
            {givenLabel !== '' ? givenLabel : 'No given label'}
          </p>
        </div>
      )}
      {issuesStacks.length < 1 && <p className={noIssuesClassName}>No issues for this datapoint</p>}
    </div>
  )
}
export default IssuesTable
