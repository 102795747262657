import { BarDatum, ComputedDatum } from '@nivo/bar'
import { ComputedCell, HeatMapDatum } from '@nivo/heatmap'

import { ActionType } from '../cleanset/commandpalette/CommandPalette.types'
import { CLEANLAB_FRONTEND_COLUMN } from '../cleanset/datasheet/Datasheet.types'
import { getHeaderName } from '../cleanset/filterMenu/FilterMenu.helpers'
import {
  CleansetFilterActions,
  FilterActionType,
  FilterData,
  FilterOperator,
} from '../cleanset/filterReducer/FilterState.types'

const suggestedLabelIssuesOnClickFn = (
  bar: ComputedDatum<BarDatum>,
  dispatch: React.Dispatch<FilterActionType>
): void => {
  const { indexValue, id } = bar
  const givenLabel = indexValue
  const barLabel = id
  const givenFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.GIVEN, true),
    filterType: FilterOperator.Equals,
    filterInput: givenLabel,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  const issueFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.ISSUE,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.ISSUE, false),
    filterType: FilterOperator.Equals,
    filterInput: (barLabel === 'issues').toString(),
    agGridType: '',
    isOpen: false,
    isPreset: true,
    isChecked: barLabel === 'issues',
    hide: true,
  }

  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [givenFilter, issueFilter],
  })
}

const sourcesWithMostIssuesOnClickFn = (
  bar: ComputedDatum<BarDatum>,
  column: string,
  dispatch: React.Dispatch<FilterActionType>
) => {
  const columnFilter: FilterData = {
    property: column,
    headerName: column,
    filterType: FilterOperator.Equals,
    filterInput: bar.data.label,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }

  const barId = `${bar.id}`
  const filterTitles = idToFilterData(barId)

  const issueFilter: FilterData = {
    property: filterTitles.property,
    headerName: filterTitles.headerName,
    filterType: FilterOperator.Equals,
    filterInput: 'true',
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }

  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [columnFilter, issueFilter],
  })
}

const sourcesWithLabeledDataOnClickFn = (
  bar: ComputedDatum<BarDatum>,
  column: string,
  dispatch: React.Dispatch<FilterActionType>,
  issueColumn: CLEANLAB_FRONTEND_COLUMN
) => {
  const columnFilter: FilterData = {
    property: column,
    headerName: column,
    filterType: FilterOperator.Equals,
    filterInput: bar.data.label,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }

  const unlabeledFilter: FilterData = {
    property: issueColumn,
    headerName: issueColumn === CLEANLAB_FRONTEND_COLUMN.UNLABELED ? 'Unlabeled' : 'Well Labeled',
    filterType: FilterOperator.Equals,
    filterInput: 'true',
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }

  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [columnFilter, unlabeledFilter],
  })
}

const topSuggestedCorrectionsOnClickFn = (
  bar: ComputedDatum<BarDatum>,
  dispatch: React.Dispatch<FilterActionType>
): void => {
  const { data } = bar
  const givenLabel = data.given
  const suggestedLabel = data.suggested
  const givenFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.GIVEN, true),
    filterType: FilterOperator.Equals,
    filterInput: givenLabel,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  const suggestedFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.SUGGESTED, false),
    filterType: FilterOperator.Equals,
    filterInput: suggestedLabel,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [givenFilter, suggestedFilter],
  })
}

const suggestionsHeatmapOnClickFn = (
  square: ComputedCell<HeatMapDatum>,
  dispatch: React.Dispatch<FilterActionType>
): void => {
  const { data, serieId } = square
  // do nothing if square has zero items
  if (!data.y || data.y === 0) {
    return
  }
  const givenLabel = serieId
  const suggestedLabel = data.x
  const givenFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.GIVEN, true),
    filterType: FilterOperator.Equals,
    filterInput: givenLabel,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  const suggestedFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.SUGGESTED,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.SUGGESTED, false),
    filterType: FilterOperator.Equals,
    filterInput: suggestedLabel,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [givenFilter, suggestedFilter],
  })
}

const correctedLabelIssuesOnClickFn = (
  bar: ComputedDatum<BarDatum>,
  dispatch: React.Dispatch<FilterActionType>
): void => {
  const { indexValue, id } = bar
  const givenLabel = indexValue
  const correctionOrExclusion = id
  const givenFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.GIVEN, true),
    filterType: FilterOperator.Equals,
    filterInput: givenLabel,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  const correctedFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.CORRECTED, false),
    filterType: FilterOperator.NotEquals,
    filterInput: '-',
    agGridType: 'agTextColumnFilter',
    isOpen: false,
    isPreset: false,
  }
  const actionFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.ACTION,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.ACTION, false),
    filterType: FilterOperator.Equals,
    filterInput: ActionType.EXCLUDE,
    agGridType: 'agTextColumnFilter',
    isOpen: false,
    isPreset: false,
  }

  const secondFilter = correctionOrExclusion === 'corrections' ? correctedFilter : actionFilter
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [givenFilter, secondFilter],
  })
}

const topCorrectionsOnClickFn = (
  bar: ComputedDatum<BarDatum>,
  dispatch: React.Dispatch<FilterActionType>
): void => {
  const { data } = bar
  const givenLabel = data.given
  const correctedLabel = data.suggested
  const givenFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.GIVEN, true),
    filterType: FilterOperator.Equals,
    filterInput: givenLabel,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  const correctedFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.CORRECTED, false),
    filterType: FilterOperator.Equals,
    filterInput: correctedLabel,
    agGridType: 'agTextColumnFilter',
    isOpen: false,
    isPreset: false,
  }
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [givenFilter, correctedFilter],
  })
}

const correctionsHeatmapOnClickFn = (
  square: ComputedCell<HeatMapDatum>,
  dispatch: React.Dispatch<FilterActionType>
): void => {
  const { data, serieId } = square
  // do nothing if square has zero items
  if (!data.y || data.y === 0) {
    return
  }
  const givenLabel = serieId
  const correctedLabel = data.x
  const givenFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.GIVEN,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.GIVEN, true),
    filterType: FilterOperator.Equals,
    filterInput: givenLabel,
    agGridType: '',
    isOpen: false,
    isPreset: true,
  }
  const correctedFilter: FilterData = {
    property: CLEANLAB_FRONTEND_COLUMN.CORRECTED,
    headerName: getHeaderName(CLEANLAB_FRONTEND_COLUMN.CORRECTED, false),
    filterType: FilterOperator.Equals,
    filterInput: correctedLabel,
    agGridType: 'agTextColumnFilter',
    isOpen: false,
    isPreset: false,
  }
  dispatch({
    type: CleansetFilterActions.CLEAR_AND_APPLY_MULTIPLE_FILTERS,
    filterData: [givenFilter, correctedFilter],
  })
}

const idToFilterData = (barId: string): { property: string; headerName: string } => {
  switch (barId) {
    case 'outlier': {
      return { property: CLEANLAB_FRONTEND_COLUMN.OUTLIER, headerName: 'Outlier' }
    }
    case 'ambiguous': {
      return { property: CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS, headerName: 'Ambiguous' }
    }
    case 'label issue': {
      return { property: CLEANLAB_FRONTEND_COLUMN.ISSUE, headerName: 'Issue' }
    }
    case 'near duplicate': {
      return { property: CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE, headerName: 'Near Duplicate' }
    }
    case 'unlabeled': {
      return { property: CLEANLAB_FRONTEND_COLUMN.UNLABELED, headerName: 'Near Duplicate' }
    }
    default: {
      return { property: '', headerName: '' }
    }
  }
}

export {
  correctedLabelIssuesOnClickFn,
  correctionsHeatmapOnClickFn,
  sourcesWithLabeledDataOnClickFn,
  sourcesWithMostIssuesOnClickFn,
  suggestedLabelIssuesOnClickFn,
  suggestionsHeatmapOnClickFn,
  topCorrectionsOnClickFn,
  topSuggestedCorrectionsOnClickFn,
}
