import {
  Grid,
  GridItem,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { BasicProjectRowRes, EnrichmentProjectRowRes } from '@services/project/constants'

import { ProjectDetailsModalProps } from './ProjectDetailsModal.types'

const enrichmentDetailColumns: {
  header: string
  accessor: keyof EnrichmentProjectRowRes
}[] = [
  {
    header: 'ID',
    accessor: 'id',
  },
  {
    header: 'Dataset ID',
    accessor: 'dataset_id',
  },
  {
    header: 'Number of rows',
    accessor: 'num_rows',
  },
  {
    header: 'Created',
    accessor: 'created_at',
  },
  {
    header: 'Last Updated',
    accessor: 'updated_at',
  },
]

const curationDetailColumns: {
  header: string
  accessor: keyof BasicProjectRowRes
}[] = [
  {
    header: 'Modality',
    accessor: 'modality',
  },
  {
    header: 'Model type',
    accessor: 'model_type',
  },
  {
    header: 'ID column',
    accessor: 'id_column',
  },
  {
    header: 'Label column',
    accessor: 'label_column',
  },
]

const ProjectGridItem = ({ header, data = 'n/a' }: { header: string; data?: string }) => (
  <GridItem rowSpan={1} colSpan={1}>
    <VStack align="flex-start">
      <Text fontWeight={600} fontSize="md">
        {header}
      </Text>
      <Text fontSize="md" wordBreak="break-word">
        {data}
      </Text>
    </VStack>
  </GridItem>
)

const ProjectDetailsModal = (props: ProjectDetailsModalProps) => {
  const { isOpen, onClose, rowData } = props
  const { isEnrichment } = rowData

  return (
    rowData && (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Project: {rowData.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 1fr)" gap={4}>
              {(isEnrichment ? enrichmentDetailColumns : curationDetailColumns).map((col) => (
                <ProjectGridItem
                  key={col.header}
                  header={col.header}
                  data={
                    rowData[
                      col.accessor as keyof (EnrichmentProjectRowRes | BasicProjectRowRes)
                    ] as string
                  }
                />
              ))}

              {rowData.predictive_columns?.length > 0 && (
                <VStack align="flex-start" mt={6}>
                  <Text fontWeight={600} fontSize="md">
                    Predictive columns
                  </Text>
                  <VStack maxHeight="25vh" overflowY="auto" width="100%" align="flex-start">
                    <UnorderedList>
                      {rowData.predictive_columns.map((c) => (
                        <ListItem key={c} fontSize="md">
                          {c}
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </VStack>
                </VStack>
              )}
            </Grid>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    )
  )
}

export default ProjectDetailsModal
