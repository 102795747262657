import { useEffect, useState } from 'react'

const useKeyPressState = (targetKey: string, preventDefault = false, override = false) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false)

  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    const downHandler = (evt: KeyboardEvent) => {
      if (override) return
      else if (evt.key === targetKey) {
        if (preventDefault) {
          evt.preventDefault()
        }
        setKeyPressed(true)
      }
    }

    // If released key is our target key then set to false
    const upHandler = (evt: KeyboardEvent) => {
      if (evt.key === targetKey) {
        setKeyPressed(false)
      }
    }

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [targetKey, preventDefault, override]) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}

export default useKeyPressState
