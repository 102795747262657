import VpcGridContextMenu from '@common/vpcGridContextMenu/VpcGridContextMenu'
import { Badge } from '@components/badge/Badge'
import { AccessLevel, AccountStatus } from '@services/user/constants'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community'

export const generateGridOptions = (vpcGridType: AccountStatus): GridOptions => {
  return {
    domLayout: 'autoHeight',
    rowHeight: 40,
    columnDefs: getColumnDefs(vpcGridType),
    paginationPageSize: 10,
    onGridReady: (params) => {
      params.api.sizeColumnsToFit()
    },
    defaultColDef: {
      filter: false,
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
  }
}

const getColumnDefs = (vpcGridType: AccountStatus): ColDef[] => [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    hide: vpcGridType === AccountStatus.INVITED,
    flex: 1,
    valueFormatter: (params) => {
      return params.value ?? '--'
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    flex: 1,
  },
  {
    field: 'accessLevel',
    headerName: 'Role',
    sortable: true,
    valueFormatter: (params) => {
      return params.value === AccessLevel.ORGANIZATION_ADMIN ? 'Admin' : 'Member'
    },
    flex: 1,
  },
  {
    field: 'accountStatus',
    headerName: 'Status',
    flex: 1,
    cellStyle: { display: 'flex', alignItems: 'center' },
    cellRenderer: (params: ICellRendererParams) => {
      const accountStatus =
        (params.data.accountStatus === AccountStatus.INVITED
          ? 'Pending'
          : params.data.accountStatus) ?? ''
      return (
        <Badge
          variant="outline"
          hue={getHueFromStatus(accountStatus)}
          round="full"
          className="w-min"
        >
          {accountStatus.charAt(0).toUpperCase() + accountStatus.slice(1)}
        </Badge>
      )
    },
  },
  {
    field: 'apiKeyTimestamp',
    headerName: 'Created',
    sortable: true,
    flex: 1,
    valueFormatter: (params) => {
      return params.value ? timestampToDate(params.value as number) : '--'
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Deactivated',
    flex: 1,
    hide: vpcGridType !== AccountStatus.DEACTIVATED,
    sortable: true,
    valueFormatter: (params) => {
      return params.value ? timestampToDate(params.value as number) : '--'
    },
  },
  {
    field: 'rowActions',
    headerName: '',
    maxWidth: 80,
    flex: 1,
    cellStyle: { display: 'flex', justifyContent: 'flex-end' },
    cellRenderer: (params: ICellRendererParams) => {
      return <VpcGridContextMenu params={params} />
    },
  },
  {
    field: 'id',
    hide: true,
  },
]

const getHueFromStatus = (status: AccountStatus) => {
  switch (status) {
    case AccountStatus.ACTIVE:
      return 'green'
    case AccountStatus.DEACTIVATED:
      return 'neutral'
    case AccountStatus.BLOCKED:
      return 'red'
    default:
      return 'blue'
  }
}
