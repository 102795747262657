import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import Link from '@components/link/Link'
import { useAuth } from '@hooks/useAuth'
import { useCleanlabLocation } from '@hooks/useCleanlabLocation'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useConfirmSubscriptionUpgrade } from '@services/billing/mutations'
import { useCardDetails, useSubscriptionDetails } from '@services/billing/queries'
import { useMyself } from '@services/user/queries'
import { useState } from 'react'
import { FiCheckCircle } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import PaymentSetup from 'src/pages/account/payment/paymentSetup/PaymentSetup'

import PlanInfo from '../planInfo/PlanInfo'
import { getUpgradePlanModalTitle } from './UpgradePlanModal.helpers'
import { UpgradePlanModalProps } from './UpgradePlanModal.types'

const UpgradePlanModal = (props: UpgradePlanModalProps) => {
  const { isOpen, onClose } = props

  const {
    isEnterprisePlan,
    freeTrialPeriod,
    setShowCelebration,
    isIndividualPayingCustomer,
    isTlmPlan,
  } = useSubscription()
  const { activated: isActivated, emailConfirmed } = useMyself()
  const subscriptionDetails = useSubscriptionDetails({ enabled: isActivated && emailConfirmed })
  const { isAuthenticated } = useAuth()
  const { trackEvent } = useEventTracking()
  const cardDetails = useCardDetails(isAuthenticated)
  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')
  const navigate = useNavigate()
  const path = useCleanlabLocation()

  const [collectCardDetails, setCollectCardDetails] = useState<boolean>(false)
  const [upgradeComplete, setUpgradeComplete] = useState<boolean>(false)
  const [stripeLoading, setStripeLoading] = useState<boolean>(false)

  const { mutate: confirmSubscriptionUpgrade, isLoading: isUpgradeLoading } =
    useConfirmSubscriptionUpgrade({
      onSuccess: () => {
        setUpgradeComplete(true)
        setShowCelebration(true)
      },
    })

  const onUpgradeSubscription = () => {
    if (!Object.keys(cardDetails).length) {
      setCollectCardDetails(true)
    } else if (upgradeComplete) {
      onClose()
      setShowCelebration(false)
      setUpgradeComplete(false)
    } else {
      confirmSubscriptionUpgrade()
      trackEvent(MixpanelEvents.clickUpgradePlanButton, { path: path, ...subscriptionDetails })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setCollectCardDetails(false)
        setUpgradeComplete(false)
        onClose()
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg={modalBg} maxW="70vw">
        <ModalHeader>
          {getUpgradePlanModalTitle(
            isIndividualPayingCustomer,
            upgradeComplete,
            isEnterprisePlan,
            freeTrialPeriod
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody w="100%" fontSize="sm">
          {!upgradeComplete ? (
            <div className="flex items-start gap-9">
              <div className="min-w-fit">
                <PlanInfo />
              </div>
              <Center w="100%" h="100%" pointerEvents={isUpgradeLoading ? 'none' : 'auto'}>
                <PaymentSetup
                  collectCardDetails={collectCardDetails}
                  setCollectCardDetails={setCollectCardDetails}
                  onCardAdded={confirmSubscriptionUpgrade}
                  setStripeLoading={setStripeLoading}
                />
              </Center>
            </div>
          ) : (
            <Stack>
              <VStack textAlign="center" fontSize="md" mt={6} mx={40}>
                <Center borderRadius={50} w="80px" h="80px" bgColor="green">
                  <FiCheckCircle color="white" size="35%" />
                </Center>
                <Text fontSize="4xl">Upgrade successful!</Text>

                <Text py={4}>
                  {!isTlmPlan &&
                    'We’re happy to have you here. You will be charged for your plan and any excess usage at the end of your billing cycle. '}
                  You may find your plan’s current usage and limits in the Usage & Billing tab of
                  your Account page, or by clicking the link below.
                </Text>
                <Link
                  onClick={() => {
                    onClose()
                    navigate('/account?tab=Usage%26Billing')
                  }}
                >
                  View plan details
                </Link>
              </VStack>
            </Stack>
          )}
        </ModalBody>
        {!collectCardDetails && (
          <ModalFooter>
            {isIndividualPayingCustomer && !upgradeComplete ? (
              <Button
                colorScheme="teal"
                onClick={() => window.open('mailto:sales@cleanlab.ai?subject=Plan Upgrade')}
              >
                Contact us
              </Button>
            ) : (
              <PrimaryButton
                height="36px"
                onClick={() => {
                  onUpgradeSubscription()
                }}
                isLoading={isUpgradeLoading || stripeLoading}
                isDisabled={isUpgradeLoading || stripeLoading}
              >
                {upgradeComplete
                  ? 'Close'
                  : Object.keys(cardDetails).length && !collectCardDetails
                    ? 'Upgrade'
                    : 'Add Card'}{' '}
              </PrimaryButton>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default UpgradePlanModal
