// An abstraction layer over Auth0 auth (used in SaaS) and OAuth 2 / OpenID
// Connect (used in VPC)

import { AUTH_MODE_OAUTH } from '@utils/environmentVariables'

import {
  // eslint-disable-next-line no-restricted-syntax
  auth0,
  Auth0ProviderWithHistory,
} from './auth0ProviderWithHistory/Auth0ProviderWithHistory'
import { oauth, OAuthProviderWithHistory } from './OAuthProviderWithHistory'

// XXX this helper function seems misplaced here, but there's a lot of code
// relying on it that would need to be refactored to import it from elsewhere
export const createAuthHeaders = (
  accessToken: string
): { headers: { [key: string]: number | string | boolean } } => {
  return {
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  }
}

export interface TAuth {
  getTokenSilently: () => Promise<string>
}

// eslint-disable-next-line no-restricted-syntax
export const auth: TAuth = AUTH_MODE_OAUTH ? oauth : auth0

export const AuthProviderWithHistory = AUTH_MODE_OAUTH
  ? OAuthProviderWithHistory
  : Auth0ProviderWithHistory
