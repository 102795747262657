import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
// import logger from '../util/logger'

const queryApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/query`,
  withCredentials: true,
})

interface QueryParamsType {
  resource: string
  cleanset_id?: string
  id?: string
  name?: string
  email?: string
  user_id?: string
  project_id?: string
  dataset_id?: string
  session_id?: string
  request_id?: string
  task_id?: string
  experimental_dataset_id?: string
  experimental_job_id?: string
  start_date?: string
  end_date?: string
  group_by?: string
  task_type?: string
}

const query = async (queryParams: QueryParamsType) => {
  const accessToken = await auth.getTokenSilently()
  return await queryApi.get('/', { params: queryParams, ...createAuthHeaders(accessToken) })
}

const apiMethods = {
  query,
}

export default apiMethods
