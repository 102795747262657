import { useToast } from '@chakra-ui/react'
import CleanlabGrid from '@common/grid/CleanlabGrid'
import useGridClassname from '@hooks/useGridClassname'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import datasetApiService, { DatasetExamplesProps } from '@services/datasetApi'
import { MAX_NUM_DEFAULT_COLUMNS } from '@utils/ag-grid/consts'
import { isUUID4 } from '@utils/functions/isUUID4'
import { cn } from '@utils/tailwindUtils'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'

import { DatasetSampleProps } from './DatasetSample.types'

const DatasetSample = ({ datasetId, numRows }: DatasetSampleProps) => {
  const [datasetExamples, setDatasetExamples] = useState<DatasetExamplesProps | null>(null)
  const toast = useToast()

  useEffect(() => {
    const fetchDatasetExamples = async (datasetId: string) => {
      try {
        setDatasetExamples(await datasetApiService.getDatasetExamples(datasetId, numRows))
      } catch (err) {
        notifyAxiosError(toast, err as AxiosError, { title: 'Dataset example data fetch failed' })
      }
    }

    if (datasetId && isUUID4(datasetId)) {
      fetchDatasetExamples(datasetId)
    }
  }, [toast, datasetId, setDatasetExamples, numRows])

  if (!datasetExamples) return null

  return (
    <DatasetSampleGrid
      className="h-[200px] w-full"
      rows={datasetExamples?.rows ?? (datasetExamples as any)?.data_rows}
      columns={datasetExamples?.columns}
    />
  )
}

export const DatasetSampleGrid = ({
  className,
  rows,
  columns,
}: {
  className?: string
  rows: Record<string, any>[]
  columns: string[]
}) => {
  const gridClassName = useGridClassname()
  const gridOptions = {
    columnDefs: columns.map((column, index) => ({
      field: column,
      headerName: column,
      hide: index > MAX_NUM_DEFAULT_COLUMNS,
    })),
    rowData: rows,
    defaultColDef: {
      filter: false,
      sortable: false,
      resizable: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,
      flex: 1,
      minWidth: 100,
    },
    suppressFieldDotNotation: true,
    suppressColumnVirtualisation: true,
    animateRows: true,
    onGridReady: (event: GridReadyEvent) => {
      setTimeout(() => {
        event.columnApi.autoSizeAllColumns(false)
      }, 400)
    },
  }

  return (
    <div className={cn(gridClassName, className)}>
      <CleanlabGrid gridOptions={gridOptions} />
    </div>
  )
}

export default DatasetSample
