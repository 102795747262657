import { Button } from '@components/button/Button'
import SecondaryButtonWithIcon from '@components/buttons/secondaryButtonWithIcon/SecondaryButtonWithIcon'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useRef } from 'react'
import { FiX } from 'react-icons/fi'

import { CleansetFilterActions } from '../filterReducer/FilterState.types'
import { useFilters } from '../filterReducer/useFilters'
import AddNewFilterButton from './filterButton/AddNewFilterButton'
import FilterButton from './filterButton/FilterButton'
import { FilterMenuProps } from './FilterMenu.types'

const FilterMenu = (props: FilterMenuProps) => {
  const {
    gridOptions,
    handleClearFilters,
    cleansetId,
    isMultilabel,
    labelsIndexed,
    firstGridDataRendered,
    resetResolverAndRowSelection,
    sortColumn,
    onOpenResolver,
  } = props

  const { state, dispatch } = useFilters()

  const { trackEvent } = useEventTracking()

  const ref = useRef<HTMLDivElement | null>(null)

  const { filterData } = state
  const displayedFilterData = filterData.filter((f) => !f.hide)
  const appliedFilters = filterData.filter(
    (f) =>
      (Array.isArray(f.filterInput) && f.filterInput.length) ||
      (typeof f.filterInput === 'string' && f.filterInput !== '')
  )
  return (
    <div ref={ref} className="flex w-full gap-4 py-3 [&>*]:shrink-0">
      {/* Clear filters button */}
      <SecondaryButtonWithIcon
        height="32px"
        rightIcon={<FiX size="16px" />}
        onClick={() => {
          dispatch({ type: CleansetFilterActions.CLEAR_ALL_FILTERS })
          sortColumn?.setSort(undefined) // reset preset config column's sort
          handleClearFilters()
          trackEvent(MixpanelEvents.clickClearAllFiltersButton, { ...state })
        }}
        isDisabled={!appliedFilters.length}
      >
        Clear
      </SecondaryButtonWithIcon>
      {displayedFilterData.length ? (
        displayedFilterData.map((filter) => (
          <FilterButton
            key={filter.property}
            filterData={filter}
            gridOptions={gridOptions}
            cleansetId={cleansetId}
            isMultilabel={isMultilabel}
            labelsIndexed={labelsIndexed}
            firstGridDataRendered={firstGridDataRendered}
            resetResolverAndRowSelection={resetResolverAndRowSelection}
          />
        ))
      ) : (
        <></>
      )}
      {/* Add new filter */}
      <AddNewFilterButton
        gridOptions={gridOptions}
        isMultilabel={isMultilabel}
        cleansetId={cleansetId}
        resetResolverAndRowSelection={resetResolverAndRowSelection}
      />
      {onOpenResolver && (
        <div className="flex grow items-start justify-end">
          <Button onClick={onOpenResolver} size="small" variant="highContrast">
            Open resolver
          </Button>
        </div>
      )}
    </div>
  )
}

export default FilterMenu
