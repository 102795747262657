export const getProgressColorScheme = (max: number, value: number, overageActive: boolean) => {
  const percentage = (value / max) * 100
  if (percentage > 99 || overageActive) {
    return 'red'
  } else if (percentage >= 80) {
    return 'orange'
  } else {
    return 'teal'
  }
}

export const getPlanText = (
  freeTrialPeriod: boolean,
  enterpriseSubscriptionActive: boolean,
  planName: string
) => {
  if (freeTrialPeriod) {
    return 'Free Trial Plan'
  } else if (enterpriseSubscriptionActive) {
    return planName
  } else {
    return planName + ' monthly subscription'
  }
}

export const getPlanPriceText = (
  freeTrialPeriod: boolean,
  enterpriseSubscriptionActive: boolean,
  pricePerMonth: number
) => {
  if (enterpriseSubscriptionActive) {
    return 'External Billing'
  } else if (freeTrialPeriod) {
    return 'Free'
  } else {
    return `$${pricePerMonth.toFixed(2)}`
  }
}

export const getTotalThisMonth = (
  freeTrialPeriod: boolean,
  amountDue: number,
  pricePerMonth: number
) => {
  if (freeTrialPeriod) {
    return 'Free'
  } else if (amountDue) {
    return `$${(amountDue / 100).toFixed(2)}`
  } else {
    return `$${pricePerMonth.toFixed(2)}`
  }
}
