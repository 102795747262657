import {
  OAUTH_AUTHORIZATION_ENDPOINT,
  OAUTH_CLIENT_ID,
  OAUTH_SCOPE,
  OAUTH_TOKEN_ENDPOINT,
} from '@utils/environmentVariables'
import React, { PropsWithChildren } from 'react'
import { AuthProvider, TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce'
import { useNavigate } from 'react-router-dom'

const authConfig: TAuthConfig = {
  clientId: OAUTH_CLIENT_ID,
  authorizationEndpoint: OAUTH_AUTHORIZATION_ENDPOINT,
  tokenEndpoint: OAUTH_TOKEN_ENDPOINT,
  redirectUri: window.location.origin,
  scope: OAUTH_SCOPE,
  autoLogin: false,
  storage: 'local',
  storageKeyPrefix: `@@rocp@@_${OAUTH_CLIENT_ID}_`,
  clearURL: false, // so we can make use of state query parameter
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => {
    // XXX do we want a more elegant way of doing this, with nice UI?
    window.confirm('Session expired. Refresh page to continue using the site?') && event.login()
  },
}

export const OAuthProviderWithHistory: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()

  const postLogin = () => {
    // coordinates with how useOAuth's loginWithRedirect stores state in sessionStorage
    const urlParams = new URLSearchParams(window.location.search)
    const state = urlParams.get('state')
    const returnTo = state
      ? JSON.parse(sessionStorage.getItem(`@@rocp@@_returnTo_${state}`) ?? 'null')
      : null
    // if returnTo is not set, the following will still clear the query parameters
    navigate(returnTo ?? window.location.pathname)
  }

  return <AuthProvider authConfig={{ ...authConfig, postLogin }}>{children}</AuthProvider>
}

export interface TOAuth {
  getTokenSilently: () => Promise<string>
}

// XXX to match Auth0
//
// Once we remove the global auth0 object (and all of its uses), this can be
// removed entirely.
export const oauth: TOAuth = {
  getTokenSilently: async () => {
    // XXX Here, we don't match Auth0's behavior of auto-logging-in if we're
    // not already logged in. This is okay because all uses of this are
    // protected inside a <AuthenticationGuard /> component.
    //
    // We don't match this behavior because it's tricky with this not being
    // able to use the AuthContext provided by AuthProvider to access login().

    // XXX relying on internals of react-oauth2-code-pkce
    const tokenJSON = localStorage.getItem(`${authConfig.storageKeyPrefix}token`)
    if (!tokenJSON) {
      throw new Error('not logged in')
    }
    return JSON.parse(tokenJSON)
  },
}
