import { Tag, Text } from '@chakra-ui/react'
import { TagToColorScheme } from '@common/ColorSchemes.styles'
import MaxWidthText from '@common/misc/maxWidthText/MaxWidthText'
import { Checkbox } from '@components/checkbox/Checkbox'
import { LabelInline } from '@components/label/LabelInline'

import { FilterCheckboxButtonProps } from './FilterCheckboxButton.types'

const FilterCheckboxButton = (props: FilterCheckboxButtonProps) => {
  const { filterInput, text, applyCheckboxFilter, filterChecked, isDisabled, isTag } = props

  return (
    <LabelInline
      variant={isTag ? 'block' : 'textDefault'}
      content={
        isTag ? (
          <MaxWidthText maxWidth="200px" showLabel={false}>
            <Tag colorScheme={TagToColorScheme[text]} fontSize="1rem" size="md">
              {text.replace('_', ' ')}
            </Tag>
          </MaxWidthText>
        ) : (
          <Text fontSize="1rem" size="md">
            {text}
          </Text>
        )
      }
    >
      <Checkbox
        value={text}
        checked={filterChecked}
        disabled={isDisabled}
        onCheckedChange={() => applyCheckboxFilter(filterInput, !filterChecked)}
      />
    </LabelInline>
  )
}

export default FilterCheckboxButton
