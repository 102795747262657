import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import Loading from '@common/layout/loading/Loading'
import { usePaymentHistory } from '@services/billing/queries'
import { centsToDollarsUSD } from '@utils/functions/centsToDollarsUSD'
import { timestampToDate } from '@utils/functions/timestampToDate'
import React, { useState } from 'react'

import { PaymentData } from './PaymentHistory.types'

const PaymentHistory = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const recordsPerPage = 10

  const paymentHistory = usePaymentHistory()

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const nextPage = () => {
    if (currentPage !== Math.ceil(paymentHistory.length / recordsPerPage)) {
      setCurrentPage(currentPage + 1)
    }
  }

  if (paymentHistory === null) {
    return <Loading text="Loading payment history" position="relative" />
  }

  return (
    <Stack width="100%">
      <VStack align="flex-start" spacing="1rem">
        <Text>Previous Monthly Payments</Text>
        <TableContainer width="100%" overflowY="auto">
          <Table variant="unstyled">
            <Thead>
              <Tr>
                <Th fontSize="md">Amount Paid</Th>
                <Th fontSize="md">Date</Th>
                <Th fontSize="md">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(paymentHistory as PaymentData[])
                .slice(currentPage * recordsPerPage - recordsPerPage, currentPage * recordsPerPage)
                .map((row, rowIdx) => (
                  <Tr key={rowIdx}>
                    <Td>{centsToDollarsUSD(row.amount)}</Td>
                    <Td>{timestampToDate(row.period_end)}</Td>
                    <Td>
                      <Link isExternal href={row.receiptUrl} fontWeight="semibold">
                        <HStack>
                          <Text>View Receipt</Text>
                          <ExternalLinkIcon />
                        </HStack>
                      </Link>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
      <Box px={{ base: '10px', md: '15px' }} pb="12px">
        {paymentHistory && paymentHistory.length > 0 ? (
          <HStack spacing="3rem" justify="space-between">
            <Text color="muted" fontSize="sm">
              {`Showing ${currentPage * recordsPerPage - recordsPerPage + 1} to ${Math.min(
                currentPage * recordsPerPage,
                paymentHistory.length
              )} of ${paymentHistory.length} results`}
            </Text>
            <ButtonGroup
              spacing="1rem"
              justifyContent="space-between"
              width={{ base: 'full', md: 'auto' }}
              variant="secondary"
            >
              <Button onClick={prevPage}>Previous</Button>
              <Button onClick={nextPage}>Next</Button>
            </ButtonGroup>
          </HStack>
        ) : (
          <Center>
            <Text fontSize="sm">Showing 0 results</Text>
          </Center>
        )}
      </Box>
    </Stack>
  )
}

export default PaymentHistory
