'use client'

import { useFormField } from '@components/formField/FormFieldContext'
import { IconCheck, IconCopy, IconEye, IconEyeOff, IconSearch } from '@components/icons'
import { Tooltip } from '@components/tooltip/Tooltip'
import { mergeRefs } from '@react-aria/utils'
import { cn, tv } from '@utils/tailwindUtils'
import { isNil } from 'lodash'
import {
  ChangeEventHandler,
  cloneElement,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { useClipboard } from '../../hooks/useClipboard'
import { ButtonInput } from './ButtonInput'
import { ClearButton } from './ClearButton'

type InputSize = 'small' | 'medium' | 'large'
type InputVariant = 'default' | 'title' | 'monospace'

const HIDE_LABEL = 'Hide'
const SHOW_LABEL = 'Show'

export type InputRootProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  /**
   * The visual size of the input (to set the `size` attribute of the input element, use `htmlSize` prop instead)
   */
  size?: InputSize
  /**
   * Use to set the `size` attribute of underlying `<input>` html element
   */
  htmlSize?: React.InputHTMLAttributes<HTMLInputElement>['size']
  error?: boolean | string
  variant?: InputVariant
  contentStart?: React.ReactNode
  contentEnd?: React.ReactNode
}

export type InputProps = Omit<InputRootProps, 'contentStart' | 'onCopy'> & {
  iconStart?: React.ReactNode
  /**
   * Show a clear button when the input is not empty
   */
  canClear?: boolean
  /**
   * Show a copy button that automatically copies the input value to the clipboard
   */
  canCopy?: boolean
  /**
   * Callback when the copy button is clicked
   * @param value The input value
   * @default undefined
   */
  onClickCopy?: (value?: string) => void
  /**
   * Show a button that toggles the visibility of the input value
   * @default undefined
   */
  canHide?: boolean
  /**
   * Whether the input value is hidden by default
   */
  defaultHidden?: boolean
}

const inputVariants = tv({
  slots: {
    base: 'flex w-full items-center overflow-hidden bg-surface-0 text-text-strong shadow-inner outline outline-1 -outline-offset-1 outline-border-1 invalid:ring-red-100 focus-within:outline-blue-700 focus-within:ring focus-within:ring-focus',
    input:
      'relative z-0 -mx-[100px] flex-grow bg-transparent px-[100px] text-text-strong placeholder:text-text-disabled focus:outline-none focus-visible:ring-0',
  },

  variants: {
    variant: {
      default: {
        base: 'type-body-100 rounded-2',
        input: 'type-body-100',
      },
      monospace: {
        base: 'type-code-100 rounded-2',
        input: 'type-code-100',
      },
      title: {
        base: 'rounded-1 px-4 py-3',
        input: 'type-display-50 -my-[10px]',
      },
    },
    mono: {},
    size: {
      small: { base: 'min-h-[32px] px-4', input: 'py-3' },
      medium: { base: 'min-h-[36px] px-5', input: 'py-4' },
      large: { base: 'min-h-[40px] px-5', input: 'py-[10px]' },
    },
    error: {
      true: {
        base: '[&:not(:focus-within)]:outline-red-700 [&:not(:focus-within)]:ring [&:not(:focus-within)]:ring-red-500',
      },
    },
    disabled: {
      true: {
        base: 'cursor-not-allowed bg-surface-disabled text-text-disabled outline-none',
        input: 'text-text-disabled',
      },
      false: { base: 'has-[input:hover]:bg-surface-0-hover' },
    },
  },
})

/**
 * Simulate a native input value setter event
 * https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04
 */
const simulateSetNativeInputValue = (
  input: HTMLInputElement,
  value: string | number | readonly string[]
) => {
  const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
  )?.set
  if (!nativeInputValueSetter) {
    return
  }
  nativeInputValueSetter.call(input, value)
  input.dispatchEvent(new Event('input', { bubbles: true }))
}

const InputRootBase = (
  {
    className,
    type,
    id: idProp,
    size = 'medium',
    htmlSize,
    error: errorProp,
    onChange,
    required: requiredProp,
    disabled: disabledProp,
    variant = 'default',
    value,
    contentStart,
    contentEnd,
    ...props
  }: InputRootProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const formFieldContext = useFormField()
  const id = idProp || formFieldContext?.htmlFor || undefined
  const required =
    requiredProp !== null && requiredProp !== undefined
      ? !!requiredProp
      : !!formFieldContext?.required
  const disabled =
    disabledProp !== null && disabledProp !== undefined
      ? !!disabledProp
      : !!formFieldContext?.disabled

  const error =
    errorProp !== null && errorProp !== undefined ? !!errorProp : !!formFieldContext?.error
  const inputRef = useRef<HTMLInputElement>(null)
  const mergedRef = mergeRefs(ref, inputRef)

  const { base, input } = inputVariants({
    size: size,
    error: !!error,
    disabled: !!disabled,
    variant: variant,
  })

  return (
    <div className={cn(base(), className)}>
      {contentStart}
      <input
        ref={mergedRef}
        id={id}
        required={required}
        disabled={disabled}
        size={htmlSize}
        type={type}
        onChange={onChange}
        className={input()}
        value={value}
        {...props}
      />
      {contentEnd}
    </div>
  )
}
export const InputRoot = memo(forwardRef(InputRootBase))

const InputBase = (
  {
    className,
    type,
    size = 'medium',
    htmlSize,
    error: errorProp,
    canClear = false,
    canHide = false,
    defaultHidden = true,
    canCopy = false,
    onClickCopy: onClickCopyProp,
    onChange: onChangeProp,
    iconStart,
    contentEnd,
    ...props
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const mergedRef = mergeRefs(ref, inputRef)

  const { hasCopied, copyToClipboard } = useClipboard(1500)
  const onClickCopy = useCallback(() => {
    const val = isNil(props.value) ? inputRef.current?.value : props.value
    if (typeof val !== 'string') {
      return
    }
    copyToClipboard(val)
    onClickCopyProp?.(val)
  }, [copyToClipboard, onClickCopyProp, props.value])

  const onClickClear = useCallback(() => {
    // Simulate native input instead of calling onChange, so this will
    // work with both uncontrolled and controlled inputs
    if (inputRef.current) {
      simulateSetNativeInputValue(inputRef.current, '')
    }
  }, [])

  const [hidden, setHidden] = useState(canHide ? defaultHidden : false)
  const onClickHide = useCallback(() => setHidden(!hidden), [hidden])

  const iconClone =
    iconStart && cloneElement(iconStart as React.ReactElement, { size: size === 'small' ? 16 : 20 })
  const [isEmpty, setIsEmpty] = useState(!inputRef.current?.value)
  useLayoutEffect(() => {
    setIsEmpty(!inputRef.current?.value)
  }, [inputRef])
  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onChangeProp?.(e)
      setIsEmpty(!e.target.value)
    },
    [onChangeProp]
  )

  useEffect(() => {
    if (inputRef.current && props.value === '') {
      setIsEmpty(true)
    }
  }, [props.value])

  const showHideLabel = hidden ? SHOW_LABEL : HIDE_LABEL
  const showClearButton = canClear && !isEmpty && !props.disabled
  const showContentEnd = canHide || showClearButton || canCopy || contentEnd

  return (
    <InputRoot
      ref={mergedRef}
      size={size}
      type={hidden ? 'password' : type}
      className={cn(
        hidden && props.variant !== 'monospace' && '[&>input:not(:placeholder-shown)]:font-mono',
        className
      )}
      onChange={onChange}
      contentStart={
        iconClone && (
          <div
            className={cn(
              'pointer-events-none relative z-10 pe-4 text-text-faint',
              props.disabled && 'text-text-disabled'
            )}
          >
            {iconClone}
          </div>
        )
      }
      contentEnd={
        showContentEnd && (
          <div className="pointer-events-none z-10 flex gap-3 ps-4 [&>*]:pointer-events-auto">
            {canHide && (
              <Tooltip placement="top" content={showHideLabel}>
                <ButtonInput
                  icon={hidden ? <IconEyeOff /> : <IconEye />}
                  aria-label={showHideLabel}
                  onClick={onClickHide}
                />
              </Tooltip>
            )}
            {canCopy && (
              <Tooltip content={hasCopied ? 'Copied' : 'Copy'}>
                <ButtonInput
                  icon={hasCopied ? <IconCheck /> : <IconCopy />}
                  aria-label="Copy"
                  onClick={onClickCopy}
                />
              </Tooltip>
            )}
            {contentEnd}
            {showClearButton && <ClearButton aria-label="Clear input" onClick={onClickClear} />}
          </div>
        )
      }
      {...props}
    />
  )
}

export const Input = memo(forwardRef(InputBase))
Input.displayName = 'Input'

export const InputSearchBase = (
  props: Omit<ComponentPropsWithoutRef<typeof Input>, 'iconStart'>,
  ref: ComponentPropsWithRef<typeof Input>['ref']
) => {
  return <Input ref={ref} type="search" iconStart={<IconSearch />} canClear {...props} />
}
export const InputSearch = memo(forwardRef(InputSearchBase))
Input.displayName = 'InputSearch'
