import { Image, useColorModeValue } from '@chakra-ui/react'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'

const ImageCellRenderer = (imageUrlField: string, params: ICellRendererParams) => {
  const rowData = params.node.data
  const imageUrl = rowData[imageUrlField]

  return (
    <Image
      borderRadius="4px"
      border="1px solid"
      borderColor={useColorModeValue('neutral.300', 'neutralDarkMode.300')}
      p="4px"
      h="calc(100% - 16px)"
      src={imageUrl}
      loading="lazy"
      objectFit="contain"
    />
  )
}

export default ImageCellRenderer
