import { Flex } from '@chakra-ui/react'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'
import React from 'react'

import SuggestedCellTag from './suggestedCellTag/SuggestedCellTag'

const SuggestedCellRenderer = (params: ICellRendererParams) => {
  const category = params.value
  const isOutlier = params.node.data._cleanlab_outlier
  const isAmbiguous = params.node.data._cleanlab_ambiguous
  const suggestExclude = isOutlier || isAmbiguous

  return (
    <Flex align="center" w="100%" height="100%">
      <SuggestedCellTag label={category} suggestExclude={suggestExclude} />
    </Flex>
  )
}

export default SuggestedCellRenderer
