import { Button } from '@components/button/Button'
import { LabelRadioCard } from '@components/radioGroup/LabelRadioCard'
import { RadioGroup, RadioGroupItem } from '@components/radioGroup/RadioGroup'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useDatasets } from '@services/datasets/queries'
import { cn } from '@utils/tailwindUtils'
import { FormEvent, useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import testIds from 'src/playwright/testIds'

import {
  CreateProjectContainer,
  CreateProjectContent,
  CreateProjectNav,
  HeadingWrapper,
  PageDescription,
  PageHeading,
  PreviousLink,
  WithSidebarContainer,
} from './CreateProject.helpers'
import { ProjectWizardActions } from './projectWizard/ProjectWizardActions'
import {
  CreateProjectSidebar,
  ResourceLinkLi,
  SidebarResourcesSection,
  SidebarSectionSecurity,
  SidebarSectionWelcome,
} from './Sidebar'

const OPTION_VALUES = ['uploadNew', 'useDemoData', 'selectExisting'] as const
type OptionValue = (typeof OPTION_VALUES)[number]
const OPTION_DETAILS = {
  uploadNew: {
    dataTestId: testIds.createProjectUploadDatasetRadio,
    heading: 'Import a new Dataset',
    description:
      'We support most file types. Upload from your machine or directly from cloud storage like AWS s3.',
  },
  useDemoData: {
    dataTestId: testIds.createProjectUseDemoDatasetRadio,
    heading: 'Use a demo Dataset',
    description:
      'See instant results on real-world industry data across finance, LLM, images, and more use cases.',
  },
  selectExisting: {
    dataTestId: testIds.createProjectUseExistingDataset,
    heading: 'Select a Dataset from your account',
    description: 'Choose from a Dataset that you’ve already imported to Cleanlab Studio.',
  },
} as const satisfies Record<OptionValue, any>

const Form = () => {
  const navigate = useNavigate()
  const [radioValue, setRadioValue] = useState<OptionValue | ''>('')
  const onRadioChange = useCallback((value: OptionValue | '') => {
    setRadioValue(value)
  }, [])

  const { data: datasets } = useDatasets({ useQueryOptions: { suspense: true } })

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (radioValue === 'uploadNew') {
        navigate('/upload')
        return
      }
      if (radioValue === 'useDemoData') {
        navigate('/clean/demo')
        return
      }
      navigate('/clean/dataset?reset')
    },
    [navigate, radioValue]
  )

  const submitDisabled = !radioValue

  const optionValues = OPTION_VALUES.filter((optionValue) => {
    if (optionValue === 'selectExisting' && !datasets?.length) {
      return false
    }
    return true
  })

  return (
    <form className="flex flex-col gap-8" onSubmit={onSubmit}>
      <RadioGroup
        value={radioValue}
        onValueChange={onRadioChange}
        className={cn(
          'grid gap-8',
          optionValues.length === 1 && 'grid-cols-1',
          optionValues.length === 2 && 'grid-cols-2',
          optionValues.length >= 3 && 'grid-cols-3'
        )}
      >
        {optionValues.map((optionValue) => {
          const optionInfo = OPTION_DETAILS[optionValue]
          return (
            <LabelRadioCard
              data-testid={optionInfo.dataTestId}
              key={optionValue}
              heading={optionInfo.heading}
              description={optionInfo.description}
            >
              <RadioGroupItem value={optionValue}></RadioGroupItem>
            </LabelRadioCard>
          )
        })}
      </RadioGroup>
      <ProjectWizardActions>
        <Tooltip disabled={!submitDisabled} content="Select a Dataset type">
          <Button variant="highContrast" size="medium" type="submit" disabled={submitDisabled}>
            Next
          </Button>
        </Tooltip>
      </ProjectWizardActions>
    </form>
  )
}

const CreateProject = () => {
  return (
    <CreateProjectContainer>
      <CreateProjectNav>
        <PreviousLink asChild>
          <Link to="/">Dashboard</Link>
        </PreviousLink>
      </CreateProjectNav>
      <WithSidebarContainer>
        <CreateProjectContent>
          <HeadingWrapper>
            <PageHeading>Create a new Project</PageHeading>
            <PageDescription>
              Create a Project for an end-to-end workflow in a few clicks. Iterate over data quality
              improvement, review automated analytics, train and quickly deploy the highest quality
              models with state-of-the-art autoML.
            </PageDescription>
          </HeadingWrapper>
          <Form />
        </CreateProjectContent>
        <CreateProjectSidebar>
          <SidebarSectionWelcome />
          <SidebarSectionSecurity />
          <SidebarResourcesSection>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/data_labeling/">
              Cleanlab Studio for data labeling
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/understanding_data/">
              Understanding your data
            </ResourceLinkLi>
            <ResourceLinkLi to="https://help.cleanlab.ai/tutorials/tlm/">
              Trustworthy language model quickstart
            </ResourceLinkLi>
          </SidebarResourcesSection>
        </CreateProjectSidebar>
      </WithSidebarContainer>
    </CreateProjectContainer>
  )
}

export default CreateProject
