import { Button, Grid, GridItem, Heading, Text, VStack } from '@chakra-ui/react'
import queryApi from '@services/queryApi'
import React, { useState } from 'react'

import JSONQueryResults from './JSONQueryResults'
import { ControlledFormInputElement } from './QueryInterface.helpers'

const QueryInterfaceSessionData = () => {
  const [userId, setUserId] = useState('')
  const [sessionId, setSessionId] = useState('')
  const [requestId, setRequestId] = useState('')
  const [queryResults, setQueryResults] = useState(null)

  return (
    <VStack spacing={4} align="left" width="100%" fontSize="md">
      <Heading size="xs">Query Session Data</Heading>
      <Text>
        <strong>Instructions:</strong> Session data can be queried using UserID, SessionID, or
        RequestID.
      </Text>
      <Text>If a RequestID is given, the UserID and the SessionID are ignored.</Text>
      <Text>If a SessionID is given, the UserID is ignored.</Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem>
          <ControlledFormInputElement
            id="user_id"
            type="text"
            label="UserID"
            value={userId}
            setValue={setUserId}
          />
        </GridItem>
        <GridItem>
          <ControlledFormInputElement
            id="session__id"
            type="text"
            label="SessionID"
            value={sessionId}
            setValue={setSessionId}
          />
        </GridItem>
        <GridItem>
          <ControlledFormInputElement
            id="request_id"
            type="text"
            label="RequestID"
            value={requestId}
            setValue={setRequestId}
          />
        </GridItem>
      </Grid>

      <Button
        width={200}
        colorScheme="teal"
        size="md"
        onClick={async () => {
          const response = await queryApi.query({
            resource: 'session',
            user_id: userId,
            session_id: sessionId,
            request_id: requestId,
          })
          setQueryResults(response.data)
        }}
        aria-label="Get session data"
      >
        Get Session Data
      </Button>

      <JSONQueryResults data={queryResults} resource="session" />
    </VStack>
  )
}

export default QueryInterfaceSessionData
