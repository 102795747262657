import { useToast } from '@chakra-ui/react'
import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios, { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import { queryKeys } from './constants'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/cli/v0`,
  withCredentials: true,
})

export const useRefreshApiKey = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation({
    mutationFn: async () => {
      const accessToken = await auth.getTokenSilently()
      await axiosClient.patch('/api_key', null, createAuthHeaders(accessToken))
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.commandLine.all() })
    },
    onError: (err) => {
      notifyAxiosError(toast, err as AxiosError)
    },
  })
}
