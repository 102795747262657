import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

import { BlockProjectsMessage } from './ProjectWizardRootLayout'

export const ProjectWizardModalAlert = ({
  isOpen,
  message,
  actions,
}: {
  isOpen: boolean
  message?: BlockProjectsMessage
  actions?: ReactNode
} & Partial<Omit<ComponentPropsWithoutRef<typeof Modal>, 'children'>>) => {
  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        {message?.heading && (
          <ModalHeader className="type-body-300-medium text-text-strong">
            {message?.heading}
          </ModalHeader>
        )}
        {message?.description && (
          <ModalBody className="type-body-200 text-text-primary">{message.description}</ModalBody>
        )}
        {actions && <ModalFooter>{actions}</ModalFooter>}
      </ModalContent>
    </Modal>
  )
}
