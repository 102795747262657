// @ts-ignore
import { useColorMode, useColorModeValue } from '@chakra-ui/react'
import { ColorModeSwitcherProps } from '@common/layout/colorModeSwitcher/ColorModeSwitcher.types'
import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconMoon, IconSun } from '@components/icons'
import { useAuth } from '@hooks/useAuth'
import { useEventTracking } from '@hooks/useEventTracking'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import * as React from 'react'

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = () => {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(IconMoon, IconSun)
  const { trackEvent } = useEventTracking()
  const { isAuthenticated } = useAuth()

  const handleClick = () => {
    if (isAuthenticated) {
      trackEvent(
        text === 'dark' ? MixpanelEvents.switchToDarkMode : MixpanelEvents.switchToLightMode
      )
    }
    toggleColorMode()
  }

  return (
    <IconFrameButton
      variant="level1"
      onClick={handleClick}
      icon={<SwitchIcon className="text-neutral-800" />}
      size="small"
      aria-label={`Switch to ${text} mode`}
      tooltipContent={text === 'dark' ? 'Dark mode' : 'Light mode'}
    />
  )
}
