import { Text, useDisclosure, useToast } from '@chakra-ui/react'
import Loading from '@common/layout/loading/Loading'
import NameCellComponent from '@common/misc/nameCellComponent/NameCellComponent'
import DeleteRowModal from '@common/modals/deleteRowModal/DeleteRowModal'
import { Button } from '@components/button/Button'
import PrimaryButtonWithIcon from '@components/buttons/primaryButtonWithIcon/PrimaryButtonWithIcon'
import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconPlus, IconTrash } from '@components/icons'
import { InputSearch } from '@components/input/Input'
import { LinkBlock } from '@components/linkBlock/LinkBlock'
import { Meter } from '@components/meter/Meter'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useDashboardFilter } from '@hooks/useDashboardFilter'
import { useEventTracking } from '@hooks/useEventTracking'
import useIsTabActive from '@hooks/useIsTabActive'
import { useKeyPress } from '@hooks/useKeyPress'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import datasetApiService, { DatasetRowProps } from '@services/datasetApi'
import { queryKeys as projectsQueryKeys } from '@services/project/constants'
import { tabWithinCell } from '@utils/ag-grid/tabWithinCell'
import { handlePaginationGridPageChanged } from '@utils/functions/handlePaginationGridPageChange'
import { timestampToDate } from '@utils/functions/timestampToDate'
import { cn } from '@utils/tailwindUtils'
import { GetContextMenuItemsParams, GridApi, GridReadyEvent } from 'ag-grid-community'
import { ColDef, NewValueParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'
import { AxiosError } from 'axios'
import { isEqual } from 'lodash'
import {
  ComponentPropsWithoutRef,
  Dispatch,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FiUpload } from 'react-icons/fi'
import { useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import testIds from 'src/playwright/testIds'

import { DASHBOARD_DEFAULT_COLUMN_DEF, DashboardGrid } from '../DashboardGrid'
import { DateOnlyWithTooltip } from '../DateOnlyWithTooltip'
import NoResultsOverlay from '../noResultsOverlay/NoResultsOverlay'
import { DatasetsGridProps } from './DatasetsGrid.types'

type DatasetCellRendererParams = ICellRendererParams<DatasetRowProps>

type ActionsCellParams = {
  setTargetRowData: Dispatch<SetStateAction<DatasetRowProps | null | undefined>>
  onDeleteOpen: () => void
}

const numRowsPerPage = 20

const getDatasetRoute = (rowData: DatasetRowProps) => {
  if (!rowData.complete) {
    return undefined
  }
  return `/datasets/${rowData.id}`
}

const DatasetNameCellRenderer = (params: DatasetCellRendererParams) => {
  const rowData = params.node.data
  if (!rowData) {
    return null
  }
  return (
    <NameCellComponent
      name={rowData.name}
      id={rowData.id}
      to={getDatasetRoute(rowData)}
      onEditClick={() => params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: 'name' })}
    />
  )
}

const StatusCellRenderer = (params: DatasetCellRendererParams) => {
  const rowData = params.node.data
  if (!rowData) {
    return null
  }
  if (rowData.progress !== undefined) {
    return (
      <div className="flex h-full items-center gap-3">
        <Meter
          variant="completion"
          aria-label="Dataset processing progress"
          value={rowData.progress}
          max={1}
          className="w-10"
        />
        <span className="tabular-nums">
          {`${rowData.progress.toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          })} ready`}
        </span>
      </div>
    )
  }
  return getDatasetStatus(rowData)
}

export const UpdatedDateRenderer = (params: DatasetCellRendererParams) => {
  const timestamp = params.node.data?.upload_date
  if (!timestamp) {
    return null
  }
  return <DateOnlyWithTooltip timestamp={timestamp} />
}

const getDatasetStatus = (rowData: DatasetRowProps) => {
  if (rowData.complete) {
    return (
      <Link
        className={cn(
          'truncate focus-visible:outline-none',
          'text-blue-700 hover:text-blue-800 hover:underline focus-visible:underline'
        )}
        to={`/datasets/${rowData.id}`}
      >
        Ready
      </Link>
    )
  } else if (rowData.progress !== undefined) {
    return 'Initializing'
  } else {
    return 'Failed'
  }
}

const ActionsCellRenderer = (params: DatasetCellRendererParams) => {
  const { trackEvent } = useEventTracking()
  const rowData = params.node.data
  const { setTargetRowData, onDeleteOpen } = (params.colDef
    ?.cellRendererParams as ActionsCellParams) || {
    setTargetRowData: () => {},
    onDeleteOpen: () => {},
  }
  const handleCleanButtonClicked = useCallback(() => {
    trackEvent(MixpanelEvents.clickCreateProject, { page: 'dashboard' })
  }, [trackEvent])

  if (!rowData) {
    return null
  }
  const openLabel = rowData.complete ? 'View dataset' : `Dataset ${getDatasetStatus(rowData)}`

  const deleteLabel = 'Delete Dataset'
  const createLabel = 'Create Project'
  const openButtonProps = {
    variant: 'secondaryFaint',
    size: 'xSmall',
    'aria-label': openLabel,
    children: 'Open',
  } as const satisfies Partial<ComponentPropsWithoutRef<typeof Button>>
  const datasetRoute = getDatasetRoute(rowData)

  return (
    // Add right pr-[15px] to accomodate scrollbar when system set to always show scrollbar
    <div className="flex h-full flex-shrink-0 items-center gap-5 pr-[15px]">
      {rowData.complete && (
        <LinkBlock
          className="type-caption-medium"
          size="small"
          variant="primary"
          iconEnd={<IconPlus />}
          asChild
        >
          <Link
            onClick={handleCleanButtonClicked}
            to={`/clean/${rowData.id}?reset`}
            data-testid={params.rowIndex === 0 ? testIds.dashboardPageCreateProjectButton : ''}
          >
            {createLabel}
          </Link>
        </LinkBlock>
      )}
      <div className="flex h-full flex-shrink-0 items-center gap-4">
        <IconFrameButton
          variant="outline"
          size="xSmall"
          icon={<IconTrash />}
          onClick={() => {
            setTargetRowData(rowData)
            onDeleteOpen()
          }}
          tooltipContent={deleteLabel}
          aria-label={deleteLabel}
          data-testid={testIds.dashboardPageDeleteDatasetButton}
        />
        <Tooltip content={openLabel}>
          {/* Need <span> wrapper for <Tooltip> to work when <Button> is using `asChild` */}
          <span>
            {datasetRoute ? (
              <Button {...openButtonProps} asChild>
                <Link
                  to={datasetRoute}
                  onClick={() => {
                    trackEvent(MixpanelEvents.clickGoToDatasetDetailedView, {
                      datasetId: rowData.id,
                    })
                  }}
                  aria-label={openLabel}
                >
                  {openButtonProps.children}
                </Link>
              </Button>
            ) : (
              <Button {...openButtonProps} disabled={true}></Button>
            )}
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

const UploadButton = () => {
  const navigate = useNavigate()
  return (
    <div style={{ pointerEvents: 'auto' }}>
      <PrimaryButtonWithIcon
        leftIcon={<FiUpload />}
        height="36px"
        onClick={() => navigate('/upload')}
      >
        Import a Dataset
      </PrimaryButtonWithIcon>
    </div>
  )
}

const DatasetsGrid = (props: DatasetsGridProps) => {
  const { data, refreshData } = props
  const queryClient = useQueryClient()
  const toast = useToast()
  const { trackEvent } = useEventTracking()
  const isTabActive = useIsTabActive()

  // delete modal
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [targetRowData, setTargetRowData] = useState<DatasetRowProps | null | undefined>(null)

  useKeyPress({
    callback: () => {
      if (gridApi?.getEditingCells().length) {
        gridApi.stopEditing()
      }
    },
    preventDefault: false,
    stopPropagation: false,
    keys: ['Enter'],
  })

  const handleCellValueChanged = useCallback(
    async (evt: NewValueParams) => {
      try {
        await datasetApiService.updateRow(evt.data)
        refreshData()
      } catch (err) {
        notifyAxiosError(toast, err as AxiosError, { title: 'Edit failed' })
      }
    },
    [refreshData, toast]
  )

  const handleDeleteButtonClicked = useCallback(
    async (rowData: DatasetRowProps) => {
      try {
        await datasetApiService.deleteRow(rowData.id)
        refreshData()
        void queryClient.invalidateQueries(projectsQueryKeys.projects.all())
      } catch (err) {
        notifyAxiosError(toast, err as AxiosError, { title: 'Delete failed' })
      }
    },
    [queryClient, refreshData, toast]
  )

  const LoadingMessage = useCallback(() => {
    return <Text size="md">Fetching rows...</Text>
  }, [])

  const onNameCellChanged = useCallback(
    (evt: NewValueParams) => handleCellValueChanged(evt),
    [handleCellValueChanged]
  )

  const onModalityCellChanged = useCallback(
    (evt: NewValueParams) => handleCellValueChanged(evt),
    [handleCellValueChanged]
  )

  const columnDefs: ColDef<DatasetRowProps>[] = useMemo(
    () =>
      [
        {
          field: 'name',
          valueGetter: ({ data }) => ({
            name: data?.name,
            complete: data?.complete,
            id: data?.id,
          }),
          equals: (a, b) => isEqual(a, b),
          valueFormatter: (params) => params.data?.name ?? '',
          headerName: 'Name',
          tooltipField: 'name',
          editable: true,
          cellEditor: 'agTextCellEditor',
          cellEditorParams: { useFormatter: true },
          onCellValueChanged: onNameCellChanged,
          cellRenderer: DatasetNameCellRenderer,
          suppressKeyboardEvent: tabWithinCell,
          minWidth: 250,
          flex: 2.5,
          filter: 'agTextColumnFilter',
          menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        },
        {
          field: 'modality',
          tooltipField: 'modality',
          headerName: 'Modality',
          editable: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            values: ['text', 'tabular'],
          },
          singleClickEdit: true,
          onCellValueChanged: onModalityCellChanged,
          width: 120,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          field: 'num_rows',
          headerName: 'No. of rows',
          width: 150,
          minWidth: 150,
          maxWidth: 200,
        },
        {
          valueGetter: (params) => {
            return params.data?.progress ? params.data?.progress : params.data?.complete
          },
          headerName: 'Status',
          cellRenderer: StatusCellRenderer,
          minWidth: 144,
          maxWidth: 160,
        },
        {
          field: 'upload_date',
          headerName: 'Uploaded',
          filter: false,
          valueGetter: (params) => {
            return typeof params.data?.upload_date === 'number'
              ? timestampToDate(params.data?.upload_date)
              : params.data?.upload_date
          },
          cellRenderer: UpdatedDateRenderer,
          suppressKeyboardEvent: tabWithinCell,
          width: 130,
          minWidth: 130,
          maxWidth: 140,
        },
        {
          headerName: '',
          filter: false,
          sortable: false,
          pinned: 'right',
          cellRenderer: ActionsCellRenderer,
          cellRendererParams: {
            setTargetRowData,
            onDeleteOpen,
          } as const satisfies ActionsCellParams,
          cellClass: 'flex justify-end',
          suppressKeyboardEvent: tabWithinCell,
          width: 235,
          minWidth: 235,
        },
      ] satisfies ColDef<DatasetRowProps>[],
    [onDeleteOpen, onModalityCellChanged, onNameCellChanged]
  )
  const handleGridReady = useCallback(
    async (event: GridReadyEvent) => {
      setGridApi(event.api)
      refreshData()
    },
    [refreshData]
  )

  useEffect(() => {
    if (data) {
      if (
        !data.every((row) => row.complete || (!row.complete && row.progress === undefined)) &&
        isTabActive
      ) {
        setTimeout(() => refreshData(), 3000)
      }
    }
  })

  const gridOptions: GridOptions = {
    // modules: [ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule],
    columnDefs: columnDefs,
    defaultColDef: {
      ...DASHBOARD_DEFAULT_COLUMN_DEF,
    },
    animateRows: true,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      if (params.value === undefined) {
        return []
      } else {
        return ['copy']
      }
    },
  }

  const handleUploadButtonClicked = () => {
    trackEvent(MixpanelEvents.clickUploadDatasetButtonFromDashboard)
  }

  const {
    isGridReady,
    onFilterInputChange,
    updateEmptyState,
    noRowsOverlayComponentParams,
    filterValue,
  } = useDashboardFilter({ gridApi, debounceWait: 100, runImmediately: true, gridType: 'dataset' })

  return (
    <Suspense
      fallback={<Loading position="relative" type="chakra-spinner" text="Loading Datasets..." />}
    >
      <div
        className="home-tour-datasets-table flex h-[40vh] min-h-[400px] flex-col gap-5"
        data-testid={testIds.dashboardPageDatasetsGrid}
      >
        <h2 className="type-display-50 text-text-strong">Datasets</h2>

        {targetRowData && (
          <DeleteRowModal
            isOpen={isDeleteOpen}
            onClose={onDeleteClose}
            rowData={targetRowData}
            handleDeleteButtonClicked={handleDeleteButtonClicked}
            deletionType="dataset"
          />
        )}

        <div className="flex w-full items-start gap-5">
          <InputSearch
            placeholder="Filter by Dataset name"
            value={filterValue}
            onChange={onFilterInputChange}
            disabled={!isGridReady}
          />

          <Button
            variant="primaryFaint"
            size="medium"
            data-testid={testIds.dashboardPageUploadDatasetButton}
            asChild
            className="min-w-fit"
          >
            <Link to="/upload" onClick={handleUploadButtonClicked}>
              Import data
            </Link>
          </Button>
        </div>

        {targetRowData && (
          <DeleteRowModal
            isOpen={isDeleteOpen}
            onClose={onDeleteClose}
            rowData={targetRowData}
            handleDeleteButtonClicked={handleDeleteButtonClicked}
            deletionType="dataset"
          />
        )}
        <DashboardGrid<DatasetRowProps>
          gridOptions={gridOptions}
          ensureDomOrder
          onGridReady={handleGridReady}
          pagination
          paginationPageSize={numRowsPerPage}
          rowData={data}
          getRowId={({ data }) => data.id}
          loadingOverlayComponent={LoadingMessage}
          onModelUpdated={updateEmptyState}
          onPaginationChanged={handlePaginationGridPageChanged}
          noRowsOverlayComponent={filterValue.length > 0 ? NoResultsOverlay : UploadButton}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        />
      </div>
    </Suspense>
  )
}

export default DatasetsGrid
