import { Link as ChakraLink } from '@chakra-ui/react'

import { LinkProps } from './Link.types'

const Link = (props: LinkProps) => {
  const {
    children,
    className = '',
    fontSize,
    href,
    isExternal,
    textDecoration,
    variant,
    onClick,
  } = props
  return (
    <ChakraLink
      className={className}
      variant={variant ?? 'standard'}
      isExternal={isExternal}
      href={href}
      fontSize={fontSize}
      onClick={onClick}
      textDecoration={textDecoration}
    >
      {children}
    </ChakraLink>
  )
}

export default Link
