import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { HStack, Text, useColorModeValue } from '@chakra-ui/react'
import Link from '@components/link/Link'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import pluralize from 'pluralize'
import React from 'react'
import { FiInfo } from 'react-icons/fi'

const TrialInfo = () => {
  const { freeTrialPeriod, days, hours, minutes, timeRemaining, onSubscriptionModalOpen } =
    useSubscription()
  const iconColor = useColorModeValue(
    cleanlabColors.neutral[800],
    cleanlabColors.neutralDarkMode[800]
  )
  const iconColorExpired = useColorModeValue(
    cleanlabColors.red[600],
    cleanlabColors.redDarkMode[600]
  )

  return (
    <HStack pt="1px" w="max-content">
      <FiInfo size="14px" color={freeTrialPeriod ? iconColor : iconColorExpired} />
      <Text
        color={useColorModeValue('neutral.700', 'neutralDarkMode.700')}
        fontSize="12px"
        fontWeight={500}
        noOfLines={1}
      >
        {freeTrialPeriod && timeRemaining > 0
          ? `Free trial ends in ${days ? days + pluralize(' day', days) : ''}${
              !days && hours ? hours + pluralize(' hours', hours) : ''
            }${!days && !hours && minutes ? minutes + pluralize(' minutes', minutes) : ''}.`
          : 'Your free trial has expired.'}{' '}
        <Link textDecoration="underline" fontSize="xs" onClick={onSubscriptionModalOpen}>
          Upgrade now
        </Link>
      </Text>
    </HStack>
  )
}

export default TrialInfo
