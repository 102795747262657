import { ICellRendererParams } from 'ag-grid-community'

import { ProjectDetailsProps } from '../Datasheet.types'

export interface MultiLabelTagRendererParams extends ICellRendererParams {
  tagValue: string
  label: string
  columnType: string
  projectDetails: ProjectDetailsProps | null
}

export enum COLUMN_TYPE {
  SUGGESTED = 'suggested',
  CORRECTED = 'corrected',
  GIVEN = 'given',
  MULTI_LABEL_TAG = 'multilabel_tag',
}
