import { cleanlabColors } from '@assets/styles/CleanlabColors'
import { Box, Button, Container, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import Loading from '@common/layout/loading/Loading'
import { useAuth } from '@hooks/useAuth'
import { useEffect } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

const InvalidEmailPage = () => {
  const warningTextColor = useColorModeValue('orange.700', 'orangeDarkMode.700')
  const textFaint = useColorModeValue('neutral.700', 'neutralDarkMode.700')

  const warningBorderColor = useColorModeValue('orange.500', 'orangeDarkMode.500')
  const warningIconColor = useColorModeValue(
    cleanlabColors.orange[600],
    cleanlabColors.orangeDarkMode[600]
  )

  const { isAuthenticated, isLoading, logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated, navigate])

  if (isAuthenticated || isLoading) {
    return <Loading />
  } else {
    return (
      <Container px="15%" h="100%">
        <Box
          borderRadius="12px"
          w="100%"
          border="1px solid"
          borderColor={warningBorderColor}
          padding="16px"
          mt="10%"
          fontSize="md"
          lineHeight="24px"
        >
          <HStack>
            <FiAlertTriangle color={warningIconColor} size={16} />
            <Text fontWeight={500} lineHeight="24px" color={warningTextColor}>
              Invalid email type
            </Text>
          </HStack>
          <Box paddingLeft="24px">
            <Text color={textFaint}>Please sign up again with a company or university email.</Text>
            <Button
              p="6px 12px"
              mt="8px"
              fontSize="14px"
              height="32px"
              variant="tlm-action-button"
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Container>
    )
  }
}

export default InvalidEmailPage
