import { cleanlabColors } from '@assets/styles/CleanlabColors'
import globalStyles from '@assets/styles/global.module.css'
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import ExportIcon from '@components/icons/exportIcon/ExportIcon'
import { notifyAxiosError } from '@providers/errors/ErrorToast'
import headlessBrowserApi from '@services/headlessBrowserApi'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { AxiosError } from 'axios'
import React from 'react'

const ExportToPdf = ({ cleansetId }: { cleansetId: string }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')

  const toast = useToast()
  const handleExportClick = async () => {
    setIsLoading(true)

    try {
      const blob = await headlessBrowserApi.generatePdf(cleansetId)

      const link = document.createElement('a')
      const href = URL.createObjectURL(blob)

      link.href = href
      link.download = `Analytics Chart for Cleanset ${cleansetId} ${new Date()
        .toISOString()
        .replace(/T/, ' ')
        .replace(/\..+/, '')}.pdf`
      link.click()

      // avoid memory leak
      URL.revokeObjectURL(href)
    } catch (err) {
      notifyAxiosError(toast, err as AxiosError)
    } finally {
      setIsLoading(false)
    }
  }
  const iconColor = useColorModeValue(
    cleanlabColors.text.primary,
    cleanlabColors.textDarkMode.primary
  )
  return (
    <>
      {IS_VPC_ENVIRONMENT ? (
        <Button
          variant="secondary"
          onClick={() => {
            window.print()
          }}
        >
          Export to PDF
        </Button>
      ) : (
        <Button
          iconEnd={isLoading ? <Spinner /> : <ExportIcon color={iconColor} />}
          variant="secondary"
          className={globalStyles.screenOnly}
          disabled={isLoading}
          onClick={() => setIsModalOpen(true)}
        >
          Export to PDF
        </Button>
      )}

      <Modal isCentered isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent bg={modalBg}>
          <ModalHeader>Export PDF</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="lg" p={6}>
            <VStack>
              <Text>Click the button below to export the charts to a downloadable PDF</Text>
              <Flex w="100%" pt={6} justify="flex-end">
                <PrimaryButton isLoading={isLoading} height="36px" onClick={handleExportClick}>
                  Export to PDF
                </PrimaryButton>
              </Flex>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ExportToPdf
