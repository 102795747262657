import { cleanlabColors } from '@assets/styles/CleanlabColors'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { defaultToastAlertProps } from '@common/alerts/defaultToastProps'
import { Button } from '@components/button/Button'
import { FormField } from '@components/formField/FormField'
import ExpandIcon from '@components/icons/expandIcon/ExpandIcon'
import { Input } from '@components/input/Input'
import { renderChakraToastAlert, ToastStatus } from '@components/toast/ToastAlert'
import { AccountStatus } from '@services/user/constants'
import { useDeactivateUser, useSetAccessLevelMutation } from '@services/user/mutations'
import { useId } from 'react'
import { useForm } from 'react-hook-form'

import { ButtonCloseModalChakra } from '../ButtonCloseChakraModal'
import { ModalProps } from '../Modal.types'

interface EditUserModalProps extends ModalProps {
  userId: string
  accessLevel: string
  name: string
  email: string
  isCurrentUser: boolean
  accountStatus: string
}

const EditUserModal = (props: EditUserModalProps) => {
  const { isOpen, onClose, userId, accessLevel, name, email, isCurrentUser, accountStatus } = props
  const toast = useToast()
  const roleSelectId = useId()

  const selectBorderColor = useColorModeValue('border.1', 'borderDarkMode.1')
  const surface0 = useColorModeValue('surface.0', 'surfaceDarkMode.0')

  const onSuccess = (title: string, status: ToastStatus) => {
    toast({
      ...defaultToastAlertProps,
      render: renderChakraToastAlert({
        heading: title,
        status: status,
      }),
    })
    onClose()
  }

  const onError = (title: string) => {
    toast({
      ...defaultToastAlertProps,
      render: renderChakraToastAlert({
        heading: title,
        status: 'warning',
      }),
    })
    onClose()
  }

  const { mutate: deactivateUser, isLoading: isDeactivateUserLoading } = useDeactivateUser({
    onSuccess: () => onSuccess('Deactivated user', 'warning'),
    onError: () => onError('Failed to deactivate user'),
  })

  const { mutate: editUserAccessLevel, isLoading: isEditUserLoading } = useSetAccessLevelMutation({
    onSuccess: () => onSuccess('User access level updated', 'success'),
    onError: () => onError('Failed to update user access level'),
  })

  const { register, getValues, formState } = useForm()

  const textPrimary = useColorModeValue(
    cleanlabColors.neutral[800],
    cleanlabColors.neutralDarkMode[800]
  )

  const onSubmit = () => {
    const formValues = getValues()
    editUserAccessLevel({ userId: userId, accessLevel: formValues.editUser })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent bg={surface0}>
        <ModalHeader pb={0} pt="16px" className="text-text-strong">
          Edit user
        </ModalHeader>
        <ButtonCloseModalChakra />
        <ModalBody py={0}>
          <div className="flex flex-col gap-6 pt-6">
            <FormField htmlFor={roleSelectId} label="Role">
              <Select
                id={roleSelectId}
                height="40px"
                borderRadius="8px"
                fontSize="sm"
                borderColor={selectBorderColor}
                className="text-text-strong"
                icon={<ExpandIcon width="20" height="20" color={textPrimary} />}
                defaultValue={accessLevel}
                {...register('editUser', { required: true })}
              >
                <option value="user">Member</option>
                <option value="organization_admin">Admin</option>
              </Select>
            </FormField>
            <FormField label="Name">
              <Input size="large" value={name} disabled={true} />
            </FormField>
            <FormField label="Email address">
              <Input size="large" value={email} disabled={true} />
            </FormField>
          </div>
        </ModalBody>
        <ModalFooter p="24px" justifyContent="space-between">
          {accountStatus === AccountStatus.ACTIVE ? (
            <Button
              variant="critical"
              disabled={isCurrentUser || isDeactivateUserLoading}
              onClick={() => deactivateUser({ userId: userId })}
            >
              {isDeactivateUserLoading ? <Spinner size="sm" /> : 'Deactivate user'}
            </Button>
          ) : (
            <div />
          )}
          <div className="flex flex-row gap-5">
            <Button type="button" variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              variant="primary"
              disabled={!formState.isDirty || isEditUserLoading}
            >
              {isEditUserLoading ? <Spinner size="sm" /> : 'Save changes'}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditUserModal
