import Loading from '@common/layout/loading/Loading'
import { useAuth } from '@hooks/useAuth'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface RedirectAuthProps {
  signup: boolean
}

const RedirectAuth = (props: RedirectAuthProps) => {
  const { signup } = props
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth()
  const navigate = useNavigate()

  if (isLoading) {
    return <Loading />
  } else if (signup && !isAuthenticated) {
    void loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })
  } else if (!isAuthenticated) {
    void loginWithRedirect({})
  }
  navigate('/')
  return null
}

export default RedirectAuth
