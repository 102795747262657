import { GridApi } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DashboardGridVariantsType } from 'src/pages/dashboard/Dashboard.types'

import { useDebounce } from './useDebounce'

interface UseDashboardFilterOptions {
  /** The gridApi from the associated ag-grid */
  gridApi: GridApi | null
  /** What kind of grid this is */
  gridType: DashboardGridVariantsType
  /** How long the debounce timeout should be */
  debounceWait?: number
  /** If true, the function will run immediately */
  runImmediately?: boolean
}

interface UseDashboardFilterReturnType {
  isGridReady: boolean
  onFilterInputChange: (input: React.ChangeEvent<HTMLInputElement> | null) => void
  updateEmptyState: () => void
  noRowsOverlayComponentParams:
    | { gridType: DashboardGridVariantsType }
    | { onClickClearSearch: () => void; gridType: DashboardGridVariantsType }
  filterValue: string
}

/**
 * @param {UseDashboardFilterOptions} options - Options for the hook
 * @returns {UseDashboardFilterReturnType} Object containing properties and callbacks that help with filtering
 */
export const useDashboardFilter = ({
  gridApi,
  gridType,
  debounceWait = 150,
  runImmediately = false,
}: UseDashboardFilterOptions): UseDashboardFilterReturnType => {
  const [filterValue, setFilterValue] = useState('')
  const [isGridReady, setIsGridReady] = useState(false)

  useEffect(() => {
    if (gridApi) {
      setIsGridReady(true)
    }
  }, [gridApi])

  const updateFilter = useCallback(
    (value: string) => {
      const trimmedValue = value.trim()
      let newFilterModel

      if (trimmedValue.length === 0) {
        newFilterModel = null
      } else {
        newFilterModel = {
          name: {
            filterType: 'text',
            type: 'contains',
            filter: trimmedValue,
          },
        }
      }

      gridApi?.setFilterModel(newFilterModel)
    },
    [gridApi]
  )

  const debouncedFilterUpdate = useDebounce(updateFilter, debounceWait, runImmediately)

  const onFilterInputChange = useCallback(
    (input: React.ChangeEvent<HTMLInputElement> | null) => {
      const value = input === null ? '' : input.target.value

      setFilterValue(value)
      debouncedFilterUpdate(value)
    },
    [debouncedFilterUpdate]
  )

  const updateEmptyState = useCallback(() => {
    const displayedRowCount = gridApi?.getDisplayedRowCount()

    if (displayedRowCount === 0) {
      gridApi?.showNoRowsOverlay()
    } else {
      gridApi?.hideOverlay()
    }
  }, [gridApi])

  // Trying to memoize what we can to minimize re-renders/calculations
  const memoizedNoRowsOverlayComponentParamsWithFilter = useMemo(
    () => ({
      onClickClearSearch: () => onFilterInputChange(null),
      gridType,
    }),
    [onFilterInputChange, gridType]
  )
  const memoizedNoRowsOverlayComponentParamsWithoutFilter = useMemo(
    () => ({
      gridType,
    }),
    [gridType]
  )

  // This feels overly complicated but the GridApi gives props to the NoRowsOverlayComponent via an object
  const noRowsOverlayComponentParams = useMemo(() => {
    if (filterValue.length > 0) {
      return memoizedNoRowsOverlayComponentParamsWithFilter
    }

    return memoizedNoRowsOverlayComponentParamsWithoutFilter
  }, [
    filterValue,
    memoizedNoRowsOverlayComponentParamsWithoutFilter,
    memoizedNoRowsOverlayComponentParamsWithFilter,
  ])

  return {
    isGridReady,
    onFilterInputChange,
    updateEmptyState,
    noRowsOverlayComponentParams,
    filterValue,
  }
}
