import { FEATURE_FLAGS } from '@utils/functions/checkFeatureFlag'

export enum DatasheetColumnType {
  CUSTOM = 'custom',
  ISSUE = 'issue',
  SCORE = 'score',
}

export interface DatasheetColumnConfig {
  featureFlag?: (typeof FEATURE_FLAGS)[number]
  isInitiallyHidden: boolean
  headerName: string
  tooltip?: string
  columnType: DatasheetColumnType
  backendColumnName?: string
  isNumberColumn?: boolean
  tooltipLabel?: string
}
