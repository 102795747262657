import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Loading from '@common/layout/loading/Loading'
import { PlanData } from '@services/billing/constants'
import { useGetBillingPlans } from '@services/billing/queries'
import React, { useState } from 'react'

import { SwitchPlansModalProps } from './SwitchPlansModal.types'

const SwitchPlansModal = (props: SwitchPlansModalProps) => {
  const { isOpen, onClose, onSubmit, currentPlan, currentSubscriptionId, currentPlanId } = props
  const inputBg = useColorModeValue('gray.200', 'gray.600')
  const [selectedPlanId, setSelectedPlanId] = useState<string>('')

  const plans = useGetBillingPlans()

  if (!plans) {
    return <Loading text="Loading plans" />
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Switch Plans</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="1.5rem">
            <Stack spacing={0}>
              <FormLabel>Current Plan</FormLabel>
              <Text fontSize="md">{currentPlan}</Text>
            </Stack>
            <FormControl>
              <FormLabel>Plan</FormLabel>
              <Select
                placeholder="Select plan"
                bg={inputBg}
                onChange={(evt) => setSelectedPlanId(evt.target.value)}
              >
                {plans
                  .filter((e) => e.id !== currentPlanId)
                  .map((plan) => (
                    <option key={plan.plan_name} value={plan.id}>
                      {`${plan.tier}, ${plan.plan_name} (${plan.id})`}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter mt={3}>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={() => {
              const planData = plans.find((e) => e.id === selectedPlanId)
              const planDataCopy = { ...planData } // make copy so we can replace id with plan_id
              planDataCopy && delete planDataCopy.id // remove id property
              planDataCopy &&
                onSubmit(currentSubscriptionId, {
                  ...(planDataCopy as PlanData),
                  ...{ plan_id: selectedPlanId },
                })
              onClose()
            }}
          >
            Update
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SwitchPlansModal
