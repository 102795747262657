import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { KeyPress } from '@common/misc/keyPress/KeyPress'
import React from 'react'

import { HelpModalProps } from './HelpModal.types'

const HelpModal = (props: HelpModalProps) => {
  const { isOpen, onClose } = props

  const modalBg = useColorModeValue('white', 'neutralDarkMode.100')
  const isMacOS = navigator.userAgent.toLowerCase().includes('mac')

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent bg={modalBg} pb="1rem">
        <ModalHeader>Help</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack spacing={6} alignItems="flex-start">
            <VStack spacing={5} alignItems="flex-start">
              <Box as="span">
                <KeyPress>↑↓</KeyPress> for previous / next example
              </Box>
              <Box as="span">
                <KeyPress>esc</KeyPress> open / close Resolver
              </Box>
            </VStack>
            <VStack spacing={5} alignItems="flex-start">
              <Box as="span">
                <KeyPress>click</KeyPress> row to select
              </Box>
              <Box as="span">
                <KeyPress>{isMacOS ? 'cmd' : 'ctrl'} + z</KeyPress> to undo last action
              </Box>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default HelpModal
