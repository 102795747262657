import { IconEdit } from '@components/icons'
import { useState } from 'react'

interface Props {
  name: string
  onEditClick?: () => void
}

const EditButton = (props: Props) => {
  const { name, onEditClick } = props
  const [hideEditButton, setHideEditButton] = useState(false)

  const triggerEditMode = () => {
    /* Seems like onEditClick is suppressed by... something.
     * This is a workaround to ensure that onEditClick is called
     * and the cell enters edit mode. The Edit icon-button must be hidden
     * and onEditClick must be wrapped in setTimeout for this to work */
    setHideEditButton(true)
    setTimeout(() => onEditClick?.(), 0)
  }

  if (hideEditButton) {
    return <div />
  }

  return (
    <button
      className="hidden rounded-1 bg-transparent p-1 text-text-primary outline-none hover:bg-surface-0-hover focus-visible:outline-blue-700 focus-visible:ring focus-visible:ring-focus active:bg-surface-0-active group-focus-within:flex group-hover:flex"
      onClick={triggerEditMode}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          triggerEditMode()
        }
      }}
      aria-label={`Edit ${name} title`}
    >
      <IconEdit size={16} />
    </button>
  )
}

export default EditButton
