export interface GetFeatureFlagsRes {
  id: number
  feature_flag_id: string
  user_id: string
  email: string
  name: string
  timestamp: number
}

export interface FeatureFlags {
  id: number
  featureFlagId: string
  userId: string
  email: string
  name: string
  timestamp: number
}

export const queryKeys = {
  featureFlags: {
    all: () => ['featureFlags'] as const,
    allAvailable: () => ['availableFeatureFlags'] as const,
    self: () => [...queryKeys.featureFlags.all(), 'self'],
  },
}
