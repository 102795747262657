import { DeploymentRowProps } from '@services/deploymentApi'

export interface DeploymentsGridProps {
  data: DeploymentRowProps[]
  fetchDeploymentData: VoidFunction
}

export enum DEPLOYMENT_STATUS {
  TRAINED = 'TRAINED',
  TRAINING = 'TRAINING',
  INITIALIZING = 'INITIALIZING',
  FAILED = 'FAILED',
}
