import { Card as ChakraCard, CardBody } from '@chakra-ui/react'
import React from 'react'

import CollapseCardButton from './collapseCardButton/CollapseCardButton'
import { CollapsedCardProps } from './CollapsedCard.types'

const CollapsedCard = (props: CollapsedCardProps) => {
  const { isCollapsed, handleCollapse } = props

  return (
    <ChakraCard variant="collapsed">
      <CardBody>
        <CollapseCardButton isCollapsed={isCollapsed} handleCollapse={handleCollapse} />
      </CardBody>
    </ChakraCard>
  )
}

export default CollapsedCard
