import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'
import { RowCountType } from '@services/datasheet/constants'
import { useRowCount } from '@services/datasheet/queries'

import { IssuesResolvedSliderProps } from './IssuesResolvedSlider.types'

const IssuesResolvedSlider = (props: IssuesResolvedSliderProps) => {
  const { cleansetId, gridApi, isResolving, setShowTooltip, firstGridDataRendered } = props
  const { numRows: numIssues } = useRowCount(
    cleansetId,
    RowCountType.ISSUES,
    gridApi,
    firstGridDataRendered
  )
  const { numRows: numIssuesResolved } = useRowCount(
    cleansetId,
    RowCountType.ISSUES_RESOLVED,
    gridApi,
    firstGridDataRendered
  )

  const inactiveColor = useColorModeValue('neutral.100', 'neutralDarkMode.100')
  const activeColor = useColorModeValue('blue.600', 'blueDarkMode.400')

  const renderTooltipLabel = (numIssuesResolved: number, numIssues: number) => {
    if (numIssues - numIssuesResolved >= 0) {
      // Avoid negative value when still rendering
      return `${numIssues - numIssuesResolved} issues unresolved`
    }
  }

  return (
    <>
      {numIssuesResolved !== -1 && numIssues !== -1 ? (
        <Slider
          aria-label="clean-label-tracker"
          value={numIssuesResolved}
          max={numIssues}
          color={activeColor}
          focusThumbOnChange={false}
          mb="8px"
        >
          <Box onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            <SliderTrack height="9px" bg={inactiveColor}>
              <SliderFilledTrack />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg={activeColor}
              color="white"
              placement="top"
              isOpen={isResolving}
              label={renderTooltipLabel(numIssuesResolved, numIssues)}
            >
              <Box boxSize={6} />
            </Tooltip>
          </Box>
        </Slider>
      ) : (
        <Box h="1.5rem" />
      )}
    </>
  )
}

export default IssuesResolvedSlider
