import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import {
  IconButtonProps,
  IconFrameRoot,
  IconFrameRootSize,
} from '@components/iconFrameButton/IconFrameRoot'
import { IconX } from '@components/icons'
import { ForwardedRef, forwardRef, memo } from 'react'
import { type Except } from 'type-fest'

type ButtonCloseSize = Extract<
  IconFrameRootSize,
  'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large'
>

const ButtonCloseBase = (
  {
    className,
    size = 'medium',
    ...props
  }: Except<IconButtonProps, 'variant' | 'icon' | 'size'> & { size?: ButtonCloseSize },
  ref: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <IconFrameRoot
      ref={ref}
      className={className}
      variant="closeButton"
      size={size}
      icon={<IconX />}
      clickable={true}
      {...props}
    />
  )
}

/**
 * ButtonClose component
 */
export const ButtonClose = memo(forwardRef(ButtonCloseBase))
IconFrameButton.displayName = 'ButtonClose'
