import { LabelRoot } from '@components/label/Label'
import { cn } from '@utils/tailwindUtils'
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
} from 'react'

import {
  FormFieldContextValue,
  FormFieldProvider,
  useFormField,
} from '../formField/FormFieldContext'

interface LabelInlineProps {
  variant?: 'textDefault' | 'textSubtle' | 'block'
  content: ReactNode
  description?: ReactNode
}

const LabelInlineBase = (
  {
    variant = 'textDefault',
    className,
    children,
    content,
    description,
    ...props
  }: Omit<ComponentProps<typeof LabelRoot>, 'content'> & LabelInlineProps,
  ref: ForwardedRef<HTMLLabelElement>
) => {
  const isTextVariant = variant?.startsWith('text')
  const { htmlFor, disabled, required } = useFormField() || {}
  return (
    <LabelRoot
      className={cn(
        'type-body-500-medium grid cursor-pointer grid-cols-[max-content_1fr] items-start justify-items-start gap-x-4 gap-y-1',
        disabled && 'cursor-not-allowed',
        variant === 'block' && 'items-center',
        className
      )}
      ref={ref}
      htmlFor={htmlFor}
      {...props}
    >
      <div className={cn(isTextVariant && 'pt-[1px]')}>{children}</div>
      <div
        className={cn(
          'text-text-strong',
          variant === 'textSubtle' && 'type-body-100 text-text-faint',
          disabled && 'text-text-disabled'
        )}
      >
        {content}
        {isTextVariant && required && '*'}
      </div>
      {description && (
        <p
          className={cn(
            'type-body-100 col-start-2 text-text-faint',
            disabled && 'text-text-disabled'
          )}
        >
          {description}
        </p>
      )}
    </LabelRoot>
  )
}

const LabelInlineBaseFR = forwardRef(LabelInlineBase)

const LabelInlineBaseWithCtx = (
  {
    htmlFor,
    required,
    error,
    disabled,
    ...props
  }: ComponentPropsWithoutRef<typeof LabelInlineBaseFR> & FormFieldContextValue,
  ref: ComponentPropsWithRef<typeof LabelInlineBaseFR>['ref']
) => {
  return (
    <FormFieldProvider htmlFor={htmlFor} required={required} error={error} disabled={disabled}>
      <LabelInlineBaseFR ref={ref} {...props} />
    </FormFieldProvider>
  )
}

/**
 * `<label>` for `Checkbox`, `Radio` and other form inputs that should display
 * side-by-side with their labels. Intended to be used as a wrapper around the
 * component rather than on its own.
 *
 * @param text The main text for the label
 * @param description Additional text to be displayed below the title
 * @param children Place your input component as a child
 */
export const LabelInline = memo(forwardRef(LabelInlineBaseWithCtx))
LabelInline.displayName = 'LabelInline'
