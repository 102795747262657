import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { useState } from 'react'

import { UpdatePlanModalProps } from './UpdatePlanModal.types'

const UpdatePlanModal = (props: UpdatePlanModalProps) => {
  const { isOpen, onClose, onSubmit, rowData } = props

  const [pricePerMonth, setPricePerMonth] = useState<number>(rowData.price_per_month)
  const [datasetRowsLimit, setDatasetRowsLimit] = useState<number>(rowData.dataset_rows_limit)
  const [datasetSizeInBytesLimit, setDatasetSizeInBytesLimit] = useState<number>(
    rowData.dataset_size_limit_bytes
  )
  const [datasetNumColumnsLimit, setDatasetNumColumnsLimit] = useState<number>(
    rowData.dataset_num_columns_limit
  )
  const [projectNumClassesLimit, setProjectNumClassesLimit] = useState<number>(
    rowData.project_num_classes_limit
  )
  const [projectInstanceSizeLimit, setProjectInstanceSizeLimit] = useState<string>(
    rowData.project_instance_size_limit
  )
  const [projectTrainingTimeLimitSeconds, setProjectTrainingTimeLimitSeconds] = useState<number>(
    rowData.project_training_time_limit
  )
  const [cleansetExportRowsMonthlyLimit, setCleansetExportRowsMonthlyLimit] = useState<number>(
    rowData.cleanset_export_rows_monthly_limit
  )
  const [freeCleansetExportRowsMonthlyLimit, setFreeCleansetExportRowsMonthlyLimit] =
    useState<number>(rowData.free_cleanset_export_rows_monthly_limit)
  const [freeInferenceExportRowsMonthlyLimit, setFreeInferenceExportRowsMonthlyLimit] =
    useState<number>(rowData.free_inference_export_rows_monthly_limit)
  const [freeModelsMonthlyLimit, setFreeModelsMonthlyLimit] = useState<number>(
    rowData.free_models_monthly_limit
  )
  const [freeProjectsMonthlyLimit, setFreeProjectsMonthlyLimit] = useState<number>(
    rowData.free_projects_monthly_limit
  )
  const [inferenceExportRowsMonthlyLimit, setInferenceExportRowsMonthlyLimit] = useState<number>(
    rowData.inference_export_rows_monthly_limit
  )
  const [pricePerRowCleansetExport, setPricePerRowCleansetExport] = useState<number>(
    rowData.price_per_row_cleanset_export
  )
  const [pricePerRowInferenceExport, setPricePerRowInferenceExport] = useState<number>(
    rowData.price_per_row_inference_export
  )
  const [pricePerYear, setPricePerYear] = useState<number>(rowData.price_per_year)
  const [freeTrialDatasetRowsLimit, setFreeTrialDatasetRowsLimit] = useState<number>(
    rowData.free_trial_dataset_rows_limit
  )
  const [freeTrialInferenceExportRowsLimit, setFreeTrialInferenceExportRowsLimit] =
    useState<number>(rowData.free_trial_inference_export_rows_limit)
  const [freeTrialLengthDays, setFreeTrialLengthDays] = useState<number>(
    rowData.free_trial_length_days
  )
  const [freeTrialModelsLimit, setFreeTrialModelsLimit] = useState<number>(
    rowData.free_trial_models_limit
  )
  const [freeTrialProjectsLimit, setFreeTrialProjectsLimit] = useState<number>(
    rowData.free_trial_projects_limit
  )

  const inputBg = useColorModeValue('gray.200', 'gray.600')

  const requiredFormValues = [
    pricePerMonth,
    cleansetExportRowsMonthlyLimit,
    freeCleansetExportRowsMonthlyLimit,
    freeInferenceExportRowsMonthlyLimit,
    freeModelsMonthlyLimit,
    freeProjectsMonthlyLimit,
    inferenceExportRowsMonthlyLimit,
    pricePerRowCleansetExport,
    pricePerRowInferenceExport,
    pricePerYear,
    freeTrialDatasetRowsLimit,
    freeTrialInferenceExportRowsLimit,
    freeTrialLengthDays,
    freeTrialModelsLimit,
    freeTrialProjectsLimit,
    datasetRowsLimit,
    datasetSizeInBytesLimit,
    datasetNumColumnsLimit,
    projectNumClassesLimit,
    projectInstanceSizeLimit,
    projectTrainingTimeLimitSeconds,
  ]

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Billing Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="1.5rem">
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(cleansetExportRowsMonthlyLimit)}>
                  <FormLabel>Cleanset Export Rows Monthly Limit</FormLabel>
                  <Input
                    defaultValue={rowData.cleanset_export_rows_monthly_limit}
                    type="number"
                    onChange={(evt) => {
                      setCleansetExportRowsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeCleansetExportRowsMonthlyLimit)}>
                  <FormLabel>Free Cleanset Export Rows Monthly Limit</FormLabel>
                  <Input
                    defaultValue={rowData.free_cleanset_export_rows_monthly_limit}
                    type="number"
                    onChange={(evt) => {
                      setFreeCleansetExportRowsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeInferenceExportRowsMonthlyLimit)}>
                  <FormLabel>Free Inference Export Rows Monthly Limit</FormLabel>
                  <Input
                    defaultValue={rowData.free_inference_export_rows_monthly_limit}
                    type="number"
                    onChange={(evt) => {
                      setFreeInferenceExportRowsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeModelsMonthlyLimit)}>
                  <FormLabel>Free Models Monthly Limit</FormLabel>
                  <Input
                    defaultValue={rowData.free_models_monthly_limit}
                    type="number"
                    onChange={(evt) => {
                      setFreeModelsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeProjectsMonthlyLimit)}>
                  <FormLabel>Free Projects Monthly Limit</FormLabel>
                  <Input
                    defaultValue={rowData.free_projects_monthly_limit}
                    type="number"
                    onChange={(evt) => {
                      setFreeProjectsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(datasetRowsLimit)}>
                  <FormLabel>Dataset Rows Limit</FormLabel>
                  <Input
                    defaultValue={rowData.dataset_rows_limit}
                    type="number"
                    onChange={(evt) => {
                      setDatasetRowsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={datasetSizeInBytesLimit !== null && isNaN(datasetSizeInBytesLimit)}
                >
                  <FormLabel>Dataset Size in Bytes Limit</FormLabel>
                  <Input
                    defaultValue={rowData.dataset_size_limit_bytes}
                    type="number"
                    onChange={(evt) => {
                      setDatasetSizeInBytesLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={datasetNumColumnsLimit !== null && isNaN(datasetNumColumnsLimit)}
                >
                  <FormLabel>Dataset Number of Columns Limit</FormLabel>
                  <Input
                    defaultValue={rowData.dataset_num_columns_limit}
                    type="number"
                    onChange={(evt) => {
                      setDatasetNumColumnsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={projectNumClassesLimit !== null && isNaN(projectNumClassesLimit)}
                >
                  <FormLabel>Project Number of Classes Limit</FormLabel>
                  <Input
                    defaultValue={rowData.project_num_classes_limit}
                    type="number"
                    onChange={(evt) => {
                      setProjectNumClassesLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Project Instance Size Limit</FormLabel>
                  <Select
                    bg={inputBg}
                    defaultValue={rowData.project_instance_size_limit}
                    onChange={(evt) => setProjectInstanceSizeLimit(evt.target.value)}
                  >
                    <option value="SMALL">SMALL</option>
                    <option value="LARGE">LARGE</option>
                    <option value="EXTRA_LARGE">EXTRA LARGE</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={
                    projectTrainingTimeLimitSeconds !== null &&
                    isNaN(projectTrainingTimeLimitSeconds)
                  }
                >
                  <FormLabel>Project Training Time Limit (seconds)</FormLabel>
                  <Input
                    defaultValue={rowData.project_training_time_limit}
                    type="number"
                    onChange={(evt) => {
                      setProjectTrainingTimeLimitSeconds(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(inferenceExportRowsMonthlyLimit)}>
                  <FormLabel>Inference Export Rows Monthly Limit</FormLabel>
                  <Input
                    defaultValue={rowData.inference_export_rows_monthly_limit}
                    type="number"
                    onChange={(evt) => {
                      setInferenceExportRowsMonthlyLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(pricePerMonth)}>
                  <FormLabel>Price Per Month ($)</FormLabel>
                  <Input
                    defaultValue={rowData.price_per_month}
                    type="number"
                    onChange={(evt) => {
                      setPricePerMonth(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(pricePerYear)}>
                  <FormLabel>Price Per Year ($)</FormLabel>
                  <Input
                    defaultValue={rowData.price_per_year}
                    type="number"
                    onChange={(evt) => {
                      setPricePerYear(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(pricePerRowCleansetExport)}>
                  <FormLabel>Price Per Row Cleanset Export Overage (cents)</FormLabel>
                  <Input
                    defaultValue={rowData.price_per_row_cleanset_export}
                    type="number"
                    onChange={(evt) => {
                      setPricePerRowCleansetExport(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(pricePerRowInferenceExport)}>
                  <FormLabel>Price Per Row Inference Export Overage (cents)</FormLabel>
                  <Input
                    defaultValue={rowData.price_per_row_inference_export}
                    type="number"
                    onChange={(evt) => {
                      setPricePerRowInferenceExport(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeTrialDatasetRowsLimit)}>
                  <FormLabel>Free Trial Dataset Rows Limit</FormLabel>
                  <Input
                    defaultValue={rowData.free_trial_dataset_rows_limit}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialDatasetRowsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeTrialLengthDays)}>
                  <FormLabel>Free Trial Length Days</FormLabel>
                  <Input
                    defaultValue={rowData.free_trial_length_days}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialLengthDays(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeTrialModelsLimit)}>
                  <FormLabel>Free Trial Models Limit</FormLabel>
                  <Input
                    defaultValue={rowData.free_trial_models_limit}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialModelsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeTrialProjectsLimit)}>
                  <FormLabel>Free Trial Projects Limit</FormLabel>
                  <Input
                    defaultValue={rowData.free_trial_projects_limit}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialProjectsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={isNaN(freeTrialInferenceExportRowsLimit)}>
                  <FormLabel>Free Trial Inference Export Rows Limit</FormLabel>
                  <Input
                    defaultValue={rowData.free_trial_inference_export_rows_limit}
                    type="number"
                    onChange={(evt) => {
                      setFreeTrialInferenceExportRowsLimit(evt.target.valueAsNumber)
                    }}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </ModalBody>
        <ModalFooter mt={3}>
          <Button
            colorScheme="teal"
            isDisabled={requiredFormValues.includes(NaN)}
            mr={3}
            onClick={() => {
              onSubmit(rowData.id ?? '', {
                cleanset_export_rows_monthly_limit: cleansetExportRowsMonthlyLimit,
                free_cleanset_export_rows_monthly_limit: freeCleansetExportRowsMonthlyLimit,
                free_inference_export_rows_monthly_limit: freeInferenceExportRowsMonthlyLimit,
                free_models_monthly_limit: freeModelsMonthlyLimit,
                free_projects_monthly_limit: freeProjectsMonthlyLimit,
                inference_export_rows_monthly_limit: inferenceExportRowsMonthlyLimit,
                price_per_month: pricePerMonth,
                price_per_year: pricePerYear,
                price_per_row_cleanset_export: pricePerRowCleansetExport,
                price_per_row_inference_export: pricePerRowInferenceExport,
                free_trial_dataset_rows_limit: freeTrialDatasetRowsLimit,
                free_trial_length_days: freeTrialLengthDays,
                free_trial_models_limit: freeTrialModelsLimit,
                free_trial_projects_limit: freeTrialProjectsLimit,
                free_trial_inference_export_rows_limit: freeTrialInferenceExportRowsLimit,
                dataset_rows_limit: datasetRowsLimit,
                dataset_size_limit_bytes: datasetSizeInBytesLimit,
                dataset_num_columns_limit: datasetNumColumnsLimit,
                project_num_classes_limit: projectNumClassesLimit,
                project_instance_size_limit: projectInstanceSizeLimit,
                project_training_time_limit: projectTrainingTimeLimitSeconds,
                plan_name: rowData.plan_name,
                tier: rowData.tier,
                description: rowData.description,
                id: rowData.id,
              })
              onClose()
            }}
          >
            Update
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdatePlanModal
