import { Code } from '@chakra-ui/react'
import { Badge } from '@components/badge/Badge'
import { Tooltip } from '@components/tooltip/Tooltip'

import { CLEANLAB_FRONTEND_COLUMN } from '../../datasheet/Datasheet.types'
import { RowData } from '../EditInterface.types'
import { issueColumns } from './IssuesTable.types'

export const getIssueStacks = (
  currRowData: RowData,
  resolverTextColor: string,
  tooltipBg: string,
  tooltipColor: string
) => {
  const applicableIssues = issueColumns.filter((e) => currRowData[e])
  return applicableIssues.map((issue) => {
    return (
      <Tooltip key={issue} content={getIssueTooltipBody(issue, tooltipBg, tooltipColor)}>
        <div className="flex flex-col items-start gap-3 pr-5">
          <div className="flex gap-2">
            <p className="type-caption-semibold" color={resolverTextColor}>
              Score
            </p>
            <p className="type-caption-semibold">
              {currRowData[getIssueScoreName(issue)] !== undefined
                ? ((currRowData[getIssueScoreName(issue)] ?? 0) as number).toFixed(2)
                : 'N/A'}
            </p>
          </div>
          <Badge className="whitespace-nowrap" size="small">
            {getIssueTitle(issue)}
          </Badge>
        </div>
      </Tooltip>
    )
  })
}

const getIssueTitle = (column: CLEANLAB_FRONTEND_COLUMN) => {
  switch (column) {
    case CLEANLAB_FRONTEND_COLUMN.ISSUE: {
      return 'Label Issue'
    }
    case CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS: {
      return 'Ambiguous'
    }
    case CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE: {
      return 'Near Duplicate'
    }
    case CLEANLAB_FRONTEND_COLUMN.OUTLIER: {
      return 'Outlier'
    }
    case CLEANLAB_FRONTEND_COLUMN.UNLABELED: {
      return 'Unlabeled'
    }
    default: {
      return ''
    }
  }
}

const getIssueScoreName = (column: CLEANLAB_FRONTEND_COLUMN) => {
  switch (column) {
    case CLEANLAB_FRONTEND_COLUMN.ISSUE: {
      return CLEANLAB_FRONTEND_COLUMN.LABEL_ISSUE_SCORE
    }
    case CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS: {
      return CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS_SCORE
    }
    case CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE: {
      return CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE_SCORE
    }
    case CLEANLAB_FRONTEND_COLUMN.OUTLIER: {
      return CLEANLAB_FRONTEND_COLUMN.OUTLIER_SCORE
    }
    default: {
      return ''
    }
  }
}

const getIssueTooltipBody = (
  issue: CLEANLAB_FRONTEND_COLUMN,
  tooltipBg: string,
  tooltipColor: string
) => {
  switch (issue) {
    case CLEANLAB_FRONTEND_COLUMN.ISSUE: {
      return (
        <>
          <strong>Label Issue</strong>
          <br />
          This data point is potentially mislabeled. The{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Suggested
          </Code>{' '}
          column shows the most likely alternative label. The{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Label Issue Score
          </Code>
          , between 0 and 1, describes the severity of the issue, with lower scores being worse.
        </>
      )
    }
    case CLEANLAB_FRONTEND_COLUMN.OUTLIER: {
      return (
        <>
          <strong>Outlier</strong>
          <br />
          This data point is significantly different from other data and may be an outlier. Such
          data might be appropriate to exclude from the Dataset. The{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Outlier Score
          </Code>
          , between 0 and 1, describes the severity of the issue, with lower scores being worse.
        </>
      )
    }
    case CLEANLAB_FRONTEND_COLUMN.UNLABELED: {
      return (
        <>
          <strong>Unlabeled</strong>
          <br />
          This data point is unlabeled. The{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Suggested
          </Code>{' '}
          column shows the most likely label for this data point.
        </>
      )
    }
    case CLEANLAB_FRONTEND_COLUMN.AMBIGUOUS: {
      return (
        <>
          <strong>Ambiguous</strong>
          <br />
          This data point may be ambiguous and is not well-described by any of the classes in this
          Dataset. Such data might be appropriate to exclude from the Dataset. The{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Ambiguous Score
          </Code>
          , between 0 and 1, describes the severity of the issue, with lower scores being worse.
        </>
      )
    }
    case CLEANLAB_FRONTEND_COLUMN.NEAR_DUPLICATE: {
      return (
        <>
          <strong>Near Duplicate</strong>
          <br />
          The Dataset contains at least one other data point that is identical or near-identical to
          this data point. These data points have been given the same{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Near Duplicate Cluster ID
          </Code>{' '}
          value. Duplicates might be appropriate to exclude from the Dataset. The{' '}
          <Code bg={tooltipBg} color={tooltipColor} fontSize="xs">
            Near Duplicate Score
          </Code>
          , between 0 and 1, describes the severity of the issue, with lower scores being worse,
          indicating more similar/identical examples.
        </>
      )
    }
    default: {
      return ''
    }
  }
}
