import { Center, Spinner, Text, VStack } from '@chakra-ui/react'
import BillingInfoForm from '@common/billing/BillingInfoForm'
import ElementsProvider from '@providers/billing/ElementsProvider'
import { useSetupPaymentMethod } from '@services/billing/mutations'
import { AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'

import CardDetails from './cardDetails/CardDetails'
import { PaymentSetupProps } from './PaymentsSetup.types'

const PaymentSetup = (props: PaymentSetupProps) => {
  const {
    setStripeLoading,
    onCardAdded,
    setHasCard,
    setIsAddingCard,
    collectCardDetails,
    setCollectCardDetails,
  } = props

  const [clientSecret, setClientSecret] = useState<string>('')
  const [collectPayment, setCollectPayment] = useState<boolean>(false)

  const { mutate: setupPaymentMethod, isLoading: isSetupPaymentLoading } = useSetupPaymentMethod({
    onSuccess: (res: AxiosResponse) => {
      setClientSecret(res.data.clientSecret)
      setCollectPayment(true)
    },
  })

  const handlePaymentSetup = useCallback(async () => {
    setStripeLoading?.(true)
    setupPaymentMethod()
  }, [setStripeLoading, setupPaymentMethod])

  useEffect(() => {
    if (collectCardDetails || collectPayment) {
      handlePaymentSetup()
    }
  }, [collectCardDetails, collectPayment, handlePaymentSetup])

  return isSetupPaymentLoading ? (
    <Center h="450px">
      <Spinner size="lg" />
    </Center>
  ) : collectPayment ? (
    <ElementsProvider clientSecret={clientSecret}>
      <BillingInfoForm
        setHasCard={setHasCard}
        setCollectPayment={setCollectPayment}
        setIsAddingCard={setIsAddingCard}
        setCollectCardDetails={setCollectCardDetails}
        onCardAdded={onCardAdded}
        setStripeLoading={setStripeLoading}
      />
    </ElementsProvider>
  ) : (
    <VStack align="flex-start" h="100%" w="100%">
      <Text fontSize="lg">Billing Information</Text>
      <CardDetails
        setHasCard={setHasCard}
        setIsAddingCard={setIsAddingCard}
        handlePaymentSetup={handlePaymentSetup}
        setCollectCardDetails={setCollectCardDetails}
        setCollectPayment={setCollectPayment}
      />
    </VStack>
  )
}

export default PaymentSetup
