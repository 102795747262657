import { ActionType, CommandProps } from '../commandpalette/CommandPalette.types'

interface GetDefaultDataLabelingCommandsProps {
  isUnlabeled: boolean
  isMultiSelect: boolean
  suggestedAction: string
  suggestedLabel: string
  labelToProba: Record<string, number>
  handleActionSelect: (
    action: string,
    label?: string | number | boolean,
    isNewLabel?: boolean
  ) => void
  hideSuggestion: boolean
  givenLabel: string
}

export const getDefaultDataLabelingCommands = (
  props: GetDefaultDataLabelingCommandsProps
): CommandProps[] => {
  const {
    isUnlabeled,
    isMultiSelect,
    suggestedAction,
    suggestedLabel,
    labelToProba,
    handleActionSelect,
    hideSuggestion,
    givenLabel,
  } = props
  if (isUnlabeled && !isMultiSelect && suggestedAction !== ActionType.EXCLUDE) {
    return [
      {
        category: suggestedAction,
        name: `${suggestedLabel}`,
        shortcut: 'W',
        proba: labelToProba[suggestedLabel],
        command: () => handleActionSelect(suggestedAction),
      },
      {
        category: ActionType.EXCLUDE,
        name: 'Exclude',
        shortcut: 'E',
        proba: labelToProba.EXCLUDE,
        command: () => handleActionSelect(ActionType.EXCLUDE),
      },
    ]
  } else if (isUnlabeled && !isMultiSelect) {
    return [
      {
        category: suggestedAction,
        name: `${suggestedLabel}`,
        shortcut: 'W',
        proba: labelToProba[suggestedLabel],
        command: () => handleActionSelect(suggestedAction),
      },
    ]
  } else if (isMultiSelect) {
    return [
      {
        category: ActionType.AUTO_FIX,
        name: 'Auto-fix selected datapoints',
        shortcut: 'W',
        proba: labelToProba[suggestedLabel],
        command: () => handleActionSelect(ActionType.AUTO_FIX),
      },
    ]
  } else if (!hideSuggestion) {
    return [
      {
        category: ActionType.KEEP,
        name: `${givenLabel}`,
        shortcut: 'Q',
        proba: labelToProba[givenLabel],
        command: () => handleActionSelect(ActionType.KEEP),
      },
      {
        category: suggestedAction,
        name: `${suggestedLabel}`,
        shortcut: 'W',
        proba: labelToProba[suggestedLabel],
        command: () => handleActionSelect(suggestedAction),
      },
    ]
  } else {
    return [
      {
        category: ActionType.KEEP,
        name: `${givenLabel}`,
        shortcut: 'Q',
        proba: labelToProba[givenLabel],
        command: () => handleActionSelect(ActionType.KEEP),
      },
    ]
  }
}

export const getAccordionHeight = (isAccordionOpen: boolean, displayedCommandsLength: number) => {
  if (isAccordionOpen) {
    return `calc(${displayedCommandsLength * 80}px + 200px)`
  }
}

export const getAccordionMaxHeight = (showThresholdButton: boolean) => {
  if (showThresholdButton) {
    return '48vh'
  } else {
    return '55vh'
  }
}

export const getAccorionMarginTop = (showThresholdButton: boolean) => {
  if (showThresholdButton) {
    return '12px'
  } else {
    return '0px'
  }
}
