import { RowCountType } from '@services/datasheet/constants'
import { ColumnApi, GridApi } from 'ag-grid-community'

export interface PresetFilterRowProps {
  cleansetId: string
  gridApi: GridApi
  filterType: string
  rowCountType: RowCountType
  currentPresetFilter: string
  setActivePresetFilter: (currentPresetFilter: string) => void
  firstGridDataRendered: boolean
  columnApi: ColumnApi
  presetConfigCallback: (filterType: string) => void
  resetResolverAndRowSelection: () => void
}

export const PresetFilterRowTitle: Record<string, string> = {
  total: 'total examples',
  best_examples: 'well labeled',
  issues_resolved: 'issues resolved',
  label_issues: 'label issues',
  outlier: 'outliers',
  unlabeled: 'unlabeled',
  ambiguous: 'ambiguous',
  is_near_duplicate: 'near duplicates',
  is_PII: 'PII',
  is_informal: 'informal',
  is_non_english: 'non english',
  is_toxic: 'toxic',
  is_biased: 'biased',
  is_dark: 'dark',
  is_light: 'light',
  is_blurry: 'blurry',
  is_odd_size: 'odd size',
  is_low_information: 'low information',
  is_grayscale: 'grayscale',
  is_odd_aspect_ratio: 'odd aspect ratio',
  is_NSFW: 'NSFW',
}
