import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import {
  EnrichmentJobDetailType,
  EnrichmentJobRes,
  EnrichmentProjectRes,
  EnrichmentProjectsRes,
  queryKeys as enrichmentQueryKeys,
} from '@services/enrichment/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import camelCaseKeys from '@utils/functions/camelCaseKeys'
import { LoadSuccessParams } from 'ag-grid-community'
import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import axios from 'axios'
import { normalizeEnrichmentJobPreviewData } from 'src/pages/enrichmentProject/enrichmentUtils'
import { queryClient } from 'src/queryClient'

import { truncateDecimals } from './cleanset/utils'
import { cleanlabTransformResponse, DEFAULT_STALE_TIME_MS, triggerFileDownload } from './common'
import {
  createServerSideDatasource as createServerSideDatasourceFromProjectApi,
  ServerType,
} from './projectApi'

export const enrichmentProjectApi = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/enrichment/v0`,
  withCredentials: true,
  transformResponse: cleanlabTransformResponse,
})

export const enrichmentProjectQueryFn = async (projectId: string) => {
  const accessToken = await auth.getTokenSilently()
  let data = (
    await enrichmentProjectApi.get<EnrichmentProjectRes>(
      `projects/${projectId}`,
      createAuthHeaders(accessToken)
    )
  ).data

  // TODO move these to transformResponse above when all enrichmentProjectApi operations can safely be transformed
  // Main roadblock is enrichmentProjectsQueryFn which needs to return snake_case for now
  data = camelCaseKeys(data)
  data.datasetId = data.datasetId.replaceAll('-', '')
  return data
}

export const enrichmentProjectsQueryFn = async ({ page = 1 }: { page: number }) => {
  const accessToken = await auth.getTokenSilently()

  // TODO: Figure out pagination with backend team
  const url = `projects?page=${page}`

  return (
    await enrichmentProjectApi.get<EnrichmentProjectsRes>(url, createAuthHeaders(accessToken))
  ).data
}

export const enrichmentJobsQueryFn = async (projectId: string) => {
  const accessToken = await auth.getTokenSilently()
  const url = `projects/${projectId}/jobs`
  const { data = [] } = await enrichmentProjectApi.get<EnrichmentJobDetailType>(
    url,
    createAuthHeaders(accessToken)
  )

  return camelCaseKeys(data)
}

export const enrichmentJobPreviewQueryFn = async (jobId: string, newColumnName: string) => {
  const accessToken = await auth.getTokenSilently()
  const url = `preview/${jobId}`
  const { data } = await enrichmentProjectApi.get<EnrichmentJobDetailType[]>(
    url,
    createAuthHeaders(accessToken)
  )

  return normalizeEnrichmentJobPreviewData(data, newColumnName)
}

export const enrichmentJobStatusQueryFn = async (jobId: string) => {
  const accessToken = await auth.getTokenSilently()
  const url = `status/${jobId}`
  const { data } = await enrichmentProjectApi.get<EnrichmentJobDetailType[]>(
    url,
    createAuthHeaders(accessToken)
  )

  return camelCaseKeys(data)
}

export const enrichmentJobFullEnrichmentQueryFn = async (projectId: string) => {
  const accessToken = await auth.getTokenSilently()
  const url = `enrich_all/${projectId}?include_original_dataset=true`
  const output: LoadSuccessParams = { rowData: [], rowCount: 0 }

  try {
    const { data } = await enrichmentProjectApi.get<EnrichmentJobRes[]>(
      url,
      createAuthHeaders(accessToken)
    )

    output.rowCount = data.length
    output.rowData = data.map((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (typeof value === 'number') {
          item[key] = truncateDecimals(value, 3)
        }
      })

      return item
    })
  } catch (error) {
    console.error(error)
  }

  return output
}

export const enrichmentProjectsQueryOptions = ({ page = 1 }: { page?: number }) => {
  const queryKey = enrichmentQueryKeys.enrichmentProjects.all({ page })
  return {
    queryKey,
    queryFn: () => enrichmentProjectsQueryFn({ page }),
    staleTime: DEFAULT_STALE_TIME_MS,
  } as const
}

export const enrichmentJobPreviewQueryOptions = (jobId: string, newColumnName: string) => {
  const queryKey = enrichmentQueryKeys.jobs.id(jobId)
  return {
    queryKey,
    queryFn: () => enrichmentJobPreviewQueryFn(jobId, newColumnName),
    staleTime: DEFAULT_STALE_TIME_MS,
  } as const
}

export const enrichmentJobExportToDownloadableFile = async (jobId: string) => {
  try {
    const accessToken = await auth.getTokenSilently()
    const url = `export/${jobId}`
    const response = await enrichmentProjectApi.get(url, createAuthHeaders(accessToken))
    const filename =
      response.headers['content-disposition']?.split('filename=')?.[1] ?? 'export.csv'

    return triggerFileDownload(response.data, filename)
  } catch (err) {
    console.error(err)
  }
}

const createServer = (): ServerType => {
  return {
    getData: async (_request: IServerSideGetRowsRequest) => {
      try {
        const res = await queryClient.fetchQuery(enrichmentProjectsQueryOptions({}))
        return {
          rows: res?.projects ?? [],
          row_count: res?.total_count ?? 0,
        }
      } catch {
        return { rows: [], row_count: 0 }
      }
    },
  }
}

const apiMethods = {
  createServer,
  createServerSideDatasource: createServerSideDatasourceFromProjectApi,
}

export default apiMethods
