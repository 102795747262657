import Layout from '@common/layout/Layout'
import { Button } from '@components/button/Button'
import ErrorMessage from '@providers/errors/ErrorMessage'
import { useOrgUsageSummary, useUsageDownloadUrl } from '@services/organizations/queries'
import { useMyself } from '@services/user/queries'
import { downloadFileFromUrl } from '@utils/functions/downloadFile'
import { getMDYDate } from '@utils/functions/timestampToDate'
import { cn } from '@utils/tailwindUtils'

import { UsageSummaryDict, usageTableBlockList } from './VpcUsage.types'

const VpcUsage = () => {
  const { organizationId } = useMyself()

  const usageSummaryResponse = useOrgUsageSummary(organizationId ?? '') ?? {}

  const { downloadUrl } = useUsageDownloadUrl(organizationId ?? '')

  const filteredUsageSummaryKeys = Object.keys(usageSummaryResponse).filter(
    (key) => !usageTableBlockList.includes(key)
  )

  const getCellClassName = (index: number) =>
    cn(
      'flex flex-row justify-between border-b p-6',
      // Cells that are never at the bottom of table always have border-bottom
      index < filteredUsageSummaryKeys.length - 2 && 'border-b',
      // Bottom two cells in two column table have no border-bottom
      index >= filteredUsageSummaryKeys.length - 2 && 'md:border-b-0',
      // Bottom cell of single column table has no border-bottom
      index === filteredUsageSummaryKeys.length - 1 && 'border-b-0',
      !(index % 2) && 'md:border-r'
    )

  return (
    <Layout>
      {organizationId && usageSummaryResponse ? (
        <div className="mx-13 flex flex-col gap-7 py-5">
          <div className="flex flex-row justify-between">
            <p className="type-display-100 text-text-strong">Usage</p>{' '}
            <Button
              variant="highContrast"
              onClick={() => {
                downloadFileFromUrl(downloadUrl)
              }}
            >
              Export
            </Button>
          </div>
          <div className="overflow-hidden rounded-3 border border-border-1">
            <div className="w-full  border-b  bg-surface-1 p-7">
              <p className="type-body-300-medium w-full text-text-strong">
                License:{' '}
                <span className="type-body-300 text-text-primary">
                  {getMDYDate(usageSummaryResponse.licenseStartDate)} -{' '}
                  {getMDYDate(usageSummaryResponse.licenseEndDate)}
                </span>
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2">
              {filteredUsageSummaryKeys.map((key: string, index) => (
                <div key={key} className={getCellClassName(index)}>
                  <p className="type-body-200-medium text-text-strong">
                    {UsageSummaryDict[key as keyof typeof UsageSummaryDict]}
                  </p>
                  <p className="type-body-200 text-text-faint">{`${usageSummaryResponse[key as keyof typeof usageSummaryResponse]}`}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <ErrorMessage error={Error('Could not load usage details. Please try again later.')} />
      )}
    </Layout>
  )
}

export default VpcUsage
