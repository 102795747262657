import { FormErrorMessage } from '@chakra-ui/react'
import { LabelRadioCard } from '@components/radioGroup/LabelRadioCard'
import { RadioGroup, RadioGroupItem } from '@components/radioGroup/RadioGroup'
import { EnabledModelTypesRes } from '@services/project/constants'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'

import { SelectMLPrefsForm } from './CreateProjectSelectMLPrefs'
import {
  adminModelChoices,
  Modality,
  modelChoices,
} from './projectFormFields/ProjectFormFields.types'

export const SelectModelType = ({
  enabledModelTypes,
  form,
  modality,
}: Readonly<{
  enabledModelTypes: EnabledModelTypesRes
  form: SelectMLPrefsForm
  modality: Modality
}>) => {
  return (
    <form.Field name="modelType">
      {({ state, handleBlur, handleChange }) => {
        const value = state.value

        const modalityModelChoices = checkFeatureFlag('IS_ADMIN') ? adminModelChoices : modelChoices
        const error = state.meta.errors?.[0] || undefined
        return (
          <div className="flex w-full flex-col">
            <RadioGroup
              className="grid-wrap grid w-full grid-cols-2 gap-8"
              name="modelType"
              value={value}
              onBlur={handleBlur}
              onValueChange={(e) => handleChange(e)}
            >
              {Object.entries(modalityModelChoices).map(([modelType, details]) => (
                <LabelRadioCard
                  key={modelType}
                  data-testid={details.dataTestId}
                  disabled={!enabledModelTypes[modality]?.includes(modelType)}
                  heading={details.name}
                  description={details.description}
                >
                  <RadioGroupItem value={modelType}></RadioGroupItem>
                </LabelRadioCard>
              ))}
            </RadioGroup>
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </div>
        )
      }}
    </form.Field>
  )
}
